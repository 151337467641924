import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import InputBase from '@mui/material/InputBase';
import { useNavigate } from "react-router-dom";
import { styled, alpha } from '@mui/material/styles';
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DashboardIcon from "@mui/icons-material/Dashboard";
import logout from "../images/logout.svg";
import SearchIcon from '@mui/icons-material/Search';
import customer from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import mail from "../images/mail.svg";
import withReactContent from "sweetalert2-react-content";
import MemberTable from "../Customer/MemberTable";
import addPerson from "../images/person-plus-fill.svg";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import info from "../images/info-circle.svg";
import clear from "../images/clear.svg";
import discount from "../images/Discount.svg";
import invoice from "../images/Invoice.svg";
import upscan from "../images/upc-scan.svg";
import Items from "./Items";
import walmart from "../images/Walmart.jpg";
import Swal from 'sweetalert2';
import customers from "../images/customers.svg";
import CustomerDetailsModal from "./CustomerDetailsModal";
import CurbModal from "./CurbModal";
import StorePickupModal from "./StorePickupModal";
import DiscountModal from "./DiscountModal";
import ScanModal from "./ScanModal";
import InvoiceDetailsModal from "./InvoiceDetailsModal";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import print from "../images/print-invoice.svg";
import edit from "../images/edit.svg";
import EditProductModal from "./EditProductModal";
import cross1 from "../images/cross.svg";
import CustomerInvoiceDetailsModal from "./CustomerInvoiceDetailsModal";
import EditTable from "./EditTable";
import { getPendingInvoices } from "../Redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import api from "../Api"
import DeliveryModal from "./DeliveryModal";
import { format } from 'date-fns';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import dairy from '../images/Dairy.png';
import dayjs from "dayjs";
import { setItemStock, setPosPhone, setPosLocation,posOrderData,setDeliveryTypeId, setPosInvoiceId, setCartTotal, posPendingCartItems, setCustomersId, setOrderId } from "../Redux/actions/userActions";
import InStoreModal from "./InStoreModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const defaultItem = {
  cart_id: "",
  product_complete_sku: "",
  product_name: "",
  product_qty_sold: "",
  sale_price: '',
  total_item_price: ''
};

const defaultItemSet = [{
  cart_id: "",
  product_complete_sku: "",
  product_name: "",
  product_qty_sold: "",
  sale_price: '',
  total_item_price: ''
}];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const week = ["Day", "Week", "Month"];
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const SearchInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {

  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Pos() {
  const [open, setOpen] = useState(false);
  let pendingInvoices = useSelector(state => state.user.pending_invoices);
  let pendingCartItem = useSelector(state => state.user.pending_cart_items);
  const deliveryId = useSelector(state => state.user.delivery_Id);
  const [searchItem, setSearchItem] = React.useState();
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const [deliveryTypeId,setDeliveryTypeIds] = React.useState();
  let posDeliverTypeId = useSelector(state => state.user.delivery_Type_Id);
  const dispatch = useDispatch();
  let pendingInvoiceId = useSelector(state => state.user.pos_invoice_id);
  const [finalSegment, setFinalSegment] = React.useState();
  const [finalSubSegment, setSubFinalSegment] = React.useState();
  const response = useSelector(state => state.user.user);
  let phonePnding = useSelector(state => state.user.pending_phone);
  let posLocation = useSelector(state => state.user.pos_location);
  let orderId = useSelector(state => state.user.order_id);
  let customerIds = useSelector(state => state.user.customer_id);
  const [customerId, setCustomerId] = React.useState();
  const [locationId, setLocationId] = React.useState();
  const [searchProductDetails, setSearchProductDetails] = React.useState();
  const handleOpen = () => setOpen(true);
  const [merchantDetails, setMerchantDetails] = React.useState();
  const [customerData, setCustomerData] = React.useState();
  const [doorIndex, setDoorIndex] = React.useState(2);
  const [tabIndex, setTabIndex] = React.useState(0)
  const [location, setLocation] = React.useState();
  const navigate = useNavigate();
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const [cities, setCities] = useState();
  const [segmentDetails, setSegmentDetails] = useState();
  const [subSegments, setSubSegmentDetails] = useState();
  const [itemDetails, setItemDetails] = useState();
  // const [segmentId, setSegementId] = useState();
  const [deliveryTypes, setDeliveryTypes] = React.useState();
  // const [searchValue, setSearchValue] = useState('');

  // const handleSearchValue = (event) => {

  //   setSearchValue(event.target.value);
  // };


  const fetchCities = () => {
    api.getData('/api/locations/get_Location_name_pos?merchant_id=' + response?.merchant_id + '&city_id=' + response?.city_id)
      .then((response) => {

        if (response.data[0]?.Status === "200") {

          setLocation(JSON.parse(response.data[0].LocationDetails));

        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  const [cartUom, setCartUom] = React.useState();
  const getCartUom = (city_id = 1) => {
    api.getData('/api/pos/get_POS_cart_PRODUCT_UOMs?product_id=' + response?.product_id)
      .then((response) => {

        if (response.data[0]?.Status === "200") {

          setCartUom(JSON.parse(response.data[0].ProductDetails));
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
  useEffect(() => {

  }, [merchantDetails]);
  const [searchMerchantItems, setSearchMerchantItems] = React.useState();

  const getSearchDetails = (searchValue) => {
    if (searchValue.length == 0) {
      getSegmentDetails();
    }
    setSearchItem(searchValue);
    // api.getData('/api/pos/get_POS_SearchMerchantItems?merchant_id=' + response?.merchant_id + '&search_param=' + searchValue + '&city_id=' + response?.city_id + '&location_id=' + posLocation)
    api.getData('/api/pos/get_POS_SearchMerchantItems?merchant_id=' + response?.merchant_id + '&module=phone_orders&search_param=' + searchValue)

      .then((response) => {

        if (response.data[0]?.Status === "200") {

          // setSearchProductDetails(JSON.parse(response.data[0].ProductDetails));
          if (JSON.parse(response.data[0].ProductDetails)) {
            //     const filteredSegmentDetails = segmentDetails.filter((item) =>
            //       item.merchant_segment_id === (JSON.parse(response.data[0].ProductDetails)[0].product_segment_id)
            //     );
            let parsedProductDetails = JSON.parse(response.data[0].ProductDetails);
            const productSegmentIds = Array.from(new Set(parsedProductDetails.map(item => item.product_segment_id)));

            const filteredSegmentDetails = segmentDetails.filter(item => productSegmentIds.includes(item.merchant_segment_id));
            setFinalSegment(filteredSegmentDetails);

            const productSubSegmentIds = Array.from(new Set(parsedProductDetails.map(item => item.product_sub_segment_id)));
            const filteredSubSegmentDetails = subSegments.filter(item => productSubSegmentIds.includes(item.merchant_sub_segment_id));
            setSubFinalSegment(filteredSubSegmentDetails);
            // setSubSegmentDetails(JSON.parse(response.data[0].ProductDetails) === null ? [] : filteredSubSegmentDetails);
          }  // const filtereditemDetails = itemDetails.filter((item) =>
          //   item.product_id === (JSON.parse(response.data[0].ProductDetails)[0].product_id)
          // );
          setItemDetails(JSON.parse(response.data[0].ProductDetails) === null ? [] : JSON.parse(response.data[0].ProductDetails));
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  useEffect(() => {
    if (location) {
      dispatch(setPosLocation(location[0]?.merchant_location_id));
      setLocationId(location[0]?.merchant_location_id);
    }
  }, [location]);

  useEffect(() => {
    dispatch(setPosLocation(locationId));
  }, [locationId])

  useEffect(() => {

    if (itemDetails && itemDetails.length > 0) {
      getItemDetailsStock(response?.merchant_id, itemDetails && itemDetails[0].product_id);
    }

  }, [itemDetails])

  const getItemDetailsStock = (merchantId, ProductId) => {
    api.getData('/api/pos/get_item_details_stock?merchant_id=' + merchantId + '&product_id=' + ProductId)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          dispatch(setItemStock(JSON.parse(response.data[0]?.ItemDetails)[0]));
          setItemStock(JSON.parse(response.data[0]?.ItemDetails)[0]);

          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  const getCartDetails = (searchValue) => {
    if (searchValue.length > 0) {
      // api.getData('/api/pos/get_POS_SearchMerchantItems?merchant_id=' + response?.merchant_id + '&search_param=' + searchValue + '&city_id=' + response?.city_id + '&location_id=' + posLocation)
      api.getData('/api/pos/get_POS_SearchMerchantItems?merchant_id=' + response?.merchant_id + '&module=phone_orders&search_param=' + searchValue)

        .then((response) => {

          if (response.data[0]?.Status === "200") {

            setSearchMerchantItems(JSON.parse(response.data[0].ProductDetails));

          }


        })
        .catch((error) => {
          console.log(error)
        })
    } else {

      setSearchMerchantItems([]);
    }


  }
  useEffect(() => {

  }, [merchantDetails]);
  const [phone, setPhone] = React.useState();
  const handleChangePhone = (e) => {
    setPhone(e.target.value);

  }
  useEffect(() => {
    if (phone != phonePnding) {
      dispatch(setPosPhone(phone));
    }
  }, [phone]);



  const [invoiceId, setInvoiceId] = React.useState();
  const getInvoiceId = (customerId) => {
    let data = {
      'order_module': 'phoneorders',
      'customer_id': customerId,
      'bazaar_id': process.env.REACT_APP_MY_TITLE === 'Vikretha' ? 0 : '1',
      'merchant_id': response?.merchant_id,
      'billing_person': '1',
      'billing_start_time': new Date(),
      'environment': process.env.REACT_APP_MY_TITLE,
      'city_id': response?.city_id,
      'location_id': posLocation
    }
    if(data.customer_id === null || data.customer_id === ''){
      showSwalWithError("Customer Id Not Null");
    }else if(data.merchant_id === null || data.merchant_id === ''){
      showSwalWithError("Merchant Id Not Null");
    }else if(data.billing_start_time === null || data.merchant_id === ''){
      showSwalWithError("Billing Start Time Not Null");
    }else if(data.city_id === null || data.city_id === ''){
      showSwalWithError("City Ids Not Null");
    }else if(data.location_id === null || data.location_id === ''){
      showSwalWithError("Location Not Null");
    }
    api.postData('/api/pos/POS_InsertOrderInvoice', data).then((response) => {


      if (response.data[0].Status === "200") {
        // dispatch(setInvoiceId(response.data[0].invoice_id));
        setInvoiceId(response.data[0].invoice_id);
        dispatch(setPosInvoiceId(response.data[0].invoice_id));
      }

    })
  }
  // const handleDeleteTab = (index) => {
  //   // Implement the logic to delete the tab at the given index
  //   // For example, you can remove the tab from the `pendingInvoices` array
  //   const updatedInvoices = [...pendingInvoices];
  //   updatedInvoices.splice(index, 1);
  //   // setPendingInvoices(updatedInvoices);
  // };

  const POSAbortedInvoicecartDetails = (item) => {
    let data = {
      'invoice_id': item.invoice_id,
      'customer_bazaar_id': item.customer_id,
      'merchant_id': item.merchant_id,
      'environment': process.env.REACT_APP_MY_TITLE

    }
    api.postData('/api/pos/POS_Aborted_invoice_cart_details', data).then((response) => {


      if (response.data[0].Status === "200") {


        showSwalWithLink(response.data[0].Message);
        getPosPendingInvoices();
      }

    })
  }

  const checkCustomerId = () => {
    if (pendingInvoices) {
      for (let i = 0; i < pendingInvoices.length; i++) {
        if (pendingInvoices[i].customer_id === customerId) {
          return true;
        }
      }
      return false;
    }
  }
  const getSearchPhoneDetails = () => {
    if (!phone) {
      showSwalWithError("Please Enter Phone Number");
      return false;
    }
    if ((pendingInvoices === null || (pendingInvoices && pendingInvoices.length < 3))) {
      api.getData('/api/pos/customer_phone_verify?phone_number=' + phone + '&env=' + process.env.REACT_APP_MY_TITLE)
        .then((response) => {

          if (response.data[0]?.Status === "200") {
            getPosPendingInvoices();
            setCustomerData(JSON.parse(response.data[0].CustomerDetails)[0]);
            getInvoiceId(JSON.parse(response.data[0].CustomerDetails)[0]?.customer_id);
          } else {
            showSwalWithError(response.data[0]?.Message + 'Please Register')
          }


        })
        .catch((error) => {
          console.log(error)
        })
    }
    // else {
    //   if(checkCustomerId()){
    //     showSwalWithError("Already Customer had Billing Invoice Id");
    //   }

    else if (pendingInvoices && pendingInvoices.length < 3) {
      showSwalWithError("Billings Not More Than 3");
    }
    // }


  }

  const [deliveryAddressDetails, setDeliveryAddressDetails] = React.useState();
  const getDeliveryTypeSending = (invoiceId) => {
    api.getData('/api/pos/get_delivery_types_pending?invoice_id=' + invoiceId)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setDeliveryAddressDetails(JSON.parse(response.data[0].Delivery_typeDetails));

          // getInvoiceId();
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
  useEffect(()=>{
    getPosPendingInvoices();
  },[])
  useEffect(() => {

    if (invoiceId) {
      // dispatch(setPosInvoiceId(response?.pos_invoice_id));
      getPosPendingInvoices();

      getDeliveryTypeSending(invoiceId);
      // getDeliveryTypeSending();

    }
  }, [invoiceId]);


  useEffect(() => {
    if (pendingInvoiceId) {
      // getPendingCartByInvoice();
      getCartItemProductid(pendingInvoiceId);
    }
  }, []);

  useEffect(() => {
    getPosPendingInvoices();
    getDeliveryTypeSending(pendingInvoiceId);
  }, [pendingInvoiceId])


  const [customerOrderDeatails, setCustomerOrderDeatails] = React.useState();
  const POSGetOrderDetailsCustomers = (customerId) => {
    api.getData('/api/pos/POS_Get_order_details_customers?order_module=phoneorders&customer_id=' + customerId + '&merchant_id=' + response?.merchant_id + '&environment=' + process.env.REACT_APP_MY_TITLE)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setCustomerOrderDeatails(JSON.parse(response.data[0].CustomerOrderDeatails));
          // getInvoiceId();
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  const [orderData, setOrderData] = React.useState();
  const POSGetTotalOrderDetailsByMerchant = () => {
    api.getData('/api/pos/POS_Get_total_order_details_byMerchant?order_module=phoneorders&merchant_id=' + response?.merchant_id + '&city_id=' + response?.city_id + '&environment=' + process.env.REACT_APP_MY_TITLE)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setOrderData(JSON.parse(response.data[0].CustomerOrderDeatails));
          // getInvoiceId();
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  useEffect(()=>{
   if(orderData){
    getOrderById(orderData[0].invoice_id);
   }
  },[orderData])

  // const [customerDetails, setCustomerDetails] = React.useState();
  const POSGetPendingCustMerchantDetails = (customerId) => {
    api.getData('/api/pos/POS_Get_Pending_Cust_Merchant_Details?customer_bazaar_id=' + customerId + '&merchant_id=' + response?.merchant_id + '&environment=' + process.env.REACT_APP_MY_TITLE)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setCustomerData(JSON.parse(response.data[0].CustomerDetails)[0]);
          // getInvoiceId();
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
  const logoutDashboard = () => {
    navigate("/");
  };

  const navigateDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    if (pendingInvoices && pendingInvoices.length>0) {
      dispatch(setPosInvoiceId(pendingInvoices ? pendingInvoices[0].invoice_id : '0'));
      POSGetPendingCustMerchantDetails(pendingInvoices ? pendingInvoices[0].customer_id : '0');
      getPOSCustomersAddressDetails(pendingInvoices ? pendingInvoices[0].customer_id : '0');
      POSGetOrderDetailsCustomers(pendingInvoices ? pendingInvoices[0].customer_id : '0')
      setCustomerId(pendingInvoices ? pendingInvoices[0].customer_id : '0')
    }else{
      dispatch(setPosInvoiceId(null));
    }
    

  }, [pendingInvoices])
  // const [pendingInvoices, setPendingInvoices] = React.useState();
  const getPosPendingInvoices = () => {
    api.getData('/api/pos/POS_Get_Pending_Invoices?order_module=phoneorders&merchant_id=' + response?.merchant_id + '&billing_person=' + 1 + '&city_id=' + response?.city_id + '&location_id=' + posLocation + '&environment=' + process.env.REACT_APP_MY_TITLE)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          dispatch(getPendingInvoices(JSON.parse(response.data[0].Pending_Invoices)));
          dispatch(setPosInvoiceId(pendingInvoices ? pendingInvoices[0].invoice_id : '0'));
          dispatch(setCustomersId(pendingInvoices ? pendingInvoices[0].customer_id : '0'));
          if (response.data[0].Pending_Invoices === null) {
            dispatch(setPosInvoiceId(null));
            dispatch(setCustomersId(null));
          }
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }


  const [cartitem, setCartItem] = React.useState();
  const [cartTotals, setCartTotals] = React.useState();
  const getCartItemProductid = (invoiceId) => {

    api.getData('/api/pos/POS_get_cart_item_productid?invoice_id=' + pendingInvoiceId)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          dispatch(posPendingCartItems(JSON.parse(response.data[0].GetCartItems)));
          setCartItem(JSON.parse(response.data[0].GetCartItems));
          setCartTotals(JSON.parse(response.data[0].CartTotals));
          dispatch(setCartTotal(JSON.parse(response.data[0].CartTotals)));
          dispatch(setPosInvoiceId(pendingInvoiceId));
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }
  const [cartItemOrders, setCartItemOrders] = React.useState();
  const [cartTotalsOrders, setCartTotalsOrders] = React.useState();

  const getCartItemsOrderid = (invoiceId) => {

    api.getData('/api/pos/POS_get_cart_item_productid?invoice_id=' + pendingInvoiceId)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setCartItemOrders(JSON.parse(response.data[0].GetCartItems));
          setCartTotalsOrders(JSON.parse(response.data[0].CartTotals));
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (pendingInvoiceId) {
      getCartItemsOrderid(pendingInvoiceId);
      getOrderById(pendingInvoiceId);
    }
    if(pendingInvoices === null){
      dispatch(posPendingCartItems(null));
      setCartItem([]);
      setCustomerData(null)
      getCartItemsOrderid(null);
    }
  }, [pendingInvoiceId]);



  // const [defaultData, setDefaultData] = useState([]);

  // useEffect(() => {
  //   if (cartItemOrders) {
  //     // Filter and update defaultData with matched properties from cartItemOrders
  //     const updatedDefaultData = cartItemOrders.map((cartItem) => {
  //       const matchedProperties = Object.keys(defaultItem)
  //         .filter((prop) => prop in cartItem);

  //       return { ...defaultItem, ...Object.fromEntries(matchedProperties.map((prop) => [prop, cartItem[prop]])) };
  //     });

  //     console.log('updatedDefaultData', updatedDefaultData);
     
  //     setDefaultData(updatedDefaultData);
  //   }else{
  //     setDefaultData(defaultItemSet);
  //   }
  // }, [cartItemOrders]);
  

  // useEffect(()=>{
  //     dispatch(posOrderData(defaultData));
  // },[cartItemOrders]);

  useEffect(()=>{
    dispatch(posOrderData(defaultItemSet));
  },[])

  const [orderCustomerDetails, setOrderCustomerDetails] = React.useState();
  const getOrderById = (invoiceId) => {

    api.getData('/api/pos/POS_Get_Cust_Merchant_invoice_id?invoice_id=' + invoiceId + '&merchant_id=' + response?.merchant_id + '&environment=' + process.env.REACT_APP_MY_TITLE)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setOrderCustomerDetails(JSON.parse(response.data[0].CustomerDetails));
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }
  const [customerBasedMerchantDetails, setCustomerBasedMerchantDetails] = React.useState();
  const getCustomerBasedMerchantDetails = () => {
    api.getData('/api/pos/POS_get_customers_based_merchant_details?order_module=pos&merchant_id=' + response?.merchant_id + '&city_id=' + response?.city_id + '&environment=' + process.env.REACT_APP_MY_TITLE)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setCustomerBasedMerchantDetails(JSON.parse(response.data[0].GetCustomers));
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    getCustomerBasedMerchantDetails();
    POSGetTotalOrderDetailsByMerchant();
  }, []);
     useEffect(()=>{
      if(customerBasedMerchantDetails){
        getPOSCustomersAddressDetails(customerBasedMerchantDetails[0].customer_id);
        POSGetOrderDetailsCustomers(customerBasedMerchantDetails[0].customer_id);
      }
   },[customerBasedMerchantDetails]);
  const [POSCustomersAddressDetails, setPOSCustomersAddressDetails] = React.useState();
  const getPOSCustomersAddressDetails = (customerId) => {
    api.getData('/api/pos/POS_customers_address_details?customer_bazaar_id=' + customerId + '&environment=' + process.env.REACT_APP_MY_TITLE)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setPOSCustomersAddressDetails(JSON.parse(response.data[0].GetCustomers)[0]);
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }

  const deleteCartDetails = (invoice) => {
    if(pendingCartItem && pendingCartItem.length>0){
    let data = {
      'invoice_id': invoice,
      'merchant_id': response?.merchant_id
    }

    try {
      api.postData('/api/pos/POS_DeleteCartDetailsByInvoiceId', data).then((response) => {

        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          getCartItemProductid(invoice);

        } else {
          showSwalWithError(response.data[0].Message);
        }

      })


    } catch (error) {
      // Handle network error
    }
  }else{
    showSwalWithError("No Items In cart");
  }


  };

  // useEffect(()=>{
  //   getCartItemProductid();
  // },[])
  const [pendingCartItems, setPendingCartItems] = React.useState();
  const getPendingCartByInvoice = () => {
    // api.getData('/api/pos/POS_Get_Pending_Cart_by_Invoice?merchant_id=' + pendingInvoices[0].merchant_id + '&invoice_no=' + pendingInvoices[0].invoice_id)
    api.getData('/api/pos/POS_Get_Pending_Cart_by_Invoice?merchant_id=' + response?.merchant_id + '&invoice_no=' + pendingInvoiceId)

      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setPendingCartItems(JSON.parse(response.data[0].Cart_Items));
          dispatch(posPendingCartItems(JSON.parse(response.data[0].Cart_Items)));
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }

const setDeliveryIds = (deliveryId)=>{
   console.log('deliveryId',deliveryId);
  setDeliveryTypeIds(deliveryId);
  dispatch(setDeliveryTypeId(deliveryId))
  const obj = deliveryTypes.find(item => item.del_type_id === deliveryId);
    if (!obj || !obj.hasOwnProperty("del_type_fields") || obj.del_type_fields.trim() === "") {
        return false;
    }
  handleOpenDelivery(deliveryId);
}



  const getDeliveryTypes = () => {
    api.getData('/api/pos/get_delivery_types?environment=' + process.env.REACT_APP_MY_TITLE+"&module=phone_orders")
      .then((response) => {

        if (response.data[0]?.Status === "200") {

          setDeliveryTypes(JSON.parse(response.data[0].Delivery_typeDetails));
        }


      })
      .catch((error) => {
        console.log(error)
      })



  }

  useEffect(() => {
    getDeliveryTypes();
  }, [])

  const getSegmentDetails = () => {
    api.getData('/api/pos/get_Segment_Details_pos?merchant_id=' + response.merchant_id+"&module=phone_orders")
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setSegmentDetails(JSON.parse(response.data[0].Merchant_Segement_Details));
          setFinalSegment(JSON.parse(response.data[0].Merchant_Segement_Details));
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getSubSegmentsDetails = (merchantSubSegmentId) => {
    // const data = fetchSubSegmentsDetails(response.merchant_id, 2);

    api.getData('/api/pos/get_sub_segment_Details_pos?merchant_id=' + response.merchant_id + '&merchant_segment_id=' + merchantSubSegmentId +"&module=phone_orders")
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setSubSegmentDetails(JSON.parse(response.data[0].SubSegmentDetails));
          setSubFinalSegment(JSON.parse(response.data[0].SubSegmentDetails));
        }


      })
      .catch((error) => {
        console.log(error)
      })
    // setSubSegmentDetails(data);
  }

  const getItemDetails = (segmentId, subSegmnetId) => {
    api.getData('/api/pos/get_item_Details_pos?merchant_id=' + response?.merchant_id + '&segment_id=' + segmentId + '&sub_segment_id=' + subSegmnetId +"&module=phone_orders")
      .then((response) => {

        if (response.data[0]?.Status === "200") {

          setItemDetails(JSON.parse(response.data[0].ProductDetails));

        }

      })
      .catch((error) => {
        console.log(error)
      })

  }
  const [cartItemDetails, setCartItemDetails] = React.useState();
  // const getCartByItems = (productId, cartId) => {
  //   api.getData('/api/pos/POS_get_cart_item_productid?product_id=' + productId + '&cart_id=' + cartId)
  //     .then((response) => {

  //       if (response.data[0]?.Status === "200") {

  //         setCartItemDetails(JSON.parse(response.data[0].GetCartItems));
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })

  // }

  const tabChange = (item) => {
    dispatch(setCustomersId(item.customer_id));
    POSGetPendingCustMerchantDetails(item.customer_id);
  }

  const [count, setCount] = React.useState(0);
  const POSInsertOrderDetails = () => {
   
   
    let data = {
      'invoice_id': pendingInvoiceId || '0',
      'merchant_id': response?.merchant_id || '0',
      'customer_id': customerId,
      'bazaar_id': process.env.REACT_APP_MY_TITLE === 'Vikretha' ? 0 : '1',
      'order_payload': JSON.stringify(pendingCartItem),
      'order_status': 'pending',
      'invoice_file_name': 'null',
      'delivery_id': deliveryId,
      'payment_id': '0',
      'pay_type': null,
      'order_count': count,
      'order_total_amount': pendingCartItem ? pendingCartItem[0].total_item_price : '0',
      'order_paid_amount': pendingCartItem ? pendingCartItem[0].item_final_amount : '0',
      'order_refund_amount': '0',
      'order_balance_amount': '0',
      'order_date': new Date()                                                                                      
    };

    if(data.invoice_id === null || data.invoice_id === ''){
      showSwalWithError("Invoice Id Can Not Be Null");
      return false;
    }else if(data.merchant_id === null || data.merchant_id === ''){
      showSwalWithError("Merchant Id Can Not Be Null");
      return false;
    }else if(data.customer_id === null || data.customer_id === ''){
      showSwalWithError("Customer Id Can Not Be Null");
      return false;
    }else if(data.pendingCartItem === null || data.pendingCartItem === ''){
      showSwalWithError("Pending Cart Items Can Not Be Null");
      return false;
    }else if(data.delivery_id === null || data.delivery_id === ''){
      showSwalWithError("Delivery Id Can Not Be Null");
      return false;
    }else if(data.count === null || data.count === ''){
      showSwalWithError("Order Count Can Not Be Null");
      return false;
    }
    else if(data.order_total_amount === null || data.order_total_amount === ''){
      showSwalWithError("Order Total Amount Can Not Be Null");
      return false;
    }
    else if(data.order_paid_amount === null || data.order_paid_amount === ''){
      showSwalWithError("Order Paid Amount Can Not Be Null");
      return false;
    }else if(data.order_date === null || data.order_date === ''){
      showSwalWithError("Order Date Can Not Be Null");
      return false;
    }

       if(pendingCartItem && pendingCartItem.length>0){
    api.postData('/api/pos/POS_insert_order_details', data).then((response) => {


      if (response.data[0].Status === "200") {

        dispatch(setOrderId(response.data[0].order_id));
        dispatch(setCustomersId(customerId));
        ref6.current.log();
      }else{
        showSwalWithError(response.data[0].Message || response.data.Message);
      }

    })
  }else{
    showSwalWithError('Cart Items Are Empty')
  }
  }

  useEffect(() => {
    getSegmentDetails();


  }, []);

  useEffect(() => {
    if (!searchItem && !searchProductDetails) {
      getSegmentDetails();
    }

  }, [searchProductDetails]);

  useEffect(() => {
    fetchCities();
  }, [])
  useEffect(() => {
    if ((segmentDetails || finalSegment) && !searchItem) {

      getSubSegmentsDetails(finalSegment ? finalSegment[0].merchant_segment_id : segmentDetails ? segmentDetails[0].merchant_segment_id : '0');
    }
  }, [segmentDetails, finalSegment]);

  useEffect(() => {
    if (subSegments) {
      getItemDetails(subSegments[0]?.merchant_segment_id, subSegments[0]?.merchant_sub_segment_id);
    }
  }, [subSegments])
  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);
  const ItemColumns = [
    "S.NO",
    "SKU ID",
    "ITEM NAME & DETAILS",
    "QTY",
    "PRICE",
    "TOTAL",
    "",
  ];

  const customerColumns =
    [
      {
        name: 'customer_id',
        label: 'Customer Id',
        //    label:'',

      },
      {
        name: 'name_of_the_customer', label: 'Customer Name'
      },
      { name: 'email_id', label: 'Email' },
      { name: 'phone_number', label: 'Phone' }

    ];

  const QuantityCell = ({ value, rowData }) => {
    const [counter, setCounter] = useState(value);

    const handleIncrement = () => {
      setCounter(Number(counter) + 1);
      updateDeleteQuantity(Number(counter) + 1);
    };

    const addQuantity = (value) => {
      setCounter(value);
      updateDeleteQuantity(Number(value));
    }
    const handleDecrement = () => {
      if (counter > 0) {
        setCounter(Number(counter) - 1);
        updateDeleteQuantity(Number(counter) - 1);
      }

    };

    const updateDeleteQuantity = (counter) => {
      let data = {
        'product_id': rowData?.product_id,
        'invoice_id': rowData?.invoice_id,
        'product_qty_sold': counter,
        'cart_id': rowData?.cart_id,
        'sale_price': rowData?.sale_price,
        'tax_amount': '0',
        'discount_amount': '0',
        'fee_amount': '0',
        'maintain_stock': rowData?.maintain_stock ? rowData.maintain_stock : '0'
      }
      if(data.product_id === null || data.product_id === ''){
        showSwalWithError("Product Id Can Not Be Null");
        return false;
      }else if(data.invoice_id === null || data.invoice_id === ''){
        showSwalWithError("Merchant Id Can Not Be Null");
        return false;
      }else if(data.product_qty_sold === null || data.product_qty_sold === ''){
        showSwalWithError("Product Quantity Can Not Be Null");
        return false;
      }else if(data.cart_id === null || data.cart_id === ''){
        showSwalWithError("Cart Id Can Not Be Null");
        return false;
      }else if(data.sale_price === null || data.sale_price === ''){
        showSwalWithError("Sale Price Can Not Be Null");
        return false;
      }
      api.postData('/api/pos/POS_update_delete_cart_item_quantity', data).then((response) => {


        if (response.data[0].Status === "200") {

          getCartItemProductid(pendingInvoiceId);
          // setInvoiceId(response.data[0].invoice_id);
        }

      })
    }

    useEffect(() => {

    }, [counter]);
    return (
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={handleIncrement}>+</Button>
        <InputBase
          value={counter}
          type='number'
          onChange={(e) => { addQuantity(e.target.value) }}
          className='counter-input'

        />
        <Button onClick={handleDecrement}>-</Button>
      </ButtonGroup>
    );
  };

  const deleteCartItem = (rowData) => {
    let data = {
      'product_id': rowData?.product_id,
      'product_qty_sold': rowData?.product_qty_sold,
      'cart_id': rowData?.cart_id,
      'maintain_stock': rowData?.maintain_stock ? rowData.maintain_stock : '0'
    }
    if(data.product_id === null || data.product_id === ''){
      showSwalWithError("Product Id Can Not Be Null");
      return false;
    }else if(data.product_qty_sold === null || data.product_qty_sold === ''){
      showSwalWithError("Product Quantity Can Not Be Null");
      return false;
    }else if(data.cart_id === null || data.cart_id === ''){
      showSwalWithError("Cart Id Can Not Be Null");
      return false;
    }
    api.postData('/api/pos/POS_delete_cart_product_id_item_quantity', data).then((response) => {


      if (response.data[0].Status === "200") {

        showSwalWithLink(response.data[0].Message);
        getCartItemProductid(pendingInvoiceId);
        // setInvoiceId(response.data[0].invoice_id);
      } else {
        showSwalWithError(response.data[0].Message)
      }

    })
  }
  const editCartItemColumns =
  [
    {
      name: '',
      label: 'S.NO',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        }
      }

    },
    {
      name: 'product_complete_sku', label: 'SKU ID'
    },
    { name: 'product_name', label: 'ITEM NAME & DETAILS' },
    {
      name: 'product_qty_sold',
      label: 'QTY',
    },
    { name: 'sale_price', label: 'PRICE' },
    { name: 'total_item_price', label: 'TOTAL' },
    {
      name: 'invoice_id',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = pendingCartItem ? pendingCartItem[rowIndex] : [];
          return (<img className="cross" src={cross1} onClick={() => { deleteCartItem(rowData) }} />);
        },
      },
    },

  ];
  const cartItemColumns =
    [
      {
        name: '',
        label: 'S.NO',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return tableMeta.rowIndex + 1;
          }
        }

      },
      {
        name: 'product_complete_sku', label: 'SKU ID'
      },
      { name: 'product_name', label: 'ITEM NAME & DETAILS' },
      {
        name: 'product_qty_sold',
        label: 'QTY',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowIndex = tableMeta.rowIndex;
            const rowData = pendingCartItem ? pendingCartItem[rowIndex] : [];
            return (<QuantityCell value={value} rowData={rowData} />);
          },
        },
      },
      { name: 'sale_price', label: 'PRICE' },
      { name: 'total_item_price', label: 'TOTAL' },
      {
        name: 'invoice_id',
        label: ' ',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            const rowIndex = tableMeta.rowIndex;
            const rowData = pendingCartItem ? pendingCartItem[rowIndex] : [];
            return (<img className="cross" src={cross1} onClick={() => { deleteCartItem(rowData) }} />);
          },
        },
      },

    ];
  useEffect(() => {

  }, [POSCustomersAddressDetails]);


  const customerOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.rowIndex;
      const clickedRowData = customerBasedMerchantDetails[clickedRowIndex];
      getPOSCustomersAddressDetails(clickedRowData.customer_id);
      POSGetOrderDetailsCustomers(clickedRowData.customer_id);
      // onRowClick(clickedRowData);
    },
  };

  const billColumns = [

    {
      name: '',
      label: 'S.NO',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        }
      }
    },
    {
      name: 'invoice_id', label: 'Invoice ID'
    },
    {
      name: 'order_date', label: 'Date/Time',

      // options: {
      //   customBodyRender: (value) => {
      //     const formattedDateTime = format(new Date(value), 'dd/MM/yyyy hh:mm:ss a');
      //     return formattedDateTime;
      //   }
      // }
    },
    { name: 'order_total_amount', label: 'Bill Amount' },
    { name: 'pay_type_name', label: 'Payment Mode' },
  ];

  const billData = [
    [
      1,
      <Box component="a" href="#" onClick={() => ref19.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
    [
      2,
      <Box component="a" href="#" onClick={() => ref19.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
  ];

  const itemData = [
    [1, "#98759", "Rice", 2, "$ 200", "$400"],
    [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
  ];

  const orderColumns =
    [
      {
        name: 'order_id',
        label: 'ORDER ID',
        //    label:'',

      },
      {
        name: 'city_name', label: 'CITY'
      },
      { name: 'order_date', label: 'DATE' },
      { name: 'name_of_the_customer', label: 'CUSTOMER' },
      { name: 'order_status', label: 'STATUS' }

    ];


  const driverData = [];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.dir(state);
    },
  };

  const handleOpenDelivery = (delTypeId) =>{
    // let delTypeId = posDeliverTypeId;
    // console.log('delTypeId',posDeliverTypeId);
    if(delTypeId === 5){
      ref5.current.log();
    }
    else if(delTypeId === 7){
      ref7.current.log();
     }
     else if(delTypeId === 8){
      ref8.current.log();
     }
     else if(delTypeId === 9){
      ref9.current.log();
     }
  }
  

  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };

  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      text: message,
    });
  };
  const [cartItems, setCartItems] = React.useState();
  const getCartAddItem = async (productId) => {
    try {
      const response = await api.getData('/api/pos/get_POS_cart_Product_Details?module=phone_orders&product_id=' + productId);

      if (response.data[0].Status === "200") {
        if (JSON.parse(response.data[0].ProductDetails)) {
          return JSON.parse(response.data[0].ProductDetails)[0];
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addToCart = async (item) => {
    try {
      let itemData = await getCartAddItem(item.product_id);
      console.log('itemData', itemData);

      // if (itemData && itemData.maintain_stock === 0 && itemData.product_qty_physical > 0) {
      let data = {
        'cart_id': '0',
        'invoice_id': pendingInvoiceId || '',
        'merchant_id': item.merchant_id || '',
        'customer_id': process.env.REACT_APP_MY_TITLE === 'Bazaar' ? null : customerId,
        // 'bazaar_id': process.env.REACT_APP_MY_TITLE === 'Vikretha' ? null : customerId,
        'product_id': itemData.product_id || '',
        'product_name': itemData.product_name || '',
        'product_image': itemData.product_image || '',
        'product_segment_id': '',
        'product_sub_segment_id': itemData.product_sub_segment_id || '',
        'product_master_sku': itemData.product_master_sku || '',
        'product_complete_sku': itemData.product_complete_sku || '',
        'product_metric': itemData.product_metric || '',
        'product_uom': itemData.product_uom || '',
        'no_of_units': itemData.no_of_units || '',
        'sale_price': itemData.sale_price || itemData.product_sale_price || '',
        'product_qty_sold': itemData.product_qty_physical || '',
        'discount_id': '0',
        'discount_amount': '0',
        'tax_id': '0',
        'tax_amount': '0',
        'fee_id': '0',
        'fee_amount': '0',
        'item_status': '0',
        'isbilled': '1',
        'maintain_stock': itemData?.maintain_stock || '0'
      }
      if(data.invoice_id === null || data.invoice_id === ''){
        showSwalWithError("Invoice Id Can Not Be Null");
        return false;
      }else if(data.product_qty_sold === null || data.product_qty_sold === ''){
        showSwalWithError("Product quantity Not Available");
        return false;
      }else if(data.merchant_id === null || data.merchant_id === ''){
        showSwalWithError("Merchant Id Can Not Be Null");
        return false;
      }else if(data.customer_id === null || data.customer_id === ''){
        showSwalWithError("Customer Id Can Not Be Null");
        return false;
      }
      else if(data.product_id === null || data.product_id === ''){
        showSwalWithError("Product Id Can Not Be Null");
        return false;
      }else if(data.product_name === null || data.product_name === ''){
        showSwalWithError("Product Name Can Not Be Null");
        return false;
      }else if(data.product_image === null || data.product_image === ''){
        showSwalWithError("Product Image Can Not Be Null");
        return false;
      }
      else if(data.product_image === null || data.product_image === ''){
        showSwalWithError("Product Image Can Not Be Null");
        return false;
      }else if(data.product_segment_id === null || data.product_segment_id === ''){
        showSwalWithError("Product Segment Can Not Be Null");
        return false;
      }
      const response = await api.postData('/api/pos/POS_create_update_cart_item', data);

      if (response.data[0].Status === "200") {
        setCartItems(response.data[0].Cart_Items);
        showSwalWithLink(response.data[0].Message);
        getCartItemProductid(pendingInvoiceId);
      }
      else {
        showSwalWithError(response.data[0].Message);
      }

    } catch (error) {
      console.log(error);
    }
  };
  const [orderCustomerData, setOrderCustomerData] = React.useState();
  const orderOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.dataIndex;
      const clickedRowData = orderData[clickedRowIndex];
      getOrderById(clickedRowData.invoice_id);
      getCartItemsOrderid(clickedRowData.invoice_id);
      // getPOSCustomersaAddressDetails(clickedRowData.invoice_id);
      // POSGetOrderDetailsCustomers(clickedRowData.invoice_id);

      // onRowClick(clickedRowData);
    },
  };

  const itemOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.rowIndex;

      // onRowClick(clickedRowData);
    },
  };
  const [value, setValue] = React.useState(0);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const timeFormate = (dateTimes) => {
    const dateTimeString = dateTimes;
    const dateTime = new Date(dateTimeString);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    return dateTime.toLocaleString('en-US', options);
  }

  const [logo, setLogo] = React.useState();
  React.useEffect(() => {
    // Update favicon based on condition
    if (process.env.REACT_APP_MY_TITLE === 'Bazaar') {
      setLogo(process.env.REACT_APP_MY_LOGO_URL);
    } else if (process.env.REACT_APP_MY_TITLE === 'Vikretha') {
      setLogo(process.env.REACT_APP_MY_LOGO_URL_VIKRETA);
    }
  }, []);

  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref15 = useRef();
  const ref6 = useRef();
  const ref17 = useRef();
  const ref18 = useRef();
  const ref19 = useRef();
  const ref5 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  useEffect(() => {

  }, [deliveryTypes]);
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />
                  <Box>
                    <Typography component="h2">Phone Orders {pos}</Typography>
                  </Box>
                  <Box className="icons">
                    <Box
                      component="img"
                      src={customers}
                      onClick={() => ref.current.log()}
                    />

                    <Typography component="h5" ml={1}>
                      {response?.merchant_business_name}
                    </Typography>
                    <Box component="img" src={logout} ml={2} onClick={logoutDashboard} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box className="w-100 sub-div flex-between plr-15">
              <Box className="flex ml-20">
                <Box className="flex color-white"onClick={navigateDashboard} style={{cursor:'pointer'}}>
                  <DashboardIcon />
                  <Typography component="h4" ml={1}>
                    Dashboard
                  </Typography>
                </Box>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="ml-20 location-select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  onChange={(e) => setLocationId(e.target.value)}
                  defaultValue={posLocation}
                >
                  {/* <MenuItem value="locations">Location</MenuItem>
                  <MenuItem>MVP</MenuItem>
                  <MenuItem>CBM</MenuItem>
                  <MenuItem>RK Beach</MenuItem> */}
                  {
                    location?.map((item) => {
                      return (<MenuItem value={item.merchant_location_id}>{item.location_name}</MenuItem>)
                    })
                  }
                </Select>
              </Box>
              <Box className="d-flex w-46">
                <Box>
                  <Box className="w-100 flex align-center">
                    <FormControl className="flex">
                      <Box className="code">+91</Box>
                      <OutlinedInput
                        placeholder=""
                        className="custom-ml"
                        onBlur={(e) => handleChangePhone(e)}
                      />
                    </FormControl>

                    <Button
                      variant="outlined"
                      color="error"
                      mt={2}
                      mb={3}

                      className="retail-btn ml-20 height-22 bg-white hover"
                      onClick={() => getSearchPhoneDetails()}
                    >
                      Search
                    </Button>
                    <Box
                      ml={4}
                      src={addPerson}
                      component="img"
                      className="add-img"
                      onClick={() => ref17.current.log()}
                    />
                  </Box>
                </Box>
                <FullscreenIcon className="fullscreen mr-20" />
              </Box>
            </Box>
            <Box className="w-100 pos-cardcontent">
              <Grid container className="full_content">
                <Box className="main-bg" sx={{ flexGrow: 1, display: "flex" }}>
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                    className="vertical-tabs"
                  >
                    <Tab
                      label="BILLING"
                      {...a11yProps(0)}
                      onClick={() => changeTitle("Billing")}
                    />
                    <Tab
                      label="CUSTOMERS"
                      {...a11yProps(1)}
                      onClick={() => changeTitle("Customers")}
                    />
                    <Tab
                      label="ORDERS"
                      {...a11yProps(2)}
                      onClick={() => changeTitle("Orders")}
                    />
                  </Tabs>
                  <TabPanel value={value} index={0} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box
                          className="w-100 d-flex align-center flex-between mt-6"
                          mb={2}

                        >
                          {/* <SearchBar className="search-bar" 
                          
                          // onChange={(e)=>{handleSearchValue(e)}}
                          onRequestSearch={(e) => getSearchDetails(e.target.value)} 
                          /> */}
                          <Search className="search-pos">
                            <SearchIconWrapper>
                              <SearchIcon />
                            </SearchIconWrapper>
                            <SearchInputBase
                              placeholder="Search…"
                              inputProps={{ 'aria-label': 'search' }}
                              onChange={(e) => getSearchDetails(e.target.value)}
                            />
                          </Search>
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={3}
                            className="retail-btn float-right posmain-btn"
                            onClick={() => ref15.current.log()}
                          >
                            <Box
                              src={upscan}
                              component="img"
                              className="upscan"
                            />
                            <Box component="span">SCAN</Box>
                          </Button>
                        </Box>
                        <Box mb={3} mt={3}>
                          <Carousel
                            swipeable={false}
                            draggable={false}
                            // showDots={true}
                            responsive={responsive}

                            ssr={true} // means to render carousel on server-side.
                            //   infinite={true}
                            //   autoPlay={props.deviceType !== "mobile" ? true : false}
                            //   autoPlaySpeed={1000}
                            keyBoardControl={true}
                            // customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            //   deviceType={props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                          >
                            {
                              finalSegment ? finalSegment?.map((item) => {
                                return <div className="custom-slider">
                                  <Card sx={{ maxWidth: 110 }} style={{ cursor: 'pointer' }} onClick={() => { getSubSegmentsDetails(item.merchant_segment_id) }} >
                                    <CardContent style={{ display: 'flex', flexFlow: 'column' }} >
                                      <Box src={item.merchant_segment_image} component="img" className="slide-img" />
                                      <Typography gutterBottom variant="small" component="small">
                                        {item.merchant_segment_name}
                                      </Typography>

                                    </CardContent>

                                  </Card>
                                </div>
                              }) : <div className="custom-slider">
                                <Card sx={{ maxWidth: 110 }}>
                                  <CardContent style={{ display: 'flex', flexFlow: 'column' }}>
                                    <Box src={dairy} component="img" className="slide-img" />
                                    <Typography gutterBottom variant="small" component="small">
                                      Groceries
                                    </Typography>

                                  </CardContent>

                                </Card>
                              </div>
                            }

                          </Carousel>
                        </Box>
                        <Box sx={{ width: "100%" }} className="items-div">
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={itemValue}
                              onChange={handleChangeItems}
                              aria-label="basic tabs example"
                              className="multitabs"
                            >


                              {
                                finalSubSegment?.map((item, index) => {
                                  return <Tab label={item.merchant_sub_segment_name} {...a11yProps(index)} onClick={() => getItemDetails(item.merchant_segment_id, item.merchant_sub_segment_id)} />
                                })
                              }


                              {/* <Tab label="OIL & GHEE" {...a11yProps(1)} />
                              <Tab label="RICE" {...a11yProps(2)} />
                              <Tab label="DALS&PULSES" {...a11yProps(3)} />
                              <Tab label="MASALA" {...a11yProps(4)} />
                              <Tab label="BODY CARE" {...a11yProps(5)} />
                              <Tab label="DAIRY" {...a11yProps(6)} />
                              <Tab label="MASALA" {...a11yProps(7)} /> */}
                            </Tabs>
                          </Box>
                          {
                            finalSubSegment?.map((item, index) => {
                              return <TabPanel value={itemValue} index={index}>
                                <Items itemdata={itemDetails} getCartItemProductid={getCartItemProductid} pendingInvoiceId={pendingInvoiceId} />
                              </TabPanel>
                            })
                          }


                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={values}
                              onChange={handleChanges}
                              aria-label="basic tabs example"
                              className="pos-billing"
                            >

                              {/* {pendingInvoices?.map((item, index) => {
                                {
                                  console.log('item', item)
                                }
                                return <Tab label={item.invoice_id} {...a11yProps(index)} />
                              })
                              } */}

                              {pendingInvoices?.map((item, index) => {
                                return (
                                  <div style={{ position: 'relative' }}>
                                    <Tab label={item.invoice_id} {...a11yProps(index)} onClick={() => { setTabIndex(index); tabChange(item); getCartItemProductid(item.invoice_id) }} />

                                    <HighlightOffIcon style={{ position: 'absolute', top: '-12px', right: 0, fontSize: '17px', cursor: 'pointer' }}
                                      onClick={() => { POSAbortedInvoicecartDetails(item) }} />

                                  </div>
                                );
                              })}


                              {/* <Tab label="Billing 2" {...a11yProps(1)} />
                              <Tab label="Billing 3" {...a11yProps(2)} /> */}
                            </Tabs>
                          </Box>
                          {pendingInvoices ? pendingInvoices?.map((item, index) => {
                            return <TabPanel
                              value={tabIndex}
                              index={index}
                              className="bg-white  p-15"
                            >
                              <Box className="w-100 flex-between" mb={2} mt={1}>
                                <Typography
                                  component="h4"
                                  className="tab-sub-title"
                                >
                                  {item.invoice_id}
                                </Typography>
                                <Typography component="label">
                                  {
                                    dayjs(item.billing_start_time).format('MMMM D, YYYY HH:mm A')
                                    // format(new Date(), 'yyyy/MM/dd kk:mm:ss')
                                  }
                                </Typography>
                              </Box>
                              {/* <Box className="w-100 flex align-center" mb={2}>
                                <FormControl className="flex">
                                  <Box className="code">+91</Box>
                                  <OutlinedInput
                                    placeholder=""
                                    className="custom-ml"
                                    onBlur={(e) => handleChangePhone(e)}
                                  />
                                </FormControl>

                                <Button
                                  variant="outlined"
                                  color="error"
                                  mt={2}
                                  mb={3}
                                  className="retail-btn ml-20 height-22"
                                  onClick={() => getSearchPhoneDetails()}
                                >
                                  Search
                                </Button>
                                <Box
                                  ml={4}
                                  src={addPerson}
                                  component="img"
                                  className="add-img"
                                  onClick={() => ref7.current.log()}
                                />

                              </Box> */}
                              {
                                customerData ?

                                  <Box className="w-100 flex flex-between order-values">
                                    <Box className="information-div">
                                      <Box component="img" src={customer} />
                                      <Box>
                                        <Typography component="label">
                                          Name
                                        </Typography>
                                        <Typography component="h5">
                                          {customerData?.name_of_the_customer}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box className="information-div">
                                      <Box component="img" src={mobile} />
                                      <Box>
                                        <Typography component="label">
                                          Mobile
                                        </Typography>
                                        <Typography component="h5">
                                          {customerData?.phone_number}
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box className="information-div">
                                      <Box component="img" src={mail} />
                                      <Box>
                                        <Typography component="label">
                                          Email
                                        </Typography>
                                        <Typography component="h5">
                                          {customerData?.email_id}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box> : ""}
                              <Box
                                className="w-100 flex flex-between order-values sub-order"
                                mt={2}
                              >

                                {
                                  deliveryTypes?.map((item, index) => {
                                    return <Box className={doorIndex === index ? "information-div active" : "information-div"} onClick={() => { setDoorIndex(index);setDeliveryIds(item.del_type_id); }}>
                                      <Box component="img" src={item.icon} />
                                      <Box>
                                        <Typography component="h5">

                                          {item.del_type}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  })
                                }
                              </Box>

                              <Box className="w-100" mt={2}>
                                <Box mb={1} mt={1} className="w-100">
                                  <Search className="search-pos">
                                    <SearchIconWrapper>
                                      <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                      placeholder="Search…"
                                      inputProps={{ 'aria-label': 'search' }}
                                      onChange={(e) => getCartDetails(e.target.value)}
                                    />
                                  </Search>
                                  <List>
                                    {searchMerchantItems?.map((item, index) => {
                                      return <ListItem key={item.product_id} style={{ background: '#eeeeee69', marginBottom: '2px' }}>
                                        <ListItemButton onClick={() => addToCart(item)}>
                                          <Box className="flex-between w-100"><Box className="d-flex"><Typography component="p" mr={2}>{item.product_master_sku}</Typography>  <Box style={{ width: '30px' }} src={item.product_image} mr={2} component='img' />  <Typography component="p">{item.product_name}</Typography></Box> <Button variant='outlined' className='retail-btn float-right'>Add to Cart</Button> </Box>
                                        </ListItemButton>
                                      </ListItem>
                                    })}
                                  </List>
                                </Box>
                                <MemberTable
                                  options={itemOptions}
                                  columns={cartItemColumns}
                                  data={cartitem ? cartitem : []}
                                />
                              </Box>

                              <Box className="flex-between bg-light px-10" mt={2}>
                                <Typography component="p">
                                  No Of Items : {cartTotals ? cartTotals[0].no_of_items : ''}
                                </Typography>
                                <Typography component="p" className="flex">
                                  Grand Total <b className="ml-20">{response?.country_currency_symbol} {cartTotals ? cartTotals[0].item_final_amount : ''} /-</b>
                                </Typography>
                                <Tooltip
                                  className="tooltip"
                                  title={
                                    <React.Fragment>
                                      <Typography component="label">
                                        Sub Total -{response?.country_currency_symbol} {cartTotals ? cartTotals[0].total_item_price : ''}
                                      </Typography>
                                      <Typography component="label">
                                        Tax - {response?.country_currency_symbol} {cartTotals ? cartTotals[0].tax_amount : ''}
                                      </Typography>
                                      <Typography component="label">
                                        Gst - {response?.country_currency_symbol} {cartTotals ? cartTotals[0].tax_amount : ''}
                                      </Typography>
                                      <Typography component="label">
                                        Discount -{response?.country_currency_symbol} {cartTotals ? cartTotals[0].discount_amount : ''}
                                      </Typography>
                                      <Typography component="p">
                                        <b>Grand Total - {response?.country_currency_symbol} {cartTotals ? cartTotals[0].item_final_amount : ''}</b>
                                      </Typography>
                                    </React.Fragment>
                                  }
                                  placement="left"
                                  arrow
                                >
                                  <Box component="img" src={info} />
                                </Tooltip>
                              </Box>
                              <Box mt={2} className="flex-around pos-btns">
                                <Button
                                  variant="outlined"
                                  color="error"
                                  className="retail-btn "
                                  onClick={() => deleteCartDetails(item.invoice_id)}

                                >
                                  <Box src={clear} component="img" />
                                  <Typography component="span"> Clear</Typography>
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  className="retail-btn "
                                  onClick={() => ref4.current.log()}
                                >
                                  {" "}
                                  <Box src={discount} component="img" />
                                  <Typography component="span">
                                    Discount
                                  </Typography>
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  className="retail-btn "
                                  onClick={() => { POSInsertOrderDetails(); }}
                                // onClick={() => { ref6.current.log() }}

                                >
                                  <Box src={invoice} component="img" />
                                  <Typography component="span">
                                    Place Order
                                  </Typography>
                                </Button>
                              </Box>
                            </TabPanel>
                          }) : <TabPanel
                            // value={1}
                            // index={1}
                            className="bg-white  p-15"
                          >
                            <Box className="w-100 flex-between" mb={2} mt={1}>
                              <Typography
                                component="h4"
                                className="tab-sub-title"
                              >

                              </Typography>
                              <Typography component="label">

                              </Typography>
                            </Box>
                            {/* <Box className="w-100 flex align-center" mb={2}>
                              <FormControl className="flex">
                                <Box className="code">+91</Box>
                                <OutlinedInput
                                  placeholder="9876543210"
                                  className="custom-ml"
                                  onBlur={(e) => handleChangePhone(e)}
                                />
                              </FormControl>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                mb={3}
                                className="retail-btn ml-20 height-22"
                                onClick={() => getSearchPhoneDetails()}
                              >
                                Search
                              </Button>
                              <Box
                                ml={4}
                                src={addPerson}
                                component="img"
                                className="add-img"
                                onClick={() => ref7.current.log()}
                              />
                            </Box> */}


                            <Box className="w-100 flex flex-between order-values">
                              <Box className="information-div">
                                <Box component="img" src={customer} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography component="h5">
                                    {customerData ? customerData.name_of_the_customer : '*******'}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography component="h5">
                                    {customerData ? customerData.phone_number : '+91******'}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div">
                                <Box component="img" src={mail} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography component="h5">
                                    {customerData ? customerData.email_id : '*****@*** ***'}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              className="w-100 flex flex-between order-values sub-order"
                              mt={2}
                            >

                              {
                                deliveryTypes?.map((item, index) => {
                                  return <Box className={doorIndex === index ? "information-div active" : "information-div"} onClick={() => { setDoorIndex(index);setDeliveryIds(item.del_type_id); }}>
                                    <Box component="img" src={item.icon} />
                                    <Box>
                                      <Typography component="h5">

                                        {item.del_type}
                                      </Typography>
                                    </Box>
                                  </Box>
                                })
                              }
                            </Box>

                            <Box className="w-100" mt={2}>
                              <Box mb={1} mt={1} className="w-100">
                                <Search className="search-pos">
                                  <SearchIconWrapper>
                                    <SearchIcon />
                                  </SearchIconWrapper>
                                  <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) => getCartDetails(e.target.value)}
                                  />
                                </Search>
                                <List>
                                  {searchMerchantItems?.map((item, index) => {
                                    return <ListItem key={item.product_id} style={{ background: '#eeeeee69', marginBottom: '2px' }}>
                                      <ListItemButton onClick={() => addToCart(item)}>
                                        <Box className="flex-between w-100">
                                          <Box style={{ width: '30px' }} src={item.product_image} component='img' />
                                          <Typography>
                                            {item.product_name}
                                          </Typography>
                                          <Typography component="p">{item.product_quantity}</Typography>
                                          <Button variant="contained">Add To Cart</Button>
                                        </Box>
                                      </ListItemButton>
                                    </ListItem>
                                  })}
                                </List>
                              </Box>
                              <MemberTable
                                options={itemOptions}
                                columns={cartItemColumns}
                                data={cartitem ? cartitem : []}
                              />
                            </Box>

                            <Box className="flex-between bg-light px-10" mt={2}>
                              <Typography component="p">
                                No Of Items : {pendingCartItems ? pendingCartItems[0].product_qty_sold : ''}
                              </Typography>
                              <Typography component="p" className="flex">
                                Grand Total <b className="ml-20"> {pendingCartItems ? pendingCartItems[0].total_item_price : ''} /-</b>
                              </Typography>
                              <Tooltip
                                className="tooltip"
                                title={
                                  <React.Fragment>
                                    <Typography component="label">
                                      Sub Total - $ 95.00
                                    </Typography>
                                    <Typography component="label">
                                      Tax - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Gst - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Discount - $ 15.00
                                    </Typography>
                                    <Typography component="p">
                                      <b>Grand Total - $ 15.00</b>
                                    </Typography>
                                  </React.Fragment>
                                }
                                placement="left"
                                arrow
                              >
                                <Box component="img" src={info} />
                              </Tooltip>
                            </Box>
                            <Box mt={2} className="flex-around pos-btns">
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={() => deleteCartDetails()}
                              >
                                <Box src={clear} component="img" />
                                <Typography component="span"> Clear</Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={() => ref4.current.log()}
                              >
                                {" "}
                                <Box src={discount} component="img" />
                                <Typography component="span">
                                  Discount
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={() => { POSInsertOrderDetails();}}
                              >
                                <Box src={invoice} component="img" />
                                <Typography component="span">
                                  Place Order
                                </Typography>
                              </Button>
                            </Box>
                          </TabPanel>
                          }
                        </Box>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Card>
                          <CardContent className="p-15">
                            <Box className="w-100 " mt={2}>

                              <MemberTable
                                options={customerOptions}
                                columns={customerColumns}
                                data={customerBasedMerchantDetails ? customerBasedMerchantDetails : []}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Card className="bill-pos">
                          <CardContent>
                            <Grid container spacing={4}>
                              <Grid item md={3}>
                                <Box className="w-100">
                                  <Box
                                    src={POSCustomersAddressDetails?.profile_pic ? POSCustomersAddressDetails?.profile_pic : walmart}
                                    style={{ width: "150px" }}
                                    component="img"
                                  />
                                </Box>
                              </Grid>

                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Customer Name
                                  </Typography>
                                  <Typography component="p">
                                    {POSCustomersAddressDetails?.name_of_the_customer}
                                  </Typography>
                                </Box>
                                <Box mt={2}>
                                  <Typography component="label">
                                    Address
                                  </Typography>
                                  <Typography component="p">
                                    {POSCustomersAddressDetails?.a[0]?.cust_address}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Contact No
                                  </Typography>
                                  <Typography component="p">
                                    +{POSCustomersAddressDetails?.phone_number}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Email Id
                                  </Typography>
                                  <Typography component="p">
                                    {POSCustomersAddressDetails?.email_id}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="w-100 " mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={billColumns}
                                data={customerOrderDeatails ? customerOrderDeatails : []}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={2} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent className="p-15">
                            <Box className="flex w-100 flex-between" mt={2}>
                              <FormControl className="flex">
                                <Typography component="label"> Show</Typography>
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="ml-20 location-select ws-80"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="locations"
                                >
                                  <MenuItem value="locations">10</MenuItem>
                                  <MenuItem>One</MenuItem>
                                  <MenuItem>Two</MenuItem>
                                  <MenuItem>Three</MenuItem>
                                </Select>
                              </FormControl>
                              <Box>
                                <OutlinedInput
                                  placeholder="Search"
                                  className="search w-130"
                                />

                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="ml-20 location-select w-130"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="locations"
                                >
                                  <MenuItem value="locations">
                                    In Progress
                                  </MenuItem>
                                  <MenuItem>One</MenuItem>
                                  <MenuItem>Two</MenuItem>
                                  <MenuItem>Three</MenuItem>
                                </Select>
                              </Box>
                            </Box>

                            <Box className="w-100 " mt={2}>
                              <MemberTable
                                options={orderOptions}
                                columns={orderColumns}
                                data={orderData ? orderData : []}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          {/* <CardContent className="p-15 custom-edit"> */}
                          <CardContent className="p-15">

                            <Box className="w-100 flex-between" mb={2} mt={2}>
                              <Typography component="h4">
                                {orderCustomerDetails && orderCustomerDetails[0].invoice_id}
                              </Typography>
                              <Typography component="h4">
                                {orderCustomerDetails && orderCustomerDetails[0].invoice_id}
                              </Typography>
                            </Box>

                            <Box className="w-100 flex flex-between order-values orders">
                              <Box className="information-div">
                                <Box component="img" src={customer} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography component="h5">
                                    {orderCustomerDetails && orderCustomerDetails[0].name_of_the_customer}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div align-center">
                                <Box
                                  component="img"
                                  src={mobile}
                                  className="border-50"
                                />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography component="h5">
                                    {orderCustomerDetails && orderCustomerDetails[0].phone_number}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div align-center">
                                <Box
                                  component="img"
                                  src={mail}
                                  className="border-50"
                                />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography component="h5">
                                    {orderCustomerDetails && orderCustomerDetails[0].email_id}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div align-center">
                                <Box
                                  component="img"
                                  src={mail}
                                  className="border-50"
                                />
                                <Box>
                                  <Typography component="label">
                                    Delivery Type
                                  </Typography>
                                  {
                                    console.log('deliveryAddressDetails', deliveryAddressDetails)
                                  }
                                  <Typography component="h5">
                                    Curbside Pickup
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              mt={2}
                              mb={2}
                              className="flex-between pos-btns pos-orders-btns"
                            >
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={print} component="img" />
                                <Typography component="span">
                                  {" "}
                                  Print Invoice
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={() => ref18.current.log()}
                              >
                                {" "}
                                <Box src={edit} component="img" />
                                <Typography component="span">Submit</Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                              >
                                <Typography component="span">Add</Typography>
                              </Button>
                            </Box>
                            <Box
                              className="member-table scroll-editabletable"
                              mt={2}
                            >
                            
                              {/* <MemberTable options={itemOptions} columns={ItemColumns} data={itemData} /> */}
                              
                                {/* <EditTable defaultData={defaultData} /> */}
                                <MemberTable
                                  options={itemOptions}
                                  columns={editCartItemColumns}
                                  data={cartItemOrders ? cartItemOrders : []}
                                />
                              

                            </Box>
                            <Box className="grand-total">
                              <Typography component="p">
                                No Of Items :{cartTotalsOrders ? cartTotalsOrders[0].no_of_items : ''}
                              </Typography>
                              <Box>
                                <Typography component="p">
                                  Sub Total : {cartTotalsOrders ? cartTotalsOrders[0].total_item_price : ''}
                                </Typography>
                                <Typography component="p">
                                  Discount: {cartTotalsOrders ? cartTotalsOrders[0].discount_amount : ''}
                                </Typography>
                                <Typography component="p">
                                  Tax: {cartTotalsOrders ? cartTotalsOrders[0].tax_amount : ''}
                                </Typography>
                                <Typography component="p">
                                  Grand Total : {cartTotalsOrders ? cartTotalsOrders[0].item_final_amount : ''}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>

                      </Grid>
                    </Grid>
                  </TabPanel>
                </Box>
              </Grid>
            </Box>

            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <ProfileModal ref={ref} />
        <CustomerDetailsModal ref={ref17} />
        <DeliveryModal ref={ref5} deliveryAddressDetails={deliveryAddressDetails} deliveryTypes={deliveryTypes} />
        <CurbModal ref={ref9} deliveryAddressDetails={deliveryAddressDetails} deliveryTypes={deliveryTypes} />
        <StorePickupModal ref={ref7} deliveryAddressDetails={deliveryAddressDetails} deliveryTypes={deliveryTypes} />
        <InStoreModal ref={ref8} deliveryAddressDetails={deliveryAddressDetails} deliveryTypes={deliveryTypes} />
        <DiscountModal ref={ref4} />
        <ScanModal ref={ref15} />
        <InvoiceDetailsModal ref={ref6} orderId={orderId} customerData={customerData} customerId={customerId} getCartItemProductid={getCartItemProductid} getPosPendingInvoices={getPosPendingInvoices} />
        <EditProductModal ref={ref18} />
        <CustomerInvoiceDetailsModal ref={ref19} />
      </Box>
    </>
  );
}
