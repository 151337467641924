import React,{useRef,useEffect} from "react";
import { Box, Button, Typography, Grid, Card, CardContent,CardMedia, CardActions, MenuItem, OutlinedInput, FormControl } from "@mui/material";
import shoppingcounter from '../images/shopping-counter.png';
import p2 from '../images/p2.png';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddToCartModal from "./AddToCartModal";
import api from "../Api";
import { useDispatch, useSelector } from "react-redux";


export default function Items(props) {
    const ref7=useRef();
    const [cartDetails,setCartDetails] = React.useState();
    const [uomDetails,setUomDetails] = React.useState();
    const resp = useSelector(state => state.user.user);
    const productId = useSelector(state => state.user.product_id);
    let invoiceId = useSelector(state => state.user.pos_invoice_id);

    const callGetCartItems = ()=>{
      
      props.getCartItemProductid(invoiceId);
    }
    const getCartDetails = (productId) => {
      api.getData('/api/pos/get_POS_cart_Product_Details?module=phone_orders&product_id='+productId)
        .then((response) => {
  
          if (response.data[0]?.Status === "200") {
            if(JSON.parse(response.data[0].ProductDetails != null)){
            setCartDetails(JSON.parse(response.data[0].ProductDetails)[0]);
            }
          }
  
  
        })
        .catch((error) => {
          console.log(error)
        })
  
  
    }
    const getUomDetails = (productId) => {
        api.getData('/api/pos/get_POS_cart_PRODUCT_UOMs?product_id='+productId)
          .then((response) => {
    
            if (response.data[0]?.Status === "200") {
    
              setUomDetails(JSON.parse(response.data[0].ProductDetails));
            }
    
    
          })
          .catch((error) => {
            console.log(error)
          })
    
    
      }
      // useEffect(()=>{
      //   getCartDetails();
      // },[])
    // useEffect(()=>{
    //     getUomDetails();
    // },[productId])
    return (
        < Grid container spacing={3} className="items" >
         
            {props.itemdata?.map((item)=>{
              
           
           return <Grid item md={4}>
                <Card className="pos-card">
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        image={item.product_image}
                        className="card-img"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="p">
                            {item.product_name}
                        </Typography>
                        <Box className="flex flex-between align-center">
                            <Typography component='span' className="text-danger">Rs {item.product_price}/-</Typography>
                            <Typography component='span' className="small">{item.no_of_units}{item.product_uom}</Typography>
                        </Box>
                        <Box className="text-center">
                            <Button variant="contained" onClick={() =>{getCartDetails(item.product_id);getUomDetails(item.product_id); ref7.current.log()}}><ShoppingCartIcon />ADD TO Cart</Button>
                        </Box>
                    </CardContent>
                    <CardActions className="card-footer">
                        <Typography component='span'>

                            <Box component='img' src={shoppingcounter}></Box>
                           {resp?.merchant_business_name}
                        </Typography>
                    </CardActions>
                </Card>
            </Grid>
              })           
            }
            <AddToCartModal ref={ref7} posDeliverTypeId={props.posDeliverTypeId} cartDetails={cartDetails} tableNumberId={props?.tableNumberId} uomDetails={uomDetails} callGetCartItems={callGetCartItems} invoiceId={invoiceId} />
        </Grid >
    )
}