import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  Breadcrumbs,
  Link,
  Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardActions from "@mui/material/CardActions";
import SearchBar from "material-ui-search-bar";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import shoppingcounter from "../images/shopping-counter.png";
import logo from "../images/logo-white.png";
import GroupedButtons from "./GroupedButtons";
import globe from "../images/globe.png";
import cart from "../images/cart.svg";
import notification from "../images/notification.png";
import profile from "../images/user.png";
import back from "../images/back.png";
import SwiperSlider from "./SwiperSlider";
import store from "../images/store.png";
import delivery from "../images/delivery-time.png";
import Caurosel from "./Caurosel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductView() {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);
  const ItemColumns = [
    "S.NO",
    "SKU ID",
    "ITEM NAME & DETAILS",
    "QTY",
    "PRICE",
    "TOTAL",
    "",
  ];

  const customerColumns = ["Customer ID", "Customer Name", "Email", "Phone"];

  const customerData = [
    ["#258974", "Akhil", "akhil.@gmail.com", "9478982317"],
    ["#258974", "Suresh", "suresh.@gmail.com", "8327641236"],
  ];

  const billColumns = [
    "S.NO",
    "Invoice Id",
    "Date/Time",
    "Bill Amount",
    "Payment Mode",
  ];

  const billData = [
    [
      1,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
    [
      2,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
  ];

  const itemData = [
    [1, "#98759", "Rice", 2, "$ 200", "$400"],
    [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
  ];

  const orderColumns = ["ORDER ID", "CITY", "DATE", "CUSTOMER", "STATUS"];

  const orderData = [
    [
      "#258974",
      "Visakhapatnam",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "Completed",
    ],
    [
      "#258974",
      "Rajahmundry",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "In Progress",
    ],
  ];

  const driverData = [];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [value, setValue] = React.useState(1);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main" className="main-section">
        <Grid container>
          <Grid item xs="12">
            <Box className="w-100 text-center h-50">
              <Typography component="p">
                Shop Wholesale online from 85,000 brands
              </Typography>
            </Box>
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />
                  <Box>
                    <SearchBar className="search-bar" />
                  </Box>
                  <Box className="icons bredcum-icons">
                    <Box
                      component="img"
                      src={globe}
                      onClick={() => ref.current.log()}
                    />

                    <Typography paddingRight={1} component="h5" ml={1}>
                      EN-GB |
                    </Typography>
                    <ShoppingCartIcon />
                    <NotificationsIcon />

                    <PersonIcon onClick={() => ref.current.log()} />
                    {/* <Button variant="filled" className="invite-supplier-btn">
                      INVITE SUPPLIER
                    </Button> */}
                  </Box>
                </Box>
                <Box className="breadcrumb-sec">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="/Procurement/Bazaar"
                    >
                      <Box component="img" src={back} />
                    </Link>
                    <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link>
                    <Typography color="text.primary">
                      Product Detail View
                    </Typography>
                  </Breadcrumbs>
                </Box>
              </Grid>
            </Grid>
            <Box padding={5}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Grid
                    container
                    className="text-center"
                    justifyContent={"center"}
                  >
                    <Grid item md={8}>
                      <Box className="w-100 p-15 custom-swiper-slider">
                        <SwiperSlider />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Accordion className="product-detail-accordion">
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordian-header"
                        >
                          <Typography className="accordion-head">
                            Instructions
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="p-0 pe-3">
                          <Box padding={2}>
                            <Typography
                              component="p"
                              className="accordion-data"
                            >
                              Lorem ipsum dolor sit amet, consetetur sadipscing
                              elitr, sed diam nonumy eirmod tempor invidunt ut
                              labore et dolore magna aliquyam erat. At vero eos
                              et accusam et justo duo dolores et ea rebum. Stet
                              clita kasd gubergren.On the other hand, we
                              denounce with righteous indignation and dislike
                              men who are so beguiled and demoralized by the
                              charms of pleasure of the moment, so blinded by
                              desire.
                            </Typography>
                          </Box>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="product-detail-accordion">
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordian-header"
                        >
                          <Typography className="accordion-head">
                            Specifications
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="p-0 pe-3">
                          <Box padding={2}>
                            <Typography
                              component="p"
                              className="accordion-data"
                            >
                              Lorem ipsum dolor sit amet, consetetur sadipscing
                              elitr, sed diam nonumy eirmod tempor invidunt ut
                              labore et dolore magna aliquyam erat. At vero eos
                              et accusam et justo duo dolores et ea rebum. Stet
                              clita kasd gubergren.On the other hand, we
                              denounce with righteous indignation and dislike
                              men who are so beguiled and demoralized by the
                              charms of pleasure of the moment, so blinded by
                              desire.
                            </Typography>
                          </Box>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion className="product-detail-accordion">
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="accordian-header"
                        >
                          <Typography className="accordion-head">
                            Offers
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="p-0 pe-3">
                          <Box padding={2}>
                            <Typography
                              component="p"
                              className="accordion-data"
                            >
                              Lorem ipsum dolor sit amet, consetetur sadipscing
                              elitr, sed diam nonumy eirmod tempor invidunt ut
                              labore et dolore magna aliquyam erat. At vero eos
                              et accusam et justo duo dolores et ea rebum. Stet
                              clita kasd gubergren.On the other hand, we
                              denounce with righteous indignation and dislike
                              men who are so beguiled and demoralized by the
                              charms of pleasure of the moment, so blinded by
                              desire.
                            </Typography>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Typography className="merchant-name-head" component="h1">
                    Chicken Shahi Biryani
                  </Typography>
                  <Typography
                    className="skuid"
                    component="p"
                    paddingY={1}
                    mt={1}
                  >
                    @ Zeeshan Apna Hyderabadi
                  </Typography>
                  <Typography component="p" className="skuid" paddingY={1}>
                    SKUID : TAT-SA1KG-GRO
                  </Typography>
                  <Box className="flex" mt={1}>
                    <Typography className="skuid">Rs 50</Typography>
                    <Stack spacing={1}>
                      <Rating
                        name="half-rating-read"
                        defaultValue={4.0}
                        precision={0.5}
                        readOnly
                      />
                    </Stack>
                    <Typography component="span" className="badge bg-success">
                      4.0
                    </Typography>
                  </Box>

                  <Typography className="about-merchant" component="h4">
                    Indian authentic flavours with no added flavours. best in
                    quality & premium taste.Indian authentic flavours with no
                    added flavours. best in quality & premium taste.
                  </Typography>
                  <Box class="pro-view-qantity" mt={1}>
                    <Button variant="outlined" className="active">
                      500 gms
                    </Button>
                    <Button variant="outlined">100 gms</Button>
                    <Button variant="outlined">250 gms</Button>
                    <Button variant="outlined">500 gms</Button>
                    <Button variant="outlined">1 KG</Button>
                  </Box>
                  <Box pt={1}>
                    <FormControl className="">
                      <Typography component="label" className="dropdown-label">
                        Flavour
                      </Typography>
                      <Select
                        labelId="demo-isolation-label"
                        id="isolation-select"
                        className="select viewmore-dpdown-detail"
                        // onChange={handleSelectOxygen}
                        // input={<OutlinedInput />}
                        defaultValue="Suppliers"
                      >
                        <MenuItem value="Suppliers">Select</MenuItem>
                        <MenuItem value="car">Original</MenuItem>
                        <MenuItem value="van">2nd Quality</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box className="flex" mt={2}>
                    <GroupedButtons />

                    <FormControl className="flex ml-20">
                      <OutlinedInput placeholder="promo code" />
                      <Box className="pcode custom-ml">Apply</Box>
                    </FormControl>
                  </Box>
                  <Box mt={1}>
                    <Button
                      variant="contained p-button"
                      href="/procurement/Invoice"
                      className="viewmore-dpdown-detail"
                    >
                      Add to Cart
                    </Button>
                  </Box>
                  <Box className="flex" alignItems={"center"}>
                    <Box className="flex" mt={1}>
                      <Box component="img" src={store} />
                      <Box>
                        <Typography
                          component="label"
                          ml={1}
                          className="font-bold"
                        >
                          Store Location
                        </Typography>
                        <Typography component="p" ml={1}>
                          Hyderabad
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="flex" mt={1} marginLeft={3}>
                      <Box component="img" src={delivery} />
                      <Box>
                        <Typography
                          component="label"
                          ml={1}
                          className="font-bold"
                        >
                          Delivery Time
                        </Typography>
                        <Typography
                          component="p"
                          ml={1}
                          className="text-success "
                        >
                          {" "}
                          6 Hrs
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="border-top" marginTop={2}>
                <Grid item md={12}>
                  <Typography
                    component="h4"
                    className="product-view-similar-products-heading"
                  >
                    Similar Products
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Caurosel />
            </Box>
            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}
