import React, { useImperativeHandle, forwardRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import test from '../images/test.jpg';
import salt from '../images/salt.webp';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Grid, FormControl, OutlinedInput, Divider, Select, MenuItem } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import GroupedButtons from './GroupedButtons';
import { InputBase } from '@mui/material';
import api from '../Api';
import { useSelector } from 'react-redux';
import { easing } from '@material-ui/core';
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const AddToCartModal = forwardRef((props, ref7) => {
    const response = useSelector(state => state.user.user);
    // const productId =  useSelector(state => state.user.product_id);

    const itemStock = useSelector(state => state.user.item_stock);
    const posPendingId = useSelector(state => state.user.pos_invoice_id);
    let customerId = useSelector(state => state.user.customer_id);
    console.log('props',props);
    const [open, setOpen] = React.useState(false);
    const [passwd, setPasswd] = React.useState(false);
    const handlePassword = () => {
        setPasswd(passwd => !passwd)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const showSwalWithLink = (message) => {
        MySwal.fire({
            icon: 'success',
            text: message
        }).then(()=>{
            props.callGetCartItems();
            handleClose();
        })
    };
    const showSwalWithLinkError = (message) => {
        MySwal.fire({
            icon: 'error',
            text: message
        })
    };
    const [counter,setCounter] = React.useState(0);

    const handleIncrement = () => {
        setCounter(Number(counter) + 1);
      };
    
      const handleDecrement = () => {
        setCounter(Number(counter) - 1);
      };

    useImperativeHandle(ref7, () => ({
        log() {
            handleOpen();
        }
    }));

    const [cartItems, setCartItems] = React.useState();

    useEffect(() => {
        if (props.cartDetails) {
            setCartItems(props.cartDetails);
        }

    }, [props.cartDetails]);

    const createUpdateCartItem = () => {
        
        if(posPendingId === null){
            showSwalWithLinkError('Invoice Id Can Not Be Null');
            return false;
        }else if(response?.merchant_id === null){
            showSwalWithLinkError('Merchant Id Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_id === null){
            showSwalWithLinkError('Product Id Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_name === null){
            showSwalWithLinkError('Product Name Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_image === null){
            showSwalWithLinkError('Product Image Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_segment_id === null){
            showSwalWithLinkError('Product Segment Id Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_sub_segment_id === null){
            showSwalWithLinkError('Product Sub Segment Id Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_master_sku === null){
            showSwalWithLinkError('Product Master SKU Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_complete_sku === null){
            showSwalWithLinkError('Product Complete SKU Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_metric === null){
            showSwalWithLinkError('Product Metric Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_uom === null){
            showSwalWithLinkError('Product Uom Can Not Be Null');
            return false;
        }else if(props?.cartDetails.no_of_units === null){
            showSwalWithLinkError('Number Of Units Can Not Be Null');
            return false;
        }else if(props?.cartDetails.product_sale_price === null){
            showSwalWithLinkError('Product Sale Price Can Not Be Null');
            return false;
        }else if(counter === null){
            showSwalWithLinkError('Product Quantity Can Not Be Null');
            return false;
        }
        
        let data = {
            'cart_id': '0',
            'invoice_id': posPendingId,
            'merchant_id': response?.merchant_id || '',
            'customer_id': process.env.REACT_APP_MY_TITLE === 'Bazaar' ? null : customerId,
            // 'bazaar_id': process.env.REACT_APP_MY_TITLE === 'Vikretha' ? null : customerId,
            'product_id': props?.cartDetails.product_id || '',
            'product_name': props?.cartDetails.product_name || '',
            // 'table_id': props?.tableNumberId || tabelId,
            'product_image': props?.cartDetails.product_image || '',
            'product_segment_id':  props?.cartDetails.product_segment_id || '',
            'product_sub_segment_id': props?.cartDetails.product_sub_segment_id || '',
            'product_master_sku':  props?.cartDetails.product_master_sku || '',
            'product_complete_sku':  props?.cartDetails.product_complete_sku || '',
            'product_metric':  props?.cartDetails.product_metric || '',
            'product_uom':  props?.cartDetails.product_uom || '',
            'no_of _units':  props?.cartDetails.no_of_units || '',
            'sale_price':  props?.cartDetails.product_sale_price || '',
            'product_qty_sold': counter || '',
            'discount_id': '0',
            'discount_amount': '0',
            'tax_id': '0',
            'tax_amount': '0',
            'fee_id': '0',
            'fee_amount': '0',
            'item_status': '0',
            'isbilled': '1',
            'maintain_stock':  props?.cartDetails.maintain_stock || '0'
        }
        if(data.invoice_id === null || data.invoice_id === ''){
            showSwalWithLinkError('Invoice Id Can Not Be Null');
            return false;
        }else if(data.merchant_id === null || data.merchant_id === ''){
            showSwalWithLinkError('Merchant Id Can Not Be Null');
            return false;
        }else if(data.product_id === null || data.product_id === ''){
            showSwalWithLinkError('Product Id Can Not Be Null');
            return false;
        }else if(data.product_name === null || data.product_name === ''){
            showSwalWithLinkError('Product Name Can Not Be Null');
            return false;
        }else if(data.product_image === null || data.product_image === ''){
            showSwalWithLinkError('Product Image Can Not Be Null');
            return false;
        }else if(data.product_segment_id === null || data.product_segment_id === ''){
            showSwalWithLinkError('Product Segment Id Can Not Be Null');
            return false;
        }else if(data.product_sub_segment_id === null || data.product_sub_segment_id === ''){
            showSwalWithLinkError('Product Sub Segment Id Can Not Be Null');
            return false;
        }else if(data.product_master_sku === null || data.product_master_sku === ''){
            showSwalWithLinkError('Product Master SKU Can Not Be Null');
            return false;
        }else if(data.product_complete_sku === null || data.product_complete_sku === ''){
            showSwalWithLinkError('Product Complete SKU Can Not Be Null');
            return false;
        }else if(data.product_metric === null || data.product_metric === ''){
            showSwalWithLinkError('Product Metric Can Not Be Null');
            return false;
        }else if(data.product_uom === null || data.product_uom === ''){
            showSwalWithLinkError('Product Uom Can Not Be Null');
            return false;
        }else if(data.no_of_units === null || data.no_of_units === ''){
            showSwalWithLinkError('Number Of Units Can Not Be Null');
            return false;
        }else if(data.product_sale_price === null || data.product_sale_price === ''){
            showSwalWithLinkError('Product Sale Price Can Not Be Null');
            return false;
        }else if(data.product_qty_sold === null || data.product_qty_sold === ''){
            showSwalWithLinkError('Product Quantity Can Not Be Null');
            return false;
        }
        api.postData('/api/pos/POS_create_update_cart_item', data).then((response) => {


            console.log(response)

            if (response.data[0].Status === "200") {

                setCartItems(response.data[0].Cart_Items);
                showSwalWithLink(response.data[0].Message);
               

            }else{
              showSwalWithLinkError(response.data[0].Message);
          }

        })
    }
    const handleDataFromChild = (data) => {

        // Do something with the data received from the child
    };
    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal'
            >
                <Box sx={{ ...style }} className='profile-modal'>
                    <Grid container>
                        <Grid item xs={12} className="profile-header p-15" >

                            <Typography id="parent-modal-title" component="h4">ADD ITEMS</Typography>
                            <CloseIcon onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <Divider></Divider>

                    <Grid container className='details-div p-15' spacing={2}>
                        <Grid item xs={2} sm={4} md={4}>

                            <Box>
                                <Box component="img" src={props.cartDetails?.product_image} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box className="sub-details addto-cart">
                                <Typography component="h4" className="tab-sub-title">{props.cartDetails?.product_id}</Typography>
                                <Typography component='h5'>{props.cartDetails?.product_name}</Typography>
                                <Typography component='label'>{props.cartDetails?.product_description}</Typography>
                                <Box className="flex" mt={2}>
                                    <Box className="w-50">
                                        <Typography component='p'>Price</Typography>
                                        <Typography component='label'>Rs {props.cartDetails?.product_sale_price} /-</Typography>
                                    </Box>
                                    <Box className="w-50">
                                        <Typography component='p'>Expiry Date</Typography>
                                        <Typography component='label'>{props.cartDetails?.expiry_date}</Typography>
                                    </Box>

                                </Box>
                                <Box className="flex" mt={2}>
                                    <Box className="w-50">
                                        <Typography component='p'>Quantity Available {props.cartDetails?.product_qty_physical?props.cartDetails.product_qty_physical:0}</Typography>
                                        <ButtonGroup size="small" aria-label="small outlined button group">

                                            <Button onClick={handleIncrement}>+</Button>
                                            <InputBase
                                            value={counter}
                                            type='number'
                                            onChange={(e)=>{setCounter(e.target.value)}}
                                            className='counter-input'
                                          
                                        />
                                            <Button onClick={handleDecrement}>-</Button>
                                        </ButtonGroup>
                                    </Box>
                                    <Box className="w-50">
                                        <FormControl className='w-100'>

                                            <Typography component='p'>UOM</Typography>
                                            <Select
                                                labelId="demo-isolation-label"
                                                id="isolation-select"
                                                className="location-select add-to-cart"
                                                // onChange={handleSelectOxygen}
                                                // input={<OutlinedInput />}
                                                defaultValue="locations"

                                            >
                                                {
                                                    props.uomDetails?.map((item) => {
                                                        return <MenuItem value="locations">{item.uom}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>

                            </Box>
                            { props && props.cartDetails && props.cartDetails.maintain_stock === 0 && props.cartDetails.product_qty_physical == "0" && <Box Typography="small" className="float-left text-danger small-spinner" mt={2}>Product Quantity Not Available</Box>}
                            <Box className="w-100 text-center" mt={2}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    mt={2}
                                    mb={3}
                                    className="add-cart-btn ml-20"
                                    disabled={props && props.cartDetails && props.cartDetails.maintain_stock === 0 && props.cartDetails.product_qty_physical == "0"}
                                    onClick={() => createUpdateCartItem()}
                                >
                                    Add To Cart
                                </Button>
                            </Box>
                        </Grid>


                    </Grid>

                </Box>
            </Modal>
        </div>
    );
});

export default AddToCartModal;