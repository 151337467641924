import React, { useRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
  SvgIcon,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate } from "react-router-dom";
import SearchBar from "material-ui-search-bar";
import DeleteIcon from "@mui/icons-material/Delete";
import MemberTable from "../Customer/MemberTable";
import ListItemText from "@mui/material/ListItemText";
import AddPromoModal from "./AddPromoModal";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import api from "../Api";
import { setPosLocation } from "../Redux/actions/userActions";

export default function PromotionsCreate() {
  const [itemDetails, setItemDetails] = React.useState();
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState("");
  const response = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  let posLocation = useSelector((state) => state.user.pos_location);

  const ref = React.useRef();
  const [promoCodeCustomers, setPromoCodeCutomers] = React.useState([]);
  const [promoNewCodeCustomers, setPromoNewCodeCutomers] = React.useState([]);
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const ref4 = useRef();
  const [selectedItems, setSelectedItems] = React.useState([]);

  const handleSelectChange = (event) => {
    setSelectedItems(event.target.value);
  };

  const handleSelectAllItems = () => {
    if (itemDetails.length === itemDetails.length) {
      setLocationName([]);
    } else {
      setSelectedItems(itemDetails.map((item) => item.product_id));
    }
  };
  const [filterData, setFilterData] = React.useState([]);
  const filterCustomerData = (rowData) => {
    if (filterData.some((item) => item.customer_id === rowData.customer_id)) {
      setFilterData((prevSelected) =>
        prevSelected.filter((item) => item.customer_id !== rowData.customer_id)
      );
    } else {
      setFilterData((prevSelected) => [...prevSelected, rowData]);
    }
  };

  const [promoMadule, setPromoMadule] = React.useState([]);
  const handleModule = (e) => {
    if (e.target.checked) {
      setPromoMadule((oldArray) => [...oldArray, e.target.name]);
    } else {
      removeModules(e);

    }
  };
  const removeModules = (e) => {
    setPromoMadule([
      ...promoMadule.filter((module) => module !== e.target.name),
    ]);
  };

  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };

  const [location, setLocation] = React.useState();
  const [locationId, setLocationId] = React.useState();
  const [segmentDetails, setSegmentDetails] = React.useState();
  const [subSegments, setSubSegmentDetails] = React.useState();

  const fetchCities = () => {
    api
      .getData(
        "/api/locations/get_Location_name_pos?merchant_id=" +
        response?.merchant_id +
        "&city_id=" +
        response?.city_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setLocation(JSON.parse(response.data[0].LocationDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (location) {
      dispatch(setPosLocation(location[0]?.merchant_location_id));
      setLocationId(location[0]?.merchant_location_id);
    }
  }, [location]);



  const getSubSegmentId = (id) => {
    getSubSegmentsDetails(id);
  };
  const [locationName, setLocationName] = React.useState([]);

  const handleChangeLocations = (event) => {
    setLocationName(event.target.value);
  };

  const handleSelectAll = () => {
    if (locationName.length === location.length) {
      setLocationName([]);
    } else {
      setLocationName(location.map((item) => item.merchant_location_id));
    }
  };

  const [selectedSegments, setSelectedSegments] = React.useState([]);

  const handleChangeSegment = (event) => {
    setSelectedSegments(event.target.value);
    getSubSegmentId(event.target.value);
  };

  const handleSelectAllSegments = () => {
    if (selectedSegments.length === segmentDetails.length) {
      setSelectedSegments([]);
    } else {
      setSelectedSegments(
        segmentDetails.map((item) => item.merchant_segment_id)
      );
    }
  };

  const handleSelectAllSubSegments = () => {
    if (selectedSubSegments.length === subSegments.length) {
      // If all sub-segments are selected, clear the selection
      setSelectedSubSegments([]);
    } else if (
      selectedSubSegments.length === 0 ||
      selectedSubSegments.length < subSegments.length
    ) {
      // If no sub-segment or not all are selected, select all sub-segments
      setSelectedSubSegments(
        subSegments.map((item) => item.merchant_sub_segment_id)
      );
    }
  };

  const [profileImage, setProfileImage] = React.useState();
  const handleImageSelection = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [finalSegment, setFinalSegment] = React.useState();

  const [discountNames, setDiscountNames] = React.useState();
  const getDiscountNames = () => {
    api
      .getData("/api/promotions/get_discount_names")
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setDiscountNames(JSON.parse(response.data[0].DiscountDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSegmentDetails = () => {
    api
      .getData(
        "/api/pos/get_Segment_Details_pos?merchant_id=" + response.merchant_id + "&module=pos"
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setSegmentDetails(
            JSON.parse(response.data[0].Merchant_Segement_Details)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubSegmentsDetails = (merchantSubSegmentId) => {
    // const data = fetchSubSegmentsDetails(response.merchant_id, 2);

    api
      .getData(
        "/api/pos/get_sub_segment_Details_pos?merchant_id=" +
        response.merchant_id + "&module=pos" +
        "&merchant_segment_id=" +
        merchantSubSegmentId
      )
      .then((response) => {
        console.log();
        if (response.data[0]?.Status === "200") {
          setSubSegmentDetails(
            JSON.parse(response.data[0]?.SubSegmentDetails)
          );
        }
        console.log(subSegments);
      })
      .catch((error) => {
        console.log(error);
      });
    // setSubSegmentDetails(data);
  };
  const [discountType, setDiscountType] = React.useState();
  const getDiscountValue = (value) => {
    const foundDiscount = discountNames.find(
      (discount) => discount.discount_type_id === value
    );

    if (foundDiscount) {
      setDiscountType(foundDiscount.discount_name);
    }

    if (
      value === 3 ||
      value === 4 ||
      value === 5 ||
      value === 6 ||
      value === 7 ||
      value === 8
    ) {
      setSelectedSegments(["All"]);
      setSelectedSubSegments(["All"]);
      setSelectedItems(["All"]);
      setPromoSegments([{ "merchant_segment_name": "ALL", "merchant_sub_segment_name": "All", "product_name": "All" }])
    } else {
      setSelectedSegments([]);
      setSelectedSubSegments([]);
      setSelectedItems([]);
      setPromoSegments([])
    }
  };
  useEffect(() => {
    fetchCities();
    getDiscountNames();
    getSegmentDetails();
  }, []);

  const handleSelectedMembersChange = (selectedMembers) => {
    setPromoNewCodeCutomers(selectedMembers);
    if(selectedMembers.length>0){
      showSwalWithLink("Customers Added Successfully");

    }

  };

  useEffect(() => {
    if (segmentDetails) {
      getSubSegmentsDetails(segmentDetails[0].merchant_segment_id);
    }
  }, [segmentDetails]);

  const handleSubmit = (values) => {
    console.log('values', values);
    let data = {
      promo_id: '0',
      promo_code: values?.promo_code || "",
      locations: JSON.stringify(locationName) || "",
      modules: JSON.stringify(promoMadule) || "",
      environment: process.env.REACT_APP_MY_TITLE,
      merchant_id: response?.merchant_id || "",
      discount_type_id: values.discount_type_id || "",
      discount_value: values.discount_value || "",
      discount_base: values.discount_type_id || "",
      segments: selectedSegments?.join() || "",
      subsegments: selectedSubSegments?.join() || "",
      products: promosegments.map((item) => item.product_id).join() || "",
      fromdate: values?.fromdate || "",
      todate: values?.todate || "",
      no_of_usages: values?.no_of_usages || "",
      customers: (process.env.REACT_APP_MY_TITLE === "Vikretha") ? filterData.map((item) => item.customer_id)?.join() : "",
      // bazar_customers: (process.env.REACT_APP_MY_TITLE === "Bazaar")? JSON.stringify(filterData.map((item) => item.customer_id)): "",
      promo_pic: profileImage || "",
      description: values?.description || "",
      status: "0",
    }
    if (data.promo_code === null || data.promo_code === "") {
      showSwalWithError("Promo Code Not Null");
      return false;
    } else if (data.locations === null || data.locations === "") {
      showSwalWithError("Locations Not Null");
      return false;
    } else if (data.modules === null || data.modules === "") {
      showSwalWithError("Promo Module Not Null");
      return false;
    } else if (data.merchant_id === null || data.merchant_id === "") {
      showSwalWithError("Merchant Id Not Null");
      return false;
    } else if (data.discount_type_id === null || data.discount_type_id === "") {
      showSwalWithError("Discount Type Id Not Null");
      return false;
    } else if (data.discount_value === null || data.discount_value === "") {
      showSwalWithError("Discount Value Not Null");
      return false;
    } else if (data.segments === null || data.segments === "") {
      showSwalWithError("Segments Not Null");
      return false;
    } else if (data.subsegments === null || data.subsegments === "") {
      showSwalWithError("Sub Segments Not Null");
      return false;
    } else if (data.products === null || data.products === "") {
      showSwalWithError("Products Not Null");
      return false;
    } else if (data.fromdate === null || data.fromdate === "") {
      showSwalWithError("From Date Not Null");
      return false;
    } else if (data.todate === null || data.todate === "") {
      showSwalWithError("To Date Not Null");
      return false;
    } else if (data.customers === null || data.customers === "") {
      showSwalWithError("Customers Not Null");
      return false;
    } else if (data.promo_pic === null || data.promo_pic === "") {
      showSwalWithError("Promo Picture Not Null");
      return false;
    } else if (data.created_date === null || data.created_date === "") {
      showSwalWithError("Create Date Not Null");
      return false;
    }
    if (data.segments == "all" || data.segments == "All") {
      data.segments = 0;
    }
    if (data.subsegments == "all" || data.subsegments == "All") {
      data.subsegments = 0;
    }
    if (data.products == "all" || data.products == "All") {
      data.products = 0;
    }
    api
      .postData("/api/promotions/Insert_Update_Promo_Codes", data)
      .then((response) => {
        if (response.Status === "200") {
          // setEmpId(response.data[0].new_employee_id)
          showSwalWithLink(response.Message);
        } else {
          showSwalWithError(response.Message);
        }
      });
  };

  const getProducts = (id) => {
    const result = subSegments.filter((item) => {
      if (item.merchant_sub_segment_id === id) {
        return item;
      }
    });
    if (result) {
      getItemDetails(
        result[0]?.merchant_segment_id,
        result[0]?.merchant_sub_segment_id
      );
    }
  };

  const getItemDetails = (segmentId, subSegmnetId) => {
    api
      .getData(
        "/api/pos/get_item_Details_pos?merchant_id=" +
        response?.merchant_id + "&module=pos" +
        "&segment_id=" +
        segmentId +
        "&sub_segment_id=" +
        subSegmnetId +
        "&city_id=" +
        response?.city_id +
        "&location_id=" +
        posLocation
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setItemDetails(JSON.parse(response.data[0].ProductDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [promosegments, setPromoSegments] = React.useState();
  const getProductPromoSegments = () => {
    if (selectedSegments.length === 0) {
      showSwalWithError("Please select Segments");
      return false;
    }
    if (selectedSubSegments.length === 0) {
      showSwalWithError("Please select Sub Segments");
      return false;
    }
    if (selectedItems.length === 0) {
      showSwalWithError("Please select Product");
      return false;
    }

    api
      .getData(
        "/api/promotions/get_product_Promo_Segments?merchant_id=" +
        response?.merchant_id +
        "&segments=" +
        selectedSegments.toString() +
        "&subsegments=" +
        selectedSubSegments.toString() +
        "&products=" +
        selectedItems.toString()
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setPromoSegments(JSON.parse(response.data[0].ProductDetails));
          showSwalWithLink(response.data[0]?.Message);
        }else{
          showSwalWithError(response.data[0]?.Message);
        }
      })
      .catch((error) => {
        console.log(error);
        showSwalWithError("error");
      });
  };

  const getCustomersPromoCodes = (data) => {
    api
      .getData(
        "/api/promotions/get_customers_Promo_Codes?merchant_id=" +
        response?.merchant_id +
        "&cr_fromdate=" +
        "01/01/2023" +
        "&cr_todate=" +
        "01/01/1900" +
        "&la_fromdate=" +
        "01/01/1900" +
        "&la_todate=" +
        "01/01/1900" +
        "&ts_min=" +
        "0" +
        "&ts_max=" +
        "9000" +
        "&to_min=" +
        "0" +
        "&to_max=" +
        "0"
      )
      .then((response) => {
        if (response.data[0]?.Status === 200) {
          setPromoCodeCutomers(JSON.parse(response.data[0].ProductDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCustomersPromoCodes();
  }, []);

  const formik = useFormik({
    initialValues: {
      promo_code: "",
      locations: "",
      modules: "",
      environment: process.env.REACT_APP_MY_TITLE,
      merchant_id: "",
      discount_type_id: "",
      discount_value: "",
      discount_base: "",
      segments: "",
      subsegments: "",
      products: "",
      fromdate: "",
      todate: "",
      no_of_usages: "",
      customers: "",
      bazar_customers: "",
      promo_pic: "",
      description: "",
      created_date: "",
      status: "",
    },
    // validationSchema: addLocation,
    onSubmit: handleSubmit,
    // enableReinitialize: true,
  });
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;

  useEffect(() => {
    if (subSegments) {
      getItemDetails(
        subSegments[0]?.merchant_segment_id,
        subSegments[0]?.merchant_sub_segment_id
      );
    }
  }, [subSegments]);

  const customerColumns = [
    // {
    //   name: "name_of_the_customer",
    //   label: "Name",
    //   //    label:'',
    // },
    // { name: "phone_number", label: "Contact No" },
    // { name: "location_name", label: "Location" },
    { name: "customer_type", label: "Customer Type" },
    { name: "name_of_the_customer", label: "Customer Name" },
    {
      name: "actions",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = promoCodeCustomers[rowIndex];
          return (
            <Checkbox
              onChange={() => {
                filterCustomerData(rowData);
              }}
            />
          );
        },
      },
    },
  ];
  const [selectedSubSegments, setSelectedSubSegments] = React.useState([]);

  const handleChangeSubSegment = (event) => {
    setSelectedSubSegments(event.target.value);
  };
  const deleteSegment = (productIdToDelete) => {
    const updatedItems = promosegments.filter(item => item.product_id !== productIdToDelete);

    // Update the state with the remaining items
    setPromoSegments(updatedItems);
  }
  const segmentColumns = [
    { name: "product_name", label: "Item Name" },
    {
      name: "merchant_segment_name",
      label: "Segment Name",
      //    label:'',
    },
    { name: "merchant_sub_segment_name", label: "Sub Segment Name" },

    {
      name: "actions",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = promosegments[rowIndex];
          return (<DeleteIcon onClick={() => { deleteSegment(rowData.product_id) }} className="delete" />
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {

    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    // expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {

      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Taxes:</b> ₹ 485.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Advance:</b> ₹ 321.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Due Amount:</b> ₹ 542.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>State:</b> State
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card store-item-card">
              <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link>

                    <Typography color="text.primary">Promotions</Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid>
              <form onSubmit={formik.handleSubmit}>
                <CardContent className="add_new_item">
                  <Card>
                    <CardContent className="promotion-card">
                      <Grid container spacing={4} mt={1} className="align-items-baseline">
                        <Grid item xs={12} md={3}>
                          <Typography component="h4" className="sub-title">
                            Create Promo
                          </Typography>
                          <Box mt={2} className="flex-column">
                            <Box
                              src={imagePreviewUrl}
                              component="img"
                              className="p-img"
                              style={{ width: '200px', height: '200px' }}
                              mb={2}
                            />
                            <Button
                              variant="contained"
                              component="label"
                              className="btn-upload"
                            >
                              Upload image
                              <input
                                hidden
                                accept="image/*"
                                multiple
                                type="file"
                                onChange={(e) => {
                                  handleChange(e);
                                  handleImageSelection(e);
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                }}
                              />
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>

                          <FormControl mt={2}>
                            <Typography
                              component="label"
                              className="primary-color"
                            >
                              Promo Code
                            </Typography>
                            <OutlinedInput
                              placeholder="Promo Name"
                              name="promo_code"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          {/* <FormControl className="w-50">
                          {/* <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel> */}
                          {/* <Typography component="label">Locations</Typography>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            className="select"
                            multiple
                            name="locations"
                            value={locationName}
                            onChange={handleChangeLocations}
                            renderValue={(selected) =>
                              selected?.length === location?.length
                                ? "All Locations"
                                : selected.join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            <MenuItem
                              key="selectAll"
                              value="selectAll"
                              onClick={handleSelectAll}
                            >
                              <Checkbox
                                checked={
                                  locationName?.length === location?.length
                                }
                              />
                              <ListItemText primary="All Locations" />
                            </MenuItem>

                            {location?.map((item) => (
                              <MenuItem
                                key={item.merchant_location_id}
                                value={item.merchant_location_id}
                              >
                                <Checkbox
                                  checked={
                                    locationName.indexOf(
                                      item.merchant_location_id
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={item.location_name} />
                              </MenuItem>
                            ))}
                          </Select> */}
                          {/* </FormControl> */}
                          <Box mt={2}>
                            <Typography component="h4" className="promo-subtitle">
                              APPLICABLE TO
                            </Typography>
                            <FormGroup className="checkbox-group flex-column">
                              <FormControlLabel
                                control={
                                  <Checkbox onChange={handleModule} name="pos" />
                                }
                                label="POS"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleModule}
                                    checked={promoMadule?.includes("phone_orders") ? true : false}
                                    name="phone_orders"
                                  />
                                }
                                label="Phone Orders"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={handleModule}
                                    name="ecommerce"
                                    checked={promoMadule?.includes("ecommerce") ? true : false}
                                  />
                                }
                                label="Ecommerce"
                              />
                            </FormGroup>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">
                              Description
                            </Typography>
                            <Box
                              component="textarea"
                              placeholder="Staff Offer"
                              name="description"
                              className="br-10"
                              rows={8}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid container spacing={4} mt={1} mb={2}>
                          <Grid item xs={12} md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Discount Type
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  getDiscountValue(e.target.value);
                                }}
                                onBlur={formik.handleBlur}
                                name="discount_type_id"
                                defaultValue="0"
                              >
                                <MenuItem value='0'>Select Discount Type</MenuItem>
                                {
                                  discountNames?.map((item) => {
                                    return <MenuItem value={item.discount_type_id}>{item.discount_name}</MenuItem>
                                  })
                                }

                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Discount Value
                              </Typography>
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <OutlinedInput type="value" name="discount_value" onChange={formik.handleChange}
                                  onBlur={formik.handleBlur} />  {(discountType) ? discountType?.includes("Percentage")
                                    ? "%"
                                    : "Fixed" : ''}</Box>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={3}></Grid>
                          <Grid item xs={12} md={3}></Grid>
                        </Grid>
                        <Box className="flex-between w-100">
                          <Typography
                            component="h4"
                            mt={1}
                            mb={2}
                            className="font-bold"
                          >
                            APPLIES TO
                          </Typography>

                          <Button
                            variant="outlined"
                            mt={2}
                            mb={2}
                            className="retail-btn float-right"
                            mr={2}
                            onClick={() => {
                              getProductPromoSegments();
                            }}
                          >
                            Add
                          </Button>
                        </Box>
                        <Grid container spacing={3} mb={2}>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">Segment</Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                multiple
                                value={selectedSegments}
                                onChange={handleChangeSegment}
                                renderValue={(selected) =>
                                  selectedSegments[0] === "All" ? "All" :
                                    selected.length === segmentDetails.length
                                      ? "All"
                                      : selected
                                        .map(
                                          (value) =>
                                            segmentDetails.find(
                                              (item) =>
                                                item.merchant_segment_id === value
                                            )?.merchant_segment_name
                                        )
                                        .join(", ")
                                }
                              >
                                <MenuItem
                                  key="All"
                                  value="All"
                                  onClick={handleSelectAllSegments}
                                >
                                  <Checkbox
                                    checked={
                                      (selectedSegments &&
                                        selectedSegments.length) ===
                                      (segmentDetails && segmentDetails.length) || selectedSegments[0] === "All"
                                    }
                                  />
                                  <ListItemText primary="All" />
                                </MenuItem>

                                <MenuItem value="SelectSegment">
                                  Select Segment
                                </MenuItem>
                                {segmentDetails?.map((item) => (
                                  <MenuItem
                                    key={item.merchant_segment_id}
                                    value={item.merchant_segment_id}
                                  >
                                    <Checkbox
                                      checked={
                                        selectedSegments.indexOf(
                                          item.merchant_segment_id
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={item.merchant_segment_name}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Sub Segment
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                multiple
                                value={selectedSubSegments}
                                onChange={handleChangeSubSegment}
                                renderValue={(selected) =>
                                  selectedSubSegments[0] === "All" ? "All" :
                                    selected.length === subSegments.length
                                      ? "All"
                                      : selected
                                        .map(
                                          (value) =>
                                            subSegments.find(
                                              (item) =>
                                                item.merchant_sub_segment_id ===
                                                value
                                            )?.merchant_sub_segment_name
                                        )
                                        .join(", ")
                                }
                              >
                                <MenuItem
                                  key="All"
                                  value="All"
                                  onClick={handleSelectAllSubSegments}
                                >
                                  <Checkbox
                                    checked={
                                      selectedSubSegments?.length ===
                                      subSegments?.length || selectedSubSegments[0] === "All"
                                    }
                                  />
                                  <ListItemText primary="All" />
                                </MenuItem>

                                <MenuItem value="SelectSubSegment">
                                  Select Sub-Segment
                                </MenuItem>
                                {subSegments?.map((item) => (
                                  <MenuItem
                                    key={item.merchant_sub_segment_id}
                                    value={item.merchant_sub_segment_id}
                                  >
                                    <Checkbox
                                      checked={
                                        selectedSubSegments.indexOf(
                                          item.merchant_sub_segment_id
                                        ) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={item.merchant_sub_segment_name}
                                    />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">Item</Typography>
                              <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                className="select"
                                multiple
                                value={selectedItems}
                                onChange={handleSelectChange}
                                renderValue={(selected) =>
                                  selectedItems[0] === "All" ? "All" :
                                    selected.length === itemDetails.length
                                      ? "All"
                                      : selected
                                        .map(
                                          (value) =>
                                            itemDetails.find(
                                              (item) => item.product_id === value
                                            )?.product_name
                                        )
                                        .join(", ")
                                }
                              >
                                <MenuItem
                                  key="All"
                                  value="All"
                                  onClick={handleSelectAllItems}
                                >
                                  <Checkbox
                                    checked={
                                      selectedItems.length === itemDetails &&
                                      itemDetails.length || selectedItems[0] === "All"
                                    }
                                  />
                                  <ListItemText primary="All" />
                                </MenuItem>

                                {itemDetails?.map((item) => (
                                  <MenuItem
                                    key={item.product_id}
                                    value={item.product_id}
                                  >
                                    <Checkbox
                                      checked={
                                        selectedItems.indexOf(item.product_id) >
                                        -1
                                      }
                                    />
                                    <ListItemText primary={item.product_name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Box className="member-table w-100" mt={2}>
                          <MemberTable
                            columns={segmentColumns}
                            options={options}
                            data={promosegments}
                          />
                        </Box>

                        <Divider></Divider>
                        <Box mt={2} mb={1}>
                          <Typography component="b" className="small">
                            VALIDITY
                          </Typography>
                        </Box>
                        <Grid container spacing={3} mb={2}>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">From Date</Typography>
                              <OutlinedInput
                                type="date"
                                name="fromdate"
                                onChange={handleChange}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">To Date</Typography>
                              <OutlinedInput
                                type="date"
                                name="todate"
                                onChange={handleChange}
                              />
                            </FormControl>
                          </Grid>
                          {/* <Grid item xs={12} md={4}>
                          <FormControl className="w-100">
                            <Typography component="label">Number Of Time Usages</Typography>
                            <OutlinedInput
                              type="number"
                              name="no_of_usages"
                              onChange={handleChange}
                            />
                          </FormControl>
                        </Grid> */}
                        </Grid>
                        <Divider></Divider>
                        <Box className="flex-between w-100" mt={2}>
                          <Typography component="h4" className="sub_title" mt={2}>
                            EXISTING CUSTOMERS
                          </Typography>
                          <Box>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn mr-20"
                              onClick={() => {
                                ref4.current.log();
                              }}
                            >
                              Add New Customer
                            </Button>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn"
                              onClick={() => ref4.current.log()}
                            >
                              Delete Customer
                            </Button>
                          </Box>
                        </Box>
                        <Box className="member-table w-100" mt={2}>
                          <MemberTable
                            columns={customerColumns}
                            options={options}
                            data={promoNewCodeCustomers ? promoNewCodeCustomers : []}
                          />
                        </Box>
                        {/* 
                      <Grid container spacing={2} mb={2} mt={2}>
                        <Grid item xs={12} md={6}>
                          
                        </Grid>
                        <Grid item xs={12} md={6}>
                         
                        </Grid>
                      </Grid> */}
                        <Divider></Divider>
                        <Box className=" w-100" mt={2}>
                          <Button
                            variant="outlined"
                            mt={2}
                            mb={2}
                            className="retail-btn float-right"
                            mr={2}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Box>
                      </Grid>
                    </CardContent>
                  </Card>
                </CardContent>
              </form>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <AddPromoModal
          ref={ref4}
          promoCodeCustomers={promoCodeCustomers}
          onSelectedMembersChange={handleSelectedMembersChange}
        />
      </Box>
    </>
  );
}
