import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import api from "../Api";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Select, MenuItem } from "@mui/material";
// import AddIngredientsModal from './AddIngredientsModal';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddIngredientsMeasurements = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const productIdGlobal = useSelector(state => state.user.product_id);
  const [stockIngredientDetails,setStockIngredientDetails] = React.useState();
  const handleSubmit = (values) => {
   
    console.log('stockIngredientDetails',stockIngredientDetails);
    let data = {
      'product_id': productIdGlobal,
      'ingredient_id': stockIngredientDetails?.product_id,
      'required_qty': formik.values.required_qty,
      'uom': stockIngredientDetails?.base_uom?stockIngredientDetails?.base_uom:formik.values.base_uom
    }
    if(data.product_id === null || data.product_id === undefined){
    showSwalWithLinkError('Product Can Not Be Null');
    }

    try {
      api
        .postData("/api/segments/Mapping_items_Insert", data)
        .then((response) => {
          if (response.data[0].Status === "200") {
            showSwalWithLink(response.data[0].Message);
            
             props?.fetchIngredientTableData();
          }else{
            showSwalWithLinkError(response.data[0].Message);
          }
        }).catch((error)=>{
          console.log(error);
        });
    } catch (error) {
      // Handle network error
    }
  };
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
    //  props.fetchIngredientTableData();
      handleClose();
    });
  };
  const showSwalWithLinkError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    })
  };
  
  const getStockIngredientDetails = (productId) => {
    api
      .getData("/api/items/Get_Stock_Ingredient_Details?product_name="+productId?.product_name)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setStockIngredientDetails(JSON.parse(response.data[0]?.StockIngredientDetails)&&JSON.parse(response.data[0]?.StockIngredientDetails)[0]);
          //    setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const initialValues = {
    'product_id': props?.ingredientProductId || stockIngredientDetails?.product_id || '',
    'ingredient_id': stockIngredientDetails?.ingredient_id || '',
    'required_qty': stockIngredientDetails?.required_qty || '',
    'uom': stockIngredientDetails?.uom || ''  
  };
  const formik = useFormik({
    initialValues,
    // validationSchema: SegmentSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  // useEffect(() => {
  //   fetchProfile
  // }, [ingredient]);
  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [inputValue, setInputValue] = useState("");
  const [ingredientList, setIngredientList] = useState([]);
  useEffect(() => {
    async function fetchData(val) {
      try {
        // const response = await api.getData(
        //   "/api/universal/Get_Search_Productname?search_parameter=" + val
        // );
         const response = await api.getData(
          "/api/items/Get_Stock_Ingredient_Names?search_parameter=" + val
        );
        if (response.data[0].Status === "200") {
          setIngredientList(JSON.parse(response.data[0]?.ProductNames));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIngredientList([]); // Handle the error by setting an empty array.
      }
    }

    if (inputValue !== "") {
      fetchData(inputValue);
    }
  }, [inputValue]);
  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
      setStockIngredientDetails({});
      setValue('')
      
    },
  }));
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
  formik;
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
        <Box
          sx={style}
          className="view-more-content subsegment-product adm-modal"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              INGREDIENTS MEASUREMENT DETAILS
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>

          <Divider></Divider>

          <Grid container className="p-15">
            <Grid item xs={2} sm={12} md={12}>
              <FormControl className="w-100 mb-15" >
                <Typography component="label">Ingredient Name</Typography>
                {/* <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  name="ingredient_id"
                  value={formik.values.ingredient_id || 1}
                  onChange={(e) => {
                    handleChange(e);getStockIngredientDetails(e.target.value)
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  defaultValue="1"
                >
                  { props.ingredientNames?.map((item)=>{
                   return <MenuItem value={item.product_id}>{item.product_name}</MenuItem>
                  })
                }
                 
                </Select> */}
                 <Autocomplete
                          value={value}
                          name="Product_Name"
                          className="select autoselect mb-15"
                          onChange={(event, newValue) => {
                            getStockIngredientDetails(newValue);
                            if (typeof newValue === "string") {
                              setValue({
                                title: newValue,
                              });
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setValue({
                                title: newValue.inputValue,
                              });
                            } else {
                              setValue(newValue);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          filterOptions={(options, params) => {
                            try {
                              options = Array.isArray(options) ? options : [];

                              const filtered = options?.filter((option) =>
                                option?.product_name.toLowerCase().includes(
                                  params.inputValue.toLowerCase()
                                )
                              );

                              const { inputValue } = params;

                              const isExisting = options?.some(
                                (option) => inputValue === option?.product_name
                              );

                              if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  title: `Add "${inputValue}"`,
                                });
                              }

                              return filtered;
                            } catch (error) {
                              console.error("Error filtering options:", error);
                              return [];
                            }
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          options={ingredientList || []}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option?.inputValue) {
                              return option?.inputValue;
                            }
                            return option?.product_name;
                          }}
                          style={{ height: "0px" }}
                          renderOption={(props, option) => (
                            <li {...props} value={option?.product_name}>
                              {option?.product_name}
                            </li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                setInputValue(e.target.value);
                                formik.handleChange(e);
                              }}
                            />
                          )}
                        />
              </FormControl>
              {errors.ingredient_id && touched.ingredient_id && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.ingredient_id}
                  </Box>
                )}
              <Box mt={2}>
                <FormControl className="w-100">
                  <Typography component="label">Metric</Typography>
                  <OutlinedInput value={stockIngredientDetails?.product_metric} name="product_metric"  onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }} />
                </FormControl>
                {errors.product_metric && touched.product_metric && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.product_metric}
                  </Box>
                )}
              </Box>
              <Box mt={2}>
                <FormControl className="w-100">
                  <Typography component="label">UOM</Typography>
                  <OutlinedInput value={stockIngredientDetails?.base_uom} name="base_uom"   onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }} />
                </FormControl>
                {errors.base_uom && touched.base_uom && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.base_uom}
                  </Box>
                )}
              </Box>
              {/* <Box mt={2}>
                <FormControl className="w-100">
                  <Typography component="label">No.of Units</Typography>
                  <OutlinedInput value={stockIngredientDetails?.no_of_total_units}  name="no_of_total_units" onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }} />
                </FormControl>
                {errors.no_of_total_units && touched.no_of_total_units && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.no_of_total_units}
                  </Box>
                )}
              </Box> */}
              <Box mt={2}>
                <FormControl className="w-100">
                  <Typography component="label">Required Quantity</Typography>
                  <OutlinedInput value={stockIngredientDetails?.required_qty} name="required_qty"  onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }} />
                </FormControl>
                {errors.required_qty && touched.required_qty && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.required_qty}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Divider></Divider>

          <Box className="p-15" marginBottom={2}>
            <Button
              variant="outlined"
              color="error"
              mt={2}
              className="retail-btn float-right"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </Box>
        </form>
      </Modal>
    </div>
  );
});

export default AddIngredientsMeasurements;
