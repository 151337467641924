import React, { useImperativeHandle, forwardRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography,Grid, FormControl, OutlinedInput,Divider,Select,MenuItem } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const PromoDiscountModal = forwardRef((props, ref1) => {
    const [open, setOpen] = React.useState(false);
    const [passwd,setPasswd] = React.useState(false);
    const handlePassword = () =>{
        setPasswd(passwd=>!passwd)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
     
    const showSwalWithLink = () => {
        MySwal.fire({
            icon: 'error',
            text: "All Fields Are Mandatory",
        });
      };

    useImperativeHandle(ref1, () => ({
        log() {
            handleOpen();
        }
    }));


    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal'
            >
                <Box sx={{ ...style }} className='profile-modal'>
                <Grid container>
                    <Grid item xs={12} className="profile-header p-15" >

                        <Typography id="parent-modal-title" component="h4">PROMO CODE</Typography>
                        <CloseIcon onClick={handleClose}  />
                    </Grid>
                    </Grid>
                    <Divider></Divider>
                     
                    <Grid container className='details-div p-15' spacing={2}>
                    <Grid item xs={12}  md={6}>
                    <FormControl className='w-100'>
                        <Typography component="label">Promo Code</Typography>
                            <OutlinedInput value={props?.promoCode} />
                        </FormControl>
                        </Grid>
                    </Grid>
                     
               
                </Box>
            </Modal>
        </div>
    );
});

export default PromoDiscountModal;