import React, {
  useImperativeHandle,
  useRef,
  forwardRef,
  useEffect,
} from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
  SvgIcon,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import Calender from "../Calender/Calender";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import status from "../images/last-active.svg";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Retailers/ExpandableRowTable";
import email from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import StoreNewItemModal from "./StoreNewItemModal";
import product1 from "../images/product-1.png";
import product2 from "../images/product-2.png";
import dal from "../images/dal3.jpg";
import upload from "../images/upload.svg";
import { Label } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
// import AddProductModal from "./AddProductModal";
import api from "../Api";
import { Formik, useFormik, Form, Field } from "formik";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import Switch from "@mui/material/Switch";
import { AddItemSchema } from "../Validation";
import AddIngredientsMeasurements from "./AddIngredientMeasurement";
import {
  setMeasurementId,
  setProductId,
  setProductSku,
  setCity,
  setSegments,
} from "../Redux/actions/userActions";
import AddProductModal from "./AddProductModal";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StoreNewItem() {
  const [edit, setEdit] = React.useState(true);
const [cities, setCities] = React.useState();
  const dispatch = useDispatch();
  const segment = useSelector((state) => state.user.segments);
  const response = useSelector((state) => state.user.user);
  const item_stok = useSelector((state) => state.user.item_stock);
  // const productId = useSelector(state => state.user.product_id);
  let productSku = useSelector((state) => state.user.product_sku);
  // let measurementId = useSelector(state => state.user.measurement_id);
  const [showState, setShowState] = React.useState(false);
const [spineer, setSpinner] = React.useState(true);
  const [uom, setUom] = React.useState("");
  const [locations, setLocation] = React.useState();
  const [segmentId, setSegementId] = React.useState(1);
  const [subSegment, setSubSegment] = React.useState();
  const [productSkuNew, setProductSkus] = React.useState();
  const [productSku11, setProductSkus11] = React.useState();
  const [productSku12, setProductSku12] = React.useState();
  const [segmentList, setSegmentList] = React.useState();
  const [subSegmentList, setSubSegmentList] = React.useState();
  const [segmentSkuPart, setSegmentSkuPart] = React.useState("___");
  const [subSegmentSkuPart, setSubSegmentSkuPart] = React.useState("___");
  const [brandSkuPart, setBrandSkuPart] = React.useState("___");
  const [itemNameSkuPart, setItemNameSkuPart] = React.useState("___");
  const [sizeSkuPart, setSizeSkuPart] = React.useState("___");

const [noOfDays, setNoOfDays] = React.useState();
  const [threshold, setthreshold] = React.useState();
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
const handleChanges = (event, newValue) => {
    setValue(newValue);
  };

const getUom = (e) => {
    const { name, value } = e.target;
    setUom(value);
  };
  const [brandName,setBrandName] = React.useState("nonVeg");
   const getBrandName = (value) =>{
    console.log('value',value);
    setBrandName(value);
   }
  const handleClick = () => {
    setShowState((showState) => !showState);
  };
const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {});
  };
  const showSwalWithLinkProfileError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    }).then(function () {
      //  navigate("/admin/Profile");
    });
  };
  const fetchData = () => {
    api
      .getData("/api/config/cities_list?countryid=" + response?.country_id)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCities(
            JSON.parse(response.data[0].Cities).filter((item) => {
              return item.city_state === "Andhra Pradesh";
            })
          );
          dispatch(
            setCity(
              JSON.parse(response.data[0].Cities).filter((item) => {
                return item.city_state === "Andhra Pradesh";
              })
            )
          );
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchLocations = () => {
    api
      .getData(
        "/api/locations/get_Location_name_pos?merchant_id=" +
          response?.merchant_id +
          "&city_id=" +
          response?.city_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setLocation(JSON.parse(response.data[0].LocationDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [itemUomDetails, setItemUomDetails] = React.useState();
  const [itemStockDetails, setItemStockDetails] = React.useState();
  const [metricUom, setMetricUom] = React.useState();
  const [pershable, setPershable] = React.useState(false);
  const [idleStock, setIdleStock] = React.useState(false);
  const [productId, setProductId] = React.useState(false);
  const [measurementId, setMeasurementIds] = React.useState(false);
  const [lowStock, setLowStock] = React.useState(false);
  const changePerishable = () => {
    setPershable((pershable) => !pershable);
  };
  const changeIdleStock = () => {
    setIdleStock((idleStock) => !idleStock);
  };
  const changeLowStock = () => {
    setLowStock((lowStock) => !lowStock);
  };
  const getUomData = (e) => {
    api
      .getData(
        "/api/items/metric_uom_details_metric_name?metric_name=" +
          e.target.value
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setMetricUom(JSON.parse(response.data[0]?.Metric_uom_details));
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getItemUomDetails = () => {
    api
      .getData(
        "/api/items/get_items_uom_details?product_id=" +
          productId +
          "&measurement_id=" +
          measurementId
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setItemUomDetails(JSON.parse(response.data[0]?.Item_uom_Details));
          dispatch(
            setProductSku(
              JSON.parse(response.data[0]?.Item_uom_Details)[0]
                ?.product_complete_sku
            )
          );
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [aisleRacks, setAisleRacks] = React.useState();
  const getMerchantSubSegment = (e) => {
    api
      .getData(
        "/api/segments/get_merchant_sub_segment_aisle_row_details?merchant_id=" +
          response?.merchant_id +
          "&sub_segment_id=" +
          e.target.value
      )
      .then((response) => {
        if (response.data[0].Status === "200") {
          setAisleRacks(
            JSON.parse(response.data[0]?.sub_segement_aisle_row_details)[0]
          );
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSegment = () => {
    api
      .getData(
        "/api/segments/get_merchant_segment_details?merchant_id=" +
          response?.merchant_id
      )
      .then((response) => {
        if (response.data[0].Status === "200") {
          setSegmentList(
            JSON.parse(response.data[0].Merchant_Segement_Details)
          );
          dispatch(
            setSegments(JSON.parse(response.data[0].Merchant_Segement_Details))
          );
        }
      });
  };

  const [segmentsuggestions, setSuggestions] = React.useState();
  const segmentGetData = () => {
    api
      .getData("/api/segments/master_segments")
      .then((response) => {
        console.log("response", response);
        //   if (JSON.parse(response.data[0].Status) === 200) {

        setSuggestions(response.data);
        // setSpinner(false);
        //   }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubSegment = (segmentId) => {
    api
      .getData(
        "/api/segments/get_merchant_sub_segment_details?merchant_id=" +
          response?.merchant_id +
          "&merchant_segment_id=" +
          segmentId
      )
      .then((response) => {
        if (response.data[0].Status === "200") {
          setSubSegment(
            JSON.parse(response.data[0].Merchant_Sub_Segement_Details)
          );
          if (
            JSON.parse(response.data[0].Merchant_Sub_Segement_Details) === null
          ) {
            // showSwalWithLinkProfileError("Profile is Not Completed. Please Add Location");
          }
          // setIsLoading(false);
        }
      });
  };

  const [subSuggestions, setSubSuggestions] = React.useState();
  const subSegmentSuggestions = () => {
    api
      .getData(
        "/api/segments/master_sub_segments?segment_id=" +
          (segment && segment.length > 0 ? segment[0].merchant_segment_id : 1)
      )
      .then((response) => {
        console.log("response", response);
        //   if (JSON.parse(response.data[0].Status) === 200) {

        setSubSuggestions(response.data);
        // setSpinner(false);
        //   }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getItemStockDetails = () => {
    api
      .getData(
        "/api/items/get_items_stock_details?product_id=" +
          productId +
          "&measurement_id=" +
          measurementId
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          console.log(
            "JSON.parse(response.data[0]?.Item_Stock_Details)",
            JSON.parse(response.data[0]?.Item_Stock_Details)
          );
          setItemStockDetails(JSON.parse(response.data[0]?.Item_Stock_Details));
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const managementColumns = [
    { name: "Item Name", options: { filterOptions: { fullWidth: true } } },
    "UOM",
    "No. of Units",
  ];
  const managementdata = [["India Gate Basmati Rice", "gm", "150gms"]];

  const managementOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useEffect(() => {
    if (measurementId) getItemUomDetails();
  }, [measurementId]);
  useEffect(() => {
    fetchData();
    getItemStockDetails();
    fetchLocations();
    subSegmentSuggestions();
    segmentGetData();
    getSegment();
    getSubSegment(
      segment && segment?.length > 0 ? segment[0].merchant_segment_id : 0
    );
    setSegementId(
      segment && segment?.length > 0 ? segment[0].merchant_segment_id : 0
    );
  }, []);
  // useEffect(()=>{
  //   console.log('locations',locations);
  //   if(locations?.length < 1){
  //     showSwalWithLinkProfileError("Profile is Not Completed. Please Add Location");
  //   }
  // },[locations]);
  // useEffect(()=>{
  //   if(segmentList?.length < 1){
  //     showSwalWithLinkProfileError("Profile is Not Completed. Please Add Segment");

  //   }
  // },[segmentList]);
  const [alignment, setAlignment] = React.useState("left");
const handleSubmit = (values) => {
    //
    let formData = new FormData();

    formData.append("product_id", "0");
    formData.append("merchant_id", response?.merchant_id);
    formData.append("city_id", response?.city_id);
    formData.append("location_id", (locations?locations[0].merchant_location_id:'0'));
    formData.append("product_name", values.product_name);
    formData.append("brand_name", brandName);
    formData.append("product_quantity", "10");
    formData.append("product_description", values.product_description);
    formData.append("product_how_to_use", "NA");
    formData.append("product_specifications", "NA");
    formData.append("product_segment_id", values.product_segment_id);
    formData.append("product_sub_segment_id", values.product_sub_segment_id);
    formData.append("product_aisle", 1);
    formData.append("product_row", 1);

    formData.append("product_master_sku", "NA");
    formData.append(
      "product_status",
      values.product_status ? values.product_status : "0"
    );
    formData.append("ptype", "Product");
    if (images.length > 0) {
      images.forEach((image, index) => {
        formData.append("productimage" + (index + 1), image);
      });
    }

    try {
      api
        .updateData("/api/items/items_create_update", formData)
        .then((res) => {
          console.log("response", res);
          if (res.data[0].Status === "200") {
            console.log('response.data[0]',res.data[0]);
            
            // if (!formik.values.product_id) {
            //   formik.values.product_id = res.data[0].ProductId;
            // }
            const data= {
                  measurement_id: '0',
                  product_id: res.data[0].ProductId,
                  merchant_id: response?.merchant_id,
                  product_complete_sku: "NA",
                  product_metric: formik.values.product_metric,
                  product_uom: formik.values.product_uom,
                  no_of_units: formik.values.no_of_units,
                  one_unit_contains: '1',
                  base_uom: "1",
                  msr_price: "0",
                  cost_price: "0",
                  sale_price: formik.values.sale_price,
                  maintain_stock: 0,
                };
       

            api
              .postData("/api/items/create_product_uom", data)
              .then((response) => {
                if (response.data[0].Status === "200") {
                  showSwalWithLink(response.data[0].Message);
                  // setMeasurement(response.data[0].measurement_id);
                  dispatch(setMeasurementId(response.data[0].measurement_id));
                  // dispatch(setProductSku(response.data[0].Product_master_sku));
                  // setSuccess(true);
                  getItemUomDetails();
                }
              })
              .catch((error) => {
                // Handle error
                console.error(error);
              });
            // dispatch(setProductSku(response.data[0].Product_master_sku));
            // setProductId(response.data[0].Product_master_sku)
            // setSuccess(true);
          }
        });
    } catch (error) {
      // Handle network error
    }

    // setSubmitting(false);
  };

  const initialValues = {
    product_id: "",
    merchant_id: "",
    city_id: "",
    location_id: "",
    product_name: "",
    brand_name: "",
    product_quantity: "",
    product_description: "",
    product_how_to_use: "",
    product_specifications: "",
    product_segment_id: "",
    product_sub_segment_id: "",
    product_aisle: "",
    product_row: "",
    product_metric: "",
    product_uom: "",
    no_of_units: "",
    sale_price: "",
    product_master_sku: "",
    product_status: "",
    product_image1: "",
    product_image2: "",
    product_image3: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: AddItemSchema,
    onSubmit: handleSubmit,
  });
  const handleItemStatusChange = (item) => {
    let data = {
      product_stock_id: item.product_stock_id,
      product_id: item.product_id,
      measurement_id: item.measurement_id,
    };
    try {
      api.postData("/api/items/stock_status_change", data).then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          getItemStockDetails();
        }
      });
    } catch (error) {
      // Handle network error
    }
  };

  const menuSegments = [
    { value: "1", key: "Cooking Essentials", label: "Cooking Essentials" },
    { value: "2", key: "Cleaning & Household", label: "Cleaning & Household" },
    { value: "3", key: "Beverages", label: "Beverages" },
    { value: "4", key: "Personal Care", label: "Personal Care" },
    { value: "5", key: "Dairy", label: "Dairy" },
    {
      value: "6",
      key: "Snacks & Branded Foods",
      label: "Snacks & Branded Foods",
    },
    { value: "7", key: "Dals & Staples", label: "Dals & Staples" },
  ];

  const subSegmentMenuItems = [
    { value: "1", key: "Tea & Coffee", label: "Tea & Coffee" },
    { value: "2", key: "Water & Soda", label: "Water & Soda" },
    { value: "3", key: "Soft Drinks", label: "Soft Drinks" },
    {
      value: "4",
      key: "Fruit Juices & Energy Drinks",
      label: "Fruit Juices & Energy Drinks",
    },
    { value: "5", key: "Health Drinks", label: "Health Drinks" },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const segmentValue =
      name === "product_segment_id" ? value : formik.values.product_segment_id;
    const subsegmentValue =
      name === "product_sub_segment_id"
        ? value
        : formik.values.product_sub_segment_id;

    const brandNameValue = formik.values.brand_name;
    const itemNameValue = formik.values.product_name;

    const selectedsegmentMenuItem = segment?.find(
      (item) => item.merchant_segment_id === segmentValue
    );
    const selectedSubMenuItem = subSegment?.find(
      (item) => item.merchant_sub_segment_id === subsegmentValue
    );

    const segmentSubstring =
      selectedsegmentMenuItem?.merchant_segment_name?.substring(0, 3) || "";
    const subsegmentSubstring =
      selectedSubMenuItem?.merchant_sub_segment_name?.substring(0, 2) || "";
    const brandNameSubstring = brandNameValue?.substring(0, 3) || "";
    const itemNameSubstring = itemNameValue?.substring(0, 3) || "";
    if (name === "product_name") {
      const itemNameValue = formik.values.product_name;
      setItemNameSkuPart(itemNameValue?.substring(0, 3));
    }
    if (name === "brand_name") {
      const brandNameValue = formik.values.brand_name;
      setBrandSkuPart(brandNameValue?.substring(0, 3));
    }
    if (name === "product_segment_id") {
      const segmentValue =
        name === "product_segment_id"
          ? value
          : formik.values.product_segment_id;
      const selectedsegmentMenuItem = segment?.find(
        (item) => item.merchant_segment_id === segmentValue
      );
      setSegmentSkuPart(
        selectedsegmentMenuItem?.merchant_segment_name?.substring(0, 3)
      );
    }
    if (name === "product_sub_segment_id") {
      const subsegmentValue =
        name === "product_sub_segment_id"
          ? value
          : formik.values.product_sub_segment_id;
      const selectedSubMenuItem = subSegment?.find(
        (item) => item.merchant_sub_segment_id === subsegmentValue
      );
      setSubSegmentSkuPart(
        selectedSubMenuItem?.merchant_sub_segment_name?.substring(0, 2)
      );
    }

    const newProductId = `${segmentSubstring}-${subsegmentSubstring}-${brandNameSubstring}-${itemNameSubstring}`;

    setProductSkus(newProductId);

    // if (formik1.values.no_of_units || formik1.values.product_uom) {
    //   const product_uom =
    //     name === "product_uom" ? value : formik1.values.product_uom;
    //   const product_uomstring = product_uom?.substring(0, 2) || "";
    //   const itemNameno_of_units = formik1.values.no_of_units;
    //   console.log("itemNameno_of_units.length", productSku, productSku);
    //   setSizeSkuPart(
    //     `${
    //       itemNameno_of_units.length === 1
    //         ? "0" + itemNameno_of_units
    //         : itemNameno_of_units.substring(0, 2)
    //     }${product_uomstring}`
    //   );
    //   const productSku12 = `${productSku11 ? productSku11 : productSku}-${
    //     itemNameno_of_units.length === 1
    //       ? "0" + itemNameno_of_units
    //       : itemNameno_of_units.substring(0, 2)
    //   }${product_uomstring}`;
    //   setProductSku12(productSku12);
    //   // dispatch(setProductSku(productSku12));
    //   // if(formik.values.product_name){

    //   // }
    // }
  };
  const [images, setImages] = React.useState([]);
  const handleImageUpload = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };
  // const [measurementId, setMeasurement] = React.useState();
  const handleUomSubmit = (values) => {
    //  return false;
    // if (!formik1.values.merchant_id || formik1.values.merchant_id !== response.merchant_id) {
    //   formik1.values.merchant_id = response.merchant_id;
    // }
    // if (!formik1.values.product_id) {
    //   formik1.values.product_id = productId;
    // }
    // if (!formik1.values.product_complete_sku) {
    //   formik1.values.product_complete_sku = productSku12;
    // }
    // console.log('values', values);
    // api.postData('/api/items/create_product_uom', values).then((response) => {
    //   if (response.data[0].Status === "200") {
    //     showSwalWithLink(response.data[0].Message);
    //     // setMeasurement(response.data[0].measurement_id);
    //     dispatch(setMeasurementId(response.data[0].measurement_id));
    //     dispatch(setProductSku(response.data[0].Product_master_sku));
    //     // setSuccess(true);
    //     getItemUomDetails();
    //   }
    // }).catch(error => {
    //   // Handle error
    //   console.error(error);
    // });
  };

  // const formik1 = useFormik({
  //   initialValues: {
  //     measurement_id: "0",
  //     product_id: "",
  //     merchant_id: "",
  //     product_complete_sku: "",
  //     product_metric: "",
  //     product_uom: "",
  //     no_of_units: "",
  //     one_unit_contains: "",
  //     base_uom: "",
  //     msr_price: "",
  //     cost_price: "",
  //     sale_price: "",
  //     maintain_stock: showState ? 0 : 1,
  //   },
  //   validationSchema: createUom,
  //   onSubmit: handleUomSubmit,
  // });

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const ref4 = useRef();

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleInputStatusChange = (item) => {
    let data = {
      product_stock_id: item.product_stock_id,
      product_id: item.product_id,
      measurement_id: item.measurement_id,
    };
    try {
      api.postData("/api/items/stock_status_change", data).then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
        }
      });
    } catch (error) {
      // Handle network error
    }
  };

  const ProductColumns = [
    {
      name: "product_batch_id",
      label: "Batch Id",
      //    label:'',
    },
    { name: "product_vendor_name", label: "Vendor Name" },
    { name: "", label: "Po Number" },
    { name: "product_purchase_date", label: "Po Date" },
    { name: "merchant_sub_segment_row", label: "Delivery Date" },
    {
      name: "product_qty_purchased",
      label: "Qty",
    },
    {
      name: "product_qty_sold",
      label: "Sale Quantity",
    },
    {
      name: "product_expiry_date",
      label: "Expiry Date",

    // options: {
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //         const rowIndex = tableMeta.rowIndex;
      //         const rowData = subSegment[rowIndex];
      //         return <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => { ref3.current.log(); editSSOption(rowData); }}>Edit</Button>
      //     },
      // },
      },
{
      name: "product_stock_status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = itemStockDetails[rowIndex];
          return (
            <IOSSwitch
              checked={value === 0}
              onChange={(e) => {
                handleItemStatusChange(rowData);
              }}
            />
          );
    },
        },
  },
    {
      name: "",
      label: "",

      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = itemStockDetails[rowIndex];
          return (
            <Button
              variant="outlined"
              color="error"
              mt={2}
              className="retail-btn"
              onClick={() => {
                ref4.current.log();
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
  ];

  const ingredientData = [
    [
      "63673",
      "Rice",
      "Basmati Rice",
      "Indian Gate Basmati Rice",
      "Weight",
      "Kg",
      "2Kg",
    ],
  ];
  const uomColumns = [
    {
      name: "product_complete_sku",
      label: "Master SKU Id",
      //    label:'',
    },
    {
      name: "product_metric",
      label: "Metric",
    },
    { name: "product_uom", label: "UOM" },
    { name: "no_of_units", label: "No of Units" },
    { name: "one_unit_contains", label: "One Unit Contains" },
    {
      name: "base_uom",
      label: "Base Measurement",
    },
    {
      name: "uom_status",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = itemUomDetails[rowIndex];
          return (
            <IOSSwitch
              checked={value === 0}
              onChange={() => {
                // handleUomStatusChange(rowData);s
              }}
            />
          );
        },
      },
    },
  ];

  const uomData = [["#98759", "Rice", "01", "Rs. 300", "hbs", "adc"]];

  const uomoptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: true,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const memeberColumns = [
    "Batch Id",
    { name: "Supplier Name", options: { filterOptions: { fullWidth: true } } },
    "PO Number",
    "PO Date",
    "Delivery Date",
    "Qty",
    // "Sale Quantity",
    "Expiry Date",
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
  };

  const data = [];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card store-item-card">
              <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link> */}
                    <Link underline="hover" color="inherit" href="#">
                      Menu
                    </Link>
                    <Typography color="text.primary">Add New Item</Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid>
              <CardContent className="add_new_item">
                <Card>
                  <CardContent>
                    {/* <Grid item md={12}>
                      <Box
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                        className="procurement-tabs"
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          className="tabs-custom"
                        >
                          <Tab
                            className="product-btn"
                            label="Item"
                            {...a11yProps(1)}
                          />
                          <Tab
                            className="distributor-btn"
                            label="Ingredients"
                            {...a11yProps(0)}
                          />
                        </Tabs>
                      </Box>
                    </Grid> */}
                    <Typography component="h4" className="sub-title">
                      Add New Ingredient Details
                    </Typography>
                    <TabPanel
                      value={value}
                      index={0}
                      className="custom-shift-tab"
                    >
                      <form onSubmit={formik.handleSubmit}>
                      <Box component="img" src={upload} mt={2} />
                      <Box mt={2}>
                        <Button
                          variant="contained"
                          component="label"
                          className="btn-upload"
                        >
                          Upload image
                          <input hidden accept="image/*" multiple type="file" />
                        </Button>
                      </Box>
                      <Grid container spacing={3} mt={1} mb={2}>
                      <Grid item xs={12} md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Item Name
                              </Typography>
                              <OutlinedInput
                                placeholder="Item Name"
                                name="product_name"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  // handleInputChange(e);
                                }}
                              />
                            </FormControl>
                            {errors.product_name && touched.product_name && (
                              <Box
                                Typography="small"
                                className="float-left text-danger"
                              >
                                {errors.product_name}
                              </Box>
                            )}
                          </Grid>
                        <Grid item xs={12} md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">Brand</Typography>
                            <OutlinedInput placeholder="Brand Name" name="brand_name"
                             onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              // handleInputChange(e);
                            }}
                            />
                            {errors.brand_name && touched.brand_name && (
                              <Box
                                Typography="small"
                                className="float-left text-danger"
                              >
                                {errors.brand_name}
                              </Box>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                        <FormControl className="w-100">
                              <Typography component="label">Segment</Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                name="product_segment_id"
                                value={formik.values.product_segment_id}
                                onChange={(e) => { handleChange(e); handleInputChange(e); getSubSegment(e.target.value) }}
                                onBlur={(e) => { handleBlur(e) }}
                              >
                                {segment?.map((item) => {

                                  return <MenuItem key={item.merchant_segment_name} value={item.merchant_segment_id}>
                                    {item.merchant_segment_name}
                                  </MenuItem>
                                })}
                              </Select>
                            </FormControl>
                            {errors.product_segment_id && touched.product_segment_id && <Box Typography="small" className="float-left text-danger">{errors.product_segment_id}</Box>}
                         
                        </Grid>
                        <Grid item xs={12} md={3}>
                        <FormControl className="w-100">
                              <Typography component="label">
                                Sub-Segment
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                name="product_sub_segment_id"
                                value={formik.values.product_sub_segment_id}
                                onChange={(e) => { handleChange(e); getMerchantSubSegment(e); handleInputChange(e); }}
                                onBlur={(e) => { handleBlur(e); }}
                              >

                                {subSegment?.map((item) => {

                                  return <MenuItem key={item.merchant_sub_segment_name} value={item.merchant_sub_segment_id}>
                                    {item.merchant_sub_segment_name}
                                  </MenuItem>
                                })}
                              </Select>
                            </FormControl>
                            {errors.product_sub_segment_id && touched.product_sub_segment_id && <Box Typography="small" className="float-left text-danger">{errors.product_sub_segment_id}</Box>}
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                      <Grid container spacing={2} marginY={2}>
                        <Grid item md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">Metric</Typography>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              name="product_metric"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                // handleInputChange(e);
                              }}
                              defaultValue="select"
                            >
                              <MenuItem value="select">Please Select</MenuItem>
                              <MenuItem value="weight">Weight</MenuItem>
                              <MenuItem value="volume">Volume</MenuItem>
                              <MenuItem value="count">Count</MenuItem>
                            </Select>
                          </FormControl>
                          {formik.errors.product_metric &&
                                formik.touched.product_metric && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {formik.errors.product_metric}
                                  </Box>
                                )}
                        </Grid>
                        <Grid item md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">UOM</Typography>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              name="product_uom"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                // handleInputChange(e);
                              }}
                              defaultValue="select"
                            >
                             {
                                  metricUom?.map((item) => (
                                    <MenuItem key={item.uom_name} value={item.uom_name}>
                                      {item.uom_name}
                                    </MenuItem>
                                  ))
                                }
                            </Select>
                          </FormControl>
                          {formik.errors.product_uom &&
                                formik.touched.product_uom && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {formik.errors.product_uom}
                                  </Box>
                                )}
                        </Grid>
                        <Grid item md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">
                              No of Units
                            </Typography>
                            <OutlinedInput  name="no_of_units"  onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              // handleInputChange(e);
                            }} />
                          </FormControl>
                          {formik.errors.no_of_units &&
                                formik.touched.no_of_units && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {formik.errors.no_of_units}
                                  </Box>
                                )}
                        </Grid>
                        <Grid item md={3}>
                          <FormControl className="w-100">
                            <Typography component="label">
                              Cost Price
                            </Typography>
                            <OutlinedInput name="cost_price"  onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              // handleInputChange(e);
                            }} />
                          </FormControl>
                          {formik.errors.cost_price &&
                                formik.touched.cost_price && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {formik.errors.cost_price}
                                  </Box>
                                )}
                        </Grid>
                      </Grid>
                      <Box className="w-100 float-right mb-15">
                      <Button
                            variant="outlined"
                            mt={2}
                            mb={2}
                            className="retail-btn float-right"
                            mr={2}
                            type="submit"
                          >
                            Submit
                          </Button>
                      </Box>
                      </form>
                      <Divider></Divider>
                      <Grid container mt={1}>
                        <Typography component="h4" className="font-bold">
                          NOTIFICATION
                        </Typography>
                        {/* <Grid container spacing={2}>
                          <Grid item md={4}>
                            <FormControlLabel
                              className="custom-checkbox"
                              control={<Checkbox />}
                              label="Perishable"
                              onClick={handleClick}
                            />
                          </Grid>
                          {showState && (
                            <Grid item md={4}>
                              <FormControl className="w-100 custom-fc">
                                <Typography component="label" mr={2}>
                                  Expiry Date
                                </Typography>
                                <OutlinedInput type="date" />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid> */}

                        <Grid container spacing={2} mt={1}>
                          <Grid item md={4}>
                            <FormControlLabel
                              className="custom-checkbox"
                              control={<Checkbox   onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                // handleInputChange(e);
                              }}/>}
                              label="IdleStock"
                            />
                          </Grid>
                          <Grid item md={4}>
                            <FormControl className="w-100 custom-fc">
                              <Typography component="label" mr={2}>
                                No of Days
                              </Typography>
                              <OutlinedInput   onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              // handleInputChange(e);
                            }} />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            md={4}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <Typography component="p" className="small">
                              After ___ Days an Alert will be Generated Saying
                              the Item is Idle
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={1} mb={1}>
                          <Grid item md={4}>
                            <FormControlLabel
                              className="custom-checkbox"
                              control={<Checkbox   onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                                // handleInputChange(e);
                              }} />}
                              label="LowStock"
                            />
                          </Grid>
                          <Grid item md={4}>
                            <FormControl className="w-100 custom-fc ">
                              <Typography component="label" mr={2}>
                                Threshold Value
                              </Typography>
                              <OutlinedInput   onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              // handleInputChange(e);
                            }} />
                            </FormControl>
                            
                          </Grid>
                          <Grid item md={4}>
                            <Typography component="p" className="small">
                              After Stock Comes Below ___ then an Alert will be
                              Generated Saying low stock into reports
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                      <Grid container>
                        <Box className="flex-between w-100" mt={2}>
                          <Typography className="font-bold" component="h4">
                            PRODUCT HISTORY
                          </Typography>
                          {/* <Typography component="p" className="ag-skuid">
                            BEV-TE-FSD-FF-23LI
                          </Typography> */}
                          <Button
                            variant="outlined"
                            mt={2}
                            mb={2}
                            className="retail-btn"
                            mr={2}
                            onClick={() => ref4.current.log()}
                          >
                            Add Product
                          </Button>
                        </Box>
                        <Box className="member-table w-100">
                          <MemberTable
                            columns={memeberColumns}
                            options={options}
                            data={data}
                          />
                        </Box>
                      </Grid>
                    </TabPanel>
                  </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <StoreNewItemModal ref={ref3}  />
        <AddProductModal ref={ref4} />
      </Box>
    </>
  );
}
