import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput, TextareaAutosize } from "@mui/material";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import MemberTable from "../Customer/MemberTable";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import api from "../Api";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
// import { yupResolver } from '@hookform/resolvers/yup';
import { addEmployee } from "../Validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const shiftdetailsColumns = [
  { name: "S.No", options: { filterOptions: { fullWidth: true } } },
  "Working Days",
  "Timings",
];

const shiftdetailsdata = [];

const managementdata = [];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    // console.log(action);
    console.dir(state);
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AddEmployeeModal = forwardRef((props, ref3) => {
  const response = useSelector((state) => state.user.user);
  let locationId = useSelector((state) => state.user.pos_location);
  let locations = useSelector((state) => state.user.location);
  const [complianceFile, setComplianceFile] = React.useState();
  const [empId, setEmpId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [edit, setEdit] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const [roleValue, setRoleValue] = React.useState(null);
  const changeEdit = () => setEdit((edit) => !edit);
  const [location, setLocation] = React.useState();
  const handleTab = (event, newValue) => {
    setValue(newValue);
  };
  const [inputValue, setInputValue] = useState("");
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const viColumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Inspection",
    "Status",
    "Comments",
  ];
   
  const viOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      // console.log(action);
      console.dir(state);
    },
  };
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      // props?.onUpdate(true);
      // handleClose();
    });
  };

  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };
  const [complianceFileName, setComplianceFileName] = React.useState();
  const handleDocComplianceSelection = (e) => {
   
    setComplianceFile(e.target.files[0]);
  };

  const [equipmentFile, setequipmentFile] = React.useState();
  const handleDocSelection = (e) => {

    setequipmentFile(e.target.files[0]);
  };

  const [uploadDocument, setUploadDocument] = React.useState();
  const handleUploadDocument = (e) => {

    setUploadDocument(e.target.files[0]);
  };

  const [complainceData, setComplianceData] = React.useState({
    compliance_name: "",
    compliance_number: "",
    expiry_date: "",
    compliance_file_name: "",
  });
  const [complainceTableData, setComplianceTableData] = React.useState();

  const uploadDocumentCompliance = (e) => {
    e.preventDefault();
    let data = new FormData();
    // console.log('complianceFile',complianceFile[0]);
    data.append("uploaded_file", complianceFile);
    api
      .postData("/api/employee_management/UploadFile", data)
      .then((response) => {
        // console.log("response", response);
        if (response.data.Status === 200) {
       
          setComplianceFileName(response.data.FileName);
          showSwalWithLink(response.data.Message);
          setComplianceTableData([complainceData]);
          return false;
          // alert(response.data.Message);
        } else {
          showSwalWithError(response.data.Message);
          return false;
        }
      }).catch((error) => {
        console.log(error)
      });
      return false
  };

 

  const managementColumns = [
    {
      name: "sno", 
      label: "S. NO",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1; 
        },
      },
    },
    
    {
      name: "compliance_name",
      label: "Name",
    },
    {
      name: "compliance_number",
      label: "Number",
    },
    // {
    //   name: "expiry_date",
    //   label: "Expiry Date",
    // },
    {
      name: "compliance_file_name",
      label: "Attachment",
    },
  ];

  const equipmentColumns = [
    {
      name: "sno", 
      label: "S. NO",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1; 
        },
      },
    },
    {
      name: "equipment_name",
      label: "NAME",
    },
    {
      name: "equipment_number",
      label: "NUMBER",
    },
  ];

  const uploadColumns = [
    {
      name: "sno", // Add a unique name for the S. No column
      label: "S. NO",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1; // Calculate and display the row index as S. No
        },
      },
    },
    {
      name: "file_name",
      label: "ATTACHMENT NAME",
    },
    {
      name: "doc_expiry_date",
      label: "EXPIRY DATE",
    },
  ];

  const handleCompliance = (e) => {
    const { name, value, files } = e.target;

    setComplianceData((prevData) => ({
      ...prevData,
      [name]:
        name === "compliance_file_name" && files && files.length > 0
          ? files[0].name
          : value,
    }));
  };
  const [docTableData, setDocTableData] = React.useState();
  const [docData, setDocData] = React.useState({
    file_name: "",
    doc_expiry_date: "",
  });
  const handleDocument = (e) => {
    const { name, value } = e.target;

    setDocData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetDocData = () =>{
    setDocTableData("");
  }
  const [equipmentData, setEquipmentData] = React.useState({
    equipment_name: "",
    equipment_number: "",
  });
  const [equipmentTableData, setEquipmentTableData] = React.useState();
  const handleEquipment = (e) => {
    const { name, value } = e.target;

    setEquipmentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetComplainceData = () => {
    setComplianceTableData("");
  };

  const resetEquipmentData = () => {
    setEquipmentTableData("");
  };
  const [uploadDocumentName, setUploadDocumentName] = React.useState();
  const uploadEmployeeDocument = () => {
    let data = new FormData();

    data.append("document_file", uploadDocument);
    api
      .postData("/api/employee_management/Document_upload", data)
      .then((response) => {
        if (response.data.Status === 200) {
          // setEmpId(response.data[0].new_employee_id)
          showSwalWithLink(response.data.Message);
          setUploadDocumentName(response.data.FileName);
          setDocTableData([docData]);
        } else {
          showSwalWithError(response.data.Message);
        }
      }).catch((error) => {
        console.log(error)
      });
  };
  const [equipmentFileName, setEquipmentFileName] = React.useState();
  const uploadEquipmentFile = () => {
    let data = new FormData();

    data.append("equipment_file", equipmentFile);
    api
      .postData("/api/employee_management/equipment_upload", data)
      .then((response) => {
        if (response.data.Status === 200) {
          setEquipmentFileName(response.data.FileName);
          // setEmpId(response.data.new_employee_id)
          showSwalWithLink(response.data.Message);
          setEquipmentTableData([equipmentData]);
        } else {
          showSwalWithError(response.data.Message);
        }
      }).catch((error) => {
        console.log(error)
      });
  };

  const handleSubmit = (values) => {
    // console.log('inputValue',inputValue);
 
      formik.values.role_id = inputValue;
      formik.values.compliance_file_name = complianceFileName;
      formik.values.files_information = uploadDocumentName;
      formik.values.equipment_file = equipmentFileName;
     
    //  console.log('formik.values',formik.values);
    //  console.log('values',values);
    if((docData?.doc_expiry_date && docData?.file_name) && (equipmentData?.equipment_name && equipmentData?.equipment_number)){
    api
      .postData("/api/employee_management/employee_management", formik.values)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          // setEmpId(response.data[0].new_employee_id)
          showSwalWithLink(response.data[0]?.Message);
          props?.getCurrentEmpDetails();
          handleClose();
        } else {
          showSwalWithError(response.data[0]?.Message);
        }
      }).catch((error) => {
        console.log(error)
      });
    }else{
      showSwalWithError("Please fill all details");
    }
  };

  const fetchCities = () => {
    api
      .getData(
        "/api/locations/get_Location_name_pos?merchant_id=" +
          response?.merchant_id +
          "&city_id=" +
          response?.city_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setLocation(JSON.parse(response.data[0].LocationDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCities();
  }, []);

const initialValues= {
  employee_id: "0",
  merchant_id: response?.merchant_id,
  first_name: "",
  // last_name: "",
  phone_number: "",
  email_id: "",
  gender: "",
  date_of_birth: "",
  location_id: "",
  address: "",
  employee_status: "0",
  role_id: "",
  job_type: "",
  pay_type: "",
  basic_pay: "",
  pay_period_unit: "",
  // bonus_comment: "",
  bank_name: "",
  bank_branch: "",
  ifsc: "",
  account_number: "",
  account_holder_name: "",
  employee_bank_status: "0",
  user_name: "",
  password: "",
  login_status: "0",
  compliance_number: "",
  compliance_name: "",
  compliance_file_name: complianceFileName,
  expiry_date: "",
  compliance_status: "0",
  doc_expiry_date: "",
  files_information: uploadDocumentName,
  file_name: "",
  equipment_name: "",
  equipment_number: "",
  equipment_file: equipmentFileName,
}
    
  const formik = useFormik({
    initialValues,
    validationSchema: addEmployee,
    onSubmit: handleSubmit,
    
    // enableReinitialize: true,
    // resolver: yupResolver(addEmployee)
   
  });

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      // console.log(action);
      console.dir(state);
    },
  };

  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
      // formik.setTouched({}, false);
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={style} className="view-more-content add-employee-modal">
            <Box className="modal-header p-15">
              <Typography
                id="modal-modal-title"
                variant="h5"
                className="m-heading"
                component="h5"
              >
                Employee Profile
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Box>
            <Divider></Divider>
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">
                    Personal Information
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {console.log(formik)}
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Name*</Typography>
                  <OutlinedInput
                    placeholder="First Name"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.first_name && touched.first_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.first_name}
                    </Box>
                  )}
                </FormControl>
              </Grid>
            
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Phone Number</Typography>
                  <OutlinedInput
                    placeholder="Phone Number"
                    name="phone_number"
                    value={formik.values.phone_number}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.phone_number && touched.phone_number && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.phone_number}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Address*</Typography>
                  <OutlinedInput
                    placeholder="Address"
                    name="address"
                    value={formik.values.address}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.address && touched.address && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.address}
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Email ID*</Typography>
                  <OutlinedInput
                    placeholder="Email"
                    name="email_id"
                    value={formik.values.email_id}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.email_id && touched.email_id && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.email_id}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Gender*</Typography>
                  <OutlinedInput
                    placeholder="Gender"
                    name="gender"
                    value={formik.values.gender}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.gender && touched.gender && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.gender}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Dob*</Typography>
                  <OutlinedInput
                    type="Date"
                    name="date_of_birth"
                    value={formik.values.date_of_birth}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.date_of_birth && touched.date_of_birth && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.date_of_birth}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Address</Typography>
                  <TextareaAutosize
                    name="address"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    minRows={2}
                    multiline={true}
                  />
                  {errors.address && touched.address && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.address}
                    </Box>
                  )}
                </FormControl>
              </Grid> */}
            </Grid>
            {/* <Box className="w-100">
              <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right mr-15' type='submit'>Submit</Button>
            </Box>
          */}
            <Grid container spacing={2} mt={3} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">Role Details</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Role Name*</Typography>
                  {/* <OutlinedInput placeholder="Job Name"   onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }} name="role_id" /> */}
                    <Autocomplete
                          value={roleValue}
                          name="role_id"
                      
                          className="select autoselect"
                          onChange={(event, newValue) => {
                            // getIngredientDetailsByProductname(newValue);
                            setInputValue(newValue?.role_id);
                            if (typeof newValue === "string") {
                              setRoleValue({
                                title: newValue,
                              });
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setRoleValue({
                                title: newValue.inputValue,
                              });
                            } else {
                              setRoleValue(newValue);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          filterOptions={(options, params) => {
                            try {
                              options = Array.isArray(options) ? options : [];

                              const filtered = options?.filter((option) =>
                                option?.role_name.toLowerCase().includes(
                                  params.inputValue.toLowerCase()
                                )
                              );

                              const { inputValue } = params;

                              const isExisting = options?.some(
                                (option) => inputValue === option?.role_id
                              );

                              if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  title: `Add "${inputValue}"`,
                                });
                              }

                              return filtered;
                            } catch (error) {
                              console.error("Error filtering options:", error);
                              return [];
                            }
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          options={props?.jobRoles || []}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option?.inputValue) {
                              return option?.inputValue;
                            }
                            return option?.role_name;
                          }}
                          style={{ height: "0px" }}
                          renderOption={(props, option) => (
                            <li {...props} value={option?.role_id}>
                              {option?.role_name}
                            </li>
                          )}
                          freeSolos
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="role_id"
                              onBlur={handleBlur}
                              onChange ={(e) => {
                            
                                formik.handleChange(e);
                              }}
                              
                            />
                          )}
                        />
                  {/* <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="role_id"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  >
                    {props?.jobRoles
                      ? props.jobRoles.map((item) => {
                          return (
                            <MenuItem value={item.role_id}>
                              {item.role_name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select> */}
                  {/* {errors.role_id && touched.role_id && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.role_id}
                    </Box>
                  )} */}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Job Type*</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    value={formik.values.job_type}
                    name="job_type"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    defaultValue="0"
                  >
                    <MenuItem value="0">Job Type</MenuItem>
                    <MenuItem value="Part Time">Part Time</MenuItem>
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Contract">Contract</MenuItem>
                  </Select>
                  {errors.job_type && touched.job_type && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.job_type}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Location*</Typography>
                {/* <OutlinedInput placeholder="Location" value="Hyderabad" /> */}
                <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="location_id"
                    value={formik.values.location_id}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => { handleBlur(e); }}
                  >
                    {location?.map((item) => {
                    return (
                      <MenuItem value={item.merchant_location_id}>
                        {item.location_name}
                      </MenuItem>
                    );
                  })}

                  </Select>
                  {errors.location_id && touched.location_id && <Box Typography="small" className="float-left text-danger">{errors.location_id}</Box>}
              </FormControl>
            </Grid>
              <Grid item md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Pay Type*</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="pay_type"
                    defaultValue="0"
                    value={formik.values.pay_type}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  >
                    <MenuItem value="0">Pay Type</MenuItem>
                    <MenuItem value="Per Hour">Per Hour</MenuItem>
                    <MenuItem value="Per Week">Per Week</MenuItem>
                    <MenuItem value="Per Day">Per Day</MenuItem>
                    <MenuItem value="Per Month">Per Month</MenuItem>
                    {/* <MenuItem value="Per Transaction">Per Transaction</MenuItem> */}
                  </Select>
                </FormControl>
                {errors.pay_type && touched.pay_type && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.pay_type}
                  </Box>
                )}
              </Grid>
              <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Pay Period Unit*</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  name="pay_period_unit"
                  value={formik.values.pay_period_unit}
                  className="select viewmore-dpdown"
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  defaultValue=""
                >
                  <MenuItem value="0">Select</MenuItem>
                  <MenuItem value="Per Day / 8 Hours">Per Day / 8 Hours</MenuItem>
                  <MenuItem value="Per Week / 5 Days">Per Week / 5 Days</MenuItem>
                  <MenuItem value="Per Month / 26 Days">Per Month / 26 Days</MenuItem>
                </Select>
                {errors.pay_period_unit && touched.pay_period_unit && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.pay_period_unit}
                    </Box>
                  )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Base Pay</Typography>
                  <OutlinedInput
                    name="basic_pay"
                    value={formik.values.basic_pay}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.basic_pay && touched.basic_pay && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.basic_pay}
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {/* <Grid container spacing={2} mt={1} paddingRight={2}>
              
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Amount</Typography>
                  <OutlinedInput
                    name="bonus"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.bonus && touched.bonus && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.bonus}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Bonus Comments</Typography>
                  <OutlinedInput
                    name="bonus_comment"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.bonus_comment && touched.bonus_comment && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.bonus_comment}
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid> */}

            {/* <Grid container spacing={2} mt={3} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">Shift Details</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} >
              <Grid item md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Employee Name</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="employee_id"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => { handleBlur(e); }}
                  >

                    {
                      (props?.employeeNames) ? props.employeeNames.map((item) => {
                        return <MenuItem value={item.employee_id}>{item.employee_name}</MenuItem>
                      }) : null
                    }

                  </Select>

                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Location</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="location_id"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => { handleBlur(e); }}
                  >
                    {
                      locations?.map((item) => {

                        return item.location_status === 0 ? <MenuItem value={item.merchant_location_id}>{item.location_name}</MenuItem> : <></>


                      })
                    }

                  </Select>
                  {errors.location_id && touched.location_id && <Box Typography="small" className="float-left text-danger">{errors.location_id}</Box>}

                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Start Date</Typography>
                  <OutlinedInput type="date"
                    name="start_date"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => { handleBlur(e); }}
                  />
                  {errors.start_date && touched.start_date && <Box Typography="small" className="float-left text-danger">{errors.start_date}</Box>}

                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">End Date</Typography>
                  <OutlinedInput type="date"
                    name="end_date"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => { handleBlur(e); }}
                  />
                  {errors.end_date && touched.end_date && <Box Typography="small" className="float-left text-danger">{errors.end_date}</Box>}

                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Start Time</Typography>
                  <OutlinedInput type="time"
                    name="start_time"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => { handleBlur(e); }}
                  />
                  {errors.start_time && touched.start_time && <Box Typography="small" className="float-left text-danger">{errors.start_time}</Box>}

                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">End Time</Typography>
                  <OutlinedInput type="time"
                    name="end_time"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => { handleBlur(e); }}
                  />
                  {errors.end_time && touched.end_time && <Box Typography="small" className="float-left text-danger">{errors.end_time}</Box>}

                </FormControl>
              </Grid>
            </Grid> */}
            <Grid container spacing={2} mt={3} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary flex-between">
                  <Typography className="p-info">Compliance</Typography>
                  {/* <Button
                    variant="outlined"
                    color="error"
                    className="btn-outline-primary"
                    onClick={() => {
                      addComplainceData();
                    }}
                  >
                    Add
                  </Button> */}
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              mt={1}
              mb={3}
              paddingRight={2}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Name</Typography>
                  <OutlinedInput
                    name="compliance_name"
                    value={formik.values.compliance_name}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleCompliance(e);
                    }}
                  />
                  {errors.compliance_name && touched.compliance_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.compliance_name}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Number</Typography>
                  <OutlinedInput
                    name="compliance_number"
                    value={formik.values.compliance_number}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleCompliance(e);
                    }}
                  />
                  {errors.compliance_number && touched.compliance_number && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.compliance_number}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Expiry Date</Typography>
                  <OutlinedInput
                    name="expiry_date"
                    onChange={(e) => handleChange(e)}
                    value={formik.values.expiry_date}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleCompliance(e);
                    }}
                    type="date"
                  />
                  {errors.expiry_date && touched.expiry_date && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.expiry_date}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Choose File</Typography>
                  <OutlinedInput
                    name="compliance_file_name"
                    onChange={(e) => {
                      // handleChange(e);
                      handleDocComplianceSelection(e);
                    }}
                    onBlur={(e) => {
                      // handleBlur(e);
                      handleCompliance(e);
                    }}
                    accept=".pdf"
                    type="file"
                  />
                  {errors.compliance_file_name &&
                    touched.compliance_file_name && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.compliance_file_name}
                      </Box>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={2}>
                <Button
                  variant="outlined"
                  color="error"
                  type="button"
                  component="button"
                  className="btn-outline-primary"
                  onClick={(e) => {
                    uploadDocumentCompliance(e);
                  }}
                >
                  Upload
                </Button>
              
              <Button
                    variant="outlined"
                    color="error"
                   
                    className="btn-outline-primary float-right"
                    onClick={() => {
                      resetComplainceData();
                    }}
                  >
                    Reset
                  </Button>
              </Grid>
              <Grid item md={12}>
                <MemberTable
                  columns={managementColumns}
                  options={managementOptions}
                  data={complainceTableData?complainceTableData:[]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={3} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">
                    Financial Information
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} mb={3} paddingRight={2}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Bank Name*</Typography>
                  <OutlinedInput
                    name="bank_name"
                    onChange={(e) => handleChange(e)}
                    value={formik.values.bank_name}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.bank_name && touched.bank_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.bank_name}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Branch Name*</Typography>
                  <OutlinedInput
                    name="bank_branch"
                    value={formik.values.bank_branch}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.bank_branch && touched.bank_branch && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.bank_branch}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">IFSC Code*</Typography>
                  <OutlinedInput
                    name="ifsc"
                    value={formik.values.ifsc}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.ifsc && touched.ifsc && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.ifsc}
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} mb={3}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Account Number*</Typography>
                  <OutlinedInput
                    name="account_number"
                    value={formik.values.account_number}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.account_number && touched.account_number && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.account_number}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Account Holder Name*</Typography>
                  <OutlinedInput
                    name="account_holder_name"
                    value={formik.values.account_holder_name}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.account_holder_name &&
                    touched.account_holder_name && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.account_holder_name}
                      </Box>
                    )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">
                    Username & Password
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              mt={1}
              mb={3}
              style={{ display: "flex", alignItems: "flex-end" }}
            >
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Username*</Typography>
                  <OutlinedInput
                    name="user_name"
                    value={formik.values.user_name}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.user_name && touched.user_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.user_name}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Password*</Typography>
                  <OutlinedInput
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.password && touched.password && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.password}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4} textAlign={"center"}>
                <Button
                  variant="outlined"
                  color="error"
                  mb={2}
                  
                  className="btn-outline-primary"
                  
                >
                  Update
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Tabs
                  value={value}
                  onChange={handleTab}
                  aria-label="basic tabs example"
                  className="custom-tab"
                >
                  {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                  <Tab label="Equipment Details" {...a11yProps(0)} />
                  <Tab label="Upload Documents" {...a11yProps(1)} />
                </Tabs>
              </Grid>
              <Grid item md={12}>
                {/* <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Days</Typography>
                      <OutlinedInput
                        name="first_name"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => { handleBlur(e); }}
                        type="date" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Start Time</Typography>
                      <OutlinedInput
                        name="first_name"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => { handleBlur(e); }}
                        type="time" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">End TIme</Typography>
                      <OutlinedInput
                        name="first_name"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => { handleBlur(e); }}
                        type="time" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      variant="outlined"
                      color="error"
                      mb={2}
                      className="btn-outline-primary"
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <MemberTable
                      columns={shiftdetailsColumns}
                      options={managementOptions}
                      data={shiftdetailsdata}
                    />
                  </Grid>
                </Grid>
              </TabPanel> */}
                <TabPanel value={value} index={0}>
                  <Grid
                    container
                    spacing={2}
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">Name*</Typography>
                        <OutlinedInput
                          name="equipment_name"
                          value={formik.values.equipment_name}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleEquipment(e);
                          }}
                        />
                        {errors.equipment_name && touched.equipment_name && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.equipment_name}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">Number*</Typography>
                        {/* <Typography component="label">Expiry Date*</Typography> */}
                        <OutlinedInput
                          name="equipment_number"
                          value={formik.values.equipment_number}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleEquipment(e);
                          }}
                        />
                        {errors.equipment_number &&
                          touched.equipment_number && (
                            <Box
                              Typography="small"
                              className="float-left text-danger"
                            >
                              {errors.equipment_number}
                            </Box>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        {/* <Typography component="label">Choose File</Typography>
                      <OutlinedInput
                        name="equipment_file"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => { handleBlur(e); }}
                        type="file" /> */}
                        <OutlinedInput
                          name="equipment_file"
                          onChange={(e) => {
                            handleChange(e);
                            handleDocSelection(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          accept=".pdf"
                          type="file"
                        />
                        {errors.equipment_file && touched.equipment_file && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.equipment_file}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        variant="outlined"
                        color="error"
                        mb={2}
                        type="button"
                        className="btn-outline-primary"
                        onClick={() => {
                          uploadEquipmentFile();
                        }}
                      >
                        Upload
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        className="btn-outline-primary ml-15"
                        onClick={() => {
                          resetEquipmentData();
                        }}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item md={12}>
                    <MemberTable
                        columns={equipmentColumns}
                        options={managementOptions}
                        data={equipmentTableData?equipmentTableData:[]}
                      />
                      
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid
                    container
                    spacing={2}
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">
                          Attachments Name*
                        </Typography>
                        <OutlinedInput
                          name="file_name"
                          value={formik.values.file_name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);handleDocument(e)
                          }}
                        />
                        {errors.file_name && touched.file_name && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.file_name}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">Expiry Date</Typography>
                        {/* <Typography component="label">Number*</Typography> */}
                        <OutlinedInput
                          name="doc_expiry_date"
                          value={formik.values.doc_expiry_date}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            handleBlur(e);handleDocument(e)
                          }}
                          type="date"
                        />
                        {errors.doc_expiry_date && touched.doc_expiry_date && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.doc_expiry_date}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">Choose File</Typography>
                        <OutlinedInput
                          name="files_information"
                          onChange={(e) => {
                            handleChange(e);
                            handleUploadDocument(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          accept=".pdf"
                          type="file"
                        />
                        {errors.files_information && touched.files_information && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.files_information}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        variant="outlined"
                        color="error"
                        mb={2}
                        type="button"
                        className="btn-outline-primary"
                        onClick={() => {
                          uploadEmployeeDocument();
                        }}
                      >
                        Upload
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        className="btn-outline-primary ml-15"
                        onClick={() => {
                          resetDocData();
                        }}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item md={12}>
                    <MemberTable
                        columns={uploadColumns}
                        options={managementOptions}
                        data={docTableData?docTableData:[]}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
            </Grid>
            <Divider></Divider>
            <Box textAlign={"right"} marginX={2} marginY={2}>
              <Button
                variant="outlined"
                color="error"
                mb={2}
                className="btn-outline-primary"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
      <Modal
        open={addOpen}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="add-user-modal" sx={style}>
          <Box className="add-user-div p-15">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New user
            </Typography>
            <CloseIcon onClick={handleCloseAdd} />
          </Box>
          <Divider></Divider>
          <Grid
            className="inputfield-container"
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={2}
          >
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">First Name</Typography>
                <OutlinedInput
                  name="first_name"
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Last Name</Typography>
                <OutlinedInput
                  name="last_name"
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Phone No</Typography>
                <OutlinedInput
                  name="phone_number"
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Email</Typography>
                <OutlinedInput
                  name="Email"
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Password</Typography>
                <OutlinedInput
                  name="password"
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Button
            variant="outlined"
            color="error"
            mt={2}
            mb={2}
            className="save-btn p-15"
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
});

export default AddEmployeeModal;
