import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import upload from "../images/upload.svg";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import api from "../Api";
import { Formik, useFormik, Form, Field } from "formik";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { AddItemSchemaItem } from "../Validation";
import Switch from "@mui/material/Switch";
import cross1 from "../images/cross.svg";
import AddIngredientsMeasurements from "./AddIngredientMeasurement";

import {
  setMeasurementId,
  setProductId,
  setProductSku,
  setCity,
} from "../Redux/actions/userActions";

import status from "../images/order.svg";
import MemberTable from "../Customer/MemberTable";
import Stack from "@mui/material/Stack";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 25,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StoreNewItemModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [edit, setEdit] = React.useState(true);
  const [cities, setCities] = React.useState();
  const response = useSelector((state) => state.user.user);
  const [ingredient, setIngredient] = React.useState();
  const productIdGlobal = useSelector((state) => state.user.product_id);
  const [spineer, setSpinner] = React.useState(true);
  // const response = useSelector((state) => state.user.profile);
  const productId = useSelector((state) => state.user.product_id);
  const locations = useSelector((state) => state.user.location);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    props?.getItemStockDetails();
    setOpen(false);
    
  };
 
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleOpenEdit = () => setShow(true);
  const handleCloseAdd = () => setAddOpen(false);
  const [metricUom, setMetricUom] = React.useState();
  const ingredientNames = props.ingredientNames;
  const [uom, setUom] = React.useState("");
  const [productSku, setProductSkus] = React.useState();
  const [segmentsuggestions, setSuggestions] = React.useState();
  const dispatch = useDispatch();
  const [brandName, setBrandName] = React.useState("nonVeg");
  const [subSegment, setSubSegment] = React.useState();
  const getBrandName = (value) => {
    setBrandName(value);
  };
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {});
  };
  const showSwalWithLinkError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    });
  };
  const fetchData = () => {
    api
      .getData("/api/config/cities_list?countryid=" + response?.country_id)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCities(
            JSON.parse(response.data[0].Cities).filter((item) => {
              return item.city_state === "Andhra Pradesh";
            })
          );
          setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ref1 = React.useRef();
  const getSubSegment = (segmentId) => {
    api
      .getData(
        "/api/segments/get_merchant_sub_segment_details?merchant_id=" +
          response?.merchant_id +
          "&merchant_segment_id=" +
          segmentId
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setSubSegment(
            JSON.parse(response.data[0].Merchant_Sub_Segement_Details)
          );
          if (
            JSON.parse(response.data[0].Merchant_Sub_Segement_Details) === null
          ) {
            // showSwalWithLinkProfileError("Profile is Not Completed. Please Add Location");
          }
          // setIsLoading(false);
        }
      });
  };
  const fetchIngredientTableData = () => {
    api
      .getData(
        "/api/segments/get_mapping_items_details?product_id=" + productIdGlobal
      )
      .then((response) => {
        if (response.data[0].Status === "200") {
          setIngredient(JSON.parse(response.data[0].Item_Ingredient_Details));
          //    setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [images, setImages] = React.useState([]);
  const [displayImages, setDisplayImages] = React.useState([]);
  const handleImageUpload = (e) => {
    const selectedImages = Array.from(e.target.files);
    const selectedFiles = e.target.files;
    const blobImages = Array.from(selectedFiles).map((file) =>
      URL.createObjectURL(file)
    );
    setDisplayImages(blobImages);
    setImages(selectedImages);
  };
  // useEffect(() => {
  //   if (itemStock.product_image) {
  //     const imageArray = itemStock.product_image?.split(",");
  //     setImages(imageArray);
  //   }
  //   if(itemStock.merchant_segment_id){
  //        setValue(itemStock.merchant_segment_id);
  //   }
  // }, [itemStock]);
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    setIngredient(props.ingredient);
  }, [props.ingredient]);
  const getUom = (e) => {
    const { name, value } = e.target;
    setUom(value);
  };
  const showSwalWithLinkProfileError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    }).then(function () {
      //  navigate("/admin/Profile");
    });
  };
  const handleSubmit = async (values) => {
    //

    let formData = new FormData();

    formData.append("product_id", formik.values.product_id || 0);
    formData.append("merchant_id", response?.merchant_id);
    formData.append("city_id", response?.city_id);
    formData.append(
      "location_id",
      locations ? locations[0].merchant_location_id : "0"
    );
    formData.append("product_name", values?.product_name);
    formData.append("brand_name", brandName);
    formData.append("product_quantity", "10");
    formData.append("product_description", values?.product_description);
    formData.append("product_how_to_use", "NA");
    formData.append("product_specifications", "NA");
    formData.append("product_segment_id", formik.values?.product_segment_id);
    formData.append("product_sub_segment_id", values?.product_sub_segment_id);
    formData.append("product_aisle", 1);
    formData.append("product_row", 1);

    formData.append("product_master_sku", "NA");
    formData.append(
      "product_status",
      values?.product_status ? values.product_status : "0"
    );
    formData.append("ptype", "Product");
    if (images.length > 0) {
      images.forEach((image, index) => {
        formData.append("productimage" + (index + 1), image);
      });
    }
    // let imageBlob = await fetchAndConvertToBlob(itemStock?.product_image);

    if (images.length === 0) {
      try {
        const imageUrl = props.itemStock?.product_image?.split(",")[0];

        const fetchAndConvertToBlob = async (url) => {
          try {
            const response = await fetch(url);
            const arrayBuffer = await response.arrayBuffer();
            const blob = new Blob([arrayBuffer]);
            return blob;
          } catch (error) {
            console.error("Error fetching the image:", error);
            return null;
          }
        };

        const imageBlob = await fetchAndConvertToBlob(imageUrl);

        if (imageBlob) {
          formData.append("productimage1", imageBlob);
        }
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }

    try {
      api.postData("/api/items/items_create_update", formData).then((res) => {
        console.log("response", res);
        if (res.data[0].Status === "200") {
          if (formik.values.product_uom) {
            if (formik.values.product_uom === "kgs") {
              formik.values.one_unit_contains = 1000;
              formik.values.base_uom = "grams";
            } else if (formik.values.product_uom === "grams") {
              formik.values.one_unit_contains = 1;
              formik.values.base_uom = "grams";
            } else if (formik.values.product_uom === "lbs") {
              formik.values.one_unit_contains = 453.592;
              formik.values.base_uom = "grams";
            } else if (formik.values.product_uom === "quintals") {
              formik.values.one_unit_contains = 100000;
              formik.values.base_uom = "grams";
            } else if (formik.values.product_uom === "tonnes") {
              formik.values.one_unit_contains = 1000000;
              formik.values.base_uom = "grams";
            } else if (formik.values.product_uom === "liters") {
              formik.values.one_unit_contains = 1000;
              formik.values.base_uom = "ml";
            } else if (formik.values.product_uom === "ml") {
              formik.values.one_unit_contains = 1;
              formik.values.base_uom = "ml";
            }
          }
          // if (!formik.values.product_id) {
          //   formik.values.product_id = res.data[0].ProductId;
          // }
          dispatch(setProductId(res.data[0].ProductId));
          const data = {
            measurement_id: formik.values.measurement_id || "0",
            product_id: res.data[0].ProductId || formik.values.product_id,
            merchant_id: response?.merchant_id,
            product_complete_sku: "NA",
            product_metric: formik.values.product_metric,
            product_uom: formik.values.product_uom,
            no_of_units: formik.values.no_of_units,
            one_unit_contains: "1",
            base_uom: "1",
            msr_price: "0",
            cost_price: "0",
            sale_price: formik.values.sale_price,
            maintain_stock: 1,
          };

          api
            .postData("/api/items/create_product_uom", data)
            .then((response) => {
              if (response.data[0].Status === "200") {
                showSwalWithLink(response.data[0].Message);
                // setMeasurement(response.data[0].measurement_id);
                dispatch(setMeasurementId(response.data[0].measurement_id));
                // dispatch(setProductSku(response.data[0].Product_master_sku));
                // setSuccess(true);
                // getItemUomDetails();
                // props?.getItemStockDetails();
              }
            })
            .catch((error) => {
              // Handle error
              console.error(error);
            });
          // dispatch(setProductSku(response.data[0].Product_master_sku));
          // setProductId(response.data[0].Product_master_sku)
          // setSuccess(true);
        } else {
          showSwalWithLinkError(res.data[0].Message);
        }
      });
    } catch (error) {
      // Handle network error
    }

    // setSubmitting(false);
  };

  const changeEdit = () => {
    setEdit((edit) => !edit);
    //  if(edit === false){
    //   handleSubmit();
    //  }
  }

  const initialValues = {
    product_id: props.itemStock?.product_id || "",
    merchant_id: props.itemStock?.merchant_id || "",
    city_id: props.itemStock?.city_id || "",
    location_id: props.itemStock?.location_id || "",
    product_name: props.itemStock?.product_name || "",
    brand_name: props.itemStock?.brand_name || "",
    measurement_id: props.itemStock?.measurement_id || "",
    product_quantity: props.itemStock?.product_quantity || "",
    product_description: props.itemStock?.product_description || "",
    product_how_to_use: props.itemStock?.product_how_to_use || "",
    product_specifications: props.itemStock?.product_specifications || "",
    product_segment_id: props.itemStock?.merchant_segment_id || "",
    product_sub_segment_id: props.itemStock?.merchant_sub_segment_id || "",
    product_aisle: props.itemStock?.product_aisle || "",
    product_row: props.itemStock?.product_row || "",
    product_metric: props.itemStock?.product_metric || "",
    product_uom: props.itemStock?.product_uom || "",
    no_of_units: props.itemStock?.no_of_units || "",
    one_unit_contains: props.itemStock?.one_unit_contains || "",
    base_uom: props.itemStock?.product_uom || "",
    sale_price: props.itemStock?.sale_price || "",
    product_master_sku: props.itemStock?.product_master_sku || "",
    product_status: props.itemStock?.product_status || "",
    product_image1: props.itemStock?.product_image1 || "",
    product_image2: props.itemStock?.product_image2 || "",
    product_image3: props.itemStock?.product_image3 || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddItemSchemaItem,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    formik.setValues(initialValues);
    getSubSegment(props.itemStock?.merchant_segment_id);
    getUomData(props.itemStock?.product_metric);
    if (props.itemStock?.brand_name) {
      setBrandName(props.itemStock?.brand_name);
    }
    if (props.itemStock?.product_image) {
      setDisplayImages([props.itemStock?.product_image]);
    }
    
  }, [props.itemStock]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const segmentValue =
      name === "product_segment_id" ? value : formik.values.product_segment_id;
    const subsegmentValue =
      name === "product_sub_segment_id"
        ? value
        : formik.values.product_sub_segment_id;

    const brandNameValue = formik.values.brand_name;
    const itemNameValue = formik.values.product_name;

    const selectedsegmentMenuItem = segmentsuggestions.find(
      (item) => item.segement_id === segmentValue
    );
    const selectedSubMenuItem = subSuggestions.find(
      (item) => item.sub_segment_id === subsegmentValue
    );

    const segmentSubstring =
      selectedsegmentMenuItem?.segment_name?.substring(0, 3) || "";
    const subsegmentSubstring =
      selectedSubMenuItem?.sub_segment_name?.substring(0, 2) || "";
    const brandNameSubstring = brandNameValue?.substring(0, 3) || "";
    const itemNameSubstring = itemNameValue?.substring(0, 3) || "";

    const newProductId = `${segmentSubstring}-${subsegmentSubstring}-${brandNameSubstring}-${itemNameSubstring}`;

    setProductSkus(newProductId);

    if (formik1.values.no_of_units || formik1.values.product_uom) {
      const product_uom =
        name === "product_uom" ? value : formik1.values.product_uom;
      const product_uomstring = product_uom?.substring(0, 2) || "";
      const itemNameno_of_units = formik1.values.no_of_units;

      const productSku12 = `${newProductId}-${
        itemNameno_of_units.length === 1
          ? "0" + itemNameno_of_units
          : itemNameno_of_units.substring(0, 2)
      }${product_uomstring}`;
      setProductSkus(productSku12);
      dispatch(setProductSku(productSku12));
    }
  };
  const getUomData = (val) => {
    api
      .getData("/api/items/metric_uom_details_metric_name?metric_name=" + val)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          console.log(
            "JSON.parse(response.Metric_uom_details)",
            JSON.parse(response.data[0]?.Metric_uom_details)
          );
          setMetricUom(JSON.parse(response.data[0]?.Metric_uom_details));
          setSpinner(false);
        } else {
          showSwalWithLinkError(response.data[0].Message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUomSubmit = (values) => {
    //  return false;
    api
      .postData("/api/items/create_product_uom", values)
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          // setSuccess(true);
          props?.getItemStockDetails();
        } else {
          showSwalWithLinkError(response.data[0].Message);
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };
  // const formik1 = {
  //     'measurement_id': '',
  //     'product_id': '',
  //     'merchant_id': '',
  //     'product_complete_sku': '',
  //     'product_metric': '',
  //     'product_uom': '',
  //     'no_of_units': '',
  //     'one_unit_contains': '',
  //     'base_uom': '',
  //     'msr_price': '',
  //     'cost_price': '',
  //     'sale_price': '',
  //     'maintain_stock': ''

  //   };

  const formik1 = useFormik({
    initialValues: {
      measurement_id: "0",
      product_id: productId,
      merchant_id: response?.merchant_id,
      product_complete_sku: "",
      product_metric: "",
      product_uom: "",
      no_of_units: "",
      one_unit_contains: "",
      base_uom: "",
      msr_price: "",
      cost_price: "",
      sale_price: "",
      maintain_stock: "",
    },
    // validationSchema: RegisterSchema,
    onSubmit: handleUomSubmit,
  });
  // useEffect(() => {
  //   if (props.itemStock?.product_image) {
  //     const imageArray = props.itemStock?.product_image?.split(",");
  //     setImages(imageArray);
  //   }

  // }, [props?.itemStock]);
  const data = [
    [
      "12/03/2023",
      "474858",
      "Accepted",
      "₹ 22890",
      "Pending",
      "Cheque",
      "12345678",
      "12/03/2023",
    ],
  ];
  const deleteCartItem = (rowData,index) => {

    // const rowDatas = props.ingredient ? props.ingredient[index] : [];
   
    if(rowData){
    let data = {
      product_id: rowData?.product_id,
      ingredient_id: rowData?.ingredient_id,
    };
    api
      .postData("/api/segments/Mapping_items_Delete", data)
      .then((response) => {
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          // props?.getItemStockDetails(rowData?.product_id);
          props?.fetchIngredientTableData();
          // setInvoiceId(response.data[0].invoice_id);
        } else {
          // showSwalWithError(response.data[0].Message);
        }
      })
      .catch((error) => {
        console.log(error);
        // Handle the error here, e.g., show an error message to the user
      });
    }
    
  };
  const managementColumns = [
    { name: "ingredient_name", label: "Ingredient Name" },
    { name: "uom", label: "UOM" },
    { name: "required_qty", label: "No. of Units" },
    { name: "required_qty", label: "Required Qty" },
    {
      name: "",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
       
          const rowData = props.ingredient ? props.ingredient[rowIndex] : [];
          return (
            <img
              className="cross"
              src={cross1}
              onClick={() => { 
                deleteCartItem(rowData,rowIndex);
              }}
            />
          );
        },
      },
    },
  ];
  const managementdata = [["India Gate Basmati Rice", "gm", "150gms"]];

  const managementOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const memeberColumns = [
    { name: "PO Date", options: { filterOptions: { fullWidth: true } } },
    "PO ID",
    "PO Status",
    "PO Total Invoice Amount",
    "Payment Status",
    "Transaction Type",
    "Transaction ID",
    "Transaction Date",
    {
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Select
              labelId="demo-isolation-label"
              id="isolation-select"
              className="select retaile-select"
              // onChange={handleSelectOxygen}
              // input={<OutlinedInput />}
              defaultValue="locations"
            >
              <MenuItem value="locations">Channel</MenuItem>
              <MenuItem>Download</MenuItem>
              <MenuItem>Email</MenuItem>
              <MenuItem>Email Accounting</MenuItem>
            </Select>
          );
        },
      },
    },
    {
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              onClick={() => console.log(value, tableMeta)}
              className="retail-btn"
            >
              Payment
            </Button>
          );
        },
      },
    },
  ];

  const ref4 = useRef();

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const [subSuggestions, setSubSuggestions] = React.useState();
  const subSegmentSuggestions = () => {
    api
      .getData("/api/segments/master_sub_segments?segment_id=1")
      .then((response) => {
        console.log("response", response);
        //   if (JSON.parse(response.data[0].Status) === 200) {

        setSubSuggestions(response.data);
        // setSpinner(false);
        //   }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    subSegmentSuggestions();
  }, []);

  useImperativeHandle(ref3, () => ({
    log() {
     
      handleOpen();
      setDisplayImages([]);
      if (props.itemStock?.product_image) {
        setDisplayImages([props.itemStock?.product_image]);
      }
      formik.setTouched({},false);
    },
  }));
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="view-more-content retail-content sni-content"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              {props?.functionality === "edit"
                ? "ITEM ID: " + props.itemStock?.product_id
                : "ADD ITEM"}
            </Typography>
            <Box className="sub-modal-header">
              <Button
                variant="outlined"
                color="error"
                mt={2}
                mb={2}
                onClick={changeEdit}
                className="retail-btn  mr-2"
              >
                {edit ? "Edit" : "Save"}
              </Button>
              <CloseIcon onClick={handleClose} />
            </Box>
          </Box>
          <Divider></Divider>
          <Box className="modal-body">
            <Grid className="add_new_item">
              <Card>
                <CardContent className={edit ? "disable" : ""}>
                  <form onSubmit={formik.handleSubmit} className="w-100">
                    <Box className="images d-flex">
                      <Box className="position-relative">
                        <Box className="upload__img-close"></Box>
                        <Box className="flex-between">
                          {/* {images.length > 0 ? (
                            images.map((image, index) => (
                              <Box
                                component="img"
                                className="w-200"
                                key={index}
                                src={URL.createObjectURL(image)}
                                alt={`Preview ${index + 1}`}
                              />
                            ))
                          ) : (
                            <Box component="img" src={upload} mt={2} />
                          )} */}
                          {displayImages.length > 0 ? (
                            displayImages.map((imageUrl, index) => (
                              <img
                                className="w-200"
                                key={index}
                                src={imageUrl}
                                alt={`Preview ${index + 1}`}
                              />
                            ))
                          ) : (
                            <img src={upload} alt="Upload" />
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        component="label"
                        className="btn-upload"
                      >
                        Upload image
                        <input
                          hidden
                          accept="image/*"
                          // multiple
                          type="file"
                          name="productimage"
                          // max="3"
                          onChange={(e) => {
                            handleChange(e);
                            handleImageUpload(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        />
                      </Button>
                    </Box>

                    <Grid container>
                      {/* <Grid mt={2} xs={12} md={3}>
                                                <FormControl className="w-100">
                                                    <Typography component='label' className="small">Location</Typography>
                                                    <Select
                                                        labelId="demo-isolation-label"
                                                        id="isolation-select"
                                                        className="select"
                                                        // onChange={handleSelectOxygen}
                                                        // input={<OutlinedInput />}

                                                        name="city_id"
                                                        // value={formik.values?.city_id}
                                                        defaultValue={locations?locations[0].merchant_location_id:null}
                                                        onChange={(e) => handleChange(e)}
                                                        onBlur={(e) => { handleBlur(e) }}
                                                        disabled={edit ? true : false}
                                                    >
                                                        {
                                                            locations?.map((item) => {

                                                                return item.location_status===0?<MenuItem value={item.merchant_location_id}>{item.location_name}</MenuItem>:null


                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid> */}
                      <Grid container spacing={3} mt={1} p={0}>
                        <Grid item xs={12} md={4}>
                          <FormControl className="w-100">
                            <Typography component="label">Item Name</Typography>
                            <OutlinedInput
                              placeholder="Chana Dal"
                              name="product_name"
                              disabled={edit ? true : false}
                              value={formik.values?.product_name}
                              onChange={(e) => handleChange(e)}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                            />
                            {errors.product_name && touched.product_name && (
                              <Box
                                Typography="small"
                                className="float-left text-danger"
                              >
                                {errors.product_name}
                              </Box>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item md={4}>
                          {/* <FormControlLabel
                            className="remove-tick"
                            control={
                              <Switch
                                checked={
                                  brandName === "veg"
                                    ? true
                                    : false
                                }
                                disabled={edit ? true : false}
                                onChange={() =>
                                  getBrandName(
                                    brandName === "veg" ? "nonVeg" : "veg"
                                  )
                                }
                              />
                            }
                            label="Non Veg"
                            size="small"
                          /> */}
                          <Box className="double-switch">
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                             
                              <Typography>Veg</Typography>
                              <AntSwitch
                                checked={brandName === "veg" ? false : true}
                                onChange={() =>
                                  getBrandName(
                                    brandName === "veg" ? "nonVeg" : "veg"
                                  )
                                }
                              className={
                                brandName === "veg"
                                ? 'active'
                                : ''
                              }
                              //  onChange={() =>
                              //   getBrandName(
                              //     brandName === "veg" ? "nonVeg" : "veg"
                              //   )
                              // }
                                inputProps={{ "aria-label": "ant design" }}
                              />
                              <Typography>Non Veg</Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={3}
                        mt={1}
                        mb={3}
                        className="textarea-div"
                      >
                        <Grid item xs={12} md={4}>
                          <FormControl className="w-100">
                            <Typography component="label">Segment</Typography>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              value={formik.values?.product_segment_id}
                              name="product_segment_id"
                              disabled={edit ? true : false}
                              onChange={(e) => {
                                handleChange(e);
                                getSubSegment(e.target.value);
                              }}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                            >
                              {props.segmentsList?.map((item, index) => {
                                return (
                                  <MenuItem value={item.merchant_segment_id}>
                                    {item.merchant_segment_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {errors.product_segment_id &&
                              touched.product_segment_id && (
                                <Box
                                  Typography="small"
                                  className="float-left text-danger"
                                >
                                  {errors.product_segment_id}
                                </Box>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl className="w-100 ">
                            <Typography component="label">
                              Sub-Segment
                            </Typography>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select"
                              disabled={edit ? true : false}
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              name="product_sub_segment_id"
                              onChange={(e) => handleChange(e)}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                              value={formik.values?.product_sub_segment_id}
                            >
                              {subSegment?.map((item) => {
                                return (
                                  <MenuItem
                                    key={item.merchant_sub_segment_name}
                                    value={item.merchant_sub_segment_id}
                                  >
                                    {item.merchant_sub_segment_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {errors.product_sub_segment_id &&
                              touched.product_sub_segment_id && (
                                <Box
                                  Typography="small"
                                  className="float-left text-danger"
                                >
                                  {errors.product_sub_segment_id}
                                </Box>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl className="w-100">
                            <Typography component="label">
                              Description
                            </Typography>
                            <Box
                              component="textarea"
                              rows={5}
                              name="product_description"
                              onChange={(e) => handleChange(e)}
                              disabled={edit ? true : false}
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                              value={formik.values?.product_description}
                            />
                            {errors.product_description &&
                              touched.product_description && (
                                <Box
                                  Typography="small"
                                  className="float-left text-danger"
                                >
                                  {errors.product_description}
                                </Box>
                              )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Divider></Divider>
                    </Grid>

                    <Divider></Divider>
                    <Typography component="h4" mt={2} className="font-bold">
                      UOM MEASUREMENT
                    </Typography>
                    <Grid container spacing={4} mb={2}>
                      <Grid item xs={12} md={3}>
                        <FormControl className="w-100">
                          <Typography component="label">Metric</Typography>

                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            name="product_metric"
                            disabled={edit ? true : false}
                            onChange={(e) => {
                              formik.handleChange(e);
                              getUomData(e.target.value);
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}

                            value={formik.values?.product_metric}
                          >
                            <MenuItem value="0">please Select</MenuItem>
                            <MenuItem value="Weight">Weight</MenuItem>
                            <MenuItem value="Volume">Volume</MenuItem>
                            <MenuItem value="Pieces">Pieces</MenuItem>
                          </Select>
                          {errors.product_metric && touched.product_metric && (
                            <Box
                              Typography="small"
                              className="float-left text-danger"
                            >
                              {errors.product_metric}
                            </Box>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl className="w-100">
                          <Typography component="label">UOM</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            name="product_uom"
                            disabled={edit ? true : false}
                            value={formik.values?.product_uom}
                            onChange={(e) => {
                              formik.handleChange(e);
                              // handleInputChange(e);
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            defaultValue="select"
                          >
                            {metricUom?.map((item) => {
                              return (
                                <MenuItem value={item.uom_name}>
                                  {item.uom_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {errors.product_uom && touched.product_uom && (
                            <Box
                              Typography="small"
                              className="float-left text-danger"
                            >
                              {errors.product_uom}
                            </Box>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl className="w-100">
                          <Typography component="label">No of Units</Typography>
                          <OutlinedInput
                            onChange={(e) => formik.handleChange(e)}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            disabled={edit ? true : false}
                            value={formik.values?.no_of_units}
                            name="no_of_units"
                          />
                          {errors.no_of_units && touched.no_of_units && (
                            <Box
                              Typography="small"
                              className="float-left text-danger"
                            >
                              {errors.no_of_units}
                            </Box>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormControl className="w-100">
                          <Typography component="label">Sale Price</Typography>
                          <OutlinedInput
                            name="sale_price"
                            onChange={(e) => formik.handleChange(e)}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                            }}
                            disabled={edit ? true : false}
                            value={formik.values?.sale_price}
                          />
                          {errors.sale_price && touched.sale_price && (
                            <Box
                              Typography="small"
                              className="float-left text-danger"
                            >
                              {errors.sale_price}
                            </Box>
                          )}
                        </FormControl>
                      </Grid>
                      <Box className="w-100">
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          mb={3}
                          className="retail-btn float-right mb-15"
                          type="submit"
                        >
                          Next
                        </Button>
                      </Box>
                    </Grid>
                  </form>
                  <Divider></Divider>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    marginTop={2}
                    className="w-100"
                  >
                    <Typography component="h4" className="sub_title">
                      INGREDIENTS MEASUREMENT
                    </Typography>
                    <Button
                      className="btn btn-outline-primary"
                      onClick={() => ref1.current.log()}
                    >
                      Add
                    </Button>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <MemberTable
                        columns={managementColumns}
                        options={managementOptions}
                        data={
                          ingredient
                            ? ingredient
                            : props.ingredient
                            ? props.ingredient
                            : []
                        }
                      />
                    </Grid>
                  </Grid>
                  <Divider></Divider>
                  <Box className="p-15">
                    <Button
                      variant="outlined"
                      color="error"
                      mt={2}
                      mb={3}
                      className="retail-btn float-right"
                      onClick={handleClose}
                    >
                      Submit
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Box>
        </Box>
      </Modal>
      <AddIngredientsMeasurements
        ref={ref1}
        ingredientNames={ingredientNames}
        fetchIngredientTableData={props.fetchIngredientTableData}
      />
    </div>
  );
});

export default StoreNewItemModal;
