import React, { useImperativeHandle, forwardRef, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
// import Calender from "../Calender/Calender";
import { useNavigate } from "react-router-dom";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import active from "../images/last-active.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import ExpandableRowTable from "../Retailers/ExpandableRowTable";
import { useDispatch, useSelector } from "react-redux";
import api from "../Api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import email from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import OrderModal from "./OrderModal";
import Bredcrum from "../Bredcrum";
import { CSVLink } from "react-csv";
import { setCity } from "../Redux/actions/userActions";

export default function PurchaseOrders() {
  const [showState, setShowState] = React.useState(false);
  const dispatch = useDispatch();
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const response = useSelector((state) => state.user.user);
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();
  const MySwal = withReactContent(Swal);
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };

  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      text: message,
    });
  };

  const showSwalWithLinkError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };
  const memeberColumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Customer",
    "Purchase Order ID",
    "Total Invoice Amount",
    "Order Type",
    "Status",
  ];

  const data = [
    [
      "22-July-2023",
      "Sai Kishore",
      "252778",
      "₹ 560",
      "Phone Order",
      "Delivered",
    ],
    ["22-July-2023", "Lavanya", "856889", "₹ 390", "Phone Order", "Delivered"],
  ];

  const pcData = [["#98759", "Rice", "01", "Rs. 300"]];
  const [filterByStatus, setFilterByStatus] = React.useState("All");
  const [orderDetails, setOrderDetails] = React.useState();
  const [filterOrderDetails, setFilterOrderDetails] = React.useState();
  const [orderHistoey, setOrderHistory] = React.useState();
  const getOrderCustomerDetails = () => {
    api
      .getData(
        "/api/customer_orders/order_customer_details?merchant_id=" +
          response.merchant_id +
          "&environment=" +
          process.env.REACT_APP_MY_TITLE
      )
      .then((response) => {
        if (response.data[0].Status === "200") {
          setOrderDetails(JSON.parse(response.data[0].OrderDetails));
          setFilterOrderDetails(JSON.parse(response.data[0].OrderDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cartItemColumns = [
    {
      name: "product_complete_sku",
      label: "ORDER ID",
    },
    { name: "product_name", label: "ORDER NAME" },
    {
      name: "product_qty_sold",
      label: "QTY",
      // options: {
      //   customBodyRender: (value, tableMeta, updateValue) => {
      //     const rowIndex = tableMeta.rowIndex;
      //     const rowData = pendingCartItem ? pendingCartItem[rowIndex] : [];
      //     return (<QuantityCell value={value} rowData={rowData} />);
      //   },
      // },
    },
    { name: "sale_price", label: "PRICE" },
  ];
  const [orderCustomerAllDetails, setOrderCustomerAllDetails] =
    React.useState();
  const getOrderCustomerAllDetails = (orderId) => {
    api
      .getData(
        "/api/customer_orders/order_customer_all_details?merchant_id=" +
          response.merchant_id +
          "&order_id=" +
          orderId
      )
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          setOrderCustomerAllDetails(
            JSON.parse(response.data[0].OrderDetails)[0]
          );
          setOrderHistory(JSON.parse(response.data[0].OrderHistory));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [orderTracking, setOrderTracking] = React.useState();
  const getOrderTrackig = (deliveryTypeId) => {
    api
      .getData(
        "/api/customer_orders/get__order_tracking?delivery_type_id=" + 7
        // deliveryTypeId
      )
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          setOrderTracking(JSON.parse(response.data[0].OrderTrackingDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderCustomerDetails();
  }, []);

  useEffect(() => {
    if (orderDetails) {
      getOrderCustomerAllDetails(orderDetails[0]?.order_id);
    }
  }, [orderDetails]);

  const [cities, setCities] = React.useState();
  const fetchCities = () => {
    api
      .getData("/api/config/cities_list?countryid=" + response?.country_id)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          dispatch(
            setCity(
              JSON.parse(response.data[0].Cities).filter((item) => {
                return item.city_state === "Andhra Pradesh";
              })
            )
          );
          setCities(
            JSON.parse(response.data[0].Cities).filter((item) => {
              return item.city_state === "Andhra Pradesh";
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchCities();
  }, []);
  const [cartItemOrders, setCartItemOrders] = React.useState();
  const getCartItemsOrderid = (invoiceId) => {
    api
      .getData("/api/pos/POS_get_cart_item_productid?invoice_id=" + invoiceId)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCartItemOrders(JSON.parse(response.data[0].GetCartItems));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (orderCustomerAllDetails) {
      getOrderTrackig(orderCustomerAllDetails?.delivery_type_id);
      getCartItemsOrderid(orderCustomerAllDetails?.invoice_id);
    }
  }, [orderCustomerAllDetails]);
  const handleDateFilter = (newValue) => {
    const startDate = newValue[0];
    const endDate = newValue[1];

    const formattedStartDate = startDate?.toISOString().slice(0, 10); // yyyy-mm-dd
    const formattedEndDate = endDate?.toISOString().slice(0, 10); // yyyy-mm-dd

    if (formattedStartDate && formattedEndDate) {
      const filteredData = filterOrderDetails.filter((customer) => {
        return (
          customer.order_date >= formattedStartDate &&
          customer.order_date <= formattedEndDate
        );
      });
      setOrderDetails(filteredData);
    }
    // You can use these formatted dates as needed.
  };
  const [dayActive, setDayActive] = React.useState();
  const filterByToday = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    const formattedToday = `${year}-${month}-${day}`;
    if (formattedToday) {
      const filteredData = filterOrderDetails.filter((customer) => {
        return customer.order_date == formattedToday;
      });
      setOrderDetails(filteredData);
    }
  };

  const filterByYesterday = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const year = yesterday.getFullYear();
    const month = String(yesterday.getMonth() + 1).padStart(2, "0");
    const day = String(yesterday.getDate()).padStart(2, "0");

    const formattedYesterday = `${year}-${month}-${day}`;
    if (formattedYesterday) {
      const filteredData = filterOrderDetails.filter((customer) => {
        return customer.order_date == formattedYesterday;
      });
      setOrderDetails(filteredData);
    }
  };

  const getFilterByStatus = (status) => {
    setFilterByStatus(status);
    if (status) {
      const filteredData = filterOrderDetails.filter((customer) => {
        return customer.delivery_status === status;
      });
      setOrderDetails(filteredData);
    }
    if (status === "All") {
      setOrderDetails(filterOrderDetails);
    }
  };
  // useEffect(()=>{
  //   if(orderDetails){
  //   getOrderCustomerAllDetails(orderDetails[0].order_id);
  //   }
  // },[orderDetails])

  const insertUpdateOrderTracking = (item) => {
    console.log(
      "orderCustomerAllDetails.order_status_index",
      orderCustomerAllDetails
    );
    console.log("item.order_status_index", item.order_status_index);
    // if(orderCustomerAllDetails.order_status_index === 0 && item.order_status_index > 1){
    //   showSwalWithError("Invalid Status Change");
    // }else if(orderCustomerAllDetails.order_status_index === 1 && item.order_status_index !== 2){
    //   showSwalWithError("Invalid Status Change");
    // }else if(orderCustomerAllDetails.order_status_index === 2 && item.order_status_index !== 3){
    //   showSwalWithError("Invalid Status Change");
    // }else if(orderCustomerAllDetails.order_status_index === 3 && item.order_status_index !== 4){
    //   showSwalWithError("Invalid Status Change");
    // }else if(orderCustomerAllDetails.order_status_index === 4 && item.order_status_index !== 5){
    //   showSwalWithError("Invalid Status Change");
    // }else if(orderCustomerAllDetails.order_status_index === 5 && item.order_status_index !== 5){
    //   showSwalWithError("Invalid Status Change");
    // }
    // if (
    //   (orderCustomerAllDetails.order_status_index === 0 && [0, 1].includes(item.order_status_index)) ||
    //   (orderCustomerAllDetails.order_status_index === 1 && [0, 2].includes(item.order_status_index)) ||
    //   (orderCustomerAllDetails.order_status_index === 2 && [1, 3].includes(item.order_status_index)) ||
    //   (orderCustomerAllDetails.order_status_index === 3 && [2, 4].includes(item.order_status_index)) ||
    //   (orderCustomerAllDetails.order_status_index === 4 && [3, 5].includes(item.order_status_index)) ||
    //   (orderCustomerAllDetails.order_status_index === 5 && item.order_status_index === 5)
    // ) {
    //   alert(item.order_status_index);
    // let data = {
    //   order_id: orderCustomerAllDetails?.order_id,
    //   invoice_id: orderCustomerAllDetails?.invoice_id,
    //   order_delivery_type: item?.order_delivery_type,
    //   order_status_id: item?.order_status_Id,
    //   order_status: item?.order_status,
    // };
    //   return false;
    // try {
    //   api
    //     .postData("/api/customer_orders/insert_update_order_tracking", data)
    //     .then((response) => {
    //       console.log('response',response);
    //       if (response.data[0].Status === "200") {
    //         showSwalWithLink(response.data[0].Message);
    //       } else {
    //         showSwalWithError(response.data[0].Message);
    //       }
    //     });
    // } catch (error) {
    //   // Handle network error
    // }
    // } else {
    //   showSwalWithError("Invalid Status Change");
    if (
      (item.order_status_index === 0 && !(item.order_status === "New")) ||
      orderCustomerAllDetails.order_status === "Completed" ||
      orderCustomerAllDetails.order_status === "Cancelled" ||
      orderCustomerAllDetails.order_status === "Aborted" ||
      (orderCustomerAllDetails.order_status_index === 1 &&
        [0, 2].includes(item.order_status_index)) ||
      (orderCustomerAllDetails.order_status_index === 2 &&
        [1, 3].includes(item.order_status_index)) ||
      (orderCustomerAllDetails.order_status_index === 3 &&
        item.order_status_index === 4) ||
      (orderCustomerAllDetails.order_status_index === 4 &&
        [3, 5].includes(item.order_status_index)) ||
      (orderCustomerAllDetails.order_status_index === 5 &&
        item.order_status_index === 5)
    ) {
      let data = {
        order_id: orderCustomerAllDetails?.order_id,
        invoice_id: orderCustomerAllDetails?.invoice_id,
        order_delivery_type: item?.order_delivery_type,
        order_status_id: item?.order_status_Id,
        order_status: item?.order_status,
      };

      try {
        api
          .postData("/api/customer_orders/insert_update_order_tracking", data)
          .then((response) => {
            console.log("response", response);
            if (response.data[0].Status === "200") {
              showSwalWithLink(response.data[0].Message);
              getOrderCustomerDetails();
            } else {
              showSwalWithError(response.data[0].Message);
            }
          });
      } catch (error) {
        // Handle network error
      }
    } else {
      showSwalWithError("Invalid Status Change");
    }

    console.log("item", item);
  };

  const formateDate = (date) => {
    const dateTimeString = date;
    const parsedDate = new Date(dateTimeString);

    const year = parsedDate.getFullYear();
    const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
    const day = String(parsedDate.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.rowIndex;
      const clickedRowData = orderDetails[clickedRowIndex];
      getOrderCustomerAllDetails(clickedRowData.order_id);
    },
    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const pcColumns = [
    {
      name: "order_date",
      label: "Date",
    },
    {
      name: "order_id",
      label: "Order Id",
    },
    {
      name: "name_of_the_customer",
      label: "Customer",
    },
    {
      name: "order_total_amount",
      label: "Total Amount",
    },
    {
      name: "order_module",
      label: "Order Method",
    },
    {
      name: "delivery_status",
      label: "Status",
    },
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      // return (
      //   <React.Fragment>
      //     <tr className="inner-row">
      //       <td colSpan={6}>
      //         <TableContainer>
      //           <Table aria-label="simple table">
      //             <TableBody>
      //               {/* {rows.map(row => ( */}
      //               {/* <TableRow key={row.id}> */}
      //               <TableRow>
      //                 <TableCell scope="row">
      //                   <Typography component="p">
      //                     <b>Taxes:</b> ₹ 485.00
      //                   </Typography>
      //                 </TableCell>
      //               </TableRow>
      //               <TableRow>
      //                 <TableCell scope="row">
      //                   <Typography component="p">
      //                     <b>Advance:</b> ₹ 321.00
      //                   </Typography>
      //                 </TableCell>
      //               </TableRow>
      //               <TableRow>
      //                 <TableCell scope="row">
      //                   <Typography component="p">
      //                     <b>Due Amount:</b> ₹ 542.00
      //                   </Typography>
      //                 </TableCell>
      //               </TableRow>
      //               <TableRow>
      //                 <TableCell scope="row">
      //                   <Typography component="p">
      //                     <b>State:</b> State
      //                   </Typography>
      //                 </TableCell>
      //               </TableRow>
      //               {/* ))} */}
      //             </TableBody>
      //           </Table>
      //         </TableContainer>
      //       </td>
      //     </tr>
      //   </React.Fragment>
      // );
    },
    page: 1,
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid
                    xs="12"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <Box className="icons bredcum-icons">
                      <SearchBar className="search-bar" />
                      <PersonIcon onClick={() => ref.current.log()} />

                      <NotificationsIcon />
                      <LogoutIcon onClick={logoutDashboard} ml={2} />
                    </Box> */}
                  </Grid>
                  {/* <Divider className="bredcum-hr"></Divider> */}
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters sales-filters"
                    >
                      <Box className="flex">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["SingleInputDateRangeField"]}
                          >
                            <DateRangePicker
                              className="calender"
                              variant="outlined"
                              slots={{ field: SingleInputDateRangeField }}
                              inputFormat="yyyy-mm-dd"
                              onChange={(newValue) => {
                                handleDateFilter(newValue);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        {/* <Calender className="calenders"/> */}
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className={
                            dayActive === "today"
                              ? "retail-btn active"
                              : "retail-btn"
                          }
                          onClick={() => {
                            filterByToday();
                            setDayActive("today");
                          }}
                        >
                          Today
                        </Button>

                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className={
                            dayActive === "yesterday"
                              ? "retail-btn active"
                              : "retail-btn"
                          }
                          onClick={() => {
                            filterByYesterday();
                            setDayActive("yesterday");
                          }}
                        >
                          Yesterday
                        </Button>
                      </Box>
                      {/* <Box className="flex">
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Cities</MenuItem>

                          {cities?.map((item) => {
                            return (
                              <MenuItem value={item.city_id}>
                                {item.city_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Orders</MenuItem>
                          <MenuItem value="visakhapatnam">PDF</MenuItem>
                          <MenuItem value="vijayawada">Excel</MenuItem>

                          <MenuItem value="kurnool">Image</MenuItem>
                        </Select>
                      </Box> */}
                    </Box>
                  </Grid>
                  <Grid container mt={3}>
                    <Grid
                      xs="12"
                      mt={2}
                      mb={2}
                      className="transport-div salesreport-container flex"
                    >
                      <Box className="filters-group po-group">
                        <Box
                          className={
                            filterByStatus === "All"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("All");
                          }}
                        >
                          <Typography component="h4">
                            {
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "All";
                              })?.length
                            }
                          </Typography>
                          <Typography
                            component="p"
                            className="btn-primary-gradient"
                          >
                            All
                          </Typography>
                        </Box>
                        <Box
                          className={
                            filterByStatus === "New"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("New");
                          }}
                        >
                          <Typography component="h4">{
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "New";
                              })?.length
                            }</Typography>
                          <Typography
                            component="p"
                            className="btn-secondary-gradient"
                          >
                            New
                          </Typography>
                        </Box>
                        <Box
                          className={
                            filterByStatus === "Assinged"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("Assinged");
                          }}
                        >
                          <Typography component="h4">{
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "Assinged";
                              })?.length
                            }</Typography>
                          <Typography
                            component="p"
                            className="btn-purple-gradient"
                          >
                            Assinged
                          </Typography>
                        </Box>
                        <Box
                          className={
                            filterByStatus === "Pending Acknowledgement"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("Pending Acknowledgement");
                          }}
                        >
                          <Typography component="h4">{
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "Pending Acknowledgement";
                              })?.length
                            }</Typography>
                          <Typography
                            component="p"
                            className="btn-success-gradient"
                          >
                            Pending Ack
                          </Typography>
                        </Box>
                        <Box
                          className={
                            filterByStatus === "Ready for Dispatched"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("Ready for Dispatched");
                          }}
                        >
                          <Typography component="h4">{
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "Ready for Dispatched";
                              })?.length
                            }</Typography>
                          <Typography
                            component="p"
                            className="btn-info-gradient"
                          >
                            Ready for Dispatch
                          </Typography>
                        </Box>
                        <Box
                          className={
                            filterByStatus === "In Transit"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("In Transit");
                          }}
                        >
                          <Typography component="h4">{
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "In Transit";
                              })?.length
                            }</Typography>
                          <Typography
                            component="p"
                            className="btn-warning-gradient"
                          >
                            In Transit
                          </Typography>
                        </Box>
                        <Box
                          className={
                            filterByStatus === "Completed"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("Completed");
                          }}
                        >
                          <Typography component="h4">{
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "Completed";
                              })?.length
                            }</Typography>
                          <Typography
                            component="p"
                            className="btn-danger-gradient"
                          >
                            Completed
                          </Typography>
                        </Box>
                        <Box
                          className={
                            filterByStatus === "Cancelled"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("Cancelled");
                          }}
                        >
                          <Typography component="h4">{
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "Cancelled";
                              })?.length
                            }</Typography>
                          <Typography
                            component="p"
                            className="btn-primary-gradient"
                          >
                            Cancelled
                          </Typography>
                        </Box>
                        <Box
                          className={
                            filterByStatus === "Abandoned"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("Abandoned");
                          }}
                        >
                          <Typography component="h4">{
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "Abandoned";
                              })?.length
                            }</Typography>
                          <Typography
                            component="p"
                            className="btn-primary-gradient"
                          >
                            Abandoned
                          </Typography>
                        </Box>
                        <Box
                          className={
                            filterByStatus === "Hold"
                              ? "sales-report filter-active"
                              : "sales-report"
                          }
                          onClick={() => {
                            getFilterByStatus("Hold");
                          }}
                        >
                          <Typography component="h4">{
                              filterOrderDetails?.filter((customer) => {
                                return customer.delivery_status === "Hold";
                              })?.length
                            }</Typography>
                          <Typography
                            component="p"
                            className="btn-secondary-gradient"
                          >
                            Hold
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box className="member-table">
                          {/* <ExpandableRowTable
                            columns={memeberColumns}
                            options={options}
                            data={data}
                          /> */}
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={orderDetails ? orderDetails : []}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box>
                          <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            mb={2}
                          >
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Order ID:
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.order_id}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Order Status
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.delivery_status}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Select
                                  labelId="demo-isolation-label"
                                  id="status-select"
                                  className="select float-right"
                                  onChange={(e) => {
                                    insertUpdateOrderTracking(e.target.value);
                                  }}
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="0"
                                >
                                  <MenuItem value="0">Select Status</MenuItem>
                                  {orderTracking?.map((item) => {
                                    return (
                                      <MenuItem value={item}>
                                        {item.order_status}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </Box>
                            </Grid>

                            <Grid item xs={2} sm={4} md={4}>
                              <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Date Created
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.order_date}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={2} sm={4} md={4}>
                              {/* <Box className="information-div">
                                <Box>
                                  <Typography component="label">
                                    Due Date
                                  </Typography>
                                  <Typography>
                                    {formateDate(
                                      orderCustomerAllDetails?.delivery_date
                                    )}
                                  </Typography>
                                </Box>
                              </Box> */}
                            </Grid>
                            <Grid item xs={2} sm={4} md={4}>
                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                className="retail-btn"
                                onClick={() => ref3.current.log()}
                              >
                                View More
                              </Button>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            mt={2}
                            mb={2}
                          >
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Customer
                                  </Typography>
                                  <Typography>
                                    {
                                      orderCustomerAllDetails?.merchant_business_name
                                    }
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.phone_number}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2} mb={2}>
                              <Box className="information-div">
                                <Box component="img" src={email} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.admin_email}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2} mb={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Location
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.location_name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Divider></Divider>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Order Method
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.delivery_status}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Order Type
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.delivery_status}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Status
                                  </Typography>
                                  <Typography>On the way</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Delivery
                                  </Typography>
                                  <Typography>On the way</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography>+91 90528 45869</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Payment Mode
                                  </Typography>
                                  <Typography>UPI</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Payment Status
                                  </Typography>
                                  <Typography>30 Days</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Payment Mode
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.pay_type}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid> */}
                            {/* <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Payment Status
                                  </Typography>
                                  <Typography>
                                    {orderCustomerAllDetails?.payment_status=== 0?"Completed":"Pending"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid> */}
                          </Grid>
                        </Box>
                        <Divider></Divider>
                        <Grid mt={5} className="view-more-btn">
                          <Box className="w-100 member-table">
                            <MemberTable
                              columns={cartItemColumns}
                              options={pcOptions}
                              data={cartItemOrders ? cartItemOrders : []}
                            />
                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <OrderModal
          ref={ref3}
          orderCustomerAllDetails={orderCustomerAllDetails}
          cartItemOrders={cartItemOrders}
          orderTracking={orderTracking}
          orderHistoey={orderHistoey}
        />
      </Box>
    </>
  );
}
