import React, { useImperativeHandle, forwardRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Grid, FormControl, OutlinedInput, Divider } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { useFormik } from "formik";
import api from '../Api';
import { deliveryAddress } from '../Validation';
import { useDispatch, useSelector } from "react-redux";
import { json } from 'react-router-dom';
import { setDeliveryId } from '../Redux/actions/userActions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const DeliveryModal = forwardRef((props, ref5) => {
    const [open, setOpen] = React.useState(false);
    const pendingInvoiceId = useSelector(state => state.user.pos_invoice_id);
    const [passwd, setPasswd] = React.useState(false);
    const dispatch = useDispatch();
    const handlePassword = () => {
        setPasswd(passwd => !passwd)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const showSwalWithLink = (message) => {
        MySwal.fire({
            icon: 'success',
            title: message,
        }).then(function () {
            props?.getSearchPhoneDetails();
            handleClose();
           
        })
    };
    const showSwalWithLinkError = (message) => {
        MySwal.fire({
            icon: 'error',
            title: message,
        })
    };

    const handleSubmit = (values) => {
        let initialValues = {
            "invoice_id": pendingInvoiceId,
            "delivery_type_id": 5,
            "delivery_from_address": null,
            "delivery_to_address": JSON.stringify([Object.values(formik.values).join(',')]),
            "delivery_status": "New",
            "delivery_date": new Date(),
            "runner_id": 0,
            assigned_date: new Date(),
            delivery_or_return: 'd',
            delivery_id: 0


        }

        api.postData('/api/pos/POS_InsertOrUpdateOrderDeliveryDetails', initialValues).then((response) => {


            if (response.data[0].Status === "200") {

                showSwalWithLink(response.data[0].Message);
                dispatch(setDeliveryId(JSON.parse(response.data[0].DeliveryDetails)[0].delivery_id));

            } else {
                showSwalWithLinkError(response.data[0].Message)
            }
        })
    };
    const initialValues = {
        "Name": props.customerPhoneDetails?.name_of_the_customer,
        "PhoneNumber": props.customerPhoneDetails?.phone_number,
        "Address": "",
        "Pincode": ""
    }
    const formik = useFormik({
        initialValues,
        // validationSchema: deliveryAddress,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    useEffect(()=>{
        formik.setValues(initialValues);
    },[props.customerPhoneDetails])

    useImperativeHandle(ref5, () => ({
        log() {
            handleOpen();
        }
    }));

    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
    } = formik;

    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal'
            >
                <form onSubmit={formik.handleSubmit} className="w-100">
                    <Box sx={{ ...style }} className='profile-modal'>
                        <Grid container>
                            <Grid item xs={12} className="profile-header p-15" >

                                <Typography id="parent-modal-title" component="h4">DOOR DELIVERY</Typography>
                                <CloseIcon onClick={handleClose} />
                            </Grid>
                        </Grid>
                        <Divider></Divider>

                        <Grid container className='details-div p-15' spacing={2}>
                            {props && props.deliveryTypes?.map((item) => {
                                if (item.del_type_fields && item.del_type_id === 5) {
                                   return item.del_type_fields.split(',').map((field) => {
                                    

                                        if (field === 'Name') {
                                            return (<Grid item xs={12} md={6}>

                                                <FormControl className='w-100'>
                                                    <Typography component="label">Name</Typography>
                                                    <OutlinedInput placeholder="Name" name='Name' value={formik.values?.Name} onChange={(e) => handleChange(e)} onBlur={(e) => { handleBlur(e) }} />
                                                    {/* {errors.name && touched.name && <Box Typography="small" className="float-left text-danger">{errors.name}</Box>} */}

                                                </FormControl>
                                            </Grid>)
                                        } else if (field === 'DateTime') {
                                            return <Grid item xs={12} md={6}>
                                                <FormControl className='w-100'>
                                                    <Typography component="label">Date And Time</Typography>
                                                    <OutlinedInput type='datetime-local' name='date' onChange={(e) => handleChange(e)} onBlur={(e) => { handleBlur(e) }} />
                                                    {/* {errors.date && touched.date && <Box Typography="small" className="float-left text-danger">{errors.date}</Box>} */}

                                                </FormControl>
                                            </Grid>
                                        } else if (field === 'PhoneNumber') {
                                            return <Grid item xs={12} md={6}>
                                                <FormControl className='w-100'>
                                                    <Typography component="label">Phone Number</Typography>
                                                    <OutlinedInput placeholder="Phone Number" name='PhoneNumber' value={formik.values?.PhoneNumber} onChange={(e) => handleChange(e)} onBlur={(e) => { handleBlur(e) }} />
                                                    {/* {errors.phone_number && touched.phone_number && <Box Typography="small" className="float-left text-danger">{errors.phone_number}</Box>} */}

                                                </FormControl>
                                            </Grid>
                                        } else if (field === 'Pincode') {
                                            return <Grid item xs={12} md={6}>
                                                <FormControl className='w-100'>
                                                    <Typography component="label">Pincode</Typography>
                                                    <OutlinedInput placeholder="Pincode" name='Pincode' onChange={(e) => handleChange(e)} onBlur={(e) => { handleBlur(e) }} />
                                                    {/* {errors.Pincode && touched.Pincode && <Box Typography="small" className="float-left text-danger">{errors.Pincode}</Box>} */}

                                                </FormControl>
                                            </Grid>
                                        } else if (field === 'Address') {
                                            return <Grid item xs={12} md={12}>
                                                <FormControl className='w-100'>
                                                    <Typography component="label">Address</Typography>
                                                    <OutlinedInput placeholder="Address" rows={7} multiline name='Address' onChange={(e) => handleChange(e)} onBlur={(e) => { handleBlur(e) }} />
                                                    {/* {errors.address && touched.address && <Box Typography="small" className="float-left text-danger">{errors.address}</Box>} */}

                                                </FormControl>
                                            </Grid>
                                        }
                                    })
                                }
                                return
                            })
                            }







                        </Grid>

                        <Divider></Divider>
                        <Box className="p-15 text-right">
                            <Button variant='outlined' color='error' className='retail-btn mr-15' type='submit'>Submit</Button>

                        </Box>
                    </Box>
                </form>
            </Modal>
        </div>
    );
});

export default DeliveryModal;