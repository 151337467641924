import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import Calender from "../Calender/Calender";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate } from "react-router-dom";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import SearchBar from "material-ui-search-bar";
import ExpandableRowTable from "../Customer/ExpandableRowTable";
import email from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
import OrderModal from "./OrderModal";
import { TableHead } from "mui-datatables";
import PropTypes from "prop-types";
import { OutlinedInput, FormControl, Modal } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function TaxFilling() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const memeberColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const pcData = [
    ["Cream", "#648744", "35", "₹ 100.00", "₹ 3500.00"],
    ["Facewash", "#627888", "40", "₹ 200.00", "	₹ 8000.00"],
    ["", "", "", "	Sub Total", "	₹ 11500.00"],
    ["", "", "", "	Discount", "	₹ 500.00"],
    ["", "", "", "	Total Tax", "	₹ 400.00"],
    ["", "", "", "	CGST", "	₹ 100.00"],
    ["", "", "", "	SGST", "	₹ 100.00"],

    ["", "", "", "	IGST", "	₹ 100.00"],
    ["", "", "", "	UTGST", "	₹ 100.00"],
    ["", "", "", "	Compensatory Cess Rate", "		₹ 50.00"],
  ];
  const payData = [
    ["TCS CGST", "13", "₹ 1300.00"],
    ["TCS SGST", "14", "₹ 1400.00"],
    ["TCS IGST", "15", "₹ 1500.00"],
    ["TCS UTGST", "11", "₹ 1100.00"],
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const pcColumns = [
    { name: "SKU NAME", options: { filterOptions: { fullWidth: true } } },
    "SKU ID",
    "QUANTITY",
    "UNIT PRICE",
    "TOTAL PRICE",
  ];

  const payColumns = [
    { name: "", options: { filterOptions: { fullWidth: true } } },
    "RATE",
    "AMOUNT",
  ];

  const options = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Taxes:</b> ₹ 485.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Advance:</b> ₹ 321.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Due Amount:</b> ₹ 542.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>State:</b> State
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  const data = [
    [
      "12487",
      "Sri Sai Kirana and General Stores",
      "₹ 25.00",
      "Completed",
      "₹ 24000.00",
    ],
    [
      "12456",
      "Suryanarayana Departmental Stores",
      "₹ 25.00",
      "Completed",
      "₹ 36000.00",
    ],
  ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <CardContent>
                <Grid container className="full_content">
                  <Grid
                    xs="12"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Breadcrumbs aria-label="breadcrumb">
                      {/* <Link underline="hover" color="inherit" href="/">
                                                Home
                                            </Link> */}
                      <Link underline="hover" color="inherit" href="#">
                        Accounting
                      </Link>
                      <Typography color="text.primary">Tax Filling</Typography>
                    </Breadcrumbs>
                    <Box className="icons bredcum-icons">
                      <SearchBar className="search-bar" />
                      <PersonIcon onClick={() => ref.current.log()} />

                      <NotificationsIcon />
                      <LogoutIcon onClick={logoutDashboard} ml={2} />
                    </Box>
                  </Grid>
                  <Divider className="bredcum-hr"></Divider>
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters sales-filters"
                    >
                      <Box className="flex">
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="select"
                        >
                          <MenuItem value="select">Select</MenuItem>
                          <MenuItem value="month">Month</MenuItem>
                          <MenuItem value="quaterly">Quarterly</MenuItem>

                          <MenuItem value="halfyearly">Half Yearly</MenuItem>
                        </Select>

                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="select"
                        >
                          <MenuItem value="select">Select</MenuItem>
                          {month?.map((item) => {
                            return <MenuItem value={item}>{item}</MenuItem>;
                          })}
                        </Select>
                      </Box>
                      <Box className="flex">
                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Search
                        </Button>

                        <Button
                          variant="outlined"
                          color="error"
                          mt={2}
                          className="retail-btn"
                        >
                          Download
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={6} className="tax-fill">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Card className="min-215">
                          <CardContent>
                            <Box className="information-div">
                              <Box component="img" src={location} />
                              <Box>
                                <Typography component="h4">
                                  Seller Location
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="m-16" mt={2}>
                              <Typography component="label">
                                Street Address
                              </Typography>
                              <Typography component="p">
                                389, Ayappa Society Road
                              </Typography>
                            </Box>
                            <Box className="flex-between m-16" mt={2}>
                              <Box>
                                <Typography component="label">City</Typography>
                                <Typography component="p">Hyderabad</Typography>
                              </Box>
                              <Box className="m-16">
                                <Typography component="label">State</Typography>
                                <Typography component="p">Telangana</Typography>
                              </Box>
                            </Box>
                            <Box className="flex-between m-16" mt={2} pb={2}>
                              <Box>
                                <Typography component="label">
                                  Country
                                </Typography>
                                <Typography component="p">India</Typography>
                              </Box>
                              <Box>
                                <Typography component="label">
                                  Postal Code
                                </Typography>
                                <Typography component="p">500175</Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Card className="min-215">
                          <CardContent>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="h4">
                                  Retailer’s Details
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="flex-between m-16" mt={2}>
                              <Box>
                                <Typography component="label">
                                  Retailer Name
                                </Typography>
                                <Typography component="p">
                                  Srinivasa Kirana Store
                                </Typography>
                              </Box>
                              <Box>
                                <Typography component="label">
                                  Retailer ID
                                </Typography>
                                <Typography component="p">45869</Typography>
                              </Box>
                            </Box>
                            <Box className="flex-between m-16" mt={2}>
                              <Box>
                                <Typography component="label">
                                  Retailer GST
                                </Typography>
                                <Typography component="p">DGDH4456H</Typography>
                              </Box>
                              <Box>
                                <Typography component="label">
                                  Invoice Type
                                </Typography>
                                <Typography component="p">Cash</Typography>
                              </Box>
                            </Box>
                            <Box className="flex-between m-16" mt={2} pb={2}>
                              <Box>
                                <Typography component="label">
                                  Invoice Number
                                </Typography>
                                <Typography component="p">
                                  109836389286
                                </Typography>
                              </Box>
                              <Box>
                                <Typography component="label">
                                  Invoice Date
                                </Typography>
                                <Typography component="p">
                                  13/03/2023
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Card className="min-270">
                          <CardContent>
                            <Box className="information-div">
                              <Box component="img" src={location} />
                              <Box>
                                <Typography component="h4">
                                  Ship From Location
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="m-16" mt={2}>
                              <Typography component="label">
                                Street Address
                              </Typography>
                              <Typography component="p">
                                Plot No. 61, Vorla Shankaramma Colony, Behind
                                Vijaya Public School, A S Rao Nagar
                              </Typography>
                            </Box>
                            <Box className="flex-between m-16" mt={2}>
                              <Box>
                                <Typography component="label">City</Typography>
                                <Typography component="p">Hyderabad</Typography>
                              </Box>
                              <Box className="m-16" mt={2}>
                                <Typography component="label">State</Typography>
                                <Typography component="p">Telangana</Typography>
                              </Box>
                            </Box>
                            <Box className="flex-between m-16" mt={2} pb={3}>
                              <Box>
                                <Typography component="label">
                                  Country
                                </Typography>
                                <Typography component="p">India</Typography>
                              </Box>
                              <Box>
                                <Typography component="label">
                                  Postal Code
                                </Typography>
                                <Typography component="p">500059</Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Card className="min-270">
                          <CardContent>
                            <Box className="information-div">
                              <Box>
                                <Typography component="h4">
                                  Tax Collected at Source :
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="m-16">
                              <MemberTable
                                columns={payColumns}
                                options={pcOptions}
                                data={payData}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Card className="min-450">
                          <CardContent>
                            <Box className="information-div">
                              <Box component="img" src={location} />
                              <Box>
                                <Typography component="h4">
                                  Ship To Location
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="m-16" mt={2}>
                              <Typography component="label">
                                Street Address
                              </Typography>
                              <Typography component="p">
                                Station Bypass Rd
                              </Typography>
                            </Box>
                            <Box className="flex-between m-16" mt={2}>
                              <Box>
                                <Typography component="label">City</Typography>
                                <Typography component="p">
                                  Ganj Basoda
                                </Typography>
                              </Box>
                              <Box className="m-16" mt={2}>
                                <Typography component="label">State</Typography>
                                <Typography component="p">
                                  Madhya Pradesh
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="flex-between m-16" mt={2} pb={3}>
                              <Box>
                                <Typography component="label">
                                  Country
                                </Typography>
                                <Typography component="p">India</Typography>
                              </Box>
                              <Box>
                                <Typography component="label">
                                  Postal Code
                                </Typography>
                                <Typography component="p">464221</Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Card className="min-450">
                          <CardContent>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="h4">
                                  Shipping Vendor
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="flex-between m-16" mt={2}>
                              <Box>
                                <Typography component="label">
                                  Shipping Vendor Code
                                </Typography>
                                <Typography component="p">
                                  1357-72857824
                                </Typography>
                              </Box>
                              <Box>
                                <Typography component="label">
                                  Shipping Vendor Name
                                </Typography>
                                <Typography component="p">
                                  Ashish Kumar
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="flex-between m-16" mt={2}>
                              <Box>
                                <Typography component="label">
                                  Shipping Vendor Code
                                </Typography>
                                <Typography component="p">NA</Typography>
                              </Box>
                              <Box className="m-16" mt={2}>
                                <Typography component="label">
                                  Shipping Vendor GST
                                </Typography>
                                <Typography component="p">FGSG7549G</Typography>
                              </Box>
                            </Box>

                            <Table className="m-16 custom-table shipping-row">
                              <TableBody>
                                <TableRow>
                                  <TableCell className="box-cell">
                                    <Typography component="label">
                                      Shipping Amount
                                    </Typography>
                                    <Typography component="p">
                                      ₹ 300.00
                                    </Typography>
                                  </TableCell>
                                  <TableCell className="box-cell">
                                    <Typography component="label">
                                      JShipping Amount Bases
                                    </Typography>
                                    <Typography component="p">
                                      ₹ 180.00
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="box-cell">
                                    <Typography component="label">
                                      Promo Discount Bases
                                    </Typography>
                                    <Typography component="p">
                                      ₹ 20.00
                                    </Typography>
                                  </TableCell>
                                  <TableCell className="box-cell">
                                    <Typography component="label">
                                      Promo Tax
                                    </Typography>
                                    <Typography component="p">
                                      ₹ 5.00
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="box-cell">
                                    <Typography component="label">
                                      CGST
                                    </Typography>
                                    <Typography component="p">
                                      ₹ 10.00
                                    </Typography>
                                  </TableCell>
                                  <TableCell className="box-cell">
                                    <Typography component="label">
                                      SGST
                                    </Typography>
                                    <Typography component="p">
                                      ₹ 10.00
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="box-cell">
                                    <Typography component="label">
                                      IGST
                                    </Typography>
                                    <Typography component="p">
                                      ₹ 10.00
                                    </Typography>
                                  </TableCell>
                                  <TableCell className="box-cell">
                                    <Typography component="label">
                                      UGST
                                    </Typography>
                                    <Typography component="p">
                                      ₹ 10.00
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="box-cell">
                                    <Typography component="label">
                                      Shipping Cess Tax
                                    </Typography>
                                    <Typography component="p">
                                      ₹ 20.00
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} className="custom-pl">
                    <Typography component="h5" className="custom-h5">
                      Invoice Total : ₹ 5325.00
                    </Typography>
                    <Card className="customer_cards">
                      <Box className="flex-between top-heading" mb={2}>
                        <Typography component="h4">HSN Codes : </Typography>

                        <Typography component="p">
                          Total HSN Value : ₹ 11050.00
                        </Typography>
                      </Box>
                      <CardContent className="customer_information">
                        <Grid container>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            className="custom-tab tax-tabs"
                          >
                            <Tab label="HSN - 1306.6o" {...a11yProps(0)} />
                            <Tab label="HSN - 1307.8a" {...a11yProps(1)} />
                            <Tab label="HSN - 1536.4g" {...a11yProps(2)} />
                          </Tabs>

                          <TabPanel
                            value={value}
                            index={0}
                            className="scroll-auto"
                          >
                            <Box mt={2} mb={2}>
                              <MemberTable
                                columns={pcColumns}
                                options={pcOptions}
                                data={pcData}
                              />
                            </Box>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={1}
                            className="scroll-auto"
                          >
                            <Box mt={2} mb={2}>
                              <MemberTable
                                columns={pcColumns}
                                options={pcOptions}
                                data={pcData}
                              />

                              {/* <MemberTable columns={ShiftColumns} options={pricingOptions} /> */}
                            </Box>
                          </TabPanel>
                          <TabPanel
                            value={value}
                            index={2}
                            className="scroll-auto"
                          >
                            <Box mt={2} mb={2}>
                              <MemberTable
                                columns={pcColumns}
                                options={pcOptions}
                                data={pcData}
                              />

                              {/* <MemberTable columns={ShiftColumns} options={pricingOptions} /> */}
                            </Box>
                          </TabPanel>
                        </Grid>
                        <Divider></Divider>
                        <Grid container></Grid>

                        <Divider></Divider>
                        <Grid mt={5} className="view-more-btn">
                          <Box className="member-table w-100">
                            <MemberTable
                              columns={pcColumns}
                              options={pcOptions}
                              data={pcData}
                            />
                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <OrderModal ref={ref3} />
      </Box>
    </>
  );
}
