import React, { useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput, TextareaAutosize } from "@mui/material";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import rupee from "../images/rupee.svg";
import mail from "../images/mail.svg";
import MemberTable from "../Customer/MemberTable";
// import Steppers from "../Orders/Steppers";
import Steppers from "../PurchaseOrders/Steppers";
import pickup from "../images/pickup1.svg";
import truck from "../images/truck.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import reddot from "../images/reddot.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OrderModal = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const subEdit = () => {
    setEdit((edit) => !edit);
  };

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const skuColumns = [
    { name: "SKU ID", options: { filterOptions: { fullWidth: true } } },
    "SKU NAME",
    "QTY",
    "PRICE",
  ];

  const pcData = [
    ["#98759", "Rice", "01", "Rs. 300"],
    ["", "", "Sub Total", "Rs. 300"],
    ["", "", "Discount", "Rs. 100"],
    ["", "", "Taxes", "Rs. 200"],
    ["", "", "Grand Total", "Rs. 400"],
  ];

  const OrderHistoryData = [["#98759", "28-07-2023", "Rs.300", "Delivered"]];
  const OrderHistoryColumns = ["Order ID", "Date", "Amount", "Status"];
  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content order-content">
          <Box className="modal-header p-15">
            <Box>
              <Typography
                id="modal-modal-title"
                variant="label"
                component="label"
              >
                ORDER NO:
              </Typography>
              <Typography variant="h5">2878257573325</Typography>
            </Box>
            <Box>
              <Box className="flex">
                <Typography
                  id="modal-modal-title"
                  variant="label"
                  component="label"
                >
                  Status:
                </Typography>
                <Typography variant="p" ml={1}>
                  <Box component="img" src={reddot} className="reddot" />{" "}
                  Pending
                </Typography>
              </Box>
              <Box className="flex font-bold">
                <Typography
                  id="modal-modal-title"
                  variant="label"
                  component="label"
                >
                  Date:
                </Typography>
                <Typography variant="p" ml={1}>
                  02-03-2023
                </Typography>
              </Box>
              <Box className="flex font-bold">
                <Typography
                  id="modal-modal-title"
                  variant="label"
                  component="label"
                >
                  Due Date:
                </Typography>
                <Typography variant="p" ml={1}>
                  01-04-2023
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider></Divider>
          <Grid container mt={3} mb={3}>
            <Steppers />
          </Grid>
          <Grid container spacing={4} className="p-15" mb={2}>
            <Grid item xs={2} sm={3} md={3}>
              <Box className="information-div">
                <Box component="img" src={customers} />
                <Box>
                  <Typography component="label">Supplier</Typography>
                  <Typography>Srinivasa Kirana Store</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
              <Box className="information-div">
                <Box component="img" src={mobile} />
                <Box>
                  <Typography component="label">Mobile</Typography>
                  <Typography>+91 90528 45869</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
              <Box className="information-div">
                <Box component="img" src={mail} />
                <Box>
                  <Typography component="label">Email</Typography>
                  <Typography>Srinivasa@gmail.com</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
              <Box className="information-div">
                <Box component="img" src={location} />
                <Box>
                  <Typography component="label">Location</Typography>
                  <Typography>Kakinada</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Divider></Divider>

          <Grid container spacing={3} className="p-15">
            <Grid item xs={6}>
              <Box className="address-card">
                <Box className="flex-between">
                  <Box className="flex">
                    <Box component="img" src={pickup} />
                    <Typography component="h2">PICKUP</Typography>
                  </Box>
                  {/* <Button variant='outlined' color='error' mt={2} mb={2} className='retail-btn' onClick={subEdit}>{edit?'Edit':'Submit'}</Button> */}
                </Box>
                <Box mt={2}>
                  <Typography component="label">Name</Typography>
                  {edit ? (
                    <Typography component="label">GURUMITA STORES</Typography>
                  ) : (
                    <OutlinedInput value="GURUMITA STORES" />
                  )}
                </Box>
                <Box mt={2}>
                  <Typography component="label">Mobile No.</Typography>
                  {edit ? (
                    <Typography component="label">7075554800</Typography>
                  ) : (
                    <OutlinedInput value="7075554800" />
                  )}
                </Box>
                <Box mt={2}>
                  <Typography component="label">Address</Typography>
                  {edit ? (
                    <Typography component="label">
                      Gandhipuram,Old Raithu Bazar Rd Gandhipuram Rajahmundry
                      Andhra Pradesh 533103 India
                    </Typography>
                  ) : (
                    <TextareaAutosize
                      minRows={3}
                      component="textarea"
                      value="Gandhipuram,Old Raithu Bazar Rd Gandhipuram Rajahmundry Andhra Pradesh 533103 India"
                    ></TextareaAutosize>
                  )}
                </Box>
              </Box>
            </Grid>
            {/* <Grid item xs={4}>
              <Box className="address-card">
                <Box className="flex-between">
                  <Box className="flex">
                    <Box component="img" src={pickup} />
                    <Typography component="h2">DROPOFF</Typography>
                  </Box>
                </Box>
                <Box mt={2}>
                  <Typography component="label">Name</Typography>
                  <Typography component="label">GURUMITA STORES</Typography>
                </Box>
                <Box mt={2}>
                  <Typography component="label">Mobile No.</Typography>
                  <Typography component="label">7075554800</Typography>
                </Box>
                <Box mt={2}>
                  <Typography component="label">Address</Typography>
                  <Typography component="label">
                    Gandhipuram,Old Raithu Bazar Rd Gandhipuram Rajahmundry
                    Andhra Pradesh 533103 India
                  </Typography>
                </Box>
              </Box>
            </Grid> */}
            <Grid item xs={6}>
              <Box className="address-card">
                <Box className="flex-between">
                  <Box className="flex">
                    <Box component="img" src={truck} />
                    <Typography component="h2">DELIVERY</Typography>
                  </Box>
                  {/* <Button
                    variant="outlined"
                    color="error"
                    mt={2}
                    mb={2}
                    className="retail-btn"
                  >
                    Edit
                  </Button> */}
                </Box>
                <Box mt={2}>
                  <Typography component="label">Driver Name</Typography>
                  <Typography component="p">Santosh</Typography>
                </Box>
                <Box mt={2}>
                  <Typography component="label">Mobile</Typography>
                  <Typography component="p">+91 90528 45869</Typography>
                </Box>
                {/* <Box mt={2}>
                  <Typography component="label">Distance</Typography>
                  <Typography component="p">3 Km</Typography>
                </Box> */}
                <Box mt={2}>
                  <Typography component="label">Email</Typography>
                  <Typography component="p">santosh.15@gmail.com</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container className="p-15 flex-end">
            {/* <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={2}
              className="retail-btn float-right"
              onClick={handleOpenAdd}
            >
              Add
            </Button> */}
          </Grid>
          <Box pl={2} pr={2} className="order-table member-table">
            <MemberTable
              columns={skuColumns}
              options={pcOptions}
              data={pcData}
            />
          </Box>
          <Divider></Divider>
          <Grid container spacing={4} mt={1}>
            <Grid item xs={2} sm={3} md={3}>
              <Box className="information-div">
                <Box component="img" src={customers} />
                <Box>
                  <Typography component="label">Payment Terms</Typography>
                  <Typography>7 Days</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
              <Box className="information-div">
                <Box component="img" src={mobile} />
                <Box>
                  <Typography component="label">Due Date</Typography>
                  <Typography>01-04-2023</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
              <Box className="information-div">
                <Box component="img" src={location} />
                <Box>
                  <Typography component="label">Payment Type</Typography>
                  <Typography>Net Billing</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
              <Box className="information-div">
                <Box component="img" src={rupee} />
                <Box>
                  <Typography component="label">Payment Status</Typography>
                  <Typography>Pending</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} sm={3} md={3}>
              <Box className="information-div">
                <Box component="img" src={rupee} />
                <Box>
                  <Typography component="label">Payout Information</Typography>
                  <Typography>Payout Info</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container className="add-user-div accordion-div p-15">
            <Accordion>
              <AccordionSummary
                expandIcon={
                  <svg
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="ArrowDropUpIcon"
                  >
                    <path d="m7 16 4-7 4 7z"></path>
                  </svg>
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="accordian-header"
              >
                Order History
              </AccordionSummary>
              <AccordionDetails>
                <MemberTable
                  columns={OrderHistoryColumns}
                  options={pcOptions}
                  data={OrderHistoryData}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Divider></Divider>
          <Box className="p-15 flex-end">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={2}
              className="retail-btn"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={addOpen}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="add-user-modal" sx={style}>
          <Box className="add-user-div p-15">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New user
            </Typography>
            <CloseIcon onClick={handleCloseAdd} />
          </Box>
          <Divider></Divider>
          <Grid
            className="inputfield-container"
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={2}
          >
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">First Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Last Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Phone No</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Email</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Password</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Button
            variant="outlined"
            color="error"
            mt={2}
            mb={2}
            className="save-btn p-15"
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
});

export default OrderModal;
