import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import ViewSuppliesModal from "./ViewSuppliesModal";
import gm from "../images/gm.jpg";
import bm from "../images/bm.jpg";
import cp from "../images/cp.jpg";
import dal from "../images/dal3.jpg";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import api from "../Api";
import { useSelector, useDispatch } from "react-redux";
import MemberTable from "../Customer/MemberTable";
import { CSVLink } from "react-csv";
import {
  setMeasurementId,
  setProductId,
  setSegments,
} from "../Redux/actions/userActions";
import ViewEditSuppliesModal from "./ViewEditSuppliesModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StockManagement() {
  const response = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const [showState, setShowState] = React.useState(false);
  const [itemStockDetails, setItemStockDetails] = React.useState();
  const [functionality, setFunctionality] = React.useState();
  // const itemStock = useSelector((state) => state.user.item_stock);s
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const [filterItemStockDetails, setFilterItemStockDetails] = React.useState();
  const getItemStockDetails = () => {
    api
      .getData(
        "/api/items/Menu_Supply_Management_details?merchant_id=" +
          response?.merchant_id +
          "&ptype=Ingredient"
      )
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          console.log("Ingredient_Details", JSON.parse(response.data[0]?.Ingredient_Details));
          setItemStockDetails(JSON.parse(response.data[0]?.Ingredient_Details));
          setFilterItemStockDetails(
            JSON.parse(response.data[0]?.Ingredient_Details)
          );
          dispatch(
            setProductId(
              JSON.parse(response.data[0]?.Ingredient_Details)[0].product_id
            )
          );
          dispatch(
            setMeasurementId(
              JSON.parse(response.data[0]?.Ingredient_Details)[0].measurement_id
            )
          );
          //   setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [masterSegments, setMasterSegments] = React.useState();
  const getMasterSegments = () => {
    api
      .getData("/api/universal/Get_Master_Segments")
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          setMasterSegments(JSON.parse(response.data[0]?.MasterSegmentDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [masterSubSegments, setMasterSubSegments] = React.useState();
  const getMasterSubSegments = (segmentVal) => {
    api
      .getData(
        "/api/universal/Get_Master_Sub_Segments?segment=" + segmentVal
      )
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          setMasterSubSegments(JSON.parse(response.data[0]?.SubSegmentDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleFilterSegment = (segName) => {
    getMasterSubSegments(segName);
   if (!segName) {
      setItemStockDetails(filterItemStockDetails);
    } else {
     
      const filteredData = filterItemStockDetails.filter((item) => {
        return item.segment_name === segName;
      });
     
      if(segName == 0){

        setItemStockDetails(filterItemStockDetails);
      }else{
        setItemStockDetails(filteredData);
      }
    }
  };
 
  const handleFilterSubSegment = (itemId) => {
    if (itemId == 0) {
      setItemStockDetails(filterItemStockDetails);
    } else {
      const filteredData = filterItemStockDetails.filter((item) => {
        return item.sub_segment_name === itemId;
      });
      setItemStockDetails(filteredData);
    }
  };
  const [segmentsList, setSegmentList] = React.useState();
  const [subSegmentsList, setSubSegmentList] = React.useState();
  const [productList, setProductList] = React.useState();
  const getSegment = () => {
    api
      .getData(
        "/api/segments/get_merchant_segment_details?merchant_id=" +
          response?.merchant_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          dispatch(
            setSegments(JSON.parse(response.data[0].Merchant_Segement_Details))
          );
          setSegmentList(
            JSON.parse(response.data[0].Merchant_Segement_Details)
          );
          setSubSegmentList(
            JSON.parse(response.data[0].Merchant_Sub_Segement_Details)
          );
          setProductList(JSON.parse(response.data[0].Product_Details));
        }
      });
  };

  useEffect(() => {
    getMasterSegments();
    getSegment();
  }, []);
  useEffect(() => {
    if (masterSegments)
      getMasterSubSegments(masterSegments && masterSegments[0]?.segment);
  }, [masterSegments]);
  const [itemStock, setItemStock] = React.useState();
  const getItemDetailsStock = (pid) => {
    api
      .getData(
        "/api/items/Get_Item_Ingredient_stock_Details?merchant_id=" +
          response?.merchant_id +
          "&product_id=" +
          pid +
          "&ptype=Ingredient"
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          // dispatch(setItemStock(JSON.parse(response.data[0]?.ItemDetails)[0]));
          setItemStock(
            JSON.parse(response.data[0].IngredientDetails) &&
              JSON.parse(response.data[0].IngredientDetails)[0]
          );

          //   setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getItemStockDetails();
  }, []);

  useEffect(() => {
    if (filterItemStockDetails) {
      console.log("filterItemStockDetails", filterItemStockDetails);

      getItemDetailsStock(filterItemStockDetails[0]?.product_id);
    }
  }, [filterItemStockDetails]);
 
  const memeberColumns = [
    {
      name: "product_image",
      label: " ",
      //    label:'',
      options: {
        customBodyRender: (value) => (
          <img
            src={value ? value.split(",")[0] : value}
            alt="Product Image"
            style={{ width: 40, height: 40 }}
          />
        ),
      },
    },
    { name: "product_id", label: "Ingredient ID" },
    { name: "segment_name", label: "Segment" },
    { name: "sub_segment_name", label: "Sub Segment" },
    { name: "product_name", label: "Ingredient Name" },

    { name: "box_quantity", label: "Qty" },
    { name: "product_expiry_date", label: "Expiry Date" },

    { name: "cost_price", label: "Cost Price" },
    // {
    //   name: "product_status",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const rowIndex = tableMeta.rowIndex;
    //       const rowData = itemStockDetails[rowIndex];
    //       return (
    //         <IOSSwitch
    //           checked={value === 0}
    //           onChange={() => {
    //             handleItemStatusChange(rowData);
    //           }}
    //         />
    //       );
    //     },
    //   },
    // },
  ];
  
  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onFilterChange: false,
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: false,
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.dataIndex;
      const clickedRowData = filterItemStockDetails[clickedRowIndex];
      getItemDetailsStock(clickedRowData.product_id);
      dispatch(setProductId(clickedRowData.product_id));
      dispatch(setMeasurementId(clickedRowData.measurement_id));
    },
    expandableRows: false,
    // page: 0,
  };
  const onDownload = () =>{   
  }

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link> */}
                    <Link underline="hover" color="inherit" href="#">
                      Menu
                    </Link>
                    <Typography color="text.primary">
                      Supplies Management
                    </Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                   
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid>
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box mb={2} className="sm-selects">
                          <Typography component="h2" className="sub_title">
                            SUPPLIES MANAGEMENT
                          </Typography>
                          <Box>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select filter_select"
                              onChange={(e) => {
                                handleFilterSegment(e.target.value);
                              }}
                              // input={<OutlinedInput />}
                              defaultValue="0"
                            >
                              <MenuItem value="0">All</MenuItem>
                              {masterSegments?.map((item, index) => {
                                return (
                                  <MenuItem value={item.segment}>
                                    {item.segment}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select filter_select"
                              onChange={(e) => {
                                handleFilterSubSegment(e.target.value);
                              }}
                              // input={<OutlinedInput />}
                              defaultValue="0"
                            >
                              <MenuItem value="0">All</MenuItem>
                              {masterSubSegments?.map((item, index) => {
                                return (
                                  <MenuItem
                                    value={item.sub_segment}
                                  >
                                    {item.sub_segment}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {/* <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select filter_select"
                              onChange={(e)=>{handleFilterProduct(e.target.value)}}
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              defaultValue="0"
                            >
                              <MenuItem value="0">All</MenuItem>
                              {productList?.map((item, index) => {
                                return (
                                  <MenuItem value={item.product_id}>
                                    {item.product_name}
                                  </MenuItem>
                                );
                              })}
                            </Select> */}
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn ml-15"
                              mr={2}
                              onClick={() => {
                                setFunctionality("add");
                                ref2.current.log();
                              }}
                            >
                              Add New Ingredient
                            </Button>
                            <CSVLink data={itemStockDetails ? itemStockDetails : []} filename={"item_stock.csv"}>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn"
                              mr={2}
                            >
                              Download
                            </Button>
                            </CSVLink>
                          </Box>
                        </Box>
                       
                        <Box className="member-table">
                          <MemberTable
                            columns={memeberColumns}
                            options={options}
                            data={itemStockDetails ? itemStockDetails : []}
                            // data={[]}

                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box>
                          <Grid container mb={2}>
                            <Box className="flex-between w-100">
                              <Typography
                                style={{ textTransform: "uppercase" }}
                                component="h4"
                              >
                                Item ID - {itemStock?.product_id}
                              </Typography>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                className="retail-btn"
                                onClick={() => {
                                  setFunctionality("edit");
                                  ref3.current.log();
                                }}
                              >
                                View More
                              </Button>
                            </Box>
                          </Grid>

                          <Box className="information-div" mt={2}>
                            <Box
                              component="img"
                              className="w-80"
                              src={
                                itemStock?.product_image
                                  ? itemStock.product_image.split(",")[0]
                                  : dal
                              }
                            />
                            <Box className="w-81">
                              <Box className="flex-between">
                              
                                  <Typography
                                    component="h2"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {itemStock?.product_name}
                                  </Typography>
                                  {/* <Typography component="h4">
                                    {itemStock?.product_uom}
                                  </Typography> */}
                                  <Typography
                                  component="span"
                                  className="text-danger fw-bold"
                                >
                                  Available Stock
                                </Typography>
                                </Box>
                                {/* <Typography
                                  component="span"
                                  className="text-danger fw-bold"
                                >
                                  Rs {itemStock?.cost_price}/-
                                </Typography> */}
                             
                              <Box className="flex-between">
                                {/* <Button
                                className={
                                  active === 1 ? "retail-btn" : "normal-text"
                                }
                                style={{ textTransform: "uppercase" }}
                                onClick={() => changeActive(1)}
                              > */}{" "}
                              <Typography className="font-normal" mt={1}>
                                {itemStock?.product_uom}
                                </Typography>
                                <Typography className="font-normal" mt={1}>
                                {itemStock?.product_qty_physical}{itemStock?.product_uom}
                              </Typography>
                                {/* </Button> */}
                              </Box>
                              
                            </Box>
                          </Box>
                          <Box className="w-50 text-center"></Box>
                        </Box>

                        <Box
                          sx={{ width: "100%" }}
                          mt={2}
                          className="fullstack-div"
                        >
                          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              className="stack-tabs"
                            >
                              <Tab label="Description" {...a11yProps(0)} />
                              <Tab label="Specifications" {...a11yProps(1)} />
                              <Tab label="Instructions" {...a11yProps(2)} />
                            </Tabs>
                          </Box> */}
                          {/* <TabPanel value={value} index={0}>
                            <Typography component="span" className="f-12">
                              {itemStock?.product_description}
                            </Typography>
                          </TabPanel> */}
                          {/* <TabPanel value={value} index={1}>
                            <Typography component="span" className="f-12">
                              {itemStock?.product_specifications}
                            </Typography>
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <Typography component="span" className="f-12">
                              {itemStock?.product_how_to_use}
                            </Typography>
                          </TabPanel> */}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <ViewSuppliesModal
          ref={ref3}
          itemStock={itemStock}
          functionality={functionality}
          masterSegments={masterSegments}
          getItemStockDetails={getItemStockDetails}
        />
         <ViewEditSuppliesModal
          ref={ref2}
          itemStock={{}}
          functionality={functionality}
          masterSegments={masterSegments}
          getItemStockDetails={getItemStockDetails}
        />
      </Box>
    </>
  );
}
