import React, { useImperativeHandle, forwardRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MySwal = withReactContent(Swal);

const DiscountModal = forwardRef((props, ref4) => {
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState(false);
  const handlePassword = () => {
    setPasswd((passwd) => !passwd);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "All Fields Are Mandatory",
    });
  };

  useImperativeHandle(ref4, () => ({
    log() {
      handleOpen();
    },
  }));

  //   if(openM){
  //     handleOpen();
  //   }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="parent-modal"
      >
        <Box sx={{ ...style }} className="profile-modal">
          <Grid container>
            <Grid item xs={12} className="profile-header p-15">
              <Typography id="parent-modal-title" component="h4">
                DISCOUNT
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <Divider></Divider>

          <Grid container className="details-div p-15" spacing={2}>
            <Grid item xs={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Name</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="location-select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Manager Discount</MenuItem>
                  <MenuItem>Promo Code</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Discount Type</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="location-select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="locations"
                >
                  <MenuItem value="locations">Flat Rate</MenuItem>
                  <MenuItem>Percentage</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Discount</Typography>
                <OutlinedInput placeholder="Percentage" />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl className="w-100">
                <Typography component="label">Description</Typography>
                <OutlinedInput
                  placeholder=""
                  components="textarea"
                  minRows={5}
                  multiline={true}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Divider></Divider>
          <Box className="p-15 text-right">
            <Button
              variant="outlined"
              color="error"
              className="retail-btn mr-15"
              onClick={handleClose}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default DiscountModal;
