
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { PhoneVerification } from './Validation';
import { useFormik } from "formik";
import api from "./Api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));


export default function VerificationScreen(props) {
  const [message,setMessage] = React.useState();
  const [status,setStatus] = React.useState();
  const navigate = useNavigate();
  const location = useLocation();
const [logo,setLogo] = React.useState();
React.useEffect(() => {
  // Update favicon based on condition
  if (process.env.REACT_APP_MY_TITLE === 'Bazaar') {
    setLogo(process.env.REACT_APP_MY_LOGO_URL);
  } else if(process.env.REACT_APP_MY_TITLE === 'Vikretha'){
    setLogo(process.env.REACT_APP_MY_LOGO_URL_VIKRETA);
  }
}, []);
  const handleSubmit = (values) => {
   
    if(formik.values.phone === undefined){
      formik.values.phone = phonenumber;
    }
    api.postData('/api/merchant/verify_phone', formik.values).then((response) => {
      console.log('response.data[0].Status',response.data[0].Status);
      
        console.log('JSON.parse(response.data[0].Status)',JSON.parse(response.data[0].Status));
        if(response.data[0].Status === '200'){
          setStatus(200);
          if(location.state.verifyEmail === 'No'){
          setMessage(response.data[0].Message);
          setTimeout(()=>{
            navigate('/');
          },2000)
        }else{
          setMessage(response.data[0].Message+' And Please Verify Email Before Login');
          setTimeout(()=>{
            navigate('/');
          },3000)
        }
        }
       else {
        console.log('message');
        setStatus(500);
        setMessage(response.data[0].Message);
        
      }
    })
  };
  const [phonenumber,setPhonenumber] = React.useState();
  const ResentOtp = () =>{
    console.log('location.state',location);
    api.postData('/api/merchant/resend_phone_otp', {'email_id':location.state.email}).then((response) => {
      console.log('response.data[0].Status',response.data.Status);
      setPhonenumber(response.data.PhoneNumber);
        console.log('response',response);
    })
  }
  React.useEffect(() => {
    // fetchData();
  }, [message,status])
  const formik = useFormik({
    initialValues: {
      'phone':location.state?.phone_number || phonenumber,
      'otp':'',
    },
    validationSchema: PhoneVerification,
    onSubmit: handleSubmit,
  });

  const handleData = (event) => {
    const { name, value } = event.target;
    const updatedValues = {
      ...formik.values,
      [name]: value,
    };
    formik.setValues(updatedValues);
  }
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
  } = formik;

  
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box className="Absolute-Box text-center">
          <Box sx={{ minWidth: 275, paddingTop: 3 }}>
            <Card variant="outlined">
              <Box
                src={logo}
                className="App-logo"
                alt="logo"
                mt={3}
                component="img"
              />
              <CardContent className="card-content text-center">
                <Typography
                  className="clr-primary fw-bold"
                  variant="h5"
                  gutterBottom
                >
                  Phone Verification
                </Typography>
                <Box className="">
                  {/* <FormControl className="w-100 mt-10" variant="standard">
                    <InputLabel shrink htmlFor="username-input">
                      Email OTP
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      id="email-input"
                      type="password"
                      className="mb-3"
                      placeholder="Enter Six Digit OTP Sent to Mail"
                    />
                  </FormControl> */}
                  <form onSubmit={formik.handleSubmit}>
                  <FormControl className="w-100 mt-10" variant="standard">
                    <InputLabel shrink htmlFor="username-input">
                      Phone OTP
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      id="mobile-input"
                      name="otp"
                      type="password"
                      onChange={handleChange}
                      onBlur={(e) => { handleBlur(e); handleData(e) }}
                      placeholder="Enter Six Digit OTP Sent to Mobile"
                    />
                  </FormControl>
                  <Box className="w-100" mt={1}>
                  {message? <Box Typography="small" className={status===200?"text-success":"text-danger"}>{message}</Box>:''}
                  </Box>
                  <BootstrapInput component="input" variant="contained" className="mt-3 w-50 btn-submit py-2 mx-0"  value="Submit" type="submit">
                      Submit
                    </BootstrapInput>
                  </form>
                  <Box className="w-100">
                  <Button variant="outlined" className="mt-3 w-50  py-2 mx-0 retail-btn" onClick={ResentOtp}>
                     Resend
                  </Button>
                  </Box>
                  <Box className="mt-3">
                    <Typography className="" variant="body2" gutterBottom>
                      Still facing issue ?<Box component="br" /> Contact{" "}
                      <a href="tel:1234567890" className="frgt-pswrd">
                        {" "}
                        +91-1234567890
                      </a>{" "}
                      or Email{" "}
                      <a
                        href="mailto:someone@example.com"
                        className="frgt-pswrd"
                      >
                        {" "}
                        someone@example.com
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

// export default App;
