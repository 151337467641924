import React, { useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import api from "../Api";
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { doorDelivery } from "../Validation";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const shiftdetailsColumns = [
  { name: "S.No", options: { filterOptions: { fullWidth: true } } },
  "Working Days",
  "Timings",
];

const shiftdetailsdata = [];
const managementColumns = [
  { name: "S.No", options: { filterOptions: { fullWidth: true } } },
  "Name",
  "Number",
  "Attachment",
];
const managementdata = [];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AddCustomer = forwardRef((props, ref3) => {
  const [open, setOpen] = React.useState(false);
  const response = useSelector(state => state.user.user);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const [value, setValue] = React.useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);
   
  const viColumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Inspection",
    "Status",
    "Comments",
  ];

  const viOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];
  const showSwalWithLink = (message) => {
    MySwal.fire({
        icon: 'success',
        title: message,
    }).then(()=>{
      props?.getCustomers();
      handleClose();
    })
};

const showSwalWithLinkError = (message) => {
  MySwal.fire({
      icon: 'error',
      title: message,
  })
};
const handleSubmit = async (values, { setSubmitting }) => {

  let data = new FormData();
  data.append('customer_id', '0');
  data.append('bazaar_id', process.env.REACT_APP_MY_TITLE ==='Vikretha'?0:1);
  data.append('merchant_id', response?.merchant_id);
  data.append('name_of_the_customer', formik.values.name_of_the_customer);
  data.append('email_id', formik.values.email);
  data.append('phone_number', formik.values.phone_number);
  data.append('password', '123456');
  data.append('gender', 'male');
  data.append('birthday', "11/11/1995");
  data.append('occasion_date', '');
  data.append('otp_status', '0');
  data.append('city_id', response?.city_id);
  data.append('cod_status', '0');
  data.append('block_status', '1');
  data.append('cust_address', formik.values.cust_address);
  data.append('cust_area', 'x');
  data.append('cust_locality', 'x');
  data.append('cust_street', 'x');
  data.append('cust_city',response?.city_id);
  data.append('pincode', '123456');
  data.append('address_type', 'd');
  data.append('adress_status', '0');
  data.append('profilephoto', '');



  
      api.updateData('/api/pos/customer_Create_Update', data).then((response) => {
         console.log('response',response);
          if (response.data.Status === "200") {
              showSwalWithLink(response.data.Message);
              
              if(response.data.IsPhoneVerifyRequired === 'yes'){
                  // ref6.current.log()
                  // setPhoneNumber(response.phone_number);
              }
          }else{
              showSwalWithLinkError(response.data.Message)
          }

      }).catch((error) => {
        console.log(error)
      })
      // Handle network error
  

  setSubmitting(false);
};

// const ref6 = useRef();

const initialValues = {
  'name_of_the_customer': '',
  'phone_number': '',
  'email': '',
  'cust_address': ''

}
const formik = useFormik({
  initialValues,
  validationSchema: doorDelivery,
  onSubmit: handleSubmit,
  enableReinitialize:true,
});
// const formik = useFormik({
//     initialValues:{
//       'customer_id': props.CustomerDetails?.customer_id || '',
//       'first_name': props.CustomerDetails?.first_name || '',
//       'last_name': props.CustomerDetails?.last_name || '',
//       'email_id': props.CustomerDetails?.email_id || '',
//       "phone_number":props.CustomerDetails?.phone_number || '',
//       'customer_type': props.CustomerDetails?.customer_type || '',
//       'cust_address': props.CustomerDetails?.cust_address || '',
  
//   },
//     validationSchema: AddCustomerValidation,
//     onSubmit: handleSubmit,
//     enableReinitialize:true,
// });

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
} = formik;
  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
      formik.setValues({});
      formik.setTouched({}, false);
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content">
          <Box className="modal-header p-15">
            <Typography
              id="modal-modal-title"
              variant="h5"
              className="m-heading"
              component="h5"
            >
              Add Customer
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <form onSubmit={formik.handleSubmit}>
          <Box className="accordion-space">
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={6}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Name</Typography>
                  <OutlinedInput placeholder="First Name" name="name_of_the_customer" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {errors.name_of_the_customer && touched.name_of_the_customer && <Box Typography="small" className="float-left text-danger">{errors.name_of_the_customer}</Box>}
                </FormControl>
              </Grid>
              {/* <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Last Name</Typography>
                  <OutlinedInput placeholder="Last Name" name="last_name" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {errors.last_name && touched.last_name && <Box Typography="small" className="float-left text-danger">{errors.last_name}</Box>}
                </FormControl>
              </Grid> */}
              <Grid item md={6}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Phone Number</Typography>
                  <OutlinedInput placeholder="Phone Number" name="phone_number" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {errors.phone_number && touched.phone_number && <Box Typography="small" className="float-left text-danger">{errors.phone_number}</Box>}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={6}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Email ID</Typography>
                  <OutlinedInput placeholder="Email" name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {errors.email && touched.email && <Box Typography="small" className="float-left text-danger">{errors.email}</Box>}
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Address</Typography>
                  <OutlinedInput placeholder="Address" name="cust_address" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                  {errors.cust_address && touched.cust_address && <Box Typography="small" className="float-left text-danger">{errors.cust_address}</Box>}
                </FormControl>
              </Grid>
              {/* <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Customer Type</Typography>
                  <OutlinedInput placeholder="Customer Type" name="customer_type" onChange={formik.handleChange} onBlur={formik.handleBlur} />

                  {errors.customer_type && touched.customer_type && <Box Typography="small" className="float-left text-danger">{errors.customer_type}</Box>}
                </FormControl>
              </Grid> */}
            </Grid>
          </Box>
          <Divider></Divider>
          <Box textAlign={"right"} marginX={2} marginY={2}>
            <Button
              variant="outlined"
              color="error"
              mb={2}
              className="btn-outline-primary"
              type="submit"
              // onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
          </form>
        </Box>
      </Modal>
      <Modal
        open={addOpen}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="add-user-modal" sx={style}>
          <Box className="add-user-div p-15">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New user
            </Typography>
            <CloseIcon onClick={handleCloseAdd} />
          </Box>
          <Divider></Divider>
          <Grid
            className="inputfield-container"
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={2}
          >
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">First Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Last Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Phone No</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Email</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Password</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Button
            variant="outlined"
            color="error"
            mt={2}
            mb={2}
            className="save-btn p-15"
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
});

export default AddCustomer;
