import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
  Icon,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MemberTable from "../Customer/MemberTable";
import { OutlinedInput, FormControl, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import Bredcrum from "../Bredcrum";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import EditIcon from "../images/edit-icon.png";
import DeleteIcon from "../images/delete-icon.png";
import Scheduler from "./Scheduler";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: true,

  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const pastmanagementColumns = [
  { name: "Employee Name", options: { filterOptions: { fullWidth: true } } },
  "Location",
  "Designation",
  "Start Time",
  "End Time",
  "Status",
  "",
];
const pastmanagementdata = [
  [
    "SreeRamu",
    "Visakhapatnam",
    "Manager",
    "10:30AM",
    "06:00PM",
    "Assigned",
    <Box>
      <Box component="img" className="table-icon" mr={1} src={EditIcon} />
      <Box component="img" className="table-icon" src={DeleteIcon} />
    </Box>,
  ],
  [
    "Manohar",
    "Visakhapatnam",
    "DEO",
    "10:30AM",
    "06:00PM",
    "Assigned",
    <Box>
      <Box component="img" className="table-icon" mr={1} src={EditIcon} />
      <Box component="img" className="table-icon" src={DeleteIcon} />
    </Box>,
  ],
  [
    "Sai",
    "Visakhapatnam",
    "DEO",
    "10:30AM",
    "06:00PM",
    "Assigned",
    <Box>
      <Box component="img" className="table-icon" mr={1} src={EditIcon} />
      <Box component="img" className="table-icon" src={DeleteIcon} />
    </Box>,
  ],
];

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TableScheduling() {
  const [open, setOpen] = useState(false);
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const MySwal = withReactContent(Swal);
  const ShiftColumns = [
    { name: "DRIVER NAME", options: { filterOptions: { fullWidth: true } } },
    "START TIME",
    "END TIME",
    "PHONE NUMBER",
  ];

  const driverData = [
    // ["Gabby George", "Business Analyst", "Minneapolis",""],
  ];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const pricingOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const ref4 = React.useRef();
  const ref1 = React.useRef();
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  {/* <Grid container mt={2}>
                    <Grid item xs="12">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                        className="filters"
                      >
                        
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Button
                            variant="outlined"
                            className="btn-outline-primary btn-space"
                            onClick={() => ref4.current.log()}
                          >
                            Schedule Employee
                          </Button>
                          <Button
                            variant="outlined"
                            className="btn-outline-primary btn-space"
                            onClick={() => ref1.current.log()}
                          >
                            Clone
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid> */}
                  <Grid container spacing={2} mt={1}>
                    <Grid item md={4} className="">
                      <Card className="left-calender-grid">
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <FormControl className="w-100">
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="select viewmore-dpdown"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="Suppliers"
                                >
                                  <MenuItem value="Suppliers">City</MenuItem>
                                  <MenuItem value="car">Visakhapatnam</MenuItem>
                                  <MenuItem value="van">Hyderabad</MenuItem>
                                  <MenuItem value="van">Guntur</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={6}>
                              <FormControl className="w-100">
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="select viewmore-dpdown"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="Suppliers"
                                >
                                  <MenuItem value="Suppliers">
                                    Location
                                  </MenuItem>
                                  <MenuItem value="car">Gachibowli</MenuItem>
                                  <MenuItem value="van">Madhapur</MenuItem>
                                  <MenuItem value="van">Hi-Tech City</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1}>
                            <Grid item md={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <StaticDatePicker />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} mt={1} paddingX={2}>
                            <Grid item md={12}>
                              <Box className="movie-cards mc-active">
                                <Typography component="p" className="mve-name">
                                  Table 1
                                </Typography>
                                <Typography
                                  component="p"
                                  className="screens-count"
                                >
                                  2 Seater
                                </Typography>
                                <Typography
                                  component="p"
                                  className="screens-count"
                                >
                                  6
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item md={12}>
                              <Box className="movie-cards">
                                <Typography component="p" className="mve-name">
                                  Table 2
                                </Typography>
                                <Typography
                                  component="p"
                                  className="screens-count"
                                >
                                  4 Seater
                                </Typography>
                                <Typography
                                  component="p"
                                  className="screens-count"
                                >
                                  6
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item md={12}>
                              <Box className="movie-cards">
                                <Typography component="p" className="mve-name">
                                  Table 3
                                </Typography>
                                <Typography
                                  component="p"
                                  className="screens-count"
                                >
                                  6 Seater
                                </Typography>
                                <Typography
                                  component="p"
                                  className="screens-count"
                                >
                                  6
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item md={12}>
                              <Box className="movie-cards">
                                <Typography component="p" className="mve-name">
                                  Table 4
                                </Typography>
                                <Typography
                                  component="p"
                                  className="screens-count"
                                >
                                  8 Seater
                                </Typography>
                                <Typography
                                  component="p"
                                  className="screens-count"
                                >
                                  6
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={8}>
                      <Card>
                        <CardContent>
                          <Scheduler />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <ProfileModal ref={ref} />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="shift-modal">
            <Box className="shift-header" mb={2}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                SCHEDULE EMPLOYEE
              </Typography>
              <Close onClick={handleClose} />
            </Box>
            <Divider></Divider>
            <Grid container spacing={2} mb={3} mt={2}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <Typography component="label">Employee Name</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    defaultValue="locations"
                  >
                    <MenuItem value="locations">Select Driver</MenuItem>
                    <MenuItem>Debray</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <Typography component="label">Employee Name</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    defaultValue="locations"
                  >
                    <MenuItem value="locations">Please Select</MenuItem>
                    <MenuItem>Debray</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <Typography component="label">Schedule Start Date</Typography>
                  <OutlinedInput type="date" />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <Typography component="label">Schedule End Date</Typography>
                  <OutlinedInput type="date" />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <Typography component="label">Start Time</Typography>
                  <OutlinedInput type="time" />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <Typography component="label">End Time</Typography>
                  <OutlinedInput type="time" />
                </FormControl>
              </Grid>
            </Grid>
            <Divider></Divider>
            <Box mt={3}>
              <Button variant="outlined" color="error" className="save-btn">
                Submit
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
