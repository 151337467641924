import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput, TextareaAutosize, FormControlLabel, Switch, FormGroup, Checkbox } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import api from '../Api';
import { useFormik } from "formik";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { SubSegmentSchema } from '../Validation';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const AddSubSegmentModal = forwardRef((props, ref3,onHandle) => {
    const data=props.props;
    const response = useSelector(state => state.user.segments);
    const user = useSelector(state => state.user.user);
    console.log('data',props.editData);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const MySwal = withReactContent(Swal);
    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleCloseAdd = () => setAddOpen(false);

    const showSwalWithLink = (message) => {
        MySwal.fire({
            icon: 'success',
            title: message,
        }).then(function(){
            props?.onHandle(true);
            handleClose();
        })
      };
      const showSwalWithLinkError = (message) => {
        MySwal.fire({
            icon: 'error',
            title: message,
        })
      };

    const handleSubmit = (values) => {
        console.log('formik.values',formik.values);
        const formData = new FormData();
        formData.append('sub_segment_id', formik.values.sub_segment_id || 0);
        formData.append('segment_id',formik.values.segment_id || 0);
        formData.append('sub_segment_name', formik.values.sub_segment_name);
        formData.append('merchant_id', user?.merchant_id);
        formData.append('sub_segment_aisle', '1');
        formData.append('sub_segment_row', '1');
        // formData.append('segment_image', profileImage);

      
        try {
            api.updateData('/api/segments/create_update_sub_segment', formData).then((response) => {
             
                  if(response.data[0].Status === "200"){
                    showSwalWithLink(response.data[0].Message);
                  }else{
                    showSwalWithLinkError(response.data[0].Message)
                  }
                
              })
      
          
        } catch (error) {
          // Handle network error
        }
      };

      const [subSuggestions,setSubSuggestions ] = React.useState();
      const segmentSuggestions = () => {
  
          api.getData('/api/segments/master_sub_segments')
              .then((response) => {
                console.log('response',response);
                    if (response.data[0].Status === 200) {
  
                  setSubSuggestions(JSON.parse(response.data[0].SubSegmentDetails));
                  // setSpinner(false);
                    }
  
  
              })
              .catch((error) => {
                  console.log(error)
              })
  
  
      };
      useEffect(() => {
          segmentSuggestions();
      }, []);
      const [segment,setSegments ] = React.useState();
      const getSegment = () => {
  
        api.getData('/api/segments/master_segments')
            .then((response) => {
                  if (response.data[0].Status === "200") {
                setSegments(response.data[0].SubSegmentDetails);
                // setSpinner(false);
                  }


            })
            .catch((error) => {
                console.log(error)
            })


    };
    useEffect(() => {
        getSegment();
    }, [])
    const initialValues= {
        'sub_segment_id':props.editData?.merchant_sub_segment_id || '',
        'segment_id': props.editData?.merchant_segment_id || '',
        'sub_segment_name': props.editData?.merchant_sub_segment_name || '',
        'merchant_id':props.editData?.merchant_id || '',
        'sub_segment_aisle': '',
        'sub_segment_row':'1'
      
    }
      const formik = useFormik({
        initialValues,
        validationSchema: SubSegmentSchema,
        onSubmit: handleSubmit,
        enableReinitialize:true,
      });
      useEffect(() => {
        formik.setValues(initialValues);
      }, [props.editData]);
    const memeberColumns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        "Last Name",
        "Email",
        "Password"
    ];

    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        // pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref3, () => ({
        log() {
            handleOpen();
            formik.setValues({});
            formik.setTouched({}, false);
        }
    }));

    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
      } = formik;
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className='view-more-content subsegment-product adm-modal'>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            SUB SEGMENT DETAILS
                        </Typography>
                        <CloseIcon onClick={handleClose} />
                    </Box>




                    <Divider></Divider>

                    <form onSubmit={formik.handleSubmit}>
                    <Grid container className='p-15' >


                        <Grid item xs={2} sm={12} md={12}>

                            <FormControl className='w-100'>
                                <Typography component="label">Segment Name</Typography>
                                <Select
                                    labelId="demo-isolation-label"
                                    id="isolation-select"
                                    className="select"
                                    // onChange={handleSelectOxygen}
                                    // input={<OutlinedInput />}
                                    value={formik.values?.segment_id}
                                    onChange={(e)=>handleChange(e)}
                                    onBlur={(e) => { handleBlur(e) }}
                                    name='segment_id'
                                >
                                    
                                {
                                    response?.map((item)=>{
                                       return <MenuItem value={item.merchant_segment_id}>{item.merchant_segment_name}</MenuItem> 
                                    })
                                }

                                </Select>
                            </FormControl>
                            {errors.segment_id && touched.segment_id && <Box Typography="small" className="float-left text-danger">{errors.segment_id}</Box>}

                            <Box mt={2}>

                                <FormControl className='w-100'>
                                    <Typography component="label">Sub Segment Name</Typography>
                                    <OutlinedInput 
                                    value={formik.values?.sub_segment_name}
                                    onChange={(e)=>handleChange(e)}
                                    onBlur={(e) => { handleBlur(e) }}
                                    name='sub_segment_name'
                                    />
                                </FormControl>
                                <ul>
                                        {subSuggestions?.filter(subsuggestion => subsuggestion.toLowerCase().includes(formik.values.segment.toLowerCase()))
                                            .map((subsuggestion, index) => (
                                                <li key={index}>{subsuggestion}</li>
                                            ))}
                                    </ul>
                            {errors.sub_segment_name && touched.sub_segment_name && <Box Typography="small" className="float-left text-danger">{errors.sub_segment_name}</Box>}

                            </Box>
                            {/* <Box mt={2}>
                                <FormControl className='w-100'>
                                    <Typography component="label">Isle</Typography>
                                    <OutlinedInput 
                                     value={formik.values?.sub_segment_aisle}
                                    onChange={(e)=>handleChange(e)}
                                    onBlur={(e) => { handleBlur(e) }}
                                    name='sub_segment_aisle'
                                    />
                                </FormControl>
                                {errors.sub_segment_aisle && touched.sub_segment_aisle && <Box Typography="small" className="float-left text-danger">{errors.sub_segment_aisle}</Box>}

                            </Box>
                            <Box mt={2}>
                                <FormControl className='w-100'>
                                    <Typography component="label">Rows</Typography>
                                    <OutlinedInput 
                                    value={formik.values?.sub_segment_row}
                                    onChange={(e)=>handleChange(e)}
                                    onBlur={(e) => { handleBlur(e) }}
                                    name='sub_segment_row'
                                    />
                                </FormControl>
                                {errors.sub_segment_row && touched.sub_segment_row && <Box Typography="small" className="float-left text-danger">{errors.sub_segment_row}</Box>}

                            </Box> */}
                        </Grid>



                    </Grid>
                    <Divider></Divider>

                    <Box className="p-15">
                        <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right' type='submit'>Submit</Button>
                    </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
});

export default AddSubSegmentModal;