import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import upload from "../images/upload.svg";
import api from "../Api";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { SegmentSchema } from "../Validation";
import { useNavigate } from 'react-router-dom';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddSegmentModal = forwardRef((props, ref1, onHandle) => {
  const data = props.props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () =>{  navigate("/admin/Profile"); setOpen(false); }
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(null);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);
  const [success, setSuccess] = React.useState(false);
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      props?.onHandle(true);
      handleClose();
    });
  };
  const showSwalWithLinkError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    }).then(function () {

    });
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append(
      "segment_id",
      values.segment_id
        ? values.segment_id
        : props?.editData?.merchant_segment_id
        ? props?.editData?.merchant_segment_id
        : 0
    );
    formData.append(
      "segment_name",
      values.segment_name
        ? values.segment_name
        : props?.editData?.merchant_segment_name
    );
    formData.append(
      "merchant_id",
      data.merchant_id ? data.merchant_id : props?.editData?.merchant_id
    );
    formData.append(
      "segment_aisle",
      values.segment_aisle
        ? values.segment_aisle
        : props?.editData?.merchant_segment_aisle
    );
    formData.append("segment_image", profileImage?profileImage:formik.values.segment_image);

    try {
      api
        .updateData("/api/segments/create_update", formData)
        .then((response) => {
          console.log("response", response);
          if (response.data[0].Status === "200") {
            showSwalWithLink(response.data[0].Message);
            props?.getSubSegment(values.segment_id
              ? values.segment_id
              : props?.editData?.merchant_segment_id
              ? props?.editData?.merchant_segment_id
              : 0)
            setSuccess(true);
          }else{
            showSwalWithLinkError(response.data[0].Message)
          }
        });
    } catch (error) {
      // Handle network error
    }
  };
  const initialValues = {
    segment_id: props.editData?.merchant_segment_id || "0",
    segment_name: props.editData?.merchant_segment_name || "",
    merchant_id: props.editData?.merchant_id || "",
    segment_aisle: "1",
    segment_image: props.editData?.merchant_segment_image || "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: SegmentSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    formik.setValues(initialValues);
    if(props.editData===undefined || props.editData===null){
      formik.setValues(null);
    }
    setImagePreviewUrl(null);

  }, [props.editData]);

  const [suggestions, setSuggestions] = React.useState();
  const segmentSuggestions = () => {
    api
      .getData("/api/segments/master_segments")
      .then((response) => {
        if (response.data[0].Status === "200") {
          setSuggestions(JSON.parse(response.data[0].SegmentDetails));
          // setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    segmentSuggestions();
  }, []);

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];
  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [profileImage, setProfileImage] = React.useState(null);
  const handleImageSelection = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };
  useImperativeHandle(ref1, () => ({
    log() {
      handleOpen();
      // formik.setValues({});
      formik.setValues(initialValues);
      setImagePreviewUrl(null);
      formik.setTouched({}, false);
    },
  }));
  // const handleParent = (e) => {
  //     onChange(e.target.value);
  //   };
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="view-more-content subsegment-product adm-modal"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              SEGMENT DETAILS
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>

          <Divider></Divider>

          <form onSubmit={formik.handleSubmit}>
            <Grid container className="p-15">
              <Grid item xs={2} sm={12} md={12}>
                <FormControl>
                  <Box
                    src={
                      imagePreviewUrl
                        ? imagePreviewUrl
                        : data?.props?.merchant_segment_image
                        ? data?.props.merchant_segment_image
                        : formik.values?.segment_image
                        ? formik.values?.segment_image
                        : upload
                    }
                    component="img"
                    className="p-img"
                    mb={2}
                  />
                  <OutlinedInput
                    value=""
                    type="file"
                    className="file-upload"
                    onChange={(e) => {
                      handleChange(e);
                      handleImageSelection(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    name="segment_image"
                  />
                </FormControl>
                {errors.segment_image && touched.segment_image && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.segment_image}
                  </Box>
                )}

                <Box mt={2}>
                  <FormControl className="w-100">
                    <Typography component="label">Segment Name</Typography>
                    <OutlinedInput
                      name="segment_name"
                      id="segment_name"
                      value={formik.values?.segment_name}
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      // readOnly={false}
                    />
                  </FormControl>
                  {errors.segment_name && touched.segment_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.segment_name}
                    </Box>
                  )}

                  <ul>
                    {suggestions
                      ?.filter(
                        (suggestion) =>
                          typeof suggestion === "string" &&
                          suggestion
                            .toLowerCase()
                            .includes(formik.values.segment.toLowerCase())
                      )
                      .map((suggestion, index) => (
                        <li key={index}>{suggestion}</li>
                      ))}
                  </ul>
                </Box>
                {/* <Box mt={2}>
                                    <FormControl className='w-100'>
                                        <Typography component="label">Isle</Typography>
                                        <OutlinedInput
                                            name='segment_aisle'
                                            value={formik.values?.segment_aisle}
                                            onChange={(e) => handleChange(e)}
                                            onBlur={(e) => { handleBlur(e) }}
                                        />
                                    </FormControl>
                                    {errors.segment_aisle && touched.segment_aisle && <Box Typography="small" className="float-left text-danger">{errors.segment_aisle}</Box>}

                                </Box> */}
                {/* <Box mt={2}>
                                <FormControl className='w-100'>
                                    <Typography component="label">Rows</Typography>
                                    <OutlinedInput 
                                    name='segment_aisle'
                                    
                                    onChange={(e)=>handleChange(e)}
                                    onBlur={(e) => { handleBlur(e) }}
                                    />
                                </FormControl>

                            </Box> */}
              </Grid>
            </Grid>
            <Divider></Divider>

            <Box className="p-15">
              <Button
                variant="outlined"
                color="error"
                mt={2}
                mb={3}
                className="retail-btn float-right"
                value="Submit"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
});

export default AddSegmentModal;
