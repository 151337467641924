import React, { useImperativeHandle, forwardRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import PromoDiscountModal from "./PromoDiscountModal";
import api from "../Api";
import { useDispatch, useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MySwal = withReactContent(Swal);

const DiscountModal = forwardRef((props, ref4) => {
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState();
  const [promoValue, setPromoValue] = React.useState();
  let pendingInvoiceId = useSelector((state) => state.user.pos_invoice_id);
  let customerId = useSelector((state) => state.user.customer_id);
  console.log(useSelector((state) => state.user))
  const handlePassword = () => {
    setPasswd((passwd) => !passwd);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const showSwalWithLink = (message) => {
    MySwal.fire({
        icon: 'success',
        text: message,
    })
    // .then(() => {
    //     handleClose();
    // })
};
const showSwalWithLinkError = (message) => {
    MySwal.fire({
        icon: 'error',
        text: message,
    });
};
  const [promoCodeName, setPromoCodeName] = React.useState("Discount");
  const handlePromoCode = (value) => {
    setPromoCode(value);
    if (value === 3 || value === 4 || value === 5) {
      // ref1.current.log();
      setPromoCodeName("Code");
    } else {
      setPromoCodeName("Discount (%)");
    }
  };

  const posDiscountValidate = () => {
    api
      .getData("/api/promotions/POS_discount_validate?customer_id="+customerId+"&promo_code="+promoValue+"&promo_type="+promoCode)
      .then((response) => {
        if (response.data[0].status === "200") {
            applyPromoCode();
        }else{
                 showSwalWithLinkError(response.data[0].Message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const applyPromoCode = () => {
    let data = {
      promo_code: (promoCode === 3 || promoCode === 4 || promoCode === 4) ? promoValue : null,
      invoice_id: pendingInvoiceId,
      customer_id: customerId,
      special_discount_id: promoCode,
      discount_base_value: (promoCode === 1 || promoCode === 2) ? promoValue : null,
    };
    api
      .postData("/api/promotions/apply_discount_on_total", data)
      .then((response) => {
        console.log("response", response);
        if (response.data[0].status === "200") {
          // setSpecialDiscountNames(JSON.parse(response.data[0].SpecialDiscounts));
          showSwalWithLink(response.data[0].Message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const removePromoCode = () => {
    let data = {
      invoice_id: pendingInvoiceId,
      customer_id: customerId,
    };
    api
      .postData("/api/promotions/remove_special_discount_pos", data)
      .then((response) => {
        console.log("response", response);
        if (response.data[0].status === "200") {
          // setSpecialDiscountNames(JSON.parse(response.data[0].SpecialDiscounts));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const ref1 = React.useRef();
  // const showSwalWithLink = () => {
  //   MySwal.fire({
  //     icon: "error",
  //     text: "All Fields Are Mandatory",
  //   });
  // };

  useImperativeHandle(ref4, () => ({
    log() {
      handleOpen();
    },
  }));

  //   if(openM){
  //     handleOpen();
  //   }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="parent-modal"
      >
        <Box sx={{ ...style }} className="profile-modal">
          <Grid container>
            <Grid item xs={12} className="profile-header p-15">
              <Typography id="parent-modal-title" component="h4">
                DISCOUNT
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <Divider></Divider>

          <Grid container className="details-div p-15" spacing={3}>
            <Grid item xs={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Name</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="location-select"
                  onChange={(e) => {
                    handlePromoCode(e.target.value);
                  }}
                  // input={<OutlinedInput />}
                  defaultValue=""
                >
                  {props.specialDiscountNames?.map((item) => {
                    return (
                      <MenuItem value={item.special_discount_id}>
                        {item.type_of_discount}
                      </MenuItem>
                    );
                  })}
                  {/* <MenuItem value="locations">Manager Discount</MenuItem>
                                    <MenuItem>Coupon Code</MenuItem>
                                    <MenuItem>Scratch Card</MenuItem>
                                    <MenuItem>Daily Discount</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">Discount Type</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="location-select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="Flat Rate"
                >
                  <MenuItem value="Flat Rate">Flat Rate</MenuItem>
                  <MenuItem value="Percentage">Percentage</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl className="w-100">
                <Typography component="label">{promoCodeName}</Typography>
                <OutlinedInput
                  placeholder="Percentage"
                  onChange={(e) => setPromoValue(e.target.value)}
                  onBlur={(e) => setPromoValue(e.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              <FormControl className="w-100">
                <Typography component="label">Description</Typography>
                <OutlinedInput
                  placeholder=""
                  components="textarea"
                  minRows={5}
                  multiline={true}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Divider></Divider>
          <Box className="p-15 text-right">
            {/* <Button
              variant="outlined"
              color="error"
              className="retail-btn mr-15"
              onClick={handleClose}
            >
              Remove
            </Button> */}
            <Button
              variant="outlined"
              color="error"
              className="retail-btn mr-15"
              onClick={()=>{posDiscountValidate()}}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
      <PromoDiscountModal ref={ref1} promoCode={promoCode} />
    </div>
  );
});

export default DiscountModal;
