import React, { useState, useEffect } from "react";
import {
  Box,
Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import LocationModal from "./LocationModal";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import api from "../Api";
import * as Yup from "yup";
import { RegisterSchema } from "../Validation";

export default function AddMerchant(props) {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
 const [countryId,setCountryId] = React.useState(91);
const [cities,setCities] = React.useState();
  const [value, setValue] = React.useState(0);
//   const cities = useSelector((state) => state.user.cities);
  const response = useSelector((state) => state.user.user);
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref4 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const [openTime, setOpenTime] = React.useState();
  const [closeTime, setCloseTime] = React.useState();
  const [openTime2, setOpenTime2] = React.useState();
  const [closeTime2, setCloseTime2] = React.useState();
  const [addOpen, setAddOpen] = React.useState(false);

  const [closedDays, setClosedDays] = useState([]);

  const handleClosedDays = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setClosedDays((prevClosedDays) => [...prevClosedDays, value]);
    } else {
      setClosedDays((prevClosedDays) =>
        prevClosedDays.filter((day) => day !== value)
      );
    }
  };

  const time = [
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
  ];

  const MySwal = withReactContent(Swal);

  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      props?.onUpdate(true);
      // handleClose();
    });
  };
  const handleSubmit = (values) => {
    //   return false;

    formik.values.closed_days = JSON.stringify([...new Set(closedDays)]);
    console.log("formik.values", formik.values);

    api
      .postData("/api/locations/create_update_locations", formik.values)
      .then((response) => {
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
        }
      });
  };
  const user_menu =
    process.env.REACT_APP_MY_TITLE === "Vikretha"
      ? JSON.stringify([
          {
            submenu: [],
            item_name: "Dashboard",
            key: "Dashboard",
            path_name: "/dashboard",
            number: 0,
          },
          {
            submenu: [],
            item_name: "Orders",
            key: "PurchaseOrders",
            path_name: "/purchaseOrders",
            number: 1,
          },
          {
            submenu: [],
            item_name: "Customers",
            path_name: "/customer/customer",
            key: "Customers",
            number: 2,
          },
          {
            submenu: [
              { item_name: "Add Merchant", path_name: "/account/addMerchant" },
              {
                item_name: "Delivery Fee Engine",
                path_name: "/account/DeliveryFeeEngine",
              },
              {
                item_name: "City Management",
                path_name: "/account/CityManagement",
              },
              {
                item_name: "Service Management",
                path_name: "/admin/serviceManagement",
              },
              { item_name: "App Versions", path_name: "/admin/appVersions" },
              { item_name: "Help Support", path_name: "/admin/helpSupport" },
              { item_name: "User Activity", path_name: "/admin/userActivity" },
            ],
            item_name: "Admin",
            key: "Admin",
            number: 9,
          },
          {
            submenu: [
              { item_name: "Management", path_name: "/employees/management" },
              {
                item_name: "Shift Schedule",
                path_name: "/employees/shiftSchedule",
              },
              { item_name: "Pay Setup", path_name: "/employees/paySetup" },
              { item_name: "Tax & Fees", path_name: "/employees/taxFees" },
            ],
            item_name: "Employee Management",
            key: "EmployeeManagement",
            number: 3,
          },
          {
            submenu: [
              {
                item_name: "Stock Management",
                path_name: "/product/stockManagement",
              },
              { item_name: "Add New Item", path_name: "/product/storeNewItem" },
              { item_name: "Profile", path_name: "/product/profile" },
              { item_name: "Tax & Fees", path_name: "/product/taxFees" },
              { item_name: "Publishing", path_name: "/product/publishing" },
            ],
            item_name: "Product Catalogue",
            key: "ProductCatalogue",
            number: 4,
          },
          {
            submenu: [],
            key: "Pos",
            item_name: "Pos",
            path_name: "/pos",
            number: 5,
          },
          {
            submenu: [],
            item_name: "PhoneOrders",
            key: "PhoneOrders",
            path_name: "/PhoneOrders",
            number: 6,
          },
          {
            submenu: [
              { item_name: "Market Place", path_name: "/Procurement/Bazaar" },
              { item_name: "Purchase Orders", path_name: "/purchaseOrders" },
              { item_name: "Supplier", path_name: "/Procurement/Supplier" },
            ],
            item_name: "Procurement",
            key: "Procurement",
            number: 7,
          },
          {
            submenu: [
              { item_name: "Promotions", path_name: "/Promotions/Promotions" },
              {
                item_name: "Messaging Hub",
                path_name: "/Promotions/MessagingHub",
              },
              { item_name: "Messaging", path_name: "/Promotions/Messaging" },
            ],
            item_name: "Promotions",
            key: "Promotions",
            number: 8,
          },
        ])
      : JSON.stringify([
          {
            submenu: [],
            item_name: "Dashboard",
            key: "Dashboard",
            path_name: "/dashboard",
            number: 0,
          },
          {
            submenu: [],
            item_name: "Orders",
            key: "PurchaseOrders",
            path_name: "/Procurement/PurchaseOrders",
            number: 1,
          },
          {
            submenu: [],
            item_name: "Customers",
            path_name: "/customer/customer",
            key: "Customers",
            number: 2,
          },
          {
            submenu: [
              { item_name: "Add Merchant", path_name: "/account/addMerchant" },
              {
                item_name: "Delivery Fee Engine",
                path_name: "/account/DeliveryFeeEngine",
              },
              {
                item_name: "City Management",
                path_name: "/account/CityManagement",
              },
              {
                item_name: "Service Management",
                path_name: "/admin/serviceManagement",
              },
              { item_name: "App Versions", path_name: "/admin/appVersions" },
              { item_name: "Help Support", path_name: "/admin/helpSupport" },
              { item_name: "User Activity", path_name: "/admin/userActivity" },
            ],
            item_name: "Admin",
            key: "Admin",
            number: 9,
          },
          {
            submenu: [
              { item_name: "Management", path_name: "/employees/management" },
              {
                item_name: "Shift Schedule",
                path_name: "/employees/shiftSchedule",
              },
              { item_name: "Pay Setup", path_name: "/employees/paySetup" },
              { item_name: "Tax & Fees", path_name: "/employees/taxFees" },
            ],
            item_name: "Employee Management",
            key: "EmployeeManagement",
            number: 3,
          },
          {
            submenu: [
              {
                item_name: "Stock Management",
                path_name: "/product/stockManagement",
              },
              { item_name: "Add New Item", path_name: "/product/storeNewItem" },
              { item_name: "Tax & Fees", path_name: "/product/taxFees" },
              { item_name: "Profile", path_name: "/product/profile" },
            ],
            item_name: "Product Catalogue",
            key: "ProductCatalogue",
            number: 4,
          },
          {
            submenu: [],
            key: "Pos",
            item_name: "Pos",
            path_name: "/pos",
            number: 5,
          },
          {
            submenu: [],
            item_name: "PhoneOrders",
            key: "PhoneOrders",
            path_name: "/PhoneOrders",
            number: 6,
          },
          {
            submenu: [
              { item_name: "Market Place", path_name: "/Procurement/Bazaar" },
              {
                item_name: "Purchase Orders",
                path_name: "/Procurement/PurchaseOrders",
              },
              { item_name: "Supplier", path_name: "/Procurement/Supplier" },
            ],
            item_name: "Procurement",
            key: "Procurement",
            number: 7,
          },
          {
            submenu: [
              { item_name: "Promotions", path_name: "/Promotions/Promotions" },
              {
                item_name: "Messaging Hub",
                path_name: "/Promotions/MessagingHub",
              },
              { item_name: "Messaging", path_name: "/Promotions/Messaging" },
            ],
            item_name: "Promotions",
            key: "Promotions",
            number: 8,
          },
        ]);
  const convertTime = (value) => {
    const time24 = value;
    const dateObj = new Date(`2000-01-01T${time24}`);
    const time12 = dateObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return time12;
  };
  const addLocation = Yup.object().shape({
    manager_name: Yup.string().required("managername required"),
    manager_number: Yup.string().required("managername required"),
    business_address: Yup.string().required("Please Enter Business Address"),
    location_name: Yup.string().required("Please Location Name"),
    packing_charges: Yup.number().required("Please Enter Packing Charges"),
    business_city: Yup.string().required("Please Select Business City"),
    business_type: Yup.string().required("Please Select Business Type"),
    location_latitude: Yup.number().required("Please Enter Location Latitude"),
    location_longitude: Yup.number().required(
      "Please Select Location Longitude"
    ),
    locality: Yup.string().required("Please  Expiry Locality"),
    sub_locality: Yup.string().required("Please Enter Sub Locality"),
    location_route: Yup.string().required("Please Enter Location Route"),
    pincode: Yup.number().required("Please Enter pincode"),
    opening_time1: Yup.mixed().required("Please Select Opening Time"),
    closing_time1: Yup.mixed().required("Please Select Closing Time"),
    opening_time2: Yup.mixed().required("Please Select Opening Time2"),
    closing_time2: Yup.mixed().required("Please Select  Closing Time2"),
    delivery_days: Yup.mixed().required("Please Select Delivery Days"),
  });
  const formik = useFormik({
    initialValues: {
      user_name: response?.admin_email,
      merchant_id: "0",
      merchant_business_name: "",
      service_id: "",
      service_prefix: "",
      country_id: "",
      city_id: "",
      admin_email: "",
      phone_number: "",
      business_icon: "/src/images/business.png",
      user_password: "",
      merchant_role: "admin",
      user_menus: user_menu,
      user_action: "User Registered",
      user_action_type: "Success",
      isemail_changed: "0",
      isphone_changed: "0",
      merchant_type: process.env.REACT_APP_MY_TITLE === "Bazaar" ? 1 : 0,
      bank_name: "Not Entered",
      bank_branch_name: "Not Entered",
      ifsc_code: "Not Entered",
      account_number: "Not Entered",
      account_holder_name: "Not Entered",
      paytm_number: "Not Entered",
      google_pay_number: "Not Entered",
      phone_pay_number: "Not Entered",
      other_upi_number: "Not Entered",
      status_1: "0",
      gst_number: "Not Entered",
      gst_percentage: "Not Entered",

      // email: "",
      // phonenumber: "",
      // password: "",
      // confirmpassword: ""
    },
    validationSchema: RegisterSchema,
    onSubmit: handleSubmit,
  });

  const handleData = (event) => {
    console.log("event.target", event.target);
    const { name, value } = event.target;
    // Yup.reach(RegisterSchema, name).validate(value);

    if (name === "service_id") {
      let arr = value.split("-");
      console.log("formik.initialValues", arr[0], arr[1]);
      const updatedValues = {
        ...formik.values,
        ["service_id"]: arr[0],
        ["service_prefix"]: arr[1],
      };
      formik.setValues(updatedValues);
      console.log("formik.initialValues", formik.values);
    } else if (name === "country_id") {
      let arr1 = value.split("-");
      console.log("formik.initialValues", arr1[0], arr1[1]);
      const updatedValues = {
        ...formik.values,
        ["country_id"]: arr1[1],
        ["country_code"]: arr1[0],
      };
      formik.setValues(updatedValues);
      console.log("formik.initialValues", formik.values);
    } else {
      const updatedValues = {
        ...formik.values,
        [name]: value,
      };
      formik.setValues(updatedValues);
    }

    console.log("formik.initialValues", formik.values);
  };
  const fetchData = () => {
    api
      .getData("/api/config/cities_list?countryid=" + countryId)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCities(
            JSON.parse(response.data[0].Cities).filter((item) => {
              return item.city_state === "Andhra Pradesh";
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (props.editData) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        ...props.editData,
      }));
    }
  }, [props.editData]);

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  // useImperativeHandle(ref2, () => ({
  //     log() {
  //         handleOpen();
  //     }
  // }));

  // useImperativeHandle(ref4, () => ({
  //     log() {
  //         handleOpen();
  //     }
  // }));

  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const eiColumns = ["ID", "Location", "Name"];
  const eiData = [
    ["#963258", "Srikakulam", "Ramya"],
    ["#963258", "Srikakulam", "Ramya"],
    ["#856214", "Vizianagaram", "Srilekha"],
  ];
  const mlColumns = [
    "DATE",
    "TYPE",
    "TASK",
    "LIFE OF REPAIR",
    "COST",
    "COMMENTS",
  ];

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: false,
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link>
                    <Link underline="hover" color="inherit" href="#">
                      Account
                    </Link>
                    <Typography color="text.primary">Add Merchant</Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid>
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            ADD MERCHANT
                          </Typography>
                        </Box>
                        <Grid container spacing={2} className="p-15">
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Merchant Name
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="merchant_business_name"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">City</Typography>

                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                value={formik.values?.business_city}
                                name="business_city"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                                disabled
                              >
                                {cities?.map((item) => {
                                  return (
                                    <MenuItem value={item.city_id}>
                                      {item.city_name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Merchant Type
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                name="merchant_business_name"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">
                                  Select Merchant Type
                                </MenuItem>
                                <MenuItem value="Medical">
                                  Medical Store
                                </MenuItem>
                                <MenuItem value="Fancy">Fancy</MenuItem>
                                <MenuItem value="Grocires">Grocires</MenuItem>
                              </Select>
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">Email</Typography>
                              <OutlinedInput
                                value=""
                                name="merchant_business_name"
                                placeholder="Email"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                GST Number
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="merchant_business_name"
                                placeholder="GST Number"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                GST Tax rate
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="merchant_business_name"
                                placeholder="GST Tax rate"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <OutlinedInput
                                value=""
                                name="merchant_business_name"
                                type="file"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Divider></Divider>
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            BANK INFO
                          </Typography>
                        </Box>
                        <Grid container spacing={3} className="p-15">
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Bank Name
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="bank_name"
                                placeholder="Bank Name"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.bank_name &&
                                touched.bank_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.bank_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Bank Branch
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="bank_branch_name"
                                placeholder="Bank Branch"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.bank_branch_name &&
                                touched.bank_branch_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.bank_branch_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                IFSC Code
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="ifsc_code"
                                placeholder="ifsc code"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.ifsc_code &&
                                touched.ifsc_code && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.ifsc_code}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Account Number
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="merchant_business_name"
                                placeholder="0254896856"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Account Holder Name
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="merchant_business_name"
                                placeholder="Account Holder Name"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Payment Terms
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // name="merchant_business_name"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">
                                  Payment Terms
                                </MenuItem>
                                <MenuItem value="visakhapatnam">
                                  Cheque
                                </MenuItem>
                                <MenuItem value="vijayawada">UPI</MenuItem>
                                <MenuItem value="vijayawada">
                                  Debit / Credit Card
                                </MenuItem>
                              </Select>
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Commission Type
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // name="merchant_business_name"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">
                                  Percentage
                                </MenuItem>
                                <MenuItem value="visakhapatnam">5%</MenuItem>
                                <MenuItem value="vijayawada">10%</MenuItem>
                                <MenuItem value="vijayawada">15%</MenuItem>
                              </Select>
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Origination Fee
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="merchant_business_name"
                                placeholder="enter Origination Fee"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.merchant_business_name &&
                                touched.merchant_business_name && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_business_name}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Paytm No
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="paytm_number"
                                placeholder="enter paytm no"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.paytm_number && touched.paytm_number && (
                                <Box
                                  Typography="small"
                                  className="float-left text-danger"
                                >
                                  {errors.paytm_number}
                                </Box>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Google Pay No
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="google_pay_number"
                                placeholder="enter Google Pay No"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.google_pay_number &&
                                touched.google_pay_number && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.google_pay_number}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Phonepe No
                              </Typography>
                              <OutlinedInput
                                value=""
                                name="phone_pay_number"
                                placeholder="enter Phonepe No"
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => {
                                  handleBlur(e);
                                  handleData(e);
                                }}
                              />
                              {errors.phone_pay_number &&
                                touched.phone_pay_number && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.phone_pay_number}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Box className="p-15 text-center">
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={3}
                            className="retail-btn mr-20"
                          >
                            Add Merchant
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={3}
                            className="retail-btn"
                          >
                            Refresh
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            LOCATIONS
                          </Typography>
                          <Box>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn bg-white"
                              onClick={() => ref4.current.log()}
                              mr={2}
                            >
                              Add Locations
                            </Button>
                          </Box>
                        </Box>
                        <Box className="flex p-15 w-100" mt={2}></Box>

                        <Box className=" w-95 p-15 member-table">
                          <MemberTable
                            columns={eiColumns}
                            options={options}
                            data={eiData}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <LocationModal ref={ref4} />
      </Box>
    </>
  );
}
