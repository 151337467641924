import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import api from "./Api";
import { RotatingLines } from 'react-loader-spinner';
import { useFormik } from "formik";
import { RegisterSchema } from './Validation';
import { Phone } from "@mui/icons-material";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as Yup from 'yup';
import { Field } from "formik";
import { json, useNavigate } from "react-router-dom";
import VerificationScreen from "./VerificationScreen";
import Swal from 'sweetalert2';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const user_menu = (process.env.REACT_APP_MY_TITLE === 'Vikretha')? JSON.stringify([{ 'submenu': [],  "item_name": 'Dashboard',"key":"Dashboard", 'path_name': '/dashboard', "number": 0 },
{ 'submenu': [], "item_name": 'Orders',"key":"PurchaseOrders", 'path_name': '/purchaseOrders', "number": 1 },
 { 'submenu': [], "item_name": 'Customers', 'path_name': '/customer/customer',"key":"Customers", "number": 2 },
 { 'submenu': [{"item_name": 'Add Merchant',"path_name":"/account/addMerchant"}, 
 {"item_name": 'Delivery Fee Engine',"path_name":"/account/DeliveryFeeEngine"}, 
 {"item_name": 'City Management',"path_name":"/account/CityManagement"},
 {"item_name": 'Service Management',"path_name":"/admin/serviceManagement"},
 {"item_name": 'App Versions',"path_name":"/admin/appVersions"},
 {"item_name": 'Help Support',"path_name":"/admin/helpSupport"},
 {"item_name": 'User Activity',"path_name":"/admin/userActivity"}], 
 "item_name": "Admin","key":"Admin", "number": 9 },
 { 'submenu': [{"item_name": 'Management',"path_name":"/employees/management"}, 
 {"item_name": 'Shift Schedule',"path_name":"/employees/shiftSchedule"}, 
 {"item_name": 'Pay Setup',"path_name":"/employees/paySetup"},
 {"item_name": 'Tax & Fees',"path_name":"/employees/taxFees"}
], "item_name": 'Employee Management',"key":"EmployeeManagement", "number": 3 },
 { 'submenu': [{"item_name": 'Stock Management',"path_name":"/product/stockManagement"},
 {"item_name": 'Add New Item',"path_name":"/product/storeNewItem"}, 
 {"item_name": 'Profile',"path_name":"/product/profile"},
 {"item_name": 'Tax & Fees',"path_name":"/product/taxFees"},
 {"item_name": 'Publishing',"path_name":"/product/publishing"}], 
 "item_name": "Product Catalogue","key":"ProductCatalogue", "number": 4 },
 { 'submenu': [],'key': 'Pos', "item_name": 'Pos', 'path_name': '/pos', "number": 5 },
 { 'submenu': [],"item_name": 'PhoneOrders',"key":"PhoneOrders", 'path_name': '/PhoneOrders', "number": 6 },
 { 'submenu': [{"item_name": 'Market Place',"path_name":"/Procurement/Bazaar"}, 
 {"item_name": 'Purchase Orders',"path_name":"/purchaseOrders"},
 {"item_name": 'Supplier',"path_name":"/Procurement/Supplier"}],  
 "item_name": "Procurement","key":"Procurement", "number": 7},
 { 'submenu': [{"item_name": 'Promotions',"path_name":"/Promotions/Promotions"}, 
 {"item_name": 'Messaging Hub',"path_name":"/Promotions/MessagingHub"}, 
 {"item_name": 'Messaging',"path_name":"/Promotions/Messaging"}], 
 "item_name": "Promotions","key":"Promotions", "number": 8 },
 
]) : JSON.stringify([{ 'submenu': [],  "item_name": 'Dashboard',"key":"Dashboard", 'path_name': '/dashboard', "number": 0 },
{ 'submenu': [], "item_name": 'Orders',"key":"PurchaseOrders", 'path_name': '/Procurement/PurchaseOrders', "number": 1 },
 { 'submenu': [], "item_name": 'Customers', 'path_name': '/customer/customer',"key":"Customers", "number": 2 },
 { 'submenu': [{"item_name": 'Add Merchant',"path_name":"/account/addMerchant"}, 
 {"item_name": 'Delivery Fee Engine',"path_name":"/account/DeliveryFeeEngine"}, 
 {"item_name": 'City Management',"path_name":"/account/CityManagement"},
 {"item_name": 'Service Management',"path_name":"/admin/serviceManagement"},
 {"item_name": 'App Versions',"path_name":"/admin/appVersions"},
 {"item_name": 'Help Support',"path_name":"/admin/helpSupport"},
 {"item_name": 'User Activity',"path_name":"/admin/userActivity"}], 
 "item_name": "Admin","key":"Admin", "number": 9 },
 { 'submenu': [{"item_name": 'Management',"path_name":"/employees/management"}, 
 {"item_name": 'Shift Schedule',"path_name":"/employees/shiftSchedule"}, 
 {"item_name": 'Pay Setup',"path_name":"/employees/paySetup"},
 {"item_name": 'Tax & Fees',"path_name":"/employees/taxFees"}
], "item_name": 'Employee Management',"key":"EmployeeManagement", "number": 3 },
 { 'submenu': [{"item_name": 'Stock Management',"path_name":"/product/stockManagement"},
 {"item_name": 'Add New Item',"path_name":"/product/storeNewItem"}, 
 {"item_name": 'Tax & Fees',"path_name":"/product/taxFees"},
 {"item_name": 'Profile',"path_name":"/product/profile"}], 
 "item_name": "Product Catalogue","key":"ProductCatalogue", "number": 4 },
 { 'submenu': [],'key': 'Pos', "item_name": 'Pos', 'path_name': '/pos', "number": 5 },
 { 'submenu': [],"item_name": 'PhoneOrders',"key":"PhoneOrders", 'path_name': '/PhoneOrders', "number": 6 },
 { 'submenu': [{"item_name": 'Market Place',"path_name":"/Procurement/Bazaar"}, 
 {"item_name": 'Purchase Orders',"path_name":"/Procurement/PurchaseOrders"},
 {"item_name": 'Supplier',"path_name":"/Procurement/Supplier"}],  
 "item_name": "Procurement","key":"Procurement", "number": 7},
 { 'submenu': [{"item_name": 'Promotions',"path_name":"/Promotions/Promotions"}, 
 {"item_name": 'Messaging Hub',"path_name":"/Promotions/MessagingHub"}, 
 {"item_name": 'Messaging',"path_name":"/Promotions/Messaging"}], 
 "item_name": "Promotions","key":"Promotions", "number": 8 },
 
]);
export default function Register() {

  const [cities, setCities] = React.useState();
  const [country, setCountry] = React.useState();
  const [countryCodeId, setCountryCodeId] = React.useState();
  const [countryId, setCountryId] = React.useState();
  const [services, setServices] = React.useState();
  const [message, setMessage] = React.useState();
  const [phoneStatus, setPhoneStatus] = React.useState();
  const [phone, setPhone] = React.useState();
  const [spineer, setSpinner] = React.useState(true);
  const [loaderValue,SetLoaderValue] = React.useState(false);
  const [emailLoaderValue,SetEmailLoaderValue] = React.useState(false);

  const handleSubmit = (values) => {
    console.log('formik.initialValues', formik.values)
    if (formik.values.phone_number && formik.values.phone_number.length === 10) {
      formik.values.phone_number = countryCodeId + formik.values.phone_number;
    }
    delete formik.values["confirmpassword"];
    api.postData('/api/merchant/register', formik.values).then((response) => {
      console.log('response.data[0].Status', response.data);
      if (response.data.Status) {
    
        setMessage(response.data.Message);
        setSpinner(false);
        console.log('JSON.parse(response.data[0].Status)', typeof response.data.Status);
        if (response.data.Status === "200" && response.data.IsPhoneVerifyRequired === 'Yes') {
       
          navigate('/verifyMobile', {
            state: {
              phone_number: formik.values.phone_number,
              email: formik.values.admin_email,
              verifyEmail: response.data.IsEmailVerfyRequired
            }
          });
        }else if(response.data.Status === "200" && response.data.IsPhoneVerifyRequired === 'No' && response.data.IsEmailVerfyRequired === 'No'){
          navigate('/');
        }
      } else {

      }
    })
  };
  const navigate = useNavigate();

  const [logo,setLogo] = React.useState();
  React.useEffect(() => {
    // Update favicon based on condition
    if (process.env.REACT_APP_MY_TITLE === 'Bazaar') {
      setLogo(process.env.REACT_APP_MY_LOGO_URL);
    } else if(process.env.REACT_APP_MY_TITLE === 'Vikretha'){
      setLogo(process.env.REACT_APP_MY_LOGO_URL_VIKRETA);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      'user_name': "",
      'merchant_id': "0",
      'merchant_business_name': "",
      'service_id': "",
      'service_prefix': "",
      'country_id': "",
      'city_id': "",
      'admin_email': "",
      'phone_number': "",
      'business_icon': "/src/images/business.png",
      'user_password': "",
      'merchant_role': "admin",
      'user_menus': user_menu,
      'user_action': "User Registered",
      'user_action_type': "Success",
      'isemail_changed': "0",
      'isphone_changed': "0",
      "merchant_type": process.env.REACT_APP_MY_TITLE === 'Bazaar' ? 1 : 0,
      'bank_name': 'Not Entered',
      'bank_branch_name': 'Not Entered',
      'ifsc_code': 'Not Entered',
      'account_number': 'Not Entered',
      'account_holder_name': 'Not Entered',
      'paytm_number': 'Not Entered',
      'google_pay_number': 'Not Entered',
      'phone_pay_number': 'Not Entered',
      'other_upi_number': 'Not Entered',
      'status_1': '0',
      'gst_number': 'Not Entered',
      'gst_percentage': 'Not Entered'


      // email: "",
      // phonenumber: "",
      // password: "",
      // confirmpassword: ""
    },
    validationSchema: RegisterSchema,
    onSubmit: handleSubmit,
  });




  const handleData = (event) => {

    console.log('event.target', event.target);
    const { name, value } = event.target;
    // Yup.reach(RegisterSchema, name).validate(value);


    if (name === 'service_id') {
      let arr = value.split("-");
      console.log('formik.initialValues', arr[0], arr[1])
      const updatedValues = {
        ...formik.values,
        ['service_id']: arr[0], ['service_prefix']: arr[1]
      }
      formik.setValues(updatedValues);
      console.log('formik.initialValues', formik.values)
    } else if (name === 'country_id') {
      let arr1 = value.split("-");
      console.log('formik.initialValues', arr1[0], arr1[1])
      const updatedValues = {
        ...formik.values,
        ['country_id']: arr1[1], ['country_code']: arr1[0]
      }
      formik.setValues(updatedValues);
      console.log('formik.initialValues', formik.values)
    } else {


      const updatedValues = {
        ...formik.values,
        [name]: value,
      };
      formik.setValues(updatedValues);
    }



    console.log('formik.initialValues', formik.values)
  };



  const countryCode = (e) => {
    console.log(e)
    setPhone(e);
  }
  const fetchData = () => {
    api.getData('/api/config/cities_list?countryid=' + countryId)
      .then((response) => {

        if (response.data[0]?.Status === "200") {

          setCities(JSON.parse(response.data[0].Cities).filter((item) => {
            return item.city_state === 'Andhra Pradesh'
          }));
          setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  const fetchCountries = () => {

    api.getData('/api/config/countries_list')
      .then((response) => {

        if (JSON.parse(response.data[0].Status) === 200) {

          setCountry(JSON.parse(response.data[0].Countries));
          setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  const selectCountry = (e) => {
    let arr = e.target.value.split("-");
    console.log('formik.initialValues', arr[0], arr[1])
    console.log(arr[0])
    console.log(arr[1])
    setCountryCodeId(arr[0]);
    setCountryId(arr[1]);
    // console.log(e.target);
  }
  const fetchServices = () => {
    console.log('getData')
    api.getData('/api/config/services_list')
      .then((response) => {
        // setResponseData(response.data)
        console.log('response', JSON.parse(response.data[0].Services));
        if (JSON.parse(response.data[0].Status) === 200) {

          setServices(JSON.parse(response.data[0].Services));
          setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
  } = formik;

  const checkEmail = (data) => {
    setSpinner(true);

    console.log('getData');
    api.postData('/api/config/check_email', { 'emailid': data }).then((response) => {
      if (JSON.parse(response.data[0].Status)) {
        setMessage(JSON.parse(response.data[0].Status));
        setSpinner(false);
      } else {

      }
    })
  }



  const checkPhoneNumber = (data) => {
    setSpinner(true);
    api.postData('/api/config/check_phone_number', { 'phone_number': countryCodeId + data }).then((response) => {
      if (JSON.parse(response.data[0].Status)) {

        setPhoneStatus(JSON.parse(response.data[0].Status));
        setSpinner(false);
      } else {

      }
    })
  }

  const checkEmails = (e) => {
    console.log('12');
    // handleBlur();


    if (errors.email) {

      setMessage(true);
    } else {
      if (e.target.value) {
        if (e.target.name === 'admin_email') {

          const updatedValues = {
            ...formik.values,
            ['admin_email']: e.target.value, ['user_name']: e.target.value
          }
          console.log('updatedValues', updatedValues);
          formik.setValues(updatedValues);
          console.log('formik.initialValues', formik.values)
        }
        // alert(/\S+@\S+\.\S+/.test(e.target.value));
        if(/\S+@\S+\.\S+/.test(e.target.value)){
        checkEmail(e.target.value);
        }
      }
    }
  }
  // const handleChange = (e) =>{
  //   console.log(e.target.value);
  // }
  const checkPhone = (e) => {
    
    // handleBlur();


    if (errors.phone_number) {

      setMessage(true);
    } else {
      if (e.target.value) {
        checkPhoneNumber(e.target.value);
      }
    }
  }
  // const validateEmail = (email) => {
  //   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  //   return emailRegex.test(email);
  // };

  React.useEffect(() => {

    fetchServices();
    fetchCountries();
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [countryId]);

  const handleMail = ()=>{
    setMessage('');
  }

  const handlePhone = ()=>{
    setMessage('');
  }


  React.useEffect(() => {

  }, [spineer]);

  React.useEffect(() => {

  }, [message]);

  const handleKeyDown = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    event.target.value = inputValue;
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <div className="Absolute-Box text-center">

          <Box sx={{ minWidth: 275, paddingTop: 3 }}>
            <Card variant="outlined">
              <Box component='img' src={logo} className="App-logo" alt="logo" mt={3} />
              <CardContent className="card-content text-center register">
                <Typography
                   className="clr-primary mt-3 fw-bold"
                   variant="h5"
                   gutterBottom
                >
                  Register
                </Typography>
                <div className="">
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl className="w-100" variant="standard">
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <FormControl className="w-100" size="small">
                            <Typography component='label' className="float-left">Name of Bussines</Typography>

                            <BootstrapInput
                              fullWidth
                               id="username-input"
                              placeholder="Bussiness Name"
                              name="merchant_business_name"
                              value={formik.values.merchant_business_name}
                              onChange={handleChange}
                              onBlur={(e) => { handleBlur(e); handleData(e) }}
                            />
                            {errors.merchant_business_name && touched.merchant_business_name && <Box Typography="small" className="float-left text-danger">{errors.merchant_business_name}</Box>}
                          </FormControl>


                        </Grid>
                      </Grid>
                    </FormControl>
                    <Grid container spacing={1} className="mt-5">
                      <Grid item xs={12} className="text-start">
                        <FormControl className="w-100" size="small">
                          <Typography component='label' className="float-left">Select Country</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select select-equipment"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            name="country_id"

                            onChange={(e) => { selectCountry(e); handleData(e) }}
                            onBlur={(e) => { handleBlur(e) }}
                          >
                            {
                              country?.map((item) => {

                                return <MenuItem value={item?.country_code + '-' + item?.country_id}><Box className="flex"><Typography component="span" ml={1}>{item?.country_name}</Typography> <Typography component="span" ml={1}>{item?.country_currency_symbol}</Typography></Box></MenuItem>


                              })
                            }
                          </Select>
                          {errors.country_id && touched.country_id && <Box Typography="small" className="float-left text-danger">{errors.country_id}</Box>}

                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="mt-5">
                      <Grid item xs={6} className="text-start">
                        <FormControl className="w-100" size="small">
                          <Typography component='label' className="float-left">Primary Email</Typography>

                          <BootstrapInput fullWidth
                            name="admin_email"
                            placeholder="Email"
                            onChange={(e) => { handleChange(e); handleMail()}}

                            onBlur={(e) => { handleBlur(e); checkEmails(e) }}
                          />
                          {errors.admin_email && touched.admin_email && <Box Typography="small" className="float-left text-danger small-spinner">{errors.admin_email} </Box>}
                          {message === 500 ? <Box Typography="small" className="float-left text-danger small-spinner">Email Id already exists, Cannot Continue...!!! <RotatingLines
                            strokeColor="#9568FF"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={emailLoaderValue}
                          /></Box> : ""}
                          {message === 200 ? <Box Typography="small" className="float-left text-success">Valid Email Id....!!!</Box> : ""}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} className="text-start flex">

                        <FormControl className="w-100" size="small">

                          <Typography ography component='label' className="float-left">Phone Number</Typography>
                          <Box className="flex">
                            <PhoneInput
                              country={'ind'}
                              name=""
                              value={countryCodeId ? countryCodeId : '91'}
                              className="country-code"
                              onChange={(e) => countryCode(e)}
                            />
                            <BootstrapInput
                              id="phonenumber-input"
                              name="phone_number"
                              onKeyDown={(e)=>{handleKeyDown(e);handlePhone()}}
                              className="phone-number"
                              onChange={(e)=>handleChange(e)}
                              onBlur={(e) => { checkPhone(e);handleBlur(e);  }}
                              
                            />
                          </Box>

                          {errors.phone_number && touched.phone_number && <Box Typography="small" className="float-left text-danger small-spinner">{errors.phone_number}
                          </Box>}
                          {phoneStatus === 500 ? <Box Typography="small" className="float-left text-danger small-spinner">Phone Number already exists, Cannot Continue...!!! <RotatingLines
                            strokeColor="#9568FF"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={loaderValue}
                          /></Box> : ""}
                          {phoneStatus === 200 ? <Box Typography="small" className="float-left text-success">Valid Phone Number Id....!!!</Box> : ""}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="mt-5">
                      <Grid item md={6} className="text-start text-left">
                        <FormControl className="w-100" size="small">
                          <Typography component='label' className="float-left">Service Type</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select select-equipment"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            name="service_id"

                            onChange={handleData}
                            onBlur={(e) => { handleBlur(e) }}
                          >
                            {
                              services?.map((item) => {

                                return <MenuItem value={item.service_id + '-' + item.service_prefix}>{item.service_name}</MenuItem>


                              })
                            }
                          </Select>
                          {errors.service_id && touched.service_id && <Box Typography="small" className="float-left text-danger">{errors.service_id}</Box>}

                        </FormControl>
                      </Grid>
                      <Grid item md={6} className="text-start text-left" mb={1}>
                        <FormControl className="w-100" size="small">
                          <Typography component='label' className="float-left">City</Typography>
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select select-equipment"
                            // onChange={handleSelectOxygen}
                            // input={<OutlinedInput />}
                            name="city_id"
                            onChange={handleChange}
                            onBlur={(e) => { handleBlur(e) }}

                          >
                            {
                              cities?.map((item) => {

                                return <MenuItem value={item.city_id}>{item.city_name}</MenuItem>


                              })
                            }
                          </Select>
                          {errors.city_id && touched.city_id && <Box Typography="small" className="float-left text-danger">{errors.city_id}</Box>}

                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className="mt-5">
                      <Grid item xs={6} className="text-start">
                        <FormControl className="w-100" size="small">
                          <Typography component='label' className="float-left">Password</Typography>

                          <BootstrapInput fullWidth id="username-input"
                            onChange={handleChange}
                            name="user_password"
                            type="password"
                            placeholder="*********"
                            onBlur={(e) => { handleBlur(e); handleData(e) }}
                          />
                          {errors.user_password && touched.user_password && <Box Typography="small" className="float-left text-danger">{errors.user_password}</Box>}

                        </FormControl>
                      </Grid>
                      <Grid item xs={6} className="text-start">
                        <FormControl className="w-100" size="small">
                          <Typography component='label' className="float-left">Confirm Password</Typography>

                          <BootstrapInput fullWidth
                            id="username-input"
                            name="confirmpassword"
                            type="password"
                            onChange={handleChange}
                            onBlur={(e) => { handleBlur(e) }}
                            placeholder="**********" />
                          {errors.confirmpassword && touched.confirmpassword && <Box Typography="small" className="float-left text-danger">{errors.confirmpassword}</Box>}

                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* <BootstrapInput component="input" variant="contained" className="mt-3 w-50 btn-submit py-2 mx-0" value="Register" type="submit">
                      Register
                    </BootstrapInput> */}
                    <Button
            variant="outlined"
            className="login-btn btn-primery _btn-outline py-2 mx-0 mt-3"
            value="Register" type="submit"
          >
            Register
          </Button>
                    <div className="mt-3">
                      <Typography variant="body2" gutterBottom>
                        Already have an account ?{" "}
                        <Button component='a' className="btn btn-outline-primary retail-btn" href='/'>Login</Button>
                      </Typography>
                    </div>
                    <div className="mt-3">
                      <Typography className="" variant="body2" gutterBottom>
                        Still facing issue ? <Box component='br' />Contact{" "}
                        <a href="tel:1234567890" className="frgt-pswrd">
                          {" "}
                          +91-1234567890
                        </a>{" "}
                        or Email{" "}
                        <a href="mailto:noreply@intelmor.com" className="frgt-pswrd">
                          {" "}
                          noreply@intelmor.com
                        </a>
                      </Typography>
                    </div>
                  </form>
                </div>
              </CardContent>
              <RotatingLines
                strokeColor="#9568FF"
                strokeWidth="5"
                animationDuration="0.75"
                width="50"
                visible={spineer}
                className="spineer"
              />
            </Card>
          </Box>
        </div>
      </Container>
    </React.Fragment>
  );
}

// export default App;
