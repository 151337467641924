import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  FormControlLabel,
  Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import SearchBar from "material-ui-search-bar";
import timer from "../images/timer.png";
import MemberTable from "../Customer/MemberTable";
import OrderOpenModal from "./OrderOpenModal";
import OrderAssignModal from "./OrderAssignModal";
import StatusUpdateModal from "./StatusUpdateModal";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
// const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function StationScreen() {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);
  const ItemColumns = [
    "S.NO",
    "SKU ID",
    "ITEM NAME & DETAILS",
    "QTY",
    "PRICE",
    "TOTAL",
    "",
  ];

  const customerColumns = ["Customer ID", "Customer Name", "Email", "Phone"];

  const customerData = [
    ["#258974", "Akhil", "akhil.@gmail.com", "9478982317"],
    ["#258974", "Suresh", "suresh.@gmail.com", "8327641236"],
  ];

  const billColumns = [
    "S.NO",
    "Invoice Id",
    "Date/Time",
    "Bill Amount",
    "Payment Mode",
  ];

  const billData = [
    [
      1,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
    [
      2,
      <Box component="a" href="#" onClick={() => ref9.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
  ];

  const itemData = [
    [1, "#98759", "Rice", 2, "$ 200", "$400"],
    [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
  ];

  const orderColumns = ["ORDER ID", "CITY", "DATE", "CUSTOMER", "STATUS"];

  const orderData = [
    [
      "#258974",
      "Visakhapatnam",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "Completed",
    ],
    [
      "#258974",
      "Rajahmundry",
      "02 Feb 2021 03:16 PM",
      "naveen patnaik",
      "In Progress",
    ],
  ];

  const driverData = [];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const pcData = [
    [
      <Typography component="p" className="item-name-table-data">
        Veg Spring Rolls and Rolls
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Button
        className="btn-assign-table-data"
        onClick={() => ref2.current.log()}
      >
        Assign
      </Button>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Garlic French Fries
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Preparing
      </Typography>,
      <Button
        className="btn-assign-table-data"
        onClick={() => ref3.current.log()}
      >
        Update
      </Button>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Sweet Potato Chips
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Prepared
      </Typography>,
      <Button
        className="btn-assign-table-data"
        onClick={() => ref3.current.log()}
      >
        Update
      </Button>,
    ],
  ];

  const pcData1 = [
    [
      <Typography component="p" className="item-name-table-data">
        Onion Rings
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Assigned
      </Typography>,
      // <Typography component="p" className="item-status-table-data">
      //   Christopher
      // </Typography>,
      <Button
        className="btn-assign-table-data"
        onClick={() => ref3.current.log()}
      >
        Update
      </Button>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Veggie Chillie Cheese Nachos
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Preparing
      </Typography>,
      <Button className="btn-assign-table-data">Update</Button>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Chicken Shawarma
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Delivered
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        -
      </Typography>,
    ],
  ];

  const pcData2 = [
    [
      <Typography component="p" className="item-name-table-data">
        Chicken Tacos
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Delivered
      </Typography>,
      <Typography component="p" className="item-name-table-data">
        -
      </Typography>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Parmesan Chicken
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Preparing
      </Typography>,
      <Button
        className="btn-assign-table-data"
        onClick={() => ref3.current.log()}
      >
        Update
      </Button>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Grilled Salmon
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Assigned
      </Typography>,
      <Button
        className="btn-assign-table-data"
        onClick={() => ref3.current.log()}
      >
        Update
      </Button>,
    ],
  ];

  const pcData3 = [
    [
      <Typography component="p" className="item-name-table-data">
        BBQ Turkey
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Button
        className="btn-assign-table-data"
        onClick={() => ref2.current.log()}
      >
        Assign
      </Button>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Fried Cat Fish
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Button className="btn-assign-table-data">Assign</Button>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Lobster
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Button className="btn-assign-table-data">Assign</Button>,
    ],
  ];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const pcColumns = [
    { name: "Item Name", options: { filterOptions: { fullWidth: true } } },
    "QTY",
    "Status",
    "Action",
  ];

  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const [value, setValue] = React.useState(1);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [personName, setPersonName] = useState([]);

  const handleChangeDropdown = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Box component="main" className="main-section kot-screens">
        <Grid container>
          <Grid item xs="12">
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  {/* <Box src={logo} className="main-logo" component="img" /> */}
                  <Typography component="p" className="kot-logo">
                    KOT Automation |{" "}
                    <span className="screen-name">Kitchen Manager</span>
                  </Typography>

                  <Box className="icons bredcum-icons">
                    <Button variant="filled" className="invite-supplier-btn">
                      Dashboard
                    </Button>
                    <Button variant="filled" className="invite-supplier-btn">
                      Kitchen Management
                    </Button>
                    <Button variant="filled" className="invite-supplier-btn">
                      Signout
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box className="sku-unit-padding">
              <Grid container spacing={2}>
                <Grid item md={3} className="min-width-responsive">
                  <Box className="sku-list-card">
                    <Box className="sku-list-block">
                      <Typography component="p" className="skublock-heading">
                        Queue
                      </Typography>
                      <Box className="auto-live-block">
                        <Typography className="auto-live-label">
                          Auto Assign
                        </Typography>
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} />}
                        />
                      </Box>
                    </Box>
                    <a
                      className="text-decor"
                      onClick={() => ref1.current.log()}
                    >
                      <Box className="sku-card sku-success">
                        <Box>
                          <Typography component="p" className="sku-person-name">
                            Ravi Kiran
                          </Typography>
                          <Typography component="p" className="kot-sku-id">
                            #536789
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component="p"
                            className="sku-arrived-time"
                          >
                            15:30
                          </Typography>
                        </Box>
                      </Box>
                    </a>
                    <Box className="sku-card sku-success">
                      <Box>
                        <Typography component="p" className="sku-person-name">
                          Chaitanya
                        </Typography>
                        <Typography component="p" className="kot-sku-id">
                          #837793
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="p" className="sku-arrived-time">
                          16:00
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="sku-card sku-success">
                      <Box>
                        <Typography component="p" className="sku-person-name">
                          Ravali
                        </Typography>
                        <Typography component="p" className="kot-sku-id">
                          #534599
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="p" className="sku-arrived-time">
                          20:00
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="sku-card sku-danger">
                      <Box>
                        <Typography component="p" className="sku-person-name">
                          Vaishnavi
                        </Typography>
                        <Typography component="p" className="kot-sku-id">
                          #837790
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="p" className="sku-arrived-time">
                          32:00
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="sku-card sku-danger">
                      <Box>
                        <Typography component="p" className="sku-person-name">
                          Vivek
                        </Typography>
                        <Typography component="p" className="kot-sku-id">
                          #982394
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="p" className="sku-arrived-time">
                          39:00
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="sku-card sku-danger">
                      <Box>
                        <Typography component="p" className="sku-person-name">
                          Bennerji
                        </Typography>
                        <Typography component="p" className="kot-sku-id">
                          #699595
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="p" className="sku-arrived-time">
                          40:30
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="sku-card sku-inprogress">
                      <Box>
                        <Typography component="p" className="sku-person-name">
                          Hema Sundar
                        </Typography>
                        <Typography component="p" className="kot-sku-id">
                          #176439
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="p" className="sku-arrived-time">
                          23:30
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="sku-card sku-inprogress">
                      <Box>
                        <Typography component="p" className="sku-person-name">
                          Chakravarthy
                        </Typography>
                        <Typography component="p" className="kot-sku-id">
                          #699595
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="p" className="sku-arrived-time">
                          28:32
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="sku-card sku-inprogress">
                      <Box>
                        <Typography component="p" className="sku-person-name">
                          Sanjay
                        </Typography>
                        <Typography component="p" className="kot-sku-id">
                          #548973
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component="p" className="sku-arrived-time">
                          30:00
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={9} className="sku-col-padding">
                  <Grid container spacing={2} pt={3}>
                    <Grid item md={6}>
                      <Typography className="orders-heading">
                        Order Details
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <SearchBar className="search-bar arrangement" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1} className="">
                    <Grid item md={6} className="col-adjustment">
                      <Box className="order-card">
                        <Box className="particular-sku-id">
                          <Box>
                            <Typography
                              component="p"
                              className="sku-item-person-name"
                            >
                              Ravikiran
                            </Typography>
                            <Typography
                              component="p"
                              className="sku-item-person-id"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="timer-block success">
                            <Box component="img" src={timer}></Box>
                            <Typography component="p">15:30</Typography>
                          </Box>
                        </Box>
                        <Box marginTop={1} className=" new">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={6} className="col-adjustment">
                      <Box className="order-card">
                        <Box className="particular-sku-id">
                          <Box>
                            <Typography
                              component="p"
                              className="sku-item-person-name"
                            >
                              Vaishnavi
                            </Typography>
                            <Typography
                              component="p"
                              className="sku-item-person-id"
                            >
                              #699590
                            </Typography>
                          </Box>
                          <Box className="timer-block danger">
                            <Box component="img" src={timer}></Box>
                            <Typography component="p">32:00</Typography>
                          </Box>
                        </Box>
                        <Box marginTop={1} className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData1}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={6} className="col-adjustment">
                      <Box className="order-card">
                        <Box className="particular-sku-id">
                          <Box>
                            <Typography
                              component="p"
                              className="sku-item-person-name"
                            >
                              Vivek
                            </Typography>
                            <Typography
                              component="p"
                              className="sku-item-person-id"
                            >
                              #699585
                            </Typography>
                          </Box>
                          <Box className="timer-block danger">
                            <Box component="img" src={timer}></Box>
                            <Typography component="p">39:00</Typography>
                          </Box>
                        </Box>
                        <Box marginTop={1} className="live">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData2}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={6} className="col-adjustment">
                      <Box className="order-card">
                        <Box className="particular-sku-id">
                          <Box>
                            <Typography
                              component="p"
                              className="sku-item-person-name"
                            >
                              Hema Sundar
                            </Typography>
                            <Typography
                              component="p"
                              className="sku-item-person-id"
                            >
                              #699583
                            </Typography>
                          </Box>
                          <Box className="timer-block">
                            <Box component="img" src={timer}></Box>
                            <Typography component="p">23:30</Typography>
                          </Box>
                        </Box>
                        <Box
                          marginTop={1}
                          className="customized-table-block live1"
                        >
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData3}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <OrderOpenModal ref={ref1} />
        <OrderAssignModal ref={ref2} />
        <StatusUpdateModal ref={ref3} />
      </Box>
    </>
  );
}
