import logo from "./bazar-logo.svg";
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Grid } from "@mui/material";
import { useSearchParams } from 'react-router-dom';
import api from "./Api";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));

export default function VerificationSuccess() {
  const [searchParams] = useSearchParams();
  const [message,setMessage] = React.useState();
  const [status,setStatus] = React.useState();
  const navigate = useNavigate();
  const location = useLocation();

  const checkEmail = (values) => {
    api.postData('/api/merchant/verify_email', {email_id:searchParams.get('email'),otp:searchParams.get('otp')}).then((response) => {
      console.log('response.data[0].Status',response.data[0].Status);
      
        console.log('JSON.parse(response.data[0].Status)',JSON.parse(response.data[0].Status));
        if(response.data[0].Status === '200'){
          setStatus(200);
          setMessage(response.data[0].Message);
          setTimeout(()=>{
            navigate('/');
          },1000)
          
        }
       else {
        console.log('message');
        setStatus(500);
        setMessage(response.data[0].Message);
        setTimeout(()=>{
          navigate('/');
        },2000)
      }
    })
  };
  const ResentOtp = () =>{
    api.postData('/api/merchant/resend_email', {'email_id':location.state.email}).then((response) => {
      console.log('response.data[0].Status',response.data[0].Status);
      
        console.log('response',response);
    })
  }
  React.useEffect(()=>{
    checkEmail();
  },[])
  console.log('location',searchParams.get('email'));
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm" className="vh-100">
        <Box className="Absolute-Box text-center">
          <Box sx={{ minWidth: 275, paddingTop: 3 }}>
            <Card variant="outlined">
              <Box
                src={logo}
                className="App-logo"
                alt="logo"
                mt={3}
                component="img"
              />
              <CardContent className="card-content text-center">
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Box className="alert-bg">
                      <Typography variant="p" className="verification-success">
                      Email Successfully Verified
                      </Typography>
                    </Box>
                    {/* {status===500?'':<Button className="btn-outline-primary mt-3 btn-height" href="/">
                      {message==='Email already verified...!!!'?'Login':'Resend'}
                    </Button>} */}
                     <Box className="w-100">
                  <Button variant="outlined" className="mt-3 w-50  py-2 mx-0 retail-btn" onClick={ResentOtp}>
                     Resend
                  </Button>
                  </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

// export default App;
