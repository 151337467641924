import React, { useImperativeHandle, forwardRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Grid, FormControl, OutlinedInput, Divider, Card, CardContent } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const ScanModal = forwardRef((props, ref15) => {
    const [open, setOpen] = React.useState(false);
    const [imgfile, setImgfile] = React.useState(true);
    const scanImage = () => {
        setImgfile(imgfile => !imgfile)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const showSwalWithLink = () => {
        MySwal.fire({
            icon: 'error',
            text: "All Fields Are Mandatory",
        });
    };

    useImperativeHandle(ref15, () => ({
        log() {
            handleOpen();
        }
    }));


    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal'
            >
                <Box sx={{ ...style }} className='profile-modal'>
                    <Grid container>
                        <Grid item xs={12} className="profile-header p-15" >

                            <CloseIcon onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Box className="scan-div">
                        <Card className='w-500'>
                            <CardContent className='text-center'>
                                {
                                    imgfile ?

                                        <Grid container className='details-div p-15 '>
                                            <Grid item xs={12} md={12}>
                                                <Box className="w-100 text-center">
                                                    <Box className='camera-div'>
                                                        <Typography component='p'>Request Camera Permissions</Typography>

                                                    </Box>
                                                </Box>
                                                <Box className="w-100 text-center">
                                                    <Box className="image-file">
                                                        <Typography component='p' onClick={scanImage}>Scan an Image File</Typography>

                                                    </Box>
                                                </Box>
                                            </Grid>



                                        </Grid>
                                        : <Box className="flex-column">
                                            <OutlinedInput type='file' ></OutlinedInput>
                                            <Box className="w-100 text-center flex-column" mt={3}>
                                                <Typography component="p">Select Image</Typography>
                                                <Box className="image-file">
                                                    <Typography component='p' onClick={scanImage}>Scan an Image File</Typography>

                                                </Box>
                                            </Box>
                                        </Box>
                                }
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
});

export default ScanModal;