import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  MenuItem,
  OutlinedInput,
  FormControl,
} from "@mui/material";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import p2 from "../images/mb.jpg";
import shoppingcounter from "../images/shopping-counter.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import item1 from "../images/item1.png";
import item2 from "../images/item2.png";
import item3 from "../images/item3.png";
import item4 from "../images/item4.png";
import item5 from "../images/item9.png";
import item6 from "../images/item10.png";
import star from "../images/star.png";

// import required modules
import { FreeMode, Navigation } from "swiper";

export default function Caurosel() {
  const ref7 = useRef();
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        loop={true}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[FreeMode, Navigation]}
        // loop={true}

        watchSlidesProgress={true}
        // modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper items custom-item-slider"
      >
        <SwiperSlide>
          {" "}
          <Card className="pos-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={p2}
              className="card-img"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="p">
                Chicken Biryani
              </Typography>
              <Box className="flex flex-between align-center">
                <Typography component="span" className="text-danger">
                  Rs. 250/-
                </Typography>
                <Typography component="span" className="small">
                  250 gms
                </Typography>
              </Box>
              <Box className="text-center">
                <Button variant="contained" onClick={() => ref7.current.log()}>
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </CardContent>
            <CardActions className="card-footer">
              <Typography component="span">
                <Box component="img" src={shoppingcounter}></Box>
                Sri Srinivasa merchants
              </Typography>
            </CardActions>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Card className="pos-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={p2}
              className="card-img"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="p">
                Everest Cholle Masala
              </Typography>
              <Box className="flex flex-between align-center">
                <Typography component="span" className="text-danger">
                  Rs. 50/-
                </Typography>
                <Typography component="span" className="small">
                  50 gms
                </Typography>
              </Box>
              <Box className="text-center">
                <Button variant="contained" onClick={() => ref7.current.log()}>
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </CardContent>
            <CardActions className="card-footer">
              <Typography component="span">
                <Box component="img" src={shoppingcounter}></Box>
                Sri Srinivasa merchants
              </Typography>
            </CardActions>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Card className="pos-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={p2}
              className="card-img"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="p">
                Everest Cholle Masala
              </Typography>
              <Box className="flex flex-between align-center">
                <Typography component="span" className="text-danger">
                  Rs. 50/-
                </Typography>
                <Typography component="span" className="small">
                  50 gms
                </Typography>
              </Box>
              <Box className="text-center">
                <Button variant="contained" onClick={() => ref7.current.log()}>
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </CardContent>
            <CardActions className="card-footer">
              <Typography component="span">
                <Box component="img" src={shoppingcounter}></Box>
                Sri Srinivasa merchants
              </Typography>
            </CardActions>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Card className="pos-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={p2}
              className="card-img"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="p">
                Everest Cholle Masala
              </Typography>
              <Box className="flex flex-between align-center">
                <Typography component="span" className="text-danger">
                  Rs. 50/-
                </Typography>
                <Typography component="span" className="small">
                  50 gms
                </Typography>
              </Box>
              <Box className="text-center">
                <Button variant="contained" onClick={() => ref7.current.log()}>
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </CardContent>
            <CardActions className="card-footer">
              <Typography component="span">
                <Box component="img" src={shoppingcounter}></Box>
                Sri Srinivasa merchants
              </Typography>
            </CardActions>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Card className="pos-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={p2}
              className="card-img"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="p">
                Everest Cholle Masala
              </Typography>
              <Box className="flex flex-between align-center">
                <Typography component="span" className="text-danger">
                  Rs. 50/-
                </Typography>
                <Typography component="span" className="small">
                  50 gms
                </Typography>
              </Box>
              <Box className="text-center">
                <Button variant="contained" onClick={() => ref7.current.log()}>
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </CardContent>
            <CardActions className="card-footer">
              <Typography component="span">
                <Box component="img" src={shoppingcounter}></Box>
                Sri Srinivasa merchants
              </Typography>
            </CardActions>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Card className="pos-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={p2}
              className="card-img"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="p">
                Everest Cholle Masala
              </Typography>
              <Box className="flex flex-between align-center">
                <Typography component="span" className="text-danger">
                  Rs. 50/-
                </Typography>
                <Typography component="span" className="small">
                  50 gms
                </Typography>
              </Box>
              <Box className="text-center">
                <Button variant="contained" onClick={() => ref7.current.log()}>
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </CardContent>
            <CardActions className="card-footer">
              <Typography component="span">
                <Box component="img" src={shoppingcounter}></Box>
                Sri Srinivasa merchants
              </Typography>
            </CardActions>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Card className="pos-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={p2}
              className="card-img"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="p">
                Everest Cholle Masala
              </Typography>
              <Box className="flex flex-between align-center">
                <Typography component="span" className="text-danger">
                  Rs. 50/-
                </Typography>
                <Typography component="span" className="small">
                  50 gms
                </Typography>
              </Box>
              <Box className="text-center">
                <Button variant="contained" onClick={() => ref7.current.log()}>
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </CardContent>
            <CardActions className="card-footer">
              <Typography component="span">
                <Box component="img" src={shoppingcounter}></Box>
                Sri Srinivasa merchants
              </Typography>
            </CardActions>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Card className="pos-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={p2}
              className="card-img"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="p">
                Everest Cholle Masala
              </Typography>
              <Box className="flex flex-between align-center">
                <Typography component="span" className="text-danger">
                  Rs. 50/-
                </Typography>
                <Typography component="span" className="small">
                  50 gms
                </Typography>
              </Box>
              <Box className="text-center">
                <Button variant="contained" onClick={() => ref7.current.log()}>
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </CardContent>
            <CardActions className="card-footer">
              <Typography component="span">
                <Box component="img" src={shoppingcounter}></Box>
                Sri Srinivasa merchants
              </Typography>
            </CardActions>
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Card className="pos-card">
            <CardMedia
              component="img"
              alt="green iguana"
              height="140"
              image={p2}
              className="card-img"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="p">
                Everest Cholle Masala
              </Typography>
              <Box className="flex flex-between align-center">
                <Typography component="span" className="text-danger">
                  Rs. 50/-
                </Typography>
                <Typography component="span" className="small">
                  50 gms
                </Typography>
              </Box>
              <Box className="text-center">
                <Button variant="contained" onClick={() => ref7.current.log()}>
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </CardContent>
            <CardActions className="card-footer">
              <Typography component="span">
                <Box component="img" src={shoppingcounter}></Box>
                Sri Srinivasa merchants
              </Typography>
            </CardActions>
          </Card>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
