import React, { useImperativeHandle, forwardRef, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Grid, FormControl, OutlinedInput, Divider } from '@mui/material';
import MemberTable from '../Customer/MemberTable';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import wallet from '../images/wallet.png';
import credit from '../images/credit.png';
import upi from '../images/upi.png';
import customer from '../images/customers.svg';
import cod from "../images/cod.png";
import cash from "../images/cash.png";
import api from '../Api';
import axios from 'axios';


// import RazorpayButton from './RazorpayButton';
// import Razorpay from 'razorpay';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const InvoiceDetailsModal = forwardRef((props, ref6) => {
    const [open, setOpen] = React.useState(false);
    const [showState, setShowState] = React.useState(false);
    const [pay, setPay] = React.useState(false);
    let pendingCartItems = useSelector(state => state.user.pending_cart_items);
    let invoiceId = useSelector(state => state.user.pos_invoice_id);
    const response = useSelector(state => state.user.user);
    let cartTotal = useSelector(state => state.user.cart_total);
    let customerId = useSelector(state => state.user.customer_id);
    const [paymentDetails,setPaymentDetails] = React.useState();
    const [passwd, setPasswd] = React.useState(false);




    const [cashPaymentId, setCashpaymentId] = React.useState();

   const [gatwayDetails,setGatewayDetails] = React.useState();

   
   useEffect(()=>{
    if(gatwayDetails){
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.onload = displayRazorpay;
    document.body.appendChild(script);
    }
},[gatwayDetails]);

   const handlePayment = async () => {
    try {
       await api.getData('/api/pos/Get_payment_gateway_details?merchant_id=' + response?.merchant_id + '&environment=' + process.env.REACT_APP_MY_TITLE).then((response)=>{
        if (response.data[0]?.Status === "200") {
            const parsedData = JSON.parse(response.data[0].PaymentGatewayDeatails)[0];
            setGatewayDetails(JSON.parse(parsedData.config_payload)[0]);
      
            // Debugging: Log the received gateway details
            
      
            // Call the payment method here after setting the gateway details
            // setTimeout(()=>{
              
            // },1000);
            // const script = document.createElement('script');
            // script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            // script.async = true;
            // script.onload = displayRazorpay;
            // document.body.appendChild(script);
          } else {
            console.error("Error: Unexpected response status from the server.");
          }
      })
     
    } catch (error) {
      console.error("Error fetching payment gateway details:", error);
    }
  };
  

    // const handlePayment = async () => {

    //  await api.getData('/api/pos/Get_payment_gateway_details?merchant_id=' + response?.merchant_id + '&environment=' + process.env.REACT_APP_MY_TITLE)
    //     .then((response) => {
  
    //       if (response.data[0]?.Status === "200") {
    //         setGatewayDetails(JSON.parse(response.data[0].PaymentGatewayDeatails)[0]);
           
    //       }
  
  
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //     })
        
    // };
     
    const displayRazorpay = () => {
        const options = {
            key: gatwayDetails?.RAZOR_KEY_ID, // Replac.e with your Razorpay API key
            amount: pendingCartItems ? pendingCartItems[0].total_item_price * 100:'', // Amount in paise (e.g., 100 paise = INR 1)
            currency: 'INR',
            name: 'Your Company',
            description: 'Purchase Description',
            order_id: props?.order_id,
            handler: function (response) {
                alert('Payment Successful!');
                posinsertOnlinePaymentDetails(response);
                
                
            },
            prefill: {
                name: '',
                email: '',
                contact: '',
            },
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };


    const handlePassword = () => {
        setPasswd(passwd => !passwd)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        props.getPosPendingInvoices();
        props.getCartItemProductid();
    };

    const changePay = () => {
        setPay(pay => !pay);
    }

    // const handlePayment = async () => {
    //     // const Razorpay = (await import('razorpay')).default;
    //     const options = {
    //       key: 'YOUR_RAZORPAY_API_KEY',
    //       // Rest of your options...
    //     };
    //     const razorpay = new Razorpay(options);
    //     razorpay.open();
    //   };

    const [paymentType, setPaymentType] = React.useState();

    const handlePaymentType = () => {
        switch (paymentType) {
            case 'cash':
                posInsertOrderCashPaymentDetails();
                break;
            case 'cod':
                posInsertCodPaymentDetails();
                break;
            case 'credit':
                posinsertcreditPaymentDetails();
                break;
            case 'online':
                handlePayment();
                break;
            default:
                console.log('Default option selected');
                break;
        }

        // Update the state with the selected option
    };

    const OnlinePaymentDetails = () => {
        let data = {
            'order_id': props?.order_id,
            'customer_id': customerId || '',
            'online_amount': pendingCartItems ? pendingCartItems[0].total_item_price : '',
            'online_status': '0',
            'receipt_or_payment': 'p',
            'online_payment_payload': 'null',
            'transaction_date': new Date()
        };

        api.postData('/api/pos/POS_InsertOrderInvoice', data).then((response) => {


            if (response.data[0].Status === "200") {

                showSwalWithLink(response.data[0].Message);


                // setInvoiceId(response.data[0].invoice_id);
            } else {
                showSwalWithLinkError(response.data[0].Message);
            }


        })
    }

    const posUpdateOrderDetailsAfterPay = () => {
        let data = {
            'invoice_id': invoiceId,
            'customer_bazaar_id': customerId,
            'merchant_id': response?.merchant_id,
            'environment': process.env.REACT_APP_MY_TITLE


        };
        if(data.invoice_id === null || data.invoice_id === ''){
            showSwalWithLinkError("Invoice Id Can not Be Null");
            return false;
        }else if(data.customer_bazaar_id === null || data.customer_bazaar_id === ''){
            showSwalWithLinkError("Customer Id Can not Be Null");
            return false;
        }else if(data.merchant_id === null || data.merchant_id === ''){
            showSwalWithLinkError("Merchant Id Can not Be Null");
            return false;
        }
        api.postData('/api/pos/POS_update_order_details_after_pay', data).then((response) => {


            if (response.data[0].Status === "200") {
                

                // showSwalWithLink(response.data[0].Message);

                // setInvoiceId(response.data[0].invoice_id);
            } else {
                showSwalWithLinkError(response.data[0].Message);
            }


        })
    }

    const posinsertOnlinePaymentDetails = (res) => {
        let data = {
            'order_id': props?.order_id,
            'customer_id': customerId,
            'online_amount': cartTotal ? cartTotal[0].total_item_price : '',
            'online_status': '0',
            'receipt_or_payment': 'p',
            'online_payment_payload': [res,props?.orderId, cartTotal ? cartTotal[0].total_item_price : '', props?.customerId ? props.customerId : ''],
            'environment': process.env.REACT_APP_MY_TITLE,
            'transaction_date': new Date()

        };
        if(data.order_id === null || data.order_id === ''){
            showSwalWithLinkError("Order Id Can not Be Null");
            return false;

        }else if(data.customer_id === null || data.customer_id === ''){
            showSwalWithLinkError("Customer Id Can not Be Null");
            return false;
        }else if(data.online_amount === null || data.online_amount === ''){
            showSwalWithLinkError("Online Amount Can not Be Null");
            return false;
        }
        api.postData('/api/pos/POS_Insert_online_Payment_Details', data).then((response) => {


            if (response.data[0].Status === "200") {

                showSwalWithLink(response.data[0].Message);
                setCashpaymentId(response.data[0].cash_payment_id);
                handlePayment();
                posUpdateOrderDetailsAfterPay();
                setPaymentDetails(JSON.parse(response.data[0].PaymentDetails));
                setPay(true);
                // setInvoiceId(response.data[0].invoice_id);
            } else {
                showSwalWithLinkError(response.data[0].Message);
            }


        })
    }

    const posinsertcreditPaymentDetails = () => {
        let data = {
            'order_id': props?.orderId,
            'customer_bazaar_id': customerId || '',
            'credit_amount': cartTotal ? cartTotal[0].total_item_price : '',
            'credit_status': '0',
            'receipt_or_payment': 'r',
            'credit_payment_payload': [props?.orderId, cartTotal ? cartTotal[0].total_item_price : '', props?.customerId ? props.customerId : ''],
            'transaction_date': new Date(),
            'environment': process.env.REACT_APP_MY_TITLE,
            'payment_type': 'credit',
        };
        if(data.order_id === null || data.order_id === ''){
            showSwalWithLinkError("Order Id Can not Be Null");
            return false;

        }else if(data.customer_bazaar_id === null || data.customer_bazaar_id === ''){
            showSwalWithLinkError("Customer Id Can not Be Null");
            return false;
        }else if(data.credit_amount === null || data.credit_amount === ''){
            showSwalWithLinkError("Credit Amount Can not Be Null");
            return false;
        }
        api.postData('/api/pos/POS_Insert_credit_Payment_Details', data).then((response) => {


            if (response.data[0].Status === "200") {

                showSwalWithLink(response.data[0].Message);
                setCashpaymentId(response.data[0].cash_payment_id);
                posUpdateOrderDetailsAfterPay();
                setPaymentDetails(JSON.parse(response.data[0].PaymentDetails));
                setPay(true);
                // setInvoiceId(response.data[0].invoice_id);
            } else {
                showSwalWithLinkError(response.data[0].Message);
            }

        })
    }

    const [creditShowState, setCreditShowState] = React.useState(false);
    const payCredit = () => {
        setCreditShowState(creditShowState => !creditShowState);
    }

    const payclick = () => {
        setShowState(showState => !showState);
    };


    const posInsertOrderCashPaymentDetails = () => {
        let data = {
            'order_id': props?.orderId || '',
            'customer_bazaar_id': customerId || '',
            'cash_amount': cartTotal ? cartTotal[0].total_item_price : '',
            'cash_status': '0',
            'receipt_or_payment': 'r',
            'cash_payload': [props?.orderId, cartTotal ? cartTotal[0].total_item_price : '', props?.customerId ? props.customerId : ''],
            'transaction_date': new Date(),
            'environment': process.env.REACT_APP_MY_TITLE,
        };
        if(data.order_id === null || data.order_id === ''){
            showSwalWithLinkError("Order Id Can not Be Null");
            return false;

        }else if(data.customer_bazaar_id === null || data.customer_bazaar_id === ''){
            showSwalWithLinkError("Customer Id Can not Be Null");
            return false;
        }else if(data.cash_amount === null || data.cash_amount === ''){
            showSwalWithLinkError("Cash Can not Be Null");
            return false;
        }
        api.postData('/api/pos/POS_InsertOrderCashPaymentDetails', data).then((response) => {


            if (response.data[0].Status === "200") {

                showSwalWithLink(response.data[0].Message);
                setCashpaymentId(response.data[0].cash_payment_id);
                posUpdateOrderDetailsAfterPay();
                setPaymentDetails(JSON.parse(response.data[0].PaymentDetails));
                setPay(true);
                // setInvoiceId(response.data[0].invoice_id);
            } else {
                showSwalWithLinkError(response.data[0].Message);
            }

        })
    }





    const posInsertCodPaymentDetails = () => {
        let data = {
            'order_id': props?.orderId,
            'customer_bazaar_id': customerId,
            'cod_amount': cartTotal ? cartTotal[0].total_item_price : '',
            'cod_status': '0',
            'receipt_or_payment': 'r',
            'cod_payload': JSON.stringify([props?.orderId, cartTotal ? cartTotal[0].total_item_price : '', props?.customerId ? props.customerId : '']),
            'transaction_date': new Date(),
            'environment': process.env.REACT_APP_MY_TITLE,
            'cod_payment_type': 'cod'
        };
        if(data.order_id === null || data.order_id === ''){
            showSwalWithLinkError("Order Id Can not Be Null");
            return false;

        }else if(data.customer_bazaar_id === null || data.customer_bazaar_id === ''){
            showSwalWithLinkError("Customer Id Can not Be Null");
            return false;
        }else if(data.cod_amount === null || data.cod_amount === ''){
            showSwalWithLinkError("Amount Can not Be Null");
            return false;
        }


        api.postData('/api/pos/POS_Insert_COD_Payment_Details', data).then((response) => {


            if (response.data[0].Status === "200") {

                showSwalWithLink(response.data[0].Message);
                setCashpaymentId(response.data[0].cash_payment_id);
                posUpdateOrderDetailsAfterPay();
                setPaymentDetails(JSON.parse(response.data[0].PaymentDetails));
                setPay(true);
                // setInvoiceId(response.data[0].invoice_id);
            } else {
                showSwalWithLinkError(response.data[0].Message);
            }


        })
    }

    const [isOnlinePaymentClicked, setIsOnlinePaymentClicked] = React.useState(false);

    const handleOnlinePaymentClick = () => {
        setIsOnlinePaymentClicked(true);
    };

    const handlePaymentSuccess = (response) => {
        console.log('Payment success:', response);
        // Handle payment success logic here
    };

    const OnlinePayment = (money) => {

    }
    const cartItem =
        [
            {
                name: 'cart_id',
                label: 'S.NO',
                //    label:'',

            },
            {
                name: 'product_complete_sku', label: 'SKU ID'
            },
            { name: 'product_name', label: 'ITEM NAME & DETAILS' },
            { name: 'product_qty_sold', label: 'QTY' },
            { name: 'sale_price', label: 'PRICE' },
            { name: 'total_item_price', label: 'TOTAL' }

        ];
    const itemData = [["Cream", "#648744", "35", "₹ 100.00", "₹ 3500.00"],
    ["Facewash", "#627888", "40", "₹ 200.00", "	₹ 8000.00"],
    ["", "", "", "	Sub Total", "	₹ 11500.00"],
    ["", "", "", "	Discount", "	₹ 500.00"],
    ["", "", "", "	Total Tax", "	₹ 400.00"],
    ["", "", "", "	CGST", "	₹ 100.00"],
    ["", "", "", "	SGST", "	₹ 100.00"],

    ["", "", "", "	IGST", "	₹ 100.00"], ["", "", "", "	UTGST", "	₹ 100.00"],
    ["", "", "", "	Compensatory Cess Rate", "		₹ 50.00"]];
    const pcOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        responsive: "scrollMaxHeight",
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };
    const showSwalWithLink = (message) => {
        MySwal.fire({
            icon: 'success',
            text: message,
        })
        // .then(() => {
        //     handleClose();
        // })
    };
    const showSwalWithLinkError = (message) => {
        MySwal.fire({
            icon: 'error',
            text: message,
        });
    };

    useImperativeHandle(ref6, () => ({
        log() {
            handleOpen();
        }
    }));


    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal '

            >
                <Box sx={{ ...style }} className='profile-modal modal-md'>
                    <Grid container>
                        <Grid item xs={12} className="profile-header p-15" >

                            <Typography id="parent-modal-title" component="h4">INVOICE DETAILS</Typography>
                            <CloseIcon onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <Divider></Divider>

                    <Grid container spacing={2} className='details-div p-15' >


                        <Grid item xs={12} md={7}>
                            <Box className="w-100" mb={2}>
                                <Typography component='p'>{invoiceId}</Typography>
                            </Box>
                            <Box className="w-100 flex-between id-p" mb={2}>
                                <Box>
                                    <Typography component='p'>Name:{props && props.customerData && props.customerData.name_of_the_customer}</Typography>

                                </Box>
                                <Box>
                                    <Typography component='p'>Contact No:{props && props.customerData && props.customerData.phone_number}</Typography>

                                </Box>
                                <Box>
                                    <Typography component='p'>Email:{props && props.customerData && props.customerData.email_id}</Typography>

                                </Box>

                            </Box>
                            <MemberTable columns={cartItem} data={pendingCartItems ? pendingCartItems : []} options={pcOptions} />

                        </Grid>
                        {pay ?
                            <Grid item xs={12} md={5} className='payment-container'>
                                <Box>
                                    <Typography component="h5">Payment Details</Typography>
                                </Box>
                                <Grid container spacing={2} mt={2} className='p-15'>
                                    <Grid Item xs={12} md={6}>
                                        <Box className="information-div">
                                            <Box component="img" src={customer} />
                                            <Box>
                                                <Typography component="label">Payment Type</Typography>
                                                <Typography component='h5'>{paymentDetails && paymentDetails[0]?.pay_type}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid Item xs={12} md={6}>
                                        <Box className="information-div" >
                                            <Box component="img" src={customer} />
                                            <Box>
                                                <Typography component="label">Transaction ID</Typography>
                                                <Typography component='h5'>{paymentDetails && paymentDetails[0].order_id}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid Item xs={12} md={6}>
                                        <Box className="information-div">
                                            <Box component="img" src={customer} />
                                            <Box>
                                                <Typography component="label">Transaction Date & Time</Typography>
                                                <Typography component='h5'>{paymentDetails && paymentDetails[0]?.transaction_date}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid Item xs={12} md={6}>
                                        <Box className="information-div">
                                            <Box component="img" src={customer} />
                                            <Box>
                                                <Typography component="label">Transaction Status</Typography>
                                                <Typography component='h5'>{paymentDetails && paymentDetails[0]?.status === 0 ? "Completed":"Pending"}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box className="text-center" mt={3}>
                                    <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={()=>{setPay();handleClose()}} >Ok</Button>
                                </Box>
                            </Grid>
                            :
                            <Grid item xs={12} md={5} className='payment-container p-15'>
                                <Box>
                                    <Typography component="h5">Payment Type</Typography>
                                </Box>

                                <Box className={paymentType === "cash"?"flex payment-div payment-active":"flex payment-div"} onClick={() => { setPaymentType('cash') }}>
                                    <Box src={cash} component="img" mr={1} />
                                    <Typography component="h5">Cash</Typography>
                                </Box>
                                <Box className={paymentType === "cod"?"flex payment-div payment-active":"flex payment-div"} onClick={() => { setPaymentType('cod') }}>
                                    <Box src={cod} component="img" mr={1} />
                                    <Typography component="h5">COD</Typography>
                                </Box>
                                {/* <button >Pay with Razorpay</button> */}
                                <Box className={paymentType === "credit"?"flex payment-div payment-active":"flex payment-div"} onClick={() => { payCredit(); setPaymentType('credit') }}>
                                    <Box src={cash} component="img" mr={1} />
                                    <Typography component="h5">Credit</Typography>
                                </Box>
                                {creditShowState && (
                                    <Box className="flex">
                                        <Grid container>
                                            <Grid item md={6}>
                                                <Box className="">
                                                    <Typography component="p">Credit Limit</Typography>
                                                    <Typography component="h5">₹ 50,000</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Box className="">
                                                    <Typography component="p">
                                                        Available Credit Limit
                                                    </Typography>
                                                    <Typography component="h5">₹ 50,000</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                {/* <Box className="flex" onClick={() => { payclick();handlePayment() }} > */}
                                <Box className={paymentType === "online"?"flex payment-div payment-active":"flex payment-div"} onClick={() => { setPaymentType('online'); }} >

                                    <Box src={credit} component="img" mr={1} />
                                    <Typography component="h5">Online</Typography>
                                </Box>
                                {showState && (
                                    <Box className="flex">
                                        <Grid container>
                                            <Grid item md={6}>
                                                <Box className="flex">
                                                    <Box src={upi} component="img" mr={1} />
                                                    <Typography component="h5">UPI</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Box className="flex">
                                                    <Box src={upi} component="img" mr={1} />
                                                    <Typography component="h5">
                                                        Credit / Debit Card
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Box className="flex">
                                                    <Box src={upi} component="img" mr={1} />
                                                    <Typography component="h5">Net Banking</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item md={6}>
                                                <Box className="flex">
                                                    <Box src={upi} component="img" mr={1} />
                                                    <Typography component="h5">UPI</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                                {/* <Box className="flex  payment-click" onClick={() => handlePayment()}>
                                        <Box src={wallet} component='img' mr={1} />
                                        <Typography component="h5">Online Payment</Typography>
                                    </Box> */}

                                {/* <Box className="flex">
                                    <Box src={wallet} component='img' mr={1} />
                                    <Typography component="h5">Wallet</Typography>
                                </Box> */}
                                {/* <Box className="flex  payment-click">
                                    <Box src={credit} component='img' mr={1} />
                                    <Typography component="h5">Credit / Debit Card</Typography>
                                </Box>
                                <Box className="flex  payment-click">
                                    <Box src={upi} component='img' mr={1} />
                                    <Typography component="h5">UPI</Typography>
                                </Box>
                                <Box className="flex  payment-click">
                                    <Box src={wallet} component='img' mr={1} />
                                    <Typography component="h5">Add To Supplier Account</Typography>
                                </Box> */}
                                <Box mt={5} className="text-center">
                                    <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={()=>{handlePaymentType()}} >Pay Now</Button>
                                </Box>
                            </Grid>
                        }
                    </Grid>

                </Box>
            </Modal>
        </div>
    );
});

export default InvoiceDetailsModal;