
export const setUser = (response) => ({
  type: 'SET_USER',
  payload: response,
});
export const setCity = (response) => ({
  type: 'SET_CITIES',
  payload: response,
});
export const setActiveTab = (response) => {
  return {
    type: 'SET_ACTIVE_TAB',
    payload: response,
  };
};
export const setTableNumber = (response) => {
  return {
    type: 'SET_TABLE_NUMBER',
    payload: response,
  };
};
export const setExpandState = (response) => {
  return {
    type: 'SET_EXPAND_STATE',
    payload: response,
  };
};

export const getUser = (response) => ({
  type: 'GET_USER',
  payload: response,
});

export const setServices = (response) => ({
  type: 'SET_SERVICES',
  payload: response,
});

export const setDeliveryId = (response) => ({
  type: 'SET_DELIVERY_ID',
  payload: response,
});

export const setDeliveryTypeId = (response) => ({
  type: 'SET_DELIVERY_TYPE_ID',
  payload: response,
});

export const clearUser = () => ({
  type: 'CLEAR_USER',
});
export const posPendingCartItems = (response) => ({
  type: 'SET_PENDING_CART_ITEMS',
  payload: response,
});
export const posPendingCartTaxAndFee = (response) => ({
  type: 'SET_CART_TAX_AND_FEE',
  payload: response,
});
export const posOrderData = (response) => ({
  type: 'SET_POS_ORDER_DATA',
  payload: response,
});
export const setProductId = (response) => ({
  type: 'SET_PRODUCT_ID',
  payload: response,
});
export const setCartTotal = (response) => ({
  type: 'SET_CART_TOTAL',
  payload: response,
});
export const setCustomersId = (response) => ({
  type: 'SET_CUSTOMER_ID',
  payload: response,
});
export const setOrderId = (response) => ({
  type: 'SET_ORDER_ID',
  payload: response,
});
export const setUomData = (response) => ({
  type: 'SET_UOM_DATA',
  payload: response,
});
export const setStockItemData = (response) => ({
  type: 'SET_STOCKITEM_DATA',
  payload: response,
});
export const setProfileData = (response) => ({
  type: 'SET_PROFILE_DATA',
  payload: response,
});
export const setProductSku = (response) => ({
  type: 'SET_PRODUCT_SKU',
  payload: response,
});
export const setPosPhone = (response) => ({
  type: 'SET_POS_PHONE',
  payload: response,
});
export const setPosLocation = (response) => ({
  type: 'SET_POS_Location',
  payload: response,
});
export const setPosInvoiceId = (response) => ({
  type: 'SET_POS_INVOICEID',
  payload: response,
});
export const setItemStock = (response) => ({
  type: 'SET_ITEM_STOCK',
  payload: response,
});
export const setMeasurementId = (response) => ({
  type: 'SET_MEASUREMENT_ID',
  payload: response,
});
export const setLocations = (response) => ({
  type: 'SET_LOCATIONS',
  payload: response,
});

export const setSegments = (response) => ({
  type: 'SET_SEGMENTS',
  payload: response,
});

export const getPendingInvoices = (response) => ({
  type: 'SET_PENDING_INVOICES',
  payload: response,
});

export const editSegment = (id, newData) => ({
  type: 'EDIT_SEGMENT',
  payload: { id, newData }
});

export const setTwoDelDynamicId = (response) => ({
  type: 'SET_TWO_DEL_DYNAMIC_ID',
  payload: response,
});
export const setThreeDelDynamicId = (response) => ({
  type: 'SET_THREE_DEL_DYNAMIC_ID',
  payload: response,
});

export const setTwoDelBaseId = (response) => ({
  type: 'SET_TWO_DEL_BASE_ID',
  payload: response,
});
export const setThreeDelBaseId = (response) => ({
  type: 'SET_THREE_DEL_BASE_ID',
  payload: response,
});

export const setPromotionsEditData = (response) => ({
  type: 'SET_PROMOTIONS_EDIT_DATA',
  payload: response,
});

export const setPromotionsProductDetails = (response) => ({
  type: 'SET_PROMOTIONS_PRODUCT_EDIT_DATA',
  payload: response,
});
export const setPromotionsCustomerEditData = (response) => ({
  type: 'SET_PROMOTIONS_CUSTOMER_EDIT_DATA',
  payload: response,
});
// export function Store() {
//   return {
//      type: 'STORE'
//   }
// }
// export function Update() {
//   return {
//      type: 'UPDATE'
//   }
// }

// export function Insert() {
//   return {
//      type: 'INSERT'
//   }
// }

// export function Delete() {
//   return {
//      type: 'DELETE'
//   }
// }
// export function Reset() {
//   return { type: 'RESET' }
// }