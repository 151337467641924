import React,{useEffect} from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import MemberTable from "../Customer/MemberTable";
import LocationModal from "./LocationModal";
import Bredcrum from "../Bredcrum";
import { useSelector,useDispatch } from 'react-redux';
import api from '../Api';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Formik, useFormik,Form,Field } from "formik";
import { baseTwoWheeler,baseThreeWheeler,dynamicTwoWheeler,dynamicThreeWheeler,adminFee } from "../Validation";

import { setThreeDelDynamicId,setTwoDelDynamicId,setTwoDelBaseId,setThreeDelBaseId } from "../Redux/actions/userActions";

export default function DeliveryFeeEngine() {
  const [showState, setShowState] = React.useState(false);
  const [cities, setCities] = React.useState();
  const dispatch = useDispatch();
  const [cityId,setCityId] = React.useState();
  const response = useSelector(state=>state.user.user);
  const two_del_dynamic_id = useSelector(state=>state.user.two_del_dynamic_id);
  const three_del_dynamic_id = useSelector(state=>state.user.three_del_dynamic_id);
  const three_del_base_id = useSelector(state=>state.user.three_del_base_id);
  const two_del_base_id = useSelector(state=>state.user.two_del_base_id);
  
  const [spineer, setSpinner] = React.useState(true);
  const [twoWheelerBasedetails,setTwoWheelerBaseDetails] = React.useState();
  const [twoDelFeeId,setTwoDelFeeId] = React.useState();
  const [threeDelFeeId,setThreeDelFeeId] = React.useState();
  const [twoDelFeeDynamicId,setTwoDelFeeDynamicId] = React.useState();
  const [threeDelFeeDynamicId,setThreeDelFeeDynamicId] = React.useState();
  const [twoWheeler,setTwoWheeler] = React.useState();
  const [threeWheelerBaseDetails,setThreeWheelerBaseDetails] = React.useState();
  const [threeWheeler,setThreeWheeler] = React.useState();
  const [threeWheelerDynamicData,setThreeWheelerDynamicData] = React.useState();
  const [adminDeliveryFeeDetails,setAdminDeliveryFeeDetails] = React.useState();
  const [twoWheelerDynamic,setTwoWheelerDynamic] = React.useState();
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  const handleCity = (event) =>{
    setCityId(event.target.value)
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
  };
  const ref = React.useRef();
  const ref4 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
      MySwal.fire({
          icon: 'success',
          title: message,
      }).then(function(){
         
         
      })
    };
  const handleSubmit = (values) => {
      api.postData('/api/delivery_fee_engine/two_wheeler_base_del_config',values).then((response) => {
            if(response.data[0].Status === "200"){
              showSwalWithLink(response.data[0].Message);
            }
         
        }).catch(error => {
          console.error(error);
        });
  };
  // const handleSubmit1 = (values) => {
  //     api.postData('/api/delivery_fee_engine/two_wheeler_dynamic_del_config',values).then((response) => {
  //          console.log('response',response);
  //           if(response.data[0].Status === "200"){
  //             showSwalWithLink();
  //           }
         
  //       }).catch(error => {
  //         console.error(error);
  //       });
  // };
  const twoWheelerColumns = [
   
    { name: 'from_amount', label: 'FROM' },
    { name: 'to_amount', label: 'TO' },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            const rowIndex = tableMeta.rowIndex;
            const rowData = twoWheeler[rowIndex]; 
          return(  <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={()=>deleteTwoWheeler(rowData)}>Delete</Button>);
        },
      },
    },
  ];

  const threeWheelerColumns = [
   
    { name: 'from_amount', label: 'FROM' },
    { name: 'to_amount', label: 'TO' },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            const rowIndex = tableMeta.rowIndex;
            const rowData = threeWheeler[rowIndex]; 
          return(  <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={()=>deletethreeWheeler(rowData)}>Delete</Button>);
        },
      },
    },
  ];
  const handleSubmitThreeWheeler = (values) => {
      api.postData('/api/delivery_fee_engine/three_wheeler_base_del_config',values).then((response) => {
            if(response.data[0].Status === "200"){
              showSwalWithLink(response.data[0].Message);
              dispatch(setTwoDelDynamicId(response.data[0].TwoWheelerBaseDelFeeId));
              fetchDynamicThreeWheeler();
            }
         
        }).catch(error => {
          console.error(error);
        });
  };
  const [threeWheelerBaseDelFeeId,setThreeWheelerBaseDelFeeId] = React.useState();
  const handleSubmitThreeWheelerDel = (values) => {
      api.postData('/api/delivery_fee_engine/three_wheeler_dynamic_del_config',values).then((response) => {
            if(response.data[0].Status === "200"){
              showSwalWithLink(response.data[0].Message);
              
              setThreeWheelerBaseDelFeeId(response.data[0].threeWheelerDynamicFeeId);
              dispatch(setThreeDelDynamicId(response.data[0].threeWheelerDynamicFeeId));
              fetchDynamicThreeWheeler();
            }
         
        }).catch(error => {
          // Handle error
          console.error(error);
        });
  };
  const [twoWheelerBaseDelFeeId,setTwoWheelerBaseDelFeeId] = React.useState();
  const handleSubmit2 = (values) => {
      api.postData('/api/delivery_fee_engine/two_wheeler_dynamic_del_config',values).then((response) => {
            if(response.data[0].Status === "200"){
              showSwalWithLink(response.data[0].Message);
              setTwoWheelerBaseDelFeeId(response.data[0].TwoWheelerBaseDelFeeId);
              dispatch(setTwoDelDynamicId(response.data[0].TwoWheelerBaseDelFeeId));
              fetchDynamicTwoWheeler();
              // setSuccess(true);
            }
         
        }).catch(error => {
          // Handle error
          console.error(error);
        });
  };
  
  const getTwoWheelerBaseDetails = () =>{
    // api.getData('/api/delivery_fee_engine/get_two_wheeler_base_details?city_id='+response?.city_id+'&country_id='+response?.country_id)
    api.getData('/api/delivery_fee_engine/get_two_wheeler_base_details?city_id='+response?.city_id+'&country_id='+response?.country_id)

    .then((response) => {

      if (response.data[0]?.Status === "200") {

        setTwoWheelerBaseDetails(JSON.parse(response.data[0].Two_Wheeler_Dynamic_Fee_Details)[0]);
        dispatch(setTwoDelBaseId(JSON.parse(response.data[0].Two_Wheeler_Dynamic_Fee_Details)[0].two_del_fee_id));
      }


    })
    .catch((error) => {
      console.log(error)
    })


  }
  const getAdminFeeEngineDetails = () =>{
    api.getData('/api/delivery_fee_engine/get_admin_fee_engine_details?city_id='+response?.city_id+'&country_id='+response?.country_id+'&two_del_fee_id='+two_del_base_id+'&three_del_fee_id='+three_del_base_id+'&two_del_fee_dynamic_id='+two_del_dynamic_id+'&three_del_fee_dynamic_id='+three_del_dynamic_id)

    .then((response) => {

      if (response.data[0]?.Status === "200") {

         setAdminDeliveryFeeDetails(JSON.parse(response.data[0].Admin_Delivery_Fee_Details)[0]);
      }


    })
    .catch((error) => {
      console.log(error)
    })


  }
  const geThreeWheelerBaseDetails = () =>{
    api.getData('/api/delivery_fee_engine/get_three_wheeler_base_details?city_id='+response?.city_id+'&country_id='+response?.country_id)

    .then((response) => {

      if (response.data[0]?.Status === "200") {

        setThreeWheelerBaseDetails(JSON.parse(response.data[0].three_Wheeler_Dynamic_Fee_Details)[0]);
        dispatch(setTwoDelBaseId(JSON.parse(response.data[0].Two_Wheeler_Dynamic_Fee_Details)[0].three_del_fee_id));

      }


    })
    .catch((error) => {
      console.log(error)
    })


  }
  useEffect(()=>{
    getTwoWheelerBaseDetails();
    geThreeWheelerBaseDetails();
  },[])

  const adminDeliveryFeeInsertUpdat = (values) => {
   
      api.postData('/api/delivery_fee_engine/admin_delivery_fee_insert_update',values).then((response) => {
            if(response.data[0].Status === "200"){
              showSwalWithLink(response.data[0].Message);
            }
         
        }).catch(error => {
          console.error(error);
        });

    
 
    
  };
  const deleteTwoWheeler = (values) => {
    const data = {
      'country_id': values.country_id,
      'city_id': values.city_id,
      'two_del_fee_id': values.two_del_fee_id,
      'two_del_fee_dynamic_id': values.two_del_fee_dynamic_id     
    }
      api.postData('/api/delivery_fee_engine/delete_admin_two_wheeler_dynamic_del_config',data).then((response) => {
            if(response.data[0].Status === "200"){
              showSwalWithLink(response.data[0].Message);
              fetchDynamicTwoWheeler();
            }
         
        }).catch(error => {
          console.error(error);
        });

    
 
    
  };
  const deletethreeWheeler = (values) => {
    const data = {
      'country_id': values.country_id,
      'city_id': values.city_id,
      'three_del_fee_id': values.three_del_fee_id,
      'three_del_fee_dynamic_id': values.three_del_fee_dynamic_id     
    }
      api.postData('/api/delivery_fee_engine/delete_admin_three_wheeler_dynamic_del_config',data).then((response) => {
          //  console.log('response',response);
            if(response.data[0].Status === "200"){
              showSwalWithLink(response.data[0].Message);
              fetchDynamicThreeWheeler();
            }
         
        }).catch(error => {
          console.error(error);
        });

    
 
    
  };

   const initialValues= {
        'two_del_fee_id': twoWheelerBasedetails?.two_del_fee_id || '0',
        'country_id': response.country_id,
        'city_id': cityId?cityId:response.city_id,
        'base_delivery_charge': twoWheelerBasedetails?.base_delivery_charge || '',
        'base_pick_drop_delivery_charge': twoWheelerBasedetails?.base_pick_drop_delivery_charge || '',
        'default_km': twoWheelerBasedetails?.default_km || '',
        'addl_charge_per_km': twoWheelerBasedetails?.addl_charge_per_km || '',
        'stop_over_charge': twoWheelerBasedetails?.stop_over_charge || '',
        'gst': twoWheelerBasedetails?.gst || '' 
      
      
    }
    // validationSchema: RegisterSchema,
    const formik = useFormik({
      initialValues,
      validationSchema:baseTwoWheeler,
      onSubmit: (values) => {
        // Handle form submission
        handleSubmit(values);
      },
    });
  
    useEffect(() => {
      formik.setValues(initialValues);
    }, [twoWheelerBasedetails]);

    // console.log('twoWheeler',twoWheeler);
    const initialValues1= {
      'two_del_fee_dynamic_id': twoWheeler?.two_del_fee_dynamic_id || '0',
      'two_del_fee_id': twoWheeler?.two_del_fee_id || twoWheelerBaseDelFeeId,
      'country_id': twoWheeler?.country_id || response.country_id,
      'city_id': twoWheeler?.city_id|| cityId?cityId:response.city_id,
      'from_amount': twoWheeler?.from_amount ||'',
      'to_amount': twoWheeler?.to_amount || '',
      'delivery_fee': twoWheeler?.delivery_fee || ''      

  }
    const formik1 = useFormik({
      initialValues1,
      validationSchema: dynamicTwoWheeler,
      onSubmit:(values) =>{
        handleSubmit2(values);
      },
    });

    useEffect(() => {
      formik1.setValues(initialValues1);
    }, [twoWheeler]);

    const initialValues3= {
      'three_del_fee_id': threeWheelerBaseDetails?.three_del_fee_id || '0',
      'country_id': threeWheelerBaseDetails?.country_id || response.country_id,
      'city_id': threeWheelerBaseDetails?.city_id || cityId,
      'base_delivery_charge': threeWheelerBaseDetails?.base_delivery_charge || '',
      'base_pick_drop_delivery_charge': threeWheelerBaseDetails?.base_pick_drop_delivery_charge || '',
      'default_km': threeWheelerBaseDetails?.default_km || '',
      'addl_charge_per_km':threeWheelerBaseDetails?.addl_charge_per_km || '',
      'stop_over_charge': threeWheelerBaseDetails?.stop_over_charge || '',
      'gst':threeWheelerBaseDetails?.gst || ''       
    
    
  }
  const formik3 = useFormik({
    initialValues3,
    validationSchema: baseThreeWheeler,
    onSubmit: (values)=>{handleSubmitThreeWheeler(values);},
  });

  useEffect(() => {
    formik3.setValues(initialValues3);
  }, [threeWheelerBaseDetails]);
  const initialValues4 = {
    'three_del_fee_dynamic_id': threeWheelerDynamicData?.three_del_fee_dynamic_id || '0',
    'three_del_fee_id': threeWheelerDynamicData?.three_del_fee_id || threeWheelerBaseDelFeeId,
    'country_id': threeWheelerDynamicData?.country_id || response.country_id,
    'city_id': threeWheelerDynamicData?.city_id || cityId?cityId:response.city_id,
    'from_amount': threeWheelerDynamicData?.from_amount || '',
    'to_amount': threeWheelerDynamicData?.to_amount || '',
    'delivery_fee': threeWheelerDynamicData?.delivery_fee || '' 
         
  
  
}
  const formik4 = useFormik({
    initialValues4,
    validationSchema: dynamicThreeWheeler,
    onSubmit:(values)=>{handleSubmitThreeWheelerDel(values);},
  });

  useEffect(() => {
    formik4.setValues(initialValues4);
  }, [threeWheelerDynamicData]);

  const initialValues5 = {
    'country_id':adminDeliveryFeeDetails?.country_id || response.country_id,
    'city_id': adminDeliveryFeeDetails?.city_id || cityId,
    'two_del_fee_id': adminDeliveryFeeDetails?.two_del_fee_id || '1',
    'two_del_fee_dynamic_id': adminDeliveryFeeDetails?.two_del_fee_dynamic_id || '1',
    'three_del_fee_id': adminDeliveryFeeDetails?.three_del_fee_id || '1',
    'three_del_fee_dynamic_id': adminDeliveryFeeDetails?.three_del_fee_dynamic_id || '1',
    'merchant_convenience_fee': adminDeliveryFeeDetails?.merchant_convenience_fee || '',
    'pickup_drop_convenience_fee': adminDeliveryFeeDetails?.pickup_drop_convenience_fee || '',
    'late_night_hours_from': adminDeliveryFeeDetails?.late_night_hours_from || '',
    'late_night_hours_to': adminDeliveryFeeDetails?.late_night_hours_to || '',
    'late_night_fee': adminDeliveryFeeDetails?.late_night_fee || '',
    'minimum_order_amount': adminDeliveryFeeDetails?.minimum_order_amount || '',
    'minimum_order_fee': adminDeliveryFeeDetails?.minimum_order_fee || '',
    'cod_order_limit_for_customer': adminDeliveryFeeDetails?.cod_order_limit_for_customer || '',
    'ease_cash_percentage': adminDeliveryFeeDetails?.ease_cash_percentage || ''
       

}
  const formik5 = useFormik({
    initialValues5
    ,
    validationSchema: adminFee,
    onSubmit: adminDeliveryFeeInsertUpdat,
  });
  useEffect(() => {
    formik5.setValues(initialValues5);
  }, [adminDeliveryFeeDetails]);

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const eiColumns = ["ID", "Location", "Name"];
  const eiData = [
    ["#963258", "Srikakulam", "Ramya"],
    ["#963258", "Srikakulam", "Ramya"],
    ["#856214", "Vizianagaram", "Srilekha"],
  ];
  const mlColumns = [
    "DATE",
    "TYPE",
    "TASK",
    "LIFE OF REPAIR",
    "COST",
    "COMMENTS",
  ];
  const ulData = [
    ["11/12/2021", "Hialeah", "09:00", "19:00", "12 Equipment", "12 Equipment"],
  ];
  const ulColumns = [
    "DATE",
    "EMPLOYEE",
    "START TIME",
    "END TIME",
    "PRE TRIP CHECK",
    "POST TRIP CHECK",
  ];
  const mlData = [
    [
      "11/12/2021",
      "Brakes",
      "Fail",
      "HandBrake Fail",
      "6 months",
      "$ 120",
      "Hand Brake Issue",
    ],
  ];
  const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
  };

  const chargeColumns = [
    { name: "From", options: { filterOptions: { fullWidth: true } } },
    "To",
  ];

  const chargeOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
  };
  const equipmentoptions = {
    filter: true,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: true,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,

    page: 1,
  };
  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: false,
  };
  const fetchData = () => {
    api.getData('/api/delivery_fee_engine/dashboard_cities_status?countryid='+response?.country_id)
      .then((response) => {

        if (response.data[0]?.Status === "200") {

          setCities(JSON.parse(response.data[0].Cities).filter((item) => {
            return item.city_state === 'Andhra Pradesh'
          }));
          setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
 
  const fetchDynamicTwoWheeler = (twoDelFeeId) => {
    // api.getData('/api/delivery_fee_engine/get_two_wheeler_dynamic_details?country_id='+response.country_id+'&city_id='+(cityId?cityId:response.city_id)+'&two_del_fee_id='+twoWheelerBaseDelFeeId?twoWheelerBaseDelFeeId:'1')
    api.getData('/api/delivery_fee_engine/get_two_wheeler_dynamic_details?city_id='+response?.city_id+'&country_id='+response?.country_id+'&two_del_fee_id='+twoDelFeeId)

      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setTwoWheeler(JSON.parse(response.data[0].Two_Wheeler_Dynamic_Fee_Details)[0]);
          setTwoWheelerDynamic(JSON.parse(response.data[0].Two_Wheeler_Dynamic_Fee_Details));
          setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
  useEffect(()=>{
   
  },[twoWheeler]);

  const fetchDynamicThreeWheeler = () => {
    api.getData('/api/delivery_fee_engine/get_three_wheeler_dynamic_details?country_id='+response.country_id+'&city_id='+(cityId?cityId:response.city_id)+'&three_del_fee_id='+threeWheelerBaseDelFeeId)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
             setThreeWheeler(JSON.parse(response?.data[0]?.three_Wheeler_Dynamic_Fee_Details));
             setThreeWheelerDynamicData(JSON.parse(response?.data[0]?.three_Wheeler_Dynamic_Fee_Details)[0]);
          setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
  useEffect(()=>{
    if(twoWheelerBasedetails){
    fetchDynamicTwoWheeler(twoWheelerBasedetails.two_del_fee_id);
    }
  },[twoWheelerBasedetails]);

  useEffect(()=>{
    if(threeWheelerBaseDetails){
    fetchDynamicTwoWheeler(threeWheelerBaseDetails.three_del_fee_id);
    }
  },[threeWheelerBaseDetails]);
 
  useEffect(()=>{
    fetchData();
   },[])

   useEffect(()=>{

   },[twoWheelerBasedetails])
  const data = [
    ["Coffee", "Beverages", "Bru coffee", "21 Aug 2021", "86", "₹ 200.00"],
    ["Coffee", "Beverages", "Bru coffee", "21 Aug 2021", "86", "₹ 200.00"],
  ];
 

  const {
    values,
    touched,
    errors,
    isSubmitting,
    // handleChange,
    handleBlur,
  } = formik;

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />

              <CardContent className="bg-white">
                <Box
                  mb={2}
                  mt={2}
                  className="filters box-shadow sm-selects bg-light p-15"
                >
                  <Box className="w-100">
                    <Select
                      labelId="demo-isolation-label"
                      id="isolation-select"
                      className="select select-equipment float-right"
                      // onChange={handleSelectOxygen}
                      // input={<OutlinedInput />}
                      defaultValue={response?.city_id}
                      name="city_id"
                            onChange={handleCity}
                            
                          >
                           {
                              cities?.map((item) => {

                                return <MenuItem value={item.city_id}>{item.city_name}</MenuItem>


                              })
                            }
                    </Select>
                  </Box>
                </Box>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            2 WHEELER DELIVERY DETAILS
                          </Typography>
                        </Box>
                        
                            <form onSubmit={formik.handleSubmit} className="w-100">
                            <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Base Delivery Charge
                              </Typography>
                              
                              <OutlinedInput
                                name="base_delivery_charge"
                                onChange={(e)=>handleChange(e)}
                                value={formik.values?.base_delivery_charge}
                                onBlur={(e) => { handleBlur(e) }}
                                placeholder="Delivery Charge"
                              />
                                {errors.base_delivery_charge && touched.base_delivery_charge && <Box Typography="small" className="float-left text-danger">{errors.base_delivery_charge}</Box>}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Base Pick & drop Delivery Charge
                              </Typography>
                              <OutlinedInput
                                 name="base_pick_drop_delivery_charge"
                                 onChange={(e)=>handleChange(e)}
                                value={formik.values.base_pick_drop_delivery_charge}
                                 onBlur={(e) => { handleBlur(e) }}
                                placeholder="Delivery Charge"
                              />
                                {errors.base_pick_drop_delivery_charge && touched.base_pick_drop_delivery_charge && <Box Typography="small" className="float-left text-danger">{errors.base_pick_drop_delivery_charge}</Box>}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Default Kms
                              </Typography>
                              <OutlinedInput
                                name="default_km"
                                onChange={handleChange}
                                value={formik.values?.default_km}
                                onBlur={(e) => { handleBlur(e) }}
                                placeholder="Default Kms"
                              />
                                {errors.default_km && touched.default_km && <Box Typography="small" className="float-left text-danger">{errors.default_km}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Additional Charge Per Km
                              </Typography>
                              <OutlinedInput
                                name="addl_charge_per_km"
                                onChange={handleChange}
                                value={formik.values?.addl_charge_per_km}
                                onBlur={(e) => { handleBlur(e) }}
                                placeholder="Charge Per Km"
                              />
                                {errors.addl_charge_per_km && touched.addl_charge_per_km && <Box Typography="small" className="float-left text-danger">{errors.addl_charge_per_km}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                StopOver Charge
                              </Typography>
                              <OutlinedInput
                                name="stop_over_charge"
                                onChange={handleChange}
                                value={formik.values?.stop_over_charge}
                                onBlur={(e) => { handleBlur(e) }}
                                placeholder="StopOver Charge"
                              />
                                {errors.stop_over_charge && touched.stop_over_charge && <Box Typography="small" className="float-left text-danger">{errors.stop_over_charge}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">GST</Typography>
                              <OutlinedInput 
                                name="gst"
                                value={formik.values?.gst}
                                onChange={handleChange}
                                onBlur={(e) => { handleBlur(e) }}
                              placeholder="Gst" />
                            </FormControl>
                            {errors.gst && touched.gst && <Box Typography="small" className="float-left text-danger">{errors.gst}</Box>}

                          </Grid>
                          <Grid item xs={12} md={12} textAlign={"center"}>
                            <Button className="btn-outline-primary" type="submit">Submit</Button>
                          </Grid>
                          </Grid>
                          </form>
                          <Grid item xs={12} md={12} mt={1}>
                            <Box mt={1} mb={1}>
                              <Typography className="t-heading">
                                Dynamic Delivery Charge
                              </Typography>
                            </Box>
                          </Grid>
                          <form onSubmit={formik1.handleSubmit} className="w-100">
                           <Grid container spacing={3}>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">From</Typography>
                              
                              <OutlinedInput
                                 name='from_amount'
                                 onBlur={(e) => { formik1.handleBlur(e) }}
                                 onChange={(e)=>formik1.handleChange(e)}
                                 value={formik1.values?.from_amount}
                                placeholder="Min Base Delivery Charge"
                              />
                            {formik1.errors.from_amount && formik1.touched.from_amount && <Box Typography="small" className="float-left text-danger">{formik1.errors.from_amount}</Box>}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">To</Typography>
                              <OutlinedInput
                                name='to_amount'
                                onChange={(e)=>formik1.handleChange(e)}
                                 value={formik1.values?.to_amount}
                                onBlur={(e) => { formik1.handleBlur(e) }}
                                placeholder="Max Base Delivery Charge"
                              />
                            {formik1.errors.to_amount && formik1.touched.to_amount && <Box Typography="small" className="float-left text-danger">{formik1.errors.to_amount}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">Price</Typography>
                              <OutlinedInput
                                name='delivery_fee'
                                onChange={(e)=>formik1.handleChange(e)}
                                value={formik1.values?.delivery_fee}
                                onBlur={(e) => { formik1.handleBlur(e) }}
                                placeholder="Base Delivery Price"
                              />
                            {formik1.errors.delivery_fee && formik1.touched.delivery_fee && <Box Typography="small" className="float-left text-danger">{formik1.errors.delivery_fee}</Box>}

                            </FormControl>
                          </Grid>
                          </Grid>
                          <Grid item xs={12} md={12} textAlign={"center"} mt={2}>
                            <Button className="btn-outline-primary" type="submit">Add</Button>
                          </Grid>
                          </form>
                          <Grid item xs={12} md={12} mt={2}>
                            <MemberTable
                               options={pcOptions}
                              columns={twoWheelerColumns}
                              data={twoWheelerDynamic?twoWheelerDynamic:[]}
                            />
                          </Grid>
                        
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            3 WHEELER DELIVERY DETAILS
                          </Typography>
                        </Box>
                        <form onSubmit={formik3.handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Dynamic Delivery Charge
                              </Typography>
                              
                              <OutlinedInput
                                 name='base_delivery_charge'
                                 onChange={(e)=>formik3.handleChange(e)}
                                 value={formik3.values?.base_delivery_charge}
                                 onBlur={(e) => {formik3.handleBlur(e) }}
                                placeholder="Delivery Charge"
                              />
                            {formik3.errors.base_delivery_charge && formik3.touched.base_delivery_charge && <Box Typography="small" className="float-left text-danger">{formik3.errors.base_delivery_charge}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Base Pick & drop Delivery Charge
                              </Typography>
                              <OutlinedInput
                                 name='base_pick_drop_delivery_charge'
                                 value={formik3.values?.base_pick_drop_delivery_charge}
                                 onChange={(e)=>formik3.handleChange(e)}
                                 onBlur={(e) => {formik3.handleBlur(e) }}
                                placeholder="Delivery Charge"
                              />
                            {formik3.errors.base_pick_drop_delivery_charge && formik3.touched.base_pick_drop_delivery_charge && <Box Typography="small" className="float-left text-danger">{formik3.errors.base_pick_drop_delivery_charge}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Default Kms
                              </Typography>
                              <OutlinedInput
                               name='default_km'
                               onChange={formik3.handleChange}
                               value={formik3.values?.default_km}
                               onBlur={(e) => { formik3.handleBlur(e) }}
                                placeholder="Default Kms"
                              />
                            {formik3.errors.default_km && formik3.touched.default_km && <Box Typography="small" className="float-left text-danger">{formik3.errors.default_km}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Additional Charge Per Km
                              </Typography>
                              <OutlinedInput
                                name='addl_charge_per_km'
                                value={formik3.values?.addl_charge_per_km}
                                onChange={(e)=>formik3.handleChange(e)}
                                onBlur={(e) => {formik3.handleBlur(e) }}
                                placeholder="Charge Per Km"
                              />
                            {formik3.errors.addl_charge_per_km && formik3.touched.addl_charge_per_km && <Box Typography="small" className="float-left text-danger">{formik3.errors.addl_charge_per_km}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                StopOver Charge
                              </Typography>
                              <OutlinedInput
                                 name='stop_over_charge'
                                 value={formik3.values?.stop_over_charge}
                                 onChange={(e)=>formik3.handleChange(e)}
                                 onBlur={(e) => {formik3.handleBlur(e) }}
                                placeholder="StopOver Charge"
                              />
                            {formik3.errors.stop_over_charge && formik3.touched.stop_over_charge && <Box Typography="small" className="float-left text-danger">{formik3.errors.stop_over_charge}</Box>}
                              
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">GST</Typography>
                              <OutlinedInput 
                              name='gst'
                              value={formik3.values?.gst}
                              onChange={(e)=>formik3.handleChange(e)}
                              onBlur={(e) => {formik3.handleBlur(e) }}
                              placeholder="Gst" />
                            </FormControl>
                            {formik3.errors.gst && formik3.touched.gst && <Box Typography="small" className="float-left text-danger">{formik3.errors.gst}</Box>}

                          </Grid>
                          <Box className="w-100 text-center" mt={1} mb={1}>
                          <Button className="btn-outline-primary" type="submit">Submit</Button>
                          </Box>
                          </Grid>
                          </form>
                          <Grid item xs={12} md={12}>
                            <Box mb={2}>
                              <Typography className="t-heading">
                                Dynamic Delivery Charge
                              </Typography>
                            </Box>
                          </Grid>
                        <form onSubmit={formik4.handleSubmit}>
                        <Grid container spacing={3} mb={1}>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">From</Typography>
                              <OutlinedInput
                                  name='from_amount'
                                  value={formik4.values?.from_amount}
                                  onChange={(e)=>formik4.handleChange(e)}
                                  onBlur={(e) => { formik4.handleBlur(e) }}
                                placeholder="Min Base Delivery Charge"
                              />
                            {formik4.errors.from_amount && formik4.touched.from_amount && <Box Typography="small" className="float-left text-danger">{formik4.errors.from_amount}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">To</Typography>
                              <OutlinedInput
                               name='to_amount'
                               value={formik4.values?.to_amount}
                               onChange={(e)=>formik4.handleChange(e)}
                               onBlur={(e) => {formik4.handleBlur(e) }}
                                placeholder="Max Base Delivery Charge"
                              />
                            {formik4.errors.to_amount && formik4.touched.to_amount && <Box Typography="small" className="float-left text-danger">{formik4.errors.to_amount}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">Price</Typography>
                              <OutlinedInput
                                name='delivery_fee'
                                value={formik4.values?.delivery_fee}
                                onChange={(e)=>formik4.handleChange(e)}
                                onBlur={(e) => {formik4.handleBlur(e) }}
                                placeholder="Base Delivery Price"
                              />
                            {formik4.errors.delivery_fee && formik4.touched.delivery_fee && <Box Typography="small" className="float-left text-danger">{formik4.errors.delivery_fee}</Box>}

                            </FormControl>
                          </Grid>
                          </Grid>
                          <Grid item xs={12} md={12} textAlign={"center"}>
                            <Button className="btn-outline-primary" type="submit">Add</Button>
                          </Grid>
                          </form>
                          <Grid item xs={12} md={12} mt={2}>
                            <MemberTable
                                options={pcOptions}
                              columns={threeWheelerColumns}
                              data={threeWheeler?threeWheeler:[]}
                            />
                          </Grid>
                       
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={12}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            FEES ON TOTAL AMOUNTS
                          </Typography>
                        </Box>
                        <form onSubmit={formik5.handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid item md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Merchant ConvenienceFee
                              </Typography>
                              <OutlinedInput
                                 name='merchant_convenience_fee'
                                 value={formik5.values?.merchant_convenience_fee}
                                 onChange={formik5.handleChange}
                                 onBlur={(e) => { formik5.handleBlur(e) }}
                                placeholder="ConvenienceFee"
                              />
                            {formik5.errors.merchant_convenience_fee && formik5.touched.merchant_convenience_fee && <Box Typography="small" className="float-left text-danger">{formik5.errors.merchant_convenience_fee}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Pickup & drop ConvenienceFee
                              </Typography>
                              <OutlinedInput
                                 name='pickup_drop_convenience_fee'
                                 value={formik5.values?.pickup_drop_convenience_fee}
                                 onChange={formik5.handleChange}
                                 onBlur={(e) => {formik5.handleBlur(e) }}
                                placeholder="ConvenienceFee"
                              />
                            {formik5.errors.pickup_drop_convenience_fee && formik5.touched.pickup_drop_convenience_fee && <Box Typography="small" className="float-left text-danger">{formik5.errors.pickup_drop_convenience_fee}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Late Night Hours From
                              </Typography>
                              <OutlinedInput
                                 name='late_night_hours_from'
                                 value={formik5.values?.late_night_hours_from}
                                 onChange={formik5.handleChange}
                                 onBlur={(e) => {formik5.handleBlur(e) }}
                                type="time"
                                placeholder="Hours"
                              />
                            {formik5.errors.late_night_hours_from && formik5.touched.late_night_hours_from && <Box Typography="small" className="float-left text-danger">{formik5.errors.late_night_hours_from}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Late Night Hours To
                              </Typography>
                              <OutlinedInput
                                 name='late_night_hours_to'
                                 value={formik5.values?.late_night_hours_to}
                                 onChange={formik5.handleChange}
                                 onBlur={(e) => {formik5.handleBlur(e) }}
                                type="time"
                                placeholder="Hours"
                              />
                            {formik5.errors.late_night_hours_to && formik5.touched.late_night_hours_to && <Box Typography="small" className="float-left text-danger">{formik5.errors.late_night_hours_to}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item md={3}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Late Night Fee
                              </Typography>
                              <OutlinedInput 
                               name='late_night_fee'
                               value={formik5.values?.late_night_fee}
                               onChange={formik5.handleChange}
                               onBlur={(e) => {formik5.handleBlur(e) }}
                              placeholder="Fee" />
                            {formik5.errors.late_night_fee && formik5.touched.late_night_fee && <Box Typography="small" className="float-left text-danger">{formik5.errors.late_night_fee}</Box>}

                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            MIN ORDER CHARGES
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Minimum Order Amount
                              </Typography>
                              <OutlinedInput
                               value={formik5.values?.minimum_order_amount}
                                name='minimum_order_amount'
                                onChange={formik5.handleChange}
                                onBlur={(e) => {formik5.handleBlur(e) }}
                                placeholder="Order Amount"
                              />
                            {formik5.errors.minimum_order_amount && formik5.touched.minimum_order_amount && <Box Typography="small" className="float-left text-danger">{formik5.errors.minimum_order_amount}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Minimum Order Fee
                              </Typography>
                              <OutlinedInput 
                               name='minimum_order_fee'
                               value={formik5.values?.minimum_order_fee}
                               onChange={formik5.handleChange}
                               onBlur={(e) => {formik5.handleBlur(e) }}
                              placeholder="Order Fee" />
                            {formik5.errors.minimum_order_fee && formik5.touched.minimum_order_fee && <Box Typography="small" className="float-left text-danger">{formik5.errors.minimum_order_fee}</Box>}

                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Box mb={3}>
                          <Typography className="promo-subtitle" component="h5">
                            EASH CASH AND COD LIMIT
                          </Typography>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Cod Order Limit for Customer
                              </Typography>
                              <OutlinedInput
                                 name='cod_order_limit_for_customer'
                                 value={formik5.values?.cod_order_limit_for_customer}
                                 onChange={formik5.handleChange}
                                 onBlur={(e) => {formik5.handleBlur(e) }}
                                placeholder="Code Order Limit"
                              />
                            {formik5.errors.cod_order_limit_for_customer && formik5.touched.cod_order_limit_for_customer && <Box Typography="small" className="float-left text-danger">{formik5.errors.cod_order_limit_for_customer}</Box>}

                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                EaseCash Percentage
                              </Typography>
                              <OutlinedInput
                                 name='ease_cash_percentage'
                                 value={formik5.values?.ease_cash_percentage}
                                 onChange={formik5.handleChange}
                                 onBlur={(e) => {formik5.handleBlur(e) }}
                                placeholder="Percentage"
                              />
                            {formik5.errors.ease_cash_percentage && formik5.touched.ease_cash_percentage && <Box Typography="small" className="float-left text-danger">{formik5.errors.ease_cash_percentage}</Box>}

                            </FormControl>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                <Box className="w-100 text-center" mt={2}>
                  <Button
                    variant="outlined"
                    mt={2}
                    mb={2}
                    className="retail-btn bg-white"
                    mr={2}
                    type="submit"
                    // onClick={() => ref3.current.log()}
                  >
                    Submit
                  </Button>
                </Box>
                </form>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
                
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <LocationModal ref={ref4} />
      </Box>
    </>
  );
}
