import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import { LoginSchema } from './Validation';
import api from "./Api";
// import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUser } from "./Redux/actions/userActions";
import { Button } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));

const Login = ({ setUser }) => {

  const [message, setMessage] = React.useState();


  const dispatch = useDispatch();
  const navigate = useNavigate();
   const [email,setEmail] = React.useState('');
  const handleSubmit = (values) => {
    if(formik.values.user_name){
    setEmail(formik.values.user_name);
    }
        
    api.postData('/api/config/check_email', {'emailid':formik.values.user_name}).then((response) => {
      if (JSON.parse(response.data[0].Status === "200")) {
        setMessage("User Name not Exits please Register Before Login");
        // setSpinner(false);
      } else{
    api.postData('/api/merchant/login', formik.values).then((response) => {
      console.log('welcome login',response);
     
      // response.data[0].Status = "200";
      if (response.data[0].Status === "200") {
        

        dispatch({type:'SET_USER',payload:JSON.parse(response.data[0].Merchant_Details)?.length>0?JSON.parse(response.data[0].Merchant_Details)[0]:JSON.parse(response.data[0].Merchant_Details)});
        // dispatch(setUser(JSON.parse(response.data[0].Merchant_Details)[0]));
        
        navigate('/dashboard');
      } else {
        console.log('JSON.parse(response.data[0].Message)', response.data[0].Message)
         
            setMessage(response.data[0].Message);
            console.log('email',email)
            if(response.data[0].Message === "Admin Phone Number is not verified, Login Not Allowed...!!!"){
            setTimeout(()=>{
            navigate('/verifyMobile',{ state: { email: email?email:formik.values.user_name } })},2000);
            }
       
       
      
    }
  });
}
    })
  };


  const formik = useFormik({
    initialValues: {
      'user_name': "",
      'password': "",
      "environment":process.env.REACT_APP_MY_TITLE
    },
    validationSchema: LoginSchema,
    onSubmit: handleSubmit,
  });
  const handleData = (event) => {

    console.log('event.target', event.target);
    const { name, value } = event.target;
    const updatedValues = {
      ...formik.values,
      [name]: value,
    };


    formik.setValues(updatedValues);

    console.log('formik.initialValues', formik.values)
  };

  React.useEffect(() => {
    // fetchData();
  }, [message])
  const [logo,setLogo] = React.useState();
  React.useEffect(() => {
    // Update favicon based on condition
    if (process.env.REACT_APP_MY_TITLE === 'Bazaar') {
      setLogo(process.env.REACT_APP_MY_LOGO_URL);
    } else if(process.env.REACT_APP_MY_TITLE === 'Vikretha'){
      setLogo(process.env.REACT_APP_MY_LOGO_URL_VIKRETA);
    }
  }, []);

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
  } = formik;

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box className="Absolute-Box text-center">
          <Box sx={{ minWidth: 275, paddingTop: 3 }}>

            <Card variant="outlined">
              <Box src={logo} className="App-logo" alt="logo" component='img' mt={3} />
              <CardContent className="card-content text-center">
                <Typography
                  className="clr-primary mt-3 fw-bold"
                  variant="h5"
                  gutterBottom
                >
                  Login
                </Typography>

                <Box className="">
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl className="w-100" variant="standard">
                      <InputLabel shrink htmlFor="username-input">
                        Username
                      </InputLabel>
                      <BootstrapInput
                        fullWidth
                        id="username-input"
                        name="user_name"
                        className="mb-3"
                        onChange={handleChange}
                        onBlur={(e) => { handleBlur(e); handleData(e) }}
                      />
                    </FormControl>
                    {errors.user_name && touched.user_name && <Box Typography="small" className="float-left text-danger">{errors.user_name}</Box>}
                    <FormControl className="w-100 mt-10" variant="standard">
                      <InputLabel shrink htmlFor="password-input">
                        Password
                      </InputLabel>
                      <BootstrapInput
                        fullWidth
                        id="password-input"
                        name="password"
                        type="password"
                        onChange={handleChange}
                        onBlur={(e) => { handleBlur(e); handleData(e) }}
                      />
                    </FormControl>
                    {errors.password && touched.password && <Box Typography="small" className="float-left text-danger">{errors.password}</Box>}
                    <Box className="w-100 text-center" mt={2}>
                      {message ? <Box Typography="small" className="text-danger">{message}</Box> : ''}
                    </Box>
                    <Box className="text-end mt-3">
                      <Box href="/forgotPassword" component='a' className="float-right">
                        <Typography className="frgt-pswrd" variant="body2">
                          Forgot Password ?
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="text-center w-100" mb={2}>
                      {/* <BootstrapInput component="input" variant="contained" className="mt-3 w-100 btn-submit py-2 mx-0" value="Login" type="submit">
                        Login
                      </BootstrapInput> */}
                      <Button
                      variant="contained"
                      className="mt-20 w-100 login-btn btn-primery _btn-outline active btn-clr-main py-2 mx-0"
                      type="submit"
                      value="login"
                    >
                      Login
                    </Button>
                    </Box>
                    <Box className="w-100 d-flex">
                    <Box
                      href="/register"
                      variant="outlined"

                      className="hvr-grow-shadow w-100 login-btn btn-primery  _btn-outline py-2 mx-0 mt-2"
                      component='a'
                    >
                      Register
                    </Box>
                    </Box>
                    <Box className="mt-3">
                      <Typography className="" variant="body2" gutterBottom>
                        Still facing issue ?<Box component='br' /> Contact{" "}
                        <Box href="tel:1234567890" className="frgt-pswrd" component='a'>
                          {" "}
                          +91-1234567890
                        </Box>{" "}
                        or Email{" "}
                        <Box href="mailto:someone@example.com" className="frgt-pswrd" component='a'>
                          {" "}
                          noreply@gmail.com
                        </Box>
                      </Typography>
                    </Box>

                  </form>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}
// export default connect(null, { setUser })(Login);
export default Login;
// export default App;
