import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import api from "../Api";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { AddProduct } from "../Validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddProductModal = forwardRef((props, ref2, ref4) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () =>{ setOpen(false);}
   let measurementId = useSelector((state) => state.user.measurement_id);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);
  const MySwal = withReactContent(Swal);
  const response = useSelector((state) => state.user.user);
  const ids = useSelector((state) => state.user);
  const [persihable, setPerishable] = React.useState(false);
  const changePerishable = () => {
    setPerishable((persihable) => !persihable);
  };
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      // console.log(props);
      // props?.onHandle(true);
      handleClose();
    });
  };
  const showSwalWithLinkError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    })
  };
  const handleSubmit = async (values, { setSubmitting }) => {
    let data = {
      product_stock_id:  "0",
      product_id: props.itemStock?.product_id || "",
      merchant_id: props.itemStock?.merchant_id || "",
      measurement_id: props.itemStock?.measurement_id || props.itemStock?.measurementId || props.measurement || "",
      product_batch_id: props.itemStock?.product_batch_id || "",
      product_is_perishable: props.itemStock?.product_is_perishable || "10",
      product_idle_stock_days: props.itemStock?.product_idle_stock_days || "",
      product_low_stock_qty: props.itemStock?.product_low_stock_qty || "",
      product_vendor_name: props.itemStock?.product_vendor_name || "",
      po_number:props.itemStock?.po_number || "",
      po_date:props.itemStock?.po_date || "",
      product_qty_purchased: props.itemStock?.product_qty_purchased || "",
      product_qty_sold: props.itemStock?.product_qty_sold || "0",
      product_qty_removed: props.itemStock?.product_qty_removed || "0",
      product_qty_blocked: props.itemStock?.product_qty_blocked || "0",
      product_purchase_date: props.itemStock?.product_purchase_date || "",
      product_purchase_description:
        props.itemStock?.product_purchase_description || "",
      product_msr_price: props.itemStock?.product_msr_price || "0",
      product_cost_price: props.itemStock?.product_cost_price || "",
      product_sale_price: props.itemStock?.product_sale_price || "0",
      product_expiry_date: props.itemStock?.product_expiry_date || "",
      product_expiry_reminder_days:
        props.itemStock?.product_expiry_reminder_days || "",
      product_stock_status: props.itemStock?.product_stock_status || "0",
    };
  
    if (data.measurement_id) {
      data.measurement_id = props.itemStock?.measurement_id || props.itemStock?.measurementId || props.measurement || measurementId || ids?.measurement_id;
    }
    if (!formik.values.product_id) {
      data.product_id = ids?.product_id;
    }
    if (formik.values.product_vendor_name) {
      data.product_vendor_name = formik.values.product_vendor_name;
    }
    if (formik.values.po_number) {
      data.po_number = formik.values.po_number;
    }
    if (formik.values.po_date) {
      data.po_date = formik.values.po_date;
    }
    if (formik.values.product_qty_purchased) {
      data.product_qty_purchased = formik.values.product_qty_purchased;
    }
    if (formik.values.po_number) {
      data.po_number = formik.values.po_number;
    }
    if (formik.values.product_cost_price) {
      data.product_cost_price = formik.values.product_cost_price;
    }
    if (formik.values.product_purchase_date) {
      data.product_purchase_date = formik.values.product_purchase_date;
    }
    if (formik.values.product_purchase_description) {
      data.product_purchase_description = formik.values.product_purchase_description;
    }
    if (formik.values.product_expiry_date) {
      data.product_expiry_date = formik.values.product_expiry_date;
    }
    if (formik.values.product_expiry_reminder_days) {
      data.product_expiry_reminder_days = formik.values.product_expiry_reminder_days;
    }
    if (!formik.values.product_batch_id) {
      data.product_batch_id = Math.floor(Math.random() * 900) + 100;
    }
    if (!formik.values.merchant_id) {
      data.merchant_id = response?.merchant_id;
    }
    if (!formik.values.product_is_perishable) {
      data.product_is_perishable = persihable ? 1 : 0;
    }
    if (!formik.values.product_idle_stock_days && props.idleStock) {
      data.product_idle_stock_days = props.productIdleStockDays
        ? props.productIdleStockDays
        : 0;
    }
    if (!formik.values.product_low_stock_qty && props.lowStock) {
      data.product_low_stock_qty = props.productLowStockQty
        ? props.productLowStockQty
        : 0;
    }
    if (!formik.values.product_qty_sold) {
      data.product_qty_sold = 0;
    }
    if (!formik.values.product_qty_removed) {
      data.product_qty_removed = 0;
    }
    if (!formik.values.product_qty_blocked) {
      data.product_qty_blocked = 0;
    }
     console.log('props.itemStock',props.itemStock);
     console.log('props.itemStock',formik.values);
     console.log('data',data);
    try {
      api
        .postData("/api/items/stock_details_insert_update", data)
        .then((response) => {
          if (response.data[0]?.Status === "200") {
            showSwalWithLink(response.data[0].Message);
            console.log('props.itemStock?.product_id',props.itemStock?.product_id);
            console.log('props.itemStock?.measurement_id',props.itemStock?.measurement_id);
            props?.getItemStockDetailsProduct(props.itemStock?.product_id,props.itemStock?.measurement_id);
            // setSuccess(true);
          }else{
            showSwalWithLinkError(response.data[0].Message);
          }
        });
    } catch (error) {
      // Handle network error
    }

    setSubmitting(false);
  };

  const initialValues = {
    product_stock_id: props.itemStock?.product_stock_id || "0",
    product_id: props.itemStock?.product_id || "",
    merchant_id: props.itemStock?.merchant_id || "",
    measurement_id: props.itemStock?.measurement_id || props.itemStock?.measurementId || "",
    product_batch_id: props.itemStock?.product_batch_id || "",
    product_is_perishable: props.itemStock?.product_is_perishable || "10",
    product_idle_stock_days: props.itemStock?.product_idle_stock_days || "",
    product_low_stock_qty: props.itemStock?.product_low_stock_qty || "",
    product_vendor_name: props.itemStock?.product_vendor_name || "",
    po_number:props.itemStock?.po_number || "",
    po_date:props.itemStock?.po_date || "",
    product_qty_purchased: props.itemStock?.product_qty_physical || "",
    product_qty_sold: props.itemStock?.product_qty_sold || "0",
    product_qty_removed: props.itemStock?.product_qty_removed || "0",
    product_qty_blocked: props.itemStock?.product_qty_blocked || "0",
    product_purchase_date: props.itemStock?.product_purchase_date || "",
    product_purchase_description:
      props.itemStock?.product_purchase_description || "",
    product_msr_price: props.itemStock?.product_msr_price || "0",
    product_cost_price: props.itemStock?.product_cost_price || "",
    product_sale_price: props.itemStock?.product_sale_price || "0",
    product_expiry_date: props.itemStock?.product_expiry_date || "",
    product_expiry_reminder_days:
      props.itemStock?.product_expiry_reminder_days || "",
    product_stock_status: props.itemStock?.product_stock_status || "0",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: AddProduct,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    // formik.setValues(initialValues);
    if(props.itemStock?.product_expiry_reminder_days && props.itemStock?.product_expiry_reminder_days === undefined){
      
      setPerishable(true);
    }
  }, [props.itemStock]);

  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;
  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const handleData = (event) => {
    const { name, value } = event.target;
    // Yup.reach(RegisterSchema, name).validate(value);
    const updatedValues = {
      ...formik.values,
      [name]: value,
    };
    formik.setValues(updatedValues);
  };
  useImperativeHandle(ref2, () => ({
    log() {
      handleOpen();
      formik.setValues({});
      formik.setTouched({}, false);
    },
  }));

  useImperativeHandle(ref4, () => ({
    log() {
      handleOpen();
      formik.setValues({});
      formik.setTouched({}, false);
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product ">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              PRODUCT DETAILS
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          {/* <Box className="ml-20" mt={2} mb={1}>
                        <Typography>SKU DETAILS</Typography>
                    </Box> */}
          <form onSubmit={formik.handleSubmit}>
            {/* <Grid container spacing={3} mb={2} className=' flex align-center' >

                        <Grid item xs={3} sm={4} md={4} className='flex align-center'>
                            <Box className="information-div">
                                <FormControl className='w-100'>
                                    <Typography component="label">ID</Typography>
                                    <OutlinedInput onChange={(e) => handleChange(e)}
                                        onBlur={(e) => { handleBlur(e); handleData(e) }} placeholder='Sku Id'  value={formik.values?.product_complete_sku?formik.values.product_complete_sku.replace(/-/g, '')+ '-xxx' :ids?.product_sku?ids.product_sku.replace(/-/g, '')+ '-xxx':''  } name='product_complete_sku' />
                                </FormControl>


                            </Box>
                            <Box>
                                <Typography component='label' className='ml-20'>OR</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={3} md={3} >
                            <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right' onClick={handleClose}>Generate Id</Button>

                        </Grid>
                        <Grid item xs={2} sm={3} md={3} className='flex-between'>
                            <Box>
                                <Typography component='label' className='ml-20'>OR</Typography>
                            </Box>
                            <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right' onClick={handleClose}>Scan Id</Button>

                        </Grid>


                    </Grid>


                    <Divider></Divider> */}
            <Box className="ml-20" mt={2}>
              <Typography component="h4" className="sub_title">
                SUPPLIER & QUANTITY
              </Typography>
            </Box>

            <Grid container spacing={3} className="p-15">
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Supplier Name</Typography>
                  <OutlinedInput
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    value={formik.values?.product_vendor_name}
                    placeholder="Supplier Name"
                    name="product_vendor_name"
                  />
                </FormControl>
                {formik.errors.product_vendor_name &&
                  formik.touched.product_vendor_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {formik.errors.product_vendor_name}
                    </Box>
                  )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">PO Number</Typography>
                  <OutlinedInput
                    name="po_number"
                    value={formik.values?.po_number}
                    placeholder="PO Number"
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                </FormControl>
                {formik.errors.po_number &&
                  formik.touched.po_number && (
                    <Box Typography="small" className="float-left text-danger">
                      {formik.errors.po_number}
                    </Box>
                  )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">PO Date</Typography>
                  <OutlinedInput
                    name="po_date"
                    value={formik.values?.po_date}
                    placeholder="Quantity"
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    type="date"
                  />
                </FormControl>
                {formik.errors.po_date &&
                  formik.touched.po_date && (
                    <Box Typography="small" className="float-left text-danger">
                      {formik.errors.po_date}
                    </Box>
                  )}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="p-15">
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Quantity</Typography>
                  <OutlinedInput
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    value={formik.values?.product_qty_purchased}
                    placeholder="Quantity"
                    name="product_qty_purchased"
                  />
                </FormControl>
                {formik.errors.product_qty_purchased &&
                  formik.touched.product_qty_purchased && (
                    <Box Typography="small" className="float-left text-danger">
                      {formik.errors.product_qty_purchased}
                    </Box>
                  )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Cost Price</Typography>
                  <OutlinedInput
                    name="product_cost_price"
                    value={formik.values?.product_cost_price}
                    placeholder="Cost Price"
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                </FormControl>
                {formik.errors.product_cost_price &&
                  formik.touched.product_cost_price && (
                    <Box Typography="small" className="float-left text-danger">
                      {formik.errors.product_cost_price}
                    </Box>
                  )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Date</Typography>
                  <OutlinedInput
                    name="product_purchase_date"
                    value={formik.values?.product_purchase_date}
                    placeholder="Quantity"
                    type="date"
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                </FormControl>
                {formik.errors.product_purchase_date &&
                  formik.touched.product_purchase_date && (
                    <Box Typography="small" className="float-left text-danger">
                      {formik.errors.product_purchase_date}
                    </Box>
                  )}
              </Grid>
            </Grid>
           <Grid container spacing={1} className="p-15">
            <Grid item xs={2} sm={12} md={12}>
             
                <FormControl className="w-100">
                  <Typography component="label">Description</Typography>
                  <TextareaAutosize
                    name="product_purchase_description"
                    value={formik.values?.product_purchase_description}
                    minRows={6}
                    className="br-6"
                    onChange={handleChange}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {formik.errors.product_purchase_description &&
                    formik.touched.product_purchase_description && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {formik.errors.product_purchase_description}
                      </Box>
                    )}
                </FormControl>
              
            </Grid>
            </Grid>

            <Divider></Divider>
            <Box className="ml-20" mt={2}>
              <Typography component="h4" className="sub_title">
                NOTIFICATION
              </Typography>
            </Box>

            <Grid container spacing={3} className="p-15">
              <Grid item xs={2} sm={12} md={3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={persihable}
                        onChange={() => {
                          changePerishable();
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        name="product_is_perishable"
                      />
                    }
                    label="Perishable"
                  />
                  {formik.errors.product_is_perishable &&
                    formik.touched.product_is_perishable && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {formik.errors.product_is_perishable}
                      </Box>
                    )}
                </FormGroup>
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <Box className="information-div">
                  <FormControl className="flex flex-row">
                    <Typography component="label">Expiry Date</Typography>
                    <OutlinedInput
                      type="date"
                      value={formik.values?.product_expiry_date}
                      name="product_expiry_date"
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={2} sm={12} md={5}>
                <Box className="information-div">
                  <FormControl className="flex flex-row">
                    <Typography component="label">Expiry Reminder</Typography>
                    <OutlinedInput
                      onChange={handleChange}
                      onBlur={(e) => {
                        handleBlur(e);
                      }}
                      value={formik.values?.product_expiry_reminder_days}
                      name="product_expiry_reminder_days"
                    />
                    <Typography component="label">Days</Typography>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Divider></Divider>
            <Box className="p-15">
              <Button
                variant="outlined"
                color="error"
                mt={2}
                mb={3}
                className="retail-btn float-right"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
});

export default AddProductModal;
