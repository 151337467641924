import React, { useImperativeHandle, forwardRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import test from "../images/test.jpg";
import biryani from "../images/cb-shahi.jpg";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GroupedButtons from "./GroupedButtons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const MySwal = withReactContent(Swal);

const AddToCartModal = forwardRef((props, ref7) => {
  const [open, setOpen] = React.useState(false);
  const [passwd, setPasswd] = React.useState(false);
  const handlePassword = () => {
    setPasswd((passwd) => !passwd);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "All Fields Are Mandatory",
    });
  };

  useImperativeHandle(ref7, () => ({
    log() {
      handleOpen();
    },
  }));

  //   if(openM){
  //     handleOpen();
  //   }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className="parent-modal"
      >
        <Box sx={{ ...style }} className="profile-modal">
          <Grid container>
            <Grid item xs={12} className="profile-header p-15">
              <Typography id="parent-modal-title" component="h4">
                ADD ITEMS
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Grid>
          </Grid>
          <Divider></Divider>

          <Grid container className="details-div p-15" spacing={2}>
            <Grid item xs={2} sm={4} md={4}>
              <Box>
                <Box component="img" src={biryani} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box className="sub-details addto-cart">
                <Typography component="h4" className="tab-sub-title">
                  #BIR38890
                </Typography>
                <Typography component="h5">Chicken Shahi Biryani</Typography>
                <Typography component="label">
                  Authentic and Unique' are two words that best describe the
                  Calicut Chicken Biryani. It has such a rich and royal taste
                  that it certainly is a cuisine that is 'Drool Worthy'.
                </Typography>
                <Box className="flex" mt={2}>
                  <Box className="w-50">
                    <Typography component="p">Price</Typography>
                    <Typography component="label">₹350</Typography>
                  </Box>

                  <Box className="w-50">
                    <Typography component="p">Quantity</Typography>
                    <GroupedButtons />
                  </Box>
                </Box>
              </Box>
              <Box className="pos-card">
                <Button variant="contained">
                  <ShoppingCartIcon /> ADD TO Cart
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
});

export default AddToCartModal;
