import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Divider, OutlinedInput, TextareaAutosize, FormControlLabel, Switch } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import id from '../images/ID.svg';
import customers from '../images/customers.svg';
import mobile from "../images/mobile.svg";
import location from '../images/location.svg';
import status from '../images/order.svg';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import api from "../Api";
import { useFormik } from "formik";
import { useSelector, useDispatch } from 'react-redux';
import rupee from '../images/rupee.svg';
import mail from '../images/mail.svg';
import MemberTable from '../Customer/MemberTable';
import { customerEdit } from '../Validation';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const ViewMoreModalRetailEdit = forwardRef((props, ref3, ref4) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const response = useSelector(state => state.user.user);
    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleCloseAdd = () => setAddOpen(false);
    const MySwal = withReactContent(Swal);
    const showSwalWithLink = (message) => {
        MySwal.fire({
            icon: 'success',
            title: message,
        })
    };
    const showSwalWithLinkError = (message) => {
        MySwal.fire({
            icon: 'error',
            title: message,
        })
    };
    const memeberColumns = [
        { name: "First Name", options: { filterOptions: { fullWidth: true } } },
        "Last Name",
        "Email",
        "Password"
    ];

    const [codStatus,setCodStatus] = React.useState();
    const [blockStatus,setBlockStatus] = React.useState();

    const updateCodStatus = (data) => {

        api.postData('/api/customer_orders/update_cod_status', {
            'merchant_id': response?.merchant_id,
            'customer_id': data,
    
        }
        ).then((response) => {
         
            if (response.data.cod_status[0].Status === "200") {
                showSwalWithLink(response.data.cod_status[0].Message);
                // getCustomers();
                // setSuccess(true);
            }
    
        }).catch(error => {
            // Handle error
            console.error(error);
        });
    
    
    };
    
    const updateBlockStatus = (data) => {
    
      api.postData('/api/customer_orders/update_block_status', {
          'merchant_id': response?.merchant_id,
          'customer_id': data,
    
      }
      ).then((response) => {
          if (response.data.block_status[0].Status === "200") {
              showSwalWithLink(response.data.block_status[0].Message);
            //   getCustomers();
              // setSuccess(true);
          }
    
      }).catch(error => {
          // Handle error
          console.error(error);
      });
    
    
    };

    const handleSubmit = async (values, { setSubmitting }) => {

        let data = new FormData();
        data.append('customer_id', formik.values.customer_id);
        data.append('bazaar_id', process.env.REACT_APP_MY_TITLE ==='Vikretha'?0:1);
        data.append('merchant_id', response?.merchant_id);
        data.append('name_of_the_customer', formik.values.name_of_the_customer);
        data.append('email_id', formik.values.email_id);
       
        data.append('credit_limit', formik.values.credit_limit);
        data.append('city_id', formik.values.city_id);
        data.append('cust_address', formik.values.cust_address);
       



   
        try {
            api.updateData('/api/pos/Update_customers', data).then((response) => {
                if (response.data[0]?.Status === "200") {
                    showSwalWithLink(response.data[0]?.Message);
                    
                    
                }else{
                    showSwalWithLinkError(response.data[0]?.Message)
                }

            })


        } catch (error) {
            // Handle network error
        }

        setSubmitting(false);
    };


    const initialValues = {
        'customer_id': props.CustomerDetails?.customer_id || '',
        'name_of_the_customer': props.CustomerDetails?.name_of_the_customer || '',
        'email_id': props.CustomerDetails?.email_id || '',
        "phone_number":props.CustomerDetails?.phone_number || '',
        'customer_type': props.CustomerDetails?.customer_type || '',
        'cod_status': props.CustomerDetails?.cod_status || '',
        'cust_address': props.CustomerDetails?.cust_address || '',
        'credit_limit': props.CustomerDetails?.credit_limit || '',
        'block_status': props.CustomerDetails?.block_status || '',
        'cr_date': props.CustomerDetails?.cr_date || '',
        "city_id":props.CustomerDetails?.city_id || '',
        'last_activity': props.CustomerDetails?.last_activity || ''

    }
    const formik = useFormik({
        initialValues,
        validationSchema: customerEdit,
        onSubmit: handleSubmit,
    });
    useEffect(() => {
        formik.setValues(initialValues);
        setCodStatus(props.CustomerDetails?.cod_status);
        setBlockStatus(props.CustomerDetails?.block_status);
      }, [props.CustomerDetails]);
    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        // pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,

        textLabels: {
            body: {
                noMatch: 'No data available in table',

            }
        },
        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref3, () => ({
        log() {
            handleOpen();
        }
    }));

    useImperativeHandle(ref4, () => ({
        log() {
            handleOpen();
        }
    }));
    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
    } = formik;
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <form onSubmit={formik.handleSubmit}>
                <Box sx={style} className='view-more-content retail-edit'>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            CUSTOMERS DETAILS
                        </Typography>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                    <Divider></Divider>
                    <Grid container spacing={{ xs: 2, md: 3 }} className='p-15' columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4} >
                            <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                               
                                    <Typography component="label">Customer Name</Typography>
                                    <OutlinedInput name="name_of_the_customer" value={formik.values.name_of_the_customer} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {errors.name_of_the_customer && touched.name_of_the_customer && <Box Typography="small" className="float-left text-danger">{errors.name_of_the_customer}</Box>}

                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                    <Typography component="label">Phone no.</Typography>
                                    <OutlinedInput value={formik.values.phone_number} disabled />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Email</Typography>
                                    <OutlinedInput name='email_id' value={formik.values.email_id} onChange={(e)=>{handleChange(e)}} onBlur={(e)=>{handleBlur(e)}} />
                                {errors.email_id && touched.email_id && <Box Typography="small" className="float-left text-danger">{errors.email_id}</Box>}

                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Customer Address</Typography>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={3}
                                        name='cust_address'
                                        value={formik.values.cust_address} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                    />
                                {errors.cust_address && touched.cust_address && <Box Typography="small" className="float-left text-danger">{errors.cust_address}</Box>}

                                </Box>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                    <Typography component="label">Customer Store Type</Typography>
                                    <OutlinedInput name="customer_type" value={formik.values.customer_type} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {errors.customer_type && touched.customer_type && <Box Typography="small" className="float-left text-danger">{errors.customer_type}</Box>}

                                </Box>
                            </Box>
                        </Grid> */}
                        {/* <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">COD</Typography>
                                    <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1 }} checked={codStatus === 0?true:false} onChange={()=>{setCodStatus(codStatus === 0 ? 1 : 0);updateCodStatus(formik.values.customer_id)}} name="cod_status" />}

                                    />
                                </Box>
                            </Box>
                        </Grid> */}
                        {/* <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Credit Limit</Typography>
                                    <OutlinedInput name="credit_limit" value={formik.values.credit_limit} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                </Box>
                            </Box>
                        </Grid> */}
                        <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Block Customer</Typography>
                                    <FormControlLabel
                                        control={<IOSSwitch sx={{ m: 1 }} name="block_status" checked={blockStatus === 0?true:false}  onChange={()=>{setBlockStatus(blockStatus === 0 ? 1 : 0);updateBlockStatus(formik.values.customer_id)}} />}

                                    />
                                </Box>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={rupee} />
                                <Box>
                                    <Typography component="label">Join Date</Typography>
                                    <OutlinedInput type="date" name='cr_date' disabled value={formik.values.cr_date} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {errors.cr_date && touched.cr_date && <Box Typography="small" className="float-left text-danger">{errors.cr_date}</Box>}

                                </Box>
                            </Box>
                        </Grid> */}
                        {/* <Grid item xs={2} sm={4} md={4}>
                            <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                    <Typography component="label">Last Activity</Typography>
                                    <OutlinedInput type="date" name='last_activity' disabled value={formik.values.last_activity} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                {errors.last_activity && touched.last_activity && <Box Typography="small" className="float-left text-danger">{errors.last_activity}</Box>}

                                </Box>
                            </Box>
                        </Grid> */}


                    </Grid>



                  


                    <Divider></Divider>
                    <Box className="p-15">
                        <Button variant='outlined' color='error' mt={2} mb={3} className='retail-btn float-right' type='submit'>Submit</Button>
                    </Box>
                </Box>
                </form>
            </Modal>
        </div>
    );
});

export default ViewMoreModalRetailEdit;