import axios from "axios";

export default {
    getData: (url) =>
    axios({
        'method':'GET',
        'url':process.env.REACT_APP_BASE_URL + url,
       
    }),
    postData: (url,data) =>{
    return axios({
        'method':'POST',
        'url':process.env.REACT_APP_BASE_URL + url,
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          
           data:data
        
    })
},
updateData: (url,data) =>{
return axios({
    'method':'POST',
    'url':process.env.REACT_APP_BASE_URL + url,
    headers: { 
        'Content-Type': 'multipart/form-data'
      },
      
       data:data
    
})
}
}