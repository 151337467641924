import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Bredcrum from "../Bredcrum";
import Calender from "../Calender/Calender";
import MemberTable from "../Customer/MemberTable";
// import ViewMoreModal from "../Customer/ViewMoreModal";
import TransportsViewMoreModal from "../Dashboard/TransportsViewMoreModal";
// import { useDemoData } from "@mui/x-data-grid-generator";

const year = ["2023", "2022", "2021", "2020"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
    editable: true,
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true,
  },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
];

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const memeberColumns = [
  { name: "Transport ID", options: { filterOptions: { fullWidth: true } } },
  "Passenger Name",
  "Date",
  "Time",
  "Vehicle Name",
  "Location",
  "Transport Type",
];

const memberOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const detailColumns = [
  { name: "S.No", options: { filterOptions: { fullWidth: true } } },
  "Service Name",
  "QTY",
  "Price",
];

const detaildata = [["1", "Pickup Fee", "1", "$2333"]];
const detailOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function Transports() {
  const ref3 = React.useRef();
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid
                    xs="12"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  ></Grid>
                  <Divider className="bredcum-hr"></Divider>
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Select
                        labelId="demo-isolation-label"
                        id="isolation-select"
                        className="select"
                        defaultValue="locations"
                      >
                        <MenuItem value="locations">All Locations</MenuItem>
                        <MenuItem>Debray</MenuItem>
                      </Select>
                      <Box className="select_div">
                        <div className="select_div">
                          <Calender />
                          <Button variant="outlined" className="today">
                            Today
                          </Button>
                          <Button variant="outlined" className="today">
                            Yesterday
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    container
                    mt={3}
                    // sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card md={12} className="transport_cards">
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box className="text-center overview-points">
                          {/* <Box component="img" src={transports} width={32} /> */}
                          <Typography component="p" className="point-value">
                            2545
                          </Typography>
                          <Typography
                            component="p"
                            className="bg-total transport-status"
                          >
                            Total
                          </Typography>
                        </Box>
                        <Box className="text-center overview-points">
                          {/* <Box component="img" src={customer} width={32} /> */}
                          <Typography component="p" className="point-value">
                            22
                          </Typography>
                          <Typography
                            component="p"
                            className="bg-unassigned transport-status"
                          >
                            Unassigned
                          </Typography>
                        </Box>
                        <Box className="text-center overview-points">
                          {/* <Box component="img" src={dollar} width={32} /> */}
                          <Typography component="p" className="point-value">
                            4
                          </Typography>
                          <Typography
                            component="p"
                            className="bg-assigned transport-status"
                          >
                            Assigned
                          </Typography>
                        </Box>
                        <Box className="text-center overview-points">
                          {/* <Box component="img" src={dollar} width={32} /> */}
                          <Typography component="p" className="point-value">
                            0
                          </Typography>
                          <Typography
                            component="p"
                            className="bg-intransit transport-status"
                          >
                            In Transit
                          </Typography>
                        </Box>
                        <Box className="text-center overview-points">
                          {/* <Box component="img" src={dollar} width={32} /> */}
                          <Typography component="p" className="point-value">
                            2017
                          </Typography>
                          <Typography
                            component="p"
                            className="bg-total transport-status"
                          >
                            Completed
                          </Typography>
                        </Box>
                        <Box className="text-center overview-points">
                          {/* <Box component="img" src={dollar} width={32} /> */}
                          <Typography component="p" className="point-value">
                            499
                          </Typography>
                          <Typography
                            component="p"
                            className="bg-unassigned transport-status"
                          >
                            Cancelled
                          </Typography>
                        </Box>
                        <Box className="text-center overview-points">
                          {/* <Box component="img" src={dollar} width={32} /> */}
                          <Typography component="p" className="point-value">
                            1
                          </Typography>
                          <Typography
                            component="p"
                            className="bg-intransit transport-status"
                          >
                            Pending Ack
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs="12" md="7" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Box sx={{ height: 400, width: "100%" }}>
                            <MemberTable
                              columns={memeberColumns}
                              options={memberOptions}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs="12" md="5" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container mb={2}>
                            <Grid item md="3">
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Transport ID
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                29867
                              </Typography>
                            </Grid>
                            <Grid item md="3">
                              <Typography
                                component="p"
                                className="transport-details-title"
                              >
                                Transport Status
                              </Typography>
                              <Typography
                                component="p"
                                className="transport-detail-value"
                              >
                                Unassigned
                              </Typography>
                            </Grid>
                            <Grid item md="3">
                              <Select
                                className="select"
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Cancelled"
                              >
                                <MenuItem>year</MenuItem>
                                {year.map((item) => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Grid>
                            <Grid item md="3" textAlign={"right"}>
                              <Button
                                variant="outlined"
                                className="btn-primary-outline"
                                onClick={() => ref3.current.log()}
                              >
                                View More
                              </Button>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={driver}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Assigned
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Andrew Carmiche
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={mobile}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Mobile
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  +1 3864733440
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item md="4">
                              <button
                                variant="contained"
                                className="btn-disabled"
                              >
                                Assign Driver
                              </button>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Customer Name
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Manikanta
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={mobile}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Mobile
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  +1 3864733440
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Patient ID
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  #466776
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <img
                                src={custicon}
                                className="T-icon"
                                alt="img-here"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Passanger Name
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Ramya
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={mobile}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Mobile
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  +1 3864733440
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Transport Duration
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  60 Minutes
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              {/* <img
                                src={custicon}
                                className="T-icon"
                                alt="img-here"
                              /> */}
                              <Box sx={{ marginLeft: "6px" }}>
                                <BootstrapInput
                                  defaultValue="60"
                                  id="bootstrap-input"
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={mobile}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Mobile
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  +1 3864733440
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={distance}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Distance
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  9 Miles
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={pickup}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Booking Made
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  10 Jan 2023 07:10 AM
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={dropoff}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Pickup Time
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  11 Jan 2023 06:40 PM
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="12"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={location}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Pickup Location
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  2424 Creekside Circle South, Irving, TX, USA
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              mt={2}
                              item
                              md="12"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={location}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Dropoff Location
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Baylor Scott & White Medical Center -
                                  Grapevine, West College Street, Grapevine, TX,
                                  USA
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="12"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={mail}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Cancellation Comments
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  siva prasad ( admin ) Cancelled the Transport.
                                  Cancellation Reason : dddd
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Typography variant="h5" className="t-heading">
                              TRANSPORT DETAILS
                            </Typography>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mb={2}>
                            <Grid md="12">
                              <MemberTable
                                columns={detailColumns}
                                options={detailOptions}
                                data={detaildata}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <TransportsViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}
