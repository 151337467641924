import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import MemberTable from "../Customer/MemberTable";
import withReactContent from "sweetalert2-react-content";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import api from "../Api";
import { addEmployee } from "../Validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const shiftdetailsColumns = [
  { name: "S.No", options: { filterOptions: { fullWidth: true } } },
  "Working Days",
  "Timings",
];

const shiftdetailsdata = [];

const managementdata = [];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManagementViewMoreModal = forwardRef((props, ref3) => {
  const response = useSelector((state) => state.user.user);
  let locations = useSelector((state) => state.user.location);
  const changeEdit = () => setEdit((edit) => !edit);
  const [edit, setEdit] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [complianceFile, setComplianceFile] = React.useState();
  const [value, setValue] = React.useState(0);
  const [location, setLocation] = React.useState();
  const [addOpen, setAddOpen] = React.useState(false);
  const [complianceFileName, setComplianceFileName] = React.useState();
  const [equipmentFile, setequipmentFile] = React.useState();
  const [uploadDocument, setUploadDocument] = React.useState();
  const [complainceData, setComplianceData] = React.useState({
    compliance_name: "",
    compliance_number: "",
    expiry_date: "",
    compliance_file_name: "",
  });
  const [complainceTableData, setComplianceTableData] = React.useState();
  const [equipmentFileName, setEquipmentFileName] = React.useState();
  const [docTableData, setDocTableData] = React.useState();
  const [docData, setDocData] = React.useState({
    file_name: "",
    doc_expiry_date: "",
  });
  const [equipmentData, setEquipmentData] = React.useState({
    equipment_name: "",
    equipment_number: "",
  });
  const [equipmentTableData, setEquipmentTableData] = React.useState();
  const [uploadDocumentName, setUploadDocumentName] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  const handleTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const viColumns = [
    { name: "Date", options: { filterOptions: { fullWidth: true } } },
    "Inspection",
    "Status",
    "Comments",
  ];

  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      // props?.onUpdate(true);
    });
  };
  const handleDocComplianceSelection = (e) => {
    setComplianceFile(e.target.files[0]);
  };

  const handleDocSelection = (e) => {
    setequipmentFile(e.target.files[0]);
  };

  const handleUploadDocument = (e) => {
    setUploadDocument(e.target.files[0]);
  };


  const uploadDocumentCompliance = () => {
    let data = new FormData();
    data.append("uploaded_file", complianceFile);
    api
      .postData("/api/employee_management/UploadFile", data)
      .then((response) => {
        if (response.data.Status === 200) {
          showSwalWithLink(response.data.Message);
          setComplianceFileName(response.data.FileName);
          setComplianceTableData([complainceData]);
        } else {
          showSwalWithError(response.data.Message);
        }
      });
  };
  const uploadEquipmentFile = () => {
    let data = new FormData();
    data.append("equipment_file", equipmentFile);
    api
      .postData("/api/employee_management/equipment_upload", data)
      .then((response) => {
        if (response.data.Status === 200) {
          setEquipmentFileName(response.data.FileName);
          // setEmpId(response.data.new_employee_id)
          showSwalWithLink(response.data.Message);
          setEquipmentTableData([equipmentData]);
        } else {
          showSwalWithError(response.data.Message);
        }
      });
  };

  const uploadEmployeeDocument = () => {
    let data = new FormData();
    data.append("document_file", uploadDocument);
    api
      .postData("/api/employee_management/Document_upload", data)
      .then((response) => {
        if (response.data.Status === 200) {
          // setEmpId(response.data[0].new_employee_id)
          showSwalWithLink(response.data.Message);
          setUploadDocumentName(response.data.FileName);
          setDocTableData([docData]);
        } else {
          showSwalWithError(response.data.Message);
        }
      });
  };

  const managementColumns = [
    {
      name: "sno", 
      label: "S. NO",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1; 
        },
      },
    },
    
    {
      name: "compliance_name",
      label: "Name",
    },
    {
      name: "compliance_number",
      label: "Number",
    },
    // {
    //   name: "expiry_date",
    //   label: "Expiry Date",
    // },
    {
      name: "compliance_file_name",
      label: "Attachment",
    },
  ];

  const equipmentColumns = [
    {
      name: "sno", 
      label: "S. NO",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1; 
        },
      },
    },
    {
      name: "equipment_name",
      label: "NAME",
    },
    {
      name: "equipment_number",
      label: "NUMBER",
    },
  ];

  const uploadColumns = [
    {
      name: "sno", // Add a unique name for the S. No column
      label: "S. NO",
      options: {
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowIndex + 1; // Calculate and display the row index as S. No
        },
      },
    },
    {
      name: "file_name",
      label: "ATTACHMENT NAME",
    },
    {
      name: "doc_expiry_date",
      label: "EXPIRY DATE",
    },
  ];

  const handleCompliance = (e) => {
    const { name, value, files } = e.target;

    setComplianceData((prevData) => ({
      ...prevData,
      [name]:
        name === "compliance_file_name" && files && files.length > 0
          ? files[0].name
          : value,
    }));
  };

  const handleDocument = (e) => {
    const { name, value } = e.target;

    setDocData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetDocData = () => {
    setDocTableData("");
  };
 
  const handleEquipment = (e) => {
    const { name, value } = e.target;

    setEquipmentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetComplainceData = () => {
    setComplianceTableData("");
  };

  const resetEquipmentData = () => {
    setEquipmentTableData("");
  };
  
  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };

  const viOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleSubmit = (values) => {
    console.log('doc_expiry_date',docData?.doc_expiry_date);
    console.log('doc_file_name',docData?.file_name);
    console.log('equipment_name',equipmentData?.equipment_name);
    console.log('equipment_number',equipmentData?.equipment_number);
    console.log('formikvalues',formik.values);
    if ( docData?.doc_expiry_date && docData?.file_name && equipmentData?.equipment_name && equipmentData?.equipment_number ) {
      formik.values.compliance_file_name = complianceFileName;
      formik.values.files_information = uploadDocumentName;
      formik.values.equipment_file = equipmentFileName;
      api
        .postData("/api/employee_management/employee_management", formik.values)
        .then((response) => {
          console.log('postresponse', response)
          if (response?.data[0]?.Status === "200") {
            // setEmpId(response.data[0].new_employee_id)
            showSwalWithLink(response.data[0].Message);
          } else {
            showSwalWithError(response.data[0].Message);
          }
        });
    } else {
      showSwalWithError("Please fill all details");
    }
  };

  const fetchCities = () => {
    api
      .getData(
        "/api/locations/get_Location_name_pos?merchant_id=" +
          response?.merchant_id +
          "&city_id=" +
          response?.city_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setLocation(JSON.parse(response.data[0].LocationDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCities();
  }, []);

  const initialValues = {
    employee_id: props?.bankDetails?.employee_id || "0",
    merchant_id: response?.merchant_id,
    first_name: props?.employeeDetails?.first_name || "",
    last_name: props?.employeeDetails?.last_name || "",
    phone_number: props?.employeeDetails?.phone_number || "",
    email_id: props?.employeeDetails?.email_id || "",
    gender: props?.employeeDetails?.gender || "",
    date_of_birth: props?.employeeDetails?.date_of_birth || "",
    location_id: props?.employeeDetails?.location_id || "",
    address: props?.employeeDetails?.address || "",
    employee_status: props?.employeeDetails?.employee_status || "0",
    role_id: props?.employeeRoleDetails?.role_id || "",
    job_type: props?.employeeRoleDetails?.job_type || "",
    pay_type: props?.employeeRoleDetails?.pay_type || "",
    pay_period_unit: props?.employeeRoleDetails?.pay_period_unit || "",
    basic_pay: props?.employeeRoleDetails?.basic_pay || "",
    bonus: props?.employeeRoleDetails?.bonus || "",
    bonus_comment: props?.employeeRoleDetails?.bonus_comment || "",
    bank_name: props?.bankDetails?.bank_name || "",
    bank_branch: props?.bankDetails?.bank_branch || "",
    ifsc: props?.bankDetails?.ifsc || "",
    account_number: props?.bankDetails?.account_number || "",
    account_holder_name: props?.bankDetails?.account_holder_name || "",
    employee_bank_status: props?.bankDetails?.employee_bank_status || "0",
    user_name: props?.empLoginDetails?.user_name || "",
    password: props?.empLoginDetails?.password || "",
    login_status: props?.empLoginDetails?.login_status || "0",
    compliance_number:
      (props?.complianceDetails &&
        props?.complianceDetails[0]?.compliance_number) ||
      "",
    compliance_name:
      (props?.complianceDetails &&
        props?.complianceDetails[0]?.compliance_name) ||
      "",
    compliance_file_name: complianceFileName,
    expiry_date:
      (props?.complianceDetails && props?.complianceDetails[0]?.expiry_date) ||
      "",
    compliance_status:
      (props?.complianceDetails &&
        props?.complianceDetails[0]?.compliance_status) ||
      "0",
    doc_expiry_date:
      (props?.employeeFile && props?.employeeFile[0]?.doc_expiry_date) || "",
    files_information:
      (props?.employeeFile && props?.employeeFile[0]?.files_information) || uploadDocumentName,
    // 'file_name': uploadDocumentName,
    equipment_name:
      (props?.EmployeeEquipmentDetails &&
        props?.EmployeeEquipmentDetails[0]?.equipment_name) ||
      "",
    equipment_number:
      (props?.EmployeeEquipmentDetails &&
        props?.EmployeeEquipmentDetails[0]?.equipment_number) ||
      "",
    equipment_file: equipmentFileName
  };

  const formik = useFormik({
    initialValues,
    validationSchema: addEmployee,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
    },
  }));
  const ref1 = React.useRef();
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className={edit ? "disable view-more-content" : "view-more-content"}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box className="modal-header p-15">
              <Typography
                id="modal-modal-title"
                variant="h5"
                className="m-heading"
                component="h5"
              >
                Employee Profile
              </Typography>
              <Box display={"flex"} alignItems={"center"}>
                <Button
                  variant="outlined"
                  color="error"
                  mt={2}
                  mb={2}
                  onClick={changeEdit}
                  className="retail-btn  mr-2"
                >
                  {edit ? "Edit" : "Save"}
                </Button>
                <CloseIcon onClick={handleClose} />
              </Box>
            </Box>
            <Divider></Divider>
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">
                    Personal Information
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Name *</Typography>
                  <OutlinedInput
                    placeholder="First Name"
                    name="first_name"
                    disabled={edit}
                    value={formik.values.first_name}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.first_name && touched.first_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.first_name}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              {/* <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Last Name</Typography>
                <OutlinedInput
                  placeholder="Last Name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
              </FormControl>
              {errors.last_name && touched.last_name && (
                <Box Typography="small" className="float-left text-danger">
                  {errors.last_name}
                </Box>
              )}
            </Grid> */}
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Phone Number*</Typography>
                  <OutlinedInput
                    placeholder="Phone Number"
                    name="phone_number"
                    disabled={edit}
                    value={formik.values.phone_number}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                </FormControl>
                {errors.phone_number && touched.phone_number && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.phone_number}
                  </Box>
                )}
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Address*</Typography>
                  <OutlinedInput
                    placeholder="address"
                    name="address"
                    disabled={edit}
                    value={formik.values.address}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                </FormControl>
                {errors.address && touched.address && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.address}
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Email ID*</Typography>
                  <OutlinedInput
                    placeholder="Email"
                    name="email_id"
                    disabled={edit}
                    value={formik.values.email_id}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.email_id && touched.email_id && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.email_id}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Gender*</Typography>
                  <OutlinedInput
                    placeholder="Gender"
                    name="gender"
                    disabled={edit}
                    value={formik.values.gender}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.gender && touched.gender && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.gender}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Dob*</Typography>
                  <OutlinedInput
                    type="Date"
                    name="date_of_birth"
                    disabled={edit}
                    value={formik.values.date_of_birth}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />

                  {errors.date_of_birth && touched.date_of_birth && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.date_of_birth}
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">Role Details</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} paddingRight={2}>
              <Grid item md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Job Name*</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="role_id"
                    disabled={edit}
                    value={formik.values.role_id}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    defaultValue={formik.values.role_id}
                  >
                    {props?.jobRoles
                      ? props.jobRoles.map((item) => {
                          return (
                            <MenuItem value={item.role_id}>
                              {item.role_name}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Select>
                  {errors.role_id && touched.role_id && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.role_id}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Job Type*</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="job_type"
                    disabled={edit}
                    value={formik.values.job_type}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  >
                    <MenuItem value="Part Time">Part Time</MenuItem>
                    <MenuItem value="Full Time">Full Time</MenuItem>
                    <MenuItem value="Contract">Contract</MenuItem>
                  </Select>
                  {errors.job_type && touched.job_type && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.job_type}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Location*</Typography>
                  {/* <OutlinedInput placeholder="Location" value="Hyderabad" /> */}
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="location_id"
                    disabled={edit}
                    value="1"
                    // value={formik.values.location_id}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  >
                    {location?.map((item) => {
                      return (
                        <MenuItem value={item.merchant_location_id}>
                          {item.location_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.location_id && touched.location_id && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.location_id}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Pay Type*</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="pay_type"
                    disabled={edit}
                    value={formik.values.pay_type}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    defaultValue="Suppliers"
                  >
                    <MenuItem value="Per Hour">Per Hour</MenuItem>
                    <MenuItem value="Per Week">Per Week</MenuItem>
                    <MenuItem value="Per Day">Per Day</MenuItem>
                    <MenuItem value="Per Month">Per Month</MenuItem>
                    {/* <MenuItem value="Per Transaction">Per Transaction</MenuItem> */}
                  </Select>
                  {errors.pay_type && touched.pay_type && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.pay_type}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Pay Period Unit*</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    name="pay_period_unit"
                    disabled={edit}
                    className="select viewmore-dpdown"
                    value={formik.values.pay_period_unit}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    defaultValue={formik.values.pay_period_unit}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    <MenuItem value="Per Day / 8 Hours">
                      Per Day / 8 Hours
                    </MenuItem>
                    <MenuItem value="Per Week / 5 Days">
                      Per Week / 5 Days
                    </MenuItem>
                    <MenuItem value="Per Month / 26 Days">
                      Per Month / 26 Days
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Basic Pay*</Typography>
                  <OutlinedInput
                    name="basic_pay"
                    disabled={edit}
                    value={formik.values.basic_pay}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.basic_pay && touched.basic_pay && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.basic_pay}
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {/* <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Amount</Typography>
                <OutlinedInput
                  name="bonus"
                  value={formik.values.bonus}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                />
                {errors.bonus && touched.bonus && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.bonus}
                  </Box>
                )}
              </FormControl>
            </Grid> */}
            {/* <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Bonus Comments</Typography>
                  <OutlinedInput
                    name="bonus_comment"
                    value={formik.values.bonus_comment}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.bonus_comment && touched.bonus_comment && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.bonus_comment}
                    </Box>
                  )}
                </FormControl>
              </Grid> */}

            {/* <Grid container spacing={2} mt={3} paddingRight={2}>
            <Grid item md={12}>
              <Box className="bg-secondary">
                <Typography className="p-info">Shift Details</Typography>
              </Box>
            </Grid>
          </Grid> */}
            {/* <Grid container spacing={2} mt={1} paddingRight={2}>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Employee Name</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  name="employee_id"
                  value={formik.values.employee_id}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => { handleBlur(e); }}
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="car">SreeRamu</MenuItem>
                  <MenuItem value="van">Sai Deva</MenuItem>
                  <MenuItem value="van">Manohar</MenuItem>
                </Select>
                {errors.employee_id && touched.employee_id && <Box Typography="small" className="float-left text-danger">{errors.employee_id}</Box>}

              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Location</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  name="location_id"
                  value={formik.values.location_id}
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => { handleBlur(e); }}
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="car">Hyderabad</MenuItem>
                  <MenuItem value="van">Visakhapatnam</MenuItem>
                  <MenuItem value="van">Kakinada</MenuItem>
                </Select>
                {errors.location_id && touched.location_id && <Box Typography="small" className="float-left text-danger">{errors.location_id}</Box>}

              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Start Date</Typography>
                <OutlinedInput type="date"
                  name="location_id"
                  value={formik.values.start_date} 
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => { handleBlur(e); }}
                />
                {errors.location_id && touched.location_id && <Box Typography="small" className="float-left text-danger">{errors.location_id}</Box>}

              </FormControl>
            </Grid>
          </Grid> */}
            {/* <Grid container spacing={2} mt={1} paddingRight={2}>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">End Date</Typography>
                <OutlinedInput type="date" placeholder="ID" />
                {errors.location_id && touched.location_id && <Box Typography="small" className="float-left text-danger">{errors.location_id}</Box>}

              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Start Time</Typography>
                <OutlinedInput type="time" placeholder="ID" />
                {errors.location_id && touched.location_id && <Box Typography="small" className="float-left text-danger">{errors.location_id}</Box>}

              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">End Time</Typography>
                <OutlinedInput type="time" placeholder="ID" />
              </FormControl>
            </Grid>
          </Grid> */}
            <Grid container spacing={2} mt={3} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">Compliance</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              mt={1}
              mb={3}
              paddingRight={2}
              style={{ display: "flex", alignItems: "end" }}
            >
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Name</Typography>
                  <OutlinedInput
                    name="compliance_name"
                    disabled={edit}
                    value={formik.values.compliance_name}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleCompliance(e);
                    }}
                  />
                  {errors.compliance_name && touched.compliance_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.compliance_name}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Number</Typography>
                  <OutlinedInput
                    name="compliance_number"
                    disabled={edit}
                    value={formik.values.compliance_number}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleCompliance(e);
                    }}
                  />
                  {errors.compliance_number && touched.compliance_number && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.compliance_number}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Expiry Date</Typography>
                  <OutlinedInput
                    name="expiry_date"
                    disabled={edit}
                    value={formik.values.expiry_date}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleCompliance(e);
                    }}
                    type="date"
                  />
                  {errors.expiry_date && touched.expiry_date && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.expiry_date}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                  <Typography component="label">Choose File</Typography>
                  <OutlinedInput
                    name="compliance_file_name"
                    onChange={(e) => {
                      // handleChange(e);
                      handleDocComplianceSelection(e);
                    }}
                    onBlur={(e) => {
                      // handleBlur(e);
                      handleCompliance(e);
                    }}
                    accept=".pdf"
                    type="file"
                  />
                  {errors.compliance_file_name &&
                    touched.compliance_file_name && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.compliance_file_name}
                      </Box>
                    )}
                </FormControl>
              </Grid>
              <Grid item md={2}>
                <Button
                  variant="outlined"
                  color="error"
                  mb={2}
                  className="btn-outline-primary"
                  onClick={(e) => {
                    uploadDocumentCompliance(e);
                  }}
                >
                  Upload
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  className="btn-outline-primary float-right"
                  onClick={() => {
                    resetComplainceData();
                  }}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item md={12}>
                <MemberTable
                  columns={managementColumns}
                  options={managementOptions}
                  data={complainceTableData?complainceTableData:props?.complianceDetails}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} mt={3} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">
                    Financial Information
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} mb={3} paddingRight={2}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Bank Name*</Typography>
                  <OutlinedInput
                    name="bank_name"
                    disabled={edit}
                    value={formik.values.bank_name}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.bank_name && touched.bank_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.bank_name}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Branch Name*</Typography>
                  <OutlinedInput
                    name="bank_branch"
                    disabled={edit}
                    value={formik.values.bank_branch}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.bank_branch && touched.bank_branch && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.bank_branch}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">IFSC Code*</Typography>
                  <OutlinedInput
                    name="ifsc"
                    disabled={edit}
                    value={formik.values.ifsc}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.ifsc && touched.ifsc && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.ifsc}
                    </Box>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} mb={3}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Account Number*</Typography>
                  <OutlinedInput
                    placeholder="Account Number"
                    name="account_number"
                    disabled={edit}
                    value={formik.values.account_number}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.account_number && touched.account_number && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.account_number}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">
                    Account Holder Name*
                  </Typography>
                  <OutlinedInput
                    placeholder="Account Holder Name"
                    name="account_holder_name"
                    disabled={edit}
                    value={formik.values.account_holder_name}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.account_holder_name &&
                    touched.account_holder_name && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.account_holder_name}
                      </Box>
                    )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={3} paddingRight={2}>
              <Grid item md={12}>
                <Box className="bg-secondary">
                  <Typography className="p-info">
                    Username & Password
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1} mb={3}>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Username*</Typography>
                  <OutlinedInput
                    name="user_name"
                    disabled={edit}
                    value={formik.values.user_name}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Password*</Typography>
                  <OutlinedInput
                    name="password"
                    disabled={edit}
                    value={formik.values.password}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item md={4} textAlign={"center"}>
                <Button
                  variant="outlined"
                  color="error"
                  mb={2}
                  className="btn-outline-primary"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Tabs
                  value={value}
                  onChange={handleTab}
                  aria-label="basic tabs example"
                  className="custom-tab"
                >
                  {/* <Tab label="Shift Details" {...a11yProps(0)} /> */}
                  <Tab label="Equipment Details" {...a11yProps(1)} />
                  <Tab label="Upload Documents" {...a11yProps(0)} />
                </Tabs>
              </Grid>
              <Grid item md={12}>
                {/* <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Days</Typography>
                      <OutlinedInput placeholder="" type="date" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Start Time</Typography>
                      <OutlinedInput placeholder="" type="time" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">End TIme</Typography>
                      <OutlinedInput placeholder="" type="time" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      variant="outlined"
                      color="error"
                      mb={2}
                      className="btn-outline-primary"
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <MemberTable
                      columns={shiftdetailsColumns}
                      options={managementOptions}
                      data={shiftdetailsdata}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
               */}
                <TabPanel value={value} index={0}>
                  <Grid
                    container
                    spacing={2}
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">Name</Typography>
                        <OutlinedInput
                          name="equipment_name"
                          disabled={edit}
                          value={formik.values.equipment_name}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleEquipment(e);
                          }}
                        />
                        {errors.equipment_name && touched.equipment_name && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.equipment_name}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">Number</Typography>
                        <OutlinedInput
                          name="equipment_number"
                          disabled={edit}
                          value={formik.values.equipment_number}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleEquipment(e);
                          }}
                        />
                        {errors.equipment_number &&
                          touched.equipment_number && (
                            <Box
                              Typography="small"
                              className="float-left text-danger"
                            >
                              {errors.equipment_number}
                            </Box>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        {/* <Typography component="label">Choose File</Typography>
                      <OutlinedInput
                        name="equipment_file"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => { handleBlur(e); }}
                        type="file" /> */}
                        <OutlinedInput
                          name="equipment_file"
                          disabled={edit}
                          onChange={(e) => {
                            handleChange(e);
                            handleDocSelection(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          accept=".pdf"
                          type="file"
                        />
                        {errors.equipment_file && touched.equipment_file && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.equipment_file}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        variant="outlined"
                        color="error"
                        disabled={edit}
                        mb={2}
                        className="btn-outline-primary"
                        onClick={() => {
                          uploadEquipmentFile();
                        }}
                      >
                        Upload
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        className="btn-outline-primary"
                        onClick={() => {
                          resetEquipmentData();
                        }}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item md={12}>
                      <MemberTable
                        columns={equipmentColumns}
                        options={managementOptions}
                        data={equipmentTableData?equipmentTableData:[]}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid
                    container
                    spacing={2}
                    style={{ display: "flex", alignItems: "flex-end" }}
                  >
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">
                          Attachments Name
                        </Typography>
                        <OutlinedInput
                          name="file_name"
                          disabled={edit}
                          value={formik.values.file_name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleDocument(e);
                          }}
                        />
                        {errors.file_name && touched.file_name && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.file_name}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">Expiry Date</Typography>
                        <OutlinedInput
                          name="doc_expiry_date"
                          disabled={edit}
                          value={formik.values.doc_expiry_date}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleDocument(e);
                          }}
                          type="date"
                        />
                        {errors.doc_expiry_date && touched.doc_expiry_date && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.doc_expiry_date}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <FormControl className="form_control" fullWidth>
                        <Typography component="label">Choose File</Typography>
                        <OutlinedInput
                          name="upload_file"
                          disabled={edit}
                          onChange={(e) => {
                            handleChange(e);
                            handleUploadDocument(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                          accept=".pdf"
                          type="file"
                        />
                        {errors.upload_file && touched.upload_file && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.upload_file}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        variant="outlined"
                        color="error"
                        mb={2}
                        className="btn-outline-primary"
                        onClick={() => {
                          uploadEmployeeDocument();
                        }}
                      >
                        Upload
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        className="btn-outline-primary"
                        onClick={() => {
                          resetDocData();
                        }}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item md={12}>
                      <MemberTable
                        columns={uploadColumns}
                        options={managementOptions}
                        data={docTableData?docTableData:[]}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
            </Grid>

            <Divider></Divider>
            <Box textAlign={"right"} marginX={2} marginY={2}>
              <Button
                variant="outlined"
                color="error"
                mb={2}
                className="btn-outline-primary"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Modal
        open={addOpen}
        onClose={handleCloseAdd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="add-user-modal" sx={style}>
          <Box className="add-user-div p-15">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Add New user
            </Typography>
            <CloseIcon onClick={handleCloseAdd} />
          </Box>
          <Divider></Divider>
          <Grid
            className="inputfield-container"
            container
            spacing={2}
            columns={{ xs: 4, sm: 8, md: 12 }}
            mt={2}
          >
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">First Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Last Name</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Phone No</Typography>
                <OutlinedInput />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Email</Typography>
                <OutlinedInput disabled={edit} />
              </FormControl>
            </Grid>
            <Grid item xs={2} sm={4} md={4}>
              <FormControl>
                <Typography component="label">Password</Typography>
                <OutlinedInput disabled={edit} />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid container spacing={2}>
            <Grid item md={12}>
              {/* <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="custom-tab"
              >
                <Tab label="Shift Details" {...a11yProps(0)} />
                <Tab label="Equipment Details" {...a11yProps(0)} />
                <Tab label="Upload Documents" {...a11yProps(1)} />
              </Tabs> */}
            </Grid>
            <Grid item md={12}>
              {/* <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Days</Typography>
                      <OutlinedInput placeholder="" type="date" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Start Time</Typography>
                      <OutlinedInput placeholder="" type="time" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">End TIme</Typography>
                      <OutlinedInput placeholder="" type="time" />
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      variant="outlined"
                      color="error"
                      mb={2}
                      className="btn-outline-primary"
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <MemberTable
                      columns={shiftdetailsColumns}
                      options={managementOptions}
                      data={shiftdetailsdata}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
               */}
              {/* <TabPanel value={value} index={0}>
                <Grid
                  container
                  spacing={2}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Name</Typography>
                      <OutlinedInput
                        name="equipment_name"
                        disabled={edit}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          // handleEquipment(e);
                        }}
                      />
                      {errors.equipment_name && touched.equipment_name && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.equipment_name}
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Number</Typography>
                      <OutlinedInput
                        name="equipment_number"
                        disabled={edit}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          // handleEquipment(e);
                        }}
                      />
                      {errors.equipment_number && touched.equipment_number && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.equipment_number}
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      {/* <Typography component="label">Choose File</Typography>
                      <OutlinedInput
                        name="equipment_file"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => { handleBlur(e); }}
                        type="file" /> */}
              {/*  <OutlinedInput
                        name="equipment_file"
                        disabled={edit}
                        onChange={(e) => {
                          handleChange(e);
                          handleDocSelection(e);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        accept=".pdf"
                        type="file"
                      />
                      {errors.equipment_file && touched.equipment_file && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.equipment_file}
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      variant="outlined"
                      color="error"
                      disabled={edit}
                      mb={2}
                      className="btn-outline-primary"
                      onClick={() => {
                        uploadEquipmentFile();
                      }}
                    >
                      Upload
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      className="btn-outline-primary"
                      onClick={() => {
                        addEquipmentData();
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <MemberTable
                      columns={equipmentColumns}
                      options={managementOptions}
                      data={props?.equipmentTableData?props.equipmentTableData:[]}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid
                  container
                  spacing={2}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">
                        Attachments Name
                      </Typography>
                      <OutlinedInput
                        name="file_name"
                        disabled={edit}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleDocument(e);
                        }}
                      />
                      {errors.file_name && touched.file_name && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.file_name}
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Expiry Date</Typography>
                      <OutlinedInput
                        name="doc_expiry_date"
                        disabled={edit}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleDocument(e);
                        }}
                        type="date"
                      />
                      {errors.doc_expiry_date && touched.doc_expiry_date && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.doc_expiry_date}
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <FormControl className="form_control" fullWidth>
                      <Typography component="label">Choose File</Typography>
                      <OutlinedInput
                        name="upload_file"
                        disabled={edit}
                        onChange={(e) => {
                          handleChange(e);
                          handleUploadDocument(e);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                        }}
                        accept=".pdf"
                        type="file"
                      />
                      {errors.upload_file && touched.upload_file && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.upload_file}
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={3}>
                    <Button
                      variant="outlined"
                      color="error"
                      mb={2}
                      className="btn-outline-primary"
                      onClick={() => {
                        uploadEmployeeDocument();
                      }}
                    >
                      Upload
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      className="btn-outline-primary"
                      onClick={() => {
                        addDocData();
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <MemberTable
                      columns={uploadColumns}
                      options={managementOptions}
                      data={props?.docTableData?props.docTableData:[]}
                    />
                  </Grid>
                </Grid>
              </TabPanel> */}
            </Grid>
          </Grid>

          <Divider></Divider>
          <Button
            variant="outlined"
            color="error"
            mt={2}
            mb={2}
            className="save-btn p-15"
          >
            Save
          </Button>
        </Box>
      </Modal>
    </div>
  );
});

export default ManagementViewMoreModal;
