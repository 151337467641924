// userReducer.js

const initialState = {
  user: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload,
      };
      case "SET_TABLE_NUMBER":
        return {
          ...state,
          tableNumber: action.payload,
        };
    case "SET_EXPAND_STATE":
      return {
        ...state,
        expandState: action.payload,
      };
    case "SET_LOCATIONS":
      return {
        ...state,
        location: action.payload,
      };
    case "SET_SERVICES":
      return {
        ...state,
        services: action.payload,
      };
    case "SET_PRODUCT_ID":
      return {
        ...state,
        product_id: action.payload,
      };
    case "SET_CART_TOTAL":
      return {
        ...state,
        cart_total: action.payload,
      };
    case "SET_CITIES":
      return {
        ...state,
        cities: action.payload,
      };
    case "SET_PENDING_INVOICES":
      console.log("action.payload", action.payload);
      return {
        ...state,
        pending_invoices: action.payload,
      };
    case "SET_PRODUCT_SKU":
      return {
        ...state,
        product_sku: action.payload,
      };
    case "SET_UOM_DATA":
      return {
        ...state,
        uom_data: action.payload,
      };
    case "SET_STOCKITEM_DATA":
      return {
        ...state,
        stockitem_data: action.payload,
      };
    case "SET_DELIVERY_ID":
      return {
        ...state,
        delivery_Id: action.payload,
      };
    case "SET_DELIVERY_TYPE_ID":
      return {
        ...state,
        delivery_Type_Id: action.payload,
      };
    case "SET_POS_ORDER_DATA":
      return {
        ...state,
        order_data: action.payload,
      };
    case "SET_ITEM_STOCK":
      return {
        ...state,
        item_stock: action.payload,
      };
    case "SET_POS_PHONE":
      return {
        ...state,
        pending_phone: action.payload,
      };
    case "SET_POS_Location":
      return {
        ...state,
        pos_location: action.payload,
      };
    case "SET_POS_INVOICEID":
      return {
        ...state,
        pos_invoice_id: action.payload,
      };
    case "SET_ORDER_ID":
      return {
        ...state,
        order_id: action.payload,
      };
    case "SET_CUSTOMER_ID":
      return {
        ...state,
        customer_id: action.payload,
      };
    case "SET_PENDING_CART_ITEMS":
      return {
        ...state,
        pending_cart_items: action.payload,
      };
    case "SET_CART_TAX_AND_FEE":
      console.log(action)
      return {
        ...state,
        pending_cart_tax_totals: action.payload?.TaxDetails ? JSON.parse(action.payload?.TaxDetails) : [],
        pending_cart_fee_totals: action.payload?.TaxDetails ? JSON.parse(action.payload?.FeeDetails): [],
      };
    case "SET_TWO_DEL_DYNAMIC_ID":
      return {
        ...state,
        two_del_dynamic_id: action.payload,
      };
    case "SET_THREE_DEL_DYNAMIC_ID":
      return {
        ...state,
        three_del_dynamic_id: action.payload,
      };

    case "SET_THREE_DEL_BASE_ID":
      return {
        ...state,
        three_del_base_id: action.payload,
      };
    case "SET_TWO_DEL_BASE_ID":
      return {
        ...state,
        two_del_base_id: action.payload,
      };
    case "SET_PROFILE_DATA":
      return {
        ...state,
        profile: action.payload,
      };
    case "SET_MEASUREMENT_ID":
      return {
        ...state,
        measurement_id: action.payload,
      };
    case "EDIT_SEGMENT":
      const { id, newData } = action.payload;
      console.log(" action.payload", action.payload);
      console.log("state", state);
    // Find the data item by id and update it
    // const updatedData = state.data.map(item =>
    //   item.id === id ? { ...item, ...newData } : item
    // );
    // return {
    //   ...state,
    //   segments: updatedData,
    // };
    case "SET_SEGMENTS":
      return {
        ...state,
        segments: action.payload,
      };
    case "SET_PROMOTIONS_EDIT_DATA":
      return {
        ...state,
        promotions_edit_data: action.payload,
      };
    case "SET_PROMOTIONS_PRODUCT_EDIT_DATA":
      return {
        ...state,
        promotions_product_edit_data: action.payload,
      };
    case "SET_PROMOTIONS_CUSTOMER_EDIT_DATA":
      return {
        ...state,
        promotions_customer_edit_data: action.payload,
      };
    case "@@INIT":
      return state;
    //   console.log('action.payload state',state);
    // case 'CLEAR_USER':
    //   return {
    //     ...state,
    //     response: null,
    //   };
    default:
      console.log("action.payload default", state);
      return state;
  }
};

export default userReducer;

// const reducer = (state, action) => {
//     switch (action.type) {
//        case 'STORE': return state + 1
//        case 'UPDATE': return state - 1
//        case 'DELETE': return state - 1
//        case 'RESET' : return 0
//        default: return state
//     }
//  }
//  export default reducer;
