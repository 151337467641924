import React, { useImperativeHandle, forwardRef } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import ambulance from "../images/Ambulance.svg";
import transports from "../images/transports.svg";
import customer from "../images/c_customer.svg";
import dollar from "../images/dollar.svg";
import ceye from "../images/C-eye.svg";
import transport from "../images/transport.png";
import Revenue from "../images/revenue.png";
import cust from "../images/customer.png";
import utilization from "../images/utilization.png";
import { OutlinedInput, FormControl } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Calender from "../Calender/Calender";
import { Image, Label } from "@mui/icons-material";
import AddCustomerModal from "./AddCustomerModal";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import MemberTable from "./MemberTable";
import EditModal from "./EditModal";
import ViewMoreModal from "./ViewMoreModal";
import Bredcrum from "../Bredcrum";

const drawerWidth = 240;

const year = ["2023", "2022", "2021", "2020"];

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function Member() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  const memeberColumns = [
    { name: "Company ID", options: { filterOptions: { fullWidth: true } } },
    "Company Name",
    "Phone",
    "Email",
  ];

  const memberOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Box className="select_div range-picker">
                        <Calender />

                        <Button variant="outlined" className="today">
                          Today
                        </Button>
                        <Button variant="outlined" className="today">
                          Yesterday
                        </Button>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Locations</MenuItem>
                          <MenuItem>Debray</MenuItem>
                        </Select>
                        <Button
                          variant="outlined"
                          color="error"
                          className="error-btn"
                          onClick={() => ref.current.log()}
                        >
                          Add Customer
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container mt={3} className="pickers">
                    <Grid xs="12" mt={2} mb={2}>
                      <Box className="member-datepickers">
                        <Box>
                          <Box className="border-right">
                            <Typography>JOIN DATE</Typography>
                            <Box className="sub_date">
                              <FormControl>
                                <Typography component="label">From</Typography>
                                <OutlinedInput
                                  type="date"
                                  placeholder="Please enter text"
                                />
                              </FormControl>
                              <FormControl>
                                <Typography component="label">To</Typography>
                                <OutlinedInput
                                  type="date"
                                  placeholder="Please enter text"
                                />
                              </FormControl>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="border-right">
                          <Typography>LAST ACTIVE</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              <OutlinedInput type="date" />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput type="date" />
                            </FormControl>
                          </Box>
                        </Box>
                        <Box className="border-right">
                          <Typography>BILLINGS</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              <OutlinedInput type="number" />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput type="number" />
                            </FormControl>
                          </Box>
                        </Box>
                        <Box>
                          <Typography>TRANSPORTS</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              <OutlinedInput type="number" />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput type="number" />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">Customer</Typography>
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={2}
                            className="save-btn"
                          >
                            Download
                          </Button>
                        </Box>
                        <Box className="member-table" mt={2}>
                          <MemberTable
                            columns={memeberColumns}
                            options={memberOptions}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">Customer</Typography>
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={2}
                            className="save-btn"
                            onClick={() => ref1.current.log()}
                          >
                            Edit
                          </Button>
                        </Box>
                        <Box>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={id} />
                                <Box>
                                  <Typography component="label">
                                    Customer Id
                                  </Typography>
                                  <Typography>#1</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Customer Name
                                  </Typography>
                                  <Typography>Access2Care</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Phone no.
                                  </Typography>
                                  <Typography>+1 303-495-1667</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography>
                                    A2Cclaimsdenver@EMSC.net
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Billable Account
                                  </Typography>
                                  <Typography>2000</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Black Status
                                  </Typography>
                                  <Typography>
                                    A2Cclaimsdenver@EMSC.net
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          <Box>
                            <Box className="information-div" mt={2} mb={2}>
                              <Box component="img" src={location} />
                              <Box>
                                <Typography component="label">
                                  Office Address
                                </Typography>
                                <Typography>
                                  6200 S.Syracuse Way, STE
                                  <br />
                                  200 Greenwood village,
                                  <br />
                                  CO 80111
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Divider></Divider>
                          <Grid container mt={2} spacing={2}>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Customer Since
                                  </Typography>
                                  <Typography>20 Jul 2022</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Last Activity
                                  </Typography>
                                  <Typography>-</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2} mt={2}>
                            <Grid item xs={6} mt={2}>
                              <Box className="clr-one">
                                <Typography component="h2">0</Typography>
                                <Typography component="p">
                                  Total Transports
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="clr-two">
                                <Typography component="h2">$ 0.00</Typography>
                                <Typography component="p">
                                  Total Transports
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider></Divider>
                        <Grid mt={5} className="view-more-btn">
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            className="save-btn"
                            onClick={() => ref3.current.log()}
                          >
                            View More
                          </Button>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <AddCustomerModal ref={ref} />
        <EditModal ref={ref1} />
        <ViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}
