import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MemberTable from "../Customer/MemberTable";
import { OutlinedInput, FormControl, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import reddot from "../images/reddot.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Bredcrum from "../Bredcrum";
import ScheduleEmployeeModal from "../Employees/ScheduleEmployeeModal";
import CloneModal from "./CloneModal";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import withReactContent from "sweetalert2-react-content";
import EditIcon from "../images/edit-icon.png";
import DeleteIcon from "../images/delete-icon.png";
import api from "../Api";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { shiftShedules } from "../Validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    // console.log(action);
    // console.dir(state);
  },
};



const pastmanagementdata = [
  ["SreeRamu", "Visakhapatnam", "Manager", "10:30AM", "06:00PM", ""],
  ["Manohar", "Visakhapatnam", "DEO", "10:30AM", "06:00PM", ""],
  ["Sai", "Visakhapatnam", "DEO", "10:30AM", "06:00PM", ""],
];

const week = ["Day", "Week", "Month"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ShiftShedule(props) {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  
  const [shifts, setShifts] = React.useState();

  const response = useSelector(state => state.user.user);
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  
  // const [data, setData] = useState([
  //   { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  // ]);

  const [sheduleDetailsCalender, setSheduleDetailsCalender] = React.useState();
  const [selectedItem, setselectedItem] = React.useState();
  const EmpColumns = [
    {
      name: 'employee_name',
      label: 'Employee Name',

    },
    { name: 'role_name', label: 'Designation' },
    { name: 'location_name', label: 'Location Name' },
    { name: 'expected_checkin_time', label: 'Start Time' },
    { name: 'expected_checked_out_time', label: 'End Time' },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<img src={EditIcon} className="table-icon"
            onClick={() => {
              const rowIndex = tableMeta.rowIndex;
              setselectedItem(sheduleDetailsCalender[rowIndex]);
              console.log(sheduleDetailsCalender[rowIndex]);
              if (sheduleDetailsCalender[rowIndex].expected_checkin_time != "") {
                setStartTime(sheduleDetailsCalender[rowIndex].expected_checkin_time);
              }
              if (sheduleDetailsCalender[rowIndex].expected_checked_out_time != "") {
                setEndTime(sheduleDetailsCalender[rowIndex].expected_checked_out_time);
              }
              // formik.setValues((prevValues) => ({
              //   ...prevValues,
              //   ...sheduleDetailsCalender[rowIndex],
              // }));
              //  formik.initialValues=sheduleDetailsCalender[rowIndex];
              //   formik.values=sheduleDetailsCalender[rowIndex];
              handleOpen();
            }} />);
        },
      },
    },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<img src={DeleteIcon} className="table-icon" onClick={() => {
            const rowIndex = tableMeta.rowIndex;
            setselectedItem(sheduleDetailsCalender[rowIndex])
            handleOpenDelete();
           // deleteShiftShedule(sheduleDetailsCalender[rowIndex]);
          }} />);
        },
      },
    },

  ];

  const MySwal = withReactContent(Swal);
  const ShiftColumns = [
    { name: "DRIVER NAME", options: { filterOptions: { fullWidth: true } } },
    "START TIME",
    "END TIME",
    "PHONE NUMBER",
  ];

  const [selectedDate, setSelectedDate] = useState(null);
  const [sourceDate, setSourceDate] = useState(null);

  const handleDateChange = (newDate) => {
    const formattedDate = dayjs(newDate).format('YYYY/MM/DD');
    getEmplyeeScheduledDetailsCalendar(formattedDate);
    setSourceDate(formattedDate);
    setSelectedDate(formattedDate);
  };

//  const formateDate(){

//   }

  const [employeeNames, setEmployeeNames] = React.useState();
  const getEmployeeNames = () => {
    api.getData('/api/employee_management/get_employee_names?merchant_id=' + response?.merchant_id)
      .then((response) => {
        console.log('response', response);
        if (response.data.employee_names_status[0].Status === "200") {
          setEmployeeNames(JSON.parse(response.data.employee_names_status[0]?.EmployeeNames));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  const fetchShifts = () => {
    api.getData('/api/employee_management/get_shift_details')
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          console.log(response);
          console.log('JSON.parse(response.data[0].EmployeeShiftDetails)', JSON.parse(response.data[0].EmployeeCurrentShiftDetails));
          setShifts(JSON.parse(response.data[0].EmployeeCurrentShiftDetails));

        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  const getEmplyeeScheduledDetailsCalendar = (date) => {
    api.getData('/api/employee_management/emplyee_Scheduled_Details_calendar?merchant_id=' + response?.merchant_id + "&source_date=" + date)
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setSheduleDetailsCalender(JSON.parse(response.data[0].EmployeeScheduledDetails));

        }


      })
      .catch((error) => {
        console.log(error)
      })


  }




  const [location, setLocation] = React.useState();
  const fetchCities = () => {
    api.getData('/api/locations/get_Location_name_pos?merchant_id=' + response?.merchant_id + '&city_id=' + response?.city_id)
      .then((response) => {

        if (response.data[0]?.Status === "200") {

          setLocation(JSON.parse(response.data[0].LocationDetails));

        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  useEffect(() => {
    fetchShifts();
    getEmployeeNames();
    fetchCities();
  }, []);

  const [startTime, setStartTime] = React.useState();
  const [shiftId, setShiftId] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const getShiftData = (e) => {

    const result = shifts.filter((item) => { if (item.shift_id === e.target.value) { return item } });
    setStartTime(result[0].starting_time);
    setEndTime(result[0].ending_time);
  }

  // const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: 'success',
      title: message,
    }).then(() => {
      handleClose();
    })
  };

  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };

  const handleSubmit = (values) => {

    if (values.start_time === "") {
      values.start_time = startTime;
    }
    if (values.end_time === "") {
      values.end_time = endTime;
    }

    api.postData('/api/employee_management/InsertOrUpdateEmployeeShiftSchedule', values).then((response) => {
      if (response.data[0].Status === "200") {
        // setEmpId(response.data[0].new_employee_id)
        showSwalWithLink(response.data[0].Message);
        getEmplyeeScheduledDetailsCalendar(sourceDate);
      } else {
        showSwalWithError(response.data[0].Message);
      }

    })
  };

  const deleteShiftShedule = (values) => {
    console.log(selectedItem);
    const data = {
      'employee_id': selectedItem.employee_id,
      'working_date': selectedItem.source_date,
    }
    api.postData('/api/employee_management/Delete_Schedule_Employee', data).then((response) => {
      if (response.data.delete_schedule[0].Status === "200") {
        showSwalWithLink(response.data.delete_schedule[0].Message);
        setOpenDelete(false);
        getEmplyeeScheduledDetailsCalendar(sourceDate);

      }

    }).catch(error => {
      console.error(error);
    });




  };

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      'scheduled_id': selectedItem?.scheduled_id ||'0',
      'scheduled_entry_date': selectedItem?.scheduled_entry_date ||new Date(),
      'employee_id': selectedItem?.employee_id || '',
      'shift_id': selectedItem?.shift_id || '',
      // 'start_date': selectedItem?.start_date || '',
      // 'end_date': new Date(selectedItem?.source_date) || '',
      "start_date":"",
      "end_date":"",
      'start_time': selectedItem?.start_time || '',
      'end_time': selectedItem?.end_time || '',
      'scheduled_status': selectedItem?.scheduled_status ||'0',
    },
    validationSchema: shiftShedules,
    onSubmit: handleSubmit,
    // enableReinitialize: true,
  });

  useEffect(() => {
    if (selectedItem) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        ...selectedItem,
      }));
    }
  }, [selectedItem]);


  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
  } = formik;

  const driverData = [
    // ["Gabby George", "Business Analyst", "Minneapolis",""],
  ];
  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
  };

  useEffect(() => {
    const formattedDate = dayjs(new Date()).format('YYYY/MM/DD');
    getEmplyeeScheduledDetailsCalendar(formattedDate);
  }, [])

  const pricingOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
  };
  const [value, setValue] = React.useState(0);

  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const ref4 = React.useRef();
  const ref1 = React.useRef();
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid container mt={2}>
                    <Grid item xs="12">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                        className="filters"
                      >
                        {/* <Box className="filters-display">
                          <Select
                            labelId="demo-isolation-label"
                            id="isolation-select"
                            className="select"
                            defaultValue="locations"
                          >
                            <MenuItem value="locations">All Locations</MenuItem>
                            <MenuItem>Debray</MenuItem>
                          </Select>
                        </Box> */}
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Button
                            variant="outlined"
                            className="btn-outline-primary btn-space"
                            onClick={() => { handleOpen() }}
                          >
                            Schedule Employee
                          </Button>
                          <Button
                            variant="outlined"
                            className="btn-outline-primary btn-space"
                            onClick={() => ref1.current.log()}
                            disabled={sheduleDetailsCalender == undefined || sheduleDetailsCalender.length == 0}
                          // sheduleDetailsCalender
                          >
                            Clone
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item md={4}>
                      <Card>
                        <CardContent>
                          <Grid container>
                            <Grid item md={12}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                                className="shift-shedule-tabs"
                                mb={1}
                              >
                                <Tabs
                                  value={value}
                                  onChange={handleChanges}
                                  aria-label="basic tabs example"
                                >
                                  <Tab label="Calender" {...a11yProps(0)} />
                                  <Tab label="Map" {...a11yProps(1)} />
                                </Tabs>
                              </Box>
                            </Grid>
                            <Grid item md="12">
                              <TabPanel
                                value={value}
                                index={0}
                                className="custom-shift-tab"
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <StaticDatePicker
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                  />
                                </LocalizationProvider>
                              </TabPanel>
                              <TabPanel value={value} index={1}></TabPanel>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={8}>
                      <Card>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item md="12">
                              <MemberTable
                                columns={EmpColumns}
                                options={managementOptions}
                                data={sheduleDetailsCalender ? sheduleDetailsCalender : []}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <ProfileModal ref={ref} />
        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="shift-modal">
            <Box className="shift-header" mb={2}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Delete Confirmation
              </Typography>
              <Close onClick={handleCloseDelete} />
            </Box>
            <Divider></Divider>
              <Grid container mb={3} mt={2}>
               You want to delete the shift?
              </Grid>
              <Divider></Divider>
              <Box mt={3}>
              
                <Button variant="outlined" color="error" className="save-btn" onClick={deleteShiftShedule}>
                  yes
                </Button>
                <Button variant="outlined" color="primary" className="save-btn" onClick={()=>setOpenDelete(false)}>
                  No
                </Button>
              </Box>
          </Box>
        </Modal>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="shift-modal">
            <Box className="shift-header" mb={2}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
              SCHEDULE EMPLOYEE
              </Typography>
              <Close onClick={handleClose} />
            </Box>
            <Divider></Divider>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} mb={3} mt={2}>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <Typography component="label">Employee Name</Typography>
                    <Select
                      labelId="demo-isolation-label"
                      id="isolation-select"
                      className="select"
                      name="employee_id"
                      value={formik.values.employee_id}
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => { handleBlur(e); }}
                      defaultValue="0"
                    >
                      <MenuItem value="0">Please Select</MenuItem>
                      {
                        employeeNames?.map((item) => {
                          return <MenuItem value={item.employee_id}>{item.employee_name}</MenuItem>
                        })
                      }
                    </Select>
                    {errors.employee_id && touched.employee_id && <Box Typography="small" className="float-left text-danger">{errors.employee_id}</Box>}

                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <Typography component="label">Locations</Typography>
                    <Select
                      labelId="demo-isolation-label"
                      id="isolation-select"
                      className="select"
                      name="location_id"
                      //value={formik.values.location_id}

                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => { handleBlur(e); }}
                      defaultValue="0"
                    >
                      <MenuItem value="0">Please Select</MenuItem>
                      {
                        location?.map((item) => {
                          return <MenuItem value={item.merchant_location_id}>{item.location_name}</MenuItem>
                        })
                      }
                    </Select>
                    {errors.location_id && touched.location_id && <Box Typography="small" className="float-left text-danger">{errors.location_id}</Box>}

                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <Typography component="label">Shifts</Typography>
                    <Select
                      labelId="demo-isolation-label"
                      id="isolation-select"
                      className="select"
                      name="shift_id"
                      value={formik.values.shift_id}
                      onChange={(e) => { handleChange(e); getShiftData(e); }}
                      onBlur={(e) => { handleBlur(e); }}
                      defaultValue="0"
                    >
                      <MenuItem value="0">Please Select</MenuItem>
                      {
                        shifts?.map((item) => {
                          return <MenuItem value={item.shift_id} data-value={item}>{item.shift_name}</MenuItem>
                        })
                      }
                      {/* // <MenuItem>Debray</MenuItem> */}
                    </Select>
                    {errors.shift_id && touched.shift_id && <Box Typography="small" className="float-left text-danger">{errors.shift_id}</Box>}

                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <Typography component="label">Schedule Start Date</Typography>
                    <OutlinedInput
                      type="date"
                      name="start_date"
                      //value={formik.values.start_date}

                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => { handleBlur(e); }}
                    />
                    {errors.start_date && touched.start_date && <Box Typography="small" className="float-left text-danger">{errors.start_date}</Box>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <Typography component="label">Schedule End Date</Typography>
                    <OutlinedInput
                      type="date"
                      name="end_date"
                      value={formik.values.end_date}

                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => { handleBlur(e); }}
                    />
                    {errors.end_date && touched.end_date && <Box Typography="small" className="float-left text-danger">{errors.end_date}</Box>}

                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <Typography component="label">Start Time</Typography>

                    <OutlinedInput
                      type="time"
                      name="start_time"
                      value={startTime}
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => { handleBlur(e); }}
                    />

                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl>
                    <Typography component="label">End Time</Typography>
                    <OutlinedInput
                      type="time"
                      name="end_time"
                      value={endTime}
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => { handleBlur(e); }}
                    />

                  </FormControl>
                </Grid>
              </Grid>
              <Divider></Divider>
              <Box mt={3}>
                <Button variant="outlined" color="error" className="save-btn" type="submit">
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <ScheduleEmployeeModal ref={ref4} shifts={shifts} selectedItem={selectedItem} />
        <CloneModal ref={ref1} sheduleDetailsCalender={sheduleDetailsCalender} updateData={getEmplyeeScheduledDetailsCalendar} sourceDate={sourceDate} />
      </Box>
    </>
  );
}
