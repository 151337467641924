import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";

import Table from "@material-ui/core/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Bredcrum from "../Bredcrum";
import StoreNewItemModal from "./StoreNewItemModal";
import product1 from "../images/product-1.png";
import product2 from "../images/product-2.png";
import dal from "../images/dal3.jpg";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import api from "../Api";
import { useSelector, useDispatch } from "react-redux";
import StoreAddItemModal from "./StoreAddItemModal";
import MemberTable from "../Customer/MemberTable";
import { CSVLink } from "react-csv";

import {
  setMeasurementId,
  setProductId,
  setProductSku,
  setCity,
  setSegments,
  // setItemStock,
} from "../Redux/actions/userActions";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export default function StockManagement() {
  const response = useSelector((state) => state.user.user);
  const segments = useSelector((state) => state.user.segments);
  const [showState, setShowState] = React.useState(false);
  const dispatch = useDispatch();
  const productIdGlobal = useSelector((state) => state.user.product_id);
  const [functionality, setFunctionality] = React.useState();
  const productId = useSelector((state) => state.user.product_id);
  const measurementId = useSelector((state) => state.user.measurement_id);
  const [itemStockDetails, setItemStockDetails] = React.useState();
  // const itemStock = useSelector((state) => state.user.item_stock);
  const [itemStock,setItemStock] = React.useState();
  const MySwal = withReactContent(Swal);
  const [active, changeActive] = React.useState(1);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    });
  };
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const [ingredientNames, setIngredientNames] = React.useState();
  const getIngredientName = () => {
    api.getData("/api/items/Get_Ingredient_Name").then((response) => {
      if (response.data[0]?.Status === "200") {
        setIngredientNames(JSON.parse(response.data[0].IngredientNames));
      }
    });
  };
  const addItem = () =>{

    ref4.current.log();
  }
  const handleItemStatusChange = (rowData) => {
    api
      .postData("/api/items/Merchant_Items_Update_Product_status", {
        merchant_id: rowData.merchant_id,
        product_id: rowData.product_id,
      })
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          showSwalWithLink(response.data[0].Message);
          getItemStockDetails();
          // setSuccess(true);
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };
  const [filterItemStockDetails, setFilterItemStockDetails] = React.useState();
  const getItemStockDetails = () => {
    api
      .getData(
        "/api/items/Menu_Supply_Management_details?merchant_id=" +
          response?.merchant_id + "&ptype=Product"
      )
      .then((response) => {
        //   console.log('response',response);
        if (response.data[0]?.Status === "200") {
          setItemStockDetails(JSON.parse(response.data[0]?.Item_Details));
          setFilterItemStockDetails(
            JSON.parse(response.data[0]?.Item_Details)
          );
          dispatch(
            setProductId(
              JSON.parse(response.data[0]?.Item_Details)[0].product_id
            )
          );
          dispatch(
            setMeasurementId(
              JSON.parse(response.data[0]?.Item_Details)[0].measurement_id
            )
          );
          //   setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [ingredient, setIngredient] = React.useState();
  const fetchIngredientTableData = (pid) => {
    
    api
      .getData(
        "/api/segments/get_mapping_items_details?product_id=" + (pid?pid:productIdGlobal)
      )
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          setIngredient(JSON.parse(response.data[0].Item_Ingredient_Details));
          //    setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchTableData = (pid) => {
    
    api
      .getData(
        "/api/segments/get_mapping_items_details?product_id=" + (pid?pid:productIdGlobal)
      )
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          setIngredient(JSON.parse(response.data[0].Item_Ingredient_Details));
          //    setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleFilterSegment = (itemId) => {
    if (itemId == 0) {
      setItemStockDetails(filterItemStockDetails);
    } else {
      const filteredData = filterItemStockDetails.filter((item) => {
        return item.merchant_segment_id === itemId;
      });
      setItemStockDetails(filteredData);
    }
  };
  const handleFilterProduct = (itemId) => {
    if (itemId == 0) {
      setItemStockDetails(filterItemStockDetails);
    } else {
      const filteredData = filterItemStockDetails.filter((item) => {
        return item.product_id === itemId;
      });
      setItemStockDetails(filteredData);
    }
  };
  const handleFilterSubSegment = (itemId) => {
    if (itemId == 0) {
      setItemStockDetails(filterItemStockDetails);
    } else {
      const filteredData = filterItemStockDetails.filter((item) => {
        return item.merchant_sub_segment_id === itemId;
      });
      setItemStockDetails(filteredData);
    }
  };
  const [segmentsList, setSegmentList] = React.useState();
  const [subSegmentsList, setSubSegmentList] = React.useState();
  const [productList, setProductList] = React.useState();
  const getSegment = () => {
    api
      .getData(
        "/api/segments/get_merchant_segment_details?merchant_id=" +
          response?.merchant_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          dispatch(
            setSegments(JSON.parse(response.data[0].Merchant_Segement_Details))
          );
          setSegmentList(
            JSON.parse(response.data[0].Merchant_Segement_Details)
          );
          setSubSegmentList(
            JSON.parse(response.data[0].Merchant_Sub_Segement_Details)
          );
          setProductList(
            JSON.parse(response.data[0].Product_Details)
          );
        }
      });
  };

  useEffect(() => {
    getSegment();
    getIngredientName();
    fetchIngredientTableData();
  }, []);

  const getItemDetailsStock = (ProductId) => {
    api
      .getData(
        "/api/items/Get_Item_Ingredient_stock_Details?merchant_id=" +
          response?.merchant_id +
          "&product_id=" +
          ProductId + "&ptype=Product"

      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setItemStock(JSON.parse(response.data[0]?.ItemDetails)[0]);
          // setItemStock(JSON.parse(response.data[0]?.ItemDetails)[0]);

          //   setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getItemStockDetails();
    segmentGetData();
  }, []);

  useEffect(() => {
    if (itemStockDetails) {
      getItemDetailsStock(
        productIdGlobal
      );
    }
  }, [itemStockDetails]);
  const handleSegmentStatusChange = (event, id) => {
    setSwitchValue(event?.target?.checked ? 1 : 0);
    api
      .postData("/api/segments/change_segment_status", {
        segment_id: id,
        merchant_id: response.merchant_id,
      })
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          // showSwalWithLink();
          // setSuccess(true);
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };
  const [filterSuggestions, setFilterSuggestions] = React.useState();
  const segmentGetData = () => {
    api
      .getData("/api/segments/master_segments")
      .then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          // setSuggestions(JSON.parse(response.data[0].MasterSegmentDetails));
          setFilterSuggestions(JSON.parse(response.data[0].SegmentDetails));
          // setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [switchValue, setSwitchValue] = React.useState(0);
  const productStock = [
    {
      name: "product_image",
      label: " ",
      //    label:'',
      options: {
        customBodyRender: (value) => (
          <img
            src={value?value.split(',')[0]:value}
            alt="Product Image"
            style={{ width: 40, height: 40 }}
          />
        ),
      },
    },
    { name: "product_id", label: "Item Id" },
    { name: "product_name", label: "Item Name" },
    { name: "segment_name", label: "Segment" },
    { name: "sub_segment_name", label: "Sub Segment" },
    { name: "box_quantity", label: "Qty/Box" },
    { name: "category", label: "Category" },
    
    { name: "sale_price", label: "Sale Price" },
    // {
    //   name: "product_status",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const rowIndex = tableMeta.rowIndex;
    //       const rowData = itemStockDetails[rowIndex];
    //       return (
    //         <IOSSwitch
    //           checked={value === 0}
    //           onChange={() => {
    //             handleItemStatusChange(rowData);
    //           }}
    //         />
    //       );
    //     },
    //   },
    // },
  ];

  const editProduct = () => {
    
  };

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const memeberColumns = [
    "",
    { name: "Segment", options: { filterOptions: { fullWidth: true } } },
    "Sub Segment",
    "Name",
    "Date",
    "Qty",
    "Sale Price",
  ];
  // const postStatus = (item) => {
  //    let data={
  //     'product_stock_id': item.product_stock_id,
  //     'product_id': item.product_id,
  //     'measurement_id': item.measurement_id

  //    }
  //     try {
  //         api.postData('/api/items/stock_status_change',data).then((response) => {
  //             console.log('response', response);
  //             if (response.data[0].Status === "200") {
  //                 showSwalWithLink(response.data[0].Message);
  //             }

  //         })

  //     } catch (error) {
  //         // Handle network error
  //     }

  // };
  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onFilterChange: false,
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    rowsPerPage: 10,
    onRowClick: (rowData, rowMeta,rowIndex) => {
      const clickedRowIndex = rowMeta.dataIndex;
      const clickedRowData = itemStockDetails[clickedRowIndex];
      getItemDetailsStock(
        clickedRowData.product_id
      );

      dispatch(setProductId(clickedRowData.product_id));
      dispatch(setMeasurementId(clickedRowData.measurement_id));
      fetchIngredientTableData(clickedRowData.product_id);
    },
    expandableRows: false,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData);
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Taxes:</b> ₹ 485.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Advance:</b> ₹ 321.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Due Amount:</b> ₹ 542.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>State:</b> State
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    // page: 0,
  };

  const data = [
    [
      <img src={product1} />,
      "Coffee",
      "Beverages",
      "Bru coffee",
      "21 Aug 2021",
      "86",
      "₹ 200.00",
    ],
    [
      <img src={product2} />,
      "Coffee",
      "Beverages",
      "Bru coffee",
      "21 Aug 2021",
      "86",
      "₹ 200.00",
    ],
  ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              {/* <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link>
                    <Link underline="hover" color="inherit" href="#">
                      Product Catalogue
                    </Link>
                    <Typography color="text.primary">
                      Stock Management
                    </Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                    <Select
                      labelId="demo-isolation-label"
                      id="menu1"
                      className="select"
                      // onChange={handleSelectOxygen}
                      // input={<OutlinedInput />}
                      defaultValue="Suppliers"
                    >
                      <MenuItem value="Suppliers">Suppliers</MenuItem>
                      <MenuItem value="visakhapatnam">S.S.M Kirana</MenuItem>
                      <MenuItem value="vijayawada">
                        Sri Raghavendra Super Market
                      </MenuItem>
                      <MenuItem value="vijayawada">
                        Suryanarayana Kirana and General
                      </MenuItem>
                      <MenuItem value="vijayawada">
                        SKML Kirana and Genral
                      </MenuItem>
                    </Select>
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid> */}
              <Bredcrum />
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box mb={2} className="sm-selects">
                          <Typography component="h4" className="sub_title">
                            MENU
                            <br /> MANAGEMENT
                          </Typography>
                          <Box>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select filter_select"
                              onChange={(e) => {
                                handleFilterSegment(e.target.value);
                              }}
                              // input={<OutlinedInput />}
                              defaultValue="0"
                            >
                              <MenuItem value="0">Segment All</MenuItem>
                              {segmentsList?.map((item, index) => {
                                return (
                                  <MenuItem value={item.merchant_segment_id}>
                                    {item.merchant_segment_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select filter_select"
                              onChange={(e)=>{handleFilterSubSegment(e.target.value)}}
                              // input={<OutlinedInput />}
                              defaultValue="0"
                            >
                              <MenuItem value="0">Sub Segment All</MenuItem>
                              {subSegmentsList?.map((item, index) => {
                                return (
                                  <MenuItem value={item.merchant_sub_segment_id}>
                                    {item.merchant_sub_segment_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {/* <Select
                              labelId="demo-isolation-label"
                              id="isolation-select"
                              className="select filter_select"
                              onChange={(e)=>{handleFilterProduct(e.target.value)}}
                              // onChange={handleSelectOxygen}
                              // input={<OutlinedInput />}
                              defaultValue="0"
                            >
                              <MenuItem value="0">All</MenuItem>
                              {productList?.map((item, index) => {
                                return (
                                  <MenuItem value={item.product_id}>
                                    {item.product_name}
                                  </MenuItem>
                                );
                              })}
                            </Select> */}
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn ml-15"
                              onClick={()=>addItem()}
                            >
                              Add New Item
                            </Button>
                            <CSVLink data={itemStockDetails ? itemStockDetails : []} filename={"ingredient.csv"}>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn"
                              mr={2}
                            >
                              Download
                            </Button>
                            </CSVLink>
                          </Box>
                        </Box>
                        <Box className="member-table w-100">
                          
                          <MemberTable
                            columns={productStock}
                            options={options}
                            data={itemStockDetails ? itemStockDetails : []}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box>
                          <Grid container mb={2}>
                            <Box className="flex-between w-100">
                              <Typography
                                style={{ textTransform: "uppercase" }}
                                component="h4"
                              >
                                Item ID -{" "}
                                {itemStock?.product_id}
                              </Typography>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                className="retail-btn"
                                onClick={() =>{setFunctionality("edit");ref3.current.log()}}
                              >
                                View More
                              </Button>
                            </Box>
                          </Grid>

                          <Box className="information-div" mt={2}>
                            <Box
                              component="img"
                              className="w-80"
                              src={itemStock?.product_image?itemStock.product_image.split(',')[0] : dal}
                            />
                            <Box className="w-81">
                              <Box className="flex-between">
                                <Box>
                                  <Typography
                                    component="h2"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {itemStock?.product_name}
                                  </Typography>
                                  <Typography component="h4">
                                  {itemStock?.prod_uom?parseInt(itemStock?.prod_uom):''}{itemStock?.product_uom}
                                  </Typography>
                                </Box>
                                <Typography
                                  component="span"
                                  className="text-danger fw-bold"
                                >
                                  Rs {itemStock?.sale_price}/-
                                </Typography>
                              </Box>
                              <Box>
                              {/* <Button
                                className={
                                  active === 1 ? "retail-btn" : "normal-text"
                                }
                                style={{ textTransform: "uppercase" }}
                                onClick={() => changeActive(1)}
                              > */}
                                {" "}
                                {itemStock?.uom}
                              {/* </Button> */}
                            </Box>
                            <Typography className="font-normal" mt={1}>
                              {itemStock?.product_description}
                            </Typography>
                            </Box>
                          </Box>
                          <Box className="w-50 text-center">
                            
                          </Box>
                        </Box>

                        <Box
                          sx={{ width: "100%" }}
                          mt={2}
                          className="fullstack-div"
                        >
                          {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                              className="stack-tabs"
                            >
                              <Tab label="Description" {...a11yProps(0)} />
                              <Tab label="Specifications" {...a11yProps(1)} />
                              <Tab label="Instructions" {...a11yProps(2)} />
                            </Tabs>
                          </Box> */}
                          {/* <TabPanel value={value} index={0}>
                            <Typography component="span" className="f-12">
                              {itemStock?.product_description}
                            </Typography>
                          </TabPanel> */}
                          {/* <TabPanel value={value} index={1}>
                            <Typography component="span" className="f-12">
                              {itemStock?.product_specifications}
                            </Typography>
                          </TabPanel>
                          <TabPanel value={value} index={2}>
                            <Typography component="span" className="f-12">
                              {itemStock?.product_how_to_use}
                            </Typography>
                          </TabPanel> */}
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <StoreNewItemModal fetchTableData={fetchTableData} ref={ref3} getItemStockDetails={getItemStockDetails} itemStock={itemStock} fetchIngredientTableData={fetchIngredientTableData} segmentsList={segmentsList} ingredientNames={ingredientNames} ingredient={ingredient} functionality={functionality}  />
        <StoreAddItemModal fetchTableData={fetchTableData} ref={ref4} getItemStockDetails={getItemStockDetails} segmentsList={segmentsList} ingredientNames={ingredientNames} ingredient={ingredient} fetchIngredientTableData={fetchIngredientTableData} />
      </Box>
    </>
  );
}
