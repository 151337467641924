import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import MemberTable from "../Customer/MemberTable";
import api from "../Api";
import withReactContent from "sweetalert2-react-content";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { addEditTax } from "../Validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddTaxesModal = forwardRef((props, ref1) => {
  const [showState, setShowState] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [segmentIdList, setSegmentIdList] = React.useState([]);
  const handleSegmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setSegmentIdList(
      // On autofill we get a stringified value.
      value
    );
  };
  const [subSegmentIdList, setSubSegmentIdList] = React.useState([]);
  const handleSubSegmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setSubSegmentIdList(
      // On autofill we get a stringified value.
      value
    );
  };
  const response = useSelector((state) => state.user.user);
  const handleClick = (value) => {
    setShowState(value);
  };
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      // props?.onUpdate(true);
      props?.getTaxfeemerchantdetails();
      handleClose();
    });
  };

  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };
  const handleSubmit = (values) => {
    if (formik.values.tax_segment && !(formik.values.tax_on === 2)) {
      formik.values.tax_segment = segmentIdList?.toString(',');
    }

    if (formik.values.tax_sub_segment && !(formik.values.tax_on === 2)) {
      formik.values.tax_sub_segment = subSegmentIdList?.toString(',');
    }
    if (formik.values.tax_on === 2) {
      delete formik.values.tax_sub_segment;
      delete formik.values.tax_segment;
    }
    api
      .postData("/api/tax_fee/insert_update_taxes_merchant", formik.values)
      .then((response) => {

        if (response.data[0].status === "200") {
          // setEmpId(response.data[0].new_employee_id)
          showSwalWithLink(response.data[0].message);
        } else {
          showSwalWithError(response.data[0].message);
        }
      });
  };

  const initialValues = {
    tax_id: "0",
    merchant_id: response?.merchant_id,
    tax_name: "",
    tax_type: "",
    tax_amount_value: "",
    tax_on: "1",
    tax_segment: "0",
    tax_sub_segment: "0",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: addEditTax,
    onSubmit: handleSubmit,
    // enableReinitialize: true,
  });

  useImperativeHandle(ref1, () => ({
    log() {
      handleOpen();
    },
  }));
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product ">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              ADD NEW TAXES
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              mt={1}
              mb={3}
              className="pickers"
              spacing={3}
              paddingRight={2}
            >
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Tax Name</Typography>
                  <OutlinedInput
                    placeholder="Tax Name"
                    name="tax_name"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  {errors.tax_name && touched.tax_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.tax_name}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Tax Type</Typography>
                  {/* <OutlinedInput
                    placeholder="Tax type"
                    name="tax_type"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  /> */}
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    onChange={handleChange}
                    name="tax_type"
                    defaultValue="0"
                  >
                    <MenuItem value="fixed">Fixed</MenuItem>
                    <MenuItem value="percent">Percentage</MenuItem>
                  </Select>
                  {errors.tax_type && touched.tax_type && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.tax_type}
                    </Box>
                  )}
                </FormControl>
              </Grid>
              <Grid item md={4}>
                <FormControl className="form_control" fullWidth>
                  <Typography component="label">Tax Amount</Typography>
                  <OutlinedInput
                    placeholder="Tax Amount"
                    name="tax_amount_value"
                    value={formik.values.tax_amount_value}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  
                  
                  {errors.tax_amount_value && touched.tax_amount_value && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.tax_amount_value}
                    </Box>
                  )}
                </FormControl>
              </Grid>

              {/* <Grid item md={6}>
                <FormControl className="w-100">
                  <Typography component="label">Tax Applicable Type</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select viewmore-dpdown"
                    name="tax_on"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}

                    defaultValue="0"
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      handleChange(e);
                      handleClick(e.target.value);
                    }}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    {props.taxMaster?.map((item) => {
                      return (
                        <MenuItem value={item.master_tax_id}>
                          {item.master_tax_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.tax_on && touched.tax_on && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.tax_on}
                    </Box>
                  )}
                </FormControl>
              </Grid> */}

            </Grid>
            {/* <Grid container spacing={2} mb={2} mt={1} paddingRight={2}>
              <Grid item md={6}>
                <FormControl className="w-100">
                  <Typography component="label">Segment</Typography>
                  <OutlinedInput
                    placeholder="Fee Amount"
                    name="tax_segment"
                    // value={formik.values.tax_segment}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                  
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl className="w-100">
                  <Typography component="label">Sub Segment</Typography>
                  <OutlinedInput
                    placeholder="Fee Amount"
                    name="tax_sub_segment"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                    }}
                  />
                 
                </FormControl>
              </Grid>
            </Grid> */}


            <Divider></Divider>
            <Box className="p-15" mb={2}>
              <Button
                variant="outlined"
                color="error"
                className="retail-btn float-right"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
});

export default AddTaxesModal;
