import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import LocationModal from "./LocationModal";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function CityManagement() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref4 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const eiColumns = [
    "Name",
    "Radius",
    "City Manager",
    {
      options: {
        label: "STATUS",
        customBodyRender: () => {
          return <IOSSwitch onClick={() => ref2.current.log()} />;
        },
      },
    },
  ];
  const eiData = [
    ["#963258", "Srikakulam", "Ramya"],
    ["#963258", "Srikakulam", "Ramya"],
    ["#856214", "Vizianagaram", "Srilekha"],
  ];
  const mlColumns = [
    "DATE",
    "TYPE",
    "TASK",
    "LIFE OF REPAIR",
    "COST",
    "COMMENTS",
  ];

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Taxes:</b> ₹ 485.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Advance:</b> ₹ 321.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Due Amount:</b> ₹ 542.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>State:</b> State
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    pagination: true,
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link>
                    <Link underline="hover" color="inherit" href="#">
                      Account
                    </Link>
                    <Typography color="text.primary">Add Merchant</Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid>
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            CITY
                          </Typography>
                        </Box>

                        <Box className=" w-95 p-15 member-table">
                          <MemberTable
                            columns={eiColumns}
                            options={options}
                            data={eiData}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            CREATE CITY
                          </Typography>
                        </Box>

                        <Grid container spacing={3} className="p-15">
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                City Name
                              </Typography>
                              <OutlinedInput value="" placeholder="Name" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                City Manager
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">Manager 1</MenuItem>
                                <MenuItem value="visakhapatnam">
                                  Manager 2
                                </MenuItem>
                                <MenuItem value="vijayawada">
                                  Manager 3
                                </MenuItem>
                                <MenuItem value="vijayawada">
                                  Manager 4
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Location
                              </Typography>
                              <OutlinedInput value="" placeholder="Location" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">Radius</Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">1</MenuItem>
                                <MenuItem value="visakhapatnam">50 KM</MenuItem>
                                <MenuItem value="vijayawada">75 KM</MenuItem>
                                <MenuItem value="vijayawada">80 KM</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Latitude
                              </Typography>
                              <OutlinedInput value="" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Longitude
                              </Typography>
                              <OutlinedInput value="" />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                StoreToDoor Open
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="OpenTime"
                              >
                                <MenuItem value="OpenTime">Open Time</MenuItem>
                                <MenuItem value="visakhapatnam">
                                  9:00AM
                                </MenuItem>
                                <MenuItem value="vijayawada">9:30AM</MenuItem>
                                <MenuItem value="vijayawada">10:00AM</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                StoreToDoor Close
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">
                                  Close Time
                                </MenuItem>
                                <MenuItem value="visakhapatnam">
                                  9:00PM
                                </MenuItem>
                                <MenuItem value="vijayawada">9:00PM</MenuItem>
                                <MenuItem value="vijayawada">9:00PM</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Late Night Open
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">Open Time</MenuItem>
                                <MenuItem value="visakhapatnam">
                                  12:00AM
                                </MenuItem>
                                <MenuItem value="vijayawada">12:00AM</MenuItem>
                                <MenuItem value="vijayawada">12:00AM</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                DoorToDoor Open
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="OpenTime"
                              >
                                <MenuItem value="OpenTime">Open Time</MenuItem>
                                <MenuItem value="visakhapatnam">
                                  9:00AM
                                </MenuItem>
                                <MenuItem value="vijayawada">9:30AM</MenuItem>
                                <MenuItem value="vijayawada">10:00AM</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                DoorToDoor Close
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">
                                  Close Time
                                </MenuItem>
                                <MenuItem value="visakhapatnam">
                                  9:00PM
                                </MenuItem>
                                <MenuItem value="vijayawada">9:00PM</MenuItem>
                                <MenuItem value="vijayawada">9:00PM</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Late Night Close
                              </Typography>
                              <Select
                                labelId="demo-isolation-label"
                                id="isolation-select"
                                className="select"
                                // onChange={handleSelectOxygen}
                                // input={<OutlinedInput />}
                                defaultValue="Suppliers"
                              >
                                <MenuItem value="Suppliers">Open Time</MenuItem>
                                <MenuItem value="visakhapatnam">
                                  12:00AM
                                </MenuItem>
                                <MenuItem value="vijayawada">12:00AM</MenuItem>
                                <MenuItem value="vijayawada">12:00AM</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} className="p-15">
                          <Grid item md={4}>
                            <FormGroup>
                              <FormControlLabel
                                className="check-fs"
                                control={<Checkbox />}
                                label="Groceries"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item md={4}>
                            <FormGroup>
                              <FormControlLabel
                                className="check-fs"
                                control={<Checkbox />}
                                label="Pan Shop"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item md={4}>
                            <FormGroup>
                              <FormControlLabel
                                className="check-fs"
                                control={<Checkbox />}
                                label="Fruit & Veg"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item md={4}>
                            <FormGroup>
                              <FormControlLabel
                                className="check-fs"
                                control={<Checkbox />}
                                label="Restaurant"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item md={4}>
                            <FormGroup>
                              <FormControlLabel
                                className="check-fs"
                                control={<Checkbox />}
                                label="Pharmacy"
                              />
                            </FormGroup>
                          </Grid>
                          <Grid item md={4}>
                            <FormGroup>
                              <FormControlLabel
                                className="check-fs"
                                control={<Checkbox />}
                                label="Meat & Seafood"
                              />
                            </FormGroup>
                          </Grid>
                        </Grid>
                        <Box className="p-15 text-center">
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={3}
                            className="retail-btn mr-20"
                          >
                            Submit
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <LocationModal ref={ref4} />
      </Box>
    </>
  );
}
