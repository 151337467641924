import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
// import Functions from '../../functions';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {useSelector, useDispatch } from 'react-redux';

function ProfileComplete(params) {
//google api
const [autocomplete, setAutocomplete] = useState(null);
const inputRef = useRef(null);
const cityRef = useRef(null);
const [locationdet,setLocationdet]=useState({dbid:0,manager_name:'', manager_number:'', address:'', location_name:'', packing_charges:'', city:'', location_latitude:'', location_longitude:'', locality:'', sub_locality:'', route:'', pincode:'', opening_time:'', closing_time:'', opening_time2:'', closing_time2:'', order_delivery_days:'', closed_days:''})
const [selectedDays, setSelectedDays] = useState([]);

const handleHolidaysChange = (event) => {
  const value = event.target.value;
  if (event.target.checked) {
    setSelectedDays([...selectedDays, value]);
  } else {
    setSelectedDays(selectedDays.filter(day => day !== value));
  }
}
const handleLocationDet = (event) => {
  const { name, value } = event.target;
  setLocationdet({
    ...locationdet,
    [name]: value,
  });
};
useEffect(() => {
  // load the Google Maps JavaScript API library
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAMWSr2YSC6925JdAvbRyfjaiRsF8rPxA4&libraries=places`;
  script.async = true;
  script.defer = true;
  script.addEventListener('load', () => {
    // initialize the Autocomplete object
    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
    setAutocomplete(autocomplete);
  });
  document.body.appendChild(script);

  // clean up the script tag
  return () => {
    document.body.removeChild(script);
  };
}, []);

useEffect(() => {
  if (autocomplete) {
    // set the component state when a suggestion is selected
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      console.log(JSON.stringify(place));
      setLocationdet(prevState => ({ ...prevState, address: place.formatted_address}))
      setLocationdet(prevState => ({ ...prevState, locality: getAddressComponent(place, 'locality')}))
      setLocationdet(prevState => ({ ...prevState, sub_locality: getAddressComponent(place, 'sublocality')}))
      setLocationdet(prevState => ({ ...prevState, city: cityRef.current.value}))
      setLocationdet(prevState => ({ ...prevState, pincode: getAddressComponent(place, 'postal_code')}))
      setLocationdet(prevState => ({ ...prevState, location_longitude: place.geometry.location.lng()}))
      setLocationdet(prevState => ({ ...prevState, location_latitude: place.geometry.location.lat()}))
    });
  }
}, [autocomplete]);

const getAddressComponent = (place, type) => {
  // get the value of a specific address component (e.g., locality, sublocality, postal code)
  const component = place.address_components.find(component => component.types.includes(type));
  return component ? component.long_name : '';
};
//
const Functions = () =>{
    
}
  const {Update_Location_Status,Create_Merchant_Location, UpdatePofileBasic, Create_Segment, Update_Segment_Status,Create_Sub_Segment, Update_Sub_Segment_Status}= Functions();
  const [isgst,setIsgst]= useState(false);
  const togglegst=()=>{
    setIsgst(!isgst);
    if(isgst ===false){
      setGstdata({gstnumber:'Not Enterd',gst_percentage:'Not Entered'});
    }
  }

  const location= useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mdata1=useSelector(state=>state.merchant.merchant);
   const [mdata, setMdata]= useState({
    businessName:'',
    serviceType:'',
    city:'',
    eMail:'',
    phoneNumber:'',
    profile_pic:''
})


const handleProfileChange = (event) => {
  const { name, value } = event.target;
  setMdata({
    ...mdata,
    [name]: value,
  });
};
const mid= useSelector(state=> (state.merchantid.merchantid.results[0][0].dbid));
  const lables=JSON.parse(useSelector(state=> (state.merchantid.merchantid.results[0][0].ServiceLabel)))[0];
  const bankdata1= useSelector(state=> state.merchant_bank_info.merchant_bank_info);
 const segmentlist1= useSelector(state=>state.segments_list.segments_list);
 const subsegmentlist1=useSelector(state=>state.sub_segments_list.sub_segments_list);
 const locationslist1= useSelector(state=>state.merchant_locations_list.merchant_locations_list);
 const [segment, setSegment]=useState({dbid:0,segment:'',row:'',aisle:'',segment_pic:'/assets/module_images/segment_pics/segment.png',status:0})
 const [segmentlist, setSegmentlist]=useState([]);
 const [subsegment, setSubsegment]=useState({dbid:0,segment:'',sub_segment_name:'',row:'',aisle:'',sub_segment_pic:'/assets/module_images/segment_pics/subsegment.png',status:0})
 const [subsegmentlist, setSubsegmentlist]=useState([]);
 const [locationslist, setLocationslist]=useState([]);
  const [bankdata, setBankdata]= useState({
    accountNumber:'',
    accountHolderName:'',
    bankBranchName:'',
    bankName:'',
    googlePayNumber:'',
    ifscCode:'',
    otherUPI:'',
    paytmNumber:'',
    phoneNumber:''
  });
  const handleBankdata=(event)=>{
    const { name, value } = event.target;
    setBankdata({
      ...bankdata,
      [name]: value,
    });
  }

  useEffect(()=>{
    setMdata(mdata1);
    setBankdata(bankdata1);
    setSegmentlist(segmentlist1);
    setSubsegmentlist(subsegmentlist1);
    setLocationslist(locationslist1);
  },[mdata1 && bankdata1 && segmentlist1 && subsegmentlist1 && locationslist1]);
  const [gstdata, setGstdata]=useState({gstnumber:'Not Entered', gst_percentage:'Not Entered'});
  const handlegstdata=(event)=>{
    const { name, value } = event.target;
    setGstdata({
      ...gstdata,
      [name]: value,
    });
  }
  const UpdateProfile=()=>{
    UpdatePofileBasic(mdata.businessName, mdata.serviceType, mdata.city, mdata.eMail, mdata.phoneNumber, mdata.profile_pic,bankdata.bankName, bankdata.bankBranchName, bankdata.ifscCode, bankdata.accountNumber, bankdata.accountHolderName, bankdata.paytmNumber, bankdata.googlePayNumber, bankdata.phonePayNumber, bankdata.otherUPI, gstdata.gstnumber, gstdata.gst_percentage, mid, localStorage.getItem("token"), isgst);
  }
  const handlesegmentdata=(event)=>{
    const { name, value } = event.target;
    setSegment({
      ...segment,
      [name]: value,
    });
  }
  const CreateSegment=()=>{
  Create_Segment(segment.dbid,segment.segment,segment.row,segment.aisle,segment.segment_pic,segment.status,mid,localStorage.getItem("token"));
  }
  const handleCheckboxChange = (id) => {
    const updatedSegmentlist = segmentlist.map((item) => {
      if (item.dbid === id) {
        return { ...item, status: item.status === 0 ? 1 : 0};
        
      }
      return item;
    });

    setSegmentlist(updatedSegmentlist);
    Update_Segment_Status(id,mid, localStorage.getItem("token"))
    dispatch({ type: 'GET_USER_SEGMENT_INFO', payload: updatedSegmentlist })
  };
  const handleEditSegment = (index) => {
    setSegment(segmentlist[index]);
  };
 const CreateSubSegment=()=>{
        Create_Sub_Segment(subsegment.dbid,subsegment.segment,subsegment.sub_segment_name,subsegment.row,subsegment.aisle,subsegment.sub_segment_pic,subsegment.status,mid,localStorage.getItem("token"));
  };
const handleSubsegmentdata=(event)=>{
          const { name, value } = event.target;
          setSubsegment({
            ...subsegment,
            [name]: value,
          });
  };
const handleCheckboxChange_SS = (id) => {
          const updatedSubSegmentlist = subsegmentlist.map((item) => {
            if (item.dbid === id) {
              return { ...item, status: item.status === 0 ? 1 : 0};
              
            }
            return item;
          });
      
          setSubsegmentlist(updatedSubSegmentlist);
          Update_Sub_Segment_Status(id,mid, localStorage.getItem("token"))
          dispatch({ type: 'GET_USER_SUB_SEGMENT_INFO', payload: updatedSubSegmentlist })
  };
const handleEditSubSegment = (index) => {
    setSubsegment(subsegmentlist[index]);
    console.log(subsegmentlist[index]);
  };
const handleFreshSegment=()=>{
  setSegment({dbid:0,segment:'',row:'',aisle:'',segment_pic:'/assets/module_images/segment_pics/segment.png',status:0});
 };
 const handleFreshSubSegment=()=>{
  setSubsegment({dbid:0,segment:'',sub_segment_name:'',row:'',aisle:'',sub_segment_pic:'/assets/module_images/segment_pics/subsegment.png',status:0});
 };
 const Save_Merchant_Location=()=>{
  Create_Merchant_Location(locationdet.dbid,mid, locationdet.manager_name,locationdet.manager_number, locationdet.address, locationdet.location_name,locationdet.packing_charges,locationdet.city,locationdet.location_latitude,locationdet.location_longitude,locationdet.locality,locationdet.sub_locality,locationdet.route,locationdet.pincode,locationdet.opening_time, locationdet.closing_time,locationdet.opening_time2,locationdet.closing_time2,locationdet.order_delivery_days,selectedDays.join(', '), localStorage.getItem("token"));
 }
 const handleEditLocation = (index) => {
  setLocationdet(locationslist[index]);
 // console.log(subsegmentlist[index]);
};
const handleFreshLocation=()=>{
  if (locationdet.length >0){
    Swal.fire({
      title: '',
      text: "Already Location is configured, Do you want to configure another...???",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#9568FF',
      cancelButtonColor: '#9568FF',
      confirmButtonText: 'OK !!!'
    }).then((result) => {
      // navigate('/');
    })
  }
  setLocationdet({dbid:0,manager_name:'', manager_number:'', address:'', location_name:'', packing_charges:'', city:'', location_latitude:'', location_longitude:'', locality:'', sub_locality:'', route:'', pincode:'', opening_time:'', closing_time:'', opening_time2:'', closing_time2:'', order_delivery_days:'', closed_days:''});
 };
 const handleCheckboxChange_LI = (id) => {
  const updatedLocationsList = locationslist.map((item) => {
    if (item.dbid === id) {
      return { ...item, status: item.status === 0 ? 1 : 0};
      
    }
    return item;
  });

  setLocationslist(updatedLocationsList);
  Update_Location_Status(id,mid, localStorage.getItem("token"))
  dispatch({ type: 'GET_USER_LOCATIONS_INFO', payload: updatedLocationsList })
};
  return (
    <>
<div className="p-4">
     
     <div className="mt-3 p-1">
       <div className="card">
         <div className="card-body">
           <div className="table-head ">
             <div>
               <h4>Profile</h4>
             </div>
             <div>
               <button type="button" className="btn-primery _btn-outline" data-bs-toggle="modal" data-bs-target="#edit-modal" data-bs-dismiss="modal">Edit</button>
                 </div>
           </div>

           <div className="row">
             <div className="col-md-4">
             <div className="mb-3" >
               <img style={{borderRadius:'50px', maxHeight:'350px', maxWidth:'200px'}} src={mdata.profile_pic} width="100%"/>
             </div>
             </div>
             <div className="col-md-8">
               <div className="row">
             <div className="col-md-4">
               <div className="form-group">
                 <label><b>{lables.Owner_name}</b></label>
                 <p>{mdata.businessName}</p>
               </div>
             </div>
             <div className="col-md-4">
               <div className="form-group md-4">
                 <label for=""><b>City</b></label>
                 <p>{mdata.city}</p>
               </div>
             </div>     
             <div className="col-md-4">
               <div className="form-group">
                 <label for=""><b>Bussiness Service Name</b></label>            
                 <p>{mdata.serviceType}</p>
               </div>
             </div>  
             </div>
             <div className="row mt-1">
               <div className="col-md-4">
                 <div className="form-group me-md-4">
                   <label for=""><b>Phone Number</b></label>
                   <p>{mdata.phoneNumber}</p>          
                 </div>
               </div>
               {isgst && 
               <>
               <div className="col-md-4">
                 <div className="form-group md-4">
                     <label for=""><b>GST Number</b></label>
                     <p>6543376543</p>
                 </div>
             </div>
             <div className="col-md-4">
               <div className="form-group me-md-4">
                   <label for=""><b>GST Tax rate</b></label>
                   <p>18%</p>
               </div>
           </div>
           </>
                       }
             </div>
             <div className="row">
               <div className="col-md-4 mt-2">
                 <div className="form-group md-4">
                   <label for=""><b>Primary Email</b></label>
                   <p>{mdata.eMail}</p>
                 </div>
               </div> 
             </div> 
             </div>  
           </div>  
                  
           <hr/>
           <div className="row mt-1">
             <div className="col-md-4">
               <div className="form-group me-md-4">
                 <label for=""><b>Bank Name</b></label>            
                 <p>{bankdata.bankName}</p>
               </div>
             </div>
             <div className="col-md-4">
               <div className="form-group md-4">
                 <label for=""><b>Branch Name / IFSC Code</b></label>
                 <p>{bankdata.bankBranchName}</p>
               </div>
             </div> 
             <div className="col-md-4">
               <div className="form-group md-4">
                 <label for=""><b>Account Number</b></label>
                 <p>{bankdata.accountNumber}</p>
               </div>
             </div>              
             <div className="col-md-4">
               <div className="form-group me-md-4">
                 <label for=""><b>Account Holder Name</b></label>            
                 <p>{bankdata.accountHolderName}</p>
               </div>
             </div>  
             <div className="col-md-4">
               <div className="form-group md-4">
                 <label for=""><b>Paytm No</b></label>
                 <p>{bankdata.paytmNumber}</p>
               </div>
             </div>
             <div className="col-md-4">
               <div className="form-group md-4">
                 <label for=""><b>Google Pay No</b></label>            
                 <p>{bankdata.googlePayNumber}</p>
               </div>
             </div>
             <div className="col-md-4">
               <div className="form-group md-4">
                 <label for=""><b>Phone Pay No</b></label>            
                 <p>{bankdata.phonePayNumber}</p>
               </div>
             </div>
             <div className="col-md-4">
               <div className="form-group md-4">
                 <label for=""><b>UPI Id's</b></label>            
                 <p>{bankdata.otherUPI}</p>
               </div>
             </div>
           </div>
           <hr/>
           <div className="row">
             <div className="col-md-12">
               <div className="table-head ">
                 <div>
                   <h4>Segments </h4>
                 </div>
                 <div>
                   <button type="button" className="btn-primery _btn-outline" data-bs-toggle="modal" data-bs-target="#Segment" data-bs-dismiss="modal" onClick={handleFreshSegment}>Add</button>
                 </div>
               </div>
               <div className="de-table table-responsive">
                 <table className="table table-striped merchantbl">
                   <thead>
                     <tr>
                       <th></th>
                       <th className="px-2" scope="col"> ID</th>
                       <th className="px-2" scope="col">Segment Name</th>
                       <th className="px-2" scope="col">Isle 	</th>
                       <th className="px-2" scope="col">Rows</th>
                       <th>Status</th>
                       <th></th>
                     </tr>
                   </thead>
                   <tbody>
                    {segmentlist.length>0 && 
                    segmentlist.map((sitem,i)=>
                    <tr>
                    <td><img src={sitem.segment_pic} width="40"/></td>
                      <td className="">{sitem.dbid}</td>
                      <td>{sitem.segment}</td>  
                      <td>{sitem.row}</td>
                      <td>{sitem.aisle}</td>
                      <td>
                        <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={sitem.status === 0} onChange={() => handleCheckboxChange(sitem.dbid)}/>
                        <label className="form-check-label" for="flexSwitchCheckDefault"></label>
                      </div>
                      </td>
                      <td>
                        <button type="button" className="btn-primery _btn-outline" data-bs-toggle="modal" data-bs-target="#Segment" onClick={()=>handleEditSegment(i)}>Edit</button>
                      </td>
                    </tr>
                    )
                    }

                     </tbody>
                 </table>
               </div>
             </div>
             <div className="col-md-12">
               <div className="table-head ">
                 <div>
                   <h4>Sub Segments </h4>
                 </div>
                 <div>
                   <button type="button" className="btn-primery _btn-outline" data-bs-toggle="modal" data-bs-target="#sub-segment" data-bs-dismiss="modal" onClick={handleFreshSubSegment}>Add</button>
                 </div>
               </div>
               <div className="de-table table-responsive">
                 <table className="table table-striped merchantbl">
                   <thead>
                     <tr>
                       <th className="px-2" scope="col"> ID</th>
                       <th className="px-2" scope="col">Segment Name</th>
                       <th className="px-2" scope="col">Sub Segment Name</th>
                       <th className="px-2" scope="col">Isle	</th>
                       <th className="px-2" scope="col">Rows</th>
                       <th>Status</th>
                       <th></th>
                     </tr>
                   </thead>
                   <tbody>
                    {subsegmentlist.map((ssitem,i)=>
                     <tr>
                     <td className="">{ssitem.dbid}</td>
                     <td>{ssitem.segment}</td>
                     <td>{ssitem.sub_segment_name}</td>
                     <td>{ssitem.aisle}</td>
                     <td>{ssitem.row}</td>
                     <td>
                       <div className="form-check form-switch">
                       <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={ssitem.status === 0} onChange={() => handleCheckboxChange_SS(ssitem.dbid)}/>
                       <label className="form-check-label" for="flexSwitchCheckDefault"></label>
                     </div>
                     </td>
                     <td>
                       <button type="button" className="btn-primery _btn-outline" data-bs-toggle="modal" data-bs-target="#sub-segment" onClick={()=>handleEditSubSegment(i)}>Edit</button>
                     </td>
                   </tr>
                    )}

                     </tbody>
                 </table>
               </div>
             </div>
           </div>
          
           <div className="table-head ">
             <div>
               <h4>Location Details</h4>
             </div>
             <div>
               <button type="button" className="btn-primery _btn-outline" data-bs-toggle="modal" data-bs-target="#add-merchant-details" onClick={handleFreshLocation}>Add Location</button>
           
               </div>
           </div>
              <div className="de-table table-responsive">
             <table className="table table-striped merchantlist merchantbl">
               <thead>
                 <tr>
                   <th>Location Name</th>
                   <th>Location Address</th>
                   <th>Contact</th>
                   <th>Phone</th>
                   <th>Opening Time</th>
                   <th>Closing Time</th>
                   <th>Opening Time 2</th>
                   <th>Closing Time 2</th>
                   <th>Status</th>
                   <th></th>
                 </tr>
               </thead>
               <tbody>
                {locationslist && locationslist.map((litem,i)=>
                <tr>
                <td>{litem.location_name}</td>
                <td>{litem.address}</td>
                <td>{litem.manager_name}</td>
                <td>{litem.manager_number}</td>
                <td>{litem.openeing_time}	</td>
                <td>{litem.closing_time}</td>
                <td>{litem.opening_time2}</td>
                <td>{litem.closing_time2}</td>
                <td>
                  <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={litem.status === 0} onChange={() => handleCheckboxChange_LI(litem.dbid)}/>
                  <label className="form-check-label" for="flexSwitchCheckDefault"></label>
                </div>
              </td>
                <td>
                  <button type="button" className="btn-primery _btn-outline" data-bs-toggle="modal" data-bs-target="#add-merchant-details" onClick={()=>handleEditLocation(i)}>Edit</button>
                </td>
              </tr>
                )}
                
               </tbody>
             </table>
           </div>    
         </div>
       </div>
     </div>
     
     

   </div>
   <div className="modal fade" id="vendor-details" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-scrollable w-500">
      <div className="modal-content">
        <div className="modal-header">           
          <h5 className="modal-title fw-bold">Vendor Details</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>           
        </div>
        <div className="modal-body">
          <div className="row">
              <div className="me-md-3">
                <div className="col-md-12 mb-3">  
                  <label>Vendor Name</label>
                  <input type="text" className="form-control rounded" placeholder="Vendor Name"/>
                </div>
                <div className="col-md-12 mb-3">
                  <label>Vendor Username</label>
                  <input type="text" className="form-control rounded" placeholder="Vendor Username"/>
                </div>
                <div className="col-md-12 mb-3">
                  <label>Password</label>
                  <input type="password" className="form-control rounded" placeholder=""/>
                </div>
                <div className="col-md-12 mb-3">
                  <label>Connection String</label>
                  <input type="text" className="form-control rounded" placeholder=""/>
                </div>
                <div className="col-md-12 mb-3">
                  <label>API url</label>
                  <input type="text" className="form-control rounded" placeholder=""/>
                </div>
              </div>
          </div>
          
          <div className="col-md-11 mb-3">     
            <div id="editor">       
            </div> 
          </div>
        </div>
        <div className="modal-footer">
       
          <button type="button" className="btn-primery _btn-outline">Submit</button>
        </div>
      </div>
    </div>
    </div>
    <div className="modal fade" id="Segment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-scrollable w-500">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Segment Details</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>      
      </div>
      <div className="modal-body">
        <div className="row">
          <div className="col-md-12 mb-2">              
          
            <div className="box-2 img-result w-25">
              <img className="cropped p-img" src={segment.segment_pic} alt=""/>
            </div>
            <div className="box">
              <input type="file" id="file-input"/>
            </div>

      </div>   
          <div className="col-md-12">
            <div className="form-group me-md-4 mb-2">
              <label for="">Segment Name</label>
              <input type="text" className="form-control form-control-sm" placeholder="" name="segment" value={segment.segment} onChange={handlesegmentdata}/>
            </div>
            <div className="col-md-12 mt-3">
              <div className="form-group me-md-4">
                <label for="">Row</label>
                <input type="text" className="form-control form-control-sm" placeholder="" name="row" value={segment.row} onChange={handlesegmentdata}/>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <div className="form-group me-md-4">
                <label for="">Aisle</label>
                <input type="text" className="form-control form-control-sm" placeholder="" name="aisle" value={segment.aisle} onChange={handlesegmentdata}/>
              </div>
            </div>
          </div>      
        </div>  
      </div>
      <div className="modal-footer">
        <button type="button" className="btn-primery _btn-outline" data-bs-dismiss="modal" onClick={CreateSegment}>Submit</button>
      </div>
    </div>
  </div>
</div>
<div className="modal fade" id="sub-segment" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-scrollable w-500">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Sub Segment Details</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>      
      </div>
      <div className="modal-body">
        <div className="row">
          
          <div className="col-md-12">
            <div className="form-group">
              <label for="">Segment Name</label>
              <select className="form-select" aria-label="Default select example" name="segment" value={subsegment.segment} onChange={handleSubsegmentdata}>
                <option value=""></option>
                {segmentlist.map((item,i)=>
                  <option vallue={item.segment}>{item.segment}</option>
                )}
                
              </select>
            </div>
          </div> 
          <div className="col-md-12 mt-3">
            <div className="form-group">
              <label for="">Sub Segment Name</label>
              <input type="text" className="form-control form-control-sm" placeholder="" name="sub_segment_name" value={subsegment.sub_segment_name} onChange={handleSubsegmentdata}/>
            </div>
          </div>  
          <div className="col-md-12 mt-3">
            <div className="form-group me-md-4">
              <label for="">Rows</label>
              <input type="text" className="form-control form-control-sm" placeholder="" name="row" value={subsegment.row} onChange={handleSubsegmentdata}/>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="form-group me-md-4">
              <label for="">Aisle</label>
              <input type="text" className="form-control form-control-sm" placeholder="" name="aisle" value={subsegment.aisle} onChange={handleSubsegmentdata}/>
            </div>
          </div>      
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn-primery _btn-outline" data-bs-dismiss="modal" onClick={CreateSubSegment}>Submit</button>
      </div>
    </div>
  </div>
</div>
<div className="modal fade" id="edit-modal" tabindex="-1" aria-labelledby="exampleModalLabel" f="display: none;" aria-hidden="true">
  <div className="modal-dialog modal-dialog-scrollable w-1150 modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Edit Profile</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>      
      </div>
      <div className="modal-body right-content-select">
        <div className="row">
          <div className="col-md-4">
            <div className="col-md-12 text-center">
              <div className="box-2 img-result text-center">
                <img className="cropped p-img" id="blah" src="/assets/img/Group 59.svg" alt="your image"/>
              </div>
              <div className="box">
                <label className="upload__btn btn-primery _btn-outline m-0 label-m-upload">
                  <p className="mb-0 text-white">Upload Image</p>
                  <input type="file" className="show-for-sr upload__inputfile" onchange="readURL(this);" id="file-input"/>
                </label>
                
              </div>
             
              <div className="box">
                <div className="options hide">
                  <input type="number" className="img-w hide" value="300" min="100" max="1200"/>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 mt-3">
            <div className="row me-2">
              <div className="col-md-12 mt-2">
                <div className="form-group md-4">
                  <label for="">{lables.Owner_name}</label>
                  <input type="text" className="form-control form-control-sm" placeholder="" name="businessName" value={mdata.businessName} onChange={handleProfileChange}/>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group ">
                  <label for="">Category</label>            
                  <select className="form-select" aria-label="Default select example" name="serviceType" value={mdata.serviceType} onChange={handleProfileChange} disabled={true}>
                    <option value="Groceries">Groceries</option>
                    <option value="1">Merchant</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label for="">City</label>
                  <select className="form-select" aria-label="Default select example" name="city" value={mdata.city} onChange={handleProfileChange}>
                    
                    <option value="1">One</option>
                    <option value="Visakhapatnam">Visakhapatnam</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="form-group ">
                  <label for="">Primary Email</label>
                  <input type="text" className="form-control form-control-sm" placeholder="NA" name="eMail" value={mdata.eMail} onChange={handleProfileChange}/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label for="">Phone Number</label>
                  <input type="text" className="form-control form-control-sm" placeholder="phone number" name="phoneNumber" value={mdata.phoneNumber} onChange={handleProfileChange}/>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-4">
               
                  <p className="mb-0">Having a GST Number ?</p>
                  <div className="form-check form-switch ">
                    <input className="form-check-input" type="checkbox" id="toggle" onChange={togglegst}/>
                    <label className="form-check-label switch" for="toggle"></label>
                  </div>
              </div>
            </div>
            {isgst && 
            <div className="row mt-3" id="contentgst">
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="form-group ">
                    <label for="">GST Number</label>
                    <input type="text" className="form-control form-control-sm" placeholder="GST Number" name="gstnumber" value={gstdata.gstnumber} onChange={handlegstdata}/>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <label for="">GST Tax Rate</label>
                    <input type="text" className="form-control form-control-sm" placeholder="GST Tax Rate" name="gst_percentage" value={gstdata.gst_percentage} onChange={handlegstdata}/>
                  </div>
                </div>
              </div>
            </div>}
          </div>
        </div>          
        <div className="row">
        <hr/>
        <div className="row mt-2">
          <div className="col-md-4">
            <div className="form-group ">
              <label for="">Bank Name</label>            
              <input type="text" className="form-control form-control-sm" placeholder="Bank Name" name="bankName" value={bankdata.bankName} onChange={handleBankdata}/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label for="">Branch Name</label>
              <input type="text" className="form-control form-control-sm" placeholder="Branch Name" name="bankBranchName" value={bankdata.bankBranchName} onChange={handleBankdata}/>
            </div>
          </div>       
          <div className="col-md-4">
            <div className="form-group">
              <label for="">IFSC Code</label>            
              <input type="text" className="form-control form-control-sm" placeholder="IFSC Code" name="ifscCode" value={bankdata.ifscCode} onChange={handleBankdata}/>
            </div>
          </div>  
        </div>
        <div className="row mt-2">            
          <div className="col-md-4">
            <div className="form-group">
              <label for="">Account Number</label>
              <input type="text" className="form-control form-control-sm" placeholder="Account Number" name="accountNumber" value={bankdata.accountNumber} onChange={handleBankdata}/>
            </div>
          </div>         
          <div className="col-md-4">
            <div className="form-group">
              <label for="">Account Holder Name</label>            
              <input type="text" className="form-control form-control-sm" placeholder="Account Holder Name" name="accountHolderName" value={bankdata.accountHolderName} onChange={handleBankdata}/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group ">
              <label for="">Other UPI ID's (Enter UPI Id's with comma separated values)</label>
              <input type="text" className="form-control form-control-sm" placeholder="UPI ID" name="otherUPI" value={bankdata.otherUPI} onChange={handleBankdata}/>
            </div>
          </div> 
       
        </div>
        <div className="row mt-2">
          
        </div>
        <div className="row mt-2">            
          <div className="col-md-4">
            <div className="form-group ">
              <label for="">Paytm No</label>
              <input type="text" className="form-control form-control-sm" placeholder="Paytm No" name="paytmNumber" value={bankdata.paytmNumber} onChange={handleBankdata}/>
            </div>
          </div>       
          <div className="col-md-4">
            <div className="form-group ">
              <label for="">Google Pay No</label>            
              <input type="text" className="form-control form-control-sm" placeholder="Google Pay No" name="googlePayNumber" value={bankdata.googlePayNumber} onChange={handleBankdata}/>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group ">
              <label for="">Phonepe No</label>
              <input type="text" className="form-control form-control-sm" placeholder="Phonepe No" name="phonePayNumber" value={bankdata.phonePayNumber} onChange={handleBankdata}/>
            </div>
          </div>    
        </div>
      </div>        
    </div>
    <div className="modal-footer">
      <button type="button" className="btn-primery _btn-outline m-0" onClick={UpdateProfile}>Submit</button>
    </div>
  </div>
</div>
</div>
<div className="modal fade" id="add-merchant-details" tabindex="-1">
    <div className="modal-dialog modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{lables.Owner_loc_heading}</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>      
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Manager Name</label>
                <input type="text" className="form-control " placeholder="" name='manager_name' value={locationdet.manager_name} onChange={handleLocationDet}/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Manager Number</label>
                <input type="text" className="form-control " placeholder="" name='manager_number' value={locationdet.manager_number} onChange={handleLocationDet}/>
              </div>
            </div>  
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Address</label>
                <input type="text" className="form-control" ref={inputRef} name="address" value={locationdet.address} onChange={handleLocationDet} />
              </div>
            </div>      
          </div>
          <hr/>
          <div className="row mt-3">
            <h6>{lables.Owner_details}</h6>
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Location Name</label>
                <input type="text" className="form-control " placeholder="" name='location_name' value={locationdet.location_name} onChange={handleLocationDet}/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Packing Charges</label>
                <input type="number" className="form-control" placeholder="" name='packing_charges' value={locationdet.packing_charges} onChange={handleLocationDet}/>
              </div>
            </div>  
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">City</label>
                <select className="form-select" aria-label="Default select example" ref={cityRef} name="city" value={mdata.city} disabled={true}>
                  <option>Please Select</option>
                  <option value="Visakhapatnam">Visakhapatnam</option>
                  <option value="Vijayawada">Vijayawada</option>
                  <option value="Kurnool">Kurnool</option>
                </select>
               
              </div>
            </div>      
            <div className="col-md-4 mt-2">
              <div className="form-group me-md-4">
                <label for="">Type</label>
                <select className="form-select" aria-label="Default select example" value={mdata.serviceType} disabled={true}>
                  <option value="">Please Select</option>
                  <option value="Groceries">Groceries</option>
                  <option value="2">Pan Shop</option>
                  <option value="3">Restaurant</option>
                </select>
              </div>
            </div>
          </div>    
          <hr/>
          <div className="row mt-3">
            <h6>{lables.Owner_details}</h6>
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Location Latitude</label>
                <input type="text" className="form-control " placeholder="" name='location_latitude' value={locationdet.location_latitude} onChange={handleLocationDet}
/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Location Longitude</label>
                <input type="text" className="form-control " placeholder=""  name='location_longitude' value={locationdet.location_longitude} onChange={handleLocationDet}/>
              </div>
            </div> 
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Locality</label>
                <input type="text" className="form-control " placeholder="" name="locality" value={locationdet.locality} onChange={handleLocationDet} />
              </div>
            </div>       
          </div>  
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Sub locality</label>
                <input type="text" className="form-control " placeholder="" name='sub_locality' value={locationdet.sub_locality} onChange={handleLocationDet}/>
              </div>
            </div> 
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Route</label>
                <input type="text" className="form-control " placeholder="" name='route' value={locationdet.route} onChange={handleLocationDet}/>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Pin Code</label>
                <input type="text" className="form-control " placeholder="" name='pincode' value={locationdet.pincode} onChange={handleLocationDet}/>
              </div>
            </div>        
          </div>   
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Opening Time</label>
                <select className="form-select" name='opening_time' value={locationdet.opening_time} onChange={handleLocationDet}>                
<option value=''></option>
<option value='12:00 AM'>12:00 AM</option>
<option value='12:30 AM'>12:30 AM</option>
<option value='01:00 AM'>01:00 AM</option>
<option value='01:30 AM'>01:30 AM</option>
<option value='02:00 AM'>02:00 AM</option>
<option value='02:30 AM'>02:30 AM</option>
<option value='03:00 AM'>03:00 AM</option>
<option value='03:30 AM'>03:30 AM</option>
<option value='04:00 AM'>04:00 AM</option>
<option value='04:30 AM'>04:30 AM</option>
<option value='05:00 AM'>05:00 AM</option>
<option value='05:30 AM'>05:30 AM</option>
<option value='06:00 AM'>06:00 AM</option>
<option value='06:30 AM'>06:30 AM</option>
<option value='07:00 AM'>07:00 AM</option>
<option value='07:30 AM'>07:30 AM</option>
<option value='08:00 AM'>08:00 AM</option>
<option value='08:30 AM'>08:30 AM</option>
<option value='09:00 AM'>09:00 AM</option>
<option value='09:30 AM'>09:30 AM</option>
<option value='10:00 AM'>10:00 AM</option>
<option value='10:30 AM'>10:30 AM</option>
<option value='11:00 AM'>11:00 AM</option>
<option value='11:30 AM'>11:30 AM</option>
<option value='12:00 PM'>12:00 PM</option>
<option value='12:30 PM'>12:30 PM</option>
<option value='01:00 PM'>01:00 PM</option>
<option value='01:30 PM'>01:30 PM</option>
<option value='02:00 PM'>02:00 PM</option>
<option value='02:30 PM'>02:30 PM</option>
<option value='03:00 PM'>03:00 PM</option>
<option value='03:30 PM'>03:30 PM</option>
<option value='04:00 PM'>04:00 PM</option>
<option value='04:30 PM'>04:30 PM</option>
<option value='05:00 PM'>05:00 PM</option>
<option value='05:30 PM'>05:30 PM</option>
<option value='06:00 PM'>06:00 PM</option>
<option value='06:30 PM'>06:30 PM</option>
<option value='07:00 PM'>07:00 PM</option>
<option value='07:30 PM'>07:30 PM</option>
<option value='08:00 PM'>08:00 PM</option>
<option value='08:30 PM'>08:30 PM</option>
<option value='09:00 PM'>09:00 PM</option>
<option value='09:30 PM'>09:30 PM</option>
<option value='10:00 PM'>10:00 PM</option>
<option value='10:30 PM'>10:30 PM</option>
<option value='11:00 PM'>11:00 PM</option>
<option value='11:30 PM'>11:30 PM</option>

                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Closing Time</label>
                <select className="form-select" name='closing_time' value={locationdet.closing_time} onChange={handleLocationDet}>                
                <option value=''></option>
<option value='12:00 AM'>12:00 AM</option>
<option value='12:30 AM'>12:30 AM</option>
<option value='01:00 AM'>01:00 AM</option>
<option value='01:30 AM'>01:30 AM</option>
<option value='02:00 AM'>02:00 AM</option>
<option value='02:30 AM'>02:30 AM</option>
<option value='03:00 AM'>03:00 AM</option>
<option value='03:30 AM'>03:30 AM</option>
<option value='04:00 AM'>04:00 AM</option>
<option value='04:30 AM'>04:30 AM</option>
<option value='05:00 AM'>05:00 AM</option>
<option value='05:30 AM'>05:30 AM</option>
<option value='06:00 AM'>06:00 AM</option>
<option value='06:30 AM'>06:30 AM</option>
<option value='07:00 AM'>07:00 AM</option>
<option value='07:30 AM'>07:30 AM</option>
<option value='08:00 AM'>08:00 AM</option>
<option value='08:30 AM'>08:30 AM</option>
<option value='09:00 AM'>09:00 AM</option>
<option value='09:30 AM'>09:30 AM</option>
<option value='10:00 AM'>10:00 AM</option>
<option value='10:30 AM'>10:30 AM</option>
<option value='11:00 AM'>11:00 AM</option>
<option value='11:30 AM'>11:30 AM</option>
<option value='12:00 PM'>12:00 PM</option>
<option value='12:30 PM'>12:30 PM</option>
<option value='01:00 PM'>01:00 PM</option>
<option value='01:30 PM'>01:30 PM</option>
<option value='02:00 PM'>02:00 PM</option>
<option value='02:30 PM'>02:30 PM</option>
<option value='03:00 PM'>03:00 PM</option>
<option value='03:30 PM'>03:30 PM</option>
<option value='04:00 PM'>04:00 PM</option>
<option value='04:30 PM'>04:30 PM</option>
<option value='05:00 PM'>05:00 PM</option>
<option value='05:30 PM'>05:30 PM</option>
<option value='06:00 PM'>06:00 PM</option>
<option value='06:30 PM'>06:30 PM</option>
<option value='07:00 PM'>07:00 PM</option>
<option value='07:30 PM'>07:30 PM</option>
<option value='08:00 PM'>08:00 PM</option>
<option value='08:30 PM'>08:30 PM</option>
<option value='09:00 PM'>09:00 PM</option>
<option value='09:30 PM'>09:30 PM</option>
<option value='10:00 PM'>10:00 PM</option>
<option value='10:30 PM'>10:30 PM</option>
<option value='11:00 PM'>11:00 PM</option>
<option value='11:30 PM'>11:30 PM</option>

                </select>
              </div>
            </div>   
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Opening Time 2</label>
                <select className="form-select" name='opening_time2' value={locationdet.opening_time2} onChange={handleLocationDet}>                
<option value=''></option>
<option value='12:00 AM'>12:00 AM</option>
<option value='12:30 AM'>12:30 AM</option>
<option value='01:00 AM'>01:00 AM</option>
<option value='01:30 AM'>01:30 AM</option>
<option value='02:00 AM'>02:00 AM</option>
<option value='02:30 AM'>02:30 AM</option>
<option value='03:00 AM'>03:00 AM</option>
<option value='03:30 AM'>03:30 AM</option>
<option value='04:00 AM'>04:00 AM</option>
<option value='04:30 AM'>04:30 AM</option>
<option value='05:00 AM'>05:00 AM</option>
<option value='05:30 AM'>05:30 AM</option>
<option value='06:00 AM'>06:00 AM</option>
<option value='06:30 AM'>06:30 AM</option>
<option value='07:00 AM'>07:00 AM</option>
<option value='07:30 AM'>07:30 AM</option>
<option value='08:00 AM'>08:00 AM</option>
<option value='08:30 AM'>08:30 AM</option>
<option value='09:00 AM'>09:00 AM</option>
<option value='09:30 AM'>09:30 AM</option>
<option value='10:00 AM'>10:00 AM</option>
<option value='10:30 AM'>10:30 AM</option>
<option value='11:00 AM'>11:00 AM</option>
<option value='11:30 AM'>11:30 AM</option>
<option value='12:00 PM'>12:00 PM</option>
<option value='12:30 PM'>12:30 PM</option>
<option value='01:00 PM'>01:00 PM</option>
<option value='01:30 PM'>01:30 PM</option>
<option value='02:00 PM'>02:00 PM</option>
<option value='02:30 PM'>02:30 PM</option>
<option value='03:00 PM'>03:00 PM</option>
<option value='03:30 PM'>03:30 PM</option>
<option value='04:00 PM'>04:00 PM</option>
<option value='04:30 PM'>04:30 PM</option>
<option value='05:00 PM'>05:00 PM</option>
<option value='05:30 PM'>05:30 PM</option>
<option value='06:00 PM'>06:00 PM</option>
<option value='06:30 PM'>06:30 PM</option>
<option value='07:00 PM'>07:00 PM</option>
<option value='07:30 PM'>07:30 PM</option>
<option value='08:00 PM'>08:00 PM</option>
<option value='08:30 PM'>08:30 PM</option>
<option value='09:00 PM'>09:00 PM</option>
<option value='09:30 PM'>09:30 PM</option>
<option value='10:00 PM'>10:00 PM</option>
<option value='10:30 PM'>10:30 PM</option>
<option value='11:00 PM'>11:00 PM</option>
<option value='11:30 PM'>11:30 PM</option>

                </select>
              </div>
            </div>     
          </div>            
          <div className="row mt-3">
            <div className="col-md-4">
              <div className="form-group me-md-4">
                <label for="">Closing Time 2</label>
                <select className="form-select" name='closing_time2' value={locationdet.closing_time2} onChange={handleLocationDet}>                
                <option value=''></option>
                <option value='12:00 AM'>12:00 AM</option>
<option value='12:30 AM'>12:30 AM</option>
<option value='01:00 AM'>01:00 AM</option>
<option value='01:30 AM'>01:30 AM</option>
<option value='02:00 AM'>02:00 AM</option>
<option value='02:30 AM'>02:30 AM</option>
<option value='03:00 AM'>03:00 AM</option>
<option value='03:30 AM'>03:30 AM</option>
<option value='04:00 AM'>04:00 AM</option>
<option value='04:30 AM'>04:30 AM</option>
<option value='05:00 AM'>05:00 AM</option>
<option value='05:30 AM'>05:30 AM</option>
<option value='06:00 AM'>06:00 AM</option>
<option value='06:30 AM'>06:30 AM</option>
<option value='07:00 AM'>07:00 AM</option>
<option value='07:30 AM'>07:30 AM</option>
<option value='08:00 AM'>08:00 AM</option>
<option value='08:30 AM'>08:30 AM</option>
<option value='09:00 AM'>09:00 AM</option>
<option value='09:30 AM'>09:30 AM</option>
<option value='10:00 AM'>10:00 AM</option>
<option value='10:30 AM'>10:30 AM</option>
<option value='11:00 AM'>11:00 AM</option>
<option value='11:30 AM'>11:30 AM</option>
<option value='12:00 PM'>12:00 PM</option>
<option value='12:30 PM'>12:30 PM</option>
<option value='01:00 PM'>01:00 PM</option>
<option value='01:30 PM'>01:30 PM</option>
<option value='02:00 PM'>02:00 PM</option>
<option value='02:30 PM'>02:30 PM</option>
<option value='03:00 PM'>03:00 PM</option>
<option value='03:30 PM'>03:30 PM</option>
<option value='04:00 PM'>04:00 PM</option>
<option value='04:30 PM'>04:30 PM</option>
<option value='05:00 PM'>05:00 PM</option>
<option value='05:30 PM'>05:30 PM</option>
<option value='06:00 PM'>06:00 PM</option>
<option value='06:30 PM'>06:30 PM</option>
<option value='07:00 PM'>07:00 PM</option>
<option value='07:30 PM'>07:30 PM</option>
<option value='08:00 PM'>08:00 PM</option>
<option value='08:30 PM'>08:30 PM</option>
<option value='09:00 PM'>09:00 PM</option>
<option value='09:30 PM'>09:30 PM</option>
<option value='10:00 PM'>10:00 PM</option>
<option value='10:30 PM'>10:30 PM</option>
<option value='11:00 PM'>11:00 PM</option>
<option value='11:30 PM'>11:30 PM</option>

                </select>
              </div>
            </div>  
  
            {/* <div className="col-md-4">              
              <div className="form-group me-md-4">
                <label for="" className="white-space-nowrap">SELECT ORDER DELIVERY DAYS</label>
                <select className="form-select" name='order_delivery_days' value={locationdet.order_delivery_days} onChange={handleLocationDet}>                
                <option value=''></option>
                  <option value="0">Instant Delivery</option>
                  <option value="1">1 Day</option>
                  <option value="2">2 Days</option>
                  <option value="3">3 Days</option>
                  <option value="4">4 Days</option>
                  <option value="5">5 Days</option>
                  <option value="6">6 Days</option>
                  <option value="7">7 Days</option>
                </select>
              </div>
            </div>                   */}
          </div>   
          <div className="row mt-4">
            <div className="col-md-12">
              <h6>Closed Days</h6>
              <div className="mb-3 d-flex">
                <input type="checkbox" className="form-check-input me-3" id="exampleCheck1" value="Monday" onChange={handleHolidaysChange}/>
                <label className="form-check-label" for="exampleCheck1">Monday</label>
                <input type="checkbox" className="form-check-input mx-3" id="exampleCheck1" value="Tuesday" onChange={handleHolidaysChange}/>
                <label className="form-check-label" for="exampleCheck1">Tuesday</label>
                <input type="checkbox" className="form-check-input mx-3" id="exampleCheck1" value="Wednesday" onChange={handleHolidaysChange}/>
                <label className="form-check-label" for="exampleCheck1">Wednesday</label>
                <input type="checkbox" className="form-check-input mx-3" id="exampleCheck1" value="Thursday" onChange={handleHolidaysChange}/>
                <label className="form-check-label" for="exampleCheck1">Thusday</label>
                <input type="checkbox" className="form-check-input mx-3" id="exampleCheck1" value="Friday" onChange={handleHolidaysChange}/>
                <label className="form-check-label" for="exampleCheck1">Friday</label>
                <input type="checkbox" className="form-check-input mx-3" id="exampleCheck1" value="Saturday" onChange={handleHolidaysChange}/>
                <label className="form-check-label" for="exampleCheck1">Saturaday</label>
                <input type="checkbox" className="form-check-input mx-3" id="exampleCheck1" value="Sunday" onChange={handleHolidaysChange}/>
                <label className="form-check-label" for="exampleCheck1">Sunday</label>
              </div>
              <p name="closed_days" value={locationdet.closed_days} onChange={handleLocationDet}>Selected Days: {selectedDays.join(', ')}</p>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn-primery _btn-outline" data-bs-dismiss="modal" onClick={Save_Merchant_Location}>Submit</button>
        </div>
      </div>
    </div>
  </div>
    </>
    );
}

export default ProfileComplete;
