import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import api from "../Api";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { format } from "date-fns";
import MemberTable from "../Customer/MemberTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddPromoModal = forwardRef((props, ref2, ref4) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const response = useSelector((state) => state.user.user);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);
  const [promoCodeCustomers, setPromoCodeCutomers] = React.useState();
  const [filterData, setFilterData] = React.useState([]);
  const filterCustomerData = (rowData) => {
    if (filterData.some((item) => item.customer_id === rowData.customer_id)) {
      setFilterData((prevSelected) =>
        prevSelected.filter((item) => item.customer_id !== rowData.customer_id)
      );
    } else {
      setFilterData((prevSelected) => [...prevSelected, rowData]);
    }
  };
  const handleAllFilterData = (e) => {
    if (e.target.checked === true) {
      setFilterData(promoCodeCustomers);
    } else {
      setFilterData([]);
    }
  };

  const sendFilterDdata = () => {
    props.onSelectedMembersChange(filterData);
    handleClose();
  };
  const selectColumns = [
    {
      name: "name_of_the_customer",
      label: "Name",
      //    label:'',
    },
    { name: "phone_number", label: "Contact No" },
    { name: "location_name", label: "Location" },
    {
      name: "actions",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = promoCodeCustomers[rowIndex];
          return (
            <Checkbox
              onChange={() => {
                filterCustomerData(rowData);
              }}
            />
          );
        },
      },
    },
  ];

  const fetchCities = () => {
    api
      .getData(
        "/api/locations/get_Location_name_pos?merchant_id=" +
          response?.merchant_id +
          "&city_id=" +
          response?.city_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          // setLocation(JSON.parse(response.data[0].LocationDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleFilterDdata = () => {
  //   fetchCities();
  // }
  const handleFilterDdata = (e) => {
    let data = {
      cr_fromdate:
        e.target.name === "cr_fromdate" ? e.target.value : "01/01/2023",
      cr_todate: e.target.name === "cr_todate" ? e.target.value : "01/01/1900",
      la_fromdate:
        e.target.name === "la_fromdate" ? e.target.value : "01/01/1900",
      la_todate: e.target.name === "la_todate" ? e.target.value : "01/01/1900",
      ts_min: e.target.name === "ts_min" ? e.target.value : "0",
      ts_max: e.target.name === "ts_max" ? e.target.value : "9000",
      to_min: e.target.name === "to_min" ? e.target.value : "0",
      to_max: e.target.name === "to_max" ? e.target.value : "0",
    };

    api
      .getData(
        "/api/promotions/get_customers_Promo_Codes?merchant_id=" +
          response?.merchant_id +
          "&cr_fromdate=" +
          data?.cr_fromdate +
          "&cr_todate=" +
          data?.cr_todate +
          "&la_fromdate=" +
          data?.la_fromdate +
          "&la_todate=" +
          data?.la_todate +
          "&ts_min=" +
          data?.ts_min +
          "&ts_max=" +
          data?.ts_max +
          "&to_min=" +
          data?.to_min +
          "&to_max=" +
          data?.to_max
      )
      .then((response) => {
        if (response.data[0]?.Status === 200) {
          setPromoCodeCutomers(JSON.parse(response.data[0].ProductDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (values) => {
    api
      .postData("/promotions/Insert_Update_Promo_Codes", formik.values)
      .then((response) => {});
  };
  const formik = useFormik({
    initialValues: {
      cr_fromdate: "",
      cr_todate: "",
      la_fromdate: "",
      la_todate: "",
      ts_min: "",
      ts_max: "",
      to_min: "",
      to_max: "",
    },
    // validationSchema: addLocation,
    onSubmit: handleSubmit,
    // enableReinitialize: true,
  });
  const selectData = [
    ["patnaik", "9513574862", "Kakinada"],
    ["Neeha", "7084327025", "Vizianagram"],
    ["naveen", "8624791305", "Visakhapatnam"],
  ];

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];
  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {},
  };

  useEffect(() => {
    setPromoCodeCutomers(props.promoCodeCustomers);
  }, [props.promoCodeCustomers]);
  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {},
  };

  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;

  useImperativeHandle(ref2, () => ({
    log() {
      handleOpen();
    },
  }));

  useImperativeHandle(ref4, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product ">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              ADD NEW CUSTOMER
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>

          <form>
            <Grid container mt={4} className="pickers" spacing={2}>
              <Grid item xs="12" md={12} className="pl-0">
                <Box className="member-datepickers custom-date-pickers w-100">
                  <Box className="border-right">
                    <Typography>JOIN DATE</Typography>
                    <Box className="sub_date">
                      <FormControl>
                        <Typography component="label">From Date</Typography>
                        <OutlinedInput
                          type="date"
                          name="cr_fromdate"
                          format="dd/MM/yyyy"
                          onChange={(e) => handleFilterDdata(e)}
                        />
                        {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                      </FormControl>
                      <FormControl>
                        <Typography component="label">To Date</Typography>
                        <OutlinedInput
                          type="date"
                          name="cr_todate"
                          onChange={(e) => {
                            handleFilterDdata(e);
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Box className="border-right" mt={2}>
                    <Typography>TOTAL SPEND</Typography>
                    <Box className="sub_date">
                      <FormControl>
                        <Typography component="label">Min</Typography>
                        <OutlinedInput
                          placeholder="$"
                          name="ts_min"
                          onBlur={(e) => {
                            handleFilterDdata(e);
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <Typography component="label">Max</Typography>
                        <OutlinedInput
                          placeholder="$"
                          name="ts_max"
                          onBlur={(e) => {
                            handleFilterDdata(e);
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                  <Box className="border-right" mt={2}>
                    <Typography>LAST ACTIVE</Typography>
                    <Box className="sub_date">
                      <FormControl>
                        <Typography component="label">From Date</Typography>
                        <OutlinedInput
                          type="date"
                          name="la_fromdate"
                          onChange={(e) => {
                            handleFilterDdata(e);
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <Typography component="label">To Date</Typography>
                        <OutlinedInput
                          type="date"
                          name="la_todate"
                          onChange={(e) => {
                            handleFilterDdata(e);
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <Box className="border-right" mt={2}>
                    <Typography>TOTAL ORDERS</Typography>
                    <Box className="sub_date">
                      <FormControl>
                        <Typography component="label">Min</Typography>
                        <OutlinedInput
                          placeholder="$"
                          name="to_min"
                          onBlur={(e) => {
                            handleFilterDdata(e);
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <Typography component="label">Max</Typography>
                        <OutlinedInput
                          placeholder="$"
                          name="to_max"
                          onBlur={(e) => {
                            handleFilterDdata(e);
                          }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid container className="p-15" mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="select all"
                    onChange={(e) => {
                      handleAllFilterData(e);
                    }}
                  />
                }
                label="Select All"
              />
              <Grid item xs={12} md={12}>
                <Box className="w-100 member-table">
                  <MemberTable
                    columns={selectColumns}
                    options={pcOptions}
                    data={promoCodeCustomers ? promoCodeCustomers : []}
                  />
                </Box>
              </Grid>
            </Grid>

            <Box className="p-15" mb={2}>
              <Button
                variant="outlined"
                color="error"
                className="retail-btn float-right"
                onClick={() => {
                  sendFilterDdata();
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
});

export default AddPromoModal;
