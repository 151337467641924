import React, { useImperativeHandle, forwardRef, useEffect } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Calender from "../Calender/Calender";
import { Image, Label, Phone } from "@mui/icons-material";
import AddCustomerModal from "../Customer/AddCustomerModal";
import id from "../images/ID.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import MemberTable from "../Customer/MemberTable";
import EditModal from "../Customer/EditModal";
import ViewMoreModal from "../Customer/ViewMoreModal";
import home from "../images/home.svg";
import Bredcrum from "../Bredcrum";
import api from "../Api";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const year = ["2023", "2022", "2021", "2020"];

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function JobApplicants() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = React.useState(true);
  const [jobApplicantDetails, setJobApplicantDetails] = React.useState();
  const [applicantDetails, setApplicantDetails] = React.useState();
  const response = useSelector(state => state.user.user);

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();

  const jobColumns = [
    { name: 'application_id',label: 'Application Id' },
    { name: 'name', label: 'Applicant Name' },
    { name: 'phone_number', label: 'Phone' },
    { name: 'email', label: 'Email' },
  ];

  const getJobApplicantDetails = () => {
    api.getData('/api/employee_management/Get_applicant_details_for_Merchant?merchant_id='+ response?.merchant_id)
      .then((response) => {
        if (response.data.merchant[0].Status === "200") {
          setJobApplicantDetails(JSON.parse(response.data.merchant[0]?.MerchantApplicantDetails));
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }
  
  const getApplicantDetails = (applicationId) => {
    api.getData(`/api/employee_management/Get_applicant_details?merchant_id=${response?.merchant_id}&application_id=${applicationId}`)
      .then((response) => {
        if (response.data.applicant[0].Status === "200") {
          setApplicantDetails(JSON.parse(response.data.applicant[0]?.ApplicantDetails)[0]);
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if(jobApplicantDetails){
      getApplicantDetails(jobApplicantDetails[0]?.application_id);
    }
  }, [jobApplicantDetails]);

  const jobOptions = {
    search: searchBtn,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: "dropdown",
    selectableRows: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.rowIndex;
      const clickedRowData = jobApplicantDetails[clickedRowIndex];
      getApplicantDetails(clickedRowData.application_id);
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    getJobApplicantDetails();
  }, []);
  
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={3}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Box className="select_div range-picker">
                        <Calender />

                        <Button variant="outlined" className="today">
                          Today
                        </Button>
                        <Button variant="outlined" className="today">
                          Yesterday
                        </Button>
                      </Box>
                      <Box style={{ display: "flex" }}>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Locations</MenuItem>
                          <MenuItem>Debray</MenuItem>
                        </Select>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography component="h6">JOB APPLICANTS</Typography>
                        </Box>
                        <Box mt={2}>
                          <MemberTable
                            columns={jobColumns}
                            options={jobOptions}
                            data={jobApplicantDetails ? jobApplicantDetails : []}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information applicant_information">
                        <Box>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            mb={2}
                          >
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={id} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.name:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Phone No.
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.phone_number:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography>
                                  {applicantDetails?applicantDetails?.email:""}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Position Applied
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.position_applied:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Location
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.location_name:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Availability
                                  </Typography>
                                  <Typography>FullTime</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    City
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.city_name:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    State
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.state:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={id} />
                                <Box>
                                  <Typography component="label">Zip</Typography>
                                  <Typography>{applicantDetails?applicantDetails?.zip_code:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Commercial passenger transport experience
                                  </Typography>
                                  <Typography>YES</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={home} />
                                <Box>
                                  <Typography component="label">
                                    Address 1
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.address_1:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={home} />
                                <Box>
                                  <Typography component="label">
                                    Address 2
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.address_2:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>

                          <Divider></Divider>
                          <Typography component="h2" ml={2} mt={2}>
                            WORK HISTORY
                          </Typography>
                          <Grid container spacing={2} mb={2}>
                            <Grid item xs={12} md={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Previous Job Title
                                  </Typography>
                                  <Typography component="p">
                                  {applicantDetails?applicantDetails?.previous_job_title:""}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Previous Job Description
                                  </Typography>
                                  <Typography>
                                  {applicantDetails?applicantDetails?.previous_job_description:""}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Previous Job Started Date
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.start_date:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} md={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Previous Job Ended Date
                                  </Typography>
                                  <Typography>{applicantDetails?applicantDetails?.end_date:""}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider></Divider>
                        <Typography component="h3">REFERENCES</Typography>
                        <Typography component="h4">REFERENCES - 1</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="label">Name</Typography>
                                <Typography component="p">Brad Pitt</Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={mobile} />
                              <Box>
                                <Typography component="label">
                                  Phone no.
                                </Typography>
                                <Typography>1112223333</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Typography component="h4">REFERENCES - 2</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="label">Name</Typography>
                                <Typography component="p">
                                  George Clooney
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={mobile} />
                              <Box>
                                <Typography component="label">
                                  Phone no.
                                </Typography>
                                <Typography>2223334444</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Typography component="h4">REFERENCES - 3</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="label">Name</Typography>
                                <Typography component="p">
                                  Barack Obama
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={mobile} />
                              <Box>
                                <Typography component="label">
                                  Phone no.
                                </Typography>
                                <Typography>0002229999</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                        <Typography component="h4">REFERENCES - 4</Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={customers} />
                              <Box>
                                <Typography component="label">Name</Typography>
                                <Typography component="p">Marlene</Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} mt={2}>
                            <Box className="information-div">
                              <Box component="img" src={mobile} />
                              <Box>
                                <Typography component="label">
                                  Phone no.
                                </Typography>
                                <Typography>305-812-4180</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <AddCustomerModal ref={ref} />
        <EditModal ref={ref1} />
        <ViewMoreModal ref={ref3} />
      </Box>
    </>
  );
}
