import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch
} from "@mui/material";
import { FormControlLabel } from "@mui/material";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import MemberTable from "../Customer/MemberTable";
import api from "../Api";
import { useSelector } from "react-redux";
// import LocationModal from "./LocationModal";
import Bredcrum from "../Bredcrum";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

export default function Publishing() {
  

  const [showIndex,setShowIndex] = React.useState();
  const response = useSelector((state) => state.user.user);
  const [showState, setShowState] = React.useState(false);
  const [showSegmentState, setSegmentShowState] = React.useState(true);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");
  const handleClick = (index) => {
    setShowIndex(index);
    
    // setShowState(!showState);
    if(showIndex === index && showSegmentState){
      setShowState(false);
      setSegmentShowState(false);
    }else if(showIndex !== index && showSegmentState === false){
      setShowState(false);
    }
    else{
      setShowState(false);
      setShowState(true);
      setSegmentShowState(true);
    }
  };
  const ref = React.useRef();
  const ref4 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    })
  };
  const showSwalWithLinkError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    })
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 15,
      height: 13,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const eiColumns = ["ID", "Location", "Name"];
  const eiData = [
    ["#963258", "Srikakulam", "Ramya"],
    ["#963258", "Srikakulam", "Ramya"],
    ["#856214", "Vizianagaram", "Srilekha"],
  ];
  const mlColumns = [
    "DATE",
    "TYPE",
    "TASK",
    "LIFE OF REPAIR",
    "COST",
    "COMMENTS",
  ];
  const [merchantSegmentsPublishing, setMerchantSegmentsPublishing] = React.useState();
  const getMerchantSegmentsPublishing = () => {
    api
      .getData("/api/publishing/Get_Merchant_Segments_Publishing?merchant_id="+response?.merchant_id)
      .then((response) => {
        console.log('response',response);
        if (response.data[0].Status === "200") {
          setMerchantSegmentsPublishing(JSON.parse(response.data[0].SegmentDetails));
          // setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [merchantSubSegmentsPublishing, setMerchantSubSegmentsPublishing] = React.useState();
  const getMerchantSubSegmentsPublishing = (segmentId) => {
    api
      .getData("/api/publishing/Get_Merchant_Sub_segments_Publishing?merchant_id="+response?.merchant_id+'&segment_id='+segmentId)
      .then((response) => {
        if (response.data[0].Status === "200") {
          setMerchantSubSegmentsPublishing(JSON.parse(response.data[0].SubsegmentDetails));
          // setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [productList,setProductList] = React.useState();
 const getProductList =(segmentId,subsegmentid)=>{
  api
      .getData("/api/publishing/Get_Product_Publishing?merchant_id="+response?.merchant_id+'&segment_id='+segmentId+'&subsegment_id='+subsegmentid)
      .then((response) => {
        if (response.data[0].Status === "200") {
          setProductList(JSON.parse(response.data[0].Product));
          // setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
 }
  useEffect(()=>{
    getMerchantSegmentsPublishing();
  },[])

  const changeSegmentStatus = (segmentid,item) =>{
    let data = {
      segment_id: segmentid,
      merchant_id: response?.merchant_id,
      third_party_vendor:item.third_party_vendor
    }
    api
    .postData("/api/publishing/Update_Merchant_segments_Publishing",data )
    .then((response) => {
      if (response && response.data[0].Status === "200") {
        showSwalWithLink(response.data[0].Message);
        // setSuccess(true);
        getMerchantSegmentsPublishing();
      }else{
        showSwalWithLinkError(response.data.Message)
      }
    })
    .catch((error) => {
      // Handle error
      console.error(error);
    });
  }
  const changeSubSegmentStatus = (e,segmentid,subsegmentid,item) =>{
    e.stopPropagation();
    api
    .postData("/api/publishing/Update_Merchant_Sub_Segments_Status_Publishing", {
      segment_id: segmentid,
      subsegment_id:subsegmentid,
      merchant_id: response?.merchant_id,
      third_party_vendor:item.third_party_vendor
    })
    .then((response) => {
      if (response && response.data[0].Status === "200") {
        showSwalWithLink(response.data[0].Message);
        // setSuccess(true);
        getMerchantSubSegmentsPublishing(segmentid);
      }else{
        showSwalWithLinkError(response.data.Message)
      }
    })
    .catch((error) => {
      // Handle error
      console.error(error);
    });
  }
  const changeProductStatus = (totalData,filterData) =>{
   
    api
    .postData("/api/publishing/Update_Product_Status_Publishing", {
      merchant_id:response?.merchant_id,
segment_id:totalData?.product_segment_id,
third_party_vendor:filterData&&filterData[0]?.third_party_vendor,
subsegment_id:totalData?.product_sub_segment_id,
product_id:totalData?.product_id

    })
    .then((response) => {
      if (response && response.data[0].Status === "200") {
        showSwalWithLink(response.data[0].Mesage);
        // setSuccess(true);
        getProductList(totalData?.product_segment_id,totalData?.product_sub_segment_id);
      }else{
        showSwalWithLinkError(response.data.Mesage)
      }
    })
    .catch((error) => {
      // Handle error
      console.error(error);
    });
  }
  const changeProductPriceStatus = (val,totalData,filterData) =>{
   
    api
    .postData("/api/publishing/Update_Product_price_Publishing", {
      merchant_id:response?.merchant_id,
segment_id:totalData?.product_segment_id,
third_party_vendor:filterData&&filterData[0]?.third_party_vendor,
subsegment_id:totalData?.product_sub_segment_id,
product_id:totalData?.product_id,
product_price:val
    })
    .then((response) => {
      if (response && response.data[0].Status === "200") {
        
        // setSuccess(true);
        getProductList(totalData?.product_segment_id,totalData?.product_sub_segment_id);
      }
    })
    .catch((error) => {
      // Handle error
      console.error(error);
    });
  }
  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: false,
    pagination: false,
  };
  const ItemColumns = [
    {
      name: "",
      label: "S.No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {name: "product_name",
    label:"Product"},
    {name: "sale_price",
    label:"Product Price"},
    {name: "third_party_vendor",
    label:"Swiggy / Price",
     options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>;
      },
    },},
    {name: "third_party_vendor",
    label:"Zomato / Price",
     options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>;
      },
    }},
    {name: "third_party_vendor",
    label:"POS / Price",
     options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const filtered = value.filter((item) =>
      item.vendor_name.toLowerCase().includes("POS".toLowerCase())
    );
    const rowIndex = tableMeta.rowIndex;
    const rowData = productList[rowIndex];
        return <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} checked={filtered[0]?.product_status===0?true:false} onChange={()=>{changeProductStatus(rowData,filtered)}} />} />
        <FormControl className="w-100">
          <OutlinedInput defaultValue={filtered[0]?.product_price} disabled={filtered[0]?.product_status===0?false:true} placeholder="Price" className="td-input" onChange={(e)=>{changeProductPriceStatus(e.target.value,rowData,filtered)}} />
        </FormControl>
      </Box>;
      },
    }},
    {name: "third_party_vendor",
    label:"Phone Order / Price",
     options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const filtered = value.filter((item) =>
        item.vendor_name.toLowerCase().includes("Phone Orders".toLowerCase())
      );
      const rowIndex = tableMeta.rowIndex;
      const rowData = productList[rowIndex];
        return <Box className="td-flex">
         <FormControlLabel control={<IOSSwitch sx={{}} checked={filtered[0]?.product_status===0?true:false} onChange={()=>{changeProductStatus(rowData,filtered)}} />} />
        <FormControl className="w-100">
          <OutlinedInput defaultValue={filtered[0]?.product_price} disabled={filtered[0]?.product_status===0?false:true} placeholder="Price" className="td-input" onChange={(e)=>{changeProductPriceStatus(e.target.value,rowData,filtered)}} />
        </FormControl>
      </Box>;
      },
    }},
    {name: "third_party_vendor",
    label: "Ecommerce / Price",
     options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const filtered = value.filter((item) =>
        item.vendor_name.toLowerCase().includes("E Commerce".toLowerCase())
      );
      const rowIndex = tableMeta.rowIndex;
      const rowData = productList[rowIndex];
        return <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} checked={filtered[0]?.product_status===0?true:false} onChange={()=>{changeProductStatus(rowData,filtered)}} />} />
        <FormControl className="w-100">
          <OutlinedInput defaultValue={filtered[0]?.product_price} disabled={filtered[0]?.product_status===0?false:true} placeholder="Price" className="td-input" onChange={(e)=>{changeProductPriceStatus(e.target.value,rowData,filtered)}} />
        </FormControl>
      </Box>;
      },
    }},
    {name: "third_party_vendor",
    label:"Mobile App / Price",
     options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const filtered = value.filter((item) =>
        item.vendor_name.toLowerCase().includes("Mobile App".toLowerCase())
      );
      const rowIndex = tableMeta.rowIndex;
      const rowData = productList[rowIndex];
        return <Box className="td-flex">
         <FormControlLabel control={<IOSSwitch sx={{}} checked={filtered[0]?.product_status===0?true:false} onChange={()=>{changeProductStatus(rowData,filtered)}} />} />
        <FormControl className="w-100">
          <OutlinedInput defaultValue={filtered[0]?.product_price} disabled={filtered[0]?.product_status===0?false:true} placeholder="Price" className="td-input" onChange={(e)=>{changeProductPriceStatus(e.target.value,rowData,filtered)}} />
        </FormControl>
      </Box>;
      },
    }},
    {name: "third_party_vendor",
    label:"Procurement / Price",
     options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const filtered = value.filter((item) =>
        item.vendor_name.toLowerCase().includes("Procurement".toLowerCase())
      );
      const rowIndex = tableMeta.rowIndex;
      const rowData = productList[rowIndex];
        return <Box className="td-flex">
         <FormControlLabel control={<IOSSwitch sx={{}} checked={filtered[0]?.product_status===0?true:false} onChange={()=>{changeProductStatus(rowData,filtered)}} />} />
        <FormControl className="w-100">
          <OutlinedInput defaultValue={filtered[0]?.product_price} disabled={filtered[0]?.product_status===0?false:true} placeholder="Price" className="td-input" onChange={(e)=>{changeProductPriceStatus(e.target.value,rowData,filtered)}} />
        </FormControl>
      </Box>;
      },
    }}
    
   
    
    
  ];
  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const orderItemData1 = [
    [
      "1",
      "Chicken Hakka Noodles",
      "300.00",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
    ],
    [
      "2",
      "Egg Noodles",
      "300.00",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
    ],
    [
      "3",
      "Chicken Chilli Garlic Noodles",
      "300.00",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
    ],
  ];
  const orderItemData = [
    [
      "1",
      "Veg Hakka Noodles",
      "300.00",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
    ],
    [
      "2",
      "Singapuri Noodles",
      "300.00",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
    ],
    [
      "3",
      "Chilli Garlic Noodles",
      "300.00",
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
      <Box className="td-flex">
        <FormControlLabel control={<IOSSwitch sx={{}} />} />
        <FormControl className="w-100">
          <OutlinedInput value="" placeholder="Price" className="td-input" />
        </FormControl>
      </Box>,
    ],
  ];
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Bredcrum />
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            SEGMENT
                          </Typography>
                        </Box>
                        <Divider></Divider>
                        <Grid container spacing={2} className="p-15">
                          {
                            merchantSegmentsPublishing?.map((item,index)=>{
                             return <Grid item md={12} className="accordion-div">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <svg
                                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    data-testid="ArrowDropUpIcon"
                                  >
                                    <path d="m7 16 4-7 4 7z"></path>
                                  </svg>
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="accordian-header"
                                onClick={()=>{handleClick(index);getMerchantSubSegmentsPublishing(item?.merchant_segment_id)}}
                              >
                                {item.merchant_segment_name}
                                <Button
                                  className="btn btn-outline-primary"
                                  
                                >
                                  View More
                                </Button>
                              </AccordionSummary>
                              <AccordionDetails>
                                {
                                  item?.third_party_vendor?.map((items)=>{
                                      return (<Box><Box className="d-flex">
                                      <Typography component="p">{items?.vendor_name}</Typography>
                                      <FormControlLabel
                                        control={<IOSSwitch checked={items?.segment_status === 0?true:false} sx={{ m: 1 }} onChange={()=>{changeSegmentStatus(item?.merchant_segment_id,items)}} />}
                                      />
                                    </Box>
                                    <Divider></Divider></Box>
                                    )
                                  })
                                }
                                
                                {/* <Box className="d-flex">
                                  <Typography component="p">
                                    Phone Order
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Ecommerce
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Mobile App
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Procurement
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box> */}
                                {/* <Divider></Divider>
                                <Box
                                  className="d-flex"
                                  justifyContent={"center"}
                                  marginTop={1}
                                >
                                  <Button
                                    className="btn btn-outline-primary"
                                    onClick={handleClick}
                                  >
                                    View More
                                  </Button>
                                </Box> */}
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                            })
                          }
                         
                          {/* <Grid item md={12} className="accordion-div">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <svg
                                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    data-testid="ArrowDropUpIcon"
                                  >
                                    <path d="m7 16 4-7 4 7z"></path>
                                  </svg>
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="accordian-header"
                              >
                                Burger
                                <Button
                                  className="btn btn-outline-primary"
                                  onClick={handleClick}
                                >
                                  View More
                                </Button>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box className="d-flex">
                                  <Typography component="p">POS</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Phone Order
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Ecommerce
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Mobile App
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Procurement
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                {/* <Divider></Divider>
                                <Box
                                  className="d-flex"
                                  justifyContent={"center"}
                                  marginTop={1}
                                >
                                  <Button className="btn btn-outline-primary">
                                    View More
                                  </Button>
                                </Box> */}
                              {/* </AccordionDetails>
                            </Accordion>
                          </Grid> */}
                          {/* <Grid item md={12} className="accordion-div">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={
                                  <svg
                                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    data-testid="ArrowDropUpIcon"
                                  >
                                    <path d="m7 16 4-7 4 7z"></path>
                                  </svg>
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                className="accordian-header"
                              >
                                Sandwich
                                <Button
                                  className="btn btn-outline-primary"
                                  onClick={handleClick}
                                >
                                  View More
                                </Button>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box className="d-flex">
                                  <Typography component="p">POS</Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Phone Order
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Ecommerce
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Mobile App
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                <Divider></Divider>
                                <Box className="d-flex">
                                  <Typography component="p">
                                    Procurement
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} />}
                                  />
                                </Box>
                                {/* <Divider></Divider>
                                <Box
                                  className="d-flex"
                                  justifyContent={"center"}
                                  marginTop={1}
                                >
                                  <Button className="btn btn-outline-primary">
                                    View More
                                  </Button>
                                </Box> */}
                              {/* </AccordionDetails>
                            </Accordion>
                          </Grid>  */} 
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Card className="customer_cards">
                      <CardContent className="p-0">
                        <Box mb={2} className="sm-selects p-15">
                          <Typography component="h4" className="sub_title">
                            SUB SEGMENT
                          </Typography>
                          <Box>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn bg-white"
                              mr={2}
                            >
                              Vendor
                            </Button>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn bg-white"
                              mr={2}
                            >
                              Submit
                            </Button>
                          </Box>
                        </Box>
                        <Divider></Divider>
                        <Grid container spacing={2}>
                          {/* <Grid item md={12}>
                            <Box mb={2} className="sm-selects p-15">
                              <Typography component="h4" className="sub_title">
                                Noodles
                              </Typography>
                            </Box>
                          </Grid> */}
                        </Grid>
                        {showState && merchantSubSegmentsPublishing&&(
                          <Grid container spacing={2} padding={2} mt={1}>
                            {
                              merchantSubSegmentsPublishing?.map((item)=>{
                                return <Grid item md={12} className="accordion-div">
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={
                                      <svg
                                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                        focusable="false"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="ArrowDropUpIcon"
                                      >
                                        <path d="m7 16 4-7 4 7z"></path>
                                      </svg>
                                    }
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    className="accordian-header"
                                    onClick={()=>{getProductList(item?.merchant_segment_id,item?.merchant_sub_segment_id)}}
                                  >
                                    <Typography
                                      component="p"
                                      className="accordion-heading-ss"
                                    >
                                      {item?.merchant_sub_segment_name}
                                    </Typography>
                                    <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        Swiggy
                                      </Typography>
                                    </Box>
                                    <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        Zomato
                                      </Typography>
                                    </Box>
                                    {
                                      item?.third_party_vendor?.map((items)=>{
                                        return <Box className="td-flex">
                                        <FormControlLabel
                                          control={<IOSSwitch sx={{}} checked={
                                            items?.subsegment_status === 0?true:false
                                          } onChange={(e)=>{changeSubSegmentStatus(e,item?.merchant_segment_id,item?.merchant_sub_segment_id,items)}} />}
                                        />
                                        <Typography
                                          component="p"
                                          className="third-party-vendor-name"
                                        >
                                          {
                                            items?.vendor_name
                                          }
                                        </Typography>
                                      </Box>
                                      })
                                    }
                                    
                                    {/* <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        Phone Orders
                                      </Typography>
                                    </Box>
                                    <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        ECommerce
                                      </Typography>
                                    </Box>
                                    <Box className="td-flex">
                                      <FormControlLabel
                                        control={<IOSSwitch sx={{}} />}
                                      />
                                      <Typography
                                        component="p"
                                        className="third-party-vendor-name"
                                      >
                                        Procurement
                                      </Typography>
                                    </Box> */}
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <MemberTable
                                      columns={ItemColumns}
                                      options={itemOptions}
                                      data={productList?productList:[]}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              </Grid>
                              })
                            }
                            
                            {/* <Grid item md={12} className="accordion-div">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="ArrowDropUpIcon"
                                    >
                                      <path d="m7 16 4-7 4 7z"></path>
                                    </svg>
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="accordian-header"
                                >
                                  <Typography
                                    component="p"
                                    className="accordion-heading-ss"
                                  >
                                    Non Veg Noodles
                                  </Typography>
                                  <Box className="td-flex">
                                    <FormControlLabel
                                      control={<IOSSwitch sx={{}} />}
                                    />
                                    <Typography
                                      component="p"
                                      className="third-party-vendor-name"
                                    >
                                      Swiggy
                                    </Typography>
                                  </Box>
                                  <Box className="td-flex">
                                    <FormControlLabel
                                      control={<IOSSwitch sx={{}} />}
                                    />
                                    <Typography
                                      component="p"
                                      className="third-party-vendor-name"
                                    >
                                      Zomato
                                    </Typography>
                                  </Box>
                                  <Box className="td-flex">
                                    <FormControlLabel
                                      control={<IOSSwitch sx={{}} />}
                                    />
                                    <Typography
                                      component="p"
                                      className="third-party-vendor-name"
                                    >
                                      POS
                                    </Typography>
                                  </Box>
                                  <Box className="td-flex">
                                    <FormControlLabel
                                      control={<IOSSwitch sx={{}} />}
                                    />
                                    <Typography
                                      component="p"
                                      className="third-party-vendor-name"
                                    >
                                      Phone Orders
                                    </Typography>
                                  </Box>
                                  <Box className="td-flex">
                                    <FormControlLabel
                                      control={<IOSSwitch sx={{}} />}
                                    />
                                    <Typography
                                      component="p"
                                      className="third-party-vendor-name"
                                    >
                                      ECommerce
                                    </Typography>
                                  </Box>
                                  <Box className="td-flex">
                                    <FormControlLabel
                                      control={<IOSSwitch sx={{}} />}
                                    />
                                    <Typography
                                      component="p"
                                      className="third-party-vendor-name"
                                    >
                                      Procurement
                                    </Typography>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <MemberTable
                                    columns={ItemColumns}
                                    options={itemOptions}
                                    data={orderItemData1}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            </Grid> */}
                          </Grid>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        {/* <LocationModal ref={ref4} /> */}
      </Box>
    </>
  );
}
