import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import api from "../Api";
import withReactContent from "sweetalert2-react-content";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { Select, MenuItem } from "@mui/material";
import MemberTable from "../Customer/MemberTable";
import { addEditFees } from "../Validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AddFeesModal = forwardRef((props, ref3) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const response = useSelector((state) => state.user.user);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      // props?.onUpdate(true);
      props?.getTaxfeemerchantdetails();
      handleClose();
    });
  };

  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };
  const handleSubmit = (values) => {
    console.log(values);
    //if(formik.values.merchant_id === ''){
      formik.values.merchant_id=response?.merchant_id;
      formik.values.fee_on=2;
      formik.values.fee_id=0;
      
    //}
    console.log(formik,response);
    api
      .postData("/api/tax_fee/insert_update_fee_merchant", formik.values)
      .then((response) => {
        if (response.data[0].status === "200") {
          // setEmpId(response.data[0].new_employee_id)
          showSwalWithLink(response.data[0].message);
        } else {
          showSwalWithError(response.data[0].message);
        }
      });
  };

  const initialValues = {
    'fee_id':'0',
    'merchant_id':response?.merchant_id,
    'fee_name':'',
    'fee_type':'',
    'fee_amount':'',
    'fee_on':'2'
  };
  const formik = useFormik({
    initialValues,
    validationSchema: addEditFees,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
  formik;
  useImperativeHandle(ref3, () => ({
    log() {
      handleOpen();
      formik.setTouched({},false);
      formik.setValues({});
    },
  }));


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product ">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              ADD NEW FEES
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
           <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            mt={1}
            mb={3}
            className="pickers"
            spacing={3}
            paddingRight={2}
          >
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Fee Name</Typography>
                <OutlinedInput placeholder="Fee Name" name="fee_name"
                  value={formik.values.fee_name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }} />
                   {errors.fee_name && touched.fee_name && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.fee_name}
                  </Box>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                
                <Typography component="label">Fee Type</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  name="fee_type"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  defaultValue=""
                >
                  <MenuItem value="">Select Fee Type</MenuItem>
                  <MenuItem value="fixed">Fixed</MenuItem>
                    <MenuItem value="percent">Percentage</MenuItem>
                </Select>
                  {/* <OutlinedInput placeholder="Fee Type" name="fee_type"
                  value={formik.values.fee_type}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }} /> */}
              
                {errors.fee_type && touched.fee_type && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.fee_type}
                  </Box>
                )}
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Fee Amount</Typography>
                <OutlinedInput placeholder="Fee Amount" name="fee_amount" value={formik.values.fee_amount}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}  />
                  {errors.fee_amount && touched.fee_amount && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.fee_amount}
                  </Box>
                )}
              </FormControl>
            </Grid>
            {/* <Grid item md={6}>
              <FormControl className="form_control" fullWidth>
                
                <Typography component="label">Fee Applicable Type</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  name="fee_on"
                  hidden
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                  }}
                  value={2}
                >
                  <MenuItem value="">Select</MenuItem>
                 {
                  props.feeMaster?.map((item)=>{
                    return <MenuItem value={item?.master_fee_id}>{item?.master_fee_name}</MenuItem>
                  })
                 }
                </Select>
              
                {errors.fee_on && touched.fee_on && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.fee_on}
                  </Box>
                )}
              </FormControl>
            </Grid> */}
          </Grid>
          <Divider></Divider>
          <Box className="p-15" mb={2}>
            <Button
              variant="outlined"
              color="error"
              className="retail-btn float-right"
             type="submit"
            >
              Submit
            </Button>
          </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
});

export default AddFeesModal;
