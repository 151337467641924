import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import upload from "../images/upload.svg";
import { useFormik } from "formik";
import api from "../Api";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import defaultProfile from "../images/defaultprofile.png";
// import { editProfile } from '../Validation';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const EditProfileModal = forwardRef((props, ref5) => {
  let data = "";
  if (props.edit) {
    data = props.props;
  }

  // const profileData = props.profileData;

  const response = useSelector((state) => state.user.user);
  const profileData = useSelector((state) => state.user.profile);
  const cities = useSelector((state) => state.user.cities);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let services = useSelector((state) => state.user.services);
  const [phoneStatus, setPhoneStatus] = React.useState();
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState("");
  const [show, setShow] = React.useState(false);
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      handleClose();
    });
  };
  const handleChanges = () => {
    setShow((show) => !show);
  };

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];
  const [profileImage, setProfileImage] = React.useState(null);
  const editProfile = Yup.object().shape({
    merchant_business_name: Yup.string().required("Business Name is required"),
    city_id: Yup.string().required("City is required"),
    service_id: Yup.string().required("Service is required"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .matches(
        /^91\d{10}$/,
        "Phone number should start with 91 and have 10 digits"
      ),
    //   .test('is-number', 'Phone number must be numeric', value => /^\d+$/.test(value))
    bank_name: Yup.string().required(),
    bank_branch_name: Yup.string().required(),
    ifsc_code: Yup.string().required(),
    account_number: Yup.string().required(),
    gst_percentage:Yup.number().lessThan(101, "GST Percentage should not be more than 100"),
    paytm_number: Yup.string().matches(
        /^91\d{10}$/,
        "Paytm number should start with 91 and have 10 digits"
      ),
      google_pay_number: Yup.string().matches(
        /^91\d{10}$/,
        "Google Pay number should start with 91 and have 10 digits"
      ),
      phone_pay_number: Yup.string().matches(
        /^91\d{10}$/,
        "Phone Pay number should start with 91 and have 10 digits"
      ),
  });
  const handleImageSelection = (e) => {
    const file = e.target.files[0];
    if(file){
      setProfileImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleSubmit = (values) => {
    // delete formik.values["admin_email"];
    // delete formik.values["phone_number"];
    if (
      formik.values.phone_number &&
      formik.values.phone_number.length === 10
    ) {
      formik.values.phone_number =
        data.country_code + formik.values.phone_number;
    }

      formik.values.profilephoto = profileImage?profileImage:formik.values.business_icon;
    
    //    console.log(data?.phone_number === formik.values.phone_number?0:setPhoneStatus(1));
    if (data.phone_number !== formik.values.phone_number) {
      formik.values.isphone_changed = "0";
    } else {
      formik.values.isphone_changed = "0";
    }

    api.updateData("/api/merchant/register", formik.values).then((response) => {
      console.log("response", response);
      if (response.data.Status === "200") {
        // setMessage(response.data.Message);
        // setSpinner(false);
        showSwalWithLink(response.data.Message);
        props.onUpdate(true);
        // if (response.data[0].Status === "200" && response.data[0].IsPhoneVerifyRequired === 'Yes') {

        // }
      } else {
      }
    });
  };
  console.log(profileData)
  const initialValues = {
    //   'user_name': data?.user_name,
    merchant_id: response?.merchant_id,
    merchant_business_name: profileData?.merchant_business_name || "",
    service_id: profileData?.service_id || "",
    service_prefix: profileData?.service_prefix || "",
    country_id: profileData?.country_id || "",
    city_id: profileData?.city_id || "",
    admin_email: profileData?.admin_email || "",
    phone_number: profileData?.phone_number || "",
    business_icon: profileData?.business_icon || "",
    country_code: response?.country_code || "",
    // 'isemail_changed': "0",
    // 'isphone_changed': '0',
    //   "merchant_type":process.env.REACT_APP_MY_TITLE === 'Bazaar'?1:0,
    bank_name: profileData?.bank_name || "",
    bank_branch_name: profileData?.bank_branch_name || "",
    ifsc_code: profileData?.ifsc_code || "",
    account_number: profileData?.account_number || "",
    account_holder_name: profileData?.account_holder_name || "",
    paytm_number: profileData?.paytm_number || "",
    google_pay_number: profileData?.google_pay_number || "",
    phone_pay_number: profileData?.phone_pay_number || "",
    other_upi_number: profileData?.other_upi_number || "",
    status_1: "0",
    gst_number: profileData?.gst_number || "",
    gst_percentage: profileData?.gst_percentage || "",

    // email: "",
    // phonenumber: "",
    // password: "",
    // confirmpassword: ""
  };

  const formik = useFormik({
    initialValues,
    validationSchema: editProfile,
    onSubmit: handleSubmit,
    enableReinitialize:true,
  });
  useEffect(() => {
    console.log(initialValues);
    formik.setValues(initialValues);
  }, [profileData]);

  const handleData = (event) => {
    console.log("event.target", event.target);
    const { name, value } = event.target;
    // Yup.reach(RegisterSchema, name).validate(value);
    console.log(name, value);
    if (name === "phone_number") {
      console.log("welcome");
      if (data.phone_number !== value) {
        console.log("welcome");
        const updatedValues = {
          ...formik.values,
          ["isphone_changed"]: "0",
        };
        console.log("updatedValues", updatedValues);
        formik.setValues(updatedValues);
        console.log("isphone_changed", formik.values);
      }
    }
    if (name === "service_id") {
      let arr = value?.split("-");
      console.log("formik.initialValues", arr[0], arr[1]);
      const updatedValues = {
        ...formik.values,
        ["service_id"]: arr[0],
        ["service_prefix"]: arr[1],
      };
      formik.setValues(updatedValues);
    } else if (name === "country_id") {
      let arr1 = value?.split("-");
      const updatedValues = {
        ...formik.values,
        ["country_id"]: arr1[1],
        ["country_code"]: arr1[0],
      };
      formik.setValues(updatedValues);
    } else {
      const updatedValues = {
        ...formik.values,
        [name]: value,
      };
      formik.setValues(updatedValues);
      console.log(updatedValues);
    }

    console.log("formik.initialValues", formik.values);
  };

  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref5, () => ({
    log() {
      handleOpen();
      formik.setValues(initialValues);
      formik.setTouched({}, false);
    },
  }));

  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content adm-modal">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              EDIT PROFILE
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <form onSubmit={formik.handleSubmit}>
            <Box className="add_new_item">
              <Grid container spacing={2} className="p-15">
                <Grid mt={2} xs={12} md={3}>
                  <Box
                    component="img"
                    src={
                      imagePreviewUrl
                        ? imagePreviewUrl
                        : formik.initialValues?.business_icon
                    }
                    mt={2}
                    style={{ width: "150px" }}
                  />
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      component="label"
                      className="btn-upload"
                    >
                      Upload image
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        name="business_icon"
                        onChange={(e) => {
                          handleChange(e);
                          handleImageSelection(e);
                        }}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                      />
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container>
                    <FormControl className="w-100">
                      {/* <Typography component='label'>Item Name</Typography> */}
                      <Typography component="label">Business Name</Typography>
                      <OutlinedInput
                        placeholder="Business Name"
                        value={formik.values?.merchant_business_name}
                        name="merchant_business_name"
                        id="merchant_business_name"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                      />
                    </FormControl>
                    {errors.merchant_business_name &&
                      touched.merchant_business_name && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.merchant_business_name}
                        </Box>
                      )}
                  </Grid>

                  <Grid className="flex gap-10" spacing={2} mt={1} mb={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl className="w-100">
                        <Typography component="label">Category</Typography>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          name="service_id"
                          onChange={handleChange}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleData(e);
                          }}
                          defaultValue={formik.values?.service_id+ '-' + formik.values?.service_prefix}
                        >
                         {
                              services?.map((item) => {

                                return <MenuItem value={item.service_id + '-' + item.service_prefix}>{item.service_name}</MenuItem>


                              })
                            }
                        </Select>
                      </FormControl>
                      {errors.service_id && touched.service_id && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.service_id}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl className="w-100">
                        <Typography component="label">City</Typography>
                        {/* <Select
                                                labelId="demo-isolation-label"
                                                id="isolation-select"
                                                className="select"
                                                // onChange={handleSelectOxygen}
                                                // input={<OutlinedInput />}
                                                defaultValue="Kurnool"
                                            >
                                                <MenuItem value="Kurnool">Kurnool</MenuItem>
                                                <MenuItem value="one">One</MenuItem>
                                                <MenuItem value="two">Two</MenuItem>
                                                <MenuItem value="two">Three</MenuItem>

                                            </Select> */}

                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select select-equipment"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          value={formik.values?.city_id}
                          name="city_id"
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        >
                          {cities?.map((item) => {
                            return (
                              <MenuItem value={item.city_id}>
                                {item.city_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {errors.city_id && touched.city_id && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.city_id}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Grid className="flex gap-10" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl className="w-100">
                        <Typography component="label">Primary Email</Typography>
                        <OutlinedInput
                          name="admin_email"
                          value={formik.values?.admin_email}
                          disabled
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleData(e);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl className="w-100">
                        <Typography component="label">Phone Number</Typography>
                        <OutlinedInput
                          name="phone_number"
                          value={formik.values?.phone_number}
                          onChange={(e) => handleChange(e)}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleData(e);
                          }}
                        />
                      </FormControl>
                      {errors.phone_number && touched.phone_number && (
                        <Box
                          Typography="small"
                          className="float-left text-danger small-spinner"
                        >
                          {errors.phone_number}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container mb={1} mt={2}>
                    <FormControl className="w-100">
                      <Typography component="label">
                        Having a GST Number ?
                      </Typography>
                      <IOSSwitch checked={show} onChange={handleChanges} />
                    </FormControl>
                  </Grid>
                  {show ? (
                    <Grid className="flex gap-10" spacing={2}>
                      <Grid item xs={12} md={6}>
                        <FormControl className="w-100">
                          <Typography component="label">GST Number</Typography>
                          <OutlinedInput
                            name="gst_number"
                            defaultValue={formik.values?.gst_number}
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => {
                              handleBlur(e);
                              handleData(e);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl className="w-100">
                          <Typography component="label">
                            GST Tax Rate
                          </Typography>
                          <OutlinedInput
                            name="gst_percentage"
                            defaultValue={formik.values?.gst_percentage}
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => {
                              handleBlur(e);
                              handleData(e);
                            }}
                          />
                        </FormControl>
                        {errors.gst_percentage && touched.gst_percentage && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.gst_percentage}
                      </Box>
                    )}
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
                <Divider></Divider>
                <Grid
                  container
                  spacing={3}
                  mt={1}
                  mb={3}
                  className="textarea-div"
                >
                  <Grid item xs={12} md={4}>
                    <FormControl className="w-100">
                      <Typography component="label">Bank Name</Typography>
                      <OutlinedInput
                        defaultValue={formik.values?.bank_name}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                        name="bank_name"
                      />
                    </FormControl>
                    {errors.bank_name && touched.bank_name && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.bank_name}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl className="w-100">
                      <Typography component="label">Branch Name</Typography>
                      <OutlinedInput
                        defaultValue={formik.values?.bank_branch_name}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                        name="bank_branch_name"
                      />
                    </FormControl>
                    {errors.bank_branch_name && touched.bank_branch_name && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.bank_branch_name}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl className="w-100">
                      <Typography component="label">IFSC Code</Typography>
                      <OutlinedInput
                        defaultValue={formik.values?.ifsc_code}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                        name="ifsc_code"
                      />
                    </FormControl>
                    {errors.ifsc_code && touched.ifsc_code && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.ifsc_code}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl className="w-100">
                      <Typography component="label">Account Number</Typography>
                      <OutlinedInput
                        defaultValue={formik.values?.account_number}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                        name="account_number"
                      />
                    </FormControl>
                    {errors.account_number && touched.account_number && (
                      <Box
                        Typography="small"
                        className="float-left text-danger"
                      >
                        {errors.account_number}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl className="w-100">
                      <Typography component="label">
                        Account Holder Name
                      </Typography>
                      <OutlinedInput
                        defaultValue={formik.values?.account_holder_name}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                        name="account_holder_name"
                      />
                    </FormControl>
                    {errors.account_holder_name &&
                      touched.account_holder_name && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.account_holder_name}
                        </Box>
                      )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl className="w-100">
                      <Typography component="label">
                        Other UPI ID's (Enter UPI Id's with comma separated
                        values)
                      </Typography>
                      <OutlinedInput
                        defaultValue={formik.values?.other_upi_number}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                        name="other_upi_number"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl className="w-100">
                      <Typography component="label">Paytm No</Typography>
                      <OutlinedInput
                        defaultValue={formik.values?.paytm_number}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                        name="paytm_number"
                      />
                    </FormControl>
                    {errors.paytm_number &&
                      touched.paytm_number && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.paytm_number}
                        </Box>
                      )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl className="w-100">
                      <Typography component="label">Google Pay No</Typography>
                      <OutlinedInput
                        defaultValue={formik.values?.google_pay_number}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                        name="google_pay_number"
                      />
                    </FormControl>
                    {errors.google_pay_number &&
                      touched.google_pay_number && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.google_pay_number}
                        </Box>
                      )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl className="w-100">
                      <Typography component="label">Phonepe No</Typography>
                      <OutlinedInput
                        defaultValue={formik.values?.phone_pay_number}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleData(e);
                        }}
                        name="phone_pay_number"
                      />
                    </FormControl>
                    {errors.phone_pay_number &&
                      touched.phone_pay_number && (
                        <Box
                          Typography="small"
                          className="float-left text-danger"
                        >
                          {errors.phone_pay_number}
                        </Box>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Divider></Divider>
            <Box className="p-15">
              <Button
                variant="outlined"
                color="error"
                mt={2}
                mb={3}
                className="retail-btn float-right"
                value="Submit"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
});

export default EditProfileModal;
