// import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { configureStore, DevToolsEnhancerOptions, getDefaultMiddleware, combineReducers } from "@reduxjs/toolkit"
 // defaults to localStorage for web
 
import rootReducer from './Redux/reducer';
 
const persistConfig = {
  key: 'root',
  storage,
}
const middleware = [thunk, ...getDefaultMiddleware()];
const persistedReducer = persistReducer(persistConfig, rootReducer)
 
// export default () => {
    export let store = configureStore({reducer:persistedReducer,middleware});
    export let persistor = persistStore(store);
//   return { store, persistor }
// }