import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Breadcrumbs,
  Link,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import SearchBar from "material-ui-search-bar";
import Swal from "sweetalert2";
import api from "../Api";
import { useFormik } from "formik";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { helpAndSupport } from "../Validation";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function HelpAndSupport() {
  const [showState, setShowState] = React.useState(false);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");
  const MySwal = withReactContent(Swal);

  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    });
  };
  const ref = React.useRef();
  const ref4 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();
  const handleSubmit = (values) => {

    console.log("formik.values", formik.values);

    api
      .postData("/api/segments/Update_Help_Support", formik.values)
      .then((response) => {
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
        }
      });
  };
  const getHelpData = (values) => {
    //   return false;

    api.getData("/api/segments/Get_Help_Support").then((response) => {
      console.log("response", response);
      if (response.data[0].Status === "200") {
        setHelpData(JSON.parse(response.data[0].SupportDetails)[0]);
      }
    });
  };
  const convertTime = (value) => {
    const time24 = value;
    const dateObj = new Date(`2000-01-01T${time24}`);
    const time12 = dateObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return time12;
  };
  const [helpData, setHelpData] = React.useState();
  const initialValues = {
    support_id: helpData?.support_id || "0",
    support_name: helpData?.support_name || "",
    support_email: helpData?.support_email || "",
    order_support_contact_number: helpData?.order_support_contact_number || "",
    merchant_support_contact_number:
      helpData?.merchant_support_contact_number || "",
    refund_support_contact_number:
      helpData?.refund_support_contact_number || "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: helpAndSupport,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  useEffect(() => {
    formik.setValues(initialValues);
  }, [helpData]);
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  useEffect(() => {
    getHelpData();
  }, []);
  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];

  const eiColumns = [
    "Name",
    "Type",
    "Display Order",
    {
      options: {
        label: "STATUS",
        customBodyRender: () => {
          return <IOSSwitch onClick={() => ref2.current.log()} />;
        },
      },
    },
  ];
  const eiData = [
    ["#963258", "Srikakulam", "Ramya"],
    ["#963258", "Srikakulam", "Ramya"],
    ["#856214", "Vizianagaram", "Srilekha"],
  ];
  const mlColumns = [
    "DATE",
    "TYPE",
    "TASK",
    "LIFE OF REPAIR",
    "COST",
    "COMMENTS",
  ];

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: true,
    filter: true,
    filterType: true,
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(rowData, rowMeta);
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Taxes:</b> ₹ 485.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Advance:</b> ₹ 321.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Due Amount:</b> ₹ 542.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>State:</b> State
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    pagination: true,
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card add-merchant">
              <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Breadcrumbs aria-label="breadcrumb">
                    {/* <Link underline="hover" color="inherit" href="/">
                      Home
                    </Link> */}
                    <Link underline="hover" color="inherit" href="#">
                      Admin
                    </Link>
                    <Typography color="text.primary">Help & Support</Typography>
                  </Breadcrumbs>
                  <Box className="icons bredcum-icons">
                    <SearchBar className="search-bar" />
                    <PersonIcon onClick={() => ref.current.log()} />

                    <NotificationsIcon />
                    <LogoutIcon onClick={logoutDashboard} ml={2} />
                  </Box>
                </Grid>
              </Grid>
              <form onSubmit={formik.handleSubmit}>
              <CardContent>
                <Grid container mt={3} spacing={2}>
                  <Grid item md={6}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Support Name
                              </Typography>
                              <OutlinedInput
                                placeholder="Support Name"
                                name="support_name"
                                value={formik.values.support_name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.support_name && touched.support_name && (
                                <Box
                                  Typography="small"
                                  className="float-left text-danger"
                                >
                                  {errors.support_name}
                                </Box>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item md={6}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Support Email
                              </Typography>
                              <OutlinedInput
                                name="support_email"
                                value={formik.values.support_email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Support Email"
                              />
                              {errors.support_email &&
                                touched.support_email && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.support_email}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Order Support Contact No
                              </Typography>
                              <OutlinedInput
                                name="order_support_contact_number"
                                value={
                                  formik.values.order_support_contact_number
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Order Support Contact No"
                              />
                              {errors.order_support_contact_number &&
                                touched.order_support_contact_number && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.order_support_contact_number}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Merchant Support Contact No
                              </Typography>
                              <OutlinedInput
                                name="merchant_support_contact_number"
                                value={
                                  formik.values.merchant_support_contact_number
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Merchant Support Contact No"
                              />
                              {errors.merchant_support_contact_number &&
                                touched.merchant_support_contact_number && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.merchant_support_contact_number}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item md={4}>
                            <FormControl className="w-100">
                              <Typography component="label">
                                Refund Support Contact No
                              </Typography>
                              <OutlinedInput
                                name="refund_support_contact_number"
                                value={
                                  formik.values.refund_support_contact_number
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Refund Support Contact No"
                              />
                              {errors.refund_support_contact_number &&
                                touched.refund_support_contact_number && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.refund_support_contact_number}
                                  </Box>
                                )}
                            </FormControl>
                          </Grid>
                          <Box className="p-15 text-center">
                            <Button
                              variant="outlined"
                              color="error"
                              mt={2}
                              mb={3}
                              type="submit"
                              className="retail-btn mr-20"
                            >
                              Update
                            </Button>
                          </Box>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
              </form>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}
