import React, { useEffect } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import {
  DataGrid,
  GridToolbar,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import api from "../Api";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import Bredcrum from "../Bredcrum";
import MemberTable from "../Customer/MemberTable";
// import AddEmployeeModal from "./AddEmployeeModal";
// import ManagementViewMoreModal from "./ManagementViewMoreModal";
import AddTaxesModal from "./AddTaxesModal";
import AddFeesModal from "./AddFeesModal";
import AddEditTaxesModal from "./AddEditTaxesModal";
import EditFeesModal from "./EditFeesModal";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { FormControlLabel } from "@mui/material";
// import { useDemoData } from "@mui/x-data-grid-generator";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

export default function TaxFees() {
  const [value, setValue] = React.useState(0);
  const response = useSelector((state) => state.user.user);
  const [locations, setLocations] = React.useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [editTaxData, setEditTaxData] = React.useState();

  const fetchCities = () => {
    api
      .getData(
        "/api/locations/get_Location_name_pos?merchant_id=" +
          response?.merchant_id +
          "&city_id=" +
          response?.city_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setLocations(JSON.parse(response.data[0].LocationDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const ref3 = React.useRef();
  const ref2 = React.useRef();
  const ref4 = React.useRef();
  const ref1 = React.useRef();
  const [taxes, setTaxes] = React.useState();
  const [fees, setFees] = React.useState();
  const getTaxfeemerchantdetails = () => {
    api
      .getData(
        "/api/tax_fee/get_Tax_fee_merchant_details?merchant_id=" +
          response?.merchant_id
      )
      .then((response) => {
        console.log("response", response);
        if (response.data[0]?.status === "200") {
          setTaxes(JSON.parse(response.data[0].TaxMerchatDetails));
          setFees(JSON.parse(response.data[0].FeeMerchatDetails));
          // setLocation(JSON.parse(response.data[0].LocationDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    })
  };

  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };
  const [segments,setSegments] = React.useState();
  const [subSegments,setSubSegments] = React.useState();
  
  const getSegment = () => {
    api.getData('/api/segments/get_merchant_segment_details?merchant_id=' + response?.merchant_id).then((response) => {

        if (response.data[0].Status === "200") {
            setSegments(JSON.parse(response.data[0].Merchant_Segement_Details));
            setSubSegments(JSON.parse(response.data[0].Merchant_Sub_Segement_Details));
        }
    })
}


  useEffect(() => {
    fetchCities();
    getSegment();
  }, []);

  useEffect(() => {
      getTaxfeemerchantdetails();
  }, []);

  const taxColumns = [
    {
      name: "tax_name",
      label: "Tax Name",
    },
    {
      name: "tax_type",
      label: "Tax Type",
    },
    {
      name: "tax_amount_value",
      label: "Tax Amount",
    },
    // {
    //   name: "tax_segment",
    //   label: "Product Segment",
    // },
    // {
    //   name: "tax_sub_segment",
    //   label: "Product Sub Segment",
    // },
    {
      name: "tax_status",
      label: "Tax Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = taxes[rowIndex];
          return <IOSSwitch checked={value === 0}  onChange={()=>{handleTaxStatusChange(rowData)}}/>;
        },
      },
    },
    {
      name: "",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = taxes[rowIndex];
          return (
            <Button
              variant="outlined"
              color="error"
              mt={2}
              className="retail-btn"
              onClick={() => {
                setEditTaxData(rowData);
                ref2.current.log();
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
  ];
  const [editFeeData,setEditFeeData] = React.useState();
  const FeesColumns = [
    {
      name: "fee_name",
      label: "Fee Name",
    },
    { name: "fee_type", label: "Fee Type" },
    { name: "fee_amount", label: "Fee Amount" },
    {
      name: "fee_status",
      label: "Fee Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = fees[rowIndex];
          return <IOSSwitch checked={value === 0} onChange={()=>{handleFeesStatusChange(rowData)}} />;
        },
      },
    },
    {
      name: "",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = fees[rowIndex];
          return (
            <Button
              variant="outlined"
              color="error"
              mt={2}
              className="retail-btn"
              onClick={() => {
                setEditFeeData(rowData);
                ref4.current.log();
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
  ];
  const [feeMaster,setFeeMaster]= React.useState();
  const [taxMaster,setTaxMaster]= React.useState();
  const getFeeMaster = () => {

    api.getData('/api/tax_fee/get_fee_master').then((response) => {

      if (response.data[0].status === "200") {
        setFeeMaster(JSON.parse(response.data[0].FeeMasterDetails));
          
      }
  })


};
const getTaxMaster = () => {

  api.getData('/api/tax_fee/get_tax_master').then((response) => {
    console.log('response',response);
    if (response.data[0].status === "200") {
      setTaxMaster(JSON.parse(response.data[0].TaxMasterDetails));
        
    }
})


};
useEffect(()=>{
  getFeeMaster();
  getTaxMaster();
},[])
  const handleFeesStatusChange = (rowData) => {

    api.postData('/api/tax_fee/update_fee_merchant_status', {
        'fee_id': rowData?.fee_id,
        'merchant_id': response?.merchant_id

    }
    ).then((response) => {
      console.log('response',response);
        if (response.data.fee_status[0].Status === "200") {
            showSwalWithLink(response.data.fee_status[0].Message);
            getTaxfeemerchantdetails();
        }

    }).catch(error => {
        console.error(error);
    });


};
const handleTaxStatusChange = (rowData) => {

  api.postData('/api/tax_fee/update_tax_merchant_status', {
      'tax_id': rowData?.tax_id,
      'merchant_id': response?.merchant_id

  }
  ).then((response) => {
    console.log('response',response);
      if (response.data.tax_status[0].Status === "200") {
          showSwalWithLink(response.data.tax_status[0].Message);
          getTaxfeemerchantdetails();
      }

  }).catch(error => {
      console.error(error);
  });


};
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />

              <CardContent>
                <Grid container className="full_content">
                  <Grid container spacing={2}>
                    <Grid item md="12" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container>
                            <Grid item md="12">
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Typography mb={2} className="t-heading">
                                  Taxes
                                </Typography>
                                <Button
                                  variant="outlined"
                                  className="btn-outline-primary"
                                  onClick={() => ref1.current.log()}
                                >
                                  Add
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item md="12">
                              {console.log("taxes", taxes)}
                              <MemberTable
                                columns={taxColumns}
                                options={managementOptions}
                                data={taxes ? taxes : []}
                              />
                            </Grid>
                          </Grid>

                          <Grid container mt={1}>
                            <Grid item md="12">
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                              >
                                <Typography mb={2} className="t-heading">
                                  Fees
                                </Typography>
                                <Button
                                  variant="outlined"
                                  className="btn-outline-primary"
                                  onClick={() => ref3.current.log()}
                                >
                                  Add
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item md="12">
                              <MemberTable
                                columns={FeesColumns}
                                options={managementOptions}
                                data={fees ? fees : []}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddTaxesModal ref={ref1} segments={segments} subSegments={subSegments} taxMaster={taxMaster} getTaxfeemerchantdetails={getTaxfeemerchantdetails} />
        <AddEditTaxesModal ref={ref2} editTaxData={editTaxData} segments={segments} subSegments={subSegments} taxMaster={taxMaster} getTaxfeemerchantdetails={getTaxfeemerchantdetails} />
        <AddFeesModal ref={ref3} feeMaster={feeMaster} getTaxfeemerchantdetails={getTaxfeemerchantdetails} />
        <EditFeesModal ref={ref4} feeMaster={feeMaster} editFeeData={editFeeData} getTaxfeemerchantdetails={getTaxfeemerchantdetails} />
      </Box>
    </>
  );
}
