import React, {
  useImperativeHandle,
  useRef,
  forwardRef,
  useEffect,
} from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
  Switch,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";
import Calender from "../Calender/Calender";
import FormGroup from "@mui/material/FormGroup";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import dal1 from "../images/dal1.jpg";
import SearchBar from "material-ui-search-bar";
import MemberTable from "../Customer/MemberTable";
import walmart from "../images/Walmart.jpg";
import AddLocationModal from "./AddLocationModal";
import AddSubSegmentModal from "./AddSubSegmentModal";
import AddSegmentModal from "./AddSegmentModal";
import EditProfileModal from "./EditProfileModal";
import { useSelector, useDispatch } from "react-redux";
import api from "../Api";
import AddIngredientsModals from "./AddIngredientsModal";
import LinearWithValueLabel from "./LinearWithValueLabel";
import Bredcrum from "../Bredcrum";
import {
  setLocations,
  setSegments,
  setProfileData,
  setCity,
  setServices,
} from "../Redux/actions/userActions";
import ProfileComplete from "./ProfileComplete";
import QR from "../images/qr.png";
import EditIngredientsModal from "./EditIngredientsModal";
import AddressGeocoder from "./AddressGeocoder";
import EditSubSegmentModal from "./EditSubSegmentModal";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export default function Profile() {
  const [isLoading, setIsLoading] = React.useState(true);
  const response = useSelector((state) => state.user.user);
  const location = useSelector((state) => state.user.location);
  const segment = useSelector((state) => state.user.segments);
  const profileData = useSelector((state) => state.user.profile);
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {});
  };
  const showSwalWithLinkError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    });
  };
  const dispatch = useDispatch();
  //  const [data,setData] = React.useState();
  const [showState, setShowState] = React.useState(false);
  const [segmentId, setSegementId] = React.useState(1);
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [alignment, setAlignment] = React.useState("left");
  const [edit, setEdit] = React.useState(false);
  const ref = React.useRef();
  const ref5 = React.useRef();
  const ref1 = React.useRef();
  const ref10 = React.useRef();
  const ref11 = React.useRef();
  const ref3 = React.useRef();
  const ref2 = React.useRef();
  const ref6 = React.useRef();
  const [editData, setEditData] = React.useState();
  const [editTableData, setEditTableData] = React.useState();
  const [editsubSegmentData, setSubSegmentEditData] = React.useState();
  const editTableOption = (data) => {
 
    setEditTableData(data);
  };
  const editOption = (data) => {
    setEdit(true);
    setEditData(data);
  };

  const editSSOption = (data) => {
    setEdit(true);
    setSubSegmentEditData(data);
  };

  const addOption = () => {
    setEditData("");
    setEdit(false);
  };
  const addssOption = () => {
    setSubSegmentEditData("");
    setEdit(false);
  };
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const ref4 = useRef();

  const pcColumns = [
    { name: "PO ID", options: { filterOptions: { fullWidth: true } } },
    "Retailer Name",
    "Total PO Value",
    "Status",
    "Total PO",
  ];
  const [switchValue, setSwitchValue] = React.useState(0);
  const ingredientsColumns = [
    {
      name: "qr_code_path",
      label: "QR ID",
      options: {
        customBodyRender: (value) => (
          <img src={value} alt="qr code" style={{ width: 40, height: 40 }} />
        ),
      },
    },
    {
      name: "table_id",
      label: "TABLE ID",
    },
    { name: "table_name", label: "TABLE NAME" },
    { name: "no_of_seats", label: "SEATS" },
    { name: "description", label: "DESCRIPTION" },
    {
        name: "",
        label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
            const rowIndex = tableMeta.rowIndex;
            const rowData = tableData[rowIndex];
          return (
            <Button
              variant="outlined"
              color="error"
              mt={2}
              className="retail-btn"
              onClick={() => {
                ref6.current.log();
                editTableOption(rowData);
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
  ];

  const ingredientsdata = [
    [<img src={QR} />, "001", "Table 1", "4", "Lake View"],
    [<img src={QR} />, "002", "Table 2", "8", "Pool View"],
    [<img src={QR} />, "003", "Table 3", "2", "Beach View"],
  ];
  useEffect(() => {}, [switchValue]);
  const handleSegmentStatusChange = (event, rowData) => {
    // setSwitchValue(event?.target?.checked ? 1 : 0);
    console.log("rowData", rowData);
    api
      .postData("/api/segments/change_segment_status", {
        segment_id: rowData.merchant_segment_id,
        merchant_id: response?.merchant_id,
      })
      .then((response) => {
        if (response && response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          // setSuccess(true);
          getSegment();
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };
  const [message, setMessage] = React.useState(false);

  const fetchProfile = () => {
    api
      .getData("/api/merchant/get_profile?merchant_id=" + response?.merchant_id)
      .then((response) => {
        if (response && response.data[0]?.Status === "200") {
          dispatch(
            setProfileData(
              JSON.parse(response.data[0]?.Merchant_Basic_Details)[0]
            )
          );
          //    setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [tableData, setTableData] = React.useState();
  const fetchTableData = () => {
    api
      .getData(
        "/api/tables_1/Get_Table_Details?merchant_id=" + response?.merchant_id
      )
      .then((response) => {
        console.log('response',response);
        if (response.data.table_det_status[0].Status === "200") {
          setTableData(JSON.parse(response.data.table_det_status[0].TableDetails));
          //    setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLocation();
    fetchProfile();
    fetchTableData();
    fetchServices();
    getSegment();
    fetchData();
    getSubSegment(segment?.length > 0 ? segment[0].merchant_segment_id : 0);
    setSegementId(segment?.length > 0 ? segment[0].merchant_segment_id : 0);
  }, []);
  const handleChildUpdate = (updatedMessage) => {
    fetchProfile();
    setMessage(updatedMessage);
  };

  const handleChildLocation = (updatedMessage) => {
    getLocation();
    setMessage(updatedMessage);
  };

  useEffect(() => {
    fetchProfile();
  }, [message]);

  const handleSubSegmentStatusChange = (event, rowData) => {
    // setSwitchValue(event?.target?.checked ? 1 : 0);
    api
      .postData("/api/segments/change_sub_segment_status", {
        segment_id: rowData.merchant_segment_id,
        sub_segment_id: rowData.merchant_sub_segment_id,
        merchant_id: rowData.merchant_id,
      })
      .then((response) => {
        if (response && response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          getSubSegment(rowData?.merchant_segment_id);
          // setSuccess(true);
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };
  const handleSwitchChange = (event, rowData) => {
    api
      .postData("/api/locations/change_location_status", {
        location_id: rowData.merchant_location_id,
        merchant_id: rowData.merchant_id,
      })
      .then((response) => {
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          getLocation();
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  const pcData = [["#98759", "Rice", "01", "Rs. 300"]];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.dir(state);
    },
  };

  const memeberColumns = [
    "",
    { name: "ID", options: { filterOptions: { fullWidth: true } } },
    "SEGMENT NAME",
    "ISLE",
    "ROWS",
    "STATUS",
  ];

  const segmentColumns = [
    {
      name: "merchant_segment_image",
      label: " ",
      //    label:'',
      options: {
        customBodyRender: (value) => (
          <img src={value} alt="Segment" style={{ width: 40, height: 40 }} />
        ),
      },
    },
    { name: "merchant_segment_id", label: "ID" },
    { name: "merchant_segment_name", label: "SEGMENT NAME" },

    {
      name: "merchant_segment_status",
      label: "STATUS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = segment[rowIndex];
          return (
            <IOSSwitch
              checked={value === 0}
              onChange={(e) => {
                handleSegmentStatusChange(e, rowData);
              }}
            />
          );
        },
      },
    },
    {
      name: "actions",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = segment[rowIndex];
          return (
            <Button
              variant="outlined"
              color="error"
              mt={2}
              className="retail-btn"
              onClick={() => {
                ref1.current.log();
                editOption(rowData);
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
  ];
  //   const locationColumns = [
  //     "LOCATION NAME",
  //      "LOCATION ADDRESS",
  //     "CONTACT",
  //     "PHONE",
  //     "OPENING TIME",
  //     "CLOSING TIME",
  //     "OPENING TIME 2",
  // ];
  const [locationEditData, setLocationEditData] = React.useState();
  const locationColumns = [
    {
      name: "location_name",
      label: "LOCATION NAME",
      //    label:'',
    },
    { name: "business_address", label: "LOCATION ADDRESS" },
    { name: "manager_name", label: "CONTACT" },
    { name: "manager_number", label: "PHONE" },
    {
      name: "opening_time1",
      label: "OPENING TIME",
      options: {
        customBodyRender: (value) => {
          const time24 = value;
          const dateObj = new Date(`2000-01-01T${time24}`);
          const time12 = dateObj.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return time12;
        },
      },
    },
    {
      name: "closing_time1",
      label: "CLOSING TIME",
      options: {
        customBodyRender: (value) => {
          const time24 = value;
          const dateObj = new Date(`2000-01-01T${time24}`);
          const time12 = dateObj.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return time12;
        },
      },
    },
    {
      name: "opening_time2",
      label: "OPENING TIME2",
      options: {
        customBodyRender: (value) => {
          const time24 = value;
          const dateObj = new Date(`2000-01-01T${time24}`);
          const time12 = dateObj.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return time12;
        },
      },
    },
    {
      name: "closing_time2",
      label: "CLOSING TIME2",
      options: {
        customBodyRender: (value) => {
          const time24 = value;
          const dateObj = new Date(`2000-01-01T${time24}`);
          const time12 = dateObj.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });
          return time12;
        },
      },
    },
    {
      name: "location_status",
      label: "STATUS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = location[rowIndex];
          return (
            <IOSSwitch
              checked={value === 0}
              onChange={(e) => {
                handleSwitchChange(e, rowData);
              }}
            />
          );
        },
      },
    },
    {
      name: "",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = location[rowIndex];
          return (
            <Button
              variant="outlined"
              color="error"
              mt={2}
              className="retail-btn"
              onClick={() => {
                setEdit(true);
                ref4.current.log();
                setLocationEditData(rowData);
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {}, [locationEditData]);
  const subSegmentColumns = [
    {
      name: "merchant_sub_segment_id",
      label: "ID",
      //    label:'',
    },
    { name: "merchant_segment_name", label: "SEGMENT NAME" },
    { name: "merchant_sub_segment_name", label: "SUB SEGMENT NAME" },

    {
      name: "merchant_sub_segment_status",
      label: "STATUS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = subSegment[rowIndex];
          return (
            <IOSSwitch
              checked={value === 0}
              onChange={(e) => {
                handleSubSegmentStatusChange(e, rowData);
              }}
            />
          );
        },
      },
    },
    {
      name: "actions",
      label: " ",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = subSegment[rowIndex];
          return (
            <Button
              variant="outlined"
              color="error"
              mt={2}
              className="retail-btn"
              onClick={() => {
                ref11.current.log();
                editSSOption(rowData);
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
  ];
  // const segmentColumns = [
  //     "",
  //     { name: "ID", options: { filterOptions: { fullWidth: true } } },
  //     "SEGMENT NAME",
  //     "ISLE",
  //     "ROWS",
  //     "Status",
  //     {

  //         options: {
  //             customBodyRender: (value, tableMeta, updateValue) => {
  //                 return (
  //                     <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => {ref3.current.log();editOption()}}>Edit</Button>
  //                 )
  //             }
  //         }
  //     }
  // ];

  const [subSegment, setSubSegment] = React.useState();
  const [segmentList,setSegmentList] = React.useState();

  const getSegment = () => {
    api
      .getData(
        "/api/segments/get_merchant_segment_details?merchant_id=" +
          response?.merchant_id
      )
      .then((response) => {
        if (response && response.data && response.data[0]?.Status === "200") {
          dispatch(
            setSegments(JSON.parse(response.data[0].Merchant_Segement_Details))
          );
          setSegmentList(JSON.parse(response.data[0].Merchant_Segement_Details));
        }
      });
  };

  const [locationList, setLocationList] = React.useState();
  const getLocation = () => {
    api
      .getData(
        "/api/segments/get_merchant_location_details?merchant_id=" +
          response?.merchant_id
      )
      .then((response) => {
        if (response && response.data && response.data[0]?.Status === "200") {
          // setLocation(JSON.parse(response.data[0].Merchant_Location_Details));

          dispatch(
            setLocations(JSON.parse(response.data[0].Merchant_Location_Details))
          );
          setLocationList(
            JSON.parse(response.data[0].Merchant_Location_Details)
          );
        }
      });
  };

  const getSubSegment = (segmentId) => {
    api
      .getData(
        "/api/segments/get_merchant_sub_segment_details?merchant_id=" +
          response?.merchant_id +
          "&merchant_segment_id=" +
          segmentId
      )
      .then((response) => {
        if (response && response.data && response.data[0]?.Status === "200") {
          setSubSegment(
            JSON.parse(response.data[0].Merchant_Sub_Segement_Details)
          );

          setIsLoading(false);
        }
      });
  };
  // const [services, setService] = React.useState();
  const fetchServices = () => {
    console.log("getData");
    api
      .getData("/api/config/services_list")
      .then((response) => {
        // setResponseData(response.data)
        console.log("response", JSON.parse(response.data[0].Services));
        if (JSON.parse(response.data[0].Status) === 200) {
          dispatch(setServices(JSON.parse(response.data[0].Services)));
          //   setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const fetchData = () => {
    api
      .getData("/api/config/cities_list?countryid=" + response?.country_id)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          dispatch(
            setCity(
              JSON.parse(response.data[0].Cities).filter((item) => {
                return item.city_state === "Andhra Pradesh";
              })
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  useEffect(() => {}, [segment]);
  useEffect(() => {}, [subSegment]);
  useEffect(() => {}, [location]);

  const [childvalue, setChildValue] = React.useState("");
  const [ssvalue, setSSvalue] = React.useState("");
  const handleChildChange = (childValue) => {
    getSegment();
    setChildValue(childValue);
  };
  const handleSubSegmentChange = (childValue) => {
    console.log("childValue", childValue);
    getSubSegment(
      editsubSegmentData ? editsubSegmentData.merchant_segment_id : segmentId
    );
    setSSvalue(childValue);
  };

  //   useEffect(()=>{
  //     getSegment();
  //   },[childvalue])

  const segmentOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: true,
    responsive: "scrollMaxHeight",
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.dataIndex;
      const clickedRowData = segment[clickedRowIndex];
      setSegementId(clickedRowData.merchant_segment_id);
      getSubSegment(clickedRowData.merchant_segment_id);
      // onRowClick(clickedRowData);
    },
    textLabels: {
      body: {
        noMatch: "No records found",
      },
    },
  };

  const options = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: true,
    // expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Taxes:</b> ₹ 485.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Advance:</b> ₹ 321.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Due Amount:</b> ₹ 542.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>State:</b> State
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  const locationOptions = {
    search: true,
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    onFilterChange: false,
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    rowsPerPage: 10,
    pagination: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <React.Fragment>
          <tr className="inner-row">
            <td colSpan={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {/* {rows.map(row => ( */}
                    {/* <TableRow key={row.id}> */}
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Taxes:</b> ₹ 485.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Advance:</b> ₹ 321.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>Due Amount:</b> ₹ 542.00
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell scope="row">
                        <Typography component="p">
                          <b>State:</b> State
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </React.Fragment>
      );
    },
    page: 1,
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card store-item-card profile-card">
              <Grid container className="full_content bazaar-breadcrumb">
                <Grid className="inner-bredcum" xs="12">
                  <Bredcrum />
                </Grid>
              </Grid>
              <CardContent className="add_new_item profile-container">
                <Card>
                  <CardContent>
                    <Box className="w-100 flex-between">
                      <Typography component="h4" className="sub_title" mb={2}>
                        PROFILE
                      </Typography>
                      <Button
                        variant="outlined"
                        color="error"
                        mt={2}
                        className="retail-btn float-right"
                        onClick={() => ref5.current.log()}
                      >
                        Edit
                      </Button>
                    </Box>

                    <Grid container spacing={4} mb={3}>
                      <Grid item xs={2} sm={4} md={3}>
                        <Box>
                          <Box
                            component="img"
                            src={
                              profileData?.business_icon
                                ? profileData?.business_icon
                                : walmart
                            }
                            className="profile-image"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={4} md={3}>
                        <Box className="sub-details">
                          <Box mb={2}>
                            <Typography component="label">
                            Business Name
                            </Typography>
                            <Typography component="p">
                              {profileData?.merchant_business_name}
                            </Typography>
                          </Box>
                          <Box mb={2}>
                            <Typography component="label">
                              Phone Number
                            </Typography>
                            <Typography component="p">
                              {profileData?.phone_number}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography component="label">
                              Primary Email
                            </Typography>
                            <Typography component="p">
                              {profileData?.admin_email}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={4} md={3}>
                        <Box className="sub-details">
                          <Box mb={2}>
                            <Typography component="label">City</Typography>
                            <Typography component="p">
                              {profileData?.city_name}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography component="label">
                              GST Number
                            </Typography>
                            <Typography component="p">
                              {profileData?.gst_number}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={4} md={3}>
                        <Box className="sub-details">
                          <Box mb={2}>
                            <Typography component="label">
                            Business Category
                            </Typography>
                            <Typography component="p">
                              {profileData?.service_name}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography component="label">
                              GST Tax rate
                            </Typography>
                            <Typography component="p">
                              {profileData?.gst_percentage}%
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Grid container spacing={3} mb={3} mt={1}>
                      <Grid item xs={2} sm={6} md={4}>
                        <Box className="sub-details">
                          <Box mb={2}>
                            <Typography component="label">Bank Name</Typography>
                            <Typography component="p">
                              {profileData?.bank_name}
                            </Typography>
                          </Box>
                          <Box mb={2}>
                            <Typography component="label">
                              Account Holder Name
                            </Typography>
                            <Typography component="p">
                              {profileData?.account_holder_name}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography component="label">
                              Phone Pay No
                            </Typography>
                            <Typography component="p">
                              {profileData?.phone_pay_number}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={6} md={4}>
                        <Box className="sub-details">
                          <Box mb={2}>
                            <Typography component="label">
                              Branch Name / IFSC Code
                            </Typography>
                            <Typography component="p">
                              {profileData?.bank_branch_name}/
                              {profileData?.ifsc_code}
                            </Typography>
                          </Box>

                          <Box mb={2}>
                            <Typography component="label">Paytm No</Typography>
                            <Typography component="p">
                              {profileData?.paytm_number}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography component="label">UPI Id's</Typography>
                            <Typography component="p">
                              {profileData?.other_upi_number}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2} sm={6} md={4}>
                        <Box className="sub-details">
                          <Box mb={2}>
                            <Typography component="label">
                              Account Number
                            </Typography>
                            <Typography component="p">
                              {profileData?.account_number}
                            </Typography>
                          </Box>

                          <Box>
                            <Typography component="label">
                              Google Pay No
                            </Typography>
                            <Typography component="p">
                              {profileData?.google_pay_number}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Grid container>
                      <Box className="flex-between w-100" mt={2}>
                        <Typography className="font-bold" component="h4">
                          SEGMENTS
                        </Typography>
                        <Button
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn"
                          mr={2}
                          onClick={() => {
                            ref1.current.log();
                            addOption();
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                      <Box className="member-table w-100 segmentTable" mt={2}>
                        <MemberTable
                          columns={segmentColumns}
                          options={segmentOptions}
                          data={segment ? segment : []}
                        />
                      </Box>
                    </Grid>
                    <Grid container>
                      <Box className="flex-between w-100" mt={2}>
                        <Typography className="font-bold" component="h4">
                          SUB SEGMENTS
                        </Typography>
                        <Button
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn"
                          mr={2}
                          onClick={() => {
                            ref3.current.log();
                            addssOption();
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                      <Box className="member-table w-100">
                        <MemberTable
                          columns={subSegmentColumns}
                          options={options}
                          data={subSegment ? subSegment : []}
                        />
                      </Box>
                    </Grid>
                    <Grid container>
                      <Box className="flex-between w-100" mt={2}>
                        <Typography className="font-bold" component="h4">
                          TABLE CONFIGURATION
                        </Typography>
                        <Button
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn"
                          mr={2}
                          onClick={() => ref2.current.log()}
                        >
                          Add
                        </Button>
                      </Box>
                      <Box className="member-table w-100">
                        {
                            console.log("tableData",tableData)
                        }
                        <MemberTable
                          columns={ingredientsColumns}
                          options={options}
                          data={tableData || []}
                        />
                      </Box>
                    </Grid>
                    <Grid container>
                      <Box className="flex-between w-100" mt={2}>
                        <Typography className="font-bold" component="h4">
                          LOCATION DETAILS
                        </Typography>{
                        console.log('locationList',locationList?.length)
}
                        <Button
                          variant="outlined"
                          mt={2}
                          mb={2}
                          className="retail-btn"
                          mr={2}
                          onClick={() => {
                            setEdit(false);
                            setLocationEditData("");
                            if (locationList && locationList?.length < 1 || locationList === undefined || locationList === null) {
                              ref4.current.log();
                            } else {
                              showSwalWithLinkError(
                                "Only One Location Allowed"
                              );
                            }
                          }}
                        >
                          Add Location
                        </Button>
                      </Box>
                      <Box className="member-table w-100">
                        <MemberTable
                          columns={locationColumns}
                          options={locationOptions}
                          data={location ? location : []}
                        />
                      </Box>
                    </Grid>
                  </CardContent>
                </Card>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <AddLocationModal
          ref={ref4}
          edit={edit}
          editData={locationEditData}
          onUpdate={handleChildLocation}
        />
        <EditSubSegmentModal
         ref={ref11}
         props={response}
         edit={edit}
         editData={editsubSegmentData}
         onHandle={handleSubSegmentChange}
        />
        <AddSubSegmentModal
          ref={ref3}
          props={response}
          edit={edit}
          editData={editsubSegmentData}
          onHandle={handleSubSegmentChange}
        />
        <AddSegmentModal
          ref={ref1}
          props={response}
          edit={edit}
          editData={editData}
          onHandle={handleChildChange}
          getSubSegment={getSubSegment}
        />
        <EditProfileModal
          ref={ref5}
          props={response}
          edit={edit}
          onUpdate={handleChildUpdate}
        />
        <AddIngredientsModals ref={ref2} fetchTableData={fetchTableData}/>
        <EditIngredientsModal ref={ref6} editTableData={editTableData} fetchTableData={fetchTableData} />
        {/* <ProfileComplete ref={ref5}/> */}
        <AddressGeocoder ref={ref10} />
      </Box>
    </>
  );
}
