import React, { useEffect } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import Header from "../Header/Header";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import location from "../images/location.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Bredcrum from "../Bredcrum";
import Calender from "../Calender/Calender";
import MemberTable from "../Customer/MemberTable";
import AddEmployeeModal from "./AddEmployeeModal";
import greendot from '../images/greendot.png';
import reddot from '../images/reddot.png';
import ManagementViewMoreModal from "./ManagementViewMoreModal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import api from "../Api";
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';

// import { useDemoData } from "@mui/x-data-grid-generator";






function createData(
  name,
  calories,
  fat,
  carbs,
  protein
) {
  return { name, calories, fat, carbs, protein };
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 15,
    height: 13,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function Management() {
  const [value, setValue] = React.useState(0);
  const response = useSelector(state => state.user.user);
  const MySwal = withReactContent(Swal);
  let locations = useSelector(state => state.user.location);
  let locationId = useSelector(state => state.user.pos_location);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: 'success',
      title: message,
    })
  };
  const transactionstatementcolumns = [
    { name: "Start Date", options: { filterOptions: { fullWidth: true } } },
    "End Date",
    "Total Amount",
    "Payout Date",
    "Payout Mode",
    "Payment CNF ID",
  ];
  const transactionstatementdata = [
    ["12-08-2023", "15-08-2023", "₹2099", "15-08-2023", "UPI", "#239889498"],
  ];
  const [pastEmpData, setPastEmpData] = React.useState();
  const [pastEmpFilterData, setPastEmpFilterData] = React.useState();
  const [currentEmpDetails, setEmpCurrentDetails] = React.useState();
  const [currentEmpFilterDetails, setEmpFilterCurrentDetails] = React.useState();
  const getCurrentEmpDetails = () => {
    api.getData('/api/employee_management/get_employee_current_details?merchant_id=' + response?.merchant_id)
      .then((response) => {
        console.log('response', response);
        if (response.data.employee_current_status[0].Status === "200") {
          setEmpCurrentDetails(JSON.parse(response.data.employee_current_status[0]?.EmployeeCurrentDetails));
          setEmpFilterCurrentDetails(JSON.parse(response.data.employee_current_status[0]?.EmployeeCurrentDetails));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
  const [bankDetails, setBankDetails] = React.useState();
  const getEmployeeBankDetails = (employeeId) => {
    api.getData('/api/employee_management/get_employee_bank_details?employee_id=' + employeeId)
      .then((response) => {
        console.log('response', response);
        if (response.data.employee_bank_status[0].Status === "200") {
          setBankDetails(JSON.parse(response.data.employee_bank_status[0]?.EmployeeRoleDetails)[0]);
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }


  const [jobRoles, setJobRoles] = React.useState();
  const getEmployeeJobRoles = () => {
    api.getData('/api/employee_management/get_employee_job_role')
      .then((response) => {
        if (response.data.employee_job_names[0].Status === "200") {
          setJobRoles(JSON.parse(response.data.employee_job_names[0]?.EmployeeJobRoleDetails));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  const [employeeNames, setEmployeeNames] = React.useState();
  const getEmployeeNames = () => {
    console.log('response', response);
    api.getData('/api/employee_management/get_employee_names?merchant_id=' + response?.merchant_id)
      .then((response) => {
        console.log('response', response);
        if (response.data.employee_names_status[0].Status === "200") {
          setEmployeeNames(JSON.parse(response.data.employee_names_status[0]?.EmployeeNames));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }


  const [shiftDetails, setShiftDetails] = React.useState();
  const getShiftDetails = (employeeId,shiftId) => {
    api.getData('/api/employee_management/get_employee_shift_details?employee_id='+employeeId+'&shift_id='+shiftId)
      .then((response) => {
        console.log('response', response);
        if (response.data.employee_shift_status[0].Status === "200") {
          setShiftDetails(JSON.parse(response.data.employee_shift_status[0]?.EmployeeJobRoleDetails)[0]);
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }



  const [employeeDetails, setEmployeeDetails] = React.useState();
  const getEmployeeDetails = (employeeId) => {
    api.getData('/api/employee_management/get_employee_details?merchant_id=' + response?.merchant_id + '&employee_id='+employeeId+'&location_id='+locationId)
      .then((response) => {
        console.log('response', response);
        if (response.data.employee_det_status[0].Status === "200") {
          console.log('JSON.parse(response.data.employee_det_status[0]?.EmployeeDetails)',response);
          setEmployeeDetails(JSON.parse(response.data.employee_det_status[0]?.EmployeeDetails)[0]);
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  useEffect(() => {
    if(currentEmpDetails){
    getEmployeeDetails(currentEmpDetails[0]?.employee_id);
    getEmployeeRoleDetails(currentEmpDetails[0]?.employee_id);
   getEmployeeBankDetails(currentEmpDetails[0]?.employee_id);
    }
  }, [currentEmpDetails]);
  const handleDateFilter = (newValue) => {
    const startDate = newValue[0];
    const endDate = newValue[1];

    const formattedStartDate = startDate?.toISOString().slice(0, 10); // yyyy-mm-dd
    const formattedEndDate = endDate?.toISOString().slice(0, 10); // yyyy-mm-dd

    if (formattedStartDate && formattedEndDate) {
      const filteredCurrentData = currentEmpFilterDetails.filter((customer) => {
        return (
          customer.created_date >= formattedStartDate &&
          customer.created_date <= formattedEndDate
        );
      });
      setEmpCurrentDetails(filteredCurrentData);

      const filteredPastData = pastEmpFilterData?.filter((customer) => {
        return (
          customer.created_date >= formattedStartDate &&
          customer.created_date <= formattedEndDate
        );
      });
      setPastEmpData(filteredPastData);
    }
    // You can use these formatted dates as needed.
  };
  const currentEmpManagement = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.rowIndex;
      const clickedRowData = currentEmpDetails[clickedRowIndex];
     getEmployeeDetails(clickedRowData.employee_id);
     getEmployeeRoleDetails(clickedRowData.employee_id);
     getComplianceDetails(clickedRowData.employee_id);
     getEmployeeBankDetails(clickedRowData.employee_id);
     getEmployeeLoginDetails(clickedRowData.employee_id);
     getEmployeeFilesInformation(clickedRowData.employee_id);
     getEmployeeEquipmentDetails(clickedRowData.employee_id);
  },
    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  

  const [employeeRoleDetails, setEmployeeRoleDetails] = React.useState();
  const getEmployeeRoleDetails = (employeeId) => {
    api.getData('/api/employee_management/get_employee_role_details?employee_id='+employeeId)
      .then((response) => {
        console.log('get_employee_role_details', response);
        if (response.data.employee_rol_status[0].Status === "200") {
          setEmployeeRoleDetails(JSON.parse(response.data.employee_rol_status[0]?.EmployeeRoleDetails)[0]);
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  useEffect(() => {
    getEmployeeJobRoles();
    getEmployeeNames();
  }, [])
 
  const [empLoginDetails,setEmpLoginDetails] = React.useState();
  const getEmployeeLoginDetails = (employeeId) => {
    api.getData('/api/employee_management/get_employee_login_details?employee_id=' + employeeId)
      .then((response) => {
        console.log('response', response);
        if (response.data.employee_login_status[0].Status === "200") {
          setEmpLoginDetails(JSON.parse(response.data.employee_login_status[0]?.EmployeeRoleDetails)[0]);
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }





  const changeCurrentEmpStatus = (employeeId) => {
    api.postData('/api/employee_management/update_employee_status', {
      'merchant_id': response?.merchant_id,
      'employee_id': employeeId,

    }
    ).then((response) => {
      console.log('JSON.parse(response.data.employee_status)',response);
      if (response.data.employee_status[0].Status === "200") {
        showSwalWithLink(response.data.employee_status[0].Message);
        
        getCurrentEmpDetails();
      }

    }).catch(error => {
      // Handle error
      console.error(error);
    });
  }
  
 const filterLocations = (value) =>{
  if (value) {
    const filteredCurrentData = currentEmpFilterDetails.filter((customer) => {
      return (
        customer.location_id === value
      );
    });
    setEmpCurrentDetails(filteredCurrentData);

    const filteredPastData = pastEmpFilterData?.filter((customer) => {
      return (
        customer.location_id === value
      );
    });
    setPastEmpData(filteredPastData);
  }
 }

 

  const updateEmpComplainceStatus = (rowData) => {
    api.postData('/api/employee_management/update_employee_compliance_status', {
      'merchant_id': response?.merchant_id,
      'employee_id': rowData.employee_id,

    }
    ).then((response) => {
      console.log('JSON.parse(response.data.employee_status)',response);
      if (response.data.employee_current_status[0].Status === "200") {
        showSwalWithLink(response.data.employee_current_status[0].Message);
        
        getCurrentEmpDetails();
        // setSuccess(true);
      }
    }).catch(error => {
      // Handle error
      console.error(error);
    });
  }

  const currentEmpColumns = [
    {
      name: 'employee_name',
      label: 'Name',

    },
    // { name: 'role_name', label: 'Designation' },
    { name: 'location_name', label: 'Location' },
    { name: 'location_name', label: 'No. of Days' },
    { name: 'location_name', label: 'Earnings' },
    { name: 'compliance_status', label: 'Compliance' ,
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const rowIndex = tableMeta.rowIndex;
        const rowData = currentEmpDetails[rowIndex];
        return (<Box onClick={() => { updateEmpComplainceStatus(rowData) }}><img src={value === 0?greendot:reddot} style={{width:'10px'}}  /> {value === 0?"Yes":"No"} </Box>);
      },
    }
  },
    {
      name: 'employee_status',
      label: 'Status',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = currentEmpDetails[rowIndex];
          return (<IOSSwitch checked={value === 0} onChange={() => { changeCurrentEmpStatus(rowData.employee_id) }} />);
        },
      },
    }
  ];

  useEffect(() => {
    getCurrentEmpDetails();
  }, []);

  const changePastEmpStatus = (employeeId) => {
    api.postData('/api/employee_management/update_employee_status', {
      'merchant_id': response?.merchant_id,
      'employee_id': employeeId,

    }
    ).then((response) => {
      console.log('response',response);
      if (response.data.employee_status[0].Status === "200") {
        showSwalWithLink(response.data.employee_status[0].Message);
        getPastEmpDetails();
        // setSuccess(true);
      }

    }).catch(error => {
      // Handle error
      console.error(error);
    });
  }

  const managementOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.rowIndex;
      const clickedRowData = pastEmpData[clickedRowIndex];
     getEmployeeDetails(clickedRowData?.employee_id);
     getEmployeeRoleDetails(clickedRowData?.employee_id);
     getShiftDetails(clickedRowData?.employee_id,clickedRowData?.shift_id );
    },
    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  
  const pastEmpColumns = [
    {
      name: 'employee_name',
      label: 'Name',

    },
    { name: 'location_name', label: 'Location' },
    { name: 'role_name', label: 'Designation' },
    { name: 'created_date', label: 'Date Of Joining' },
    { name: 'resigned_date', label: 'Last Working Day' },
    { name: 'resigned_remarks', label: 'Reason' },
    { name: 'employee_status', label: 'Status',
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        const rowIndex = tableMeta.rowIndex;
        const rowData = pastEmpData[rowIndex];
        return (<Box onClick={() => { updateEmpComplainceStatus(rowData) }}><img src={value === 0?greendot:reddot} style={{width:'10px'}}  /> {value === 0?"Yes":"No"} </Box>);
      },
    } 
  
  },
    {
      name: 'product_status',
      label: 'Status',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = pastEmpData[rowIndex];
          return (<IOSSwitch checked={value === 0} onChange={() => { changePastEmpStatus(rowData.employee_id) }} />);
        },
      },
    }
  ];

  
  const getPastEmpDetails = () => {
    api.getData('/api/employee_management/get_employee_past_details?merchant_id=' + response?.merchant_id)
      .then((response) => {
        console.log('past response', JSON.parse(response.data.employee_current_status[0]?.EmployeePastDetails));
        if (response.data.employee_current_status[0].Status === "200") {
          setPastEmpData(JSON.parse(response.data.employee_current_status[0]?.EmployeePastDetails));
          setPastEmpFilterData(JSON.parse(response.data.employee_current_status[0]?.EmployeePastDetails));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
  const [complianceDetails,setComplianceDetails] = React.useState();
  const [employeeComplianceFileDetails,setEmployeeComplianceFileDetails] = React.useState();
  const getComplianceDetails = (employeeId) => {
    api.getData('/api/employee_management/get_employee_compliance_details?employee_id=' + employeeId)
      .then((response) => {
        if (response.data.employee_compliance_status[0].Status === "200") {
          // setComplianceDetails(JSON.parse(response.data.employee_compliance_status[0]?.EmployeeComplianceDetails)[0]);
          setComplianceDetails(JSON.parse(response.data.employee_compliance_status[0]?.EmployeeComplianceDetails));
          setEmployeeComplianceFileDetails(JSON.parse(response.data.employee_compliance_status[0]?.EmployeeFileDetails));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [employeeFile,setEmployeeFileDetails] = React.useState();
  const [employeeFileInformation,setEmployeeFileDetailsInformation] = React.useState();
  const getEmployeeFilesInformation = (employeeId) => {
    api.getData('/api/employee_management/get_employee_files_information?employee_id=' + employeeId)
      .then((response) => {
        console.log('response',response);
        if (response.data.employee_doc_status[0].Status === "200") {
          setEmployeeFileDetails(JSON.parse(response.data.employee_doc_status[0]?.EmployeeFdocumentDetails));
          setEmployeeFileDetailsInformation(JSON.parse(response.data.employee_doc_status[0]?.EmployeeEquipmentFileDetails));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [EmployeeEquipmentDetails,setEmployeeEquipmentDetails] = React.useState();
  const getEmployeeEquipmentDetails = (employeeId) => {
    api.getData('/api/employee_management/get_employee_Equipment_Details?employee_id=' + employeeId)
   
      .then((response) => {
       
        if (response.data.employee_equip_status[0].Status === "200") {
          setEmployeeEquipmentDetails(JSON.parse(response.data.employee_equip_status[0]?.EmployeeEquipmentDetails));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getPastEmpDetails();
  }, []);

  const ref4 = React.useRef();
  const ref1 = React.useRef();
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />

              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                      className="filters"
                    >
                      <Box className="filters-display">
                        {/* <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          defaultValue="0"
                          onChange={(e)=>filterLocations(e.target.value)}
                        >
                          <MenuItem value="0">All Locations</MenuItem>

                          {
                            locations?.map((item) => {
                              return (<MenuItem value={item.merchant_location_id}>{item.location_name}</MenuItem>)
                            })
                          }
                        </Select> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["SingleInputDateRangeField"]}
                          >
                            <DateRangePicker
                              className="calender"
                              variant="outlined"
                              slots={{ field: SingleInputDateRangeField }}
                              inputFormat="yyyy-mm-dd"
                              onChange={(newValue) => {
                                handleDateFilter(newValue);
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </Box>
                      <Box display={"flex"} justifyContent={"space-between"}>
                        <Button
                          variant="outlined"
                          className="btn-outline-primary btn-space"
                        >
                          Import Payout Report
                        </Button>
                        <Button
                          variant="outlined"
                          className="btn-outline-primary btn-space"
                          onClick={() => ref4.current.log()}
                        >
                          Add Employee
                        </Button>
                        {/* <Button
                          variant="outlined"
                          className="btn-outline-primary btn-space"
                        >
                          List
                        </Button> */}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs="12" md="7" mt={3}>
                      <Card md="12">
                        <CardContent>
                          {/* <Grid container>
                            <Grid item md="12">
                              <Typography mb={2} className="t-heading">
                                Drivers
                              </Typography>
                            </Grid>
                          </Grid> */}
                          <Box
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                            className="shift-shedule-tabs"
                            mb={1}
                          >
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              aria-label="basic tabs example"
                            >
                              <Tab label="Current" {...a11yProps(0)} />
                              <Tab label="Past" {...a11yProps(1)} />
                            </Tabs>
                          </Box>
                          <Box sx={{ height: 400, width: "100%" }}>
                            <TabPanel
                              value={value}
                              index={0}
                              className="custom-shift-tab"
                            >
                              <MemberTable
                                columns={currentEmpColumns}
                                options={currentEmpManagement}
                                data={currentEmpDetails ? currentEmpDetails : []}
                              />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                              <MemberTable
                                columns={pastEmpColumns}
                                options={managementOptions}
                                data={pastEmpData?pastEmpData:[]}
                              />
                            </TabPanel>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs="12" md="5" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container mb={2}>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Name
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                 
                                  {employeeDetails?employeeDetails?.first_name:""}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="4"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={mobile}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Mobile
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  {employeeDetails?.phone_number}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item md="4">
                              <Button
                                variant="outlined"
                                className="btn-outline-primary"
                                onClick={() => ref1.current.log()}
                              >
                                View
                              </Button>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Employee ID
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                 {employeeDetails?.employee_id}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <img
                                src={custicon}
                                className="T-icon"
                                alt="img-here"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Email
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  {employeeDetails?.email_id}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container mt={2} mb={2}>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={location}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Address
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                 {employeeDetails?.address}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <img
                                src={custicon}
                                className="T-icon"
                                alt="img-here"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  DOB
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  {employeeDetails?.date_of_birth}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid item md="12">
                              <Typography className="t-heading">
                                Job Details
                              </Typography>
                            </Grid>
                            <Grid container mt={2}>
                              <Grid item md="4" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Job Name
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    
                                    {employeeRoleDetails?.role_name}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md="4" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Job Type
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    {employeeRoleDetails?.job_type}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md="4" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Pay Type
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                   {employeeRoleDetails?.pay_type}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid item md="6" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                   Pay Period Unit
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    {employeeRoleDetails?.basic_pay}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md="6" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Base Pay
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    {employeeRoleDetails?.bonus}
                                  </Typography>
                                </Box>
                              </Grid>
                              {/* <Grid item md="4" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Bonus Comment
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    {employeeRoleDetails?.bonus_comment}
                                  </Typography>
                                </Box>
                              </Grid> */}
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid item md="12">
                              <Typography className="t-heading">
                                Transactions
                              </Typography>
                            </Grid>
                            <Grid container mt={2}>
                              <Grid item md="4" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Join Date
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    10 Feb 2021
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md="4" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Last Active
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    10 Aug 2023
                                  </Typography>
                                </Box>
                              </Grid>

                              <Grid item md="4" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Total Earnings
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    ₹ 2088
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid item md="12">
                              <Typography className="t-heading">
                                Accounting
                              </Typography>
                            </Grid>
                            <Grid container mt={2}>
                              <Grid item md="6" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Bank Name
                                  </Typography>
                                  {
                                    console.log('bankDetails',bankDetails)
                                  }
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    {bankDetails?bankDetails?.bank_name:""}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md="6" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Name on Account
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    {bankDetails?bankDetails?.account_holder_name:""}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md="6" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Join date
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    {bankDetails?bankDetails?.join_date:"1900-01-01"}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md="6" className="table-border">
                                <Box md="4" textAlign={"center"} padding={1}>
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Account Number
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    {bankDetails?bankDetails?.account_number:""}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2}>
                            <Grid item md="12">
                              <Typography className="t-heading">
                                Payroll Statements
                              </Typography>
                            </Grid>
                            <Grid item md="12">
                              <MemberTable
                                columns={transactionstatementcolumns}
                                options={managementOptions}
                                data={transactionstatementdata?transactionstatementdata:[]}
                              />
                            </Grid>
                          </Grid>
                          {/* <Grid container mt={2} mb={2}>
                            <Grid item md="12">
                              <Typography className="t-heading">
                                Transactions Statements
                              </Typography>
                            </Grid>
                            <Grid item md="12" mt={2}>
                              <Box sx={{ height: 400, width: "100%" }}>
                                <MemberTable
                                  columns={tstatementsColumns}
                                  options={tstatementsOptions}
                                  data={tstatementsdata}
                                />
                              </Box>
                            </Grid>
                          </Grid> */}
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddEmployeeModal ref={ref4} jobRoles={jobRoles} getCurrentEmpDetails={getCurrentEmpDetails} />
        <ManagementViewMoreModal jobRoles={jobRoles} ref={ref1} EmployeeEquipmentDetails={EmployeeEquipmentDetails} employeeFile={employeeFile} employeeComplianceFileDetails={employeeComplianceFileDetails} complianceDetails={complianceDetails} bankDetails={bankDetails}  shiftDetails={shiftDetails} employeeDetails={employeeDetails} employeeRoleDetails={employeeRoleDetails} empLoginDetails={empLoginDetails} employeeFileInformation={employeeFileInformation}  />
      </Box>
    </>
  );
}
