import React, { useState, useRef, useEffect, useImperativeHandle } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import logout from "../images/logout.svg";
import SearchIcon from "@mui/icons-material/Search";
import SearchBar from "material-ui-search-bar";
import customer from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import mail from "../images/mail.svg";
import withReactContent from "sweetalert2-react-content";
import MemberTable from "../Customer/MemberTable";
import addPerson from "../images/person-plus-fill.svg";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import info from "../images/info-circle.svg";
import clear from "../images/clear.svg";
import discount from "../images/Discount.svg";
import invoice from "../images/Invoice.svg";
import upscan from "../images/upc-scan.svg";
import Items from "./Items";
import walmart from "../images/Walmart.jpg";
import Swal from "sweetalert2";
import customers from "../images/customers.svg";
import CustomerDetailsModal from "./CustomerDetailsModal";
import CurbModal from "./CurbModal";
import StorePickupModal from "./StorePickupModal";
import DiscountModal from "./DiscountModal";
import ScanModal from "./ScanModal";
import InvoiceDetailsModal from "./InvoiceDetailsModal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import print from "../images/print-invoice.svg";
import edit from "../images/edit.svg";
import EditProductModal from "./EditProductModal";
import cross1 from "../images/cross.svg";
import CustomerInvoiceDetailsModal from "./CustomerInvoiceDetailsModal";
import EditTable from "./EditTable";
import {
  getPendingInvoices,
  posPendingCartTaxAndFee,
  setDeliveryId,
} from "../Redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import api from "../Api";
import DeliveryModal from "./DeliveryModal";
import { format } from "date-fns";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import dairy from "../images/Dairy.png";
import dayjs from "dayjs";
import {
  setItemStock,
  setPosPhone,
  setPosLocation,
  posOrderData,
  setDeliveryTypeId,
  setPosInvoiceId,
  setCartTotal,
  posPendingCartItems,
  setCustomersId,
  setOrderId,
  setTableNumber,
} from "../Redux/actions/userActions";
import InStoreModal from "./InStoreModal";
import * as moment from "moment";
import { useFormik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const defaultItem = {
  cart_id: "",
  product_complete_sku: "",
  product_name: "",
  product_qty_sold: "",
  sale_price: "",
  total_item_price: "",
};

const defaultItemSet = [
  {
    cart_id: "",
    product_complete_sku: "",
    product_name: "",
    product_qty_sold: "",
    sale_price: "",
    total_item_price: "",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const week = ["Day", "Week", "Month"];
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const SearchInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Pos() {
  const [open, setOpen] = useState(false);
  let pendingInvoices = useSelector((state) => state.user.pending_invoices);
  let pendingCartItem = useSelector((state) => state.user.pending_cart_items);
  let pendingCartItemTotal = useSelector((state) => state.user.cart_total);
  const deliveryId = useSelector((state) => state.user.delivery_Id);
  const [searchItem, setSearchItem] = React.useState();
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const [deliveryTypeId, setDeliveryTypeIds] = React.useState();
  let posDeliverTypeId = useSelector((state) => state.user.delivery_Type_Id);
  const dispatch = useDispatch();
  const [updateQuantity, setUpdateQuantity] = React.useState();
  let pendingInvoiceId = useSelector((state) => state.user.pos_invoice_id);
  const [finalSegment, setFinalSegment] = React.useState();
  const [finalSubSegment, setSubFinalSegment] = React.useState();
  const response = useSelector((state) => state.user.user);
  let phonePnding = useSelector((state) => state.user.pending_phone);
  let posTableNumber = useSelector((state) => state.user.tableNumber);
  let posLocation = useSelector((state) => state.user.pos_location);
  let orderId = useSelector((state) => state.user.order_id);
  let customerIds = useSelector((state) => state.user.customer_id);
  const [customerId, setCustomerId] = React.useState();
  const [locationId, setLocationId] = React.useState();
  const [searchProductDetails, setSearchProductDetails] = React.useState();
  const handleOpen = () => setOpen(true);
  const [merchantDetails, setMerchantDetails] = React.useState();
  const [customerData, setCustomerData] = React.useState();
  const [doorIndex, setDoorIndex] = React.useState(2);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [location, setLocation] = React.useState();
  const [fullscreen, setFullscreen] = React.useState(true);
  const [newCustomer, setNewCustomer] = React.useState(false);
  let pendingCartTaxTotals = useSelector((state) => state.user);
  const navigate = useNavigate();
  const handleClose = () => setOpen(false);
  const timeFormate = (dateTimes) => {
    const dateTimeString = dateTimes;
    const dateTime = new Date(dateTimeString);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return dateTime.toLocaleString("en-US", options);
  };
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const [cities, setCities] = useState();
  const [segmentDetails, setSegmentDetails] = useState();
  const [subSegments, setSubSegmentDetails] = useState();
  const [itemDetails, setItemDetails] = useState();
  // const [segmentId, setSegementId] = useState();
  const [deliveryTypes, setDeliveryTypes] = React.useState();
  const [searchValue, setSearchValue] = useState("");

  // const handleSearchValue = (event) => {

  //   setSearchValue(event.target.value);
  // };
  const fetchCities = () => {
    api
      .getData(
        "/api/locations/get_Location_name_pos?merchant_id=" +
          response?.merchant_id +
          "&city_id=" +
          response?.city_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setLocation(JSON.parse(response.data[0].LocationDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [cartUom, setCartUom] = React.useState();
  const getCartUom = (city_id = 1) => {
    api
      .getData(
        "/api/pos/get_POS_cart_PRODUCT_UOMs?product_id=" + response?.product_id
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCartUom(JSON.parse(response.data[0].ProductDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [tableNames, setTableNames] = React.useState();
  const getTableNames = () => {
    api
      .getData(
        "/api/kot/Get_table_names?logged_in_person=" +
          response?.logged_in_person +
          "&Loggedin_Id=" +
          response?.Loggedin_Id
      )
      .then((response) => {
        // console.log("response", response);
        if (response.data.table_det_status[0]?.Status === "200") {
          setTableNames(
            JSON.parse(response.data.table_det_status[0].TableNmaes)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const orderItemData1 = [
    [
      <Typography component="h1" className="kot-order">
        KOT Id : A3567787{" "}
      </Typography>,
      "",
      "",
      "",
      "",
      <Typography component="h1" className="kot-order">
        Order Time : 07:30 PM{" "}
      </Typography>,
    ],
    [
      7,
      "Sandwich",
      // <GroupedButtons />,
      "3",
      "₹100",
      "300",
      <img className="cross" src={cross1} />,
    ],
    [
      8,
      "Dosa",
      // <GroupedButtons />,
      "3",
      "₹20",
      "₹80",
      <img className="cross" src={cross1} />,
    ],
    [
      8,
      "Burger",
      // <GroupedButtons />,
      "1",
      "₹220",
      "₹220",
      <img className="cross" src={cross1} />,
    ],
  ];
  const orderItemData = [
    [
      <Typography component="h1" className="kot-order">
        KOT Id : A356725{" "}
      </Typography>,
      "",
      "",
      "",
      "",

      <Typography component="h1" className="kot-order">
        Order Time : 07:00 PM{" "}
      </Typography>,
    ],
    [
      5,
      "Biryani",
      // <GroupedButtons />,
      "2",
      "₹200",
      "₹200",
      <img className="cross" src={cross1} />,
    ],
    [
      6,
      "Samosa",
      // <GroupedButtons />,
      "1",
      "₹100",
      "₹400",
      <img className="cross" src={cross1} />,
    ],
    [
      <Typography component="h1" className="kot-order">
        KOT Id : A3567787{" "}
      </Typography>,
      "",
      "",
      "",
      "",

      <Typography component="h1" className="kot-order">
        Order Time : 07:30 PM{" "}
      </Typography>,
    ],
    [
      7,
      "Sandwich",
      // <GroupedButtons />,
      "3",
      "₹100",
      "300",
      <img className="cross" src={cross1} />,
    ],
    [
      8,
      "Dosa",
      // <GroupedButtons />,
      "3",
      "₹20",
      "₹80",
      <img className="cross" src={cross1} />,
    ],
    [
      8,
      "Burger",
      // <GroupedButtons />,
      "1",
      "₹220",
      "₹220",
      <img className="cross" src={cross1} />,
    ],
  ];
  useEffect(() => {}, [merchantDetails]);
  const [searchMerchantItems, setSearchMerchantItems] = React.useState();

  const getSearchDetails = (searchValue) => {
    if (searchValue.length == 0) {
      getSegmentDetails();
    }
    setSearchItem(searchValue);
    api
      .getData(
        "/api/pos/get_POS_SearchMerchantItems?merchant_id=" +
          response?.merchant_id +
          "&module=pos&search_param=" +
          searchValue
        //  +
        // "&city_id=" +
        // response?.city_id +
        // "&location_id=" +
        // posLocation
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          // setSearchProductDetails(JSON.parse(response.data[0].ProductDetails));
          if (JSON.parse(response.data[0].SearchProducts)) {
            //     const filteredSegmentDetails = segmentDetails.filter((item) =>
            //       item.merchant_segment_id === (JSON.parse(response.data[0].ProductDetails)[0].product_segment_id)
            //     );
            let parsedProductDetails = JSON.parse(
              response.data[0].SearchProducts
            );
            const productSegmentIds = Array.from(
              new Set(
                parsedProductDetails.map((item) => item.product_segment_id)
              )
            );
            const filteredSegmentDetails = segmentDetails.filter((item) =>
              productSegmentIds.includes(item.merchant_segment_id)
            );
            setFinalSegment(filteredSegmentDetails);
            const productSubSegmentIds = Array.from(
              new Set(
                parsedProductDetails.map((item) => item.product_sub_segment_id)
              )
            );
            const filteredSubSegmentDetails = subSegments.filter((item) =>
              productSubSegmentIds.includes(item.merchant_sub_segment_id)
            );
            setSubFinalSegment(filteredSubSegmentDetails);
            // setSubSegmentDetails(JSON.parse(response.data[0].ProductDetails) === null ? [] : filteredSubSegmentDetails);
          } // const filtereditemDetails = itemDetails.filter((item) =>
          //   item.product_id === (JSON.parse(response.data[0].ProductDetails)[0].product_id)
          // );
          setItemDetails(
            JSON.parse(response.data[0].SearchProducts) === null
              ? []
              : JSON.parse(response.data[0].SearchProducts)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (location) {
      dispatch(setPosLocation(location[0]?.merchant_location_id));
      setLocationId(location[0]?.merchant_location_id);
    }
  }, [location]);

  useEffect(() => {
    dispatch(setPosLocation(locationId));
  }, [locationId]);

  useEffect(() => {
    if (itemDetails && itemDetails.length > 0) {
      getItemDetailsStock(
        response?.merchant_id,
        itemDetails && itemDetails[0].product_id
      );
    }
  }, [itemDetails]);

  const getItemDetailsStock = (merchantId, ProductId) => {
    api
      .getData(
        "/api/pos/get_item_details_stock?merchant_id=" +
          merchantId +
          "&product_id=" +
          ProductId
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          dispatch(setItemStock(JSON.parse(response.data[0]?.ItemDetails)[0]));
          setItemStock(JSON.parse(response.data[0]?.ItemDetails)[0]);

          //   setSpinner(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCartDetails = (searchValue) => {
    if (searchValue.length > 0) {
      api
        .getData(
          "/api/pos/get_POS_SearchMerchantItems?merchant_id=" +
            response?.merchant_id +
            "&module=pos&search_param=" +
            searchValue
          // +
          // "&city_id=" +
          // response?.city_id +
          // "&location_id=" +
          // posLocation
        )
        .then((response) => {
          if (response.data[0]?.Status === "200") {
            setSearchMerchantItems(JSON.parse(response.data[0].SearchProducts));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setSearchMerchantItems([]);
    }
  };
  useEffect(() => {}, [merchantDetails]);
  const [phone, setPhone] = React.useState();
  const handleChangePhone = (e) => {
    setPhone(e.target.value);
    setPhoneNumber();
  };
  const [customerPhoneDetails, setCustomerPhoneDetails] = React.useState();
  const setPhoneNumber = () => {
    if (phone !== phonePnding) {
      dispatch(setPosPhone(phone));
    }
    if (phone) {
      api
        .getData(
          "/api/pos/customer_phone_details?phone_number=" +
            phone +
            "&merchant_id=" +
            response?.merchant_id
        )
        .then((response) => {
          if (response.data[0]?.Status === "200") {
            setCustomerPhoneDetails(
              JSON.parse(response.data[0]?.CustomerDetails) &&
                JSON.parse(response.data[0]?.CustomerDetails)[0]
            );
            showSwalWithLink(
              "Customer Already Existing!!! Please select Delivery Type Or Table Number"
            );
          }
          if (JSON.parse(response.data[0]?.CustomerDetails) === null) {
            showSwalWithError("New Customer!!! Please Register!!!");
            setNewCustomer(true)
          }
        });
    }
  };

  if (customerPhoneDetails === "") {
    // ref1.current.log();
  }
  useEffect(() => {
    if (phone != phonePnding) {
      dispatch(setPosPhone(phone));
    }
  }, [phone]);

  const [invoiceId, setInvoiceId] = React.useState();
  const getInvoiceId = (customerId) => {
    let data = {
      order_module: "pos",
      customer_id: customerId,
      bazaar_id: process.env.REACT_APP_MY_TITLE === "Vikretha" ? 0 : "1",
      merchant_id: response?.merchant_id,
      billing_person: "1",
      billing_start_time: new Date(),
      environment: process.env.REACT_APP_MY_TITLE,
      city_id: response?.city_id,
      location_id: posLocation,
    };
    if (data.customer_id === null || data.customer_id === "") {
      showSwalWithError("Customer Id Not Null");
    } else if (data.merchant_id === null || data.merchant_id === "") {
      showSwalWithError("Merchant Id Not Null");
    } else if (data.billing_start_time === null || data.merchant_id === "") {
      showSwalWithError("Billing Start Time Not Null");
    } else if (data.city_id === null || data.city_id === "") {
      showSwalWithError("City Ids Not Null");
    } else if (data.location_id === null || data.location_id === "") {
      showSwalWithError("Location Not Null");
    }
    api.postData("/api/pos/POS_InsertOrderInvoice", data).then((resp) => {
      if (resp.data[0].Status === "200") {
        setInvoiceId(resp.data[0].invoice_id);
        dispatch(setPosInvoiceId(resp.data[0].invoice_id));
        let data = {
          invoice_id: resp.data[0].invoice_id,
          merchant_id: response?.merchant_id,
          table_id: posDeliverTypeId == 10 ? tableNumberId : 0,
          del_type_id: deliveryType || deliveryTypeId || deliveryId,
        };

        try {
          api
            .postData("/api/pos/Update_Invoice_delivery_type_table_id", data)
            .then((response) => {
              if (response.data[0].Status === "200") {
                showSwalWithLink("Invoice Generated Successfully");

                getPosPendingInvoices();
              } else {
                showSwalWithError(response.data[0].Message);
              }
            });
        } catch (error) {
          // Handle network error
        }
        getPosPendingInvoices();
      }
    });
  };
  // const handleDeleteTab = (index) => {
  //   // Implement the logic to delete the tab at the given index
  //   // For example, you can remove the tab from the `pendingInvoices` array
  //   const updatedInvoices = [...pendingInvoices];
  //   updatedInvoices.splice(index, 1);
  //   // setPendingInvoices(updatedInvoices);
  // };

  const POSAbortedInvoicecartDetails = (item) => {
    let data = {
      invoice_id: item.invoice_id,
      customer_bazaar_id: item.customer_id,
      merchant_id: item.merchant_id,
      environment: process.env.REACT_APP_MY_TITLE,
    };
    api
      .postData("/api/pos/POS_Aborted_invoice_cart_details", data)
      .then((response) => {
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          getPosPendingInvoices();
        }
      });
  };

  const getFilterByStatus = (status) => {
    // setFilterByStatus(status);
    if (status) {
      const filteredData = filterOrderData.filter((customer) => {
        return customer.order_status === status;
      });
      setOrderData(filteredData);
    }
    if (status === "All") {
      setOrderData(filterOrderData);
    }
  };
  const checkCustomerId = () => {
    if (pendingInvoices) {
      for (let i = 0; i < pendingInvoices.length; i++) {
        if (pendingInvoices[i].customer_id === customerId) {
          return true;
        }
      }
      return false;
    }
  };
  const getSearchPhoneDetails = (phoneNumber) => {
    if (!phone && phoneNumber === undefined) {
      // showSwalWithError("Please Enter Phone Number");
      return false;
    }
    // if (
    //   pendingInvoices === null ||
    //   (pendingInvoices && pendingInvoices.length < 3)
    // ) {
    api
      .getData(
        "/api/pos/customer_phone_verify?phone_number=" +
          (phoneNumber ? phoneNumber : phone) +
          "&env=" +
          process.env.REACT_APP_MY_TITLE
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCustomerData(JSON.parse(response.data[0]?.CustomerDetails));
          getInvoiceId(
            JSON.parse(response.data[0].CustomerDetails)[0]?.customer_id
          );
        } else {
          showSwalWithError(response.data[0]?.Message + "Please Register");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // }
    // else {
    //   if(checkCustomerId()){
    //     showSwalWithError("Already Customer had Billing Invoice Id");
    //   }
    // else if (pendingInvoices && pendingInvoices.length < 3) {
    //   showSwalWithError("Billings Not More Than 3");
    // }
    // }
  };

  const [deliveryAddressDetails, setDeliveryAddressDetails] = React.useState();
  const getDeliveryTypeSending = (invoiceId) => {
    api
      .getData("/api/pos/get_delivery_types_pending?invoice_id=" + invoiceId)
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setDeliveryAddressDetails(
            JSON.parse(response.data[0].Delivery_typeDetails)
          );

          // getInvoiceId();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getPosPendingInvoices();
    getTableNames();
  }, []);

  useEffect(() => {
    if (pendingInvoiceId) {
      // getPendingCartByInvoice();
      getCartItemProductid(pendingInvoiceId);
    }
  }, []);

  useEffect(() => {
    getDeliveryTypeSending(pendingInvoiceId);
  }, [pendingInvoiceId]);

  const [customerOrderDeatails, setCustomerOrderDeatails] = React.useState();
  const POSGetOrderDetailsCustomers = (customerId) => {
    api
      .getData(
        "/api/pos/POS_Get_order_details_customers?order_module=pos&customer_id=" +
          customerId +
          "&merchant_id=" +
          response?.merchant_id +
          "&environment=" +
          process.env.REACT_APP_MY_TITLE
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCustomerOrderDeatails(
            JSON.parse(response.data[0].CustomerOrderDeatails)
          );
          // getInvoiceId();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [orderData, setOrderData] = React.useState();
  const [filterOrderData, setFilterOrderData] = React.useState();
  const POSGetTotalOrderDetailsByMerchant = () => {
    api
      .getData(
        "/api/pos/POS_Get_total_order_details_byMerchant?order_module=pos&merchant_id=" +
          response?.merchant_id +
          "&city_id=" +
          response?.city_id +
          "&environment=" +
          process.env.REACT_APP_MY_TITLE
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setOrderData(JSON.parse(response.data[0].CustomerOrderDeatails));
          setFilterOrderData(
            JSON.parse(response.data[0].CustomerOrderDeatails)
          );
          // getInvoiceId();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (orderData) {
      getOrderById(orderData[0]?.invoice_id);
      getCartItemsOrderid(orderData[0]?.invoice_id);
    }
  }, [orderData]);

  // const [customerDetails, setCustomerDetails] = React.useState();
  const POSGetPendingCustMerchantDetails = (customerId) => {
    api
      .getData(
        "/api/pos/POS_Get_Pending_Cust_Merchant_Details?customer_bazaar_id=" +
          customerId +
          "&merchant_id=" +
          response?.merchant_id +
          "&environment=" +
          process.env.REACT_APP_MY_TITLE
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCustomerData(JSON.parse(response.data[0].CustomerDetails)[0]);
          // getInvoiceId();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const logoutDashboard = () => {
    navigate("/");
  };

  const navigateDashboard = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    if (pendingInvoices && pendingInvoices.length > 0) {
      dispatch(
        setPosInvoiceId(pendingInvoices ? pendingInvoices[0].invoice_id : "0")
      );
      POSGetPendingCustMerchantDetails(
        pendingInvoices ? pendingInvoices[0].customer_id : "0"
      );
      getPOSCustomersAddressDetails(
        pendingInvoices ? pendingInvoices[0].customer_id : "0"
      );
      POSGetOrderDetailsCustomers(
        pendingInvoices ? pendingInvoices[0].customer_id : "0"
      );
      setCustomerId(pendingInvoices ? pendingInvoices[0].customer_id : "0");
    } else {
      dispatch(setPosInvoiceId(null));
    }
  }, [pendingInvoices]);

  useEffect(() => {
    if (document.fullscreenEnabled || document.webkitFullscreenEnabled) {
      const toggleBtn = document.querySelector(".js-toggle-fullscreen-btn");

      const styleEl = document.createElement("link");
      styleEl.setAttribute("rel", "stylesheet");
      styleEl.setAttribute("href", "https://codepen.io/tiggr/pen/poJoLyW.css");
      styleEl.addEventListener("load", function () {
        toggleBtn.hidden = false;
      });
      document.head.appendChild(styleEl);

      toggleBtn.addEventListener("click", function () {
        if (document.fullscreen) {
          document.exitFullscreen();
        } else if (document.webkitFullscreenElement) {
          document.webkitCancelFullScreen();
        } else if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else {
          document.documentElement.webkitRequestFullScreen();
        }
      });

      document.addEventListener("fullscreenchange", handleFullscreen);
      document.addEventListener("webkitfullscreenchange", handleFullscreen);

      function handleFullscreen() {
        if (document.fullscreen || document.webkitFullscreenElement) {
          setFullscreen(false);
          toggleBtn.classList.add("on");
          toggleBtn.setAttribute("aria-label", "Exit fullscreen mode");
        } else {
          setFullscreen(true);
          toggleBtn.classList.remove("on");
          toggleBtn.setAttribute("aria-label", "Enter fullscreen mode");
        }
      }
    }
  }, []);
  // const [pendingInvoices, setPendingInvoices] = React.useState();
  const getPosPendingInvoices = () => {
    api
      .getData(
        "/api/pos/POS_Get_Pending_Invoices?order_module=pos&merchant_id=" +
          response?.merchant_id +
          "&billing_person=" +
          1 +
          "&city_id=" +
          response?.city_id +
          "&location_id=" +
          posLocation +
          "&billing_end_time=NULL&environment=" +
          process.env.REACT_APP_MY_TITLE
      )
      .then((response) => {
        // console.log("pendingInvoices", pendingInvoices);
        if (response.data[0]?.Status === "200") {
          // console.log('pendingInvoices',pendingInvoices);
          dispatch(
            getPendingInvoices(JSON.parse(response.data[0]?.Pending_Invoices))
          );
          dispatch(
            setDeliveryTypeId(
              JSON.parse(response.data[0]?.Pending_Invoices) &&
                JSON.parse(response.data[0]?.Pending_Invoices)[0]
                  ?.delivery_type_id
            )
          );
          dispatch(
            setTableNumber(
              JSON.parse(response.data[0]?.Pending_Invoices) &&
                JSON.parse(response.data[0]?.Pending_Invoices)[0]?.table_id
            )
          );
          dispatch(
            setPosInvoiceId(
              pendingInvoices ? pendingInvoices[0].invoice_id : "0"
            )
          );
          // console.log('pendingInvoices',pendingInvoices);

          dispatch(
            setCustomersId(
              pendingInvoices ? pendingInvoices[0].customer_id : "0"
            )
          );
          if (response.data[0].Pending_Invoices === null) {
            dispatch(setPosInvoiceId(null));
            dispatch(setCustomersId(null));
            setCartTotals(null);
            // getCartItemProductid(null);
            dispatch(setTableNumber(null));
            dispatch(setDeliveryTypeId(null));
          } else {
            // alert();
            getCartItemProductid(
              JSON.parse(response.data[0]?.Pending_Invoices)[0]?.invoice_id
            );
            setTabIndex(0);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [cartitem, setCartItem] = React.useState();
  const [cartTotals, setCartTotals] = React.useState();
  function convertTime(time) {
    let timeSpilt = time?.split(":");

    if (timeSpilt?.length > 0) {
      // console.log("timeSpilt", timeSpilt[0]);
      let ap = timeSpilt[0] >= 12 ? "pm" : "am";
      return timeSpilt[0] + ":" + timeSpilt[1] + " " + ap;
    }
  }
  const [tableNumberId, setTableNumberId] = React.useState(1);
  const getTableNumber = (tableId) => {
    setTableNumberId(tableNumberId);
    dispatch(setTableNumber(tableId));
  };
  useEffect(() => {
    setTableNumberId(posTableNumber);
  }, [posTableNumber]);
  const getCartItemProductid = (invoiceId) => {
    dispatch(setPosInvoiceId(invoiceId));

    api
      .getData(
        "/api/pos/POS_get_cart_item_productid?invoice_id=" +
          (invoiceId ? invoiceId : pendingInvoiceId)
      )
      .then((response) => {
        // console.log("response", response);

        if (response.data[0]?.Status === "200") {
          const scartItems = [];

          // const cartitem =JSON.parse(response.data[0].GetCartItems);
          JSON.parse(response.data[0].GetCartItems)?.forEach((item, index) => {
            let kotInTime = "";
            if (item.kot_in_time !== "00:00:00") {
              kotInTime = moment(item.kot_in_time, "HH:mm:ss.SSSSSSS").format(
                "h:mm A"
              );
            }
            if (
              index === 0 ||
              JSON.parse(response.data[0].GetCartItems)[index - 1]
                .kot_number !== item.kot_number
            ) {
              scartItems.push({
                kot_number: (
                  <Typography component="h1" className="kot-order">
                    KOT Id: {item.kot_number}
                  </Typography>
                ),
                cart_id: "",
                sno: "",
                product_name: "",
                product_qty_sold: "",
                sale_price: "",
                total_item_price: "",
                kot_in_time: (
                  <Typography component="h1" className="kot-order">
                    Order Time : {kotInTime}
                  </Typography>
                ),
              });
            }
            if (item.kot_number !== "") {
              scartItems.push({
                kot_number: "",
                sno: `${index + 1}`,
                cart_id: item.cart_id,
                product_name: item.product_name,
                product_qty_sold: item.product_qty_sold,
                sale_price: item.sale_price,
                total_item_price: item.total_item_price,
                kot_in_time: "",
              });
            } else {
              scartItems.push({
                kot_number: "",
                sno: `${index + 1}`,
                cart_id: item.cart_id,
                product_name: item.product_name,
                product_qty_sold: item.product_qty_sold,
                sale_price: item.sale_price,
                total_item_price: item.total_item_price,
                kot_in_time: (
                  <img
                    className="cross"
                    src={cross1}
                    onClick={() => {
                      deleteCartItem(item);
                    }}
                  />
                ),
              });
            }
          });
          //  console.log('scartItems',scartItems);
          setCartItem(scartItems);
          setUpdateQuantity(scartItems);
          // JSON.parse(response.data[0].GetCartItems)?.forEach((item) => {
          //   console.log("wkot", wkot);
          //   console.log("item.kot_number", item.kot_number);
          //   scartItems.push({
          //     ...item,

          //     kot_number:
          //       item.kot_number === wkot ? (
          //         ""
          //       ) : (
          //         <Typography component="h1" className="kot-order">
          //           KOT Id : {item.kot_number}{" "}
          //         </Typography>
          //       ),
          //     // sno:item.item.kot_number === wkot ?item.sno:"",
          //     // product_name:item.kot_number === wkot ?item.product_name:"",
          //     // product_qty_sold:item.kot_number === wkot ?item.product_qty_sold:"",
          //     // sale_price:item.kot_number === wkot ?item.sale_price:"",

          //     // total_item_price:item.kot_number === wkot ?item.total_item_price:"",
          //     product_name: item.kot_number === wkot ? item.product_name : "",
          //     product_qty_sold:
          //       item.kot_number === wkot ? item.product_qty_sold : "",
          //     sale_price: item.kot_number === wkot ? item.sale_price : "",
          //     total_item_price:
          //       item.kot_number === wkot ? item.total_item_price : "",
          //     cart_id: item.kot_number === wkot ? item.cart_id : "",
          //     kot_in_time:
          //       item.kot_number === wkot ? (
          //         <img
          //           className="cross"
          //           src={cross1}
          //           onClick={() => {
          //             deleteCartItem(item);
          //           }}
          //         />
          //       ) : (
          //         <Typography component="h1" className="kot-order">
          //           Order Time : {convertTime(item.kot_in_time)}{" "}
          //         </Typography>
          //       ),
          //   });
          //   wkot = item.kot_number;
          // });
          // console.log('scartItems',scartItems);
          // setCartItem(scartItems);

          dispatch(
            posPendingCartItems(JSON.parse(response.data[0].GetCartItems))
          );

          setCartTotals(JSON.parse(response.data[0].CartTotals));
          dispatch(setCartTotal(JSON.parse(response.data[0].CartTotals)));
          dispatch(posPendingCartTaxAndFee(response.data[0]));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // const defaultData = [
  //   {
  //     sno: "1",
  //     skuid: "#98759",
  //     item: "Rice",
  //     qty: "2",
  //     price: "	Rs 200",
  //     total: "Rs 400",
  //   },
  // ];
  const [cartItemOrders, setCartItemOrders] = React.useState();
  const [cartTotalsOrders, setCartTotalsOrders] = React.useState();

  const getCartItemsOrderid = (invoiceId) => {
    api
      .getData(
        "/api/pos/POS_get_cart_item_productid?invoice_id=" +
          (invoiceId ? invoiceId : pendingInvoiceId)
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCartItemOrders(JSON.parse(response.data[0].GetCartItems));
          setCartTotalsOrders(JSON.parse(response.data[0].CartTotals));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (pendingInvoiceId) {
      getCartItemsOrderid(pendingInvoiceId);
      getOrderById(pendingInvoiceId);
    }
    if (pendingInvoices === null) {
      dispatch(posPendingCartItems(null));
      setCartItem([]);
      setCustomerData(null);
      getCartItemsOrderid(null);
      setCartTotals(null);
    }
  }, [pendingInvoiceId]);

  const [defaultData, setDefaultData] = useState([]);

  useEffect(() => {
    if (cartItemOrders) {
      // Filter and update defaultData with matched properties from cartItemOrders
      const updatedDefaultData = cartItemOrders.map((cartItem) => {
        const matchedProperties = Object.keys(defaultItem).filter(
          (prop) => prop in cartItem
        );

        return {
          ...defaultItem,
          ...Object.fromEntries(
            matchedProperties.map((prop) => [prop, cartItem[prop]])
          ),
        };
      });

      // console.log('updatedDefaultData', updatedDefaultData);

      setDefaultData(updatedDefaultData);
    } else {
      console.log("welcome", cartItemOrders);
      setDefaultData(defaultItemSet);
    }
  }, [cartItemOrders]);

  useEffect(() => {
    dispatch(posOrderData(defaultData));
  }, [cartItemOrders]);

  useEffect(() => {
    dispatch(posOrderData(defaultItemSet));
  }, []);

  const [orderCustomerDetails, setOrderCustomerDetails] = React.useState();
  const getOrderById = (invoiceId) => {
    api
      .getData(
        "/api/pos/POS_Get_Cust_Merchant_invoice_id?invoice_id=" +
          invoiceId +
          "&merchant_id=" +
          response?.merchant_id +
          "&environment=" +
          process.env.REACT_APP_MY_TITLE
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setOrderCustomerDetails(JSON.parse(response.data[0].CustomerDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [customerBasedMerchantDetails, setCustomerBasedMerchantDetails] =
    React.useState();
  const getCustomerBasedMerchantDetails = () => {
    api
      .getData(
        "/api/pos/POS_get_customers_based_merchant_details?order_module=pos&merchant_id=" +
          response?.merchant_id +
          "&city_id=" +
          response?.city_id +
          "&environment=" +
          process.env.REACT_APP_MY_TITLE
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setCustomerBasedMerchantDetails(
            JSON.parse(response.data[0].GetCustomers)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getCustomerBasedMerchantDetails();
    POSGetTotalOrderDetailsByMerchant();
  }, []);
  useEffect(() => {
    if (customerBasedMerchantDetails) {
      getPOSCustomersAddressDetails(
        customerBasedMerchantDetails[0].customer_id
      );
      POSGetOrderDetailsCustomers(customerBasedMerchantDetails[0].customer_id);
    }
  }, [customerBasedMerchantDetails]);
  const [POSCustomersAddressDetails, setPOSCustomersAddressDetails] =
    React.useState();
  const getPOSCustomersAddressDetails = (customerId) => {
    api
      .getData(
        "/api/pos/POS_customers_address_details?customer_bazaar_id=" +
          customerId +
          "&environment=" +
          process.env.REACT_APP_MY_TITLE
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setPOSCustomersAddressDetails(
            JSON.parse(response.data[0].GetCustomers)[0]
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteCartDetails = (invoice) => {
    if (pendingCartItem && pendingCartItem.length > 0) {
      let data = {
        invoice_id: invoice,
        merchant_id: response?.merchant_id,
      };

      try {
        api
          .postData("/api/pos/POS_DeleteCartDetailsByInvoiceId", data)
          .then((response) => {
            if (response.data[0].Status === "200") {
              showSwalWithLink(response.data[0].Message);
              getCartItemProductid(invoice);
            } else {
              showSwalWithError(response.data[0].Message);
            }
          });
      } catch (error) {
        // Handle network error
      }
    } else {
      showSwalWithError("No Items In cart");
    }
  };

  // useEffect(()=>{
  //   getCartItemProductid();
  // },[])
  const [pendingCartItems, setPendingCartItems] = React.useState();
  const getPendingCartByInvoice = () => {
    // api.getData('/api/pos/POS_Get_Pending_Cart_by_Invoice?merchant_id=' + pendingInvoices[0].merchant_id + '&invoice_no=' + pendingInvoices[0].invoice_id)
    api
      .getData(
        "/api/pos/POS_Get_Pending_Cart_by_Invoice?merchant_id=" +
          response?.merchant_id +
          "&invoice_no=" +
          pendingInvoiceId
      )

      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setPendingCartItems(JSON.parse(response.data[0].Cart_Items));
          dispatch(
            posPendingCartItems(JSON.parse(response.data[0].Cart_Items))
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [kotNumber, setKotNumber] = React.useState();
  const generateKot = () => {
    // api.getData('/api/pos/POS_Get_Pending_Cart_by_Invoice?merchant_id=' + pendingInvoices[0].merchant_id + '&invoice_no=' + pendingInvoices[0].invoice_id)
    let data = {
      invoice_id: pendingInvoiceId,
    };

    api
      .postData("/api/kot/Generate_KOT_Number", data)

      .then((response) => {
        // if (response.data[0]?.Status === "200") {
        setKotNumber(response.data[0]?.RandomCode);
        getCartItemProductid(pendingInvoiceId);
        // setPendingCartItems(JSON.parse(response.data[0].Cart_Items));
        // dispatch(
        //   posPendingCartItems(JSON.parse(response.data[0].Cart_Items))
        // );
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setDeliveryIds = (deliveryId) => {
    setDeliveryTypeIds(deliveryId);
    dispatch(setDeliveryTypeId(deliveryId));
    const obj = deliveryTypes.find((item) => item.del_type_id === deliveryId);
    // if (
    //   !obj ||
    //   !obj.hasOwnProperty("del_type_fields") ||
    //   obj.del_type_fields.trim() === ""
    // ) {
    //   return false;
    // }
    handleOpenDelivery(deliveryId);
  };
  const [specialDiscountNames, setSpecialDiscountNames] = React.useState();
  const getSpecialDiscountNames = () => {
    api
      .getData("/api/promotions/get_special_discount_pos")
      .then((response) => {
        // console.log("response", response);
        if (response.data[0].status === "200") {
          setSpecialDiscountNames(
            JSON.parse(response.data[0].SpecialDiscounts)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSpecialDiscountNames();
  }, []);

  const getDeliveryTypes = () => {
    api
      .getData(
        "/api/pos/get_delivery_types?environment=" +
          process.env.REACT_APP_MY_TITLE +
          "&module=pos"
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setDeliveryTypes(JSON.parse(response.data[0].Delivery_typeDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDeliveryTypes();
  }, []);

  const getSegmentDetails = () => {
    api
      .getData(
        "/api/pos/get_Segment_Details_pos?merchant_id=" +
          response.merchant_id +
          "&module=pos"
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setSegmentDetails(
            JSON.parse(response.data[0].Merchant_Segement_Details)
          );
          setFinalSegment(
            JSON.parse(response.data[0].Merchant_Segement_Details)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSubSegmentsDetails = (merchantSubSegmentId) => {
    // const data = fetchSubSegmentsDetails(response.merchant_id, 2);

    api
      .getData(
        "/api/pos/get_sub_segment_Details_pos?merchant_id=" +
          response.merchant_id +
          "&module=pos&merchant_segment_id=" +
          merchantSubSegmentId
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setSubSegmentDetails(JSON.parse(response.data[0].SubSegmentDetails));
          setSubFinalSegment(JSON.parse(response.data[0].SubSegmentDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // setSubSegmentDetails(data);
  };

  const getItemDetails = (segmentId, subSegmnetId) => {
    api
      .getData(
        "/api/pos/get_item_Details_pos?merchant_id=" +
          response?.merchant_id +
          "&module=pos&segment_id=" +
          segmentId +
          "&sub_segment_id=" +
          subSegmnetId
        // +"&city_id=" +
        // response?.city_id +
        // "&location_id=" +
        // posLocation
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setItemDetails(JSON.parse(response.data[0].ProductDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [cartItemDetails, setCartItemDetails] = React.useState();
  // const getCartByItems = (productId, cartId) => {
  //   api.getData('/api/pos/POS_get_cart_item_productid?product_id=' + productId + '&cart_id=' + cartId)
  //     .then((response) => {

  //       if (response.data[0]?.Status === "200") {

  //         setCartItemDetails(JSON.parse(response.data[0].GetCartItems));
  //       }

  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })

  // }

  const tabChange = (item) => {
    dispatch(setCustomersId(item.customer_id));
    dispatch(setDeliveryTypeId(item.delivery_type_id));
    POSGetPendingCustMerchantDetails(item.customer_id);
  };

  const [count, setCount] = React.useState(0);
  const POSInsertOrderDetails = () => {
    let data = {
      invoice_id: pendingInvoiceId || "0",
      merchant_id: response?.merchant_id || "0",
      customer_id: pendingCartItem
        ? pendingCartItem[0].customer_id
        : customerId,
      bazaar_id: process.env.REACT_APP_MY_TITLE === "Vikretha" ? 0 : "1",
      order_payload: JSON.stringify(pendingCartItem),
      order_status: "pending",
      invoice_file_name: "null",
      delivery_id: posDeliverTypeId ? posDeliverTypeId : deliveryId,
      payment_id: "0",
      pay_type: null,
      order_count: pendingCartItemTotal
        ? pendingCartItemTotal[0].no_of_items
        : count,
      order_total_amount: pendingCartItemTotal
        ? pendingCartItemTotal[0].total_item_price
        : "0",
      order_paid_amount: pendingCartItemTotal
        ? pendingCartItemTotal[0].item_final_amount
        : "0",
      order_refund_amount: "0",
      order_balance_amount: "0",
      order_date: new Date(),
    };

    if (data.invoice_id === null || data.invoice_id === "") {
      showSwalWithError("Invoice Id Can Not Be Null");
      return false;
    } else if (data.merchant_id === null || data.merchant_id === "") {
      showSwalWithError("Merchant Id Can Not Be Null");
      return false;
    } else if (data.customer_id === null || data.customer_id === "") {
      showSwalWithError("Customer Id Can Not Be Null");
      return false;
    } else if (data.pendingCartItem === null || data.pendingCartItem === "") {
      showSwalWithError("Pending Cart Items Can Not Be Null");
      return false;
    } else if (data.delivery_id === null || data.delivery_id === "") {
      showSwalWithError("Delivery Id Can Not Be Null");
      return false;
    } else if (data.count === null || data.count === "") {
      showSwalWithError("Order Count Can Not Be Null");
      return false;
    } else if (
      data.order_total_amount === null ||
      data.order_total_amount === ""
    ) {
      showSwalWithError("Order Total Amount Can Not Be Null");
      return false;
    } else if (
      data.order_paid_amount === null ||
      data.order_paid_amount === ""
    ) {
      showSwalWithError("Order Paid Amount Can Not Be Null");
      return false;
    } else if (data.order_date === null || data.order_date === "") {
      showSwalWithError("Order Date Can Not Be Null");
      return false;
    }

    if (pendingCartItem && pendingCartItem.length > 0) {
      api
        .postData("/api/pos/POS_insert_order_details", data)
        .then((response) => {
          if (response.data[0].Status === "200") {
            dispatch(setOrderId(response.data[0].order_id));
            dispatch(setCustomersId(customerId));
            ref6.current.log();
          } else {
            showSwalWithError(
              response.data[0].Message || response.data.Message
            );
          }
        });
    } else {
      showSwalWithError("Cart Items Are Empty");
    }
  };

  useEffect(() => {
    getSegmentDetails();
  }, []);

  useEffect(() => {
    if (!searchItem && !searchProductDetails) {
      getSegmentDetails();
    }
  }, [searchProductDetails]);

  useEffect(() => {
    fetchCities();
  }, []);
  useEffect(() => {
    if ((segmentDetails || finalSegment) && !searchItem) {
      getSubSegmentsDetails(
        finalSegment
          ? finalSegment[0].merchant_segment_id
          : segmentDetails
          ? segmentDetails[0].merchant_segment_id
          : "0"
      );
    }
  }, [segmentDetails, finalSegment]);

  useEffect(() => {
    if (subSegments) {
      getItemDetails(
        subSegments[0]?.merchant_segment_id,
        subSegments[0]?.merchant_sub_segment_id
      );
    }
  }, [subSegments]);
  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);
  const ItemColumns = ["S.NO", "ITEM NAME", "QTY", "PRICE", "TOTAL", ""];

  const customerColumns = [
    {
      name: "customer_id",
      label: "Customer ID",
      //    label:'',
    },
    {
      name: "name_of_the_customer",
      label: "Customer Name",
    },
    { name: "email_id", label: "Email" },
    { name: "phone_number", label: "Phone" },
    { name: "count_orders", label: "Orders count" },
  ];

  const QuantityCell = ({ value, rowData }) => {
    const [counter, setCounter] = useState(value);

    const handleIncrement = (rowData) => {
      // console.log('rowData',rowData);
      setCounter(Number(counter) + 1);
      updateDeleteQuantity(Number(counter) + 1, rowData);
    };

    const addQuantity = (value) => {
      setCounter(value);
      updateDeleteQuantity(Number(value), rowData);
    };
    const handleDecrement = (rowData) => {
      // console.log('rowData',rowData);
      if (Number(counter) < 2) {
      } else {
        setCounter(Number(counter) - 1);
        updateDeleteQuantity(Number(counter) - 1, rowData);
      }
    };

    const updateDeleteQuantity = (counter) => {
      // console.log('props.rowData',rowData);
      let data = {
        product_id: rowData?.product_id,
        invoice_id: pendingInvoiceId,
        product_qty_sold: counter,
        cart_id: rowData?.cart_id,
        sale_price: rowData?.sale_price,
        tax_amount: "0",
        discount_amount: "0",
        fee_amount: "0",
        maintain_stock: rowData?.maintain_stock ? rowData.maintain_stock : "0",
        product_segment_id: rowData?.product_segment_id,
        product_sub_segment_id: rowData?.product_sub_segment_id,
        customer_id: customerIds,
        merchant_id: response?.merchant_id,
      };
      if (data.product_id === null || data.product_id === "") {
        showSwalWithError("Product Id Can Not Be Null");
        return false;
      } else if (data.invoice_id === null || data.invoice_id === "") {
        showSwalWithError("Merchant Id Can Not Be Null");
        return false;
      } else if (
        data.product_qty_sold === null ||
        data.product_qty_sold === ""
      ) {
        showSwalWithError("Product Quantity Can Not Be Null");
        return false;
      } else if (data.cart_id === null || data.cart_id === "") {
        showSwalWithError("Cart Id Can Not Be Null");
        return false;
      } else if (data.sale_price === null || data.sale_price === "") {
        showSwalWithError("Sale Price Can Not Be Null");
        return false;
      }

      api
        .postData("/api/pos/POS_update_delete_cart_item_quantity", data)
        .then((response) => {
          if (response.data[0].Status === "200") {
            getCartItemProductid(pendingInvoiceId);
            // setInvoiceId(response.data[0].invoice_id);
          }
        });
    };

    useEffect(() => {}, [counter]);
    return (
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button onClick={handleIncrement}>+</Button>
        <InputBase
          value={counter}
          type="number"
          onChange={(e) => {
            addQuantity(e.target.value);
          }}
          className="counter-input"
        />
        <Button onClick={handleDecrement}>-</Button>
      </ButtonGroup>
    );
  };

  const deleteCartItem = (rowData) => {
    let data = {
      product_id: rowData?.product_id,
      product_qty_sold: rowData?.product_qty_sold,
      cart_id: rowData?.cart_id,
      invoice_id: rowData?.invoice_id,
      maintain_stock: rowData?.maintain_stock ? rowData.maintain_stock : "0",
    };
    if (data.product_id === null || data.product_id === "") {
      showSwalWithError("Product Id Can Not Be Null");
      return false;
    } else if (data.product_qty_sold === null || data.product_qty_sold === "") {
      showSwalWithError("Product Quantity Can Not Be Null");
      return false;
    } else if (data.cart_id === null || data.cart_id === "") {
      showSwalWithError("Cart Id Can Not Be Null");
      return false;
    }

    api
      .postData("/api/pos/POS_delete_cart_product_id_item_quantity", data)
      .then((response) => {
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          getCartItemProductid(rowData?.invoice_id);
          // setInvoiceId(response.data[0].invoice_id);
        } else {
          showSwalWithError(response.data[0].Message);
        }
      })
      .catch((error) => {
        console.log(error);
        // Handle the error here, e.g., show an error message to the user
      });
  };
  const editCartItemColumns = [
    {
      name: "",
      label: "S.NO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    // {
    //   name: "product_complete_sku",
    //   label: "SKU ID",
    // },
    { name: "product_name", label: "ITEM NAME" },
    {
      name: "product_qty_sold",
      label: "QTY",
    },
    { name: "sale_price", label: "PRICE" },
    { name: "total_item_price", label: "TOTAL" },
    // {
    //   name: "invoice_id",
    //   label: " ",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const rowIndex = tableMeta.rowIndex;
    //       const rowData = pendingCartItem ? pendingCartItem[rowIndex] : [];
    //       return (
    //         <img
    //           className="cross"
    //           src={cross1}
    //           onClick={() => {
    //             deleteCartItem(rowData);
    //           }}
    //         />
    //       );
    //     },
    //   },
    // },
  ];
  const cartItemColumns = [
    // {
    //   name: "",
    //   label: "S.NO",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return tableMeta.rowIndex + 1;
    //     },
    //   },
    // },
    //   {
    //     name: '',
    //     label: 'S.NO',
    //     options: {
    //         customBodyRender: (value, tableMeta, updateValue) => {
    //           return tableMeta.rowIndex + 1;
    //         },
    //       },

    // },
    { name: "sno", label: "S.NO" },
    {
      name: "kot_number",
      label: " ",
    },
    { name: "product_name", label: "ITEM NAME" },
    {
      name: "product_qty_sold",
      label: "QTY",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          const rowData = updateQuantity ? updateQuantity[rowIndex] : [];
          const rowDatas = pendingCartItem
            ? pendingCartItem.filter((item) => {
                if (item.cart_id === rowData?.cart_id) {
                  return item;
                }
              })
            : [];

          return rowDatas[0]?.kot_number ? (
            value
          ) : value ? (
            <QuantityCell value={value} rowData={rowDatas[0]} />
          ) : (
            ""
          );
        },
      },
    },
    { name: "sale_price", label: "PRICE" },
    { name: "total_item_price", label: "TOTAL" },
    {
      name: "kot_in_time",
      label: " ",
    },
    // {
    //   name: "cart_id",
    //   label: " ",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const rowIndex = tableMeta.rowIndex;
    //       const rowData = pendingCartItem ? pendingCartItem[rowIndex] : [];
    //       console.log('rowData',rowData);
    //       return rowData?.product_name === ''?"":(
    //         <img
    //           className="cross"
    //           src={cross1}
    //           onClick={() => {
    //             deleteCartItem(rowData);
    //           }}
    //         />
    //       );
    //     },
    //   },
    // },
  ];
  useEffect(() => {}, [POSCustomersAddressDetails]);

  const customerOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.dataIndex;
      const clickedRowData = customerBasedMerchantDetails[clickedRowIndex];
      getPOSCustomersAddressDetails(clickedRowData.customer_id);
      POSGetOrderDetailsCustomers(clickedRowData.customer_id);
      // onRowClick(clickedRowData);
    },
  };

  const billColumns = [
    {
      name: "",
      label: "S.NO",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: "invoice_id",
      label: "Invoice ID",
    },
    {
      name: "order_date",
      label: "Date/Time",

      // options: {
      //   customBodyRender: (value) => {
      //     const formattedDateTime = format(new Date(value), 'dd/MM/yyyy hh:mm:ss a');
      //     return formattedDateTime;
      //   }
      // }
    },
    { name: "order_total_amount", label: "Bill Amount" },
    { name: "pay_type_name", label: "Payment Mode" },
  ];

  const billData = [
    [
      1,
      <Box component="a" href="#" onClick={() => ref19.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
    [
      2,
      <Box component="a" href="#" onClick={() => ref19.current.log()}>
        #SKU89948
      </Box>,
      "24 Jan 2020 / 03:34 PM",
      "2,500",
      "UPI",
    ],
  ];

  const itemData = [
    [1, "#98759", "Rice", 2, "$ 200", "$400"],
    [2, "#SKU89948", "Milk & White Milk", 2, "$ 200", "$400"],
  ];

  const orderColumns = [
    { name: "order_date", label: "Date" },
    {
      name: "order_id",
      label: "Order ID",
      //    label:'',
    },
    { name: "name_of_the_customer", label: "Customer" },
    { name: "order_total_amount", label: "Total Amount" },
    { name: "order_module", label: "Order Method" },
    // {
    //   name: "city_name",
    //   label: "CITY",
    // },

    { name: "order_status", label: "Status" },
  ];

  const driverData = [];

  const shiftOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch:
          driverData.length === 0 ? "No Employee has been Scheduled" : "",
      },
    },
    onTableChange: (action, state) => {
      console.dir(state);
    },
  };

  const handleOpenDelivery = (delTypeId) => {
    // let delTypeId = posDeliverTypeId;
    // console.log('delTypeId',posDeliverTypeId);
    if (delTypeId === 5) {
      ref5.current.log();
    } else if (delTypeId === 7) {
      ref7.current.log();
    } else if (delTypeId === 8) {
      ref8.current.log();
    } else if (delTypeId === 9) {
      ref9.current.log();
    } else if (delTypeId === 10) {
      getTableNames();
      ref10.current.log();
    }
  };

  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };

  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      text: message,
    });
  };
  const [cartItems, setCartItems] = React.useState();
  const getCartAddItem = async (productId) => {
    try {
      const response = await api.getData(
        "/api/pos/get_POS_cart_Product_Details?module=pos&product_id=" +
          productId
      );

      if (response.data[0].Status === "200") {
        if (JSON.parse(response.data[0].ProductDetails)) {
          return JSON.parse(response.data[0].ProductDetails)[0];
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addToCart = async (item) => {
    try {
      let itemData = await getCartAddItem(item.product_id);
      // console.log("itemData", itemData);

      // if (itemData && itemData.maintain_stock === 0 && itemData.product_qty_physical > 0) {
      let data = {
        cart_id: "0",
        invoice_id: pendingInvoiceId || "",
        merchant_id: item.merchant_id || "",
        customer_id:
          process.env.REACT_APP_MY_TITLE === "Bazaar" ? null : customerId,
        // bazaar_id:
        //   process.env.REACT_APP_MY_TITLE === "Vikretha" ? null : customerId,
        product_id: itemData.product_id || "",
        product_name: itemData.product_name || "",
        product_image: itemData.product_image || "",
        product_segment_id: itemData.product_segment_id || "",
        product_sub_segment_id: itemData.product_sub_segment_id || "",
        product_master_sku: itemData.product_master_sku || "",
        product_complete_sku: itemData.product_complete_sku || "",
        product_metric: itemData.product_metric || "",
        product_uom: itemData.product_uom || "",
        no_of_units: itemData.no_of_units || "",
        sale_price: itemData.sale_price || itemData.product_sale_price || "",
        product_qty_sold: itemData.product_qty_physical || "",
        discount_id: itemData.discount_id || "0",
        discount_amount: itemData.discount_amount || "0",
        tax_id: "0",
        tax_amount: "0",
        fee_id: "0",
        fee_amount: "0",
        item_status: "0",
        isbilled: "1",
        maintain_stock: itemData?.maintain_stock || "0",
      };
      if (data.invoice_id === null || data.invoice_id === "") {
        showSwalWithError("Invoice Id Can Not Be Null");
        return false;
      } else if (
        data.product_qty_sold === null ||
        data.product_qty_sold === ""
      ) {
        showSwalWithError("Product quantity Not Available");
        return false;
      } else if (data.merchant_id === null || data.merchant_id === "") {
        showSwalWithError("Merchant Id Can Not Be Null");
        return false;
      } else if (data.customer_id === null || data.customer_id === "") {
        showSwalWithError("Customer Id Can Not Be Null");
        return false;
      } else if (data.product_id === null || data.product_id === "") {
        showSwalWithError("Product Id Can Not Be Null");
        return false;
      } else if (data.product_name === null || data.product_name === "") {
        showSwalWithError("Product Name Can Not Be Null");
        return false;
      } else if (data.product_image === null || data.product_image === "") {
        showSwalWithError("Product Image Can Not Be Null");
        return false;
      } else if (data.product_image === null || data.product_image === "") {
        showSwalWithError("Product Image Can Not Be Null");
        return false;
      } else if (
        data.product_segment_id === null ||
        data.product_segment_id === ""
      ) {
        showSwalWithError("Product Segment Can Not Be Null");
        return false;
      }
      const response = await api.postData(
        "/api/pos/POS_create_update_cart_item",
        data
      );
      // console.log("response", response);
      if (response.data[0].Status === "200") {
        //     console.log('response',response);
        //     let scartItems = [];
        //     JSON.parse(response.data[0].GetCartItems)?.Cart_Items((item)=>{
        //      let wkot = "";
        //      console.log('wkot',wkot);
        //      scartItems.push({
        //            ...item,

        //            kot_number:item.kot_number == wkot ?"": <Typography component="h1" className="kot-order">KOT Id : {item.kot_number}{" "}</Typography>,
        //            // sno:item.item.kot_number === wkot ?item.sno:"",
        //            product_name:item.kot_number !== wkot ?item.product_name:"",
        //            product_qty_sold:item.kot_number !== wkot ?item.product_qty_sold:"",
        //            sale_price:item.kot_number !== wkot ?item.sale_price:"",
        //            total_item_price:item.kot_number !== wkot ?item.total_item_price:"",
        //            kot_in_time: item.kot_number === wkot ? "": <Typography component="h1" className="kot-order">Order Time : {getTime2(item.kot_in_time)}{" "}</Typography>,
        //            invoice_id:item.kot_number !== wkot ? item.invoice_id:"",
        //          })
        //          console.log('item.kot_number',item.kot_number);
        //          wkot=item.kot_number
        //  });
        //   console.log('scartItems',scartItems);
        // setCartItem(scartItems);

        setCartItems(response.data[0].Cart_Items);
        showSwalWithLink(response.data[0].Message);
        getCartItemProductid(pendingInvoiceId);
      } else {
        showSwalWithError(response.data[0].Message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [orderCustomerData, setOrderCustomerData] = React.useState();
  const orderOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    confirmFilters: false,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.dataIndex;
      const clickedRowData = orderData[clickedRowIndex];
      getOrderById(clickedRowData.invoice_id);
      getCartItemsOrderid(clickedRowData.invoice_id);
      // getPOSCustomersaAddressDetails(clickedRowData.invoice_id);
      // POSGetOrderDetailsCustomers(clickedRowData.invoice_id);

      // onRowClick(clickedRowData);
    },
  };

  const itemOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: true,
    selectableRows: false,
    pagination: false,
    confirmFilters: false,
    onTableChange: (action, state) => {
      // console.log(action);
      console.dir(state);
    },
  };
  const [value, setValue] = React.useState(0);
  const [deliveryType, setDeliveryType] = React.useState(0);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };

  const [logo, setLogo] = React.useState();
  React.useEffect(() => {
    // Update favicon based on condition
    if (process.env.REACT_APP_MY_TITLE === "Bazaar") {
      setLogo(process.env.REACT_APP_MY_LOGO_URL);
    } else if (process.env.REACT_APP_MY_TITLE === "Vikretha") {
      setLogo(process.env.REACT_APP_MY_LOGO_URL_VIKRETA);
    }
  }, []);
  const cancelSearch = () => {
    setSearchValue("");
    getSearchDetails(searchValue);
  };
  const searchOrders = (searchField) => {
    if (searchField) {
      const filteredCustomers = filterOrderData?.filter((person) => {
        return (
          person.name_of_the_customer
            .toLowerCase()
            .includes(searchField.toLowerCase()) ||
          person.city_name.toLowerCase().includes(searchField.toLowerCase())
        );
      });
      setOrderData(filteredCustomers);
    } else {
      setOrderData(filterOrderData);
    }
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref15 = useRef();
  const ref6 = useRef();
  const ref17 = useRef();
  const ref18 = useRef();
  const ref19 = useRef();
  const ref5 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();
  const ref10 = useRef();
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  useEffect(() => {}, [deliveryTypes]);

  const handleSubmit = (values) => {
    if(newCustomer){
      let data = new FormData();
      data.append('customer_id', '0');
      data.append('bazaar_id', process.env.REACT_APP_MY_TITLE ==='Vikretha'?0:1);
      data.append('merchant_id', response?.merchant_id);
      data.append('name_of_the_customer', formik.values.name_of_the_customer);
      data.append('email_id', formik.values.email_id);
      data.append('phone_number', phone);
      data.append('password', '123456');
      data.append('gender', 'male');
      data.append('birthday', "11/11/1995");
      data.append('occasion_date', '');
      data.append('otp_status', '0');
      data.append('city_id', response?.city_id);
      data.append('cod_status', '0');
      data.append('block_status', '1');
      data.append('cust_address', formik.values.cust_address);
      data.append('cust_area', 'x');
      data.append('cust_locality', 'x');
      data.append('cust_street', 'x');
      data.append('cust_city',response?.city_id);
      data.append('pincode', '123456');
      data.append('address_type', 'd');
      data.append('adress_status', '0');
      data.append('profilephoto', '');
      try {
          api.updateData('/api/pos/customer_Create_Update', data).then((response) => {
            console.log('response',response);
              if (response.data[0].Status === "200") {
                  
                  if(response.data[0].IsPhoneVerifyRequired === 'yes'){
                      ref6.current.log()
                      setPhoneNumber(response.data[0].PhoneNumber);
                  }else{
                    getSearchPhoneDetails(response.data[0]?.PhoneNumber)
                  }
              }else{
                showSwalWithError(response.data[0].Message)
              }
          })
      } catch (error) {
          // Handle network error
      }
    }else{
      let initialValues = {
        "invoice_id": pendingInvoiceId,
        "delivery_type_id": deliveryType,
        "delivery_from_address": null,
        "delivery_to_address":JSON.stringify([Object.values(formik.values).join(',')]),
        "delivery_status": "New",
        "delivery_date": new Date(),
        "runner_id": 0,
        assigned_date: new Date(),
        delivery_or_return: 'd',
        delivery_id: 0
    }
    api.postData('/api/pos/POS_InsertOrUpdateOrderDeliveryDetails', initialValues).then((response) => {
        if (response.data[0].Status === "200") {
            showSwalWithLink(response.data[0].Message);
            dispatch(setDeliveryId(JSON.parse(response.data[0].DeliveryDetails)[0].delivery_id));
            getSearchPhoneDetails();
        }
    })
    }
};


const handleCreateSubmit = async (values, { setSubmitting }) => {

  

  setSubmitting(false);
};

const initialValues = {
  name_of_the_customer: customerPhoneDetails?.name_of_the_customer,
  phone_number: customerPhoneDetails?.phone_number,
  email_id: customerPhoneDetails?.email_id,
  Address: "",
  Pincode: "",
  DateTime: ""
};
  const formik = useFormik({
    initialValues,
    // validationSchema: deliveryAddress,
    onSubmit: handleSubmit,
    enableReinitialize: true,
});

useEffect(()=>{
    formik.setValues(initialValues);
},[customerPhoneDetails])

  const { touched, errors, isSubmitting, handleBlur } = formik;
  const handleChangeOrderType = (orderType) => {
    setDeliveryType(orderType);
    if (orderType === 10) {
      setDoorIndex(2);
      setDeliveryIds(10);
    }
  };
  return (
    <>
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  <Box src={logo} className="main-logo" component="img" />
                  <Box>
                    <Typography component="h2">POS {pos}</Typography>
                  </Box>
                  <Box className="icons">
                    <Box
                      component="img"
                      src={customers}
                      onClick={() => ref.current.log()}
                    />

                    <Typography component="h5" ml={1}>
                      {response?.merchant_business_name}
                    </Typography>
                    <Box
                      component="img"
                      src={logout}
                      ml={2}
                      onClick={logoutDashboard}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box className="w-100 sub-div flex-between plr-15">
              <Box className="flex ml-20">
                <Box
                  className="flex color-white"
                  onClick={navigateDashboard}
                  style={{ cursor: "pointer" }}
                >
                  <DashboardIcon />
                  <Typography component="h4" ml={1}>
                    Dashboard
                  </Typography>
                </Box>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="ml-20 location-select"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  value={posLocation}
                  onChange={(e) => setLocationId(e.target.value)}
                  defaultValue="1"
                >
                  {/* <MenuItem value="locations">Location</MenuItem>
                  <MenuItem>MVP</MenuItem>
                  <MenuItem>CBM</MenuItem>
                  <MenuItem>RK Beach</MenuItem> */}
                  {location?.map((item) => {
                    return (
                      <MenuItem value={item.merchant_location_id}>
                        {item.location_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              <Box className="d-flex ">
                <div className="js-toggle-fullscreen-btn">
                  {fullscreen === false ? (
                    <FullscreenExitIcon className="fullscreen mr-20" />
                  ) : (
                    <FullscreenIcon className="fullscreen mr-20" />
                  )}
                </div>
              </Box>
            </Box>
            <Box className="w-100 pos-cardcontent">
              <Grid container className="full_content">
                <Box className="main-bg" sx={{ flexGrow: 1, display: "flex" }}>
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider" }}
                    className="vertical-tabs"
                  >
                    <Tab
                      label="BILLING"
                      {...a11yProps(0)}
                      onClick={() => changeTitle("Billing")}
                    />
                    <Tab
                      label="CUSTOMERS"
                      {...a11yProps(1)}
                      onClick={() => changeTitle("Customers")}
                    />
                    <Tab
                      label="ORDERS"
                      {...a11yProps(2)}
                      onClick={() => changeTitle("Orders")}
                    />
                  </Tabs>
                  <TabPanel value={value} index={0} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Box
                          className="w-100 d-flex align-center flex-between mt-6"
                          mb={2}
                        >
                          {/* <Search className="search-pos">
                            <SearchIconWrapper>
                              <SearchIcon />
                            </SearchIconWrapper>
                            <SearchInputBase
                              placeholder="Search…"
                              inputProps={{ "aria-label": "search" }}
                              onChange={(e) => getSearchDetails(e.target.value)}
                            />
                          </Search> */}
                          <SearchBar
                            className="search-bar"
                            value={searchValue}
                            onChange={(searchVal) =>
                              getSearchDetails(searchVal)
                            }
                            onCancelSearch={() => cancelSearch()}
                          />
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            mb={3}
                            className="retail-btn float-right posmain-btn"
                            onClick={() => ref15.current.log()}
                          >
                            <Box
                              src={upscan}
                              component="img"
                              className="upscan"
                            />
                            <Box component="span">SCAN</Box>
                          </Button>
                        </Box>
                        <Box mb={3} mt={3} className="carousel_container">
                          <Carousel
                            swipeable={false}
                            draggable={false}
                            className="main_slider"
                            // showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            //   infinite={true}
                            //   autoPlay={props.deviceType !== "mobile" ? true : false}
                            //   autoPlaySpeed={1000}
                            keyBoardControl={true}
                            // customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            //   deviceType={props.deviceType}
                            dotListClass="custom-dot-list-style"
                            itemClass="carousel-item-padding-40-px"
                          >
                            {finalSegment ? (
                              finalSegment?.map((item) => {
                                return (
                                  <div className="custom-slider">
                                    <Card
                                      sx={{ maxWidth: 110 }}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        getSubSegmentsDetails(
                                          item.merchant_segment_id
                                        );
                                      }}
                                    >
                                      <CardContent
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <Box
                                          src={item.merchant_segment_image}
                                          component="img"
                                          className="slide-img"
                                        />

                                        <Typography
                                          gutterBottom
                                          variant="small"
                                          component="small"
                                        >
                                          {item.merchant_segment_name}
                                        </Typography>
                                      </CardContent>
                                    </Card>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="custom-slider">
                                <Card sx={{ maxWidth: 110 }}>
                                  <CardContent
                                    style={{
                                      display: "flex",
                                      flexFlow: "column",
                                    }}
                                  >
                                    <Box
                                      src={dairy}
                                      component="img"
                                      className="slide-img"
                                    />
                                    <Typography
                                      gutterBottom
                                      variant="small"
                                      component="small"
                                    >
                                      Groceries
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </div>
                            )}
                          </Carousel>
                        </Box>
                        <Box sx={{ width: "100%" }} className="items-div">
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            {/* {console.log("finalSubSegment", finalSubSegment)} */}

                            <Tabs
                              value={itemValue}
                              onChange={handleChangeItems}
                              aria-label="basic tabs example"
                              className="multitabs"
                            >
                              {finalSubSegment?.map((item, index) => {
                                return (
                                  <Tab
                                    label={item.merchant_sub_segment_name}
                                    {...a11yProps(index)}
                                    onClick={() =>
                                      getItemDetails(
                                        item.merchant_segment_id,
                                        item.merchant_sub_segment_id
                                      )
                                    }
                                  />
                                );
                              })}

                              {/* <Tab label="OIL & GHEE" {...a11yProps(1)} />
                              <Tab label="RICE" {...a11yProps(2)} />
                              <Tab label="DALS&PULSES" {...a11yProps(3)} />
                              <Tab label="MASALA" {...a11yProps(4)} />
                              <Tab label="BODY CARE" {...a11yProps(5)} />
                              <Tab label="DAIRY" {...a11yProps(6)} />
                              <Tab label="MASALA" {...a11yProps(7)} /> */}
                            </Tabs>
                          </Box>
                          {finalSubSegment?.map((item, index) => {
                            return (
                              <TabPanel value={itemValue} index={index}>
                                <Items
                                  itemdata={itemDetails}
                                  posDeliverTypeId={posDeliverTypeId}
                                  getCartItemProductid={getCartItemProductid}
                                  pendingInvoiceId={pendingInvoiceId}
                                  tableNumberId={tableNumberId}
                                />
                              </TabPanel>
                            );
                          })}
                        </Box>
                      </Grid>
                      {/*pavan code start */}
                      <Grid item xs={12} md={6}>
                      <Box className="order-values sub-order bg-white">
                      <form onSubmit={formik.handleSubmit}>
                          <Grid
                            container
                            className="details-div p-15 adding-cust"
                            spacing={2}
                          >
                            <Grid item xs={12} md={4}>
                              <FormControl className="w-100">
                                <Typography component="label">Name</Typography>
                                <OutlinedInput
                                  placeholder="Name"
                                  onChange={(e) => formik.handleChange(e)}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  sx={{borderRadius:"20px"}}
                                  name="name_of_the_customer"
                                  value={formik.values?.name_of_the_customer}
                                />
                                {errors.name_of_the_customer &&
                                  touched.name_of_the_customer && (
                                    <Box
                                      Typography="small"
                                      className="float-left text-danger"
                                    >
                                      {errors.name_of_the_customer}
                                    </Box>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Phone No
                                </Typography>
                                <OutlinedInput
                                    placeholder="Phone Number"
                                    onChange={(e) => formik.handleChange(e)}
                                    onBlur={(e) => {
                                      handleChangePhone(e);
                                    }}
                                    name="phone_number"
                                    sx={{borderRadius:"20px"}}
                                  />
                                  {errors.phone_number &&
                                    touched.phone_number && (
                                      <Box
                                        Typography="small"
                                        className="float-left text-danger"
                                      >
                                        {errors.phone_number}
                                      </Box>
                                    )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl className="w-100">
                                <Typography component="label">Email</Typography>
                                <OutlinedInput
                                  placeholder="Email Id"
                                  onChange={(e) => formik.handleChange(e)}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  name="email_id"
                                  value={formik.values?.email_id}
                                  sx={{borderRadius:"20px"}}
                                />
                                {errors.email_id && touched.email_id && (
                                  <Box
                                    Typography="small"
                                    className="float-left text-danger"
                                  >
                                    {errors.email_id}
                                  </Box>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <FormControl className="form_control" fullWidth>
                                <Typography component="label">
                                  Order Type
                                </Typography>
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="select viewmore-dpdown"
                                  defaultValue="0"
                                  onChange={(e) =>
                                    handleChangeOrderType(e.target.value)
                                  }
                                  sx={{borderRadius:"20px"}}
                                >
                                  <MenuItem value="0">Select</MenuItem>
                                  {deliveryTypes?.map((item, index) => {
                                    return (
                                      <MenuItem value={item.del_type_id}>
                                        {item.del_type}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            {deliveryType === 5 && (
                              <>
                            <Grid item xs={12} md={5}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Address
                                </Typography>
                                <OutlinedInput
                                  placeholder=""
                                  onChange={(e) => formik.handleChange(e)}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  name="cust_address"
                                  components="textarea"
                                  minRows={5}
                                  multiline={true}
                                  value={formik.values?.cust_address}
                                  sx={{borderRadius:"20px"}}
                                />
                                {errors.cust_address && touched.cust_address && <Box Typography="small" className="float-left text-danger">{errors.cust_address}</Box>}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Pincode
                                </Typography>
                                <OutlinedInput
                                  placeholder="Pincode"
                                  name="Pincode"
                                  onChange={(e) => formik.handleChange(e)}
                                  onBlur={(e) => {
                                    handleBlur(e);
                                  }}
                                  value={formik.values?.Pincode}
                                  sx={{borderRadius:"20px"}}
                                />
                                {errors.Pincode && touched.Pincode && <Box Typography="small" className="float-left text-danger">{errors.Pincode}</Box>}
                              </FormControl>
                            </Grid>
                            </>
                            )}
                            {deliveryType === 9 && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <FormControl className="w-100">
                                    <Typography component="label">
                                      Date And Time
                                    </Typography>
                                    <OutlinedInput
                                      type="datetime-local"
                                      name="DateTime"
                                      onChange={(e) => formik.handleChange(e)}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                      }}
                                      value={formik.values?.DateTime}
                                      sx={{borderRadius:"20px"}}
                                    />
                                    {errors.date && touched.date && <Box Typography="small" className="float-left text-danger">{errors.date}</Box>}
                                  </FormControl>
                                </Grid>
                              </>
                            )}
                            {deliveryType === 10 && tableNumberId !== "" && (
                              <>
                                <Grid item xs={12} md={4}>
                                  <Box>
                                  <Typography component="label">
                                    Table No:
                                  </Typography>
                                  <Typography component="h5">{tableNumberId ? tableNumberId : ""}</Typography>
                                  </Box>
                                </Grid>
                              </>
                            )}
                          </Grid>
                          <Box className="p-15 text-right">
                            <Button variant='outlined' color='error' className='retail-btn mr-15' type='submit'>Submit</Button>
                        </Box>
                          </form>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                          {/* <Box
                            className="flex flex-between order-values sub-order"
                            mt={2}
                          >
                            {deliveryTypes?.map((item, index) => {
                              console.log("ordertypeeee", index);
                              return (
                                <Box
                                  className={
                                    item.del_type_id == posDeliverTypeId
                                      ? "information-div active"
                                      : "information-div"
                                  }
                                  onClick={() => {
                                    setDoorIndex(index);
                                    setDeliveryIds(item.del_type_id);
                                  }}
                                >
                                  <Box component="img" src={item.icon} />
                                  <Box>
                                    <Typography component="h5">
                                      {item.del_type}
                                    </Typography>
                                    <Typography component="h5">
                                      {item.del_type_id === 10
                                        ? "Table Number: " +
                                          (tableNumberId ? tableNumberId : "")
                                        : ""}
                                    </Typography>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box> */}
                          <Box
                            mt={1}
                            sx={{
                              borderBottom: 1,
                              borderColor: "divider",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Tabs
                              value={values}
                              onChange={handleChanges}
                              aria-label="basic tabs example"
                              className="pos-billing"
                            >
                              {/* <Tab label="TABLE 1  #388299" {...a11yProps(0)} />
                              <Tab label="TABLE 2  #647873" {...a11yProps(1)} />
                              <Tab label="TABLE 3  #884849" {...a11yProps(2)} /> */}
                              {pendingInvoices?.map((item, index) => {
                                return (
                                  <div style={{ position: "relative" }}>
                                    <Tab
                                      label={
                                        item.table_name +
                                        "\n #" +
                                        item.invoice_id
                                      }
                                      {...a11yProps(index)}
                                      onClick={() => {
                                        setTabIndex(index);
                                        tabChange(item);
                                        dispatch(setTableNumber(item.table_id));
                                        getCartItemProductid(item.invoice_id);
                                      }}
                                      className={
                                        tabIndex === index ? "Mui-selected" : ""
                                      }
                                    />

                                    <HighlightOffIcon
                                      style={{
                                        position: "absolute",
                                        top: "-12px",
                                        right: 0,
                                        fontSize: "17px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        POSAbortedInvoicecartDetails(item);
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Tabs>
                            {/* <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <FormControl className="w-100 me-2">
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="select viewmore-dpdown"
                                  defaultValue="0"
                                  onChange={(e)=>getTableNumber(e.target.value)}
                                >
                                  <MenuItem value="0">
                                    Table No
                                  </MenuItem>
                                  {/* <MenuItem value="part">
                                    Door Delivery
                                  </MenuItem>
                                  <MenuItem value="full">Curb Side</MenuItem> */}
                            {/* {tableNames?.map((item) => {
                                    return (
                                      <MenuItem value={item.table_id}>
                                          {item.table_name}
                                          </MenuItem>
                                    );
                                  })} */}
                            {/* <MenuItem value="freelance">
                                    Table Number 1
                                  </MenuItem>
                                  <MenuItem value="tabletwo">
                                    Table Number 2
                                  </MenuItem>
                                  <MenuItem value="tablethree">
                                    Table Number 3
                                  </MenuItem>
                                  <MenuItem value="tablefour">
                                    Table Number 4
                                  </MenuItem> */}
                            {/* </Select>
                              </FormControl>

                              <FormControl
                                className="form_control me-2"
                                fullWidth
                              >
                                <OutlinedInput
                                  className="c-height"
                                  placeholder="Name / Number"
                                  onBlur={(e) => handleChangePhone(e)}
                                />
                              </FormControl>
                              <Button
                                className="btn btn-outline-primary"
                                onClick={() => getSearchPhoneDetails()}
                              >
                                Search
                              </Button>
                            </Box> */}
                          </Box>
                          {
                            // console.log('pendingInvoices',pendingInvoices)
                          }
                          {pendingInvoices?.length > 0 ? (
                            pendingInvoices?.map((item, index) => {
                              return (
                                <TabPanel
                                  value={values}
                                  index={index}
                                  className="bg-white  p-15"
                                >
                                  {/* <Box className="w-100 flex-between" mb={2} mt={1}>
                              <Typography
                                component="h4"
                                className="tab-sub-title"
                              >
                                #INVOICE23009
                              </Typography>
                              <Typography component="label">
                                5/11/2023 | 19:2
                              </Typography>
                            </Box>
                            <Box className="w-100 flex align-center" mb={2}>
                              <FormControl className="flex">
                                <Box className="code">+91</Box>
                                <OutlinedInput
                                  placeholder="9876543210"
                                  className="custom-ml"
                                />
                              </FormControl>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                mb={3}
                                className="retail-btn ml-20 height-22"
                                onClick={handleClose}
                              >
                                Search
                              </Button>
                              <Box
                                ml={4}
                                src={addPerson}
                                component="img"
                                className="add-img"
                                onClick={() => ref1.current.log()}
                              />
                            </Box> */}
                                  <Box className="w-100 ">
                                    <Box
                                      paddingX={5}
                                      className="flex flex-between order-values"
                                    >
                                      <Box className="information-div">
                                        <Box component="img" src={customer} />
                                        <Box>
                                          <Typography component="label">
                                            Name
                                          </Typography>
                                          <Typography component="h5">
                                            {customerData?.name_of_the_customer}
                                          </Typography>
                                          {/* <FormControl
                                      className="form_control"
                                      fullWidth
                                    >
                                      <Typography component="label">
                                        Name
                                      </Typography>
                                      <OutlinedInput value="Sri Srinivasa" />
                                    </FormControl> */}
                                        </Box>
                                      </Box>
                                      <Box className="information-div">
                                        <Box component="img" src={mobile} />
                                        <Box>
                                          <Typography component="label">
                                            Mobile
                                          </Typography>
                                          <Typography component="h5">
                                            {customerData?.phone_number}
                                          </Typography>
                                        </Box>
                                      </Box>

                                      {/* <Box
                                  ml={4}
                                  src={addPerson}
                                  component="img"
                                  className="add-img"
                                  onClick={() => ref1.current.log()}
                                />*/}
                                    </Box>
                                  </Box>

                                  {/* <Box className="w-100" mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={ItemColumns}
                                data={orderItemData}
                              />
                            </Box> */}
                                  <Box className="w-100" mt={2}>
                                    {/* <MemberTable
                                options={itemOptions}
                                columns={ItemColumns}
                                data={orderItemData}
                              /> */}
                                    <MemberTable
                                      options={itemOptions}
                                      columns={cartItemColumns}
                                      data={cartitem ? cartitem : []}
                                    />
                                  </Box>
                                  {/* <Box className="w-100 kot-seperator" mt={2}>
                              <MemberTable
                                options={itemOptions1}
                                columns={ItemColumns}
                                data={orderItemData1}
                              />
                            </Box> */}
                                  <Grid container spacing={2} mt={1}>
                                    <Grid md={12} item>
                                      <Box
                                        className="d-flex"
                                        justifyContent={"center"}
                                      >
                                        <Button
                                          className="btn btn-outline-primary"
                                          onClick={() => {
                                            generateKot();
                                          }}
                                        >
                                          Generate KOT
                                        </Button>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  <Box
                                    className="flex-between bg-light px-10"
                                    mt={2}
                                  >
                                    <Typography component="p">
                                      No Of Items :{" "}
                                      {cartTotals
                                        ? cartTotals[0].no_of_items
                                        : ""}
                                    </Typography>
                                    <Typography component="p" className="flex">
                                      Grand Total{" "}
                                      <b className="ml-20">
                                        {" "}
                                        {cartTotals
                                          ? cartTotals[0].item_final_amount
                                          : ""}
                                      </b>
                                    </Typography>
                                    <Tooltip
                                      className="tooltip"
                                      title={
                                        <React.Fragment>
                                          <Typography component="label">
                                            Sub Total -{" "}
                                            {
                                              pendingCartTaxTotals
                                                ?.cart_total?.[0]
                                                ?.total_item_price
                                            }
                                            <br />
                                          </Typography>
                                          <Typography component="label">
                                            Discount -{" "}
                                            {
                                              pendingCartTaxTotals
                                                ?.cart_total?.[0]
                                                ?.discount_amount
                                            }
                                            <br />
                                          </Typography>
                                          <Typography component="label">
                                            Total Tax -{" "}
                                            {
                                              pendingCartTaxTotals
                                                ?.cart_total?.[0]?.tax_amount
                                            }
                                            <br />
                                          </Typography>
                                          {pendingCartTaxTotals?.pending_cart_tax_totals?.map(
                                            (val) => {
                                              return (
                                                <Typography component="label">
                                                  {val?.tax_type} -{" "}
                                                  {val?.tax_amount}
                                                  <br />
                                                </Typography>
                                              );
                                            }
                                          )}
                                          {pendingCartTaxTotals?.pending_cart_fee_totals?.map(
                                            (val) => {
                                              return (
                                                <Typography component="label">
                                                  {val?.fee_type} -{" "}
                                                  {val?.fee_amount}
                                                  <br />
                                                </Typography>
                                              );
                                            }
                                          )}
                                          <Typography component="p">
                                            <b>
                                              Grand Total -{" "}
                                              {
                                                pendingCartTaxTotals
                                                  ?.cart_total?.[0]
                                                  ?.item_final_amount
                                              }
                                            </b>
                                          </Typography>
                                        </React.Fragment>
                                      }
                                      placement="left"
                                      arrow
                                    >
                                      <Box component="img" src={info} />
                                    </Tooltip>
                                  </Box>
                                  <Box mt={2} className="flex-around pos-btns">
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      className="retail-btn "
                                      onClick={() =>
                                        deleteCartDetails(item?.invoice_id)
                                      }
                                    >
                                      <Box src={clear} component="img" />
                                      <Typography component="span">
                                        {" "}
                                        Clear
                                      </Typography>
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      className="retail-btn "
                                      onClick={() => ref4.current.log()}
                                    >
                                      {" "}
                                      <Box src={discount} component="img" />
                                      <Typography component="span">
                                        Discount
                                      </Typography>
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      className="retail-btn "
                                      onClick={() => {
                                        POSInsertOrderDetails();
                                      }}
                                    >
                                      <Box src={invoice} component="img" />
                                      <Typography component="span">
                                        Checkout
                                      </Typography>
                                    </Button>
                                  </Box>
                                </TabPanel>
                              );
                            })
                          ) : (
                            <TabPanel
                              value="0"
                              index="0"
                              className="bg-white  p-15"
                            >
                              {/* <Box className="w-100 flex-between" mb={2} mt={1}>
                             <Typography
                               component="h4"
                               className="tab-sub-title"
                             >
                               #INVOICE23009
                             </Typography>
                             <Typography component="label">
                               5/11/2023 | 19:2
                             </Typography>
                           </Box>
                           <Box className="w-100 flex align-center" mb={2}>
                             <FormControl className="flex">
                               <Box className="code">+91</Box>
                               <OutlinedInput
                                 placeholder="9876543210"
                                 className="custom-ml"
                               />
                             </FormControl>

                             <Button
                               variant="outlined"
                               color="error"
                               mt={2}
                               mb={3}
                               className="retail-btn ml-20 height-22"
                               onClick={handleClose}
                             >
                               Search
                             </Button>
                             <Box
                               ml={4}
                               src={addPerson}
                               component="img"
                               className="add-img"
                               onClick={() => ref1.current.log()}
                             />
                           </Box> */}
                              <Box className="w-100 ">
                                <Box
                                  paddingX={5}
                                  className="flex flex-between order-values"
                                >
                                  <Box className="information-div">
                                    <Box component="img" src={customer} />
                                    <Box>
                                      <Typography component="label">
                                        Name
                                      </Typography>
                                      <Typography component="h5">
                                        {customerData?.name_of_the_customer
                                          ? customerData?.name_of_the_customer
                                          : "xxxxxxx"}
                                      </Typography>
                                      {/* <FormControl
                                     className="form_control"
                                     fullWidth
                                   >
                                     <Typography component="label">
                                       Name
                                     </Typography>
                                     <OutlinedInput value="Sri Srinivasa" />
                                   </FormControl> */}
                                    </Box>
                                  </Box>
                                  <Box className="information-div">
                                    <Box component="img" src={mobile} />
                                    <Box>
                                      <Typography component="label">
                                        Mobile
                                      </Typography>
                                      <Typography component="h5">
                                        {customerData?.phone_number
                                          ? customerData?.phone_number
                                          : "xxxxxxxxxx"}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box
                                    ml={4}
                                    src={addPerson}
                                    component="img"
                                    className="add-img"
                                    onClick={() => ref1.current.log()}
                                  />
                                </Box>
                              </Box>

                              {/* <Box className="w-100" mt={2}>
                             <MemberTable
                               options={itemOptions}
                               columns={ItemColumns}
                               data={orderItemData}
                             />
                           </Box> */}
                              <Box className="w-100" mt={2}>
                                {/* <MemberTable
                               options={itemOptions}
                               columns={ItemColumns}
                               data={orderItemData}
                             /> */}
                                <MemberTable
                                  options={itemOptions}
                                  columns={cartItemColumns}
                                  data={cartitem ? cartitem : []}
                                />
                              </Box>
                              {/* <Box className="w-100 kot-seperator" mt={2}>
                             <MemberTable
                               options={itemOptions1}
                               columns={ItemColumns}
                               data={orderItemData1}
                             />
                           </Box> */}
                              <Grid container spacing={2} mt={1}>
                                <Grid md={12} item>
                                  <Box
                                    className="d-flex"
                                    justifyContent={"center"}
                                  >
                                    <Button
                                      className="btn btn-outline-primary"
                                      onClick={() => {
                                        generateKot();
                                      }}
                                    >
                                      Generate KOT
                                    </Button>
                                  </Box>
                                </Grid>
                              </Grid>
                              <Box
                                className="flex-between bg-light px-10"
                                mt={2}
                              >
                                <Typography component="p">
                                  No Of Items :{" "}
                                  {cartTotals ? cartTotals[0].no_of_items : ""}
                                </Typography>
                                <Typography component="p" className="flex">
                                  Grand Total{" "}
                                  <b className="ml-20">
                                    {" "}
                                    {cartTotals
                                      ? cartTotals[0].item_final_amount
                                      : ""}
                                  </b>
                                </Typography>
                                <Tooltip
                                  className="tooltip"
                                  title={
                                    <React.Fragment>
                                      <Typography component="label">
                                        Sub Total -{" "}
                                        {cartTotals
                                          ? cartTotals[0].total_item_price
                                          : ""}
                                        <br />
                                      </Typography>
                                      <Typography component="label">
                                        Tax -{" "}
                                        {cartTotals
                                          ? cartTotals[0].tax_amount
                                          : ""}
                                        <br />
                                      </Typography>
                                      <Typography component="label">
                                        Gst -{" "}
                                        {cartTotals
                                          ? cartTotals[0].tax_amount
                                          : ""}
                                        <br />
                                      </Typography>
                                      <Typography component="label">
                                        Discount -{" "}
                                        {cartTotals
                                          ? cartTotals[0].discount_amount
                                          : ""}
                                        <br />
                                      </Typography>
                                      <Typography component="p">
                                        <b>
                                          Grand Total -{" "}
                                          {cartTotals
                                            ? cartTotals[0].item_final_amount
                                            : ""}
                                        </b>
                                      </Typography>
                                    </React.Fragment>
                                  }
                                  placement="left"
                                  arrow
                                >
                                  <Box component="img" src={info} />
                                </Tooltip>
                              </Box>
                              <Box mt={2} className="flex-around pos-btns">
                                <Button
                                  variant="outlined"
                                  color="error"
                                  className="retail-btn "
                                  //  onClick={() => deleteCartDetails(item?.invoice_id)}
                                >
                                  <Box src={clear} component="img" />
                                  <Typography component="span">
                                    {" "}
                                    Clear
                                  </Typography>
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  className="retail-btn "
                                  onClick={() => ref4.current.log()}
                                >
                                  {" "}
                                  <Box src={discount} component="img" />
                                  <Typography component="span">
                                    Discount
                                  </Typography>
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  className="retail-btn "
                                  onClick={() => ref6.current.log()}
                                >
                                  <Box src={invoice} component="img" />
                                  <Typography component="span">
                                    Checkout
                                  </Typography>
                                </Button>
                              </Box>
                            </TabPanel>
                          )}
                          <TabPanel
                            value={values}
                            index={1}
                            className="bg-white  p-15"
                          >
                            {/* <Box className="w-100 flex-between" mb={2} mt={2}>
                              <Typography
                                component="h4"
                                className="tab-sub-title"
                              >
                                #INVOICE237714
                              </Typography>
                            </Box>
                            <Box className="w-100 flex align-center" mb={2}>
                              <FormControl className="flex">
                                <Box className="code">+91</Box>
                                <OutlinedInput placeholder="9876543210" />
                              </FormControl>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                mb={3}
                                className="retail-btn ml-20"
                                onClick={handleClose}
                              >
                                Search
                              </Button>
                              <Box
                                ml={4}
                                src={addPerson}
                                component="img"
                                className="add-img"
                              />
                            </Box> */}
                            <Box className="w-100 ">
                              <Box
                                paddingX={5}
                                className="flex flex-between order-values"
                              >
                                <Box className="information-div">
                                  <Box component="img" src={customer} />
                                  <Box>
                                    <Typography component="label">
                                      Name
                                    </Typography>
                                    <Typography component="h5">
                                      {customerData?.name_of_the_customer}
                                    </Typography>
                                    {/* <FormControl
                                      className="form_control"
                                      fullWidth
                                    >
                                      <Typography component="label">
                                        Name
                                      </Typography>
                                      <OutlinedInput value="Ganshyam" />
                                    </FormControl> */}
                                  </Box>
                                </Box>
                                <Box className="information-div">
                                  <Box component="img" src={mobile} />
                                  <Box>
                                    <Typography component="label">
                                      Mobile
                                    </Typography>
                                    <Typography component="h5">
                                      {customerData?.phone_number}
                                    </Typography>
                                  </Box>
                                </Box>

                                <Box
                                  ml={4}
                                  src={addPerson}
                                  component="img"
                                  className="add-img"
                                  onClick={() => ref1.current.log()}
                                />
                              </Box>
                            </Box>

                            <Box className="w-100" mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={ItemColumns}
                                data={orderItemData}
                              />
                              {/* <MemberTable
                                  options={itemOptions}
                                  columns={cartItemColumns}
                                  data={cartitem ? cartitem : []}
                                /> */}
                            </Box>
                            {/* <Box className="w-100 kot-seperator" mt={2}>
                              <MemberTable
                                options={itemOptions1}
                                columns={ItemColumns}
                                data={orderItemData1}
                              />
                            </Box> */}
                            <Grid container spacing={2} mt={1}>
                              <Grid md={12} item>
                                <Box
                                  className="d-flex"
                                  justifyContent={"center"}
                                >
                                  <Button className="btn btn-outline-primary">
                                    Generate KOT
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="flex-between bg-light px-10" mt={2}>
                              <Typography component="p">
                                No Of Items : 18
                              </Typography>
                              <Typography component="p" className="flex">
                                Grand Total <b className="ml-20"> $ 2980.00</b>
                              </Typography>
                              <Tooltip
                                className="tooltip"
                                title={
                                  <React.Fragment>
                                    <Typography component="label">
                                      Sub Total - $ 95.00
                                    </Typography>
                                    <Typography component="label">
                                      Tax - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Gst - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Discount - $ 15.00
                                    </Typography>
                                    <Typography component="p">
                                      <b>Grand Total - $ 15.00</b>
                                    </Typography>
                                  </React.Fragment>
                                }
                                placement="left"
                                arrow
                              >
                                <Box component="img" src={info} />
                              </Tooltip>
                            </Box>
                            <Box mt={2} className="flex-around pos-btns">
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={clear} component="img" />
                                <Typography component="span"> Clear</Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                {" "}
                                <Box src={discount} component="img" />
                                <Typography component="span">
                                  Discount
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={invoice} component="img" />
                                <Typography component="span">
                                  Checkout
                                </Typography>
                              </Button>
                            </Box>
                          </TabPanel>
                          <TabPanel
                            value={values}
                            index={2}
                            className="bg-white  p-15"
                          >
                            {/* <Box className="w-100 flex-between" mb={2} mt={2}>
                              <Typography
                                component="h4"
                                className="tab-sub-title"
                              >
                                #INVOICE23009
                              </Typography>
                            </Box>
                            <Box className="w-100 flex align-center" mb={2}>
                              <FormControl className="flex custom-ml">
                                <Box className="code">+91</Box>
                                <OutlinedInput placeholder="9876543210" />
                              </FormControl>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                mb={3}
                                className="retail-btn ml-20"
                                onClick={handleClose}
                              >
                                Search
                              </Button>
                              <Box
                                ml={4}
                                src={addPerson}
                                component="img"
                                className="add-img"
                              />
                            </Box> */}
                            <Box className="w-100 ">
                              <Box
                                paddingX={5}
                                className="flex flex-between order-values"
                              >
                                <Box className="information-div">
                                  <Box component="img" src={customer} />
                                  <Box>
                                    <Typography component="label">
                                      Name
                                    </Typography>
                                    <Typography component="h5">
                                      Vasanth
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="information-div">
                                  <Box component="img" src={mobile} />
                                  <Box>
                                    <Typography component="label">
                                      Mobile
                                    </Typography>
                                    <Typography component="h5">
                                      +91 90528 45869
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  ml={4}
                                  src={addPerson}
                                  component="img"
                                  className="add-img"
                                  onClick={() => ref1.current.log()}
                                />
                              </Box>
                            </Box>

                            <Box className="w-100" mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={ItemColumns}
                                data={orderItemData}
                              />
                            </Box>
                            {/* <Box className="w-100 kot-seperator" mt={2}>
                              <MemberTable
                                options={itemOptions1}
                                columns={ItemColumns}
                                data={orderItemData1}
                              />
                            </Box> */}
                            <Grid container spacing={2} mt={1}>
                              <Grid md={12} item>
                                <Box
                                  className="d-flex"
                                  justifyContent={"center"}
                                >
                                  <Button className="btn btn-outline-primary">
                                    Generate KOT
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="flex-between bg-light px-10" mt={2}>
                              <Typography component="p">
                                No Of Items : 18
                              </Typography>
                              <Typography component="p" className="flex">
                                Grand Total <b className="ml-20"> $ 2980.00</b>
                              </Typography>
                              <Tooltip
                                className="tooltip"
                                title={
                                  <React.Fragment>
                                    <Typography component="label">
                                      Sub Total - $ 95.00
                                    </Typography>
                                    <Typography component="label">
                                      Tax - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Gst - $ 25.00
                                    </Typography>
                                    <Typography component="label">
                                      Discount - $ 15.00
                                    </Typography>
                                    <Typography component="p">
                                      <b>Grand Total - $ 15.00</b>
                                    </Typography>
                                  </React.Fragment>
                                }
                                placement="left"
                                arrow
                              >
                                <Box component="img" src={info} />
                              </Tooltip>
                            </Box>
                            <Box mt={2} className="flex-around pos-btns">
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={clear} component="img" />
                                <Typography component="span"> Clear</Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                {" "}
                                <Box src={discount} component="img" />
                                <Typography component="span">
                                  Discount
                                </Typography>
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={handleClose}
                              >
                                <Box src={invoice} component="img" />
                                <Typography component="span">
                                  Checkout
                                </Typography>
                              </Button>
                            </Box>
                          </TabPanel>
                        </Box>
                        {/* <Box
                          className="w-100 flex flex-between order-values sub-order"
                          mt={2}
                        >
                          {deliveryTypes?.map((item, index) => {
                            return (
                              <Box
                                className={
                                  doorIndex === index
                                    ? "information-div active"
                                    : "information-div"
                                }
                                onClick={() => {
                                  setDoorIndex(index);
                                  setDeliveryIds(item.del_type_id);
                                }}
                              >
                                <Box component="img" src={item.icon} />
                                <Box>
                                  <Typography component="h5">
                                    {item.del_type}
                                  </Typography>
                                  <Typography component="h5">
                                    {item.del_type_id === 10?"Table Number: ":""}
                                  </Typography>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box> */}
                        {/* <Box sx={{ width: "100%" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                              value={values}
                              onChange={handleChanges}
                              aria-label="basic tabs example"
                              className="pos-billing"
                            > */}
                        {/* {pendingInvoices?.map((item, index) => {
                                {
                                  console.log('item', item)
                                }
                                return <Tab label={item.invoice_id} {...a11yProps(index)} />
                              })
                              } */}

                        {/* {pendingInvoices?.map((item, index) => {
                                return (
                                  <div style={{ position: "relative" }}>
                                    <Tab
                                      label={"Billing"+(index +1)+"/ #"+item.invoice_id}
                                      {...a11yProps(index)}
                                      onClick={() => {
                                        setTabIndex(index);
                                        tabChange(item);

                                        getCartItemProductid(item.invoice_id);
                                      }}
                                      className={tabIndex === index?"Mui-selected":""}
                                    />

                                    <HighlightOffIcon
                                      style={{
                                        position: "absolute",
                                        top: "-12px",
                                        right: 0,
                                        fontSize: "17px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        POSAbortedInvoicecartDetails(item);
                                      }}
                                    />
                                  </div>
                                );
                              })} */}

                        {/* <Tab label="Billing 2" {...a11yProps(1)} />
                              <Tab label="Billing 3" {...a11yProps(2)} /> */}
                        {/* </Tabs>
                          </Box>
                          {pendingInvoices ? (
                            pendingInvoices?.map((item, index) => {
                              return (
                                <TabPanel
                                  value={tabIndex}
                                  index={index}
                                  className="bg-white  p-15"
                                > */}
                        {/* <Box
                                    className="w-100 flex-between"
                                    mb={2}
                                    mt={1}
                                  >
                                    <Typography
                                      component="h4"
                                      className="tab-sub-title"
                                    >
                                      {item.invoice_id}
                                    </Typography>
                                    <Typography component="label">
                                      {
                                        dayjs(item.billing_start_time).format(
                                          "MMMM D, YYYY HH:mm A"
                                        )
                                        // format(new Date(), 'yyyy/MM/dd kk:mm:ss')
                                      }
                                    </Typography>
                                  </Box> */}
                        {/* <Box className="w-100 flex align-center" mb={2}>
                                <FormControl className="flex">
                                  <Box className="code">+91</Box>
                                  <OutlinedInput
                                    placeholder=""
                                    className="custom-ml"
                                    onBlur={(e) => handleChangePhone(e)}
                                  />
                                </FormControl>

                                <Button
                                  variant="outlined"
                                  color="error"
                                  mt={2}
                                  mb={3}
                                  className="retail-btn ml-20 height-22"
                                  onClick={() => getSearchPhoneDetails()}
                                >
                                  Search
                                </Button>
                                <Box
                                  ml={4}
                                  src={addPerson}
                                  component="img"
                                  className="add-img"
                                  onClick={() => ref7.current.log()}
                                />

                              </Box> */}

                        {/* {customerData ? (
                                    <Box className="w-100 flex flex-between order-values">
                                      <Box className="information-div">
                                        <Box component="img" src={customer} />
                                        <Box>
                                          <Typography component="label">
                                            Name / Mobile
                                          </Typography>
                                          <Typography component="h5">
                                            {customerData?.name_of_the_customer || customerData?.phone_number}
                                          </Typography>
                                        </Box>
                                      </Box> */}
                        {/* <Box className="information-div">
                                        <Box component="img" src={mobile} />
                                        <Box>
                                          <Typography component="label">
                                            Mobile
                                          </Typography>
                                          <Typography component="h5">
                                            {customerData?.phone_number}
                                          </Typography>
                                        </Box>
                                      </Box> */}
                        {/* </Box>
                                  ) : (
                                    ""
                                  )} */}

                        {/* <Box className="w-100" mt={2}>
                                    <Box mb={1} mt={1} className="w-100">
                                      <Search className="search-pos">
                                        <SearchIconWrapper>
                                          <SearchIcon />
                                        </SearchIconWrapper>
                                        <StyledInputBase
                                          placeholder="Search…"
                                          inputProps={{
                                            "aria-label": "search",
                                          }}
                                          onChange={(e) =>
                                            getCartDetails(e.target.value)
                                          }
                                        />
                                      </Search>
                                      <List>
                                        {searchMerchantItems?.map(
                                          (item, index) => {
                                            return (
                                              <ListItem
                                                key={item.product_id}
                                                style={{
                                                  background: "#eeeeee69",
                                                  marginBottom: "2px",
                                                }}
                                              >
                                                <ListItemButton
                                                  onClick={() =>
                                                    addToCart(item)
                                                  }
                                                >
                                                  <Box className="flex-between w-100">
                                                    <Box className="d-flex">
                                                      <Typography
                                                        component="p"
                                                        mr={2}
                                                      >
                                                        {
                                                          item.product_master_sku
                                                        }
                                                      </Typography>{" "}
                                                      <Box
                                                        style={{
                                                          width: "30px",
                                                        }}
                                                        src={item.product_image}
                                                        mr={2}
                                                        component="img"
                                                      />{" "}
                                                      <Typography component="p">
                                                        {item.product_name}
                                                      </Typography>
                                                    </Box>{" "}
                                                    <Button
                                                      variant="outlined"
                                                      className="retail-btn float-right"
                                                    >
                                                      Add to Cart
                                                    </Button>{" "}
                                                  </Box>
                                                </ListItemButton>
                                              </ListItem>
                                            );
                                          }
                                        )}
                                      </List>
                                    </Box>
                                    <MemberTable
                                      options={itemOptions}
                                      columns={cartItemColumns}
                                      data={cartitem ? cartitem : []}
                                    />
                                  </Box> */}
                        {/* <Box
                                  className="d-flex px-10 w-100"
                                  justifyContent={"center"}
                                  mt={2}
                                >
                                 <Button className="btn btn-outline-primary" onClick={()=>{generateKot()}}>
                                    Generate KOT
                                  </Button>
                                </Box> */}
                        {/* <Box
                                    className="flex-between bg-light px-10"
                                    mt={2}
                                  >
                                    <Typography component="p">
                                      No Of Items :{" "}
                                      {cartTotals
                                        ? cartTotals[0].no_of_items
                                        : ""}
                                    </Typography>
                                    <Typography component="p" className="flex">
                                      Grand Total{" "}
                                      <b className="ml-20">
                                        {response?.country_currency_symbol}{" "}
                                        {cartTotals
                                          ? cartTotals[0].item_final_amount
                                          : ""}{" "}
                                        /-
                                      </b>
                                    </Typography>
                                    <Tooltip
                                      className="tooltip"
                                      title={
                                        <React.Fragment>
                                          <Typography component="label">
                                            Sub Total -
                                            {response?.country_currency_symbol}{" "}
                                            {cartTotals
                                              ? cartTotals[0].total_item_price
                                              : ""}
                                          </Typography>
                                          <Typography component="label">
                                            Tax -{" "}
                                            {response?.country_currency_symbol}{" "}
                                            {cartTotals
                                              ? cartTotals[0].tax_amount
                                              : ""}
                                          </Typography>
                                          <Typography component="label">
                                            Gst -{" "}
                                            {response?.country_currency_symbol}{" "}
                                            {cartTotals
                                              ? cartTotals[0].tax_amount
                                              : ""}
                                          </Typography>
                                          <Typography component="label">
                                            Discount -
                                            {response?.country_currency_symbol}{" "}
                                            {cartTotals
                                              ? cartTotals[0].discount_amount
                                              : ""}
                                          </Typography>
                                          <Typography component="p">
                                            <b>
                                              Grand Total -{" "}
                                              {
                                                response?.country_currency_symbol
                                              }{" "}
                                              {cartTotals
                                                ? cartTotals[0]
                                                    .item_final_amount
                                                : ""}
                                            </b>
                                          </Typography>
                                        </React.Fragment>
                                      }
                                      placement="left"
                                      arrow
                                    >
                                      <Box component="img" src={info} />
                                    </Tooltip>
                                  </Box> */}
                        {/* <Box mt={2} className="flex-around pos-btns">
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      className="retail-btn "
                                      onClick={() =>
                                        deleteCartDetails(item.invoice_id)
                                      }
                                    >
                                      <Box src={clear} component="img" />
                                      <Typography component="span">
                                        {" "}
                                        Clear
                                      </Typography>
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      className="retail-btn "
                                      onClick={() => ref4.current.log()}
                                    >
                                      {" "}
                                      <Box src={discount} component="img" />
                                      <Typography component="span">
                                        Discount
                                      </Typography>
                                    </Button>
                                    <Button
                                      variant="outlined"
                                      color="error"
                                      className="retail-btn "
                                      onClick={() => {
                                        POSInsertOrderDetails();
                                      }}
                                      // onClick={() => { ref6.current.log() }}
                                    >
                                      <Box src={invoice} component="img" />
                                      <Typography component="span">
                                        Place Order
                                      </Typography>
                                    </Button>
                                  </Box> */}
                        {/* </TabPanel>
                              );
                            })
                          ) : (
                            <TabPanel
                              // value={1}
                              // index={1}
                              className="bg-white  p-15"
                            >
                              <Box className="w-100 flex-between" mb={2} mt={1}>
                                <Typography
                                  component="h4"
                                  className="tab-sub-title"
                                ></Typography>
                                <Typography component="label"></Typography>
                              </Box> */}
                        {/* <Box className="w-100 flex align-center" mb={2}>
                              <FormControl className="flex">
                                <Box className="code">+91</Box>
                                <OutlinedInput
                                  placeholder="9876543210"
                                  className="custom-ml"
                                  onBlur={(e) => handleChangePhone(e)}
                                />
                              </FormControl>

                              <Button
                                variant="outlined"
                                color="error"
                                mt={2}
                                mb={3}
                                className="retail-btn ml-20 height-22"
                                onClick={() => getSearchPhoneDetails()}
                              >
                                Search
                              </Button>
                              <Box
                                ml={4}
                                src={addPerson}
                                component="img"
                                className="add-img"
                                onClick={() => ref7.current.log()}
                              />
                            </Box> */}
                        {/* <Box
                                className="w-100 flex flex-between order-values sub-order mb-15"
                                mt={2}
                              >
                                {deliveryTypes?.map((item, index) => {
                                  return (
                                    <Box
                                      className={
                                        doorIndex === index
                                          ? "information-div active"
                                          : "information-div"
                                      }
                                      onClick={() => {
                                        setDoorIndex(index);
                                        setDeliveryIds(item.del_type_id);
                                      }}
                                    >
                                      <Box component="img" src={item.icon} />
                                      <Box>
                                        <Typography component="h5">
                                          {item.del_type}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Box> */}
                        {/* <Box className="w-100 flex flex-between order-values">
                                <Box className="information-div">
                                  <Box component="img" src={customer} />
                                  <Box>
                                    <Typography component="label">
                                      Name
                                    </Typography>
                                    <Typography component="h5">
                                      {customerData
                                        ? customerData.name_of_the_customer
                                        : "*******"}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box className="information-div">
                                  <Box component="img" src={mobile} />
                                  <Box>
                                    <Typography component="label">
                                      Mobile
                                    </Typography>
                                    <Typography component="h5">
                                      {customerData
                                        ? customerData.phone_number
                                        : "+91******"}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>

                              <Box className="w-100" mt={2}>
                                <Box mb={1} mt={1} className="w-100">
                                  <Search className="search-pos">
                                    <SearchIconWrapper>
                                      <SearchIcon />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                      placeholder="Search…"
                                      inputProps={{ "aria-label": "search" }}
                                      onChange={(e) =>
                                        getCartDetails(e.target.value)
                                      }
                                    />
                                  </Search>
                                  <List>
                                    {searchMerchantItems?.map((item, index) => {
                                      return (
                                        <ListItem
                                          key={item.product_id}
                                          style={{
                                            background: "#eeeeee69",
                                            marginBottom: "2px",
                                          }}
                                        >
                                          <ListItemButton
                                            onClick={() => addToCart(item)}
                                          >
                                            <Box className="flex-between w-100">
                                              <Box
                                                style={{ width: "30px" }}
                                                src={item.product_image}
                                                component="img"
                                              />
                                              <Typography>
                                                {item.product_name}
                                              </Typography>
                                              <Typography component="p">
                                                {item.product_quantity}
                                              </Typography>
                                              <Button variant="contained">
                                                Add To Cart
                                              </Button>
                                            </Box>
                                          </ListItemButton>
                                        </ListItem>
                                      );
                                    })}
                                  </List>
                                </Box>
                                <MemberTable
                                  options={itemOptions}
                                  columns={cartItemColumns}
                                  data={cartitem ? cartitem : []}
                                />
                                 
                              </Box>
                            
                                
                            
                              <Box
                                className="flex-between bg-light px-10"
                                mt={2}
                              >
                                <Typography component="p">
                                  No Of Items :{" "}
                                  {pendingCartItems
                                    ? pendingCartItems[0].product_qty_sold
                                    : ""}
                                </Typography>
                                <Typography component="p" className="flex">
                                  Grand Total{" "}
                                  <b className="ml-20">
                                    {" "}
                                    {pendingCartItems
                                      ? pendingCartItems[0].total_item_price
                                      : ""}{" "}
                                    /-
                                  </b>
                                </Typography>
                                <Tooltip
                                  className="tooltip"
                                  title={
                                    <React.Fragment>
                                      <Typography component="label">
                                        Sub Total - $ 95.00
                                      </Typography>
                                      <Typography component="label">
                                        Tax - $ 25.00
                                      </Typography>
                                      <Typography component="label">
                                        Gst - $ 25.00
                                      </Typography>
                                      <Typography component="label">
                                        Discount - $ 15.00
                                      </Typography>
                                      <Typography component="p">
                                        <b>Grand Total - $ 15.00</b>
                                      </Typography>
                                    </React.Fragment>
                                  }
                                  placement="left"
                                  arrow
                                >
                                  <Box component="img" src={info} />
                                </Tooltip>
                              </Box>
                              <Box mt={2} className="flex-around pos-btns">
                                <Button
                                  variant="outlined"
                                  color="error"
                                  className="retail-btn "
                                  onClick={() => deleteCartDetails()}
                                >
                                  <Box src={clear} component="img" />
                                  <Typography component="span">
                                    {" "}
                                    Clear
                                  </Typography>
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  className="retail-btn "
                                  onClick={() => ref4.current.log()}
                                >
                                  {" "}
                                  <Box src={discount} component="img" />
                                  <Typography component="span">
                                    Discount
                                  </Typography>
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  className="retail-btn "
                                  onClick={() => {
                                    POSInsertOrderDetails();
                                  }}
                                >
                                  <Box src={invoice} component="img" />
                                  <Typography component="span">
                                    Place Order
                                  </Typography>
                                </Button>
                              </Box>
                            </TabPanel>
                          )}
                        </Box> */}
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5}>
                        <Card>
                          <CardContent className="p-15">
                            <Box className="w-100 " mt={2}>
                              <MemberTable
                                options={customerOptions}
                                columns={customerColumns}
                                data={
                                  customerBasedMerchantDetails
                                    ? customerBasedMerchantDetails
                                    : []
                                }
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Card className="bill-pos">
                          <CardContent>
                            <Grid container spacing={4}>
                              {/* <Grid item md={3}>
                                <Box className="w-100">
                                  <Box
                                    src={
                                      POSCustomersAddressDetails?.profile_pic
                                        ? POSCustomersAddressDetails?.profile_pic
                                        : walmart
                                    }
                                    style={{ width: "150px" }}
                                    component="img"
                                  />
                                </Box>
                              </Grid> */}

                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Customer Name
                                  </Typography>
                                  <Typography component="p">
                                    {
                                      POSCustomersAddressDetails?.name_of_the_customer
                                    }
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Contact No
                                  </Typography>
                                  <Typography component="p">
                                    +{POSCustomersAddressDetails?.phone_number}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Email Id
                                  </Typography>
                                  <Typography component="p">
                                    {POSCustomersAddressDetails?.email_id}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={3}>
                                <Box>
                                  <Typography component="label">
                                    Address
                                  </Typography>
                                  <Typography component="p">
                                    {
                                      POSCustomersAddressDetails?.a[0]
                                        ?.cust_address
                                    }
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box className="w-100 " mt={2}>
                              <MemberTable
                                options={itemOptions}
                                columns={billColumns}
                                data={
                                  customerOrderDeatails
                                    ? customerOrderDeatails
                                    : []
                                }
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={2} className="w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent className="p-15">
                            <Box className="flex w-100 flex-between" mt={2}>
                              <FormControl className="flex">
                                <Typography component="label"> Show</Typography>
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="ml-20 location-select ws-80"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="locations"
                                >
                                  <MenuItem value="locations">10</MenuItem>
                                  <MenuItem>One</MenuItem>
                                  <MenuItem>Two</MenuItem>
                                  <MenuItem>Three</MenuItem>
                                </Select>
                              </FormControl>
                              <Box>
                                <OutlinedInput
                                  placeholder="Search"
                                  className="search w-130"
                                  onChange={(e) => {
                                    searchOrders(e.target.value);
                                  }}
                                />

                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="ml-20 location-select w-130"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="All"
                                  onChange={(e) => {
                                    getFilterByStatus(e.target.value);
                                  }}
                                >
                                  <MenuItem value="All">All</MenuItem>
                                  <MenuItem value="completed">
                                    completed
                                  </MenuItem>
                                  <MenuItem value="pending">pending</MenuItem>
                                </Select>
                              </Box>
                            </Box>

                            <Box className="w-100 " mt={2}>
                              <MemberTable
                                options={orderOptions}
                                columns={orderColumns}
                                data={orderData ? orderData : []}
                              />
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardContent className="p-15">
                            <Box className="w-100 flex-between" mb={2} mt={2}>
                              <Typography component="h4">
                                #
                                {orderCustomerDetails &&
                                  orderCustomerDetails[0].invoice_id}
                              </Typography>
                              <Typography component="h4">
                                {orderCustomerDetails &&
                                  orderCustomerDetails[0].order_date}
                              </Typography>
                            </Box>

                            <Box className="w-100 flex flex-between order-values orders">
                              <Box className="information-div">
                                <Box component="img" src={customer} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography component="h5">
                                    {orderCustomerDetails &&
                                      orderCustomerDetails[0]
                                        .name_of_the_customer}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div align-center">
                                <Box
                                  component="img"
                                  src={mobile}
                                  className="border-50"
                                />
                                <Box>
                                  <Typography component="label">
                                    Mobile
                                  </Typography>
                                  <Typography component="h5">
                                    {orderCustomerDetails &&
                                      orderCustomerDetails[0].phone_number}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div align-center">
                                <Box
                                  component="img"
                                  src={mail}
                                  className="border-50"
                                />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography component="h5">
                                    {orderCustomerDetails &&
                                      orderCustomerDetails[0].email_id}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className="information-div align-center">
                                <Box
                                  component="img"
                                  src={mail}
                                  className="border-50"
                                />
                                <Box>
                                  <Typography component="label">
                                    Delivery Type
                                  </Typography>
                                  {/* {console.log(
                                    "deliveryAddressDetails",
                                    deliveryAddressDetails
                                  )} */}
                                  <Typography component="h5">
                                    {orderCustomerDetails &&
                                      orderCustomerDetails[0].del_type}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              mt={2}
                              mb={2}
                              className="flex-between pos-btns pos-orders-btns"
                            >
                              <Grid container spacing={2}>
                                <Grid item md={3}>
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    className="retail-btn "
                                    onClick={handleClose}
                                  >
                                    <Box src={print} component="img" />
                                    <Typography component="span">
                                      {" "}
                                      Print Invoice
                                    </Typography>
                                  </Button>
                                </Grid>
                              </Grid>
                              {/* <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                                onClick={() => ref18.current.log()}
                              >
                                {" "}
                                <Box src={edit} component="img" />
                                <Typography component="span">Submit</Typography>
                              </Button> */}
                              {/* <Button
                                variant="outlined"
                                color="error"
                                className="retail-btn "
                              >
                                <Typography component="span">Add</Typography>
                              </Button> */}
                            </Box>
                            <Box
                              className="member-table scroll-editabletable"
                              mt={2}
                            >
                              {/* <MemberTable options={itemOptions} columns={ItemColumns} data={itemData} /> */}

                              {/* <EditTable defaultData={defaultData?defaultData :[]} /> */}
                              {/* <EditTable defaultData={defaultData} /> */}
                              <MemberTable
                                options={itemOptions}
                                columns={editCartItemColumns}
                                data={cartItemOrders ? cartItemOrders : []}
                              />
                            </Box>
                            <Box className="grand-total">
                              <Typography component="p">
                                No Of Items :
                                {cartTotalsOrders
                                  ? cartTotalsOrders[0].no_of_items
                                  : ""}
                              </Typography>
                              <Box>
                                <Typography component="p">
                                  Sub Total :{" "}
                                  {cartTotalsOrders
                                    ? cartTotalsOrders[0].total_item_price
                                    : ""}
                                  <br />
                                </Typography>
                                <Typography component="p">
                                  Discount:{" "}
                                  {cartTotalsOrders
                                    ? cartTotalsOrders[0].discount_amount
                                    : ""}
                                </Typography>
                                <Typography component="p">
                                  Tax:{" "}
                                  {cartTotalsOrders
                                    ? cartTotalsOrders[0].tax_amount
                                    : ""}
                                </Typography>
                                <Typography component="p">
                                  Grand Total :{" "}
                                  {cartTotalsOrders
                                    ? cartTotalsOrders[0].item_final_amount
                                    : ""}
                                </Typography>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Box>
              </Grid>
            </Box>

            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
        <ProfileModal ref={ref} />
        <CustomerDetailsModal
          ref={ref1}
          setDeliveryIds={setDeliveryIds}
          tableNames={tableNames}
          getSearchPhoneDetails={getSearchPhoneDetails}
        />
        <DeliveryModal
          ref={ref5}
          customerPhoneDetails={customerPhoneDetails}
          getSearchPhoneDetails={getSearchPhoneDetails}
          deliveryAddressDetails={deliveryAddressDetails}
          deliveryTypes={deliveryTypes}
        />
        <CurbModal
          ref={ref9}
          customerPhoneDetails={customerPhoneDetails}
          getSearchPhoneDetails={getSearchPhoneDetails}
          deliveryAddressDetails={deliveryAddressDetails}
          deliveryTypes={deliveryTypes}
        />
        <StorePickupModal
          tableNames={tableNames}
          posTableNumber={posTableNumber}
          ref={ref10}
          getSearchPhoneDetails={getSearchPhoneDetails}
          deliveryAddressDetails={deliveryAddressDetails}
          deliveryTypes={deliveryTypes}
        />
        <InStoreModal
          ref={ref8}
          deliveryAddressDetails={deliveryAddressDetails}
          deliveryTypes={deliveryTypes}
        />
        <DiscountModal ref={ref4} specialDiscountNames={specialDiscountNames} />
        <ScanModal ref={ref15} />
        <InvoiceDetailsModal
          ref={ref6}
          orderId={orderId}
          customerData={customerData}
          customerId={customerId}
          getCartItemProductid={getCartItemProductid}
          getPosPendingInvoices={getPosPendingInvoices}
        />
        <EditProductModal ref={ref18} />
        <CustomerInvoiceDetailsModal ref={ref19} />
      </Box>
    </>
  );
}
