import React, { useImperativeHandle, forwardRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Grid, FormControl, OutlinedInput, Divider } from '@mui/material';
import test from '../src/images/test.jpg';
import dmart from '../src/images/dmart.jpg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const RetaileModal = forwardRef((props, ref4) => {
    const [open, setOpen] = React.useState(false);
    const [passwd, setPasswd] = React.useState(false);
    const handlePassword = () => {
        setPasswd(passwd => !passwd)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const showSwalWithLink = () => {
        MySwal.fire({
            icon: 'error',
            text: "All Fields Are Mandatory",
        });
    };

    useImperativeHandle(ref4, () => ({
        log() {
            handleOpen();
            // formik.setTouched({}, false);
        }
    }));


    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal'
            >
                <Box sx={{ ...style }} className='profile-modal'>
                    <Grid container>
                        <Grid item xs={12} className="profile-header p-15" >

                            <Typography id="parent-modal-title" component="h4">Profile</Typography>
                            <CloseIcon onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <Divider></Divider>

                    <Grid container className='details-div retaile-div p-15' columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4}>
                            <Box>
                                <Typography component="p" className='details-p'>MERCHANT DETAILS</Typography>
                                <Box component="img" src={dmart} />
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4} mt={3}>
                            <Box className="sub-details">
                                <Box>
                                    <Typography component="p">Merchant Name</Typography>
                                    <Typography component="p">Sri Srinivas Groceries</Typography>
                                </Box>
                                <Box>
                                    <Typography component="p">Merchant Type</Typography>
                                    <Typography component="p">Groceries Store</Typography>
                                </Box>
                                <Box>
                                    <Typography component="p">Email
                                    </Typography>
                                    <Typography component="p">Prasad@gmail.com</Typography>
                                </Box>
                                <Box>
                                    <Button variant="outlined" color="error" className='retail-btn' onClick={handlePassword}>Change Password</Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4} mt={3}>
                            <Box className="sub-details">
                                <Box>
                                    <Typography component="p">Contact No
                                    </Typography>
                                    <Typography component="p">+91-9638527410</Typography>
                                </Box>
                                <Box>
                                    <Typography component="p">City</Typography>
                                    <Typography component="p">Visakhapatnam</Typography>
                                </Box>
                                 
                                <Box>
                                    <Typography component="p">GST Number</Typography>
                                    <Typography component="p">2583691470</Typography>
                                </Box>
                                <Box>
                                    <Button variant="outlined" color="error" className='retail-btn' onClick={handlePassword}>Bank Information</Button>
                                </Box>
                            </Box>
                        </Grid>
                        

                    </Grid>
                    {
                        passwd ? <>
                            <Divider mt={2} component="hr"></Divider>
                            <Box className="update-div p-15">
                                <Typography>CHANGE PASSWORD</Typography>
                                <Button onClick={showSwalWithLink} variant="outlined" color="error">Update</Button>
                            </Box>
                            <Grid className="profile-password p-15">

                                <Grid className="change_password" container columns={{ xs: 4, sm: 8, md: 12 }}>
                                    <Grid item xs={2} sm={4} md={4}>
                                        <Typography component="label">Password</Typography>
                                        <FormControl >
                                            <OutlinedInput placeholder="Password" />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2} sm={4} md={4}>
                                        <Typography component="label">New Password</Typography>
                                        <FormControl >
                                            <OutlinedInput placeholder="New Password" />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2} sm={4} md={4}>
                                        <Typography component="label">Confirm Password</Typography>
                                        <FormControl >
                                            <OutlinedInput placeholder="Confirm Password" />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </> : ''
                    }
                </Box>
            </Modal>
        </div>
    );
});

export default RetaileModal;