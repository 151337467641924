import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  FormControlLabel,
  Divider,
} from "@mui/material";
import ProfileModal from "../ProfileModal";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import timerimg from "../images/timer.png";
import statusicon from "../images/status-ico.png";
import MemberTable from "../Customer/MemberTable";
import rightArrow from "../images/right-arrow.png";
import leftArrow from "../images/left-arrow.png";
import rightArrowattached from "../images/right-arrow-connection.svg";
import leftarrowconection from "../images/left-arrow-connection.svg";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState("Billing");
  const [alignment, setAlignment] = React.useState("left");
  const [searchBtn, setSearchBtn] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([
    { shiftname: "Late Night", startTime: "23.01", endTime: "06:59" },
  ]);

  const changeTitle = (title) => {
    setPos(title);
  };
  const MySwal = withReactContent(Swal);

  const driverData = [];

  const showSwalWithLink = () => {
    MySwal.fire({
      icon: "error",
      text: "Please Select On Start & End Dates",
    });
  };

  const pcData = [
    [
      <Typography component="p" className="item-name-table-data">
        Veg Spring Rolls and Rolls
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Anderson
      </Typography>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Garlic French Fries
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        James
      </Typography>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Sweet Potato Chips
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Simmons
      </Typography>,
    ],
  ];

  const pcData1 = [
    [
      <Typography component="p" className="item-name-table-data">
        Onion Rings
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Done
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        -
      </Typography>,
      // <Button
      //   className="btn-assign-table-data"
      //   onClick={() => ref2.current.log()}
      // >
      //   Assign
      // </Button>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Veggie Chillie Cheese Nachos
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Not Assigned
      </Typography>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Chicken Shawarma
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Not Assigned
      </Typography>,
    ],
  ];

  const pcData2 = [
    [
      <Typography component="p" className="item-name-table-data">
        Chicken Tacos
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Live
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Swez
      </Typography>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Parmesan Chicken
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Live
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Patrik
      </Typography>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Grilled Salmon
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        Live
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Simmons
      </Typography>,
    ],
  ];

  const pcData3 = [
    [
      <Typography component="p" className="item-name-table-data">
        BBQ Turkey
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Not Assigned
      </Typography>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Fried Cat Fish
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Not Assigned
      </Typography>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Lobster
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
      <Typography component="p" className="item-status-table-data">
        New
      </Typography>,
      <Typography component="p" className="item-status-table-data">
        Not Assigned
      </Typography>,
    ],
  ];

  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    responsive: "scrollMaxHeight",
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const pcColumns = [
    { name: "Item Name", options: { filterOptions: { fullWidth: true } } },
    "QTY",
    "Status",
    "Assigned",
  ];

  const pcColumnslatest = [
    { name: "Item Name", options: { filterOptions: { fullWidth: true } } },
    "QTY",
  ];
  const pcDataLatest = [
    [
      <Typography component="p" className="item-name-table-data">
        BBQ Turkey
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Fried Cat Fish
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Lobster
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
    ],
  ];

  const pcDataLatest1 = [
    [
      <Typography component="p" className="item-name-table-data">
        Onion Rings
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Veggie Chillie Cheese Nachos
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Chicken Shawarma
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
    ],
  ];

  const pcDataLatest2 = [
    [
      <Typography component="p" className="item-name-table-data">
        Chicken Tacos
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Parmesan Chicken
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Grilled Salmon
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
    ],
  ];

  const pcDataLatest3 = [
    [
      <Typography component="p" className="item-name-table-data">
        BBQ Turkey
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Fried Cat Fish
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">2</Typography>
      </Box>,
    ],
    [
      <Typography component="p" className="item-name-table-data">
        Lobster
      </Typography>,
      <Box className="item-count-table-data">
        <Typography component="p">1</Typography>
      </Box>,
    ],
  ];

  const [value, setValue] = React.useState(1);

  const [values, setValues] = React.useState(0);
  const [itemValue, setIemValue] = React.useState(0);
  const handleChangeItems = (event, newValue) => {
    setIemValue(newValue);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChanges = (event, newValue) => {
    setValues(newValue);
  };
  const [calenderValue, setCalenderValue] = React.useState(0);
  const handleChangeCalender = (event, newValue) => {
    setCalenderValue(newValue);
  };
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();
  const ref8 = useRef();
  const ref9 = useRef();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [personName, setPersonName] = useState([]);

  const handleChangeDropdown = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Box component="main" className="main-section kot-screens">
        <Grid container>
          <Grid item xs="12">
            {/* <Card xs="11" className="custom_card"> */}
            <Grid container className="main-breadcrumb">
              <Grid xs="12">
                <Box className="top-bredcrum custom-padding">
                  {/* <Box src={logo} className="main-logo" component="img" /> */}
                  <Typography component="p" className="kot-logo">
                    KOT Automation |{" "}
                    <span className="screen-name">Dashboard</span>
                  </Typography>

                  <Box className="icons bredcum-icons">
                    <Button variant="filled" className="invite-supplier-btn">
                      Signout
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2} xs={12}>
                <Box className="sku-list-card">
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Accordion
                        className="single-accordion"
                        defaultExpanded={true}
                      >
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="kot-accordian-head"
                        >
                          STARTER
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Veg Manchuria
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    4
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Kebab Platter
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    2
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Paneer 65
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    3
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Veg Spring Rolls
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    4
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item md={12}>
                      <Accordion
                        className="single-accordion"
                        defaultExpanded={true}
                      >
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="kot-accordian-head"
                        >
                          MAIN COURSE
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Chicken Biryani
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    4
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Mutton Biryani
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    2
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Chicken Fried Rice
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    3
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Egg Fried Rice
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    4
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item md={12}>
                      <Accordion
                        className="single-accordion"
                        defaultExpanded={true}
                      >
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="kot-accordian-head"
                        >
                          COMBO
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Biryani Combo
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    4
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid item md={12}>
                      <Accordion
                        className="single-accordion"
                        defaultExpanded={true}
                      >
                        <AccordionSummary
                          expandIcon={
                            <svg
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="ArrowDropUpIcon"
                            >
                              <path d="m7 16 4-7 4 7z"></path>
                            </svg>
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="kot-accordian-head"
                        >
                          BEVERAGES
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <Box className="dashboard-item-count-block">
                                <Typography
                                  component="p"
                                  className="dashboard-item-name"
                                >
                                  Blue Lagoon
                                </Typography>
                                <Box>
                                  <Typography
                                    component="p"
                                    className="dashboard-item-count"
                                  >
                                    4
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={10} xs={12}>
                <Box className="p-right-block">
                  <Grid container spacing={2} mt={1}>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="analytics-block">
                        <Typography component="p" className="analytics-head">
                          Total Orders
                        </Typography>
                        <Typography component="p" className="analytics-count">
                          30
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="analytics-block">
                        <Typography component="p" className="analytics-head">
                          New
                        </Typography>
                        <Typography component="p" className="analytics-count">
                          30
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="analytics-block">
                        <Typography component="p" className="analytics-head">
                          Completed
                        </Typography>
                        <Typography component="p" className="analytics-count">
                          30
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="analytics-block">
                        <Typography component="p" className="analytics-head">
                          Inprogress
                        </Typography>
                        <Typography component="p" className="analytics-count">
                          30
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="analytics-block">
                        <Typography component="p" className="analytics-head">
                          Cancelled
                        </Typography>
                        <Typography component="p" className="analytics-count">
                          30
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid
                      item
                      md={3}
                      className="attach-arrow-custom-width-dashboard"
                    >
                      <Box className="dashboard-order-card">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name"
                            >
                              Ravi Kiran
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value"
                              >
                                New
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              12:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData}
                          />
                        </Box>
                      </Box>
                      {/* <Box>
                          <Box component="img" src={rightArrow}></Box>
                        </Box> */}
                    </Grid>
                    <Grid
                      item
                      md={3}
                      className="attach-arrow-custom-width-dashboard"
                    >
                      <Box className="dashboard-order-card">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name"
                            >
                              Soumya
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value"
                              >
                                Completed
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              01:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData1}
                          />
                        </Box>
                      </Box>
                      {/* <Box>
                          <Box component="img" src={rightArrow}></Box>
                        </Box> */}
                    </Grid>
                    <Grid
                      item
                      md={3}
                      className="attach-arrow-custom-width-dashboard"
                    >
                      <Box className="dashboard-order-card">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name"
                            >
                              Sireesha
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value"
                              >
                                Delayed
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              10:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData2}
                          />
                        </Box>
                      </Box>
                      {/* <Box>
                          <Box component="img" src={rightArrow}></Box>
                        </Box> */}
                    </Grid>
                    <Grid
                      item
                      md={3}
                      className="attach-arrow-custom-width-dashboard"
                    >
                      <Box className="dashboard-order-card">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name"
                            >
                              Mahesh
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value"
                              >
                                Inprogress
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              12:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData3}
                          />
                        </Box>
                      </Box>
                      {/* <Box className="right-connection-arrow">
                          <Box component="img" src={rightArrowattached}></Box>
                        </Box> */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item md={2} className="cards-custom-width">
                      {/* <Box>
                          <Box component="img" src={leftarrowconection}></Box>
                        </Box> */}
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn"
                            >
                              Sujith
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                Inprogress
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              12:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData3}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      {/* <Box>
                          <Box component="img" src={leftArrow}></Box>
                        </Box> */}
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn"
                            >
                              Sandeep
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                Delayed
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              10:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData2}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      {/* <Box>
                          <Box component="img" src={leftArrow}></Box>
                        </Box> */}
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn"
                            >
                              Phani Kumar
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                Completed
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              01:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData1}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      {/* <Box>
                          <Box component="img" src={leftArrow}></Box>
                        </Box> */}
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn"
                            >
                              Anil Kumar
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                New
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              12:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      {/* <Box>
                          <Box component="img" src={leftArrow}></Box>
                        </Box> */}
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn"
                            >
                              Harish
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                Completed
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              01:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData1}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} mt={1}>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn"
                            >
                              Srinu
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                Inprogress
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              12:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData3}
                          />
                        </Box>
                      </Box>
                      {/* <Box>
                          <Box component="img" src={rightArrow}></Box>
                        </Box> */}
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn"
                            >
                              Vaishnavi
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                Delayed
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              10:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData2}
                          />
                        </Box>
                      </Box>
                      {/* <Box>
                          <Box component="img" src={rightArrow}></Box>
                        </Box> */}
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn"
                            >
                              Swetha
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                Completed
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              01:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData1}
                          />
                        </Box>
                      </Box>
                      {/* <Box>
                          <Box component="img" src={rightArrow}></Box>
                        </Box> */}
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn"
                            >
                              Ramya
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                New
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              12:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData}
                          />
                        </Box>
                      </Box>
                      {/* <Box>
                          <Box component="img" src={rightArrow}></Box>
                        </Box> */}
                    </Grid>
                    <Grid item md={2} className="cards-custom-width">
                      <Box className="dashboard-order-card second-row-oc">
                        <Box className="dashboard-order-card-top">
                          <Box>
                            <Typography
                              component="p"
                              className="dashboard-customer-name second-row-cn "
                            >
                              Chaitanya
                            </Typography>
                            <Typography
                              component="p"
                              className="dashboard-sku-id second-row-skuid"
                            >
                              #699595
                            </Typography>
                          </Box>
                          <Box className="dashboard-status-block">
                            <Box component="img" src={statusicon}></Box>
                            <Box marginLeft={1}>
                              <Typography
                                component="p"
                                className="status-heading second-row-sh"
                              >
                                Status
                              </Typography>
                              <Typography
                                component="p"
                                className="status-value second-row-sv"
                              >
                                Completed
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="dashboard-timer-block">
                            <Box
                              component="img"
                              src={timerimg}
                              marginRight={1}
                            ></Box>
                            <Typography className="timer-value" component="p">
                              01:30
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="customized-table-block">
                          <MemberTable
                            columns={pcColumns}
                            options={pcOptions}
                            data={pcData1}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            {/* </Card> */}
          </Grid>
        </Grid>
        {/* <Toolbar /> */}
      </Box>
    </>
  );
}
