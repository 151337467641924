import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import MemberTable from "../Customer/MemberTable";
import api from "../Api";
import withReactContent from "sweetalert2-react-content";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { addEditTax } from "../Validation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddressGeocoder = forwardRef((props, ref10) => {
  const [showState, setShowState] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [segmentIdList, setSegmentIdList] = React.useState([]);
  const handleSegmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setSegmentIdList(
      // On autofill we get a stringified value.
      value
    );
  };
  const [address, setAddress] = useState('');
  const [locationDetails, setLocationDetails] = useState(null);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const inputRef = useRef(null);
  useEffect(() => {
    // load the Google Maps JavaScript API library
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAMWSr2YSC6925JdAvbRyfjaiRsF8rPxA4&libraries=places`;
    script.async = true;
    script.defer = true;
    script.addEventListener('load', () => {
      // initialize the Autocomplete object
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
      setAutocomplete(autocomplete);
    });
    document.body.appendChild(script);
  
    // clean up the script tag
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const handleGeocode = () => {
    // Create a Geocoder instance
    const geocoder = new window.google.maps.Geocoder();

    // Perform geocoding with the provided address
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const addressDetails = results[0].address_components;
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();

        // Initialize variables for address components
        let locality = '';
        let sublocality = '';
        let pincode = '';
        let city = '';
        console.log('address',address);
        console.log('results',results);

          console.log('addressDetails',addressDetails);
        // Extract address details
        addressDetails.forEach((component) => {
          const types = component.types;
          if (types.includes('locality')) {
            locality = component.long_name;
          } else if (types.includes('sublocality')) {
            sublocality = component.long_name;
          } else if (types.includes('postal_code')) {
            pincode = component.long_name;
          } else if (types.includes('administrative_area_level_2')) {
            city = component.long_name;
          }
        });

        setLocationDetails({
          locality,
          sublocality,
          pincode,
          longitude,
          latitude,
          city,
        });
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };
  const [subSegmentIdList, setSubSegmentIdList] = React.useState([]);
  const handleSubSegmentChange = (event) => {
    const {
      target: { value },
    } = event;
    setSubSegmentIdList(
      // On autofill we get a stringified value.
      value
    );
  };
  const response = useSelector((state) => state.user.user);
  const handleClick = (value) => {
    setShowState(value);
  };
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      // props?.onUpdate(true);
      props?.getTaxfeemerchantdetails();
      handleClose();
    });
  };

  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };
  const handleSubmit = (values) => {
    if (formik.values.tax_segment && !(formik.values.tax_on === 2)) {
      formik.values.tax_segment = segmentIdList?.toString(',');
    }

    if (formik.values.tax_sub_segment && !(formik.values.tax_on === 2)) {
      formik.values.tax_sub_segment = subSegmentIdList?.toString(',');
    }
    if(formik.values.tax_on === 2){
      delete formik.values.tax_sub_segment;
      delete formik.values.tax_segment;
    }
    api
      .postData("/api/tax_fee/insert_update_taxes_merchant", formik.values)
      .then((response) => {
    
        if (response.data[0].status === "200") {
          // setEmpId(response.data[0].new_employee_id)
          showSwalWithLink(response.data[0].message);
        } else {
          showSwalWithError(response.data[0].message);
        }
      });
  };

  const initialValues = {
    tax_id: "0",
    merchant_id: response?.merchant_id,
    tax_name: "",
    tax_type: "",
    tax_amount_value: "",
    tax_on: "",
    tax_segment: "",
    tax_sub_segment: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: addEditTax,
    onSubmit: handleSubmit,
    // enableReinitialize: true,
  });

  useImperativeHandle(ref10, () => ({
    log() {
      handleOpen();
    },
  }));
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product ">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              ADD NEW TAXES
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <div>
      <h2>Address Geocoder</h2>
      <input
        type="text"
        placeholder="Enter an address"
        value={address}
        onChange={handleAddressChange}
      />
      <button onClick={handleGeocode}>Geocode</button>
      {locationDetails && (
        <div>
          <h3>Location Details</h3>
          <p>Locality: {locationDetails.locality}</p>
          <p>Sublocality: {locationDetails.sublocality}</p>
          <p>Pin Code: {locationDetails.pincode}</p>
          <p>Latitude: {locationDetails.latitude}</p>
          <p>Longitude: {locationDetails.longitude}</p>
          <p>City: {locationDetails.city}</p>
        </div>
      )}
    </div>
        </Box>
      </Modal>
    </div>
  );
});

export default AddressGeocoder;
