import React from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";

import Header from "../Header/Header";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import driver from "../images/ambulance1.svg";
import mobile from "../images/mobile.svg";
import custicon from "../images/cust-icon.svg";
import distance from "../images/Distance.svg";
import pickup from "../images/pickup.svg";
import dropoff from "../images/Dropoff.svg";
import location from "../images/location.svg";
import mail from "../images/mail.svg";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Default from "../images/default.jpg";
import Bredcrum from "../Bredcrum";
import MemberTable from "../Customer/MemberTable";
import FleetViewMoreModal from "./FleetViewMoreModal";
import FleetAddModal from "./FleetAddModal";

const year = ["2023", "2022", "2021", "2020"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const vinspectionsColumns = [
  { name: "Date", options: { filterOptions: { fullWidth: true } } },
  "Inspections",
  "Status",
  "Comments",
];

const vinspectionsOptions = {
  search: false,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const fleetColumns = [
  { name: "Vehicle ID", options: { filterOptions: { fullWidth: true } } },
  "City",
  "Vehicle Name",
  "Registration Number",
  "Status",
];

const fleetOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
    editable: true,
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true,
  },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 110,
    editable: true,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`,
  },
];

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  //   createData("Eclair", 262, 16.0, 24, 6.0),
  //   createData("Cupcake", 305, 3.7, 67, 4.3),
  //   createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Fleet() {
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
            <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid
                    xs="12"
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                   
                  </Grid>
                  {/* <Divider className="bredcum-hr"></Divider> */}
                  <Grid container spacing={2}>
                    <Grid item xs="12" md="7" mt={3}>
                      <Card md="12">
                        <Grid container spacing={2}>
                          <Grid item md="6">
                            <Typography
                              variant="h5"
                              marginTop={3}
                              marginLeft={3}
                              className="t-heading"
                            >
                              FLEET
                            </Typography>
                          </Grid>
                          <Grid item md="6" textAlign={"right"}>
                            <Button
                              marginTop={3}
                              marginLeft={3}
                              onClick={handleOpen}
                              className="btn-primary-outline fleet-modal"
                              variant="outlined"
                              onClick={() => ref4.current.log()}
                            >
                              ADD
                            </Button>
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <Typography
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  Text in a modal
                                </Typography>
                                <Typography
                                  id="modal-modal-description"
                                  sx={{ mt: 2 }}
                                >
                                  Duis mollis, est non commodo luctus, nisi erat
                                  porttitor ligula.
                                </Typography>
                              </Box>
                            </Modal>
                          </Grid>
                        </Grid>
                        <CardContent>
                          <Box sx={{ height: 400, width: "100%" }}>
                            <MemberTable
                              columns={fleetColumns}
                              options={fleetOptions}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs="12" md="5" mt={3}>
                      <Card md="12">
                        <CardContent>
                          <Grid container mb={2} alignItems={"center"}>
                            <Grid item md="4">
                              <Typography variant="h6" className="t-heading">
                                FLEET INFORMATION
                              </Typography>
                            </Grid>
                            <Grid item md="4">
                              <Button
                                variant="outlined"
                                className="btn-primary-outline"
                                onClick={() => ref3.current.log()}
                              >
                                View More
                              </Button>
                            </Grid>
                            <Grid item md="4">
                              <Button
                                variant="outlined"
                                className="btn-primary-outline"
                              >
                                Edit
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item md="4">
                              <Box component="img" src={"Default"} />
                            </Grid>
                            <Grid item md="8">
                              <Grid container>
                                <Grid item md="6">
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Vehicle ID:
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    #1
                                  </Typography>
                                </Grid>
                                <Grid item md="6">
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Make
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    Ford
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid container marginTop={2} marginBottom={2}>
                                <Grid item md="6">
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Model
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    Transit Connect
                                  </Typography>
                                </Grid>
                                <Grid item md="6">
                                  <Typography
                                    component="p"
                                    className="transport-details-title"
                                  >
                                    Year
                                  </Typography>
                                  <Typography
                                    component="p"
                                    className="transport-detail-value"
                                  >
                                    2015
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid item md="12" mb={1}>
                              <Typography variant="h6" className="t-heading">
                                Regulatory
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  License Plate
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  #DXGB51
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <img
                                src={custicon}
                                className="T-icon"
                                alt="img-here"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Registration expiration
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  45107
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container mb={2}>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Insured By
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Hudson Insurance Company
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container mb={2}>
                            <Grid
                              itemdisplay={"flex"}
                              alignItems={"center"}
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Policy Id
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  #
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Expiration
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  45107
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container mb={2}>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Proof of Insurance
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Insurance Document
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md="6"
                              display={"flex"}
                              alignItems={"center"}
                            >
                              <Box
                                component="img"
                                src={custicon}
                                className="T-icon"
                              />
                              <Box sx={{ marginLeft: "6px" }}>
                                <Typography
                                  component="p"
                                  className="transport-details-title"
                                >
                                  Insured by
                                </Typography>
                                <Typography
                                  component="p"
                                  className="transport-detail-value"
                                >
                                  Hudson Insurance Company
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid md="12" mb={2}>
                              <Typography className="t-heading">
                                VEHICLE INSPECTIONS
                              </Typography>
                            </Grid>
                            <Grid md="12">
                              <MemberTable
                                columns={vinspectionsColumns}
                                options={vinspectionsOptions}
                              />
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid md="12" mb={2}>
                              <Typography className="t-heading">
                                maintenance Log
                              </Typography>
                            </Grid>
                            <Grid md="12">
                              <MemberTable
                                columns={vinspectionsColumns}
                                options={vinspectionsOptions}
                              />
                            </Grid>
                          </Grid>
                          <Grid container mt={2} mb={2}>
                            <Typography variant="h5" className="t-heading">
                              Utilization Log
                            </Typography>
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} mb={2}>
                            <Grid md="12">
                              <MemberTable
                                columns={vinspectionsColumns}
                                options={vinspectionsOptions}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <FleetViewMoreModal ref={ref3} />
        <FleetAddModal ref={ref4} />
      </Box>
    </>
  );
}
