import React, { useEffect } from "react";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  OutlinedInput,
  FormControl,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SearchBar from "material-ui-search-bar";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import id from "../images/ID.svg";
import logout from "../images/logout.svg";
import customers from "../images/customers.svg";
import mobile from "../images/mobile.svg";
import location from "../images/location.svg";
import status from "../images/order.svg";
import active from "../images/last-active.svg";
import MemberTable from "../Customer/MemberTable";
import EditModal from "./EditModal";
import Alert from "@mui/material/Alert";
import Switch, { SwitchProps } from "@mui/material/Switch";
import ExpandableRowTable from "./ExpandableRowTable";
import MessageModal from "./MessageModal";
import ProfileModal from "../ProfileModal";
import rupee from "../images/rupee.svg";
import ViewMoreModalRetail from "./ViewMoreModalRetail";
import RetaileModal from "../RetaileModal";
import DatePicker from "react-datepicker";
import Bredcrum from "../Bredcrum";
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import api from "../Api";
import { useSelector, useDispatch } from 'react-redux';
import {setCity} from "../Redux/actions/userActions";
import AddCustomer from "./AddCustomer";
import { CSVLink } from "react-csv";
const drawerWidth = 240;

const style = {
  position: "absolute",
  top: "0",
  right: "0",
  // transform: 'translate(-50%, -50%)',
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function Customers() {
  const [open, setOpen] = React.useState(false);
  const [showState, setShowState] = React.useState(false);
  const response = useSelector(state => state.user.user);
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [startDate, setStartDate] = React.useState();
  const dispatch = useDispatch();
  const MySwal = withReactContent(Swal);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const buttonClickedHandler = () => {
    setShowState((showState) => (showState = !showState));
  };

  const [alignment, setAlignment] = React.useState("left");

  const ref = React.useRef();
  const ref1 = React.useRef();
  const ref3 = React.useRef();
  const ref4 = React.useRef();
  const ref5 = React.useRef();
  const showSwalWithLink = (message) => {
    MySwal.fire({
        icon: 'success',
        title: message,
    })
};

const showSwalWithLinkError = (message) => {
  MySwal.fire({
      icon: 'error',
      title: message,
  })
};
  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const [customersData,setCustomersData] = React.useState();
  const [customersFiltersData,setCustomersFiltersData] = React.useState();
  const getCustomers = () => {
    api.getData('/api/customer_orders/get_customersByMerchant_details?merchant_id=' + response?.merchant_id+'&environment='+process.env.REACT_APP_MY_TITLE)
      .then((response) => {
        console.log('response', response);
        if (response.data[0].Status === "200") {
          setCustomersData(JSON.parse(response.data[0].CustomersDetails));
          setCustomersFiltersData(JSON.parse(response.data[0].CustomersDetails));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
  const [cities,setCities] = React.useState();
  const fetchCities = () => {
    api.getData('/api/config/cities_list?countryid=' + response?.country_id)
        .then((response) => {

            if (response.data[0]?.Status === "200") {


                dispatch(setCity(JSON.parse(response.data[0].Cities).filter((item) => {
                    return item.city_state === 'Andhra Pradesh'
                })))
                setCities(JSON.parse(response.data[0].Cities).filter((item) => {
                  return item.city_state === 'Andhra Pradesh'
              }));
            }


        })
        .catch((error) => {
            console.log(error)
        })


}
  useEffect(()=>{
    getCustomers();
    fetchCities();
  },[]);

  const customerColumns = [
   {
    "name":"customer_id",
    "label":"Customer ID"
   },
   {
    "name":"name_of_the_customer",
    "label":"Customer Name"
   },
   {
    "name":"email_id",
    "label":"Email"
   },
   {
    "name":"phone_number",
    "label":"Phone"
   },{
    "name":"total_orders",
    "label":"Orders Count"
   }
  ];
 


  const [customerBasedDetails,setCustomerBasedDetails] = React.useState();
  const getCustomerBasedDetails = (customerId) => {
    api.getData('/api/customer_orders/Cust_get_customerbased_details?merchant_id=' + response?.merchant_id+'&environment='+process.env.REACT_APP_MY_TITLE+"&customer_bazaar_id="+customerId)
      .then((response) => {
        console.log('response', response);
        if (response.data[0].Status === "200") {
          setCustomerBasedDetails(JSON.parse(response.data[0].CustomerDetails)[0]);
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
   useEffect(()=>{
       if(customersData){
        getCustomerBasedDetails(customersData[0]?.customer_id);
       }
   },[customersData]);

   const updateCodStatus = (data) => {

    api.postData('/api/customer_orders/update_cod_status', {
        'merchant_id': response?.merchant_id,
        'customer_id': data?.customer_id,

    }
    ).then((response) => {
     
        if (response.data.cod_status[0].Status === "200") {
            showSwalWithLink(response.data.cod_status[0].Message);
            getCustomers();
            // setSuccess(true);
        }

    }).catch(error => {
        // Handle error
        console.error(error);
    });


};

const updateBlockStatus = (data) => {

  api.postData('/api/customer_orders/update_block_status', {
      'merchant_id': response?.merchant_id,
      'customer_id': data?.customer_id,

  }
  ).then((response) => {
      if (response.data.block_status[0].Status === "200") {
          showSwalWithLink(response.data.block_status[0].Message);
          getCustomers();
          // setSuccess(true);
      }

  }).catch(error => {
      // Handle error
      console.error(error);
  });


};

const updateActiveCreditStatus = (data) => {

  api.postData('/api/customer_orders/update_active_credit_status', {
      'merchant_id': response?.merchant_id,
      'customer_id': data?.customer_id,

  }
  ).then((response) => {
    console.log('response',response);

      if (response.data.active_status[0].Status === "200") {
          showSwalWithLink(response.data.active_status[0].Message);
          getCustomers();
          // setSuccess(true);
      }

  }).catch(error => {
      // Handle error
      console.error(error);
  });


};
 const [creditLimit,setCreditLimit] = React.useState();
const updateCreditLimit = () => {

  let data = {
    'merchant_id': response?.merchant_id || '',
    'customer_id': customerBasedDetails?.customer_id || '',
    'credit_limit': creditLimit || ''
  };
   if(data.merchant_id === '' || data.merchant_id === null){
    showSwalWithLinkError("Merchant Id Can Not Null");
   }else if(data.customer_id === '' || data.customer_id === null){
    showSwalWithLinkError("Customer Id Can Not Null");
   }
  //  else if(data.credit_limit === '' || data.credit_limit === null){
  //   showSwalWithLinkError("Credit Limit Can Not Null");
  //  }

  
  api.postData('/api/customer_orders/update_credit_limit', data
  ).then((response) => {
    console.log('response',response);
      if (response.data.credit_status[0].Status === "200") {
          showSwalWithLink(response.data.credit_status[0].Message);
          getCustomerBasedDetails(customerBasedDetails?.customer_id);
          // setSuccess(true);
      }

  }).catch(error => {
      // Handle error
      console.error(error);
  });


};

const [filterStartDate,setFilterStartDate] = React.useState();
const [filterEndDate,setFilterEndDate] = React.useState();
const [filterLastActiveStartDate,setFilterLastActiveStartDate] = React.useState();
const [filterLastActiveEndDate,setFilterLastActiveEndDate] = React.useState();
const [minSpend,setMinSpend] = React.useState();
const [maxSpend,setMaxSpend] = React.useState();
const [maxOrderSpend,setMaxOrderSpend] = React.useState();
const [minOrderSpend,setMinOrderSpend] = React.useState();
const filteredData = () =>{


  if (filterStartDate && filterEndDate) {
    const filteredData = customersFiltersData.filter(customer => {
      return customer.cr_date >= filterStartDate && customer.cr_date <= filterEndDate;
    });
    setCustomersData(filteredData);
  }
  if (minSpend && maxSpend) {
    const filteredData = customersFiltersData.filter(customer => {
      return customer.total_spend >= minSpend && customer.total_spend <= maxSpend;
    });
    setCustomersData(filteredData);
  } 
  if (maxOrderSpend && minOrderSpend) {
    const filteredData = customersFiltersData.filter(customer => {
      return customer.total_orders >= minOrderSpend && customer.total_orders <= maxOrderSpend;
    });
    setCustomersData(filteredData);
  } 


  if (filterLastActiveStartDate && filterLastActiveEndDate) {
    const filteredData = customersFiltersData.filter(customer => {
      return customer.last_activity >= filterLastActiveStartDate && customer.last_activity <= filterLastActiveEndDate;
    });
    setCustomersData(filteredData);
  } 
}

useEffect(()=>{
  filteredData();
},[maxOrderSpend,minOrderSpend,filterStartDate,filterEndDate,filterLastActiveStartDate,filterLastActiveEndDate,minSpend,maxSpend]);



  const salesOptions ={
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onRowClick: (rowData, rowMeta) => {
      const clickedRowIndex = rowMeta.rowIndex;
      const clickedRowData = customersData[clickedRowIndex];
      getCustomerBasedDetails(clickedRowData.customer_id);
    
  },
    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
   
  };

  const retailerdata = [
    [
      "#12345",
      "Venkata Sai Traders",
      "9948222051",
      "24",
      "Visakhapatnam",
      "Groceries",
    ],
    [
      "#23134",
      "Khazana Super Market",
      "9948222051",
      "24",
      "Kakinada",
      "Groceries",
    ],
  ];
  const retailerOptions = {
    search: true,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Box component="main">
        <Grid container className="retailers">
          <Grid xs="12">
            <Card xs="11" className="custom_card retail_card ">
              <Bredcrum />
              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box className="filters retailer-filters">
                      {/* <Box>
                        <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select float-right"
                          // onChange={handleSelectOxygen}
                          // input={<OutlinedInput />}
                          defaultValue="locations"
                        >
                          <MenuItem value="locations">All Cities</MenuItem>
                          
                          {
                            cities?.map((item)=>{
                              return <MenuItem value={item.city_id}>
                              {item.city_name}
                            </MenuItem>
                            })
                          }
                          
                        </Select>
                      </Box> */}
                         <Button
                        className="btn btn-outline-primary"
                        onClick={() => ref5.current.log()}
                      >
                        Add Customer
                      </Button>
                    </Box>
                  </Grid>
                  <Grid container spacing={4} mt={4}  className="pickers member-datepickers align-items-center">
                  <Grid item md={3} mb={3}>
                     
                       
                          <Box className="border-right">
                            <Typography>JOIN DATE</Typography>
                            <Box className="sub_date">
                              <FormControl>
                                <Typography component="label">From</Typography>
                                <OutlinedInput type="date" placeholder="Please enter text" format="yyyy-mm-dd" onChange={(e) => setFilterStartDate(e.target.value)} />
                                {/* <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                /> */}
                              </FormControl>
                              <FormControl>
                                <Typography component="label">To</Typography>
                                <OutlinedInput
                                  type="date"
                                  format="yyyy-mm-dd"
                                  onChange={(e) => setFilterEndDate(e.target.value)}
                                  placeholder="Please enter text"
                                />
                              </FormControl>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item md={3} mb={3} className="pl-0">
                        <Box className="border-right">
                          <Typography>LAST ACTIVE</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              <OutlinedInput type="date" format="yyyy-mm-dd" onChange={(e) => setFilterLastActiveStartDate(e.target.value)} />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput type="date" format="yyyy-mm-dd" onChange={(e) => setFilterLastActiveEndDate(e.target.value)} />
                            </FormControl>
                          </Box>
                        </Box>
                        </Grid>
                        <Grid item md={3} mb={3} className="pl-0">
                        <Box className="border-right">
                          <Typography>SPEND</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              <OutlinedInput
                                type="number"
                                placeholder="Spend Min"
                                onChange={(e)=>{setMinSpend(e.target.value)}}
                              />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput
                                type="number"
                                placeholder="Spend Max"
                                onChange={(e)=>{setMaxSpend(e.target.value)}}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                        </Grid>
                        <Grid item md={3} mb={3} className="pl-0">
                        <Box>
                          <Typography>NO OF ORDERS</Typography>
                          <Box className="sub_date">
                            <FormControl>
                              <Typography component="label">From</Typography>
                              <OutlinedInput
                                type="number"
                                placeholder="Orders Min"
                                onChange={(e)=>{setMinOrderSpend(e.target.value)}}
                              />
                            </FormControl>
                            <FormControl>
                              <Typography component="label">To</Typography>
                              <OutlinedInput
                                type="number"
                                placeholder="Orders Max"
                                onChange={(e)=>{setMaxOrderSpend(e.target.value)}}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                        </Grid>
                     
                    </Grid>
                 
                </Grid>

                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={7}>
                    <Card className="customer_cards">
                      <CardContent>
                        <Box className="box-header" mb={2}>
                          <Typography component="h6">CUSTOMERS</Typography>
                          <Box>
                          <CSVLink data={customersData ? customersData : []} filename={"customers.csv"}>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn"
                              mr={2}
                            >
                              Download
                            </Button>
                            </CSVLink>
                            <Button
                              variant="outlined"
                              mt={2}
                              mb={2}
                              className="retail-btn"
                              onClick={() => ref.current.log()}
                            >
                              Send Message
                            </Button>
                          </Box>
                        </Box>
                        <Box className="member-table">
                          {/* <ExpandableRowTable /> */}
                          <MemberTable
                            columns={customerColumns}
                            options={salesOptions}
                            data={customersData?customersData:[]}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Card className="customer_cards">
                      <CardContent className="customer_information">
                        <Box>
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={id} />
                                <Box>
                                  <Typography component="label">
                                    Customer ID
                                  </Typography>
                                  <Typography>{customerBasedDetails?.customer_id}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Name
                                  </Typography>
                                  <Typography>{customerBasedDetails?.name_of_the_customer}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={mobile} />
                                <Box>
                                  <Typography component="label">
                                    Phone no.
                                  </Typography>
                                  <Typography>{customerBasedDetails?.phone_number}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Email
                                  </Typography>
                                  <Typography>
                                  {customerBasedDetails?.email_id}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Customer Type
                                  </Typography>
                                  <Typography>{customerBasedDetails?.customer_type}</Typography>
                                </Box>
                              </Box>
                            </Grid> */}
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={location} />
                                <Box>
                                  <Typography component="label">
                                    Address
                                  </Typography>
                                  <Typography>
                                  {customerBasedDetails?.cust_address}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    COD Status
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} checked={customerBasedDetails?.cod_status===0?true:false} onChange={()=>{updateCodStatus(customerBasedDetails)}} />}
                                  />
                                </Box>
                              </Box>
                            </Grid> */}
                            <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Block Status
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} checked={customerBasedDetails?.block_status===0?true:false} onChange={()=>{updateBlockStatus(customerBasedDetails)}}/>}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={6} mt={2}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Member
                                  </Typography>
                                  <Radio
                                    checked={selectedValue === "a"}
                                    onChange={handleChange}
                                    value="a"
                                    name="radio-buttons"
                                    inputProps={{ "aria-label": "A" }}
                                    className="radio-btn"
                                  />
                                </Box>
                              </Box>
                            </Grid> */}
                          </Grid>
                          <Grid container spacing={2} mb={2}>
                          {/* <Grid item xs={6} mt={2}>
                              <Box className="information-div" mt={2} mb={2}>
                                <Box component="img" src={status} />
                                <Box>
                                  <Typography component="label">
                                    Active Credit Status
                                  </Typography>
                                  <FormControlLabel
                                    control={<IOSSwitch sx={{ m: 1 }} checked={customerBasedDetails?.active_credit_status === 0?true:false} onChange={()=>{updateActiveCreditStatus(customerBasedDetails)}} />}
                                  />
                                </Box>
                              </Box>
                            </Grid> */}
                            {/* <Grid item xs={6} mt={2}>
                              <Box className="information-div" mt={2} mb={2}>
                                <Box component="img" src={rupee} />
                                <Box>
                                  <Typography component="label">
                                    Total Credit Limit
                                  </Typography>
                                  <Typography>Rs {customerBasedDetails?.credit_limit}/-</Typography>
                                </Box>
                              </Box>
                            </Grid> */}
                            
                            {/* <Grid item xs={8} mt={2}>
                              <Box className="information-div" mt={2} mb={2}>
                                <Box component="img" src={rupee} />
                                <Box >
                                  <Typography component="label">
                                  Available Credit Limit
                                  </Typography>
                                  <Box className="flex">
                                  <Typography>Rs {customerBasedDetails?.available_credit_limit}/-</Typography>
                               
                          </Box>
                                </Box>
                              </Box>
                            </Grid> */}
                          </Grid>
                          <Divider></Divider>
                          <Grid container mt={2} spacing={2}>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={customers} />
                                <Box>
                                  <Typography component="label">
                                    Customer Since
                                  </Typography>
                                  <Typography>{customerBasedDetails?.cr_date}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box className="information-div">
                                <Box component="img" src={active} />
                                <Box>
                                  <Typography component="label">
                                    Last Activity
                                  </Typography>
                                  <Typography>{customerBasedDetails?.last_activity}</Typography>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                          
                          <Grid container spacing={2}>
                            <Grid item xs={6} mt={2} className="total_orders">
                              <Box className="box-primary">
                                <Typography component="h2">{customerBasedDetails?.total_orders}</Typography>
                                <Typography component="p">
                                  Total Orders
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6} mt={2}>
                              <Box className="customer-report">
                                <Typography component="h2">{customerBasedDetails?.total_spend}</Typography>
                                <Typography component="p">
                                  Total Spent
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider></Divider>
                        <Grid mt={5} className="view-more-btn">
                          <Button
                            variant="outlined"
                            color="error"
                            mt={2}
                            className="retail-btn"
                            onClick={() => ref3.current.log()}
                          >
                            View More
                          </Button>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="addCustomerModal notification-modal">
            <Box className="modal-header p-10" mb={2}>
              <Typography id="modal-modal-title" variant="h5" component="h5">
                NOTIFICATIONS
              </Typography>
              <CloseIcon onClick={handleClose} />
            </Box>
            <Divider></Divider>
            <Box className="p-10">
              <Alert
                icon={false}
                severity="warning"
                mt={2}
                className="alert-warning"
              >
                <Typography component="h5">Order Received</Typography>
                <Typography component="p" className="small">
                  2min ago
                </Typography>
                <Typography component="p" className="text-p">
                  200 Packets of NILGIRIS Toned Milk Pouch is received from SVN
                  Mart.
                </Typography>
              </Alert>
              <Alert
                icon={false}
                severity="success"
                mt={2}
                className="alert-success"
              >
                <Typography component="h5">Order Requested</Typography>
                <Typography component="p" className="small">
                  12min ago
                </Typography>
                <Typography component="p" className="text-p">
                  200 NUTREEN Nutreen Brown Eggs, 30 Pieces Pack is received
                  from Real Mart.
                </Typography>
              </Alert>
            </Box>
          </Box>
        </Modal>
        {/* <Toolbar /> */}
        <AddCustomer ref={ref5} getCustomers={getCustomers} />
        <RetaileModal ref={ref4} />
        <MessageModal ref={ref} />
        <EditModal ref={ref1} />
        <ViewMoreModalRetail ref={ref3} customerBasedDetails={customerBasedDetails} />
      </Box>
    </>
  );
}
