import React, { useState, useEffect } from "react";
import "./App.css";
import "./css/main.scss";
import "./css/buttons.scss";
import "./css/table.scss";
import "react-datepicker/dist/react-datepicker.css";
import Dashboard from "./Dashboard/Dashboard";
import Header from "./Header/Header";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Member from "./Customer/Member";
import NonMember from "./Customer/NonMember";
import Transport from "./Accounting/Transport";
import Customers from "./Customer/Customers";
// import Customers from "./Accounting/Customers";
import FeeEngine from "./Accounting/FeeEngine/FeeEngine";
import ShiftShedule from "./Employees/ShiftShedule";
import JobApplicants from "./Employees/JobApplicants";
import Login from "./Login";
import PhoneTransport from "../src/PhoneTransport/Phonetransport";
import Transports from "./Dashboard/Transports";
import Fleet from "./Dashboard/Fleet";
import Drivers from "../src/Accounting/Drivers";
import Refunds from "../src/Accounting/Refunds";
import Messages from "../src/Notification/Messages";
import Management from "../src/Employees/Management";
import Retailers from "./Customer/Retailers";
import PO from "./PurchaseOrders/PO";
import SalesReport from "./Accounting/SalesReport";
import ProcurementReport from "./Accounting/ProcurementReport";
import EmployeeReport from "./Accounting/EmployeeReport";
import ExpenseReport from "./Accounting/ExpenseReport";
import TaxFilling from "./Accounting/TaxFilling";
import StockManagement from "./ProductCatalogue/StockManagement";
import StoreNewItem from "./ProductCatalogue/StoreNewItem";
import Profile from "./ProductCatalogue/Profile";
import Publishing from "./ProductCatalogue/Publishing";
import Pos from "./Pos/Pos";
import WaiterLog from "./WaiterLog/Pos";
import Promotions from "./Promotions/Promotions";
import PromotionsEdit from "./Promotions/PromotionsEdit";
import MessagingHub from "./Promotions/MessagingHub";
import Messaging from "./Promotions/Messaging";
import PromotionsCreate from "./Promotions/PromotionsCreate";
import Equipment from "./ServiceCatalogue/Equipment";
import PhoneOrders from "./PhoneOrders/PhoneOrders";
import Bazaar from "./Procurement/Bazaar";
import PurchaseOrders from "./Procurement/PurchaseOrders";
import Supplier from "./Procurement/Supplier";
import InspectionsSetup from "./ServiceCatalogue/InspectionsSetup";
import AddMerchant from "./Admin/AddMerchant";
import DeliveryFeeEngine from "./Admin/DeliveryFeeEngine";
import CityManagement from "./Admin/CityManagement";
import DistributorDetails from "./Procurement/DistributorDetails";
import ServiceManagement from "./Admin/ServiceManagement";
import AppVersions from "./Admin/AppVersions";
import HelpAndSupport from "./Admin/HelpAndSupport";
import UserActivity from "./Admin/UserActivity";
import ProductView from "./Procurement/ProductView";
import Invoice from "./Procurement/Invoice";
import Register from "./Register";
import ForgotPassword from "./Forgot-password";
import VerificationScreen from "./VerificationScreen";
import VerificationSuccess from "./VerificationSuccess";
import PaySetup from "./Employees/PaySetup";
import TakeOrders from "./PhoneOrders/TakeOrders";
import TaxFees from "./ProductCatalogue/TaxFees";
import BazaarNew from "./Procurement/BazaarNew";
import CategorySlider from "./Procurement/CategorySlider";
import DistributorDetailsNew from "./Procurement/DistributorDetailsNew";
import ProductViewNew from "./Procurement/ProductViewNew";
import Account from "./Procurement/Account";
import Pay from "./Procurement/Pay";
import Listing from "./Equipment/listing";
import EquipmentManagement from "./Equipment/management";
import Inspections from "./Equipment/inspections";
import Compliance from "./Equipment/compliance";
import EquipmentScheduling from "./Equipment/scheduling";
import EquipmentMarketPlace from "./Equipment/MarketPlace";
import StationScreen from "./Kot/StationScreen";
import SkuUnitEco from "./Kot/SkuUnitEco";
import KotDashboard from "./Kot/Dashboard";
import SkuUtilizationUnit from "./ProductCatalogue/SkuUtilizationAudit";
import SuppliesManagement from "./ProductCatalogue/SuppliesManagement";
import AddNewIngredients from "./ProductCatalogue/AddNewIngredients";
import TableManagement from "./TableBooking/TableManagement";
import TableScheduling from "./TableBooking/TableScheduling";
import TableListing from "./TableBooking/TableListing";
import TableBooking from "./TableBooking/TableBooking";
import TablePublishing from "./TableBooking/TablePublishing";

function App(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const loginDashboard = () => {
    navigate("/dashboard");
  };
  useEffect(() => {
    // Update favicon based on condition
    const favicon = document.getElementById("favicon");
    const condition = process.env.REACT_APP_MY_TITLE === "Bazaar";
    if (process.env.REACT_APP_MY_TITLE === "Bazaar") {
      favicon.href = process.env.REACT_APP_MY_FAVICON_URL;
    } else if (process.env.REACT_APP_MY_TITLE === "Vikretha") {
      favicon.href = process.env.REACT_APP_MY_FAVICON_URL_VIKRETA;
    }
  }, []);

  return (
    <div
      className={
        location.pathname !== "/" &&
        location.pathname !== "/register" &&
        location.pathname !== "/forgotPassword" &&
        location.pathname !== "/verifyMobile" &&
        location.pathname !== "/veifyemail" &&
        location.pathname !== "/pos" &&
location.pathname !== "/waiterLog" &&
        location.pathname !== "/PhoneOrders" &&
        location.pathname !== "/Procurement/Bazaar" &&
        location.pathname !== "/Procurement/BazaarNew" &&
        location.pathname !== "/procurement/DistributorDetails" &&
        location.pathname !== "/procurement/DistributorDetailsNew" &&
        location.pathname !== "/procurement/ProductView" &&
        location.pathname !== "/procurement/ProductViewNew" &&
        location.pathname !== "/procurement/Account" &&
        location.pathname !== "/procurement/Pay" &&
        location.pathname !== "/equipment/marketPlace" &&
        location.pathname !== "/kot/stationScreen" &&
        location.pathname !== "/kot/sKUUnitEco" &&
        location.pathname !== "/kot/dashboard" &&
        // location.pathname !== "/phoneOrders/takeOrders" &&
        location.pathname !== "/procurement/Invoice"
          ? "App"
          : "full-width"
      }
    >
      {location.pathname !== "/" &&
      location.pathname !== "/register" &&
      location.pathname !== "/forgotPassword" &&
      location.pathname !== "/verifyMobile" &&
      location.pathname !== "/veifyemail" &&
      location.pathname !== "/pos" &&
location.pathname !== "/waiterLog" &&
      location.pathname !== "/PhoneOrders" &&
      location.pathname !== "/Procurement/Bazaar" &&
      location.pathname !== "/Procurement/BazaarNew" &&
      location.pathname !== "/procurement/DistributorDetails" &&
      location.pathname !== "/procurement/DistributorDetailsNew" &&
      location.pathname !== "/procurement/ProductView" &&
      location.pathname !== "/procurement/ProductViewNew" &&
      location.pathname !== "/procurement/Account" &&
      location.pathname !== "/procurement/Pay" &&
      location.pathname !== "/equipment/marketPlace" &&
      location.pathname !== "/kot/stationScreen" &&
      location.pathname !== "/kot/sKUUnitEco" &&
      location.pathname !== "/kot/dashboard" &&
      // location.pathname !== "/phoneOrders/takeOrders" &&
      location.pathname !== "/procurement/Invoice" ? (
        <Header />
      ) : null}{" "}
      <Routes>
        {/* <Route path="/" element={<Navigate to="/login" replace={true} />} /> */}
        <Route path="/" element={<Login loginDashboard={loginDashboard} />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/verifyMobile" element={<VerificationScreen />} />
        <Route path="/veifyemail" element={<VerificationSuccess />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="customer/member" element={<Member />} />
        <Route path="customers" element={<Customers />} />
        <Route path="customer/nonMember" element={<NonMember />} />
        <Route path="accounting/transport" element={<Transport />} />
        {/* <Route path="accounting/customers" element={<Customers />} /> */}
        <Route path="accounting/feeengine" element={<FeeEngine />} />
        <Route path="employees/shiftSchedule" element={<ShiftShedule />} />
        <Route path="employees/jobApplicants" element={<JobApplicants />} />
        <Route path="employees/paySetup" element={<PaySetup />} />
        <Route path="menu/taxFees" element={<TaxFees />} />
        <Route path="menu/MenuManagement" element={<StockManagement />} />
        <Route
          path="menu/suppliesManagement"
          element={<SuppliesManagement />}
        />
        <Route
          path="menu/addNewIngredients"
          element={<AddNewIngredients />}
        />
        <Route path="menu/storeNewItem" element={<StoreNewItem />} />
        <Route path="admin/profile" element={<Profile />} />
        <Route path="menu/publishing" element={<Publishing />} />
        <Route
          path="menu/skuUtilizationAudit"
          element={<SkuUtilizationUnit />}
        />
        <Route path="phoneTransport" element={<PhoneTransport />} />
        <Route path="transport" element={<Transports />} />
        {/* <Route path="retailers" element={<Retailers />} /> */}
        {/* <Route path="customer/customer" element={<Retailers />} /> */}
        <Route path="purchaseOrders" element={<PO />} />
        <Route path="pos" element={<Pos />} />
<Route path="waiterLog" element={<WaiterLog />} />
        <Route path="fleet" element={<Fleet />} />
        <Route path="/Accounting/salesReport" element={<SalesReport />} />
        <Route path="/Accounting/taxFilling" element={<TaxFilling />} />
        <Route
          path="/Accounting/procurementReport"
          element={<ProcurementReport />}
        />
        <Route path="/Accounting/employeeReport" element={<EmployeeReport />} />
        <Route path="/Accounting/expenseReport" element={<ExpenseReport />} />
        <Route path="/Accounting/drivers" element={<Drivers />} />
        <Route path="/Accounting/refunds" element={<Refunds />} />
        <Route path="/Notification/Messages" element={<Messages />} />
        <Route path="/Employees/Management" element={<Management />} />
        <Route path="/Promotions/Promotions" element={<Promotions />} />
        <Route path="/PhoneOrders" element={<PhoneOrders />} />
        <Route path="/procurement/bazaar" element={<Bazaar />} />
        <Route
          path="/procurement/purchaseorders"
          element={<PurchaseOrders />}
        />
        <Route path="/procurement/supplier" element={<Supplier />} />
        <Route path="/Promotions/MessagingHub" element={<MessagingHub />} />
        <Route path="/Promotions/Messaging" element={<Messaging />} />
        <Route path="/Promotions/PromotionsEdit" element={<PromotionsEdit />} />
        <Route
          path="/Promotions/PromotionsCreate"
          element={<PromotionsCreate />}
        />
        <Route path="/services/Equipment" element={<Equipment />} />
        <Route path="/services/Inspections" element={<InspectionsSetup />} />
        <Route path="/account/addMerchant" element={<AddMerchant />} />
        <Route
          path="/account/DeliveryFeeEngine"
          element={<DeliveryFeeEngine />}
        />
        <Route path="/account/CityManagement" element={<CityManagement />} />
        <Route
          path="/procurement/DistributorDetails"
          element={<DistributorDetails />}
        />
        <Route
          path="/admin/serviceManagement"
          element={<ServiceManagement />}
        />
        <Route path="/admin/appVersions" element={<AppVersions />} />
        <Route path="/admin/helpSupport" element={<HelpAndSupport />} />
        <Route path="/admin/userActivity" element={<UserActivity />} />
        <Route path="/procurement/ProductView" element={<ProductView />} />
        <Route path="/procurement/Invoice" element={<Invoice />} />
        {/* NewProcurementBazaar */}
        <Route path="/procurement/BazaarNew" element={<BazaarNew />} />
        <Route
          path="/procurement/CategorySlider"
          element={<CategorySlider />}
        />
        <Route
          path="/procurement/DistributorDetailsNew"
          element={<DistributorDetailsNew />}
        />
        <Route
          path="/procurement/ProductViewNew"
          element={<ProductViewNew />}
        />
        <Route path="/procurement/Account" element={<Account />} />
        <Route path="/procurement/Pay" element={<Pay />} />

        {/* Equipment */}
        <Route path="/equipment/listing" element={<Listing />} />
        <Route path="/equipment/management" element={<EquipmentManagement />} />
        <Route path="/equipment/inspections" element={<Inspections />} />
        <Route path="/equipment/compliance" element={<Compliance />} />
        <Route path="/equipment/scheduling" element={<EquipmentScheduling />} />
        <Route
          path="/equipment/marketPlace"
          element={<EquipmentMarketPlace />}
        />

        {/* Kot Automation */}
        <Route path="/kot/stationScreen" element={<StationScreen />} />
        <Route path="/kot/skuUnitEco" element={<SkuUnitEco />} />
        <Route path="/kot/dashboard" element={<KotDashboard />} />

        {/* TableBooking */}
        <Route
          path="/TableBooking/TableManagement"
          element={<TableManagement />}
        />
        <Route
          path="/TableBooking/TableScheduling"
          element={<TableScheduling />}
        />
        <Route path="/TableBooking/TableListing" element={<TableListing />} />
        <Route path="/TableBooking/TableBooking" element={<TableBooking />} />
        <Route
          path="/TableBooking/TablePublishing"
          element={<TablePublishing />}
        />
      </Routes>
    </div>
  );
}

export default App;
