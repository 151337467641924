import React, { useState, forwardRef, useImperativeHandle } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput, Select, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import MemberTable from "../Customer/MemberTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const myTheme = createTheme({
  // Set up your custom MUI theme here
});

const segmentColumns = [
  { name: "Transaction Date", options: { filterOptions: { fullWidth: true } } },
  "Transaction ID",
  "Total Amount",
  "Pending Amount",
];

const segmentData = [
  ["12-08-2023", "2789399378", "₹12000", "₹12000"],
  ["18-08-2023", "3982399898", "₹5000", "₹2000"],
];
const options = {
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  onFilterChange: (changedColumn, filterList) => {
    console.log(changedColumn, filterList);
  },
  selectableRows: true,
  isRowSelectable: false,
  expandableRowsHeader: false,
  icons: { Add: () => "Add Row" },
  filterType: "dropdown",
  responsive: "scrollMaxHeight",
  rowsPerPage: 10,
  expandableRows: false,

  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const PayOutTransactionsModal = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useImperativeHandle(ref, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="view-more-content addCustomerModal message-modal"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              PAYOUT
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <Grid container spacing={2} mt={2}>
            <Grid item md={4}>
              <FormControl className="form_control">
                <Typography component="label">Amount</Typography>
                <OutlinedInput placeholder="Amount" />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Select Payment Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Cash"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Online"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <Button className="btn btn-outline-primary">Pay</Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item md={12}>
              <MemberTable
                columns={segmentColumns}
                options={options}
                data={segmentData}
              />
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box mt={1} mb={2} className="p-15">
            <Button
              variant="outlined"
              color="error"
              className="retail-btn float-right"
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              color="error"
              className="retail-btn mr-2 float-right"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default PayOutTransactionsModal;
