import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { useSelector, useDispatch } from "react-redux";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import api from "../Api";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Select, MenuItem } from "@mui/material";
// import QRCode from "qrcode.react";
import QRCode from "qrcode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const EditIngredientsModal = forwardRef((props, ref6) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const response = useSelector((state) => state.user.user);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      props.fetchTableData();
      handleClose();
    });
  };
  const showSwalWithLinkError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    })
  };
  const [qrData, setQrData] = useState(""); // QR code data URL
  const [qrValue, setQrValue] = useState("");
  const handleSubmit = () => {
       
    if (qrValue.trim() === "") return;

    // Generate the QR code as a data URL
    console.log('qrValue',qrValue);
    QRCode.toDataURL(qrValue)
      .then((url) => {
        console.log('url',url);
      api.postData("/api/tables_1/upload-qr-code", { qrCode: url }).then((resp)=>{
  
      if (resp && resp.data.fileName) {
        // let data = {
        //   merchant_id: response?.merchant_id,
        //   table_id: formik.values?.table_id,
        //   qr_code_path: resp.data.fileName,
        // };
        let data={
          'table_id':formik.values?.table_id,
           'table_name':formik.values.table_name,
           'merchant_id':response?.merchant_id,
           'no_of_seats': formik.values.no_of_seats,
           'qr_code_value': formik.values.qr_code_value,
           'qr_code_path': resp.data.fileName,
           'description': formik.values.description
           }
        api
          .postData("/api/tables_1/Update_Table_qrcode", data)
          .then((resp) => {
            // if (resp && resp.data.fileName) {
            //   if (formik.values.qr_code_path) {
            //     console.log("resp.data?.fileName", resp.data?.fileName);
            //     formik.values.qr_code_path = resp.data?.fileName;
            //   }
            // }
            if(formik.values.qr_code_value){
              formik.values.qr_code_value =formik.values?.table_name;
            }
            api.postData("/api/tables_1/Insert_Update_Tables", formik.values).then((res)=>{
              if (res.data[0].Status === "200") {
                showSwalWithLink(res.data[0].Message);
              }else{
                showSwalWithLinkError(res.data[0].Message);
              }
            })
         
          });
        if(formik.values.qr_code_path){
          formik.values.qr_code_path = resp.data?.fileName;
        }
      }
    });
      //   let data={
      //  'table_id':'0',
      //   'table_name':formik.values.table_name,
      //   'merchant_id':response?.merchant_id,
      //   'no_of_seats': formik.values.no_of_seats,
      //   'qr_code_value': formik.values.table_name,
      //   'qr_code_path': resp.data.fileName,
      //   'description': formik.values.description
      //   }
        // Send form data to the server
         
       
    
      })
      .catch((error) => {
        console.error("Error generating QR code:", error);
      });
        // try {
        //   api
        //     .postData("/api/tables_1/Insert_Update_Tables", formik.values)
        //     .then((response) => {
        //       if (response.data[0].Status === "200") {
        //         showSwalWithLink(response.data[0].Message);
        //         // setSuccess(true);
        //       }else{
        //         showSwalWithLinkError(response.data[0].Message);
        //       }
        //     });
        // } catch (error) {
        //   // Handle network error
        // }
        // setSuccess(true);
      }
   
    
    

  const initialValues = {
    'table_id': props.editTableData?.table_id,
    // 'table_no': '',
    'table_name': props.editTableData?.table_name,
    'merchant_id': props.editTableData?.merchant_id,
    'no_of_seats': props.editTableData?.no_of_seats,
    'qr_code_value': props.editTableData?.qr_code_value,
    'qr_code_path': props.editTableData?.qr_code_path,
    'description': props.editTableData?.description
  };
  const formik = useFormik({
    initialValues,
    // validationSchema: SegmentSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  useEffect(() => {
    formik.setValues(initialValues);
  }, [props.editTableData]);
  // const [qrValue] = useState("Your Default QR Code Value"); // Set your default value here
  const [qrDataURL, setQrDataURL] = useState("");

  const generateQRCode = () => {
    // if (qrValue.trim() === '') return;
    // Generate QR code as a data URL
   
    // setQrFile(qrDataURL);
  };
  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
  formik;
  useImperativeHandle(ref6, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="view-more-content subsegment-product adm-modal"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              TABLE CONFIGURATION
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>

          <Divider></Divider>
          <form onSubmit={formik.handleSubmit}>
            <Grid container className="p-15">
              <Grid item xs={2} sm={12} md={12}>
                <Box mt={2}>
                  
                  <FormControl className="w-100">
                    <Typography component="label">Table Name</Typography>
                    <OutlinedInput placeholder="Enter Table Name" onChange={(e)=>{handleChange(e);setQrValue(e.target.value)}} onBlur={handleBlur} name="table_name" value={formik.values?.table_name} />
                  </FormControl>
                </Box>
                <FormControl className="w-100" mt={1}>
                  <Typography component="label">Seats</Typography>
                  {
                    console.log('formik.values?.no_of_seats',formik.values?.no_of_seats)
                  }
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    name="no_of_seats"
                    onChange={handleChange} onBlur={handleBlur}
                    value={formik.values?.no_of_seats}
                  >
                    <MenuItem value="0">Select</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                  </Select>
                </FormControl>

                <Box mt={2}>
                  <FormControl className="w-100">
                    <Typography component="label">Description</Typography>
                    <OutlinedInput placeholder="Enter Description" onChange={handleChange} onBlur={handleBlur} name="description" value={formik.values?.description}/>
                    {/* <input
                    type="text"
                    placeholder="Enter value"
                    value={qrValue}
                    hidden
                    onChange={(e) => setQrValue(e.target.value)}
                  /> */}
                    {/* {" "} */}
                    {/* <button onClick={generateQRCode}>Generate QR Code</button> */}
                    {/* {qrDataURL && <QRCode value={qrValue} />} */}
                    {qrData && <QRCode value={qrData} />}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Divider></Divider>

            <Box className="p-15">
              <Button
                variant="outlined"
                color="error"
                mt={2}
                mb={3}
                className="retail-btn float-right"
                type="submit"
              >
                Submit
              </Button>

            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
});

export default EditIngredientsModal;
