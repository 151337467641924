import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { Divider, OutlinedInput, TextareaAutosize, FormControl, Switch, SvgIcon } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Select, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MemberTable from '../Customer/MemberTable';
import Swal from 'sweetalert2';
import api from '../Api';
import { useFormik } from "formik";
import withReactContent from 'sweetalert2-react-content';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 35,
    height: 17,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#9568ff',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 15,
        height: 13,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const LocationModal = forwardRef((props, ref2, ref4) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [addOpen, setAddOpen] = React.useState(false);
    const handleOpenAdd = () => setAddOpen(true);
    const handleCloseAdd = () => setAddOpen(false);

    const MySwal = withReactContent(Swal);

    const showSwalWithLink = (message) => {
        MySwal.fire({
            icon: 'success',
            title: message,
        }).then(function () {

            props?.onUpdate(true);
            handleClose();

        })
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const memeberColumns = [
        "PASSENGER",
        "DATE",
        "DRIVER",
        "VEHICLE NUMBER",
        "LOCATION",
        "STATUS"
    ];

    const memeberData = [[
        "Kiren Swez",
        "19 May 2021",
        "Jonathan",
        "CMC-1238",
        "Miami",
        "Inprogress"
    ], [
        "Oliver",
        "14 Jan 2021",
        "Chris Gardner",
        "JSD-3141",
        "Orlando",
        "Completed"
    ]];

    const addLocation = Yup.object().shape({
        manager_name: Yup.string()
            .required('managername required'),
        manager_number: Yup.string()
            .required('managername required'),
        business_address: Yup.string()
            .required('Please Enter Business Address'),
        location_name: Yup.string()
            .required('Please Location Name'),
        packing_charges: Yup.number()
            .required('Please Enter Packing Charges'),
        business_city: Yup.string()
            .required('Please Select Business City'),
        business_type: Yup.string()
            .required('Please Select Business Type'),
        location_latitude: Yup.number()
            .required('Please Enter Location Latitude'),
        location_longitude: Yup.number()
            .required('Please Select Location Longitude'),
        locality: Yup.string()
            .required('Please  Expiry Locality'),
        sub_locality: Yup.string().
            required('Please Enter Sub Locality'),
        location_route: Yup.string()
            .required('Please Enter Location Route'),
        pincode: Yup.number()
            .required('Please Enter pincode'),
        opening_time1: Yup.mixed().
            required('Please Select Opening Time'),
        closing_time1: Yup.mixed().
            required('Please Select Closing Time'),
        opening_time2: Yup.mixed().
            required('Please Select Opening Time2'),
        closing_time2: Yup.mixed().
            required('Please Select  Closing Time2'),
        delivery_days: Yup.mixed().
            required('Please Select Delivery Days'),

    });

    const handleSubmit = (values) => {

        console.log('values', values);
        console.log('formik.values', formik.values);
        //   return false;
        api.postData('/api/locations/create_update_locations', formik.values).then((response) => {


            if (response.data[0].Status === "200") {
                
                showSwalWithLink(response.data[0].Message);

            }

        })
    };
    const formik = useFormik({
        initialValues: {
        'merchant_location_id':props.editData?.merchant_location_id || '0',
            'merchant_id': props.editData?.merchant_id || '',
            'manager_name': props.editData?.manager_name || '',
            'manager_number': props.editData?.manager_number || '',
            'business_address': props.editData?.business_address || '',
            'location_name': props.editData?.location_name || '',
            'packing_charges':props.editData?.packing_charges || '',
            'business_city':props.editData?.business_city || '',
            'business_type':props.editData?.business_type || '',
            'location_latitude':props.editData?.location_latitude || '',
            "location_longitude":props.editData?.location_longitude || '',
            'locality': props.editData?.locality || '',
            'sub_locality': props.editData?.sub_locality || '',
            'location_route': props.editData?.location_route || '',
            'pincode': props.editData?.pincode || '',
            'opening_time1': props.editData?.opening_time1 || '',
            'closing_time1': props.editData?.closing_time1 || '',
            'opening_time2': props.editData?.opening_time2 || '',
            'closing_time2': props.editData?.closing_time2 || '',
            'delivery_days': props.editData?.delivery_days || '',
            'closed_days': props.editData?.closed_days || '',
            'location_status': props.editData?.location_status || ''
        },
        validationSchema: addLocation,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });
    useEffect(() => {
        if (props.editData) {
            
          formik.setValues((prevValues) => ({
            ...prevValues,
            ...props.editData,
          }));
        }
      }, [props.editData]);

    const memberOptions = {
        search: false,
        download: false,
        print: false,
        viewColumns: false,
        filter: false,
        filterType: false,
        selectableRows: false,
        pagination: false,
        // responsive,
        // tableBodyHeight,
        // tableBodyMaxHeight,


        onTableChange: (action, state) => {
            console.log(action);
            console.dir(state);
        }
    };

    useImperativeHandle(ref2, () => ({
        log() {
            handleOpen();
        }
    }));

    useImperativeHandle(ref4, () => ({
        log() {
            handleOpen();
        }
    }));

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style} className='view-more-content new-entry w-750 add-merchant'>
                    <Box className="modal-header p-15">
                        <Typography id="modal-modal-title" variant="h5" component="h5">
                            LOCATION DETAILS
                        </Typography>
                        <CloseIcon onClick={handleClose} />
                    </Box>
                    <Divider></Divider>
                    <Grid container spacing={3} className='p-15' >

                        <Grid item xs={12} md={4}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Manager Name</Typography>
                                <OutlinedInput value='' placeholder='Manager Name' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Manager Number</Typography>
                                <OutlinedInput value='9856825632' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Address</Typography>
                                <OutlinedInput value='' placeholder='Address' />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Box className="p-15">
                        <Typography component='h5' className='promo-subtitle'>
                            LOCATION DETAILS ( CLICK HERE TO FETCH DETAILS )
                        </Typography>
                    </Box>
                    <Grid container spacing={2} className='p-15' >

                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Location Latitude</Typography>
                                <OutlinedInput value='' placeholder='Merchant Latitude' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Location Longitude</Typography>
                                <OutlinedInput value='' placeholder='Merchant Longitude' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Locality</Typography>
                                <OutlinedInput value='' placeholder='Locality' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Sub Locality</Typography>
                                <OutlinedInput value='' placeholder='Sub Locality' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Route</Typography>
                                <OutlinedInput value='' placeholder='route' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Pin Code</Typography>
                                <OutlinedInput value='' placeholder='pin code' />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Open Time</Typography>
                                <Select
                                            labelId="demo-isolation-label"
                                            id="isolation-select"
                                            className="select"
                                            // onChange={handleSelectOxygen}
                                            // input={<OutlinedInput />}
                                            defaultValue="Suppliers"

                                        >
                                            <MenuItem value="Suppliers">Open Time</MenuItem>
                                            <MenuItem value="9">9 AM</MenuItem>
                                            <MenuItem value="10">10 PM</MenuItem>
                                            <MenuItem value="van">10:30 PM</MenuItem>
                                        </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Close Time</Typography>
                                <Select
                                            labelId="demo-isolation-label"
                                            id="isolation-select"
                                            className="select"
                                            // onChange={handleSelectOxygen}
                                            // input={<OutlinedInput />}
                                            defaultValue="Suppliers"

                                        >
                                            <MenuItem value="Suppliers">Open Time</MenuItem>
                                            <MenuItem value="9">9 AM</MenuItem>
                                            <MenuItem value="10">10 PM</MenuItem>
                                            <MenuItem value="van">10:30 PM</MenuItem>
                                        </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Open Time 2</Typography>
                                <Select
                                            labelId="demo-isolation-label"
                                            id="isolation-select"
                                            className="select"
                                            // onChange={handleSelectOxygen}
                                            // input={<OutlinedInput />}
                                            defaultValue="Suppliers"

                                        >
                                            <MenuItem value="Suppliers">Open Time</MenuItem>
                                            <MenuItem value="9">9 AM</MenuItem>
                                            <MenuItem value="10">10 PM</MenuItem>
                                            <MenuItem value="van">10:30 PM</MenuItem>
                                        </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Close Time 2</Typography>
                                <Select
                                            labelId="demo-isolation-label"
                                            id="isolation-select"
                                            className="select"
                                            // onChange={handleSelectOxygen}
                                            // input={<OutlinedInput />}
                                            defaultValue="Suppliers"

                                        >
                                            <MenuItem value="Suppliers">Open Time</MenuItem>
                                            <MenuItem value="9">9 AM</MenuItem>
                                            <MenuItem value="10">10 PM</MenuItem>
                                            <MenuItem value="van">10:30 PM</MenuItem>
                                        </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Closed Days</Typography>
                                <Select
                                            labelId="demo-isolation-label"
                                            id="isolation-select"
                                            className="select"
                                            // onChange={handleSelectOxygen}
                                            // input={<OutlinedInput />}
                                            defaultValue="Suppliers"

                                        >
                                            <MenuItem value="Suppliers">Select Vehicle Type</MenuItem>
                                            <MenuItem value="Two Wheeler">Two Wheeler</MenuItem>
                                            <MenuItem value="van">Van</MenuItem>
                                            <MenuItem value="truck">Truck</MenuItem>
                                        </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100' mt={2}>
                                <Typography component="label">Delivery Mode</Typography>
                                <Select
                                            labelId="demo-isolation-label"
                                            id="isolation-select"
                                            className="select"
                                            // onChange={handleSelectOxygen}
                                            // input={<OutlinedInput />}
                                            defaultValue="Suppliers"

                                        >
                                            <MenuItem value="Suppliers">Delivery Mode</MenuItem>
                                            <MenuItem value="9">Home</MenuItem>
                                            <MenuItem value="10">PickUp</MenuItem>
                                        </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                    <Box className="p-15" mb={2}>
                        <Button variant='outlined' color='error' className='retail-btn float-right' onClick={handleClose}>Submit</Button>
                    </Box>
                </Box>
            </Modal >
        </div >
    );
});

export default LocationModal;
