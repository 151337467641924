import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Switch,
  Card,
  CardContent,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import { FormControlLabel, Radio } from "@mui/material";
import Header from "../Header/Header";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { OutlinedInput } from "@mui/material";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Bredcrum from "../Bredcrum";
import MemberTable from "../Customer/MemberTable";
import AddEmployeeModal from "./AddEmployeeModal";
import ManagementViewMoreModal from "./ManagementViewMoreModal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import withReactContent from 'sweetalert2-react-content';
import { useFormik } from "formik";
import api from "../Api";
import ShiftEditModal from "./ShiftEditModal";
import RoleEditModal from './RoleEditModal';
import { Theme, useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import { addShift, addRole } from "../../src/Validation";
// import { useDemoData } from "@mui/x-data-grid-generator";

const year = ["2023", "2022", "2021", "2020"];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = [
  "Admin",
  "Accounting",
  "Employee Management",
  "Menu",
  "KOT",
  "POS",
  "Phone Orders",
  "Procurement",
  "Promotions",
  "Orders",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const managementColumns = [
  { name: "Employee Name", options: { filterOptions: { fullWidth: true } } },
  "Designation",
  "Location",
  "Complaince",
  "Status",
];
const managementdata = [
  ["Andrew", "Driver", "Debary", "Yes", ""],
  ["Zeus", "Driver", "Debary", "Yes", ""],
];

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const tstatementsColumns = [
  { name: "Start Date", options: { filterOptions: { fullWidth: true } } },
  "End Date",
  "Total Transport",
  "Total Amount",
  "PayOut Date",
  "PayOut Mode",
  "Payment CNF ID",
  "Action",
  "",
  "",
];
const tstatementsdata = [
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
  ["02-27-2023", "03-02-2023", "94", "$1836.76", "", "", "", ""],
];

const tstatementsOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const columns = [
  //   { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: true,
  },
  {
    field: "location",
    headerName: "Location",
    width: 200,
    editable: true,
  },
  {
    field: "totalTransport",
    headerName: "Total Transport",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "earnings",
    headerName: "Earnings",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "leaves",
    headerName: "Leaves",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "compliance",
    headerName: "Compliance",
    type: "number",
    width: 150,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    type: "number",
    width: 150,
    editable: true,
  },
];

function createData(
  name,
  calories,
  fat,
  carbs,
  protein
) {
  return { name, calories, fat, carbs, protein };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const itemOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: true,
  selectableRows: false,
  confirmFilters: false,
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const ItemColumns = ["Shift Name", "Start Time", "End Time", ""];



const rolescolumns = ["Role Name", "Module", ""];
const payenginet1columns = ["Per Hour", "Per Week", "Per Month"];
const rolesdata = [
  [
    "Regular Shit",
    "10:00 AM",
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];
const servicecolumns = [
  "Incentive",
  "Applicable (Yes / No)",
  "Type (Flat / %)",
  "Fee",
  "",
];
const servicedata = [
  "Transport",
  <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
  "20%",
  <Button className="btn-outline-primary btn-space">Edit</Button>,
];
const top100Films = [{ title: "The Shawshank Redemption", year: 1994 }, { title: "The Shawshank", year: 1996 }, { title: "Redemption", year: 1995 }];

const orderItemData = [
  [
    "Regular Shit",
    "10:00 AM",
    "08:00 PM",
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];

const rows = [
  { id: 1, driverCity: "Snow", driverName: "Jon", phoneNumber: 8121542167 },
  {
    id: 2,
    driverCity: "Lannister",
    driverName: "Cersei",
    phoneNumber: 8121542167,
  },
  {
    id: 3,
    driverCity: "Lannister",
    driverName: "Jaime",
    phoneNumber: 8121542167,
  },
  { id: 4, driverCity: "Stark", driverName: "Arya", phoneNumber: 8121542167 },
  {
    id: 5,
    driverCity: "Targaryen",
    driverName: "Daenerys",
    phoneNumber: 8121542167,
  },
  {
    id: 6,
    driverCity: "Melisandre",
    driverName: null,
    phoneNumber: 8121542167,
  },
  {
    id: 7,
    driverCity: "Clifford",
    driverName: "Ferrara",
    phoneNumber: 8121542167,
  },
  {
    id: 8,
    driverCity: "Frances",
    driverName: "Rossini",
    phoneNumber: 8121542167,
  },
  { id: 9, driverCity: "Roxie", driverName: "Harvey", phoneNumber: 8121542167 },
];

const trows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default function PaySetup(props) {

  const [shifts, setShifts] = React.useState();
  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };

  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: 'success',
      title: message,
    }).then(function () {

      // props?.onUpdate(true);
      // handleClose();

    })
  };
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeMulti = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      text: message,
    });
  };

  const [location, setLocation] = React.useState();
  const [roleNames, setRoleNames] = React.useState();
  const jobRolescolumns = [
    {
      name: 'role_name',
      label: 'Role Name',

    },
    // { name: 'starting_time', label: 'Start Time' },
    // { name: 'ending_time', label: 'End Time' },
    {
      name: 'actions',
      label: ' ',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => {const rowIndex = tableMeta.rowIndex;
            setRoleNames(jobRoles[rowIndex]); ref5.current.log(); }}>Edit</Button>);
        },
      },
    },

  ];

  const [jobRoles, setJobRoles] = React.useState();
  const getEmployeeJobRoles = () => {
    api.getData('/api/employee_management/get_employee_job_role')
      .then((response) => {
        console.log('response', response);
        if (response.data.employee_job_names[0].Status === "200") {
          setJobRoles(JSON.parse(response.data.employee_job_names[0]?.EmployeeJobRoleDetails));
          //   setSpinner(false);
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  useEffect(() => {
    getEmployeeJobRoles();
  }, [])


  const [shiftNames, setShiftNames] = React.useState();
  const fetchShifts = () => {
    api.getData('/api/employee_management/get_shift_details')
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setShifts(JSON.parse(response.data[0]?.EmployeeShiftDetails));

        }


      })
      .catch((error) => {
        console.log(error)
      })


  }


  const [hoildays, setHolidays] = React.useState();
  const fetchHolidays = () => {
    api.getData('/api/employee_management/get_employee_Holiday_details')
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setHolidays(JSON.parse(response.data[0].EmployeeHolidayDetails));

        }


      })
      .catch((error) => {
        console.log(error)
      })


  }

  useEffect(() => {
    fetchShifts();
    fetchHolidays();
  }, []);

  const shiftColumns = [
    {
      name: 'shift_name',
      label: 'Shift Name',

    },
    { name: 'starting_time', label: 'Start Time' },
    { name: 'ending_time', label: 'End Time' },
    // {
    //   name: 'actions',
    //   label: ' ',
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (<Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => {
    //         const rowIndex = tableMeta.rowIndex;
    //         setShiftNames(shifts[rowIndex]); ref1.current.log();
    //       }}>Edit</Button>);
    //     },
    //   },
    // },
    //   { name: 'compliance_status', label: 'Complaince' ,
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const rowIndex = tableMeta.rowIndex;
    //       const rowData = currentEmpDetails[rowIndex];
    //       return (<Box onClick={() => { updateEmpComplainceStatus(rowData) }}><img src={value === 0?greendot:reddot} style={{width:'10px'}}  /> {value === 0?"Yes":"No"} </Box>);
    //     },
    //   }
    // },
    //   {
    //     name: 'employee_status',
    //     label: 'Status',
    //     options: {
    //       customBodyRender: (value, tableMeta, updateValue) => {
    //         const rowIndex = tableMeta.rowIndex;
    //         const rowData = currentEmpDetails[rowIndex];
    //         return (<IOSSwitch checked={value === 0} onChange={() => { changeCurrentEmpStatus(rowData.employee_id) }} />);
    //       },
    //     },
    //   }
  ];

  const holidayColumns = [
    {
      name: 'holiday_name',
      label: 'Holiday Name',

    },
    { name: 'holiday_date', label: 'Date of Holiday' },
    { name: 'pricing_percentage', label: 'Pricing Percentage' },
    { name: 'pay_percentage', label: 'Pay Percentage' },
    //   { name: 'compliance_status', label: 'Complaince' ,
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const rowIndex = tableMeta.rowIndex;
    //       const rowData = currentEmpDetails[rowIndex];
    //       return (<Box onClick={() => { updateEmpComplainceStatus(rowData) }}><img src={value === 0?greendot:reddot} style={{width:'10px'}}  /> {value === 0?"Yes":"No"} </Box>);
    //     },
    //   }
    // },
    //   {
    //     name: 'employee_status',
    //     label: 'Status',
    //     options: {
    //       customBodyRender: (value, tableMeta, updateValue) => {
    //         const rowIndex = tableMeta.rowIndex;
    //         const rowData = currentEmpDetails[rowIndex];
    //         return (<IOSSwitch checked={value === 0} onChange={() => { changeCurrentEmpStatus(rowData.employee_id) }} />);
    //       },
    //     },
    //   }
  ];

  const handleSubmit = (values) => {

    api.postData('/api/employee_management/InsertOrUpdateEmployeeShifts', values).then((response) => {


      if (response.data[0].Status === "200") {
        showSwalWithLink(response.data[0].Message);
        console.log(formik);
       // formik.resetForm();
        fetchShifts();
        
        values= {
          'shift_id': '0',
          'shift_name': '',
          'starting_time': '',
          'ending_time': '',
          'shift_status': '0'
        };
      } else {
        showSwalWithError(response.data[0].Message);
      }

    })
  };

  const handleSubmitRoles = (values) => {
    console.log(personName);
    //  if(formik1.is_runner.value){
    //   formik1.is_runner.value = personName;
    //  }
    // formik1.values.is_runner = personName;
    // console.log(formik1)
    api.postData('/api/employee_management/InsertOrUpdateEmployeeJobRole', formik1.values).then((response) => {

      if (response.status === 200) {
        // setEmpId(response.data[0].new_employee_id)
        showSwalWithLink(response.data[0].Message);
        getEmployeeJobRoles();
        formik1.resetForm();
        // formik1.initialValues={
        //   'role_id': '0',
        //   'role_name': '',
        //   'is_runner': '',
        //   'role_status': '0'
        // }
      } else {
        showSwalWithError(response.data[0].Message);
      }

    })
  };

  const handleSubmitEmpHolidays = (values) => {
    api.postData('/api/employee_management/insert_Employee_Holidays', values).then((response) => {

      console.log('response', response);

      if (response.data[0].employee_holiday_status[0].Status === "200") {

        // setEmpId(response.data[0].new_employee_id)
        showSwalWithLink(response.data[0].employee_holiday_status[0].Status);

      } else {
        showSwalWithError(response.data[0].employee_holiday_status[0].Status);
      }

    })
  };



  // const initialValuesRoles = {
  //   'role_id': '0',
  //   'role_name': '',
  //   'is_runner': '',
  //   'role_status': '0'
  // }

  // const initialValuesEmpHolidays = {
  //    'holiday_id':'0',
  //   'holiday_name': '',
  //   'holiday_date': '',
  //   'pricing_percentage':'',
  //   'pay_percentage':'',
  //   'holiday_status': '0',
  // }


  const formik3 = useFormik({
    initialValues: {
      'holiday_id': '0',
      'holiday_name': '',
      'holiday_date': '',
      'pricing_percentage': '',
      'pay_percentage': '',
      'holiday_status': '0',
    },
    onSubmit: handleSubmitEmpHolidays,
    // enableReinitialize: true,
  });

  // useEffect(() => {
  //   formik.setValues(initialValues);
  // }, [props.editData]);

  const formik1 = useFormik({
    initialValues: {
      'role_id': '0',
      'role_name': '',
      'is_runner': '',
      'role_status': '0'
    },
    validationSchema: addRole,
    onSubmit: handleSubmitRoles,
    enableReinitialize: true,
  });

  const formik = useFormik({
    initialValues: {
      'shift_id': '0',
      'shift_name': '',
      'starting_time': '',
      'ending_time': '',
      'shift_status': '0'
    },
    validationSchema: addShift,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
  } = formik;


  const [value, setValue] = React.useState(0);
  const ref4 = React.useRef();
  const ref1 = React.useRef();
  const ref5 = React.useRef();
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="custom-tabs"
              >
                <Tabs
                  value={value}
                  onChange={handleChanges}
                  aria-label="basic tabs example"
                  className="custom-tab"
                >
                  <Tab label="Setup" {...a11yProps(0)} />
                  {/* <Tab label="Pricing Engine" {...a11yProps(1)} /> */}
                  <Tab label="Pay Engine" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CardContent>
                <TabPanel value={value} index={0}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item md={6}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Box mb={3}>
                            <Typography
                              className="promo-subtitle"
                              component="h5"
                            >
                              SHIFTS
                            </Typography>
                          </Box>
                          <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={2} mb={2} alignItems="end">
                              <Grid item md={4}>
                                <FormControl className="w-100" rounded>
                                  <Typography component="label">
                                    Shift Name
                                  </Typography>
                                  <OutlinedInput
                                    name="shift_name"
                                    placeholder=""
                                    onChange={(e) => formik.handleChange(e)}
                                    onBlur={(e) => { formik.handleBlur(e); }}
                                  />
                                  {errors.shift_name && touched.shift_name && (
                                    <Box Typography="small" className="float-left text-danger">
                                      {errors.shift_name}
                                    </Box>
                                  )}
                                </FormControl>
                               
                              </Grid>
                             
                              <Grid item md={3}>
                                <FormControl className="w-100">
                                  <Typography component="label">
                                    Start Time
                                  </Typography>
                                  <OutlinedInput
                                    type="time"
                                    name="starting_time"
                                    placeholder="Hours"
                                    onChange={(e) => formik.handleChange(e)}
                                    onBlur={(e) => { formik.handleBlur(e); }}
                                  />
                                  {errors.starting_time && touched.starting_time && (
                                    <Box Typography="small" className="float-left text-danger">
                                      {errors.starting_time}
                                    </Box>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item md={3}>
                                <FormControl className="w-100">
                                  <Typography component="label">
                                    End Time
                                  </Typography>
                                  <OutlinedInput
                                    name="ending_time"
                                    type="time"
                                    placeholder="Hours"
                                    onChange={(e) => formik.handleChange(e)}
                                    onBlur={(e) => { formik.handleBlur(e); }}
                                  />
                                  {errors.ending_time && touched.ending_time && (
                                    <Box Typography="small" className="float-left text-danger">
                                      {errors.ending_time}
                                    </Box>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  mb={2}
                                  className="btn-outline-primary"
                                  type="submit"
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                          <Divider></Divider>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={shiftColumns}
                                options={itemOptions}
                                data={shifts ? shifts : []}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item md={6}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Box mb={3}>
                            <Typography
                              className="promo-subtitle"
                              component="h5"
                            >
                              ROLES
                            </Typography>
                          </Box>
                          <form onSubmit={formik1.handleSubmit}>
                            <Grid container spacing={2} mb={2} alignItems="end">
                              <Grid item md={4}>
                                <FormControl className="w-100" rounded>
                                  <Typography component="label">
                                    Role Name
                                  </Typography>
                                  <OutlinedInput
                                    name="role_name"
                                    onChange={(e) => formik1.handleChange(e)}
                                    onBlur={(e) => { formik1.handleBlur(e); }}

                                    placeholder="" />
                                    {formik1.errors.role_name && formik1.touched.role_name && (
                                    <Box Typography="small" className="float-left text-danger">
                                      {formik1.errors.role_name}
                                    </Box>
                                  )}
                                </FormControl>
                              </Grid>
                              {/* <Grid item md={3}>
                                <FormControl className="w-100">
                                  <Autocomplete
                                    multiple
                                    className="custom-height-ac"
                                    id="free-solo-demo"
                                    Services
                                    options={top100Films.map(
                                      (option) => option.title
                                    )}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Services" />
                                    )}
                                  />
                                </FormControl>
                              </Grid> */}
                              <Grid item md={3}>
                                <Box className="information-div">
                                  {/* <FormControl className="w-100">
                                   <Typography
                                      component="label"
                                      className="fs-label"
                                    >
                                      Modules
                                    </Typography> 
                                    <Select
                                      labelId="demo-isolation-label"
                                      id="status-select"
                                      className="select float-right"
                                      defaultValue="locations"
                                    >
                                      <MenuItem value="locations">
                                        Dashboard
                                      </MenuItem>
                                      <MenuItem value="visakhapatnam">
                                        Purchase Orders
                                      </MenuItem>
                                      <MenuItem value="vijayawada">
                                        Retailers
                                      </MenuItem>

                                      <MenuItem value="kurnool">
                                        Accounting
                                      </MenuItem>
                                    </Select>
                                  </FormControl> */}
                                  <FormControl className="w-100">
                                    <InputLabel
                                      id="demo-multiple-name-label"
                                      className="paysetup-modules"
                                    >
                                      Modules
                                    </InputLabel>
                                    <Select
                                      className="custom-height-ac"
                                      labelId="demo-multiple-name-label"
                                      id="demo-multiple-name"
                                      name="is_runner"
                                      multiple
                                      value={personName}
                                      onChange={handleChangeMulti}
                                      input={<OutlinedInput label="Name" />}
                                      MenuProps={MenuProps}
                                    >
                                      {names.map((name) => (
                                        <MenuItem
                                          key={name}
                                          value={name}
                                          style={getStyles(name, personName, theme)}
                                        >
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  variant="outlined"
                                  className="btn-outline-primary btn-space"
                                  type="submit"
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                          <Divider></Divider>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={jobRolescolumns}
                                options={itemOptions}
                                data={jobRoles ? jobRoles : []}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  {/* <Grid container spacing={2} mt={2}>
                    <Grid item md={6}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Box mb={3}>
                            <Typography
                              className="promo-subtitle"
                              component="h5"
                            >
                              HOLIDAY SHIFT
                            </Typography>
                          </Box>
                          <form onSubmit={formik3.handleSubmit}>
                            <Grid container spacing={2} mb={2} alignItems="end">
                              <Grid item md={3}>
                                <FormControl className="w-100" rounded>
                                  <Typography component="label">
                                    Holiday Name
                                  </Typography>
                                  <OutlinedInput

                                    name="holiday_name"
                                    onChange={(e) => formik3.handleChange(e)}
                                    onBlur={(e) => { formik3.handleBlur(e); }}
                                    placeholder="" />
                                </FormControl>
                              </Grid>
                              <Grid item md={3}>
                                <FormControl className="w-100">
                                  <Typography component="label">
                                    Date of Holiday
                                  </Typography>
                                  <OutlinedInput
                                    name="holiday_date"
                                    onChange={(e) => formik3.handleChange(e)}
                                    onBlur={(e) => { formik3.handleBlur(e); }}
                                    type="date"
                                    placeholder="Hours"
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item md={3}>
                                <FormControl className="w-100" rounded>
                                  <Typography component="label">
                                    Pricing Percentage
                                  </Typography>
                                  <OutlinedInput
                                    name="pricing_percentage"
                                    onChange={(e) => formik3.handleChange(e)}
                                    onBlur={(e) => { formik3.handleBlur(e); }}
                                    placeholder="" />
                                </FormControl>
                              </Grid>
                              <Grid item md={3}>
                                <FormControl className="w-100" rounded>
                                  <Typography component="label">
                                    Pay Percentage
                                  </Typography>
                                  <OutlinedInput
                                    name="pay_percentage"
                                    onChange={(e) => formik3.handleChange(e)}
                                    onBlur={(e) => { formik3.handleBlur(e); }}
                                    placeholder="" />
                                </FormControl>
                              </Grid>
                              <Grid item md={2}>
                                <Button
                                  variant="outlined"
                                  className="btn-outline-primary btn-space"
                                  type="submit"
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                          <Divider></Divider>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={holidayColumns}
                                options={itemOptions}
                                data={hoildays?hoildays:[]}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid> */}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Grid container spacing={2} mt={2}>
                    <Grid item md={5}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item md={3}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Roles
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="isolation-select"
                                    className="select viewmore-dpdown"
                                    name="role_id"
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e) => { handleBlur(e); }}
                                  >
                                    {
                                      jobRoles?.map((item) => {
                                        return <MenuItem value={item.role_id}>{item.role_name}</MenuItem>
                                      })
                                    }
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid item md={3}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Employees
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">Sai</MenuItem>
                                    <MenuItem value="visakhapatnam">
                                      Anil
                                    </MenuItem>
                                    <MenuItem value="vijayawada">
                                      Manohar
                                    </MenuItem>

                                    <MenuItem value="kurnool">Sumit</MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid item md={3}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Shifts
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    {
                                      shifts?.map((item) => {
                                        return <MenuItem value={item.shift_id}>{item.shift_name}</MenuItem>
                                      })
                                    }
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid item md={3}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Wages Type
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">
                                      Hourly Wage
                                    </MenuItem>
                                    <MenuItem value="visakhapatnam">
                                      Daily Wage
                                    </MenuItem>
                                    <MenuItem value="vijayawada">
                                      Weekly Wage
                                    </MenuItem>

                                    <MenuItem value="kurnool">
                                      Monthly Wage
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>

                          </Grid>
                          <Grid container spacing={2} mt={2}>
                            <Grid item md={4}>
                              <FormControl className="w-100">
                                <Typography component="label">
                                  Pay Type
                                </Typography>
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="select viewmore-dpdown"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="Suppliers"
                                >
                                  <MenuItem value="Suppliers">Select</MenuItem>
                                  <MenuItem value="car">Per Day</MenuItem>
                                  <MenuItem value="van">Per Hour</MenuItem>
                                  <MenuItem value="jeep">Per Week</MenuItem>
                                  <MenuItem value="truck">Per Month</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={4}>
                              <FormControl
                                className="form_control w-100"
                                fullWidth
                              >
                                <Typography component="label">
                                  Pay Period Unit
                                </Typography>
                                <Select
                                  labelId="demo-isolation-label"
                                  id="isolation-select"
                                  className="select viewmore-dpdown"
                                  // onChange={handleSelectOxygen}
                                  // input={<OutlinedInput />}
                                  defaultValue="Suppliers"
                                >
                                  <MenuItem value="Suppliers">Select</MenuItem>
                                  <MenuItem value="car">
                                    Per Day / 8 Hours
                                  </MenuItem>
                                  <MenuItem value="van">
                                    Per Week / 2 Days
                                  </MenuItem>
                                  <MenuItem value="jeep">
                                    Per Month / 26 Days
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item md={4}>
                              <FormControl
                                className="form_control w-100"
                                fullWidth
                              >
                                <Typography component="label">
                                  Base Pay
                                </Typography>
                                <OutlinedInput
                                  placeholder="Base Pay"
                                  required
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={payenginet1columns}
                                options={itemOptions}
                                data={rolesdata ? rolesdata : []}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md={7}>
                      <Card className="add-merchant">
                        <CardContent>
                          <Box mb={3}>
                            <Typography
                              className="promo-subtitle"
                              component="h5"
                            >
                              SERVICES
                            </Typography>
                          </Box>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Service Type
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">
                                      Delivery Boy
                                    </MenuItem>
                                    <MenuItem value="visakhapatnam">
                                      Assistant
                                    </MenuItem>
                                    <MenuItem value="vijayawada">
                                      Shop Keeper
                                    </MenuItem>

                                    <MenuItem value="kurnool">
                                      Accountant
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                            <Grid item md={6}>
                              <Box className="information-div">
                                <FormControl className="w-100">
                                  <Typography
                                    component="label"
                                    className="fs-label"
                                  >
                                    Shared
                                  </Typography>
                                  <Select
                                    labelId="demo-isolation-label"
                                    id="status-select"
                                    className="select float-right"
                                    defaultValue="locations"
                                  >
                                    <MenuItem value="locations">
                                      Single
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item md={12}>
                              <MemberTable
                                columns={servicecolumns}
                                options={itemOptions}
                                data={servicedata ? servicedata : []}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddEmployeeModal ref={ref4} />
        <ShiftEditModal ref={ref1} shifts={shifts} fetchShifts={fetchShifts} shiftNames={shiftNames} />
        <RoleEditModal ref={ref5} roles={jobRoles} fetchRoles={getEmployeeJobRoles} roleNames={roleNames} />

      </Box>
    </>
  );
}
