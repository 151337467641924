import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import { useFormik } from "formik";
import api from "../Api";
import MemberTable from "../Customer/MemberTable";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const ShiftEditModal = forwardRef((props, ref2, ref4) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleCloseAdd = () => setAddOpen(false);
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      props.fetchShifts();
      handleClose();
    });
  };
  const showSwalWithError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    }).then(function () {
      
      // props?.onUpdate(true);
      handleClose();
    });
  };
  const handleSubmit = () => {
    api
      .postData("/api/employee_management/InsertOrUpdateEmployeeShifts", formik.values)
      .then((response) => {
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
        } else {
          showSwalWithError(response.data[0].Message);
        }
      });
  };
  console.log(props);
  const initialValues = {
    'shift_id': props.shiftNames?.shift_id || '0',
    'shift_name': props.shiftNames?.shift_name || '',
    'starting_time': props.shiftNames?.starting_time || '',
    'ending_time': props.shiftNames?.ending_time || '',
    'shift_status': props.shiftNames?.shift_status || '0'
  }
  const formik = useFormik({
    initialValues,
    // validationSchema: addLocation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    console.log(props);
    formik.setValues(initialValues);
  }, [props?.shiftNames]);
  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
  } = formik;
  const selectColumns = [
    { name: "Name", options: { filterOptions: { fullWidth: true } } },
    "Contact No",
    "Location",
    {
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Checkbox />;
        },
      },
    },
  ];
  const selectData = [
    ["patnaik", "9513574862", "Kakinada"],
    ["Neeha", "7084327025", "Vizianagram"],
    ["naveen", "8624791305", "Visakhapatnam"],
  ];

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];
  const pcOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const memberOptions = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };

  useImperativeHandle(ref2, () => ({
    log() {
      handleOpen();
    },
  }));

  useImperativeHandle(ref4, () => ({
    log() {
      handleOpen();
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product ">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              SCHEDULE
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>

          <Grid
            container
            mt={1}
            mb={3}
            className="pickers"
            spacing={2}
            paddingRight={2}
          >
            <Grid item md={4}>
              <FormControl className="w-100">
                <Typography component="label">Shift Name</Typography>
                {/* <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="Suppliers"
                >
                  <MenuItem value="Suppliers">Select</MenuItem>
                  <MenuItem value="car">Day</MenuItem>
                  <MenuItem value="van">Week</MenuItem>
                  <MenuItem value="van">Month</MenuItem>
                </Select> */}
                <OutlinedInput
                  name="shift_name"
                  placeholder=""
                  value={formik.values.shift_name}
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Start Time</Typography>
                <OutlinedInput
                  type="time"
                  name="starting_time"
                  value={formik.values.starting_time}
                  placeholder="Hours"
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">End Time</Typography>
                <OutlinedInput
                  name="ending_time"
                  value={formik.values.ending_time}
                  type="time"
                  placeholder="Hours"
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Box className="p-15" mb={2}>
            <Button
              variant="outlined"
              color="error"
              className="retail-btn float-right"
              onClick={()=>handleSubmit()}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
});

export default ShiftEditModal;
