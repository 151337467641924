import React, { useImperativeHandle, forwardRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Grid, FormControl, OutlinedInput,CardContent, Divider } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import InputLabel from "@mui/material/InputLabel";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import api from '../Api';
import { useSelector } from 'react-redux';
import { useFormik } from "formik";
// import { PhoneVerification, doorDelivery } from '../Validation';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 50,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const PhoneVerifications = forwardRef((props, ref1) => {
    const response = useSelector(state => state.user.user);
    const [status,setStatus] = React.useState();
    const [message,setMessage] = React.useState();
    const [open, setOpen] = React.useState(false);
    const location = useLocation();
    const [passwd, setPasswd] = React.useState(false);
    const handlePassword = () => {
        setPasswd(passwd => !passwd)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const showSwalWithLink = () => {
        MySwal.fire({
            icon: 'error',
            text: "All Fields Are Mandatory",
        });
    };

    const handleSubmit = (values) => {
        api.postData(' /api/pos/customer_verify_phone', formik.values).then((response) => {
          console.log('response.data[0].Status',response.data[0].Status);
          
            console.log('JSON.parse(response.data[0].Status)',JSON.parse(response.data[0].Status));
            if(response.data[0].Status === '200'){
              setStatus(200);
              if(location.state.verifyEmail === 'No'){
              setMessage(response.data[0].Message+' And Please Verify Email Before Login');
             
            }
            }
           else {
            console.log('message');
            setStatus(500);
            setMessage(response.data[0].Message);
            
          }
        })
      };


      React.useEffect(() => {
        // fetchData();
      }, [message,status])
      const formik = useFormik({
        initialValues: {
          'phone':props?.phoneNumber,
          'otp':'',
        },
        // validationSchema: PhoneVerification,
        onSubmit: handleSubmit,
      });
    
      const handleData = (event) => {
        const { name, value } = event.target;
        const updatedValues = {
          ...formik.values,
          [name]: value,
        };
        formik.setValues(updatedValues);
      }
      const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
      } = formik;

    useImperativeHandle(ref1, () => ({
        log() {
            handleOpen();
        }
    }));


    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal'
            >
                <Box sx={{ ...style }} className='profile-modal'>
                    <Grid container>
                        <Grid item xs={12} className="profile-header p-15" >

                            <Typography id="parent-modal-title" component="h4">CUSTOMER DETAILS</Typography>
                            <CloseIcon onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                   <form onSubmit={formik.handleSubmit}>
                   <CardContent className="card-content text-center">
                <Typography
                  className="clr-primary fw-bold"
                  variant="h5"
                  gutterBottom
                >
                  Phone Verification
                </Typography>
                <Box className="">
                  {/* <FormControl className="w-100 mt-10" variant="standard">
                    <InputLabel shrink htmlFor="username-input">
                      Email OTP
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      id="email-input"
                      type="password"
                      className="mb-3"
                      placeholder="Enter Six Digit OTP Sent to Mail"
                    />
                  </FormControl> */}
                  <form onSubmit={formik.handleSubmit}>
                  <FormControl className="w-100 mt-10" variant="standard">
                    <InputLabel shrink htmlFor="username-input">
                      Phone OTP
                    </InputLabel>
                    <BootstrapInput
                      fullWidth
                      id="mobile-input"
                      name="otp"
                      type="password"
                      onChange={handleChange}
                      onBlur={(e) => { handleBlur(e); handleData(e) }}
                      placeholder="Enter Six Digit OTP Sent to Mobile"
                    />
                  </FormControl>
                  <Box className="w-100" mt={1}>
                  {message? <Box Typography="small" className={status===200?"text-success":"text-danger"}>{message}</Box>:''}
                  </Box>
                  <BootstrapInput component="input" variant="contained" className="mt-3 w-50 btn-submit py-2 mx-0"  value="Submit" type="submit">
                      Submit
                    </BootstrapInput>
                  </form>
                  <Box className="w-100">
                  {/* <Button variant="outlined" className="mt-3 w-50  py-2 mx-0 retail-btn" onClick={ResentOtp}>
                     Resend
                  </Button> */}
                  </Box>
                 
                </Box>
              </CardContent>
                    </form>
                </Box>
            </Modal>
        </div>
    );
});

export default PhoneVerifications;