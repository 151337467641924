import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Grid, FormControl, OutlinedInput, Divider, Select,
    MenuItem } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import api from '../Api';
import { useSelector } from 'react-redux';
import { useFormik } from "formik";
import { doorDelivery } from '../Validation';
import PhoneVerifications from './PhoneVerifications';
import { useDispatch } from "react-redux";
import {  setTableNumber,setDeliveryTypeId} from "../Redux/actions/userActions";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};



const MySwal = withReactContent(Swal)

const CustomerDetailsModal = forwardRef((props, ref17) => {
    const response = useSelector(state => state.user.user);
    const [open, setOpen] = React.useState(false);
    const [passwd, setPasswd] = React.useState(false);
    const [orderTypeId,setOrderTypeId] = React.useState();
    const handlePassword = () => {
        setPasswd(passwd => !passwd)
    }
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    
    const dispatch = useDispatch();
    const [tableNumberId,setTableNumberId]= React.useState(0);
    const getTableNumber = (tableId) =>{
      setTableNumberId(tableId);
       dispatch(setTableNumber(tableId));
    }
    const showSwalWithLink = (message) => {
        MySwal.fire({
            icon: 'success',
            text:message,
        }).then(()=>{
            handleClose();
        })
    };

    const showSwalWithLinkError = (message) => {
        MySwal.fire({
            icon: 'error',
            text:message,
        });
    };
   
    const [phoneNumber,setPhoneNumber]= React.useState();

    const handleSubmit = async (values, { setSubmitting }) => {

        let data = new FormData();
        data.append('customer_id', '0');
        data.append('bazaar_id', process.env.REACT_APP_MY_TITLE ==='Vikretha'?0:1);
        data.append('merchant_id', response?.merchant_id);
        data.append('name_of_the_customer', formik.values.name_of_the_customer);
        data.append('email_id', formik.values.name_of_the_customer+"@gmail.com");
        data.append('phone_number', formik.values.phone_number);
        data.append('password', '123456');
        data.append('gender', 'male');
        data.append('birthday', "11/11/1995");
        data.append('occasion_date', '');
        data.append('otp_status', '0');
        data.append('city_id', response?.city_id);
        data.append('cod_status', '0');
        data.append('block_status', '1');
        data.append('cust_address', formik.values.cust_address);
        data.append('cust_area', 'x');
        data.append('cust_locality', 'x');
        data.append('cust_street', 'x');
        data.append('cust_city',response?.city_id);
        data.append('pincode', '123456');
        data.append('address_type', 'd');
        data.append('adress_status', '0');
        data.append('profilephoto', '');



        try {
            api.updateData('/api/pos/customer_Create_Update', data).then((response) => {
               console.log('response',response);
                // if (response.data.Status === "200") {
                //     // showSwalWithLink(response.data.Message);
                    
                //     if(response.data.IsPhoneVerifyRequired === 'yes'){
                //         ref6.current.log()
                //         setPhoneNumber(response.data.phone_number);
                //     }else{
                //     props.getSearchPhoneDetails(response.data?.phone_number)
                //     // setPhoneNumber(response.data.phone_number);
                //     handleClose();
                //     }

                // }else{
                //     showSwalWithLinkError(response.data.Message)
                // }

                if (response.data[0].Status === "200") {
                    // showSwalWithLink(response.data.Message);
                    
                    if(response.data[0].IsPhoneVerifyRequired === 'yes'){
                        ref6.current.log()
                        setPhoneNumber(response.data[0].PhoneNumber);
                    }else{
                    props.getSearchPhoneDetails(response.data[0]?.PhoneNumber)
                    // setPhoneNumber(response.data.phone_number);
                    handleClose();
                    }

                }else{
                    showSwalWithLinkError(response.data[0].Message)
                }

            })


        } catch (error) {
            // Handle network error
        }

        setSubmitting(false);
    };

const ref6 = useRef();

    const initialValues = {
        'name_of_the_customer': '',
        'phone_number': '',
        'email': '',
        'cust_address': ''

    }
    const formik = useFormik({
        initialValues,
        validationSchema: doorDelivery,
        onSubmit: handleSubmit,
    });

    const handleChangeOrderType = (orderType) =>{
        setOrderTypeId(orderType);
        dispatch(setDeliveryTypeId(orderType));
        props.setDeliveryIds(orderType);
    }

    const {
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
    } = formik;

    useImperativeHandle(ref17, () => ({
        log() {
            handleOpen();
        }
    }));


    //   if(openM){
    //     handleOpen();
    //   }
    return (
        <div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                className='parent-modal view-more-content'
            >
                <Box sx={{ ...style }} className='profile-modal'>
                    <Grid container>
                        <Grid item xs={12} className="profile-header p-15" >

                            <Typography id="parent-modal-title" component="h4">CUSTOMER DETAILS</Typography>
                            <CloseIcon onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <Divider></Divider>
                   <form onSubmit={formik.handleSubmit}>
                    <Grid container className='details-div p-15' spacing={2}>
                        <Grid item xs={12} md={6}>

                            <FormControl className='w-100'>
                                <Typography component="label">Name</Typography>
                                <OutlinedInput
                                    placeholder="Name"
                                    onChange={(e) => handleChange(e)}
                                    onBlur={(e) => { handleBlur(e) }}
                                    name='name_of_the_customer'
                                />
                            {errors.name_of_the_customer && touched.name_of_the_customer && <Box Typography="small" className="float-left text-danger">{errors.name_of_the_customer}</Box>}

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl className='w-100'>
                                <Typography component="label">Phone No</Typography>
                                <OutlinedInput
                                 placeholder="Phone Number" 
                                 onChange={(e) => handleChange(e)}
                                    onBlur={(e) => { handleBlur(e) }}
                                    name='phone_number'
                                    />
                            {errors.phone_number && touched.phone_number && <Box Typography="small" className="float-left text-danger">{errors.phone_number}</Box>}

                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} md={6}>
                            <FormControl className='w-100'>
                                <Typography component="label">Email</Typography>
                                <OutlinedInput
                                 placeholder="Email Id"
                                 onChange={(e) => handleChange(e)}
                                    onBlur={(e) => { handleBlur(e) }}
                                    name='email'
                                  />
                            {errors.email && touched.email && <Box Typography="small" className="float-left text-danger">{errors.email}</Box>}

                            </FormControl>
                        </Grid> */}
                         <Grid item xs={12} md={6}>
              <FormControl className="form_control" fullWidth>
                <Typography component="label">Order Type</Typography>
                <Select
                  labelId="demo-isolation-label"
                  id="isolation-select"
                  className="select viewmore-dpdown"
                  // onChange={handleSelectOxygen}
                  // input={<OutlinedInput />}
                  defaultValue="0"
                  onChange={(e) => handleChangeOrderType(e.target.value)}
                >
                  <MenuItem value="0">Select</MenuItem>
                  <MenuItem value="5">Door Delivery</MenuItem>
                  <MenuItem value="9">Kerbside Pickup</MenuItem>
                  <MenuItem value="10">Dine in</MenuItem>
                </Select>
              </FormControl>
            </Grid>

                        <Grid item xs={12} md={12}>
                            <FormControl className='w-100'>
                                <Typography component="label">Address</Typography>
                                <OutlinedInput placeholder=""
                                 onChange={(e) => handleChange(e)}
                                 onBlur={(e) => { handleBlur(e) }}
                                 name='cust_address'
                                components="textarea" minRows={5} multiline={true} />
                            {/* {errors.cust_address && touched.cust_address && <Box Typography="small" className="float-left text-danger">{errors.cust_address}</Box>} */}

                            </FormControl>
                        </Grid>
                        {orderTypeId==10?
                        <Grid item xs={12} md={12}>
              <Box>
                <Typography component="h4">Select Table</Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                marginTop={3}
              >
                {/* <Button className="btn btn-outline-primary">Table 1</Button>
                <Button className="btn btn-outline-primary">Table 2</Button>
                <Button className="btn btn-outline-primary">Table 3</Button>
                <Button className="btn btn-outline-primary">Table 4</Button> */}
                  {
            props.tableNames?.map((item)=>{
              return <Grid item md={3} textAlign={"center"}>
                <Button className={tableNumberId ==item.table_id?"active btn btn-outline-primary":"btn btn-outline-primary"} onClick={()=>getTableNumber(item.table_id)}>
                   {item.table_name}
                </Button>
              </Grid>
            })
          }
              </Box>
            </Grid>:""}

                    </Grid>

                    <Divider></Divider>
                    <Box className="p-15 text-right">
                        <Button variant='outlined' color='error' className='retail-btn mr-15' type='submit'>Submit</Button>

                    </Box>
                    </form>
                </Box>

            </Modal>
            <PhoneVerifications ref={ref6} phoneNumber={phoneNumber} />
        </div>
    );
});

export default CustomerDetailsModal;