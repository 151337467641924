import React, { useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  MenuItem,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import shoppingcounter from "../images/shopping-counter.png";
import p2 from "../images/p2.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddToCartModal from "./AddToCartModal";
import cbshahi from "../images/cb-shahi.jpg";
import cb1 from "../images/cb1.jpg";
import cb2 from "../images/cb2.jpg";
import mb2 from "../images/mb1.jpg";
import pb from "../images/pb.jpg";
import mixed from "../images/mixed.jpg";

export default function Items() {
  const ref7 = useRef();

  return (
    <Grid container spacing={3} className="items">
      <Grid item md={4}>
        <Card className="pos-card">
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={cbshahi}
            className="card-img"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="p">
              Chicken Shahi Biryani
            </Typography>
            <Box className="flex flex-between align-center">
              <Typography component="span" className="text-danger">
                Rs. 350/-
              </Typography>
              <Typography component="span" className="small">
                250 gms
              </Typography>
            </Box>
            <Box className="text-center">
              <Button variant="contained" onClick={() => ref7.current.log()}>
                <ShoppingCartIcon /> ADD TO Cart
              </Button>
            </Box>
          </CardContent>
          <CardActions className="card-footer">
            <Typography component="span">
              <Box component="img" src={shoppingcounter}></Box>
              Zeeshan Apna Hyderabadi
            </Typography>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card className="pos-card">
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={cb1}
            className="card-img"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="p">
              Hyderabadi Dum Biryani
            </Typography>
            <Box className="flex flex-between align-center">
              <Typography component="span" className="text-danger">
                Rs. 250/-
              </Typography>
              <Typography component="span" className="small">
                500 gms
              </Typography>
            </Box>
            <Box className="text-center">
              <Button variant="contained">
                <ShoppingCartIcon /> ADD TO Cart
              </Button>
            </Box>
          </CardContent>
          <CardActions className="card-footer">
            <Typography component="span">
              <Box component="img" src={shoppingcounter}></Box>
              Zeeshan Apna Hyderabadi
            </Typography>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card className="pos-card">
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={cb2}
            className="card-img"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="p">
              Chicken Kalmi Biryani
            </Typography>
            <Box className="flex flex-between align-center">
              <Typography component="span" className="text-danger">
                Rs. 400/-
              </Typography>
              <Typography component="span" className="small">
                250 gms
              </Typography>
            </Box>
            <Box className="text-center">
              <Button variant="contained">
                <ShoppingCartIcon /> ADD TO Cart
              </Button>
            </Box>
          </CardContent>
          <CardActions className="card-footer">
            <Typography component="span">
              <Box component="img" src={shoppingcounter}></Box>
              Zeeshan Apna Hyderabadi
            </Typography>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card className="pos-card">
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={mb2}
            className="card-img"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="p">
              Mutton Biryani
            </Typography>
            <Box className="flex flex-between align-center">
              <Typography component="span" className="text-danger">
                Rs. 500/-
              </Typography>
              <Typography component="span" className="small">
                500 gms
              </Typography>
            </Box>
            <Box className="text-center">
              <Button variant="contained">
                <ShoppingCartIcon /> ADD TO Cart
              </Button>
            </Box>
          </CardContent>
          <CardActions className="card-footer">
            <Typography component="span">
              <Box component="img" src={shoppingcounter}></Box>
              Zeeshan Apna Hyderabadi
            </Typography>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card className="pos-card">
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={pb}
            className="card-img"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="p">
              Prawn Biryani
            </Typography>
            <Box className="flex flex-between align-center">
              <Typography component="span" className="text-danger">
                Rs. 450/-
              </Typography>
              <Typography component="span" className="small">
                250 gms
              </Typography>
            </Box>
            <Box className="text-center">
              <Button variant="contained">
                <ShoppingCartIcon /> ADD TO Cart
              </Button>
            </Box>
          </CardContent>
          <CardActions className="card-footer">
            <Typography component="span">
              <Box component="img" src={shoppingcounter}></Box>
              Zeeshan Apna Hyderabadi
            </Typography>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={4}>
        <Card className="pos-card">
          <CardMedia
            component="img"
            alt="green iguana"
            height="140"
            image={mixed}
            className="card-img"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="p">
              Special Mixed Biryani
            </Typography>
            <Box className="flex flex-between align-center">
              <Typography component="span" className="text-danger">
                Rs. 390/-
              </Typography>
              <Typography component="span" className="small">
                250 gms
              </Typography>
            </Box>
            <Box className="text-center">
              <Button variant="contained">
                <ShoppingCartIcon /> ADD TO Cart
              </Button>
            </Box>
          </CardContent>
          <CardActions className="card-footer">
            <Typography component="span">
              <Box component="img" src={shoppingcounter}></Box>
              Zeeshan Apna Hyderabadi
            </Typography>
          </CardActions>
        </Card>
        <AddToCartModal ref={ref7} />
      </Grid>
    </Grid>
  );
}
