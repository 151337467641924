import React from 'react';
import * as Yup from 'yup';
import axios from 'axios';

export const LoginSchema = Yup.object().shape({
  user_name: Yup.string()
    .required('Please Enter Username'),
  password: Yup.string()
    .required('Please Enter Password')
});

export const PhoneVerification = Yup.object().shape({
  otp: Yup.string()
    .required('Please Enter 6 Digit OTP'),

});

// let data = JSON.stringify({
//   'emailid': 'admin5@intelmor.com'
// });

let EMAIL_REGX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

let phoneRegExp = "((\+*)((0[ -]*)*|((91 )*))((\d{12})+|(\d{10})+))|\d{5}([- ]*)\d{6}"



export const RegisterSchema = Yup.object().shape({
  merchant_business_name: Yup.string()
    .min(5, "Business Name Must be at Least 5 Characters")
    .required('Please Enter Business Name'),
  country_id: Yup.string()
    .required('Please Select Country'),
  service_id: Yup.string()
    .required('Please Select Service'),
  city_id: Yup.mixed()
    .required('Please Select City'),
  admin_email: Yup.string().matches(EMAIL_REGX, "Invalid Email Address")
    .required('Please Enter Email'),
  phone_number: Yup.string().matches(/^[1-9]\d{9}$/, "Please Enter Valid Phone Number")
    .required('Please Enter Phonenumber'),
  user_password: Yup.string().min(8)
    .required('Please Enter Password'),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref('user_password'), null], 'Passwords Must Match')
    .required('Please Enter Confirmpassword')
});

export const ProfileUpdateSchema = Yup.object().shape({
  merchant_Business_name: Yup.string()
    .min(5, "Business Name Must be at Least 5 Characters")
    .required('Please Enter Business Name'),
  service_id: Yup.string()
    .required('Please Select Service'),
  city_id: Yup.string()
    .required('Please Select City'),
  bank_name: Yup.string()
    .required('Please Enter Bank Name'),
  bank_branch_name: Yup.string()
    .required('Please Enter Bank Branch Name'),
  ifsc_code: Yup.string()
    .required('Please Enter IFSC Code'),
  account_number: Yup.string()
    .required('Please Enter Account Number'),
  account_holder_name: Yup.string()
    .required('Please Enter Account Holder Name'),
  paytm_number: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Please Enter Valid Phone Number"),
  google_pay_number: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Please Enter Valid Phone Number"),
  phone_pay_number: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Please Enter Valid Phone Number"),
  phone_number: Yup.string().matches(/^[6-9]\d{9}$/, "Please Enter Valid Phone Number")
    .required('Please Enter phonenumber'),

});

export const addLocation = Yup.object().shape({
  manager_name: Yup.string().required("Manager Name required"),
  manager_number: Yup.string().required("Manager Number required"),
  business_address: Yup.string().required("Please Enter Business Address"),
  location_name: Yup.string().required("Please Location Name"),
  packing_charges: Yup.number().required("Please Enter Packing Charges"),
  // business_city: Yup.mixed().required("Please Select Business City"),
  // business_type: Yup.mixed().required("Please Select Business Type"),
  // location_latitude: Yup.number().required("Please Enter Location Latitude"),
  // location_longitude: Yup.number().required(
  //   "Please Select Location Longitude"
  // ),
  // locality: Yup.string().required("Please  Expiry Locality"),
  // sub_locality: Yup.string().required("Please Enter Sub Locality"),
  // location_route: Yup.string().required("Please Enter Location Route"),
  // pincode: Yup.number().required("Please Enter pincode"),
  opening_time1: Yup.mixed().required("Please Select Opening Time"),
  closing_time1: Yup.mixed().required("Please Select Closing Time"),
  opening_time2: Yup.mixed().required("Please Select Opening Time2"),
  closing_time2: Yup.mixed().required("Please Select  Closing Time2"),
  // delivery_days: Yup.mixed().required("Please Select Delivery Days"),
});

export const AddItemSchemaItem = Yup.object().shape({
  product_name: Yup.string()
    .required('Please Enter Item Name'),
  // brand_name: Yup.string()
  // .required('Please Enter Brand Name'),

  // product_description: Yup.string()
  // .required('Please Enter Product Description'),
  // product_how_to_use: Yup.string()
  // .required('Please Enter How to Use Product'),
  // product_specifications: Yup.string()
  // .required('Please Enter Product Description'),
  product_segment_id: Yup.mixed()
    .required('Please Select Segment'),
  product_sub_segment_id: Yup.mixed()
    .required('Please Enter Sub Segment'),
  product_metric: Yup.mixed()
    .required('Please Enter Product Metric'),
  product_uom: Yup.mixed()
    .required('Please Enter UOM'),
  no_of_units: Yup.number()
    .required('Please Enter Number of Units'),
  sale_price: Yup.mixed()
    .required('Please Enter Sale Price')
  //  productimage:Yup.mixed().required('Please Select files'),
});

export const AddItemSchema = Yup.object().shape({
  // product_name: Yup.mixed()
  //   .required('Please Enter Item Name'),
  brand_name: Yup.string()
    .required('Please Enter Brand Name'),

  // product_description: Yup.string()
  // .required('Please Enter Product Description'),
  // product_how_to_use: Yup.string()
  // .required('Please Enter How to Use Product'),
  // product_specifications: Yup.string()
  // .required('Please Enter Product Description'),
  product_segment_name: Yup.string()
    .required('Please Enter Segment'),
  product_sub_segment_name: Yup.string()
    .required('Please Enter Sub Segment'),
  product_metric: Yup.string()
    .required('Please Enter Product Metric'),
  product_uom: Yup.string()
    .required('Please Enter UOM'),
  no_of_units: Yup.mixed()
    .required('Please Enter Number of Units'),
  // sale_price: Yup.mixed()
  // .required('Please Enter Sale Price')
  //  productimage:Yup.mixed().required('Please Select files'),
});


// export const createUom = Yup.object().shape({
//   product_metric: Yup.string()
//     .required('Please Enter Product Metric'),
//     product_uom: Yup.string()
//     .required('Please Enter UOM'),
//     no_of_units: Yup.string()
//     .required('Please Enter Number of Units'),
//     one_unit_contains: Yup.string()
//     .required('Please Enter Units'),
//     msr_price: Yup.string()
//     .required('Please Enter MSR Price'),
//     cost_price: Yup.string()
//     .required('Please Enter Cost Price'),
//     sale_price: Yup.string()
//     .required('Please Enter Sale Price')

// });

export const AddProduct = Yup.object().shape({
  // product_stock_id: Yup.string()
  //   .required('Please Enter Product Sku Id'),
  product_vendor_name: Yup.string()
    .required('Please Enter Supplier Name'),
  product_qty_purchased: Yup.number()
    .required('Please Enter Quantity'),
  po_number: Yup.number()
    .required('Please Enter Po Number'),
  product_purchase_date: Yup.date()
    .required('Please Select Date'),
  product_purchase_description: Yup.string()
    .required('Please Enter Purchase Description'),
  product_cost_price: Yup.number()
    .required('Please Enter MSR Price'),
  po_date: Yup.date()
    .required('Please Enter Po Date'),

  // product_expiry_date: Yup.date()
  // .required('Please Select Expiry Date'),
  // product_expiry_reminder_days: Yup.number()
  // .required('Please Select Expiry Remainder Days'),
  // product_is_perishable:Yup.mixed().
  // required('Please Select Perishable')

});

export const SegmentSchema = Yup.object().shape({
  segment_name: Yup.string()
    .required('Please Enter Segment Name'),
  // segment_aisle: Yup.string()
  // .required('Please Enter Aisle'),
  segment_image: Yup.string()
    .required('Please Add Image')
});

export const SubSegmentSchema = Yup.object().shape({
  segment_id: Yup.string()
    .required('Please Select Segment'),
  sub_segment_name: Yup.string()
    .required('Please Enter Sub Segment Name')
});

export const doorDelivery = Yup.object().shape({
  name_of_the_customer: Yup.string()
    .required('Please Enter Name'),
  // email: Yup.string().email('Please Enter a Valid Email Address').required('Email is Required'),
  phone_number: Yup.string().matches(/^[1-9]\d{9}$/, "Please Enter Valid Phone Number")
    .required('Please Enter Phonenumber'),
  // cust_address: Yup.string()
  // .required('Please Enter Address')
});

export const baseTwoWheeler = Yup.object().shape({
  base_delivery_charge: Yup.string()
    .required('Please Enter Delivery Charge'),
  base_pick_drop_delivery_charge: Yup.string()
    .required('Please Enter Drop Drop Delivery Charge'),
  default_km: Yup.string()
    .required('Please Enter Default Km'),
  addl_charge_per_km: Yup.string()
    .required('Please Enter Additional Charges Per KM'),
  stop_over_charge: Yup.string()
    .required('Please Enter Stop Over Charge'),
  gst: Yup.string()
    .required('Please Enter Gst')
});

export const baseThreeWheeler = Yup.object().shape({
  base_delivery_charge: Yup.string()
    .required('Please Enter Delivery Charge'),
  base_pick_drop_delivery_charge: Yup.string()
    .required('Please Enter Drop Drop Delivery Charge'),
  default_km: Yup.string()
    .required('Please Enter Default Km'),
  addl_charge_per_km: Yup.string()
    .required('Please Enter Additional Charges Per KM'),
  stop_over_charge: Yup.string()
    .required('Please Enter Stop Over Charge'),
  gst: Yup.string()
    .required('Please Enter Gst')
});

export const adminFee = Yup.object().shape({
  merchant_convenience_fee: Yup.string()
    .required('Please Enter Convenience Charge'),
  pickup_drop_convenience_fee: Yup.string()
    .required('Please Enter Drop  Convenience Charge'),
  late_night_hours_from: Yup.string()
    .required('Please Enter Late Night Hours From'),
  late_night_hours_to: Yup.string()
    .required('Please Enter Late Night Hours To'),
  late_night_fee: Yup.string()
    .required('Please Enter Late Night Fee'),
  minimum_order_amount: Yup.string()
    .required('Please Enter Order Amount'),
  minimum_order_fee: Yup.string()
    .required('Please Enter Minimum Order Fee'),
  cod_order_limit_for_customer: Yup.string()
    .required('Please Enter Order Limit'),
  ease_cash_percentage: Yup.string()
    .required('Please Enter Easy Cash Persentage')
});

export const dynamicTwoWheeler = Yup.object().shape({
  from_amount: Yup.string()
    .required('Please Enter From Amount'),
  to_amount: Yup.string()
    .required('Please Enter To Amout'),
  delivery_fee: Yup.string()
    .required('Please Enter Delivery Fee')
});

export const dynamicThreeWheeler = Yup.object().shape({
  from_amount: Yup.string()
    .required('Please Enter From Amount'),
  to_amount: Yup.string()
    .required('Please Enter To Amout'),
  delivery_fee: Yup.string()
    .required('Please Enter Delivery Fee')
});


export const EditItemSchema = Yup.object().shape({
  product_name: Yup.string()
    .required('Please Enter Item Name'),
  brand_name: Yup.string()
    .required('Please Enter Name'),

  product_description: Yup.string()
    .required('Please Enter Product Description'),
  product_how_to_use: Yup.string()
    .required('Please Enter How to Use Product'),
  product_specifications: Yup.string()
    .required('Please Enter Product Description'),
  product_segment_id: Yup.string()
    .required('Please Select Segment'),
  product_sub_segment_id: Yup.string()
    .required('Please Select Sub Segment'),

  productimage: Yup.mixed().required('Please Select files'),
});

export const deliveryAddress = Yup.object().shape({
  name: Yup.string()
    .required('Please Enter Name'),
  phone_number: Yup.string()
    .required('Please Enter Phone Number'),
  date: Yup.mixed()
    .required('Please Select Date'),
  address: Yup.string()
    .required('Please Enter Address')
});

export const curbAddress = Yup.object().shape({
  name: Yup.string()
    .required('Please Enter Name'),
  phone_number: Yup.string()
    .required('Please Enter Phone Number'),
  date: Yup.mixed()
    .required('Please Select Date')
});
export const AddCustomerValidation = Yup.object().shape({
  first_name: Yup.string()
    .required('Please Enter First Name'),
  last_name: Yup.string()
    .required('Please Enter Last Name'),
  phone_number: Yup.string()
    .required('Please Enter Phone Number'),
  email_id: Yup.string()
    .required('Please Enter Email'),
  cust_address: Yup.string()
    .required('Please Enter Address'),
  customer_type: Yup.string()
    .required('Please Select Customer Type'),
});
export const addEmployee = Yup.object().shape({
  first_name: Yup.string()
    .required('Please Enter First Name'),
  // last_name: Yup.string()
  // .required('Please Enter Last Name'),
  phone_number: Yup.string()
    .required('Please Enter Phone Number'),
  email_id: Yup.string()
    .required('Please Enter Email'),
  gender: Yup.string()
    .required('Please Enter Gender'),
  location_id: Yup.string()
    .required('Please Enter Location'),
  date_of_birth: Yup.string()
    .required('Please Enter Date Of Birth'),
  address: Yup.string()
    .required('Please Enter Address'),
  // role_id: Yup.string()
  // .required('Please Enter Job Name'),
  job_type: Yup.string()
    .required('Please Select Job Type'),
  pay_type: Yup.string()
    .required('Please Select Pay Type'),
  basic_pay: Yup.string()
    .required('Please Select Basic Type'),
  pay_period_unit: Yup.mixed()
    .required('Please Select Pay Period Unit'),
  // bonus_comment: Yup.string()
  // .required('Please Enter Bonus Comments'),
  bank_name: Yup.string()
    .required('Please Enter Bank Name'),
  bank_branch: Yup.string()
    .required('Please Enter Bank Branch'),
  ifsc: Yup.string()
    .required('Please Enter IFSC Code'),
  account_number: Yup.string()
    .required('Please Enter Account Number'),
  account_holder_name: Yup.string()
    .required('Please Enter Account Holder Name'),
  user_name: Yup.string()
    .required('Please Enter User Name'),
  password: Yup.string()
    .required('Please Enter Password'),
    // compliance_number: Yup.string()
    // .required('Please Enter Compliance Number'),
    // compliance_name: Yup.string()
    // .required('Please Enter Compliance Name'),
    // compliance_file_name: Yup.mixed()
    // .required('Please Upload Compliance File Name'),
    // expiry_date: Yup.string()
    // .required('Please Select Expiry Date'),
    // doc_expiry_date: Yup.string()
    // .required('Please Select Doc Expiry Date'),
    // files_information: Yup.string()
    // .required('Please Select Files Information'),
    // file_name: Yup.string()
    // .required('Please Select File Name'),
    // equipment_name: Yup.string()
    // .required('Please Select Equipment Name'),
    // equipment_number: Yup.string()
    // .required('Please Select Equipment Number'),
    // equipment_file: Yup.string()
    // .required('Please Select Equipment File'),
});

export const shiftShedules = Yup.object().shape({
  employee_id: Yup.string()
    .required('Please select Employee'),
  location_id: Yup.string()
    .required('Please select Location'),
  shift_id: Yup.mixed()
    .required('Please Select Shift'),
  start_date: Yup.mixed()
    .required('Please Select Start Date'),
  end_date: Yup.mixed()
    .required('Please Select End Date')
});

export const customerEdit = Yup.object().shape({
  name_of_the_customer: Yup.string()
    .required('please Enter Customer Name'),
  email_id: Yup.string()
    .required('please Enter Email'),
  customer_type: Yup.mixed()
    .required('Please Enter Store Type'),
  cust_address: Yup.mixed()
    .required('Please Enter Customer Address'),
  cr_date: Yup.mixed()
    .required('Please Select Create Date'),
  last_activity: Yup.mixed()
    .required('Please Select Last Activity')
});

export const addEditTax = Yup.object().shape({
  tax_name: Yup.string()
    .required('Please Enter Tax Name'),
  tax_type: Yup.mixed()
    .required('please Enter Tax Type'),
  tax_amount_value: Yup.mixed()
    .required('Please Enter Tax Amount Value'),
  // tax_segment: Yup.mixed()
  // .required('Please Enter Tax Segment'),
  // tax_sub_segment: Yup.mixed()
  // .required('Please Select Tax Sub Segment'),
  // tax_on: Yup.mixed()
  // .required('Please Select Tax Type')

});

export const addEditFees = Yup.object().shape({
  fee_name: Yup.string()
    .required('Please Enter Fee Name'),
  fee_type: Yup.mixed()
    .required('please Enter Fee Type'),
  fee_amount: Yup.mixed()
    .required('Please Enter Fee Amount Value'),
  // fee_on: Yup.mixed()
  //   .required('Please Enter Fee Applicable')
});
export const helpAndSupport = Yup.object().shape({
  support_name: Yup.string()
    .required('Please Enter Support Name'),
  support_email: Yup.mixed()
    .required('please Enter Support Email'),
  order_support_contact_number: Yup.string().matches(/^\+91-[0-9]{10}$/, "Please Enter Valid Phone Number")
    .required('Please Enter Order Support Contact Number'),
  merchant_support_contact_number: Yup.string().matches(/^\+91-[0-9]{10}$/, "Please Enter Valid Phone Number")
    .required('Please Enter Merchant Support Contact Number'),
  refund_support_contact_number: Yup.string().matches(/^\+91-[0-9]{10}$/, "Please Enter Valid Phone Number")
    .required('Please Enter Refund Support Contact Number')
});

export const addShift = Yup.object().shape({
  shift_name: Yup.string().required('Please Enter Shift Name'),
  starting_time: Yup.mixed().required("Please Select Starting Time"),
  ending_time: Yup.mixed().required("Please Select Ending Time"),

});

export const addRole = Yup.object().shape({
  role_name: Yup.string().required('Please Enter Role Name'),
  
});
