import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import {
  Divider,
  OutlinedInput,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Select, MenuItem } from "@mui/material";
import Swal from "sweetalert2";
import api from "../Api";
import { useFormik } from "formik";
import withReactContent from "sweetalert2-react-content";
import { useSelector } from "react-redux";
import { addLocation } from "../Validation";
import * as Yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddLocationModal = forwardRef((props, ref2, ref4) => {
  const data = props.props;
  const response = useSelector((state) => state.user.user);
  const cities = useSelector((state) => state.user.cities);
  const services = useSelector((state) => state.user.services);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [openTime, setOpenTime] = React.useState();
  const [closeTime, setCloseTime] = React.useState();
  const [openTime2, setOpenTime2] = React.useState();
  const [closeTime2, setCloseTime2] = React.useState();
  const [addOpen, setAddOpen] = React.useState(false);
  const [address, setAddress] = useState("");
  const [locationDetails, setLocationDetails] = useState(null);

  
 
  // const [closedDays, setClosedDays] = useState([]);

  // const handleClosedDays = (e) => {
  //   const { value, checked } = e.target;
  //   if (checked) {
  //     setClosedDays((prevClosedDays) => [...prevClosedDays, value]);
  //   } else {
  //     setClosedDays((prevClosedDays) =>
  //       prevClosedDays.filter((day) => day !== value)
  //     );
  //   }
  // };

  const [closedDays, setClosedDays] = useState([]);

  const handleClosedDays = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setClosedDays((prevClosedDays) => [...prevClosedDays, value]);
    } else {
      setClosedDays((prevClosedDays) =>
        prevClosedDays.filter((day) => day !== value)
      );
    }
  };

  const time = [
    "12:00 AM",
    "12:30 AM",
    "01:00 AM",
    "01:30 AM",
    "02:00 AM",
    "02:30 AM",
    "03:00 AM",
    "03:30 AM",
    "04:00 AM",
    "04:30 AM",
    "05:00 AM",
    "05:30 AM",
    "06:00 AM",
    "06:30 AM",
    "07:00 AM",
    "07:30 AM",
    "08:00 AM",
    "08:30 AM",
    "09:00 AM",
    "09:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "01:00 PM",
    "01:30 PM",
    "02:00 PM",
    "02:30 PM",
    "03:00 PM",
    "03:30 PM",
    "04:00 PM",
    "04:30 PM",
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 PM",
  ];

  const MySwal = withReactContent(Swal);

  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      props?.onUpdate(true);
      handleClose();
    });
  };
  const handleSubmit = (values) => {
    //   return false;

    formik.values.closed_days = JSON.stringify([...new Set(closedDays)]);
    console.log("formik.values", formik.values);

    api
      .postData("/api/locations/create_update_locations", formik.values)
      .then((response) => {
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
        }
      });
  };

  const convertTime = (value) => {
    const time24 = value;
    const dateObj = new Date(`2000-01-01T${time24}`);
    const time12 = dateObj.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return time12;
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    handleGeocode();
  };
  const inputRef = useRef(null);
  useEffect(() => {
    // load the Google Maps JavaScript API library
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAMWSr2YSC6925JdAvbRyfjaiRsF8rPxA4&libraries=places`;
    script.async = true;
    script.defer = true;
    script.addEventListener('load', () => {
      // initialize the Autocomplete object
      const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
      setAutocomplete(autocomplete);
    });
    document.body.appendChild(script);
  
    // clean up the script tag
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const handleGeocode = () => {
    // Create a Geocoder instance
    const geocoder = new window.google.maps.Geocoder();

    // Perform geocoding with the provided address
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const addressDetails = results[0].address_components;
        const latitude = results[0].geometry.location.lat();
        const longitude = results[0].geometry.location.lng();

        // Initialize variables for address components
        let locality = '';
        let sublocality = '';
        let pincode = '';
        let city = '';
        

          console.log('addressDetails',addressDetails);
        // Extract address details
        addressDetails.forEach((component) => {
          const types = component.types;
          if (types.includes('locality')) {
            locality = component.long_name;
          } else if (types.includes('sublocality')) {
            sublocality = component.long_name;
          } else if (types.includes('postal_code')) {
            pincode = component.long_name;
          } else if (types.includes('administrative_area_level_2')) {
            city = component.long_name;
          }
        });

        setLocationDetails({
          locality,
          sublocality,
          pincode,
          longitude,
          latitude,
          city,
        });
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };
  
 
  const initialValues = {
    merchant_location_id: props.editData?.merchant_location_id || "0",
    merchant_id: response?.merchant_id || "",
    manager_name: props.editData?.manager_name || "",
    manager_number: props.editData?.manager_number || "",
    business_address: props.editData?.business_address || "",
    location_name: props.editData?.location_name || "",
    packing_charges: props.editData?.packing_charges || "",
    business_city: response?.city_id || "",
    business_type: response?.service_id || "",
    location_latitude: props.editData?.location_latitude || "",
    location_longitude: props.editData?.location_longitude || "",
    locality: props.editData?.locality || "",
    sub_locality: props.editData?.sub_locality || "",
    location_route: props.editData?.location_route || "",
    pincode: props.editData?.pincode || "",
    opening_time1: props.editData?.opening_time1 || "",
    closing_time1: props.editData?.closing_time1 || "",
    opening_time2: props.editData?.opening_time2 || "",
    closing_time2: props.editData?.closing_time2 || "",
    delivery_days: props.editData?.delivery_days || "",
    closed_days: props.editData?.closed_days || "",
    location_status: props.editData?.location_status || "0",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: addLocation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    formik.setValues(initialValues);
    console.log("props.editData?", props.editData);
    if (props.editData?.closed_days) {
      const closedDaysValue = props.editData?.closed_days.toLowerCase();

      if (closedDaysValue === "sunday") {
        setClosedDays([closedDaysValue]);
      } else {
        try {
          const closedDaysJSON = JSON.parse(closedDaysValue);

          if (Array.isArray(closedDaysJSON)) {
            const normalizedClosedDays = closedDaysJSON.map((day) =>
              day?.toLowerCase()
            );
            setClosedDays(normalizedClosedDays);
          } else {
            // Handle other unexpected cases
            console.error("Unexpected format for closed_days:", closedDaysJSON);
          }
        } catch (error) {
          // Handle JSON parsing errors
          console.error("Error parsing JSON:", error);
        }
      }
    }
  }, [props.editData]);

  useEffect(() => {
    if (props.editData) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        ...props.editData,
      }));
    }
  }, [props.editData]);

  const memeberColumns = [
    { name: "First Name", options: { filterOptions: { fullWidth: true } } },
    "Last Name",
    "Email",
    "Password",
  ];

  const handleData = (event) => {
    const { name, value } = event.target;
    // Yup.reach(RegisterSchema, name).validate(value);
    if (name === "phone_number") {
      if (props.editData.phone_number !== value) {
        const updatedValues = {
          ...formik.values,
          ["isphone_changed"]: "1",
        };
        formik.setValues(updatedValues);
      }
    }
    if (name === "service_id") {
      let arr = value.split("-");
      const updatedValues = {
        ...formik.values,
        ["service_id"]: arr[0],
        ["service_prefix"]: arr[1],
      };
      formik.setValues(updatedValues);
    } else if (name === "country_id") {
      let arr1 = value.split("-");
      const updatedValues = {
        ...formik.values,
        ["country_id"]: arr1[1],
        ["country_code"]: arr1[0],
      };
      formik.setValues(updatedValues);
    } else {
      const updatedValues = {
        ...formik.values,
        [name]: value,
      };
      formik.setValues(updatedValues);
    }
  };

  useImperativeHandle(ref2, () => ({
    log() {
      handleOpen();
      formik.setTouched({}, false);
    },
  }));

  useImperativeHandle(ref4, () => ({
    log() {
      handleOpen();
      formik.setTouched({}, false);
    },
  }));

  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="view-more-content add-product adm-modal">
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              MERCHANT LOCATION DETAILS
            </Typography>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Divider></Divider>
          <form onSubmit={formik.handleSubmit}>
            <Box className="ml-20" mt={2}>
              <Typography component="h4" className="sub_title">
                Manager Details
              </Typography>
            </Box>
            <Grid container spacing={3} mb={2} className="px-15">
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Manager Name</Typography>
                  <OutlinedInput
                    id="manager_name"
                    name="manager_name"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values?.manager_name}
                  />
                </FormControl>

                {errors.manager_name && touched.manager_name && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.manager_name}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Manager Number</Typography>
                  <OutlinedInput
                    name="manager_number"
                    value={formik.values?.manager_number}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  />
                </FormControl>
                {errors.manager_number && touched.manager_number && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.manager_number}
                  </Box>
                )}
              </Grid>
            </Grid>

            <Divider></Divider>
            <Box className="ml-20" mt={2}>
              <Typography component="h4" className="sub_title">
                Location Address
              </Typography>
            </Box>
            <Grid container spacing={1} className="p-15">
              <Grid item xs={2} sm={12} md={12} className="p-15">
                <FormControl className="w-100">
                  <Typography component="label">Address</Typography>
                  <Box
                    name="business_address"
                    component="textarea"
                    minRows={5}
                    value={formik.values?.business_address}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      handleData(e);
                      handleAddressChange(e);
                    }}
                  />
                </FormControl>
                {errors.business_address && touched.business_address && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.business_address}
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={3} className="px-15">
              <Grid item xs={2} sm={12} md={4}>
                <Box className="information-div">
                  <FormControl className="w-100">
                    <Typography component="label">Location Name</Typography>
                    <OutlinedInput
                      name="location_name"
                      value={formik.values?.location_name}
                      onChange={(e) => handleChange(e)}
                      onBlur={(e) => {
                        handleBlur(e);
                        handleData(e);
                      }}
                    />
                  </FormControl>
                  {errors.location_name && touched.location_name && (
                    <Box Typography="small" className="float-left text-danger">
                      {errors.location_name}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Packing Charges</Typography>
                  <OutlinedInput
                    name="packing_charges"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                    value={formik.values?.packing_charges}
                  />
                </FormControl>
                {errors.packing_charges && touched.packing_charges && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.packing_charges}
                  </Box>
                )}
              </Grid>

              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">City</Typography>

                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    value={formik.values?.business_city}
                    name="business_city"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                    disabled
                  >
                    {cities?.map((item) => {
                      return (
                        <MenuItem value={item.city_id}>
                          {item.city_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {errors.business_city && touched.business_city && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.business_city}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Type</Typography>

                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    value={formik.values?.business_type}
                    name="business_type"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                    disabled
                  >
                    {console.log("services", services)}
                    {services?.map((item, index) => {
                      return (
                        <MenuItem value={item.service_id}>
                          {item.service_name}
                        </MenuItem>
                      );
                    })}

                    {/* <MenuItem value="1">Groceries</MenuItem>
                                        <MenuItem value="2">Pan Shop</MenuItem>

                                        <MenuItem value="3">Restaurant</MenuItem> */}
                  </Select>
                </FormControl>
                {errors.business_type && touched.business_type && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.business_type}
                  </Box>
                )}
              </Grid>
            </Grid>
            <Divider></Divider>
            <Box className="ml-20" mt={2}>
              <Typography component="h4" className="sub_title">
                Location Details
              </Typography>
            </Box>
            <Grid container spacing={3} className="px-15">
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Location Latitude</Typography>
                  <OutlinedInput
                    name="location_latitude"
                    value={locationDetails?.latitude || formik.values?.location_latitude}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  />
                </FormControl>
                {errors.location_latitude && touched.location_latitude && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.location_latitude}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Location Longitude</Typography>
                  <OutlinedInput
                    name="location_longitude"
                    value={locationDetails?.longitude || formik.values?.location_longitude}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  />
                </FormControl>
                {errors.location_longitude && touched.location_longitude && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.location_longitude}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={3} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Locality</Typography>
                  <OutlinedInput
                    name="locality"
                    value={locationDetails?.locality || formik.values?.locality}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  />
                </FormControl>
                {errors.locality && touched.locality && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.locality}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Sub locality</Typography>
                  <OutlinedInput
                    name="sub_locality"
                    value={locationDetails?.sublocality || formik.values?.sub_locality}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  />
                </FormControl>
                {errors.sub_locality && touched.sub_locality && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.sub_locality}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Route</Typography>
                  <OutlinedInput
                    name="location_route"
                    value={formik.values?.location_route}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  />
                </FormControl>
                {errors.location_route && touched.location_route && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.location_route}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={3} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Pin Code</Typography>
                  <OutlinedInput
                    name="pincode"
                    value={locationDetails?.pincode || formik.values?.pincode}
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  />
                </FormControl>
                {errors.pincode && touched.pincode && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.pincode}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Opening Time</Typography>
                  {console.log(
                    "convertTime(formik.values?.opening_time1)",
                    formik.values
                  )}
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    value={
                      openTime
                        ? openTime
                        : convertTime(formik.values?.opening_time1)
                    }
                    name="opening_time1"
                    onChange={(e) => {
                      handleChange(e);
                      setOpenTime(e.target.value);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  >
                    {time.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                {errors.opening_time1 && touched.opening_time1 && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.opening_time1}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Closing Time</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    value={
                      closeTime
                        ? closeTime
                        : convertTime(formik.values?.closing_time1)
                    }
                    name="closing_time1"
                    onChange={(e) => {
                      handleChange(e);
                      setCloseTime(e.target.value);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  >
                    {time.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                {errors.closing_time1 && touched.closing_time1 && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.closing_time1}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={3} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Opening Time 2</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    value={
                      openTime2
                        ? openTime2
                        : convertTime(formik.values?.opening_time2)
                    }
                    name="opening_time2"
                    onChange={(e) => {
                      handleChange(e);
                      setOpenTime2(e.target.value);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  >
                    {time.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                {errors.opening_time2 && touched.opening_time2 && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.opening_time2}
                  </Box>
                )}
              </Grid>
              <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">Closing Time 2</Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    value={
                      closeTime2
                        ? closeTime2
                        : convertTime(formik.values?.closing_time2)
                    }
                    name="closing_time2"
                    onChange={(e) => {
                      handleChange(e);
                      setCloseTime2(e.target.value);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  >
                    {time.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                {errors.closing_time2 && touched.closing_time2 && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.closing_time2}
                  </Box>
                )}
              </Grid>
              {/* <Grid item xs={2} sm={12} md={4}>
                <FormControl className="w-100">
                  <Typography component="label">
                    SELECT ORDER DELIVERY DAYS
                  </Typography>
                  <Select
                    labelId="demo-isolation-label"
                    id="isolation-select"
                    className="select"
                    // onChange={handleSelectOxygen}
                    // input={<OutlinedInput />}
                    value={formik.values?.delivery_days}
                    name="delivery_days"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => {
                      handleBlur(e);
                      handleData(e);
                    }}
                  >
                    <MenuItem value="0">Instant Delivery</MenuItem>
                    <MenuItem value="1">1 Day</MenuItem>
                    <MenuItem value="2">2 Days</MenuItem>
                    <MenuItem value="3">3 Days</MenuItem>
                    <MenuItem value="4">4 Days</MenuItem>
                    <MenuItem value="5">5 Days</MenuItem>
                    <MenuItem value="6">6 Days</MenuItem>
                    <MenuItem value="7">7 Days</MenuItem>
                  </Select>
                </FormControl>
                {errors.delivery_days && touched.delivery_days && (
                  <Box Typography="small" className="float-left text-danger">
                    {errors.delivery_days}
                  </Box>
                )}
              </Grid> */}
              {/* <Grid item xs={2} sm={3} md={4}>

                            <FormControl className='w-100'>
                            <Typography component="label">Closed DAYS</Typography>
                                <Select
                                    labelId="demo-isolation-label"
                                    id="isolation-select"
                                    className="select"
                                    // onChange={handleSelectOxygen}
                                    // input={<OutlinedInput />}
                                    value="Sunday"
                                    name='closed_days'
                                    onChange={(e)=>handleChange(e)}
                                    onBlur={(e) => { handleBlur(e); handleData(e) }}
                                >
                                    <MenuItem value="Sunday">Sunday</MenuItem>
                                    <MenuItem value="Monday">Monday</MenuItem>
                                    <MenuItem value="Tuesday">Tuesday</MenuItem>
                                    <MenuItem value="Wednesday">Wednesday</MenuItem>
                                    <MenuItem value="Thursday">Thursday</MenuItem>
                                    <MenuItem value="Friday">Friday</MenuItem>
                                    <MenuItem value="Saturaday">Saturaday</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid> */}
            </Grid>

            {/* <Divider></Divider> */}
            <Box className="ml-20" mt={2}>
              <Typography component="h4" className="sub_title">
                Closed Days
              </Typography>
            </Box>

            <Grid container spacing={3} className="p-15">
              <FormGroup className="flex flex-row align-center flex-between w-100">
                {console.log("closedDays", closedDays)}
                {/* <FormControlLabel control={<Checkbox  onChange={(e) => handleClosedDays(e)}
                                    value='monday' name='closed_days[]' checked={formik.values?.closed_days?JSON.parse(formik.values?.closed_days).includes('Monday'):false} />}  label="Monday" /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleClosedDays(e)}
                      value="monday"
                      name="closed_days[]"
                      checked={closedDays.includes("monday")}
                    />
                  }
                  label="Monday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleClosedDays(e)}
                      value="tuesday"
                      name="closed_days[]"
                      checked={closedDays.includes("tuesday")}
                    />
                  }
                  label="Tuesday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleClosedDays(e)}
                      value="wednesday"
                      name="closed_days[]"
                      checked={closedDays.includes("wednesday")}
                    />
                  }
                  label="Wednesday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleClosedDays(e)}
                      value="thursday"
                      name="closed_days[]"
                      checked={closedDays.includes("thursday")}
                    />
                  }
                  label="Thursday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleClosedDays(e)}
                      value="friday"
                      name="closed_days[]"
                      checked={closedDays.includes("friday")}
                    />
                  }
                  label="Friday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleClosedDays(e)}
                      value="saturday"
                      name="closed_days[]"
                      checked={closedDays.includes("saturday")}
                    />
                  }
                  label="Saturday"
                />
                {console.log("closedDays", closedDays)}
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => handleClosedDays(e)}
                      value="sunday"
                      name="closed_days[]"
                      checked={closedDays.includes("sunday")}
                    />
                  }
                  label="Sunday"
                />
              </FormGroup>
            </Grid>

            <Divider></Divider>
            <Box className="p-15">
              <Button
                variant="outlined"
                color="error"
                mt={2}
                mb={3}
                className="retail-btn float-right"
                type="submit"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
});

export default AddLocationModal;
