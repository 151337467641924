import React from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Switch,
  FormControl,
  Divider,
  OutlinedInput,
} from "@mui/material";

import Header from "../Header/Header";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import Bredcrum from "../Bredcrum";
import Calender from "../Calender/Calender";
import MemberTable from "../Customer/MemberTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { FormControlLabel } from "@mui/material";
import AddTaxesModal from "./AddTaxesModal";
import AddFeesModal from "./AddFessModal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import { useDemoData } from "@mui/x-data-grid-generator";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const FeesColumns = [
  { name: "Fee Name", options: { filterOptions: { fullWidth: true } } },
  "Fee Type",
  "Fee Amount",
  "Fee Status",
  "",
];
const Feesdata = [
  [
    "Convenience Fee",
    "Percentage",
    "1",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
  [
    "Shipping & Handling Fee",
    "Percentage",
    "3",
    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />,
    <Button className="btn-outline-primary btn-space">Edit</Button>,
  ],
];
const managementColumns = [
  { name: "Item Name", options: { filterOptions: { fullWidth: true } } },
  "Start",
  "End Balance",
];
const managementdata = [
  ["India Gate Basmati Rice", "10Kg", "8.7Kg"],

  ["India Gate Corn Flour", "5Kg", "3.2Kg"],
];

const meatdata = [
  ["Chicken", "5Kg", "1.7Kg"],
  ["Mutton", "3Kg", "0.5Kg"],
  ["Fish", "3Kg", "1Kg"],
];
const subdata = [
  ["Veg Seek Kebab", "2", "₹600"],
  ["Paneet Tikka", "1", "₹200"],
];
const subdata1 = [
  ["Dragon Baby Corn", "2", "₹2000"],
  ["Chilly Mushroom", "2", "₹1000"],
  ["Garlic Mushroom", "3", "₹1500"],
];
const subaccordioncolumns = [
  { name: "Item Name", options: { filterOptions: { fullWidth: true } } },
  "Start",
  "End Balance",
];
const suboptions = {
  search: false,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  pagination: false,
  responsive: "scrollMaxHeight",
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

const managementOptions = {
  search: true,
  download: false,
  print: false,
  viewColumns: false,
  filter: false,
  filterType: false,
  selectableRows: false,
  // pagination: false,
  // responsive,
  // tableBodyHeight,
  // tableBodyMaxHeight,

  textLabels: {
    body: {
      noMatch: "No data available in table",
    },
  },
  onTableChange: (action, state) => {
    console.log(action);
    console.dir(state);
  },
};

export default function SkuUtilizationUnit() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const ref3 = React.useRef();
  const ref2 = React.useRef();
  return (
    <>
      <Header />
      <Box component="main">
        <Grid container>
          <Grid xs="12">
            <Card xs="11" className="custom_card">
              <Bredcrum />

              <CardContent>
                <Grid container className="full_content">
                  <Grid xs="12" mt={1}>
                    <Box
                      sx={{ display: "flex" }}
                      className="filters sales-filters"
                    >
                      <Box className="flex ">
                        {/* <Calender className="calenders" /> */}
                        <FormControl className="w-100">
                          <Typography component="label" mr={2}>
                            From Date
                          </Typography>
                          <OutlinedInput type="date" />
                        </FormControl>
                        <FormControl className="w-100">
                          <Typography component="label" mr={2}>
                            To Date
                          </Typography>
                          <OutlinedInput type="date" />
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid container spacing={2} mt={3}>
                    <Grid item md="5">
                      <Card md="12">
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <Typography component="p">Sales</Typography>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container spacing={2} mt={1}>
                            <Grid item md={12} className="accordion-div">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="ArrowDropUpIcon"
                                    >
                                      <path d="m7 16 4-7 4 7z"></path>
                                    </svg>
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="accordian-header"
                                >
                                  Veg
                                  <Typography>10</Typography>
                                  <Typography>₹7500</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={2}>
                                    <Grid item md={12}>
                                      <Accordion className="sub-accordion">
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Tandoori Kababs
                                          <Typography>3</Typography>
                                          <Typography>₹3000</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <MemberTable
                                                columns={subaccordioncolumns}
                                                options={suboptions}
                                                data={subdata}
                                              />
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>

                                    <Grid item md={12}>
                                      <Accordion className="sub-accordion">
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Starters
                                          <Typography>7</Typography>
                                          <Typography>₹4500</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <MemberTable
                                                columns={subaccordioncolumns}
                                                options={suboptions}
                                                data={subdata1}
                                              />
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>

                            <Grid item md={12} className="accordion-div">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="ArrowDropUpIcon"
                                    >
                                      <path d="m7 16 4-7 4 7z"></path>
                                    </svg>
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="accordian-header"
                                >
                                  Non Veg
                                  <Typography>3</Typography>
                                  <Typography>₹5000</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={2}>
                                    <Grid item md={12}>
                                      <Accordion className="sub-accordion">
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Chicken Boneless Kebabs
                                          <Typography>1</Typography>
                                          <Typography>₹1500</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <MemberTable
                                                columns={subaccordioncolumns}
                                                options={suboptions}
                                                data={subdata}
                                              />
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>

                                    <Grid item md={12}>
                                      <Accordion className="sub-accordion">
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                          className="accordian-header"
                                        >
                                          Mutton, Fish & Prawns
                                          <Typography>2</Typography>
                                          <Typography>₹1500</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <Grid container spacing={2}>
                                            <Grid item md={12}>
                                              <MemberTable
                                                columns={subaccordioncolumns}
                                                options={suboptions}
                                                data={subdata1}
                                              />
                                            </Grid>
                                          </Grid>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item md="7">
                      <Card>
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <Typography component="p">Suppliers</Typography>
                            </Grid>
                          </Grid>
                          <Divider></Divider>
                          <Grid container spacing={2} mt={1}>
                            <Grid item md={12} className="accordion-div">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="ArrowDropUpIcon"
                                    >
                                      <path d="m7 16 4-7 4 7z"></path>
                                    </svg>
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="accordian-header"
                                >
                                  Atta & Flour
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={2}>
                                    <Grid item md={12}>
                                      <MemberTable
                                        columns={managementColumns}
                                        options={managementOptions}
                                        data={managementdata}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>

                            <Grid item md={12} className="accordion-div">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="ArrowDropUpIcon"
                                    >
                                      <path d="m7 16 4-7 4 7z"></path>
                                    </svg>
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="accordian-header"
                                >
                                  Meat
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={2}>
                                    <Grid item md={12}>
                                      <MemberTable
                                        columns={managementColumns}
                                        options={managementOptions}
                                        data={meatdata}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>

                            <Grid item md={12} className="accordion-div">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="ArrowDropUpIcon"
                                    >
                                      <path d="m7 16 4-7 4 7z"></path>
                                    </svg>
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="accordian-header"
                                >
                                  Oil & Ghee
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={2}>
                                    <Grid item md={12}>
                                      <MemberTable
                                        columns={managementColumns}
                                        options={managementOptions}
                                        data={meatdata}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>

                            <Grid item md={12} className="accordion-div">
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={
                                    <svg
                                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                      focusable="false"
                                      aria-hidden="true"
                                      viewBox="0 0 24 24"
                                      data-testid="ArrowDropUpIcon"
                                    >
                                      <path d="m7 16 4-7 4 7z"></path>
                                    </svg>
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="accordian-header"
                                >
                                  Masala
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Grid container spacing={2}>
                                    <Grid item md={12}>
                                      <MemberTable
                                        columns={managementColumns}
                                        options={managementOptions}
                                        data={meatdata}
                                      />
                                    </Grid>
                                  </Grid>
                                </AccordionDetails>
                              </Accordion>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AddTaxesModal ref={ref2} />
        <AddFeesModal ref={ref3} />
      </Box>
    </>
  );
}
