import React, { useState } from 'react';

function ErrorBoundary({ children }) {
    const [hasError, setHasError] = React.useState(false);
    const [error, setError] = React.useState(null);
  
    const componentDidCatch = (error, info) => {
      if (error instanceof SyntaxError && error.message.includes('JSON.parse')) {
        // Handle JSON.parse errors
        setError(new Error('Invalid JSON data'));
        setHasError(true);
      } else {
        // Handle other errors
        setError(error);
        setHasError(true);
      }
  
      // You can log the error or send it to a logging service here
    };
  
    if (hasError) {
      return <div>Something went wrong: {error.message}</div>;
    }
  
    return children;
  }
  
  export default ErrorBoundary;