import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { Divider, OutlinedInput, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem } from "@mui/material";
import productview from "../images/product-view.jpg";
import MemberTable from "../Customer/MemberTable";
import withReactContent from "sweetalert2-react-content";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Swal from "sweetalert2";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { Formik, useFormik, Form, Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { AddItemSchema } from "../Validation";
import AddProductModal from "./AddProductModal";
import {
  setMeasurementId,
  setProductId,
  setProductSku,
  setCity,
  setSegments,
} from "../Redux/actions/userActions";
import api from "../Api";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 17,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9568ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 15,
    height: 13,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ViewEditSuppliesModal = forwardRef((props, ref2) => {
  const [productName,setProductName]= React.useState();
  const [itemStock,setItemStock] = React.useState();
  const segment = useSelector((state) => state.user.segments);
  const response = useSelector((state) => state.user.user);
  const [locations, setLocation] = React.useState();
  const handleClick = () => {
    setShowState(!showState);
  };
  const [measurement, setMeasurement] = React.useState();
  let measurementId = useSelector((state) => state.user.measurement_id);
  let productId = useSelector((state) => state.user.product_id);
  const filter = createFilterOptions();
  const dispatch = useDispatch();
  const [showState, setShowState] = React.useState(false);
  const [itemUomDetails, setItemUomDetails] = React.useState();
  const [itemStockDetails, setItemStockDetails] = React.useState();
  const [metricUom, setMetricUom] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [edit, setEdit] = React.useState(true);
  const [subSegment, setSubSegment] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () =>{ props?.getItemStockDetails(); setOpen(false);}
  const changeEdit = () => setEdit((edit) => !edit);
  const [addOpen, setAddOpen] = React.useState(false);
  const handleOpenAdd = () => setAddOpen(true);
  const handleOpenEdit = () => setShow(true);
  const handleCloseAdd = () => setAddOpen(false);
  const [value, setValue] = React.useState(null);
  const [segmentValue, setSegmentValue] = React.useState(null);
  const [idleStock,setIdleStock] = React.useState(false);
  const [lowStock,setLowStock] = React.useState(false);
  const [productIdleStockDays,setProductIdleStockDays] = React.useState();
  const [productLowStockQty,setProductLowStockQty]= React.useState();
  const data = [
    [
      "12/03/2023",
      "474858",
      "Accepted",
      "₹ 22890",
      "Pending",
      "Cheque",
      "12345678",
      "12/03/2023",
    ],
  ];
  const [aisleRacks, setAisleRacks] = React.useState();
  const getMerchantSubSegment = (e) => {
    api
      .getData(
        "/api/segments/get_merchant_sub_segment_aisle_row_details?merchant_id=" +
          response?.merchant_id +
          "&sub_segment_id=" +
          e.target.value
      )
      .then((response) => {
        if (response.data[0].Status === "200") {
          setAisleRacks(
            JSON.parse(response.data[0]?.sub_segement_aisle_row_details)[0]
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUomData = (metricName) => {
    api
      .getData(
        "/api/items/metric_uom_details_metric_name?metric_name=" +
        metricName
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setMetricUom(JSON.parse(response.data[0]?.Metric_uom_details));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
 

  const [subSuggestions, setSubSuggestions] = React.useState();
  const subSegmentSuggestions = () => {
    api
      .getData(
        "/api/segments/master_sub_segments?segment_id=" +
          (segment && segment.length > 0 ? segment[0].merchant_segment_id : 1)
      )
      .then((response) => {
        console.log("response", response);
        //   if (JSON.parse(response.data[0].Status) === 200) {

        setSubSuggestions(response.data);
        // setSpinner(false);
        //   }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const managementColumns = [
    { name: "Item Name", options: { filterOptions: { fullWidth: true } } },
    "UOM",
    "No. of Units",
  ];
  const managementdata = [["India Gate Basmati Rice", "gm", "150gms"]];

  const options = {
    filter: true,
    print: false,
    viewColumns: false,
    onFilterChange: false,
    download: false,
    print: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    onFilterChange: (changedColumn, filterList) => {
      console.log(changedColumn, filterList);
    },
    selectableRows: false,
    isRowSelectable: false,
    expandableRowsHeader: false,
    icons: { Add: () => "Add Row" },
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    rowsPerPage: 10,
  };

  const managementOptions = {
    search: true,
    print: false,
    viewColumns: false,
    onFilterChange: false,
    download: false,
    print: false,
    filter: false,
    filterType: false,
    selectableRows: false,
    // pagination: false,
    // responsive,
    // tableBodyHeight,
    // tableBodyMaxHeight,

    textLabels: {
      body: {
        noMatch: "No data available in table",
      },
    },
    onTableChange: (action, state) => {
      console.log(action);
      console.dir(state);
    },
  };
  const getSubSegment = (segmentId) => {
    api
      .getData(
        "/api/segments/get_merchant_sub_segment_details?merchant_id=" +
          response?.merchant_id +
          "&merchant_segment_name=" +
          segmentId
      )
      .then((response) => {
        if (response.data[0].Status === "200") {
          setSubSegment(
            JSON.parse(response.data[0].Merchant_Sub_Segement_Details)
          );
          if (
            JSON.parse(response.data[0].Merchant_Sub_Segement_Details) === null
          ) {
            // showSwalWithLinkProfileError("Profile is Not Completed. Please Add Location");
          }
          // setIsLoading(false);
        }
      });
  };
  // useEffect(() => {
  //     if (measurementId) getItemUomDetails();
  //   }, [measurementId]);
  // useEffect(() => {
  //   // fetchData();
  //   getItemStockDetails();
  //   fetchLocations();
  //   subSegmentSuggestions();
  //   segmentGetData();
  //   getSegment();
  //   getSubSegment(
  //     segment && segment?.length > 0 ? segment[0].merchant_segment_id : 0
  //   );
  //   setSegementId(
  //     segment && segment?.length > 0 ? segment[0].merchant_segment_id : 0
  //   );
  // }, []);
  // useEffect(()=>{
  //   console.log('locations',locations);
  //   if(locations?.length < 1){
  //     showSwalWithLinkProfileError("Profile is Not Completed. Please Add Location");
  //   }
  // },[locations]);
  // useEffect(()=>{
  //   if(segmentList?.length < 1){
  //     showSwalWithLinkProfileError("Profile is Not Completed. Please Add Segment");

  //   }
  // },[segmentList]);
  const [alignment, setAlignment] = React.useState("left");
  const [brandName, setBrandName] = React.useState("nonVeg");
  const getBrandName = (value) => {
    setBrandName(value);
  };
  const handleSubmit = (values) => {
    console.log('values',values);
    console.log('formik.values',formik.values);
    //
    let data = {

   "product_id": props.itemStock?.product_id || formik.values.product_id || "0",
    "merchant_id": response?.merchant_id,
    "city_id":response?.city_id,
      "location_id":locations ? locations[0].merchant_location_id : "0",
      "product_name":productName,
     "brand_name":brandName,
     "product_quantity":"10",
     "product_description":"NA",
     "product_how_to_use":"NA",
     "product_specifications":"NA",
     "product_segment_name":formik.values.product_segment_name,
     "product_sub_segment_name":formik.values.product_sub_segment_name,
     "product_aisle":1,
     "product_row":1,
     "product_master_sku":"NA",
     "product_image":profileImage || formik.values.product_image,
      "product_status":
      values.product_status ? values.product_status : "0",
     "ptype":"Ingredient"
    // if (images.length > 0) {
    //   images.forEach((image, index) => {
    //     formData.append("productimage" + (index + 1), image);
    //   });
    // }
    }
 
    try {
      api.postData("/api/segments/Ingrerdients_Create_Update", data).then((res) => {
        console.log("response", res);
        if (res.data[0].Status === "200") {
          dispatch(setProductId(res.data[0].ProductId));
          if (formik.values.product_uom) {
            if (formik.values.product_uom === "kg") {
              formik.values.one_unit_contains = 1000;
              formik.values.base_uom = "gms";
            } else if (formik.values.product_uom === "gms") {
              formik.values.one_unit_contains = 1;
              formik.values.base_uom = "gms";
            } else if (formik.values.product_uom === "lbs") {
              formik.values.one_unit_contains = 453.592;
              formik.values.base_uom = "gms";
            } else if (formik.values.product_uom === "quintals") {
              formik.values.one_unit_contains = 100000;
              formik.values.base_uom = "gms";
            } else if (formik.values.product_uom === "tonnes") {
              formik.values.one_unit_contains = 1000000;
              formik.values.base_uom = "gms";
            } else if (formik.values.product_uom === "Ltr") {
              formik.values.one_unit_contains = 1000;
              formik.values.base_uom = "ml";
            } else if (formik.values.product_uom === "ml") {
              formik.values.one_unit_contains = 1;
              formik.values.base_uom = "ml";
            }
          }
          const data = {
            measurement_id: "0",
            product_id: res.data[0].ProductId,
            merchant_id: response?.merchant_id,
            product_complete_sku: "NA",
            product_metric: formik.values.product_metric,
            product_uom: formik.values.product_uom,
            no_of_units: formik.values.no_of_units,
            one_unit_contains: formik.values.one_unit_contains,
            base_uom: formik.values.base_uom,
            msr_price: "0",
            cost_price: "0",
            sale_price: formik.values.sale_price,
            maintain_stock: 0,
          };

          api
            .postData("/api/items/create_product_uom", data)
            .then((response) => {
              if (response.data[0].Status === "200") {
                showSwalWithLink(response.data[0].Message);
                setMeasurement(response.data[0].measurement_id);
                dispatch(setMeasurementId(response.data[0].measurement_id));
                // dispatch(setProductSku(response.data[0].Product_master_sku));
                // setSuccess(true);
                // getItemUomDetails();
              }
            })
            .catch((error) => {
              // Handle error
              console.error(error);
            });
          // dispatch(setProductSku(response.data[0].Product_master_sku));
          // setProductId(response.data[0].Product_master_sku)
          // setSuccess(true);
        }else{
          showSwalWithLinkError(res.data[0].Message)
        }
      });
    } catch (error) {
      // Handle network error
    }

    // setSubmitting(false);
  };
  const getItemStockDetailsProduct = (pid,mid) => {
    api.getData('/api/items/get_items_stock_details?product_id=' +(pid?pid: productId)+ '&measurement_id=' + (mid?mid:measurementId))
      .then((response) => {

        if (response.data[0]?.Status === "200") {
          setItemStockDetails(JSON.parse(response.data[0]?.Item_Stock_Details))
        }


      })
      .catch((error) => {
        console.log(error)
      })


  }
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState("");
  const [profileImage, setProfileImage] = React.useState(null);
  const handleImageSelection = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    
      uploadImage(e.target.files[0]);
    
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const uploadImage = (file) => {
    const formData = new FormData();
    
    formData.append("uploaded_file", file?file:formik.values.segment_image);

    try {
      api
        .updateData("/api/segments/UploadFile", formData)
        .then((response) => {
          if (response.data.Status === 200) {
            setProfileImage(response.data.Filename)
          }
        });
    } catch (error) {
      // Handle network error
    }
  };
  const [subSegmentValue, setSubSegmentValue] = React.useState();
  const [masterSubSegments, setMasterSubSegments] = React.useState();
  const getMasterSubSegments = (segmentVal) => {
    api
      .getData(
        "/api/universal/Get_Master_Sub_Segments?segment=" + segmentVal.segment
      )
      .then((response) => {
        if (response.data[0].Status === "200") {
          setMasterSubSegments(JSON.parse(response.data[0]?.SubSegmentDetails));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [stockIngredientDetails, setStockIngredientDetails] = React.useState();
  const getIngredientDetailsByProductname = (productName) => {
    setProductName(productName?.Product_Name);
    api
      .getData(
        "/api/universal/Get_Ingredient_Details_By_Productname?product_name=" +
          productName?.Product_Name
      )
      .then((response) => {
        if (response.data[0]?.Status === "200") {
          setStockIngredientDetails(
            JSON.parse(response.data[0]?.IngredientDetails)[0]
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const initialValues = {
    product_id: props.itemStock?.product_id || "",
    merchant_id: response?.merchant_id,
    city_id: props.itemStock?.city_id || "",
    location_id: props.itemStock?.location_id || "",
    product_name: props.itemStock?.product_name || "",
    brand_name: props.itemStock?.brand_name || "",
    product_quantity: props.itemStock?.product_quantity || "",
    product_description: props.itemStock?.product_description || "",
    product_how_to_use: props.itemStock?.product_how_to_use || "NA",
    product_specifications: props.itemStock?.product_specifications || "NA",
    product_segment_name:  props.itemStock?.merchant_segment_name || "",
    product_metric:  props.itemStock?.product_metric || "",
    product_uom:  props.itemStock?.product_uom || "",
    no_of_units:  props.itemStock?.no_of_units || "",
    product_sub_segment_name:  props.itemStock?.merchant_sub_segment_name || "",
    product_image:  props.itemStock?.product_image || "",
    product_master_sku: props.itemStock?.product_master_sku || "",
    Product_status:  props.itemStock?.Product_status || 0,
    ptype: "Ingredient",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: AddItemSchema,
    onSubmit: handleSubmit,
  });
  useEffect(() => {
    formik.setValues(initialValues);
    setValue(props.itemStock?.product_name);
    console.log('props.itemStock',props.itemStock);
    if(!(props.itemStock === undefined) && !(props.itemStock === null)){

    if(props.itemStock?.product_idle_stock_days){
    setProductIdleStockDays(props.itemStock?.product_idle_stock_days);
    setIdleStock(true);
    }
    if(props.itemStock?.product_low_stock_qty){
    setProductLowStockQty(props.itemStock?.product_low_stock_qty);
    setLowStock(true);
    }
    if(props.itemStock?.product_name){
      setProductName(props.itemStock?.product_name);
    
      }
   
    if(props.itemStock?.product_id && props.itemStock?.measurement_id){
      dispatch(setProductId(props.itemStock?.product_id));
      dispatch(setMeasurementId(props.itemStock?.measurement_id));
     
      getItemStockDetailsProduct(props.itemStock?.product_id,props.itemStock?.measurement_id);
    
      }
    }else{
      setProductIdleStockDays('');
      setIdleStock(false);
      setProductLowStockQty('');
      setLowStock(false);
    }
      if(props.itemStock){
        setItemStock(props.itemStock);
      }
  }, [props.itemStock]);
  useEffect(() => {
    if (stockIngredientDetails) {
      if (stockIngredientDetails?.Product_Image_Link) {
        formik.values.product_image =
          stockIngredientDetails?.Product_Image_Link;
      }
      if (stockIngredientDetails?.Brand) {
        formik.values.brand_name = stockIngredientDetails?.Brand;
      }
      if (stockIngredientDetails?.Segment) {
        formik.values.product_segment_name = stockIngredientDetails?.Segment;
        getSubSegment(stockIngredientDetails?.Segment);
      }
      if (stockIngredientDetails?.Sub_Segment) {
        formik.values.product_sub_segment_name =
          stockIngredientDetails?.Sub_Segment;
      }
      if (stockIngredientDetails?.Product_Image_Link) {
        formik.values.product_image =
          stockIngredientDetails?.Product_Image_Link;
      }
      if (stockIngredientDetails?.UOM_Metric) {
        formik.values.product_metric = stockIngredientDetails?.UOM_Metric;
        getUomData(stockIngredientDetails?.UOM_Metric);
      }
      if (stockIngredientDetails?.UOM) {
        formik.values.product_uom = stockIngredientDetails?.UOM;
      }
      if (stockIngredientDetails?.no_of_units) {
        formik.values.no_of_units = stockIngredientDetails?.no_of_units;
      }
    }
  }, [stockIngredientDetails]);
  const MySwal = withReactContent(Swal);
  const showSwalWithLink = (message) => {
    MySwal.fire({
      icon: "success",
      title: message,
    }).then(function () {
      
    });
  };
  const showSwalWithLinkError = (message) => {
    MySwal.fire({
      icon: "error",
      title: message,
    })
  };
  const handleItemStatusChange = (item) => {
    let data = {
      product_stock_id: item.product_stock_id,
      product_id: item.product_id,
      measurement_id: item.measurement_id,
    };
    try {
      api.postData("/api/items/stock_status_change", data).then((response) => {
        console.log("response", response);
        if (response.data[0].Status === "200") {
          showSwalWithLink(response.data[0].Message);
          // getItemStockDetails();
        }
      });
    } catch (error) {
      // Handle network error
    }
  };

  const [inputValue, setInputValue] = useState("");
  const [ingredientList, setIngredientList] = useState([]);
  useEffect(() => {
    async function fetchData(val) {
      try {
        const response = await api.getData(
          "/api/universal/Get_Search_Productname?search_parameter=" + val
        );
        console.log("response", response);
        if (response.data[0].Status === "200") {
          setIngredientList(JSON.parse(response.data[0].IngredientDetails));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIngredientList([]); // Handle the error by setting an empty array.
      }
    }

    if (inputValue !== "") {
      fetchData(inputValue);
    }
  }, [inputValue]);

  const menuSegments = [
    { value: "1", key: "Cooking Essentials", label: "Cooking Essentials" },
    { value: "2", key: "Cleaning & Household", label: "Cleaning & Household" },
    { value: "3", key: "Beverages", label: "Beverages" },
    { value: "4", key: "Personal Care", label: "Personal Care" },
    { value: "5", key: "Dairy", label: "Dairy" },
    {
      value: "6",
      key: "Snacks & Branded Foods",
      label: "Snacks & Branded Foods",
    },
    { value: "7", key: "Dals & Staples", label: "Dals & Staples" },
  ];

  const subSegmentMenuItems = [
    { value: "1", key: "Tea & Coffee", label: "Tea & Coffee" },
    { value: "2", key: "Water & Soda", label: "Water & Soda" },
    { value: "3", key: "Soft Drinks", label: "Soft Drinks" },
    {
      value: "4",
      key: "Fruit Juices & Energy Drinks",
      label: "Fruit Juices & Energy Drinks",
    },
    { value: "5", key: "Health Drinks", label: "Health Drinks" },
  ];
//  const checkIngredient = () =>{

//   if(formik.values.product_name === ''){
//     showSwalWithLinkError("Please Select Ingredient");
//   }
//  }
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   const segmentValue =
  //     name === "product_segment_id" ? value : formik.values.product_segment_id;
  //   const subsegmentValue =
  //     name === "product_sub_segment_id"
  //       ? value
  //       : formik.values.product_sub_segment_id;

  //   const brandNameValue = formik.values.brand_name;
  //   const itemNameValue = formik.values.product_name;

  //   const selectedsegmentMenuItem = segment?.find(
  //     (item) => item.merchant_segment_id === segmentValue
  //   );
  //   const selectedSubMenuItem = subSegment?.find(
  //     (item) => item.merchant_sub_segment_id === subsegmentValue
  //   );

  //   const segmentSubstring =
  //     selectedsegmentMenuItem?.merchant_segment_name?.substring(0, 3) || "";
  //   const subsegmentSubstring =
  //     selectedSubMenuItem?.merchant_sub_segment_name?.substring(0, 2) || "";
  //   const brandNameSubstring = brandNameValue?.substring(0, 3) || "";
  //   const itemNameSubstring = itemNameValue?.substring(0, 3) || "";
  //   if (name === "product_name") {
  //     const itemNameValue = formik.values.product_name;
  //     setItemNameSkuPart(itemNameValue?.substring(0, 3));
  //   }
  //   if (name === "brand_name") {
  //     const brandNameValue = formik.values.brand_name;
  //     setBrandSkuPart(brandNameValue?.substring(0, 3));
  //   }
  //   if (name === "product_segment_id") {
  //     const segmentValue =
  //       name === "product_segment_id"
  //         ? value
  //         : formik.values.product_segment_id;
  //     const selectedsegmentMenuItem = segment?.find(
  //       (item) => item.merchant_segment_id === segmentValue
  //     );
  //     setSegmentSkuPart(
  //       selectedsegmentMenuItem?.merchant_segment_name?.substring(0, 3)
  //     );
  //   }
  //   if (name === "product_sub_segment_id") {
  //     const subsegmentValue =
  //       name === "product_sub_segment_id"
  //         ? value
  //         : formik.values.product_sub_segment_id;
  //     const selectedSubMenuItem = subSegment?.find(
  //       (item) => item.merchant_sub_segment_id === subsegmentValue
  //     );
  //     setSubSegmentSkuPart(
  //       selectedSubMenuItem?.merchant_sub_segment_name?.substring(0, 2)
  //     );
  //   }

  //   const newProductId = `${segmentSubstring}-${subsegmentSubstring}-${brandNameSubstring}-${itemNameSubstring}`;

  //   setProductSkus(newProductId);

  //   // if (formik1.values.no_of_units || formik1.values.product_uom) {
  //   //   const product_uom =
  //   //     name === "product_uom" ? value : formik1.values.product_uom;
  //   //   const product_uomstring = product_uom?.substring(0, 2) || "";
  //   //   const itemNameno_of_units = formik1.values.no_of_units;
  //   //   console.log("itemNameno_of_units.length", productSku, productSku);
  //   //   setSizeSkuPart(
  //   //     `${
  //   //       itemNameno_of_units.length === 1
  //   //         ? "0" + itemNameno_of_units
  //   //         : itemNameno_of_units.substring(0, 2)
  //   //     }${product_uomstring}`
  //   //   );
  //   //   const productSku12 = `${productSku11 ? productSku11 : productSku}-${
  //   //     itemNameno_of_units.length === 1
  //   //       ? "0" + itemNameno_of_units
  //   //       : itemNameno_of_units.substring(0, 2)
  //   //   }${product_uomstring}`;
  //   //   setProductSku12(productSku12);
  //   //   // dispatch(setProductSku(productSku12));
  //   //   // if(formik.values.product_name){

  //   //   // }
  //   // }
  // };
  const [images, setImages] = React.useState([]);
  const handleImageUpload = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };
  // const [measurementId, setMeasurement] = React.useState();
  const handleUomSubmit = (values) => {
    //  return false;
    // if (!formik1.values.merchant_id || formik1.values.merchant_id !== response.merchant_id) {
    //   formik1.values.merchant_id = response.merchant_id;
    // }
    // if (!formik1.values.product_id) {
    //   formik1.values.product_id = productId;
    // }
    // if (!formik1.values.product_complete_sku) {
    //   formik1.values.product_complete_sku = productSku12;
    // }
    // console.log('values', values);
    // api.postData('/api/items/create_product_uom', values).then((response) => {
    //   if (response.data[0].Status === "200") {
    //     showSwalWithLink(response.data[0].Message);
    //     // setMeasurement(response.data[0].measurement_id);
    //     dispatch(setMeasurementId(response.data[0].measurement_id));
    //     dispatch(setProductSku(response.data[0].Product_master_sku));
    //     // setSuccess(true);
    //     getItemUomDetails();
    //   }
    // }).catch(error => {
    //   // Handle error
    //   console.error(error);
    // });
  };

  // const formik1 = useFormik({
  //   initialValues: {
  //     measurement_id: "0",
  //     product_id: "",
  //     merchant_id: "",
  //     product_complete_sku: "",
  //     product_metric: "",
  //     product_uom: "",
  //     no_of_units: "",
  //     one_unit_contains: "",
  //     base_uom: "",
  //     msr_price: "",
  //     cost_price: "",
  //     sale_price: "",
  //     maintain_stock: showState ? 0 : 1,
  //   },
  //   validationSchema: createUom,
  //   onSubmit: handleUomSubmit,
  // });

  const ref = React.useRef();
  const ref1 = React.useRef();

  let navigate = useNavigate();
  const logoutDashboard = () => {
    navigate("/");
  };
  const ref4 = useRef();
  const memeberColumns = [
    "Batch Id",
    { name: "Supplier Name", options: { filterOptions: { fullWidth: true } } },
    "PO Number",
    "PO Date",
    "Delivery Date",
    "Qty",
    // "Sale Quantity",
    "Expiry Date",
  ];
  const ProductColumns = [
    {
      name: "product_batch_id",
      label: "Batch Id",
      //    label:'',
    },
    { name: "product_vendor_name", label: "Supplier Name" },
    { name: "po_number", label: "Po Number" },
    { name: "product_purchase_date", label: "Po Date" },
    { name: "product_purchase_date", label: "Delivery Date" },
    {
      name: "product_qty_purchased",
      label: "Qty",
    },
    
    {
      name: "product_expiry_date",
      label: "Expiry Date",

      // options: {
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //         const rowIndex = tableMeta.rowIndex;
      //         const rowData = subSegment[rowIndex];
      //         return <Button variant='outlined' color='error' mt={2} className='retail-btn' onClick={() => { ref3.current.log(); editSSOption(rowData); }}>Edit</Button>
      //     },
      // },
    },
    // {
    //   name: "product_stock_status",
    //   label: "Status",
    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const rowIndex = tableMeta.rowIndex;
    //       const rowData = itemStockDetails[rowIndex];
    //       return (
    //         <IOSSwitch
    //           checked={value === 0}
    //           onChange={(e) => {
    //             handleItemStatusChange(rowData);
    //           }}
    //         />
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "",
    //   label: "",

    //   options: {
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       const rowIndex = tableMeta.rowIndex;
    //       const rowData = itemStockDetails[rowIndex];
    //       return (
    //         <Button
    //           variant="outlined"
    //           color="error"
    //           mt={2}
    //           className="retail-btn"
    //           onClick={() => {
    //             ref4.current.log();
    //           }}
    //         >
    //           Edit
    //         </Button>
    //       );
    //     },
    //   },
    // },
  ];
  const { values, touched, errors, isSubmitting, handleChange, handleBlur } =
    formik;
  useImperativeHandle(ref2, () => ({
    log() {
      handleOpen();
      formik.setValues({});
      formik.setTouched({}, false);
    },
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className="view-more-content retail-content sni-content"
        >
          <Box className="modal-header p-15">
            <Typography id="modal-modal-title" variant="h5" component="h5">
              {props?.functionality === "edit"
                ? "INGREDIENT ID: " + props.itemStock?.product_id
                : "ADD INGREDIENT"}
            </Typography>
            <Typography id="modal-modal-title" variant="h5" component="h5">
              {props?.functionality === "edit"
                ? "IN STOCK QTY:" + props.itemStock?.product_qty_physical
                : ""}
            </Typography>
            <Box className="sub-modal-header">
              <CloseIcon onClick={handleClose} />
            </Box>
          </Box>
          <Divider></Divider>
          <Box className="modal-body">
            <Grid className="add_new_item">
              <Card>
                <CardContent>
                  {/* <Typography component="h4" className="sni-title">
                      SKU ID - 454989
                    </Typography> */}
<form onSubmit={formik.handleSubmit}>
                  <Box className="images d-flex">
                    <Box className="position-relative">
                      <Box className="upload__img-close"></Box>
                      <Box component="img" src={imagePreviewUrl?imagePreviewUrl:formik.values.product_image} />
                    </Box>

                    {/* <Box className="position-relative">
                        <Box className="upload__img-close"></Box>
                        <Box component="img" src={dal3} />
                      </Box>
  
                      <Box className="position-relative">
                        <Box className="upload__img-close"></Box>
                        <Box component="img" src={dal4} />
                      </Box> */}
                  </Box>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      component="label"
                      className="btn-upload"
                    >
                      Upload image
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        // disabled={edit ? true : false}
                        onChange={(e) => {
                          
                          handleImageSelection(e);
                        }}
                      />
                    </Button>
                  </Box>
                  <Grid container spacing={3} mt={1} mb={2}>
                    <Grid item xs={12} md={3}>
                      <FormControl className="w-100">
                        <Typography component="label">
                          Ingredient Name
                        </Typography>
                        {/* <OutlinedInput
                          placeholder="Item Name"
                          name="product_name"
                          value={formik.values?.product_name}
                          onChange={(e) => {
                            handleChange(e);getIngrientList(e.target.value)
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                        /> */}
                        {/* <Autocomplete
                                value={value}
                                name="product_segment_id"
                                className="select autoselect"
                                onChange={(event, newValue) => {
                                 
                                  if (typeof newValue === "string") {
                                    setValue({
                                      title: newValue,
                                    });
                                  } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setValue({
                                      title: newValue.inputValue,
                                    });
                                  } else {
                                    setValue(newValue);
                                  }
                              
                                }}
                                onBlur={formik.handleBlur}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;
                                  // Suggest the creation of a new value
                                  const isExisting = options.some(
                                    (option) =>
                                      inputValue ===
                                      option?.Product_Name
                                  );
                                  if (inputValue !== "" && !isExisting) {
                                    filtered.push({
                                      inputValue,
                                      title: `Add "${inputValue}"`,
                                    });
                                  }

                                  return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                options={ingredientList?ingredientList:[]}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  return option?.Product_Name;
                                }}
                                style={{ height: "0px" }}
                                renderOption={(props, option) => (
                                  <li
                                    {...props}
                                    value={option?.Product_Name}
                                  >
                                    {option?.Product_Name}
                                  </li>
                                )}
                                // sx={{ width: 300 }}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField {...params} onChange={(e)=>{ getIngrientList(e.target.value);
                                    formik.handleChange(e);}} />
                                )}
                              /> */}
                        <Autocomplete
                          value={value}
                          name="product_Name"
                          className="select autoselect"
                          onChange={(event, newValue) => {
                            getIngredientDetailsByProductname(newValue);
                            if (typeof newValue === "string") {
                              setValue({
                                title: newValue,
                              });
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setValue({
                                title: newValue.inputValue,
                              });
                            } else {
                              setValue(newValue);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          filterOptions={(options, params) => {
                            try {
                              options = Array.isArray(options) ? options : [];

                              const filtered = options?.filter((option) =>
                                option?.Product_Name.toLowerCase().includes(
                                  params.inputValue.toLowerCase()
                                )
                              );

                              const { inputValue } = params;

                              const isExisting = options?.some(
                                (option) => inputValue === option?.Product_Name
                              );

                              if (inputValue !== "" && !isExisting) {
                                filtered.push({
                                  inputValue,
                                  title: `Add "${inputValue}"`,
                                });
                              }

                              return filtered;
                            } catch (error) {
                              console.error("Error filtering options:", error);
                              return [];
                            }
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          options={ingredientList || []}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option?.inputValue) {
                              return option?.inputValue;
                            }
                            return option?.Product_Name;
                          }}
                          style={{ height: "0px" }}
                          renderOption={(props, option) => (
                            <li {...props} value={option?.Product_Name}>
                              {option?.Product_Name}
                            </li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="Product_Name"
                              onChange={(e) => {
                                setInputValue(e.target.value);
                                formik.handleChange(e);
                              }}
                            />
                          )}
                        />
                      </FormControl>
                      {errors.product_name && touched.product_name && (
                        <Box
                          Typography="small"
                          className="float-left text-danger mt-15"
                          mt={2}
                        >
                          {errors.product_name}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl className="w-100">
                        <Typography component="label">Brand</Typography>
                        <OutlinedInput
                          placeholder="Brand Name"
                          name="brand_name"
                          value={formik.values?.brand_name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                        />
                        {errors.brand_name && touched.brand_name && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.brand_name}
                          </Box>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl className="w-100">
                        <Typography component="label">Segment</Typography>
                        {/* <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          name="product_segment_id"
                          value={formik.values?.product_segment_id}
                          onChange={(e) => {
                            handleChange(e);
                            getSubSegment(e.target.value);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        >
                          {segment?.map((item) => {
                            return (
                              <MenuItem
                                key={item.merchant_segment_name}
                                value={item.merchant_segment_id}
                              >
                                {item.merchant_segment_name}
                              </MenuItem>
                            );
                          })}
                        </Select> */}
                        {/* {console.log(
                          "formik.values.product_segment_id",
                          formik.values.product_segment_id
                        )}
                        <Autocomplete
                          value={
                            formik.values.product_segment_name || segmentValue
                          }
                          name="product_segment_id"
                          className="select autoselect"
                          onChange={(event, newValue) => {
                            getMasterSubSegments(newValue);
                            if (typeof newValue === "string") {
                              setSegmentValue({
                                title: newValue,
                              });
                            } else if (newValue && newValue.inputValue) {
                              // Create a new value from the user input
                              setSegmentValue({
                                title: newValue.inputValue,
                              });
                            } else {
                              setSegmentValue(newValue);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const { inputValue } = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some(
                              (option) => inputValue === option?.segment
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                title: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          options={
                            props?.masterSegments ? props.masterSegments : []
                          }
                          getOptionLabel={(option) => {
                            if (typeof option === "string") {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option?.segment;
                          }}
                          style={{ height: "0px" }}
                          renderOption={(props, option) => (
                            <li {...props} value={option?.segment}>
                              {option?.segment}
                            </li>
                          )}
                          // sx={{ width: 300 }}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                            />
                          )}
                        /> */}
                         <OutlinedInput
                          name="product_segment_name"
                          value={formik.values?.product_segment_name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                        />
                      </FormControl>
                      {errors.product_segment_name &&
                        touched.product_segment_name && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.product_segment_name}
                          </Box>
                        )}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl className="w-100">
                        <Typography component="label">Sub-Segment</Typography>
                        {/* <Autocomplete
                          value={[formik.values.product_sub_segment_name]}
                          multiple
                          id="tags-outlined"
                          options={masterSubSegments || []}
                          getOptionLabel={(option) => option.title}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="filterSelectedOptions"
                              placeholder="Favorites"
                            />
                          )}
                        /> */}
                        {/* <Autocomplete
                                // value="Salt,Masala & Spices"
                                name="product_sub_segment_name"
                                className="select autoselect custom-autoselect"
                                onChange={(event, newValue) => {
                                 
                                  if (typeof newValue === "string") {
                                    setSubSegmentValue({
                                      title: newValue,
                                    });
                                  } else if (newValue && newValue.inputValue) {
                                    // Create a new value from the user input
                                    setSubSegmentValue({
                                      title: newValue.inputValue,
                                    });
                                  } else {
                                    setSubSegmentValue(newValue);
                                  }
                              
                                }}
                                onBlur={formik.handleBlur}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;
                                  // Suggest the creation of a new value
                                  const isExisting = options.some(
                                    (option) =>
                                      inputValue ===
                                      option?.sub_segment
                                  );
                                  if (inputValue !== "" && !isExisting) {
                                    filtered.push({
                                      inputValue,
                                      title: `Add "${inputValue}"`,
                                    });
                                  }

                                  return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                multiple
                                handleHomeEndKeys
                                options={masterSubSegments || []}
                                getOptionLabel={(option) => {
                                  if (typeof option === "string") {
                                    return option;
                                  }
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  return option?.sub_segment;
                                }}
                                style={{ height: "0px" }}
                                renderOption={(props, option) => (
                                  <li
                                    {...props}
                                    value={option?.sub_segment}
                                  >
                                    {option?.sub_segment}
                                  </li>
                                )}
                                // sx={{ width: 300 }}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField {...params} onChange={(e)=>{
                                    formik.handleChange(e);}} />
                                )}
                              /> */}
                        {/* <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          name="product_sub_segment_id"
                          value={formik.values?.product_sub_segment_id}
                          onChange={(e) => {
                            handleChange(e);
                            getMerchantSubSegment(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                          }}
                        >
                          {subSegment?.map((item) => {
                            return (
                              <MenuItem
                                key={item.merchant_sub_segment_name}
                                value={item.merchant_sub_segment_id}
                              >
                                {item.merchant_sub_segment_name}
                              </MenuItem>
                            );
                          })}
                        </Select> */}
                         <OutlinedInput
                          name="product_sub_segment_name"
                          value={formik.values?.product_sub_segment_name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                        />
                      </FormControl>
                      {errors.product_sub_segment_name &&
                        touched.product_sub_segment_name && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {errors.product_sub_segment_name}
                          </Box>
                        )}
                    </Grid>
                  </Grid>
                  <Divider></Divider>
                  <Grid container spacing={2} marginY={2}>
                    <Grid item md={4}>
                      <FormControl className="w-100">
                        <Typography component="label">Metric</Typography>
                        {/* <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          name="product_metric"
                          value={formik.values?.product_metric}
                          onChange={(e) => {
                            handleChange(e);
                            getUomData(e.target.value);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                          defaultValue="select"
                        >
                          <MenuItem value="Weight">Weight</MenuItem>
                          <MenuItem value="Volume">Volume</MenuItem>
                          <MenuItem value="Count">Count</MenuItem>
                        </Select> */}
                         <OutlinedInput
                          name="product_metric"
                          value={formik.values?.product_metric}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                        />
                      </FormControl>
                      {formik.errors.product_metric &&
                        formik.touched.product_metric && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {formik.errors.product_metric}
                          </Box>
                        )}
                    </Grid>
                    <Grid item md={4}>
                      <FormControl className="w-100">
                        <Typography component="label">UOM</Typography>
                        {/* <Select
                          labelId="demo-isolation-label"
                          id="isolation-select"
                          className="select"
                          name="product_uom"
                          value={formik.values?.product_uom}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                          defaultValue="select"
                        >
                          {metricUom?.map((item) => (
                            <MenuItem key={item.uom_name} value={item.uom_name}>
                              {item.uom_name}
                            </MenuItem>
                          ))}
                        </Select> */}
                        <OutlinedInput
                          name="product_uom"
                          value={formik.values?.product_uom}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                        />
                      </FormControl>
                      {formik.errors.product_uom &&
                        formik.touched.product_uom && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {formik.errors.product_uom}
                          </Box>
                        )}
                    </Grid>
                    <Grid item md={4}>
                      <FormControl className="w-100">
                        <Typography component="label">No of Units</Typography>
                        <OutlinedInput
                          name="no_of_units"
                          value={formik.values?.no_of_units}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                        />
                      </FormControl>
                      {formik.errors.no_of_units &&
                        formik.touched.no_of_units && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {formik.errors.no_of_units}
                          </Box>
                        )}
                    </Grid>
                    {/* <Grid item md={3}>
                      <FormControl className="w-100">
                        <Typography component="label">Cost Price</Typography>
                        <OutlinedInput
                          name="cost_price"
                          value={formik.values?.cost_price}

                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            // handleInputChange(e);
                          }}
                        />
                      </FormControl>
                      {formik.errors.cost_price &&
                        formik.touched.cost_price && (
                          <Box
                            Typography="small"
                            className="float-left text-danger"
                          >
                            {formik.errors.cost_price}
                          </Box>
                        )}
                    </Grid> */}
                  </Grid>
                  <Box className="w-100 float-right mb-15">
                    <Button
                      variant="outlined"
                      mt={2}
                      mb={2}
                      className="retail-btn float-right"
                      mr={2}
                      // onClick={()=>{checkIngredient()}}
                      type="submit"
                    >
                      Next
                    </Button>
                  </Box>
                  </form>
                  <Divider></Divider>
                  <Grid container mt={1}>
                    <Typography component="h4" className="font-bold">
                      NOTIFICATION
                    </Typography>
                    {/* <Grid container spacing={2}>
                      <Grid item md={4}>
                        <FormControlLabel
                          className="custom-checkbox"
                          control={<Checkbox />}
                          label="Perishable"
                          onClick={handleClick}
                        />
                      </Grid>
                      {showState && (
                        <Grid item md={4}>
                          <FormControl className="w-100 custom-fc">
                            <Typography component="label" mr={2}>
                              Expiry Date
                            </Typography>
                            <OutlinedInput type="date" />
                          </FormControl>
                        </Grid>
                      )}
                    </Grid> */}

                    <Grid container spacing={2} mt={1}>
                      <Grid item md={4}>
                        <FormControlLabel
                          className="custom-checkbox"
                          control={
                            <Checkbox
                              onChange={(e) => {
                                setIdleStock(idleStock=>!idleStock)
                              }}
                              checked={idleStock}
                            />
                          }
                          label="IdleStock"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormControl className="w-100 custom-fc">
                          <Typography component="label" mr={2}>
                            No of Days
                          </Typography>
                          <OutlinedInput
                            onChange={(e) => {
                              handleChange(e);
                              setProductIdleStockDays(e.target.value);
                            }}
                            name="product_idle_stock_days"
                            value={productIdleStockDays}
                            onBlur={(e) => {
                              handleBlur(e);
                              // handleInputChange(e);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={4} display={"flex"} alignItems={"center"}>
                        <Typography component="p" className="small">
                          After ___ Days an Alert will be Generated Saying the
                          Item is Idle
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mt={1} mb={1}>
                      <Grid item md={4}>
                        <FormControlLabel
                          className="custom-checkbox"
                          control={
                            <Checkbox
                            checked={lowStock}
                            onChange={(e) => {
                              setLowStock(lowStock=>!lowStock)
                            }}
                              
                            />
                          }
                          label="LowStock"
                        />
                      </Grid>
                      <Grid item md={4}>
                        <FormControl className="w-100 custom-fc ">
                          <Typography component="label" mr={2}>
                            Threshold Value
                          </Typography>
                          <OutlinedInput
                          name="product_low_stock_qty"
                          value={productLowStockQty}
                            onChange={(e) => {
                              handleChange(e);
                              setProductLowStockQty(e.target.value);
                            }}
                            onBlur={(e) => {
                              handleBlur(e);
                              // handleInputChange(e);
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography component="p" className="small">
                          After Stock Comes Below ___ then an Alert will be
                          Generated Saying low stock into reports
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider></Divider>
                  <Grid container>
                    <Box className="flex-between w-100" mt={2}>
                      <Typography className="font-bold" component="h4">
                        PRODUCT HISTORY
                      </Typography>
                      {/* <Typography component="p" className="ag-skuid">
                            BEV-TE-FSD-FF-23LI
                          </Typography> */}
                      <Button
                        variant="outlined"
                        mt={2}
                        mb={2}
                        className="retail-btn"
                        mr={2}
                        onClick={() =>{ref4.current.log();setItemStock(null)}}
                      >
                        Add Product
                      </Button>
                    </Box>
                    <Box className="member-table w-100">
                      <MemberTable
                        columns={ProductColumns}
                        options={options}
                        data={itemStockDetails?itemStockDetails:[]}
                      />
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Box>
          <Divider></Divider>
          <Box className="p-15">
            <Button
              variant="outlined"
              color="error"
              mt={2}
              mb={3}
              className="retail-btn float-right"
              onClick={handleClose}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <AddProductModal measurement={measurement} itemStock={props?.itemStock} ref={ref4} getItemStockDetailsProduct={getItemStockDetailsProduct} idleStock={idleStock} lowStock={lowStock} productIdleStockDays={productIdleStockDays} productLowStockQty={productLowStockQty}  />
    </div>
  );
});

export default ViewEditSuppliesModal;
